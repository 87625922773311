import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReferrers, GET_REFERRERS } from 'src/actions/embedAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import { Referrer } from '../referrer';
import styles from './Referrers.module.scss';

const Referrers = () => {
  const dispatch = useDispatch();

  const { selectedProject, referrers, selectedTimeRange } = useSelector(({ embedAnalytics }) => ({
    referrers: embedAnalytics.referrers,
    selectedProject: embedAnalytics.selectedProject,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
  }));

  useEffect(() => {
    dispatch(getReferrers(selectedProject.id, selectedTimeRange.to));
  }, [dispatch, selectedProject, selectedTimeRange.to]);

  const { status } = useStatus(GET_REFERRERS);

  return (
    <div className={styles.container}>
      <span className={styles.label}>URL Source</span>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {status === SUCCESS && (
        referrers.map(({ referrer }) => (
          <Referrer referrer={referrer} key={referrer} />
        ))
      )}
      {status === ERROR && (
        <span className={styles.referrer}>No source found</span>
      )}
    </div>
  );
};

export { Referrers };
