import React, { useCallback } from 'react';
import { func, bool, number } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import {
  DELETE_LOGO_FROM_BRAND_LIBRARY,
  deleteLogoFromBrandLibrary,
} from 'src/actions/brandLibraryActions';
import { useLoading } from 'src/hooks';
import styles from './DeleteLogoModal.module.scss';

const DeleteLogoModal = ({ isShowing, hide, logoCategoryId, logoId }) => {
  const dispatch = useDispatch();

  const deleteLogo = useCallback(() => {
    dispatch(deleteLogoFromBrandLibrary(logoCategoryId, logoId));
  }, [dispatch, logoId, logoCategoryId]);

  const loading = useLoading(DELETE_LOGO_FROM_BRAND_LIBRARY);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete this logo?
        </span>
        <span className={styles.subtitle}>
          This action will permanently delete your logo and can&apos;t be undone.
          This doesn&apos;t affect your projects in any way.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={deleteLogo}
            loading={loading}
            alternativeCTA
          >
            Delete
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteLogoModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  logoCategoryId: number.isRequired,
  logoId: number.isRequired,
};

export { DeleteLogoModal };
