import React from 'react';
import { useSelector } from 'react-redux';
import { func, string } from 'prop-types';

import { FONT_FAMILY_OPTIONS } from 'src/constants/fonts';
import { GOOGLE_UPLOAD, SYSTEM_UPLOAD } from 'src/constants/uploadFile';
import { Select } from 'src/common/select';
import { useBrandLibraryFonts } from 'src/hooks';
import styles from './FontFamilySelect.module.scss';

const SELECT_STYLES = {
  control: base => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 0,
  }),
  singleValue: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  input: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  menu: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  group: (base, state) => {
    if (!state.label) {
      return base;
    }
    return { ...base, borderBottom: '1px solid #c2c2c2' };
  },
  option: base => ({
    ...base,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const FontFamilySelect = ({
  onChangeFontFamily,
  onChangeFontStyle,
  uploadType,
  fontFamily,
  fontStyle,
}) => {
  const { uploadedFonts } = useSelector(({ brandLibrary }) => ({
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  const { availableFonts } = useBrandLibraryFonts();

  const getFontFamily = () => {
    if (uploadType === GOOGLE_UPLOAD || uploadType === SYSTEM_UPLOAD) {
      return FONT_FAMILY_OPTIONS.find(elem => elem.value === fontFamily);
    }
    return uploadedFonts.find(elem => elem.value === fontFamily);
  };

  return (
    <>
      <Select
        options={availableFonts}
        value={getFontFamily()}
        onChange={onChangeFontFamily}
        className={styles.fontFamily}
        selectStyles={SELECT_STYLES}
      />
      <Select
        options={getFontFamily().styles}
        value={getFontFamily().styles.find(st => st.value === fontStyle)
              || { value: '' }}
        onChange={onChangeFontStyle}
        className={styles.fontFamily}
        selectStyles={SELECT_STYLES}
      />
    </>
  );
};

FontFamilySelect.propTypes = {
  uploadType: string.isRequired,
  fontStyle: string,
  fontFamily: string.isRequired,
  onChangeFontFamily: func.isRequired,
  onChangeFontStyle: func.isRequired,
};

export { FontFamilySelect };
