export const LOCAL_UPLOAD = 'localUpload';

export const UNSPLASH_UPLOAD = 'unsplashUpload';

export const TEMPLATE_UPLOAD = 'templateUpload';

export const BRAND_LIBRARY_UPLOAD = 'brandLibraryUpload';

export const GOOGLE_UPLOAD = 'googleUpload';

export const SYSTEM_UPLOAD = 'systemUpload';

export const CAMPAIGN_UPLOAD = 'campaignUpload';

export const INVALID_LOCAL_UPLOAD = 'invalidLocalUpload';

export const VIDEO_TYPE = 'video';

export const IMAGE_TYPE = 'image';
