import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSession, useStatus } from 'src/hooks';
import {
  GET_ONBOARDING_INFORMATION,
  getOnboardingInformation,
  GET_USER_WITH_ACCOUNT,
  getUserWithAccount,
} from 'src/actions/accountActions';
import { reset } from 'src/actions/statusActions';
import { SocialNetworks } from 'src/pages/account-settings/social-networks';
import { Navbar } from 'src/common/navbar';
import { ScrapperAlert } from 'src/common/scrapper-alert';
import { SUCCESS } from 'src/constants/status';
import { AccountsManager } from 'src/pages/account-settings/accounts-manager';
import { AGENCY_PLANS } from 'src/constants/memberships';
import { EditorsManager } from 'src/pages/account-settings/editors-manager';
import { AccountsAsEditor } from 'src/pages/account-settings/accounts-as-editor';
import { GeneralInformation } from 'src/pages/account-settings/general-information';
import styles from './AccountSettings.module.scss';

const AccountSettings = () => {
  const dispatch = useDispatch();

  const [userIsTheAccountOwner, setUserIsTheAccountOwner] = useState(true);
  const { user, accountId } = useSession();
  const { membership: { plan } } = user;

  useEffect(() => {
    dispatch(getUserWithAccount());

    return () => {
      dispatch(reset(GET_USER_WITH_ACCOUNT));
    };
  }, [dispatch]);

  const { status: statusGetUser } = useStatus(GET_USER_WITH_ACCOUNT);

  useEffect(() => {
    if (statusGetUser === SUCCESS) {
      setUserIsTheAccountOwner(!!user.membership?.accounts.find(
        account => account.id === accountId
      ));
      dispatch(getOnboardingInformation(accountId));
    }

    return () => {
      if (statusGetUser === SUCCESS) {
        dispatch(reset(GET_ONBOARDING_INFORMATION));
      }
    };
  }, [dispatch, accountId, statusGetUser, user]);

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.subnav}>
        <h1 className={styles.title}>Business Settings</h1>
      </div>
      <ScrapperAlert />
      <div className={styles.content}>
        <div className={styles.contentForm}>
          {userIsTheAccountOwner ? (
            <>
              <GeneralInformation />
              {statusGetUser === SUCCESS && AGENCY_PLANS.includes(plan) && (
                <>
                  <AccountsManager />
                  <EditorsManager />
                </>
              )}
              {statusGetUser === SUCCESS && (
                <AccountsAsEditor />
              )}
              <SocialNetworks />
            </>
          ) : (
            <div className={styles.cantEditSettingsMessage}>
              <span>You can&apos;t see or edit others accounts&apos; settings</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export { AccountSettings };
