// Be careful when changing these values,
// you must also change the ones in the variables.scss file.

export const DESKTOP_WIDTH = 1600;

export const EXTRA_LARGE_WIDTH = 1480;

export const LARGE_WIDTH = 1200;

export const MEDIUM_WIDTH = 992;

export const SMALL_WIDTH = 768;

export const MOBILE_THRESHOLD_WIDTH = 575;


export const SMALL_HEIGHT = 840;

export const MOBILE_LOWEST_HEIGHT = 667;
