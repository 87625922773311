import React from 'react';
import { string } from 'prop-types';

import { Spinner } from 'src/common/spinner';
import styles from './ToastLoading.module.scss';

const ToastLoading = ({ message, emoji }) => (
  <div className={styles.container}>
    <Spinner iconClassName={styles.spinner} />
    <span className={styles.text}>{message}</span>
    {emoji && <div className={styles.emoji}>{emoji}</div>}
  </div>
);

ToastLoading.propTypes = {
  message: string.isRequired,
  emoji: string,
};

export { ToastLoading };
