import React, { forwardRef } from 'react';

import PlusIcon from 'src/assets/icons/plus-white.svg';
import { CANVAS_DRAWING_CONTENT } from 'src/constants/general';
import { useCanvasFrameActions, useProjectSelector } from 'src/hooks';
import styles from './AddFrameCard.module.scss';

const AddFrameCard = forwardRef((_, refDrawingLayer) => {
  const { addFrameRequest } = useCanvasFrameActions();

  const { size } = useProjectSelector();

  const onClick = () => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const canvasDrawingContent = refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`);
    addFrameRequest(size, canvasDrawingContent);
  };

  return (
    <button onClick={onClick} className={styles.container}>
      <img src={PlusIcon} alt="Add frame" />
    </button>
  );
});

export { AddFrameCard };
