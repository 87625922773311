import React, { useMemo } from 'react';
import { bool, number, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { LogoCategory } from './logo-category';
import { AssetCategory } from './asset-category';
import { ColorPalette } from './color-palette';
import { Fonts } from './fonts';
import { SearchResults } from './search-results';
import {
  ASSETS_OPTION,
  LOGOS_OPTION,
  COLORS_OPTION,
  FONTS_OPTION,
} from '../side-menu/useCategoryChanges';
import styles from './BrandLibraryContent.module.scss';

const BrandLibraryContent = ({ selectedNavigation, selectedCategoryId, isSearching }) => {
  const { logoCategories, assetCategories, colorPalettes } = useSelector(({ brandLibrary }) => ({
    logoCategories: brandLibrary.logoCategories,
    assetCategories: brandLibrary.assetCategories,
    colorPalettes: brandLibrary.colorPalettes,
  }));

  const content = useMemo(() => {
    if (isSearching) {
      return <SearchResults />;
    }
    if (selectedNavigation === FONTS_OPTION) {
      return <Fonts />;
    }
    if (!selectedCategoryId) {
      return null;
    }
    if (selectedNavigation === LOGOS_OPTION) {
      const category = logoCategories.find(cat => cat.id === selectedCategoryId);
      if (category) {
        return <LogoCategory {...category} />;
      }
    }
    if (selectedNavigation === ASSETS_OPTION) {
      const category = assetCategories.find(cat => cat.id === selectedCategoryId);
      if (category) {
        return <AssetCategory {...category} />;
      }
    }
    if (selectedNavigation === COLORS_OPTION) {
      const category = colorPalettes.find(cat => cat.id === selectedCategoryId);
      if (category) {
        return <ColorPalette {...category} />;
      }
    }
    return null;
  }, [
    selectedCategoryId,
    selectedNavigation,
    logoCategories,
    assetCategories,
    colorPalettes,
    isSearching,
  ]);

  return (
    <div className={styles.container}>
      {content}
    </div>
  );
};

BrandLibraryContent.propTypes = {
  selectedNavigation: string,
  selectedCategoryId: number,
  isSearching: bool.isRequired,
};

export { BrandLibraryContent };
