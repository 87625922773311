import React, { useCallback } from 'react';
import cn from 'classnames';
import { number } from 'prop-types';

import Logo from 'src/assets/icons/logo.svg';
import Check from './check.svg';
import styles from './StepsMenu.module.scss';

const StepsMenu = ({ currentStep }) => {
  const options = ['Business Info', 'Target Demographic', 'Main Call To Action'];
  const safeCurrentStep = currentStep < 0 ? 0 : currentStep - 1;

  const getBehaviorClass = useCallback((currIdx) => {
    if (currIdx < safeCurrentStep) {
      return styles.past;
    }

    if (currIdx === safeCurrentStep) {
      return styles.current;
    }

    return null;
  }, [safeCurrentStep]);

  return (
    <div className={styles.menuContainer}>
      <img src={Logo} alt="Stagger logo" className={styles.logo} />
      <div className={styles.optionsContainer}>
        {
          options.map((option, idx) => (
            <div key={option} className={cn(styles.option, getBehaviorClass(idx))}>
              <div className={styles.circle}>
                {idx < safeCurrentStep ?
                  <img src={Check} className={styles.check} alt="Check icon" /> : (
                    <span className={styles.number}>
                      {idx + 1}
                    </span>
                  )}
              </div>
              <div className={styles.menuOption}>
                {option.toUpperCase()}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

StepsMenu.propTypes = {
  currentStep: number,
};

export { StepsMenu };
