import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool } from 'prop-types';

import {
  ENABLE_SQUAD_EVENT_ASSETS,
  PRODUCT_TOUR_PREFIX_ID,
} from 'src/constants/general';
import Shapes from 'src/assets/icons/shapes.svg';
import Templates from 'src/assets/icons/templates.svg';
import TextIcon from 'src/assets/icons/text-icon.svg';
import EditCanvasIcon from 'src/assets/icons/edit-canvas.svg';
import ChooseImageIcon from 'src/assets/icons/choose-image.svg';
import { Pulse } from 'src/common/pulse';
import {
  ADD_TEXT_CONTROL,
  ADD_OBJECT_CONTROL,
  TEMPLATES_CONTROL,
  CHOOSE_IMAGE_CONTROL,
  EDIT_CANVAS_CONTROL,
  COLLAGE_CONTROL,
} from 'src/constants/canvasControls';
import { GET_PROJECT } from 'src/actions/projectActions';
import { useSession, useStatus } from 'src/hooks';
import { SUCCESS } from 'src/constants/status';
import { changeControlOpen } from 'src/actions/canvasActions';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { Control } from './control';
import { CollageControl } from './collage-control';
import styles from './Controls.module.scss';

const Controls = ({ error }) => {
  const dispatch = useDispatch();

  const {
    productTour,
  } = useSelector(({ session }) => ({
    productTour: session.productTour,
  }));

  const { user } = useSession();

  const { status: statusGetProject } = useStatus(GET_PROJECT);

  useEffect(() => {
    if (statusGetProject === SUCCESS && window.innerWidth > SMALL_WIDTH) {
      if (ENABLE_SQUAD_EVENT_ASSETS(user.createdOnCampaign)) {
        dispatch(changeControlOpen(CHOOSE_IMAGE_CONTROL));
      } else {
        dispatch(changeControlOpen(COLLAGE_CONTROL));
      }
    }
  }, [dispatch, statusGetProject, user?.createdOnCampaign]);

  return (
    <div className={styles.container}>
      <CollageControl disabled={error} />
      <Control icon={Templates} control={TEMPLATES_CONTROL} text="Templates" disabled={error} />
      <Control
        icon={ChooseImageIcon}
        control={CHOOSE_IMAGE_CONTROL}
        text="Media"
        disabled={error}
      />
      <Control
        icon={EditCanvasIcon}
        control={EDIT_CANVAS_CONTROL}
        text="Canvas"
        disabled={error}
      />
      <Control icon={TextIcon} control={ADD_TEXT_CONTROL} text="Text" disabled={error} />
      <Control icon={Shapes} control={ADD_OBJECT_CONTROL} text="Objects" disabled={error} />
      <div id={`${PRODUCT_TOUR_PREFIX_ID}-1`} className={styles.productTour}>
        {productTour && <Pulse />}
      </div>
    </div>
  );
};

Controls.propTypes = {
  error: bool,
};

export { Controls };
