import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const size = {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };

  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = ({ target }) => {
      const newSize = { width: target.innerWidth, height: target.innerHeight };
      setWindowSize(newSize);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return { windowSize };
};

export { useWindowSize };
