import { useDispatch, useSelector } from 'react-redux';

import { useProjectSelector } from 'src/hooks';
import { CANVAS_HELPERS_PREFIXES } from 'src/constants/general';
import { addFrame, moveFrame } from 'src/actions/projectActions';

const ALLOWED_DIFF_FOR_HUMAN_ERROR = 0.01;

const useCanvasFrameActions = () => {
  const dispatch = useDispatch();

  const { baseCanvasWidth, draggableOffset, stageOffset, scale } = useSelector(({ canvas }) => ({
    baseCanvasWidth: canvas.baseCanvasWidth,
    draggableOffset: canvas.draggableOffset,
    stageOffset: canvas.stageOffset,
    scale: canvas.scale,
  }));

  const { layout, size } = useProjectSelector();

  const buildResult = (canvasDrawingContent, callback) => {
    const result = {};
    const content = canvasDrawingContent.getChildren(node => (
      !CANVAS_HELPERS_PREFIXES.some(prefix => node.getId().startsWith(prefix))
    ));

    content.forEach(element => {
      const box = element.getClientRect();
      const canvasOffsetX = draggableOffset.x * scale + stageOffset.x * scale;
      let startFrame = (box.x - canvasOffsetX) / baseCanvasWidth;
      let endFrame = (box.x + box.width - canvasOffsetX) / baseCanvasWidth;
      const isOutsideCanvas = endFrame < 0 || startFrame > size;
      if (!isOutsideCanvas) {
        const startRounded = Math.round(startFrame);
        if (Math.abs(startRounded - startFrame) < ALLOWED_DIFF_FOR_HUMAN_ERROR) {
          startFrame = startRounded;
        }
        const endRounded = Math.round(endFrame);
        if (Math.abs(endRounded - endFrame) < ALLOWED_DIFF_FOR_HUMAN_ERROR) {
          endFrame = endRounded - 1;
        }
        callback(element, Math.trunc(startFrame), Math.trunc(endFrame), result);
      }
    });
    return result;
  };

  const checkForMovement = (elem, startFrame, endFrame, result, indexes) => {
    if (startFrame === endFrame) {
      if (startFrame === indexes.source) {
        const difference = indexes.destination - indexes.source;
        result[elem.getId()] = elem.x() + (layout.width * difference);
      } else if (indexes.source < indexes.destination &&
        startFrame > indexes.source && startFrame <= indexes.destination) {
        result[elem.getId()] = elem.x() - layout.width;
      } else if (indexes.source > indexes.destination &&
        startFrame < indexes.source && startFrame >= indexes.destination) {
        result[elem.getId()] = elem.x() + layout.width;
      }
    }
  };

  const moveFrameRequest = (indexes, canvasDrawingContent) => {
    const result = buildResult(
      canvasDrawingContent,
      (elem, sFrame, eFrame, res) => checkForMovement(elem, sFrame, eFrame, res, indexes),
    );
    dispatch(moveFrame({ indexes, result }));
  };

  const checkForMovementInAddition = (elem, startFrame, endFrame, result, index) => {
    if (startFrame >= index) {
      result[elem.getId()] = elem.x() + layout.width;
    }
  };

  const addFrameRequest = (index, canvasDrawingContent) => {
    const result = buildResult(
      canvasDrawingContent,
      (elem, sFrame, eFrame, res) => checkForMovementInAddition(elem, sFrame, eFrame, res, index),
    );
    dispatch(addFrame({ index, result }));
  };

  return { moveFrameRequest, addFrameRequest };
};

export { useCanvasFrameActions };
