import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';

import { useProjectSelector } from 'src/hooks';
import { Dropzone } from 'src/common/dropzone';
import { uploadMedia } from 'src/actions/canvasActions';
import { IMAGE_TYPE, VIDEO_TYPE } from 'src/constants/uploadFile';
import { UploadedMedia } from '../uploaded-media';
import styles from './LocalUpload.module.scss';

const LocalUpload = ({ setVideoModalOpen, saveSourceInMemory }) => {
  const dispatch = useDispatch();

  const { replaceableImageRightClick } = useSelector(({ canvas }) => ({
    replaceableImageRightClick: canvas.replaceableImageRightClick,
  }));

  const isReplacingImage = !!replaceableImageRightClick;

  const { showedVideoInformation } = useProjectSelector();

  const addMedia = useCallback(images => {
    dispatch(uploadMedia(images));
  }, [dispatch]);

  const addFiles = files => {
    const formattedFiles = files.map(file => {
      const img = { file };
      if (file.type.startsWith('image')) {
        img.fileType = IMAGE_TYPE;
      } else {
        img.fileType = VIDEO_TYPE;
        if (!showedVideoInformation) {
          setVideoModalOpen(true);
        }
      }
      return img;
    });
    addMedia(formattedFiles);
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>Upload images from your computer</span>
      <span className={styles.subtitle}>
        Supports .jpg, .png, .bmp, .svg
      </span>
      <div className={styles.dropzoneContainer}>
        <Dropzone
          onAcceptCallback={addFiles}
          acceptType={{ 'image/*': ['.heic'], 'video/*': [] }}
          buttonFile
          noClick
          maxFiles={isReplacingImage ? 1 : undefined}
          className={styles.dropzone}
        />
      </div>
      <UploadedMedia saveSourceInMemory={saveSourceInMemory} />
    </div>
  );
};

LocalUpload.propTypes = {
  setVideoModalOpen: func.isRequired,
  saveSourceInMemory: func.isRequired,
};

export { LocalUpload };
