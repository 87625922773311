import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { number, shape } from 'prop-types';
import cn from 'classnames';

import BorderSizeWhite from 'src/assets/icons/border-icon-white.svg';
import { updateElement } from 'src/actions/projectActions';
import { useClickOutside, useProjectSelector, useTooltip } from 'src/hooks';
import { Tooltip } from 'src/common/tooltip';
import {
  NO_BORDER_SIZE,
  DEFAULT_BORDER_COLOR,
} from 'src/constants/general';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import { IMAGE_ELEMENT } from 'src/constants/canvasElements';
import styles from './BorderWidth.module.scss';

const BORDER_SIZE_OPTIONS = [NO_BORDER_SIZE, 1, 2, 3, 5, 7, 10, 13];
const PADDING_LEFT_DROPDOWN = 10;
const IMAGE_WIDTH_MULTIPLIER = 3;

const BorderWidth = ({ scrollPosition }) => {
  const dispatch = useDispatch();

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.values(selectedElements);
  const elementSelected = elements.find(item => item.uuid === selectedElementsList[0]);

  const [showOptions, setShowOptions] = useState(false);

  const updateBorderWidthRequest = useCallback((attrs, event) => {
    setShowOptions(false);
    selectedElementsList.forEach(elementId => {
      const element = elements.find(elem => elem.uuid === elementId);
      if (element.type === IMAGE_ELEMENT) {
        attrs.strokeWidth *= IMAGE_WIDTH_MULTIPLIER;
      }
      if (!element.borderColor && attrs.strokeWidth !== NO_BORDER_SIZE) {
        attrs.borderColor = DEFAULT_BORDER_COLOR;
      }
      const newAttrs = {
        uuid: elementId,
        ...attrs,
      };
      dispatch(updateElement(newAttrs, event.timeStamp));
    });
  }, [dispatch, elements, selectedElementsList]);

  const onClickButton = () => setShowOptions(s => !s);

  const { ref: refTooltip, showTooltip } = useTooltip();

  const widthSelected = useMemo(() => {
    if (!elementSelected?.strokeWidth) {
      return NO_BORDER_SIZE;
    }
    if (elementSelected.type === IMAGE_ELEMENT) {
      return elementSelected.strokeWidth / IMAGE_WIDTH_MULTIPLIER;
    }
    return elementSelected.strokeWidth;
  }, [elementSelected]);

  const containerId = 'borderWidth';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  const clickeableId = 'clickeableBorderWidth';
  const ref = useClickOutside(
    () => setShowOptions(false),
    showOptions,
    [clickeableId],
  );

  const tooltipStyles = useCallback(() => (
    {
      left: optionsSize.left - PADDING_LEFT_DROPDOWN,
      right: 'unset',
      transform: 'unset',
    }
  ), [optionsSize.left]);

  return (
    <div className={styles.container} ref={refTooltip} id={containerId}>
      <button
        onClick={onClickButton}
        className={styles.button}
      >
        <img
          src={BorderSizeWhite}
          alt="Border size"
          id={clickeableId}
        />
      </button>
      {showOptions && (
        <div
          className={styles.options}
          ref={ref}
          style={{ left: optionsSize.left - PADDING_LEFT_DROPDOWN }}
        >
          {BORDER_SIZE_OPTIONS.map(size => (
            <button
              key={size}
              className={cn(styles.option, { [styles.selected]: (widthSelected === size) })}
              onClick={(e) => updateBorderWidthRequest({ strokeWidth: size }, e)}
            >
              {size === NO_BORDER_SIZE ? (
                <span
                  className={cn(styles.border, { [styles.selectedFont]: (widthSelected === size) })}
                >
                  None
                </span>
              ) : (
                <div
                  className={cn(styles.border, { [styles.selected]: (widthSelected === size) })}
                  style={{
                    borderBottom: `${widthSelected === size ? 'white' : 'black'} ${size}px solid`,
                  }}
                />
              )}
            </button>
          ))}
        </div>
      )}
      {showTooltip && !showOptions && <Tooltip style={tooltipStyles()}>Border</Tooltip>}
    </div>
  );
};

BorderWidth.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
};

export { BorderWidth };
