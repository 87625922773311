import React from 'react';
import { bool, func, string, shape, number } from 'prop-types';

import { TEXT_COLOR_EYEDROPPER_SOURCE } from 'src/constants/general';
import { ColorPickerCanvas } from 'src/pages/project/canvas/canvas-helper-elements';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import styles from './FontColorControl.module.scss';

const FontColorControl = ({
  disabled,
  element,
  updateElementRequest,
  groupClassName,
  scrollPosition,
}) => {
  const containerId = 'fontColorSelect';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  return (
    <div className={groupClassName} id={containerId}>
      <ColorPickerCanvas
        color={element?.color}
        onColorChange={updateElementRequest}
        className={styles.colorPicker}
        disabled={disabled}
        eyedropperSource={TEXT_COLOR_EYEDROPPER_SOURCE}
        style={{ left: optionsSize.left, top: '100%' }}
      />
    </div>
  );
};

FontColorControl.propTypes = {
  disabled: bool,
  updateElementRequest: func.isRequired,
  element: shape({ color: shape({ hex: string }) }),
  groupClassName: string,
  scrollPosition: shape({
    x: number,
  }),
};

export { FontColorControl };
