import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { addAccount, ADD_ACCOUNT } from 'src/actions/accountActions';
import { validateAccount } from 'src/utils/constrains';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import styles from './AddAccountForm.module.scss';

const AddAccountForm = ({ onCancel }) => {
  const dispatch = useDispatch();

  const addAccountRequest = useCallback(async (values) => {
    await dispatch(addAccount(values));
  }, [dispatch]);

  const { status: statusAddAccount, error } = useStatus(ADD_ACCOUNT);

  useEffect(() => {
    if (statusAddAccount === SUCCESS) {
      onCancel();
    }

    return () => {
      if (statusAddAccount === SUCCESS || statusAddAccount === ERROR) {
        dispatch(reset(ADD_ACCOUNT));
      }
    };
  }, [dispatch, onCancel, statusAddAccount]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(addAccountRequest, { validate: validateAccount });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit}>
        <Input
          name="brandName"
          placeholder="Name"
          containerClassName={styles.input}
          disabled={statusAddAccount === LOADING}
          {...inputProps('brandName')}
        />
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onCancel}
            className={styles.cancelButton}
            secondary
            disabled={statusAddAccount === LOADING}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={styles.saveButton}
            loading={statusAddAccount === LOADING}
          >
            Save
          </Button>
        </div>
      </form>
      {statusAddAccount === ERROR && <span className={styles.error}>{error}</span>}
    </>
  );
};

AddAccountForm.propTypes = {
  onCancel: func.isRequired,
};

export { AddAccountForm };
