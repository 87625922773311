import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bool, func } from 'prop-types';

import { toastErrorConfig, toastSuccessConfig } from 'src/constants/toastConfig';
import { PROJECT_TYPE, TOAST_ID_PRINT_ORDER_SUCCESS, EXPORT_CALLBACK } from 'src/constants/general';
import {
  useLoading,
  useProjectSelector,
  useDownloadPreviews,
  useCopyEmbedCode,
  useStatus,
} from 'src/hooks';
import {
  UPDATE_PROJECT,
  UPDATE_PROJECT_AUTOMATICALLY,
  updateProjectToExportRequest,
  updateProjectToExport,
  UPDATE_PROJECT_TO_EXPORT,
} from 'src/actions/projectActions';
import { routesPaths } from 'src/routes/routesPaths';
import { createCheckoutSessionOrderSign } from 'src/actions/userActions';
import { SUCCESS } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import { routeWithProps } from 'src/utils/helpers';
import { trackGeneratePreview } from 'src/utils/analytics';
import { triggerKeepGeneratedCollage } from 'src/actions/collageActions';
import { SaveButton } from './save-button';
import { PublishProjectButton } from './publish-project-button';
import { PublishFlyerButton } from './publish-flyer-button';
import { OrderSignButton } from './order-sign-button';
import { FlyerSettingsModal } from './flyer-settings-modal';
import { NewTabModal } from './new-tab-modal';

const MAX_VIDEO_SIZE = 1920;

const SaveOrPublish = ({ generatePreviews, prepareCanvas, undoPrepareCanvas, disabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [flyerSettingsOpen, setFlyerSettingsOpen] = useState(false);

  const [newTabModalOpen, setNewTabModalOpen] = useState(false);

  const { id, name, type, layout, videoIdsByPosition } = useProjectSelector();

  const {
    isGeneratedCollageVisible,
  } = useSelector(({ collage }) => ({
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
  }));

  const loadingExport = useLoading(UPDATE_PROJECT_TO_EXPORT);

  const { copyEmbedInClipboard } = useCopyEmbedCode();

  const { downloadPdf } = useDownloadPreviews();

  const canvasHasMoreThanOneVideo = () => {
    let hasMoreThanOneVideoPerCanvas = false;
    const videosProjectPerPage = Object.keys(videoIdsByPosition);
    videosProjectPerPage.forEach(page => {
      const numberOfVideosPerPage = videoIdsByPosition[page].length;
      if (numberOfVideosPerPage > 1) {
        hasMoreThanOneVideoPerCanvas = true;
        const finalPage = Number(page) + 1;
        const message = `There are currently ${numberOfVideosPerPage} videos on
        canvas ${finalPage}. Please choose one video per canvas.`;
        toast.error(message, toastErrorConfig);
      }
    });
    return hasMoreThanOneVideoPerCanvas;
  };

  const canvasLayoutIsInvalidForVideo = () => {
    // Shotstack restrictions for video generation
    const videoIds = Object.values(videoIdsByPosition).flatMap(e => e);
    if (videoIds.length && (layout.width > MAX_VIDEO_SIZE || layout.height > MAX_VIDEO_SIZE)) {
      toast.error(
        `This canvas size doesn't support video. Try smaller than ${MAX_VIDEO_SIZE}px.`,
        toastErrorConfig,
      );
      return true;
    }
    if (videoIds.length && (layout.width % 2 !== 0 || layout.height % 2 !== 0)) {
      toast.error('This canvas size doesn\'t support video.', toastErrorConfig);
      return true;
    }
    return false;
  };

  const exportProject = async (callback, showFeedback = true, generatePdf = false) => {
    if (canvasHasMoreThanOneVideo() || canvasLayoutIsInvalidForVideo()) {
      return;
    }
    dispatch(updateProjectToExportRequest());
    prepareCanvas();
    const files = await generatePreviews({ nameFile: name });
    const [fileThumbnail] = await generatePreviews({
      nameFile: name,
      isThumbnail: true,
      isTemplate: false,
    });
    undoPrepareCanvas();
    dispatch(updateProjectToExport(id, files, fileThumbnail, callback, showFeedback, generatePdf));
    if (isGeneratedCollageVisible) {
      dispatch(triggerKeepGeneratedCollage(id));
    }
  };

  const goToPreview = useCallback((state) => {
    history.push(routeWithProps(routesPaths.preview, { id }), { data: state });
  }, [history, id]);

  const orderSign = useCallback(() => {
    dispatch(
      createCheckoutSessionOrderSign(
        `${routeWithProps(routesPaths.project, { id })}?showToast=${TOAST_ID_PRINT_ORDER_SUCCESS}`,
        routeWithProps(routesPaths.project, { id }),
        id,
      ),
    );
  }, [dispatch, id]);

  const {
    status: statusExport,
    statusInfo: statusInfoExport,
  } = useStatus(UPDATE_PROJECT_TO_EXPORT);

  const exportAndGoToPreview = () => {
    exportProject(EXPORT_CALLBACK.GO_TO_PREVIEW);
  };

  const exportAndDownloadPreviews = () => {
    exportProject(EXPORT_CALLBACK.DOWNLOAD_PREVIEWS);
  };

  const exportAndDownloadPdf = () => {
    exportProject(EXPORT_CALLBACK.DOWNLOAD_PDF, true, true);
  };

  const copyEmbedCodeAndExport = async () => {
    await copyEmbedInClipboard();
    exportProject(EXPORT_CALLBACK.GENERATE_EMBED_CODE);
  };

  const exportWithToast = () => {
    exportProject(EXPORT_CALLBACK.PUBLISH_TOAST);
  };

  const exportAndOrderSign = () => {
    exportProject(EXPORT_CALLBACK.ORDER_SIGN);
  };

  useEffect(() => {
    if (statusExport === SUCCESS) {
      trackGeneratePreview(statusInfoExport.id);
      dispatch(reset(UPDATE_PROJECT_TO_EXPORT));
      if (statusInfoExport.id === id) {
        if (statusInfoExport.callback === EXPORT_CALLBACK.DOWNLOAD_PREVIEWS) {
          goToPreview({ action: EXPORT_CALLBACK.DOWNLOAD_PREVIEWS });
        } else if (statusInfoExport.callback === EXPORT_CALLBACK.DOWNLOAD_PDF) {
          downloadPdf();
        } else if (statusInfoExport.callback === EXPORT_CALLBACK.GENERATE_EMBED_CODE) {
          goToPreview({ action: EXPORT_CALLBACK.GENERATE_EMBED_CODE });
        } else if (statusInfoExport.callback === EXPORT_CALLBACK.GO_TO_PREVIEW) {
          goToPreview();
        } else if (statusInfoExport.callback === EXPORT_CALLBACK.PUBLISH_TOAST) {
          toast.success('All changes published!', toastSuccessConfig);
        } else if (statusInfoExport.callback === EXPORT_CALLBACK.ORDER_SIGN) {
          orderSign();
        }
      }
    }
  }, [
    dispatch,
    id,
    statusExport,
    statusInfoExport,
    downloadPdf,
    goToPreview,
    orderSign,
  ]);

  const closeFlyerSettingsModal = () => {
    setFlyerSettingsOpen(false);
  };

  const openFlyerSettingsModal = () => {
    setFlyerSettingsOpen(true);
  };

  const loadingSaveDraft = useLoading(UPDATE_PROJECT);
  const loadingSaveAutomatically = useLoading(UPDATE_PROJECT_AUTOMATICALLY);

  const disableButtons = disabled || loadingExport || loadingSaveDraft || loadingSaveAutomatically;

  return (
    <>
      <SaveButton
        disabled={disableButtons}
        exportAndDownloadPreviews={exportAndDownloadPreviews}
        exportAndDownloadPdf={exportAndDownloadPdf}
        generatePreviews={generatePreviews}
        prepareCanvas={prepareCanvas}
        undoPrepareCanvas={undoPrepareCanvas}
        openFlyerSettingsModal={openFlyerSettingsModal}
      />
      {type === PROJECT_TYPE.PROJECT && (
        <PublishProjectButton
          disabled={disableButtons}
          exportAndDownloadPreviews={exportAndDownloadPreviews}
          copyEmbedCodeAndExport={copyEmbedCodeAndExport}
          exportWithToast={exportWithToast}
          exportAndGoToPreview={exportAndGoToPreview}
        />
      )}
      {type === PROJECT_TYPE.SIGN && (
        <OrderSignButton
          disabled={disableButtons}
          exportAndOrder={exportAndOrderSign}
        />
      )}
      {type === PROJECT_TYPE.FLYER && (
        <PublishFlyerButton
          disabled={disableButtons}
          exportWithToast={exportWithToast}
          openFlyerSettingsModal={openFlyerSettingsModal}
        />
      )}
      {flyerSettingsOpen && (
        <FlyerSettingsModal
          isShowing={flyerSettingsOpen}
          onClose={closeFlyerSettingsModal}
          exportProject={exportProject}
        />
      )}
      {newTabModalOpen && (
        <NewTabModal
          hide={() => setNewTabModalOpen(false)}
        />
      )}
    </>
  );
};

SaveOrPublish.propTypes = {
  generatePreviews: func.isRequired,
  prepareCanvas: func.isRequired,
  undoPrepareCanvas: func.isRequired,
  disabled: bool,
};

export { SaveOrPublish };
