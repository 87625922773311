import React, { useCallback, useState, useLayoutEffect, useEffect } from 'react';
import { func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { PRODUCT_TOUR_PREFIX_ID, PROJECT_TYPE } from 'src/constants/general';
import { getRootElementFontSize } from 'src/utils/helpers';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import {
  getSteps,
  getTourProductName,
} from 'src/pages/project/bottom-options/product-tour/tourHelper';
import { userDoingProductTour, userFinishesProductTour } from 'src/actions/userSettingsActions';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ArrowLeftBlack from 'src/assets/icons/arrow-left-black.svg';
import ArrowRightBlack from 'src/assets/icons/arrow-right-black.svg';
import { Button } from 'src/common/button';
import { changeControlOpen } from 'src/actions/canvasActions';
import { Modal } from 'src/common/modal';
import { Dots } from 'src/common/modal-slider/dots';
import { useWindowSize, useSession, usePrevious, useProjectSelector } from 'src/hooks';
import styles from './ProductTour.module.scss';

const MODAL_ID = 'productTourModal';

const ProductTour = ({ setModalStorytellingOpen }) => {
  const dispatch = useDispatch();

  const { productTour } = useSelector(({ session }) => ({ productTour: session.productTour }));

  const { user } = useSession();

  const { type } = useProjectSelector();

  const [currentStep, setCurrentStep] = useState(0);

  const onCloseProductTour = useCallback(() => {
    const tourName = getTourProductName(type);
    if (user.settings[tourName]) {
      dispatch(userDoingProductTour(false));
    } else {
      dispatch(userFinishesProductTour(tourName));
    }
  }, [dispatch, user, type]);

  const onClickToolbarStep = useCallback((control) => {
    dispatch(changeControlOpen(control));
    onCloseProductTour();
  }, [dispatch, onCloseProductTour]);

  const { windowSize: { width } } = useWindowSize();
  const steps = getSteps(width, type, onClickToolbarStep);

  const onClickPrev = useCallback(() => {
    if (currentStep >= 1) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const onClickNext = useCallback(() => {
    if (currentStep < steps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
    }
  }, [currentStep, steps.length]);


  const prevProductTour = usePrevious(productTour);

  useEffect(() => {
    if (prevProductTour && !productTour && currentStep !== 0) {
      setCurrentStep(0);
    }
  }, [currentStep, prevProductTour, productTour]);

  const onClickGoToStorytelling = () => {
    onCloseProductTour();
    setModalStorytellingOpen(true);
  };

  let buttons;
  if (currentStep === steps.length - 1) {
    buttons = (
      <>
        {width > MOBILE_THRESHOLD_WIDTH && type === PROJECT_TYPE.PROJECT && (
          <Button
            className={styles.buttonStory}
            onClick={onClickGoToStorytelling}
            short
          >
            Storytelling Tips
          </Button>
        )}
        <Button
          className={styles.buttonStart}
          onClick={onCloseProductTour}
          alternativeCTA
          short
        >
          Get Started
        </Button>
      </>
    );
  } else {
    buttons = (
      <>
        <button className={styles.button} onClick={onClickPrev} disabled={currentStep === 0}>
          <img src={currentStep === 0 ? ArrowLeft : ArrowLeftBlack} alt="Left arrow" />
        </button>
        <button className={styles.button} onClick={onClickNext}>
          <img src={ArrowRightBlack} alt="Right arrow" />
        </button>
      </>
    );
  }

  const [position, setPosition] = useState({});

  const getPosition = useCallback((stepIndex) => {
    const stylesForModal = {
      position: 'absolute',
      top: 'auto',
      left: 'auto',
    };
    const stepElem = document.getElementById(`${PRODUCT_TOUR_PREFIX_ID}-${stepIndex + 1}`);
    const modalElem = document.getElementById(MODAL_ID);
    if (!stepElem || !modalElem) {
      return stylesForModal;
    }
    const { align } = steps[stepIndex];
    const rect = stepElem.getBoundingClientRect();
    const valueOfRem = getRootElementFontSize();
    const pulseWidth = 4 * valueOfRem;
    const pulseCenter = pulseWidth / 2;
    const padding = 15;
    let top = rect.top / valueOfRem;
    let left = ((rect.left + pulseWidth + padding) / valueOfRem);
    if (align === 'top-center') {
      top = (rect.top + pulseWidth + padding) / valueOfRem;
      left = (rect.left + pulseCenter - (modalElem.offsetWidth / 2)) / valueOfRem;
    } else if (align === 'top-right') {
      const alignLeft = 20;
      top = (rect.top + pulseWidth + padding) / valueOfRem;
      left = (rect.left + pulseWidth + alignLeft - modalElem.offsetWidth) / valueOfRem;
    } else if (align === 'top-left') {
      top = (rect.top + pulseWidth + padding) / valueOfRem;
      left = (rect.left) / valueOfRem;
    } else if (align === 'right-top') {
      left = (rect.left - modalElem.offsetWidth - padding) / valueOfRem;
    } else if (align === 'left-bottom') {
      top = (rect.top - modalElem.offsetHeight) / valueOfRem;
    } else if (align === 'bottom-center') {
      top = (rect.top - modalElem.offsetHeight - pulseWidth - padding) / valueOfRem;
      left = (rect.left + pulseCenter - (modalElem.offsetWidth / 2)) / valueOfRem;
    }
    if (width <= MOBILE_THRESHOLD_WIDTH) {
      left = 'auto';
    }
    stylesForModal.top = `${top}rem`;
    stylesForModal.left = `${left}rem`;
    return stylesForModal;
  }, [steps, width]);

  useLayoutEffect(() => {
    setPosition(getPosition(currentStep));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, width, productTour]);

  return (
    <Modal
      isShowing={productTour}
      hide={onCloseProductTour}
      isEscEnabled
      hideClassName={styles.close}
      icon
      backgroundLight
      stylesProductTour={position}
      productTour
      closeOnClickOutside={false}
      id={MODAL_ID}
      classNameContent={styles.content}
    >
      <>
        <div className={styles.header}>
          <span className={styles.title}>
            {steps[currentStep].title}
          </span>
          <span className={styles.subtitle}>
            {steps[currentStep].subtitle}
          </span>
        </div>
        <div className={styles.body}>
          {steps[currentStep].body()}
        </div>
        <div className={styles.bottom}>
          <Dots activeIndex={currentStep} size={steps.length} />
          {buttons}
        </div>
      </>
    </Modal>
  );
};

ProductTour.propTypes = {
  setModalStorytellingOpen: func.isRequired,
};

export { ProductTour };
