import React from 'react';
import { useDispatch } from 'react-redux';

import { FtuTip } from 'src/pages/project/ftu-tip';
import { closeProductTip } from 'src/actions/userSettingsActions';

const ResizeElementTip = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeProductTip('showElementResizeMobileTip'));
  };

  return (
    <FtuTip
      title="Drag a handle to resize"
      subtitle="When you want to resize your image, drag one corner to keep it’s proportion.
        Drag a centered handle to make it wider or taller."
      onClose={onClose}
    />
  );
};

export { ResizeElementTip };
