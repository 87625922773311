import React from 'react';
import { useSelector } from 'react-redux';

import { UploadedFont } from '../uploaded-font';
import styles from './UploadedFonts.module.scss';

const UploadedFonts = () => {
  const { uploadedFonts } = useSelector(({ brandLibrary }) => ({
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  return (
    <div className={styles.container}>
      <span className={styles.title}>Uploaded Fonts</span>
      {uploadedFonts.length ? (
        uploadedFonts.map(({ id, name }) => (
          <UploadedFont key={id} id={id} name={name} />
        ))
      ) : (
        <span className={styles.empty}>Upload custom fonts to use.</span>
      )}
    </div>
  );
};

export { UploadedFonts };
