import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { func, string, bool, number, object } from 'prop-types';
import { toast } from 'react-toastify';
import cn from 'classnames';

import FolderIcon from 'src/assets/icons/folder-icon.svg';
import { useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { toastErrorConfig } from 'src/constants/toastConfig';
import { Button } from '../button';
import styles from './Dropzone.module.scss';

const Dropzone = ({
  onAcceptCallback,
  acceptType,
  noClick = false,
  buttonFile = false,
  maxFiles = Number.MAX_SAFE_INTEGER,
  className,
  disabled = false,
}) => {
  const onRejected = () => {
    toast.error('Error while uploading files', toastErrorConfig);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptType,
    onDropRejected: onRejected,
    onDropAccepted: onAcceptCallback,
    noClick,
    noKeyboard: noClick,
    maxFiles,
    disabled,
  });

  const { windowSize: { width } } = useWindowSize();
  const isSmallScreen = useMemo(() => width <= SMALL_WIDTH, [width]);

  return (
    <section className={cn(styles.container, className)}>
      <div {...getRootProps({ className: styles.dropzone })}>
        {!isSmallScreen && (
          <div className={styles.dropContainer}>
            <img src={FolderIcon} alt="Drag and drop files" />
            <span className={styles.mainText}>
              Drag and drop your files
            </span>
          </div>
        )}
        <input {...getInputProps()} />
        {buttonFile && (
          <>
            {!isSmallScreen && <span className={styles.secondaryText}>or</span>}
            <Button onClick={open} className={styles.button} disabled={disabled}>
              Browse files
            </Button>
          </>
        )}
      </div>
    </section>
  );
};

Dropzone.propTypes = {
  onAcceptCallback: func.isRequired,
  acceptType: object.isRequired,
  noClick: bool,
  buttonFile: bool,
  maxFiles: number,
  className: string,
  disabled: bool,
};

export { Dropzone };
