import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCategoriesWithTemplates,
  GET_CATEGORIES_WITH_TEMPLATES,
} from 'src/actions/templatesActions';
import { useStatus, useSession, useProjectSelector } from 'src/hooks';
import { getSuggestions, GET_SUGGESTIONS } from 'src/actions/insightActions';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { reset } from 'src/actions/statusActions';
import {
  SuggestionList,
} from 'src/pages/project/controls-options/templates-control/categories-list/suggestion-list';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import { CUSTOM_TEMPLATE_CATEGORY, PROJECT_TYPE } from 'src/constants/general';
import { PRIVATE_TEMPLATES_PLANS } from 'src/constants/memberships';
import { TemplateCategory } from './template-category';
import { CategoryGrid } from './category-grid';
import styles from './CategoriesList.module.scss';

const CategoriesList = () => {
  const dispatch = useDispatch();
  const { accountId, isSubscriptionActive, membershipPlan } = useSession();

  const { status: getTemplatesStatus } = useStatus(GET_CATEGORIES_WITH_TEMPLATES);
  const { status: getSuggestionsStatus } = useStatus(GET_SUGGESTIONS);

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const privateTemplatesEnabled =
    isSubscriptionActive && PRIVATE_TEMPLATES_PLANS.includes(membershipPlan);

  const { categories, suggestions } = useSelector(({ templates, insights }) => ({
    categories: templates.categories,
    suggestions: insights.suggestions,
  }));

  const allCategories = Object.values(categories).filter(cat => {
    if (cat.name === CUSTOM_TEMPLATE_CATEGORY) {
      if (privateTemplatesEnabled) {
        return true;
      }
      return false;
    }
    return true;
  });

  const { type } = useProjectSelector();

  useEffect(() => {
    const page = 1;
    dispatch(getCategoriesWithTemplates(accountId, page, type));

    return () => dispatch(reset(GET_CATEGORIES_WITH_TEMPLATES));
  }, [dispatch, accountId, type]);

  useEffect(() => {
    const page = 1;
    if (amountOfTemplatesInARow && type === PROJECT_TYPE.PROJECT) {
      dispatch(getSuggestions(accountId, page, amountOfTemplatesInARow));
    }

    return () => {
      dispatch(reset(GET_SUGGESTIONS));
    };
  }, [dispatch, accountId, amountOfTemplatesInARow, type]);

  const categoriesToShow = useMemo(() => {
    if (allCategories.length === 1) {
      const [category] = allCategories;
      return (
        <CategoryGrid
          name={category.name}
          amountOfTemplatesInARow={amountOfTemplatesInARow}
          categoryId={category.id}
        />
      );
    }
    return (
      allCategories.map(({ name, id, templates }) => (
        !!templates.length && (
          <TemplateCategory
            key={id}
            categoryId={id}
            name={name === CUSTOM_TEMPLATE_CATEGORY ? 'My Custom Templates' : name}
            amountOfTemplatesInARow={amountOfTemplatesInARow}
          />
        )
      ))
    );
  }, [allCategories, amountOfTemplatesInARow]);

  return (
    <div className={styles.container}>
      {(getSuggestionsStatus === SUCCESS || getSuggestionsStatus === LOADING) &&
        !!suggestions.length && (
          <SuggestionList amountOfTemplatesInARow={amountOfTemplatesInARow} />
      )}
      {getTemplatesStatus === SUCCESS && categoriesToShow}
      {getTemplatesStatus === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
          spinnerClassName={styles.spinner}
        />
      )}
      {getTemplatesStatus === ERROR && (
        <span className={styles.error}>
          Oops! The search for the templates resulted in error. Please try again.
        </span>
      )}
    </div>
  );
};

export { CategoriesList };
