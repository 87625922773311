import LineSolid from 'src/assets/icons/line-solid.svg';
import LineDashed from 'src/assets/icons/line-dashed.svg';
import LineVariedDashed from 'src/assets/icons/line-varied-dashed.svg';
import LineSmallDashed from 'src/assets/icons/line-small-dashed.svg';
import LineSolidWhite from 'src/assets/icons/line-solid-white.svg';
import LineDashedWhite from 'src/assets/icons/line-dashed-white.svg';
import LineVariedDashedWhite from 'src/assets/icons/line-varied-dashed-white.svg';
import LineSmallDashedWhite from 'src/assets/icons/line-small-dashed-white.svg';

export const OPTION_ICONS = [
  LineSolid,
  LineDashed,
  LineVariedDashed,
  LineSmallDashed,
];

export const OPTION_ICONS_SELECTED = [
  LineSolidWhite,
  LineDashedWhite,
  LineVariedDashedWhite,
  LineSmallDashedWhite,
];
