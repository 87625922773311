import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parse } from 'query-string';

import DownloadIcon from 'src/assets/icons/download.svg';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { useDownloadPreviews, useProjectSelector, useStatus } from 'src/hooks';
import { showPreviews } from 'src/utils/helpers';
import { getProject, GET_PROJECT } from 'src/actions/projectActions';
import { reset } from 'src/actions/statusActions';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { ModalDownloadLongpress } from './modal-download-longpress';
import styles from './ExportPreviewBreakDown.module.scss';

const ExportPreviewBreakDown = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getProject(id));

    return () => {
      dispatch(reset(GET_PROJECT));
    };
  }, [dispatch, id]);

  const [previewsSelected, setPreviewsSelected] = useState();

  const { downloadSinglePreview } = useDownloadPreviews();

  const { status: getProjectStatus } = useStatus(GET_PROJECT);

  useEffect(() => {
    if (getProjectStatus === SUCCESS) {
      const queryParams = new URLSearchParams(history.location.search);
      if (queryParams.has('selected')) {
        const { selected } = parse(history.location.search, { parseBooleans: true });
        setPreviewsSelected(selected.split(','));
      }
    }
  }, [getProjectStatus, history]);

  const [showModal, setShowModal] = useState(true);

  const { previews, videoUrls } = useProjectSelector();

  const slides = showPreviews(previews, videoUrls);

  return (
    <div className={styles.page}>
      <Button
        onClick={() => history.push(routesPaths.projects)}
        alternativeCTA
        className={styles.button}
      >
        Back to Projects
      </Button>
      {slides.map((slide, index) => {
        const isSelected = !previewsSelected || previewsSelected.find(s => s === index.toString());
        if (!isSelected) {
          return null;
        }
        return (
          <div className={styles.slideContainer} key={slide.key}>
            {slide}
            <button
              className={styles.downloadButton}
              onClick={() => downloadSinglePreview(index)}
            >
              <img src={DownloadIcon} alt="Download" />
            </button>
          </div>
        );
      })}
      {getProjectStatus === ERROR && (
        <div className={styles.error}>
          Oops! This project couldn&apos;t be loaded, please try again.
        </div>
      )}
      {getProjectStatus === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
          spinnerClassName={styles.spinner}
        />
      )}
      <Button
        onClick={() => history.push(routesPaths.projects)}
        alternativeCTA
        className={styles.button}
      >
        Back to Projects
      </Button>
      {showModal && <ModalDownloadLongpress hide={() => setShowModal(false)} />}
    </div>
  );
};

export { ExportPreviewBreakDown };
