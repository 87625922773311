import React, { useEffect, useMemo } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'src/common/select';
import { getCategories } from 'src/actions/productTemplateActions';
import { SELECT_STYLES } from 'src/constants/general';
import styles from './BusinessCategorySelect.module.scss';

const OTHER = 'Other';
const CATEGORY_SELECT_STYLES = {
  ...SELECT_STYLES,
  menuList: base => ({ ...base, maxHeight: '13.5rem' }),
};

const BusinessCategorySelect = ({ setBusinessCategory }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const {
    categoryNames,
  } = useSelector(({ productTemplate }) => ({
    categoryNames: productTemplate.categoryNames,
  }));

  const categoryOptions = useMemo(() => {
    const nonScrapableCategories = JSON.parse(process.env.REACT_APP_NON_SCRAPABLE_CATEGORIES) || [];
    const cats = Object.values(categoryNames).reduce((res, actual) => {
      if (!nonScrapableCategories.find(c => c === actual.id)) {
        const option = { label: actual.name, value: actual.name };
        res.push(option);
      }
      return res;
    }, []);
    return cats.concat({ label: OTHER, value: OTHER });
  }, [categoryNames]);

  const onChange = ({ value }) => {
    setBusinessCategory(value === OTHER ? null : value);
  };

  return (
    <div className={styles.categoryWrapper}>
      <Select
        options={categoryOptions}
        closeMenuOnSelect
        placeholder="Company category?"
        selectStyles={CATEGORY_SELECT_STYLES}
        onChange={onChange}
      />
    </div>
  );
};

BusinessCategorySelect.propTypes = {
  setBusinessCategory: func.isRequired,
};


export { BusinessCategorySelect };
