import React, { useMemo, forwardRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { bool, func } from 'prop-types';
import cn from 'classnames';

import { GET_PROJECT } from 'src/actions/projectActions';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { SUCCESS } from 'src/constants/status';
import ArrowDown from 'src/assets/icons/arrow-down-white.svg';
import { ScrollMenu } from 'src/common/scroll-menu';
import { useProjectSelector, useStatus } from 'src/hooks';
import { FrameCard } from './frame-card';
import { AddFrameCard } from './add-frame-card';
import styles from './BottomMenu.module.scss';

const BottomMenu = forwardRef(({ visible, setVisible }, refDrawingLayer) => {
  const { size, frameTitles } = useProjectSelector();

  const { status: statusGetProject } = useStatus(GET_PROJECT);
  const { status: statusGetBrandLibrary } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  const mediaCards = useMemo(() => (
    Array.from({ length: size }, (v, i) => i).map(elem => {
      const frameTitle = frameTitles[elem] || `Canvas ${elem + 1}`;
      return (
        <FrameCard
          key={frameTitle}
          index={elem}
          frameTitle={frameTitle}
          // NOTE: itemId is required to track items inside the ScrollMenu
          itemId={frameTitle}
        />
      );
    })
  ), [frameTitles, size]);

  return (
    <div className={cn(styles.floatingOptions, { [styles.visible]: visible })}>
      <div className={styles.container}>
        <div className={styles.bar}>
          {statusGetProject === SUCCESS && statusGetBrandLibrary === SUCCESS && (
            <>
              <AddFrameCard ref={refDrawingLayer} />
              <Droppable
                droppableId="bottomMenu"
                direction="horizontal"
                type="frameCard"
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={styles.droppableContainer}
                  >
                    <ScrollMenu
                      wrapperClassName={styles.slider}
                      leftArrowClassName={styles.leftArrow}
                    >
                      {mediaCards}
                      {provided.placeholder}
                    </ScrollMenu>
                  </div>
                )}
              </Droppable>
            </>
          )}
        </div>
        <button className={styles.handle} onClick={() => setVisible(v => !v)}>
          <img
            src={ArrowDown}
            alt="Set visibility of bottom menu"
            className={cn(styles.arrowIcon, { [styles.closedIcon]: !visible })}
          />
        </button>
      </div>
    </div>
  );
});

BottomMenu.propTypes = {
  visible: bool.isRequired,
  setVisible: func.isRequired,
};

export { BottomMenu };
