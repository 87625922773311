import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Group } from 'react-konva';

import { CANVAS_TITLE_SPACE, CANVAS_TITLE_MARGIN_TOP } from 'src/constants/general';
import { useProjectSelector } from 'src/hooks';
import { CanvasFrameTitle } from './canvas-frame-title';

const CanvasFrameTitles = () => {
  const { layout, size, frameTitles } = useProjectSelector();

  const { scale } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
  }));

  const canvasTitles = useMemo(() => {
    const titles = [];
    for (let index = 0; index < size; index++) {
      const marginLeft = 5;
      const x = (layout.width * scale) * index + marginLeft;
      const title = {
        x,
        y: -CANVAS_TITLE_SPACE + CANVAS_TITLE_MARGIN_TOP,
        prefix: `Canvas ${index + 1}`,
      };
      titles.push(title);
    }
    return titles;
  }, [layout, size, scale]);

  const drawTitles = useCallback(() => (
    canvasTitles.map(({ x, y, prefix }, index) => (
      <CanvasFrameTitle
        key={prefix}
        prefix={prefix}
        title={frameTitles[index]}
        x={x}
        y={y}
        frameIndex={index}
      />
    ))
  ), [canvasTitles, frameTitles]);

  return (
    <Group
      x={0}
      y={0}
      scaleX={1 / (scale || 1)}
      scaleY={1 / (scale || 1)}
    >
      {drawTitles()}
    </Group>
  );
};

export { CanvasFrameTitles };
