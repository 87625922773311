import React, { memo, useCallback, useRef } from 'react';
import { string, func, number, oneOfType } from 'prop-types';
import cn from 'classnames';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import styles from './InputNumber.module.scss';

const InputNumber = memo(({
  name,
  onChange,
  onBlur,
  value = '',
  sufix,
  className = '',
  inputClassName = '',
  min,
  max,
}) => {
  const ref = useRef();

  const onChangeValidation = useCallback((event) => {
    const { target } = event;
    if (target.value < min) {
      onChange(min, event);
    } else if (target.value > max) {
      onChange(max, event);
    } else {
      onChange(target.value, event);
    }
  }, [max, min, onChange]);

  const onKeyDown = useCallback(({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  }, []);

  const increment = () => {
    if (ref?.current) {
      ref.current.stepUp();
      const event = new Event('change', { cancelable: true, bubbles: true });
      ref.current.dispatchEvent(event);
    }
  };

  const decrement = () => {
    if (ref?.current) {
      ref.current.stepDown();
      const event = new Event('change', { cancelable: true, bubbles: true });
      ref.current.dispatchEvent(event);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <input
        type="number"
        className={cn(styles.input, inputClassName)}
        min={min}
        max={max}
        value={value.toString()}
        onChange={onChangeValidation}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        name={name}
        ref={ref}
      />
      {sufix && <span className={styles.sufix}>{sufix}</span>}
      <div className={styles.buttons}>
        <button className={styles.button} onClick={increment}>
          +
        </button>
        <button className={styles.button} onClick={decrement}>
          -
        </button>
      </div>
    </div>
  );
});

InputNumber.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([number, string]),
  onBlur: func,
  sufix: string,
  className: string,
  min: number,
  max: number,
  name: string,
  inputClassName: string,
};

export { InputNumber };
