import React from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import { useClickOutside } from 'src/hooks';
import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import styles from './FtuTip.module.scss';

const FtuTip = ({ title, subtitle, onClose, className, closeOnClickOutside = true }) => {
  const ref = useClickOutside(onClose, closeOnClickOutside);

  return (
    <div className={cn(styles.container, className)} ref={ref}>
      <button className={styles.close} onClick={onClose}>
        <img src={CloseWhiteIcon} alt="Close" />
      </button>
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
};

FtuTip.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  onClose: func.isRequired,
  className: string,
  closeOnClickOutside: bool,
};

export { FtuTip };
