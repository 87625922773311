import React, { useState, useEffect } from 'react';
import { string, func, arrayOf } from 'prop-types';

import { Select } from 'src/common/select';

const StepsSelect = ({ options, onChange, className, name, value, ...rest }) => {
  const [selectedOption, setSelectedOption] = useState(value ? {
    label: value,
    value,
  } : null);

  useEffect(() => {
    setSelectedOption(value ? {
      label: value,
      value,
    } : null);
  }, [value]);

  return (
    <Select
      {...rest}
      options={options.map((opValue) => ({ label: opValue, value: opValue }))}
      className={className}
      onChange={(item) => {
        setSelectedOption(item);
        onChange(name, item.label);
      }}
      value={selectedOption}
      closeMenuOnSelect
    />
  );
};

StepsSelect.propTypes = {
  options: arrayOf(string).isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
  className: string,
  value: string,
};

export { StepsSelect };
