import { useCallback, useEffect, useState } from 'react';

const useScrollPosition = (element) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const listenToScroll = useCallback(() => {
    requestAnimationFrame(() => {
      setPosition({
        x: element.current.scrollLeft,
        y: element.current.scrollTop,
      });
    });
  },
  [element]);


  useEffect(() => {
    const currentElement = element.current;
    if (currentElement) {
      currentElement.addEventListener('scroll', listenToScroll);
    }
    return () => {
      // eslint-disable-next-line no-unused-expressions
      currentElement?.removeEventListener('scroll', listenToScroll);
    };
  }, [element, listenToScroll]);

  return { position };
};

export { useScrollPosition };
