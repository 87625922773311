import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CANVAS_SCROLL_ID } from 'src/constants/general';

const useStageCenteredPosition = () => {
  const {
    draggableOffset,
    stageOffset,
    scale,
  } = useSelector(({ canvas }) => ({
    draggableOffset: canvas.draggableOffset,
    stageOffset: canvas.stageOffset,
    scale: canvas.scale,
  }));

  const canvasEl = document.getElementById(CANVAS_SCROLL_ID);

  const position = useCallback(() => {
    if (canvasEl) {
      const pos = {
        y: (canvasEl.offsetHeight / 2) / scale - draggableOffset.y - stageOffset.y,
        x: (canvasEl.offsetWidth / 2) / scale - draggableOffset.x - stageOffset.x,
      };
      return pos;
    }
    return { x: 0, y: 0 };
  }, [canvasEl, draggableOffset.x, draggableOffset.y, scale, stageOffset.x, stageOffset.y]);

  return position();
};

export { useStageCenteredPosition };
