import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { usePasteImageInCanvas } from 'src/hooks';
import {
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT,
  BROWSERS,
} from 'src/constants/general';
import { getBrowserName } from 'src/utils/helpers';
import { toastErrorConfig } from 'src/constants/toastConfig';
import PasteIcon from 'src/assets/icons/paste.svg';
import styles from './CanvasRightClickOptionsCanvas.module.scss';

const CanvasRightClickOptionsCanvas = () => {
  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  const { addImageInCanvas } = usePasteImageInCanvas();

  const pasteImage = useCallback(async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems?.length) {
        const [item] = clipboardItems;
        const hasImage = item.types.find(type => type === 'image/png');
        if (hasImage) {
          item.getType('image/png').then(blob => addImageInCanvas(blob));
        }
      }
    } catch (e) {
      toast.error('Permission denied to paste element', toastErrorConfig);
    }
  }, [addImageInCanvas]);

  if (getBrowserName() === BROWSERS.SAFARI) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: rightClickOptions.y,
        left: rightClickOptions.x,
        height: `${CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT}rem`,
        width: `${CANVAS_RIGHT_CLICK_OPTIONS_WIDTH}rem`,
      }}
    >
      <button
        className={styles.option}
        onClick={pasteImage}
      >
        <img src={PasteIcon} alt="Paste" />
        Paste image
      </button>
    </div>,
    document.getElementById('root'),
  );
};

export { CanvasRightClickOptionsCanvas };
