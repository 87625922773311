import React, { useCallback } from 'react';
import { func, bool, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { DELETE_UPLOADED_FONT, deleteUploadedFont } from 'src/actions/brandLibraryActions';
import { useStatus } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import styles from './DeleteUploadedFont.module.scss';

const DeleteUploadedFont = ({ isShowing, hide, fontId, fontName }) => {
  const dispatch = useDispatch();

  const { id, fonts } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
    fonts: brandLibrary.fonts,
  }));

  const deleteFont = useCallback(() => {
    const fontsToChange = fonts.filter(elem => elem.fontFamily === fontName);
    dispatch(deleteUploadedFont(id, fontId, fontsToChange));
  }, [fonts, dispatch, id, fontId, fontName]);

  const { status } = useStatus(DELETE_UPLOADED_FONT);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete your font?
        </span>
        <span className={styles.subtitle}>
          This action will permanently delete your font and can&apos;t be undone.
          This affects the projects and font styles that are using it.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={deleteFont}
            loading={status === LOADING}
            alternativeCTA
          >
            Delete
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteUploadedFont.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  fontId: number.isRequired,
  fontName: string.isRequired,
};

export { DeleteUploadedFont };
