import React, { useCallback, useState, useEffect, forwardRef } from 'react';
import { func, shape, string, number, bool, arrayOf, oneOf, node, object } from 'prop-types';
import { SketchPicker } from 'react-color';
import cn from 'classnames';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import {
  TEXT_DISABLED_COLOR,
  RIGHT,
  LEFT,
  COLOR_TYPE_SOLID,
  COLOR_TYPE_GRADIENT_LINEAR,
  CUSTOM,
} from 'src/constants/general';
import { hexToRgb } from 'src/utils/helpers';
import { useClickOutside } from 'src/hooks';
import { ColorTypeSelect } from './color-type-select';
import { GradientPicker } from './gradient-picker';
import { AnglePicker } from './angle-picker';
import { Modal } from '../modal';
import styles from './ColorPicker.module.scss';

const MOUSE_MOVE_EVENT = 'mousemove';

const ColorPicker = forwardRef(({
  color = { },
  onColorChange,
  className = '',
  position = RIGHT,
  disabled = false,
  disabledColor = TEXT_DISABLED_COLOR,
  enableAlpha = true,
  onOpen,
  onClose,
  presetColors = [],
  swatch,
  addedChildren = null,
  enableGradient = false,
  onChangeColorType,
  gradientColorStops,
  setGradientColorStops,
  defaultColorType,
  onGradientAngleChange,
  gradientStartPoint,
  gradientEndPoint,
  style,
  customPosition = '',
  openInModal = false,
}, buttonRef) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    onOpen && onOpen();
    setIsOpen(true);
  }, [onOpen]);

  const close = useCallback(() => {
    onClose && onClose();
    setIsOpen(false);
  }, [onClose]);

  const ref = useClickOutside(close, isOpen, undefined, openInModal);

  const [colorDragging, setColorDragging] = useState();

  const colorToShow = colorDragging || color;

  const handleColorChange = useCallback((value, event) => {
    const { hex, rgb: { a } } = value;
    const alpha = Math.floor(a * 100);
    if (color.hex !== hex || color.alpha !== alpha || event.type === MOUSE_MOVE_EVENT) {
      onColorChange({ hex, alpha }, event);
      setColorDragging(undefined);
    }
  }, [color.hex, color.alpha, onColorChange, setColorDragging]);

  const handleColorDrag = useCallback((value, event) => {
    if (event.type !== MOUSE_MOVE_EVENT) {
      return;
    }
    const { hex, rgb: { a } } = value;
    const alpha = Math.floor(a * 100);
    if (color.hex !== hex || color.alpha !== alpha) {
      setColorDragging({ hex, alpha });
    }
  }, [color.alpha, color.hex, setColorDragging]);

  const onClick = () => {
    if (!isOpen) {
      open();
    } else if (isOpen) {
      close();
    }
  };

  const swatchColor = () => {
    const rgb = hexToRgb(colorToShow.hex);
    rgb.a = colorToShow.alpha / 100;
    return rgb;
  };

  useEffect(() => {
    const handleKeydown = ({ code }) => {
      if (code === ENTER_CHARACTER_CODE && isOpen) {
        close();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [close, isOpen]);

  const [colorType, setColorType] = useState();

  useEffect(() => {
    setColorType(defaultColorType);
  }, [defaultColorType]);

  const changeColorType = (type) => {
    setColorType(type);
    onChangeColorType && onChangeColorType(type);
  };

  const colorTypeOptions = [
    {
      text: 'Solid',
      value: COLOR_TYPE_SOLID,
      action: () => changeColorType(COLOR_TYPE_SOLID),
    },
    {
      text: 'Linear',
      value: COLOR_TYPE_GRADIENT_LINEAR,
      action: () => changeColorType(COLOR_TYPE_GRADIENT_LINEAR),
    },
  ];

  const picker = isOpen && (
    <div
      className={cn(styles.picker, {
        [styles.left]: position === LEFT,
        [styles.right]: position === RIGHT,
        [customPosition]: position === CUSTOM,
      })}
      style={style}
    >
      {enableGradient && (
        <ColorTypeSelect options={colorTypeOptions} selectedOption={colorType} />
      )}
      {colorType === COLOR_TYPE_GRADIENT_LINEAR && (
        <>
          <GradientPicker
            colorStops={gradientColorStops}
            onColorStopsChange={setGradientColorStops}
          />
          <AnglePicker
            onChange={onGradientAngleChange}
            gradientStartPoint={gradientStartPoint}
            gradientEndPoint={gradientEndPoint}
          />
        </>
      )}
      <SketchPicker
        disableAlpha={!enableAlpha}
        color={swatchColor()}
        onChange={handleColorDrag}
        onChangeComplete={handleColorChange}
        presetColors={presetColors}
      />
      {addedChildren}
    </div>
  );

  return (
    <div className={cn(styles.container, className, { [styles.open]: isOpen })}>
      {disabled ? (
        <div style={{ backgroundColor: disabledColor }} className={styles.disabled} />
      ) : (
        <div className={styles.content} ref={ref}>
          {swatch ? swatch(onClick) : (
            <button
              className={styles.swatch}
              onClick={onClick}
              aria-label="Choose color"
              style={{
                backgroundColor: color.hex,
                opacity: color.alpha / 100,
              }}
              ref={buttonRef}
            />
          )}
          {isOpen && (
            openInModal ? (
              <Modal
                isShowing={isOpen}
                hide={close}
                isEscEnabled={false}
              >
                {picker}
              </Modal>
            ) : picker
          )}
        </div>
      )}
    </div>
  );
});

ColorPicker.propTypes = {
  color: shape({
    hex: string,
    alpha: number,
  }),
  onColorChange: func.isRequired,
  className: string,
  position: oneOf([LEFT, RIGHT, CUSTOM]),
  disabled: bool,
  disabledColor: string,
  enableAlpha: bool,
  onOpen: func,
  onClose: func,
  presetColors: arrayOf(shape({
    title: string,
    color: string,
  })),
  swatch: func,
  addedChildren: node,
  enableGradient: bool,
  onChangeColorType: func,
  gradientColorStops: arrayOf(shape({
    color: string,
    offset: string,
    id: number,
  })),
  setGradientColorStops: func,
  defaultColorType: string,
  onGradientAngleChange: func,
  gradientStartPoint: shape({ x: number, y: number }),
  gradientEndPoint: shape({ x: number, y: number }),
  style: object,
  customPosition: string,
  openInModal: bool,
};

export { ColorPicker };
