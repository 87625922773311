import { current } from 'immer';
import { Engine } from 'json-rules-engine';

import {
  getRulesSuccess,
  loadRulesByTypeSuccess,
  updateProjectFacts,
  triggerEventSuccess,
  triggerEventFailure,
  triggerEventReset,
  setRuleStateSuccess,
  turnEngineOffSuccess,
  loadRulesByTypeReset,
  setDirtyFact,
  setReadyToShowDesignTip,
  addImageScoreFact,
  discardTip,
  ADD_IMAGE_SCORE_FACT,
} from 'src/actions/rulesEngineActions';
import { logout } from 'src/actions/userActions';
import { RULE_TRIGGERED_POSTFIX } from 'src/constants/rulesEngine';
import {
  getProjectSuccess,
  changeSize,
} from 'src/actions/projectActions';
import {
  CanvasCountFactHandler,
  FontsCountFactHandler,
  ImagesScoresFactHandler,
} from 'src/utils/projectFactsHandlers';
import createReducer from './createReducer';

const factHandlers = [CanvasCountFactHandler(), FontsCountFactHandler(), ImagesScoresFactHandler()];

const projectActions = [changeSize];

const PROJECT_UUID = 'project-uuid';

const initialState = {
  engine: null,
  rules: null,
  projectFacts: {
    canvasCount: 0,
    fontsCount: {},
    imagesScores: {},

    dirty: false,
    dirtyElements: [],
    dirtyingAction: null,

    tips: {},
  },
  readyToShowDesignTip: false,
  showDesignTipForElementUUID: null,
  tipsObjects: {},
};

const removeTriggererElementsFromTipObjects =
  (state, eventTriggererElements, triggeredRuleName) => {
  // eslint-disable-next-line no-unused-expressions
  eventTriggererElements?.forEach(({ uuid: triggererUuid }) => {
    const triggererInTipObjects = state.tipsObjects[triggererUuid];
    if (triggererInTipObjects) {
      if (
        triggererUuid !== PROJECT_UUID ||
        (triggererUuid === PROJECT_UUID && triggererInTipObjects.ruleName === triggeredRuleName)
      ) {
        delete state.tipsObjects[triggererUuid];

        // If I deleted the showDesignTipForElementUUID, I need to reset it
        if (triggererUuid === state.showDesignTipForElementUUID) {
          state.showDesignTipForElementUUID = initialState.showDesignTipForElementUUID;
          state.readyToShowDesignTip = false;
        }
      }
    }
  });

  return state;
  };

const createActionHandlers = () => {
  const actionHandlers = {
    [getRulesSuccess]: (state, { payload: { rules } }) => {
      const rulesByProjectType = {};

      rules.forEach(rule => {
        rule.types.forEach(ruleType => {
          if (!rulesByProjectType[ruleType]) {
            rulesByProjectType[ruleType] = [];
          }
          rulesByProjectType[ruleType].push(rule);
        });
      });

      state.rules = rulesByProjectType;
    },
    [getProjectSuccess]: (state, { payload: { project } }) => {
      state.projectFacts = {
        ...project.facts,
        dirty: true,
        dirtyingAction: {
          type: 'GET_PROJECT',
          payload: project,
        },
      };
    },
    [loadRulesByTypeSuccess]: (state, { payload: { rules, successHandler, failureHandler } }) => {
      localStorage.debug = null;
      // localStorage.debug = 'json-rules-engine'; // uncomment if you want to debug, null otherwise

      const jsonRules = rules.map(({ jsonRule }) => jsonRule);
      const engine = new Engine(jsonRules, { allowUndefinedFacts: true });

      // Register the events by type (e.g. <ruleName>RULE_TRIGGERED_POSTFIX)
      rules.forEach(({ name }) => {
        engine.on(`${name}${RULE_TRIGGERED_POSTFIX}`, successHandler);
      });
      engine.on('failure', failureHandler);

      engine.addOperator('mapLengthGreaterThan', (factValue, jsonValue) => {
        if (!factValue) {
          return false;
        }

        const items = Object.keys(factValue);
        if (!items.length) return false;
        return items.length > jsonValue;
      });

      engine.addOperator(
        'arrayLengthGreaterThan',
        (factValue, jsonValue) => factValue?.length > jsonValue,
      );

      state.engine = engine;
    },
    [updateProjectFacts]: (state, { payload: { project } }) => {
      if (project) {
        let projectFacts = current(state.projectFacts);

        let isAtLeastOneFactAffectedByAction = false;
        // update all project facts using the handler of each fact
        factHandlers.forEach(factHandler => {
          if (factHandler.isFactAffectedByAction(projectFacts.dirtyingAction)) {
            isAtLeastOneFactAffectedByAction = true;
            projectFacts = {
              ...projectFacts,
              ...factHandler.updateFact(project, projectFacts),
            };
          }
        });

        // Be sure that this is run after the project facts are updated
        if (state.engine) {
          // get only the facts affected by the dirty
          const localFacts = factHandlers.reduce((acc, factHandler) => {
            if (!factHandler.isFactAffectedByAction(projectFacts.dirtyingAction)) {
              delete acc[factHandler.factFieldName];
            }
            return acc;
          }, { ...projectFacts });

          if (isAtLeastOneFactAffectedByAction) {
            const { engine } = state;
            setTimeout(() => engine.run({
              ...localFacts,
            }),
            100);
          }
        }

        state.projectFacts = {
          ...projectFacts,
          projectId: project.id,
          dirty: initialState.dirty,
          dirtyElements: initialState.dirtyElements,
          dirtyingAction: initialState.dirtyingAction,
        };
      }
    },
    [triggerEventSuccess]: (state, { payload: { ruleName, eventTriggererElements } }) => {
      state = removeTriggererElementsFromTipObjects(state, eventTriggererElements);

      const localTipsObjects = {};
      if (eventTriggererElements) {
        eventTriggererElements.forEach(element => {
          localTipsObjects[element.uuid] = {
            payload: element,
            ruleName,
          };
        });
      }

      // if there is no specific element to show the lightbulb for (project action),
      // display the tip message
      if (Object.keys(localTipsObjects).some(
        uuid => uuid === PROJECT_UUID,
      )) {
        state.readyToShowDesignTip = true;
        state.showDesignTipForElementUUID = PROJECT_UUID;
      }

      state.tipsObjects = {
        ...state.tipsObjects,
        ...localTipsObjects,
      };
    },
    [triggerEventFailure]: (state, { payload: { ruleName, eventTriggererElements } }) => {
      state = removeTriggererElementsFromTipObjects(state, eventTriggererElements, ruleName);
    },
    [setRuleStateSuccess]: (state, { payload: { ruleName, ruleState } }) => {
      if (!state.projectFacts.tips[ruleName]) {
        throw new Error(`Invalid rule name: ${ruleName}`);
      }
      state.projectFacts.tips[ruleName].state = ruleState;
    },
    [turnEngineOffSuccess]: state => {
      if (state.engine) {
        state.engine.stop();
        state.engine = null;
      }
    },
    [triggerEventReset]: state => {
      state.readyToShowDesignTip = initialState.readyToShowDesignTip;
      state.showDesignTipForElementUUID = initialState.showDesignTipForElementUUID;
      state.tipsObjects = initialState.tipsObjects;
    },
    [loadRulesByTypeReset]: state => {
      if (state.engine) {
        state.engine.stop();
      }

      return initialState;
    },
    [setDirtyFact]: (state, { payload: { elements, action } }) => {
      state.projectFacts.dirty = true;
      state.projectFacts.dirtyElements = elements;
      state.projectFacts.dirtyingAction = action;
    },
    [setReadyToShowDesignTip]: (state, { payload: { status, uuid } }) => {
      state.readyToShowDesignTip = status;
      state.showDesignTipForElementUUID = uuid;
    },
    [addImageScoreFact]: (state, { payload: { projectId, imageScore } }) => {
      if (state.projectFacts.projectId === projectId) {
        state.projectFacts.imagesScores.push(imageScore);

        state.projectFacts.dirty = true;
        state.projectFacts.dirtyingAction = ADD_IMAGE_SCORE_FACT;
        state.projectFacts.dirtyElements = [{
          uuid: imageScore.imageId,
          score: imageScore.score,
          scoreReason: imageScore.scoreReason,
        }];
      }
    },
    [discardTip]: (state, { payload: { ruleName, designTipElementUUID } }) => {
      const tipObj = state.tipsObjects[designTipElementUUID];
      if (tipObj?.ruleName === ruleName) {
        delete state.tipsObjects[designTipElementUUID];
        state.showDesignTipForElementUUID = initialState.showDesignTipForElementUUID;
        state.readyToShowDesignTip = initialState.readyToShowDesignTip;
      }
    },
    [logout]: () => initialState,
  };

  return projectActions.reduce((res, actual) => ({
    ...res,
    [actual]: state => {
      state.projectFacts.dirty = true;
      state.projectFacts.dirtyElements = [{ uuid: PROJECT_UUID }];
      state.projectFacts.dirtyingAction = actual();
    },
  }), actionHandlers);
};

export default createReducer(initialState, createActionHandlers());
