import React, { useState } from 'react';

import { useSession } from 'src/hooks';
import { AddAccount } from 'src/pages/account-settings/accounts-manager/add-account';
import { AccountCard } from 'src/pages/account-settings/accounts-manager/account-card';
import styles from './AccountsManager.module.scss';

const AccountsManager = () => {
  const { user } = useSession();

  const [openFormId, setOpenFormId] = useState();

  const managerEnabled = user.membership.subscription?.active;

  return (
    <div className={styles.container}>
      <span className={styles.header}>Accounts ({user.membership.accounts.length})</span>
      {user.membership.accounts.map(account => (
        <AccountCard
          key={account.id}
          {...account}
          disabled={!managerEnabled}
          openFormId={openFormId}
          setOpenFormId={setOpenFormId}
        />
      ))}
      <AddAccount
        disabled={!managerEnabled}
        openFormId={openFormId}
        setOpenFormId={setOpenFormId}
      />
    </div>
  );
};

export { AccountsManager };
