import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import cn from 'classnames';

import SweatIcon from 'src/assets/icons/sweat.svg';
import SweatActiveIcon from 'src/assets/icons/sweat-royal.svg';
import ComputerIcon from 'src/assets/icons/computer.svg';
import ComputerActiveIcon from 'src/assets/icons/computer-royal.svg';
import UnsplashIcon from 'src/assets/icons/unsplash.svg';
import UnsplashActiveIcon from 'src/assets/icons/unsplash-royal.svg';
import BrandLibraryIcon from 'src/assets/icons/brand-library.svg';
import BrandLibraryActiveIcon from 'src/assets/icons/brand-library-royal.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import {
  LOCAL_UPLOAD,
  UNSPLASH_UPLOAD,
  BRAND_LIBRARY_UPLOAD,
  CAMPAIGN_UPLOAD,
} from 'src/constants/uploadFile';
import { setImageSourceInMemory } from 'src/actions/projectMemoryActions';
import { useSession } from 'src/hooks';
import { setReplaceableImageRightClick, changeControlOpen } from 'src/actions/canvasActions';
import { ButtonTooltip } from 'src/common/button-tooltip';
import { ENABLE_SQUAD_EVENT_ASSETS } from 'src/constants/general';
import { UnsplashUpload } from './unsplash-upload';
import { LocalUpload } from './local-upload';
import { BrandLibraryUpload } from './brand-library-upload';
import { SquadEventUpload } from './squad-event-upload';
import styles from './AddMediaControl.module.scss';

const AddMediaControl = ({ setVideoModalOpen }) => {
  const dispatch = useDispatch();

  const { user } = useSession();

  const {
    replaceableImageRightClick,
    imageSourceMemory,
  } = useSelector(({ canvas, projectMemory }) => ({
    replaceableImageRightClick: canvas.replaceableImageRightClick,
    imageSourceMemory: projectMemory.imageSource,
  }));

  const defaultTab = ENABLE_SQUAD_EVENT_ASSETS(user.createdOnCampaign) ?
    CAMPAIGN_UPLOAD :
    LOCAL_UPLOAD;
  const [source, setSource] = useState(imageSourceMemory?.source || defaultTab);

  const onClose = useCallback(() => {
    if (replaceableImageRightClick) {
      dispatch(setReplaceableImageRightClick({ attrs: null }));
    } else {
      dispatch(changeControlOpen(''));
    }
  }, [dispatch, replaceableImageRightClick]);

  const saveSourceInMemory = useCallback((props = {}) => {
    dispatch(setImageSourceInMemory({ imageSource: { source, ...props } }));
  }, [dispatch, source]);

  return (
    <div className={styles.container}>
      <div className={styles.sourceContainer}>
        <ButtonTooltip
          text="Brand Library"
          icon={source === BRAND_LIBRARY_UPLOAD ? BrandLibraryActiveIcon : BrandLibraryIcon}
          onClick={() => setSource(BRAND_LIBRARY_UPLOAD)}
          className={cn(styles.source, { [styles.active]: source === BRAND_LIBRARY_UPLOAD })}
          alt="brand library"
        />
        <ButtonTooltip
          text="Upload Media"
          icon={source === LOCAL_UPLOAD ? ComputerActiveIcon : ComputerIcon}
          onClick={() => setSource(LOCAL_UPLOAD)}
          className={cn(styles.source, { [styles.active]: source === LOCAL_UPLOAD })}
          alt="upload media"
        />
        <ButtonTooltip
          text="Unsplash"
          icon={source === UNSPLASH_UPLOAD ? UnsplashActiveIcon : UnsplashIcon}
          onClick={() => setSource(UNSPLASH_UPLOAD)}
          className={cn(styles.source, { [styles.active]: source === UNSPLASH_UPLOAD })}
          alt="unsplash library"
        />
        {ENABLE_SQUAD_EVENT_ASSETS(user.createdOnCampaign) && (
          <ButtonTooltip
            text="Sweat with Stagger"
            icon={source === CAMPAIGN_UPLOAD ? SweatActiveIcon : SweatIcon}
            onClick={() => setSource(CAMPAIGN_UPLOAD)}
            className={cn(styles.source, { [styles.active]: source === CAMPAIGN_UPLOAD })}
            alt="sweat with stagger gallery"
          />
        )}
      </div>
      <div className={styles.content}>
        <button className={styles.closeButton} onClick={onClose}>
          <img src={CloseIcon} alt="Close" />
        </button>
        {source === BRAND_LIBRARY_UPLOAD && (
          <BrandLibraryUpload saveSourceInMemory={saveSourceInMemory} />
        )}
        {source === LOCAL_UPLOAD && (
          <LocalUpload
            setVideoModalOpen={setVideoModalOpen}
            saveSourceInMemory={saveSourceInMemory}
          />
        )}
        {source === UNSPLASH_UPLOAD && <UnsplashUpload saveSourceInMemory={saveSourceInMemory} />}
        {source === CAMPAIGN_UPLOAD && <SquadEventUpload saveSourceInMemory={saveSourceInMemory} />}
      </div>
    </div>
  );
};

AddMediaControl.propTypes = {
  setVideoModalOpen: func.isRequired,
};

export { AddMediaControl };
