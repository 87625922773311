import { useState, useRef } from 'react';

const useLongPress = (longpressAction, clickAction) => {
  const [action, setAction] = useState();

  const timerRef = useRef();

  const startPressTimer = () => {
    timerRef.current = setTimeout(() => {
      setAction('longpress');
    }, 700);
  };

  const endPressTimer = (e) => {
    if (action === 'longpress') {
      longpressAction(e);
    } else if (!action) {
      clickAction(e);
    }
    clearTimeout(timerRef.current);
    setAction();
  };

  const handleOnMouseDown = () => {
    startPressTimer();
  };

  const handleOnMouseUp = (e) => {
    endPressTimer(e);
  };

  const handleOnTouchStart = () => {
    startPressTimer();
  };

  const handleOnTouchEnd = (e) => {
    endPressTimer(e);
  };

  return {
    handlers: {
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  };
};

export { useLongPress };
