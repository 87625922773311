import { useState, useEffect, useRef, useCallback } from 'react';

const emptyValidator = () => ({});

const useForm = (callback, {
  initialValues = {},
  validate = emptyValidator,
}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const refs = useRef({});

  const submit = useCallback(async () => {
    const { current } = refs;
    setTouched(tou => ({ ...tou, ...current }));
    if (Object.keys(errors).length === 0) {
      try {
        await callback(values);
      } catch (error) {
        setErrors(error);
      }
    }
  }, [callback, errors, values]);

  useEffect(() => {
    const newErrors = validate(values);
    setErrors(newErrors);
    const elemsTouched = {};
    Object.keys(values).forEach((i) => {
      elemsTouched[i] = true;
    });
    setTouched(tou => ({ ...tou, ...elemsTouched }));
  }, [validate, values]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const newErrors = validate(values);
    setErrors(newErrors);
    submit();
  };

  const handleInputChange = useCallback((name, value) => {
    setValues(inp => ({ ...inp, [name]: value }));
  }, []);

  return {
    handleSubmit,
    handleInputChange,
    values,
    setValues,
    touched,
    errors,
    setErrors,
    refs,
  };
};

export { useForm };
