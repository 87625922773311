import { TipRuleService } from 'src/services/tipRuleService';
import { reset } from 'src/actions/statusActions';
import { RULE_TRIGGERED_POSTFIX, RULE_STATE } from 'src/constants/rulesEngine';
import { ENABLE_DESIGN_TIPS } from 'src/constants/general';
import createAction from './createAction';

export const GET_RULES = 'GET_RULES';
export const GET_RULES_REQUEST = 'GET_RULES_REQUEST';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_ERROR = 'GET_RULES_ERROR';

export const LOAD_RULES_BY_TYPE = 'LOAD_RULES_BY_TYPE';
export const LOAD_RULES_BY_TYPE_REQUEST = 'LOAD_RULES_BY_TYPE_REQUEST';
export const LOAD_RULES_BY_TYPE_SUCCESS = 'LOAD_RULES_BY_TYPE_SUCCESS';
export const LOAD_RULES_BY_TYPE_ERROR = 'LOAD_RULES_BY_TYPE_ERROR';
export const LOAD_RULES_BY_TYPE_RESET = 'LOAD_RULES_BY_TYPE_RESET';

export const UPDATE_PROJECT_FACTS = 'UPDATE_PROJECT_FACTS';

export const TRIGGER_EVENT = 'TRIGGER_EVENT';
export const TRIGGER_EVENT_REQUEST = 'TRIGGER_EVENT_REQUEST';
export const TRIGGER_EVENT_SUCCESS = 'TRIGGER_EVENT_SUCCESS';
export const TRIGGER_EVENT_FAILURE = 'TRIGGER_EVENT_FAILURE';
export const TRIGGER_EVENT_ERROR = 'TRIGGER_EVENT_ERROR';
export const TRIGGER_EVENT_RESET = 'TRIGGER_EVENT_RESET';

export const SET_RULE_STATE = 'SET_RULE_STATE';
export const SET_RULE_STATE_REQUEST = 'SET_RULE_STATE_REQUEST';
export const SET_RULE_STATE_SUCCESS = 'SET_RULE_STATE_SUCCESS';
export const SET_RULE_STATE_ERROR = 'SET_RULE_STATE_ERROR';

export const TURN_ENGINE_OFF = 'TURN_ENGINE_OFF';
export const TURN_ENGINE_OFF_REQUEST = 'TURN_ENGINE_OFF_REQUEST';
export const TURN_ENGINE_OFF_SUCCESS = 'TURN_ENGINE_OFF_SUCCESS';
export const TURN_ENGINE_OFF_ERROR = 'TURN_ENGINE_OFF_ERROR';

export const EVENT_HANDLED = 'EVENT_HANDLED';
export const EVENT_HANDLED_SUCCESS = 'EVENT_HANDLED_SUCCESS';

export const SET_DIRTY_FACT = 'SET_DIRTY_FACT';

export const SET_READY_TO_SHOW_DESIGN_TIP = 'SET_READY_TO_SHOW_DESIGN_TIP';

export const ADD_IMAGE_SCORE_FACT = 'ADD_IMAGE_SCORE_FACT';

export const DISCARD_TIP = 'DISCARD_TIP';

export const getRulesRequest = createAction(GET_RULES_REQUEST);
export const getRulesSuccess = createAction(GET_RULES_SUCCESS);
export const getRulesError = createAction(GET_RULES_ERROR);

export const loadRulesByTypeRequest = createAction(LOAD_RULES_BY_TYPE_REQUEST);
export const loadRulesByTypeSuccess = createAction(LOAD_RULES_BY_TYPE_SUCCESS);
export const loadRulesByTypeError = createAction(LOAD_RULES_BY_TYPE_ERROR);
export const loadRulesByTypeReset = createAction(LOAD_RULES_BY_TYPE_RESET);

export const updateProjectFacts = createAction(UPDATE_PROJECT_FACTS);

export const triggerEventRequest = createAction(TRIGGER_EVENT_REQUEST);
export const triggerEventSuccess = createAction(TRIGGER_EVENT_SUCCESS);
export const triggerEventFailure = createAction(TRIGGER_EVENT_FAILURE);
export const triggerEventError = createAction(TRIGGER_EVENT_ERROR);
export const triggerEventReset = createAction(TRIGGER_EVENT_RESET);

export const setRuleStateRequest = createAction(SET_RULE_STATE_REQUEST);
export const setRuleStateSuccess = createAction(SET_RULE_STATE_SUCCESS);
export const setRuleStateError = createAction(SET_RULE_STATE_ERROR);

export const turnEngineOffRequest = createAction(TURN_ENGINE_OFF_REQUEST);
export const turnEngineOffSuccess = createAction(TURN_ENGINE_OFF_SUCCESS);
export const turnEngineOffError = createAction(TURN_ENGINE_OFF_ERROR);

export const eventHandledSuccess = createAction(EVENT_HANDLED_SUCCESS);

export const setDirtyFact = createAction(SET_DIRTY_FACT);
export const setReadyToShowDesignTip = createAction(SET_READY_TO_SHOW_DESIGN_TIP);

export const discardTip = createAction(DISCARD_TIP);

export const addImageScoreFact = createAction(ADD_IMAGE_SCORE_FACT);

const successHandler = (event, almanac, ruleResult) => async (dispatch) => {
  try {
    const eventName = ruleResult.event.type;
    const ruleName = eventName.replace(RULE_TRIGGERED_POSTFIX, '');

    dispatch(triggerEventRequest({ ruleName }));
    const projectTipState = await almanac.factValue(
      'tips',
      null,
      `$.${ruleName}.state`,
    );

    if (projectTipState === RULE_STATE.READY_TO_SHOW) {
      const eventTriggererElements = await almanac.factValue(
        'dirtyElements',
        null,
      );
      /* switch by ruleName if we want custom actions for specific rule */
      dispatch(triggerEventSuccess({ ruleName, eventTriggererElements }));
    }
  } catch (err) {
    dispatch(triggerEventError(err));
  }
};

const failureHandler = (event, almanac) => async (dispatch) => {
  const eventName = event.type;
  const ruleName = eventName.replace(RULE_TRIGGERED_POSTFIX, '');

  const eventTriggererElements = await almanac.factValue(
    'dirtyElements',
    null,
  );

  dispatch(triggerEventFailure({ ruleName, eventTriggererElements }));
};

export const getRules = () => async (dispatch, getState) => {
  if (!ENABLE_DESIGN_TIPS) {
    return;
  }

  try {
    const { rulesEngine: { rules } } = getState();
    if (!rules) {
      dispatch(getRulesRequest());
      const { data } = await TipRuleService.getAllRules();
      dispatch(getRulesSuccess(data));
    }
  } catch (error) {
    dispatch(getRulesError(error?.data?.message));
  }
};

export const loadRulesByType = type => async (dispatch, getState) => {
  try {
    const { rulesEngine: { rules } } = getState();
    dispatch(loadRulesByTypeRequest());

    dispatch(loadRulesByTypeSuccess({
      rules: rules[type],
      successHandler: (event, almanac, ruleResult) => {
        dispatch(successHandler(event, almanac, ruleResult));
      },
      failureHandler: (event, almanac, ruleResult) => {
        dispatch(failureHandler(event, almanac, ruleResult));
      },
    }));
  } catch (err) {
    dispatch(loadRulesByTypeError(err));
  }
};

export const eventHandled = () => async dispatch => {
  dispatch(reset(TRIGGER_EVENT));
  dispatch(eventHandledSuccess());
};

export const setRuleState = (ruleName, ruleState) => async dispatch => {
  try {
    if (!Object.values(RULE_STATE).includes(ruleState)) {
      throw new Error(`Invalid state: ${ruleState}`);
    }

    dispatch(setRuleStateRequest());
    dispatch(eventHandled());
    dispatch(setRuleStateSuccess({ ruleName, ruleState }));
  } catch (err) {
    dispatch(setRuleStateError(err));
  }
};

export const turnEngineOff = () => async dispatch => {
  try {
    dispatch(turnEngineOffRequest());
    dispatch(eventHandled());
    dispatch(turnEngineOffSuccess());
  } catch (err) {
    dispatch(turnEngineOffError(err));
  }
};
