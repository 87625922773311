import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import {
  RE_GENERATE_COLLAGE,
  answerToChat,
  keepGeneratedCollage,
  reGenerateCollage,
} from 'src/actions/collageActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import { changeControlOpen } from 'src/actions/canvasActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { useLoading, useProjectSelector, useWindowSize } from 'src/hooks';
import { AnswerToChat } from '../answer-to-chat';
import styles from './RegenerateCollageOption.module.scss';

const RegenerateCollageOption = ({
  answer,
  containerClassName,
  answerClassName,
  keepLabel,
  showLabel,
}) => {
  const dispatch = useDispatch();

  const { id } = useProjectSelector();

  const loadingShowNewDesign = useLoading(RE_GENERATE_COLLAGE);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const onKeepCollage = useCallback(async () => {
    await dispatch(keepGeneratedCollage(id));
    if (isMobile) {
      dispatch(changeControlOpen(''));
    }
    dispatch(answerToChat({
      answer: 'Keep the design',
      messages: [
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Awesome, nice collage! Can\'t wait to see it posted.',
          id: uuidv4(),
        },
        {
          type: COLLAGE_CHAT_TYPE.FINISH_ANSWER,
          id: uuidv4(),
        },
      ],
    }));
  }, [dispatch, id, isMobile]);

  const showNewDesign = useCallback(() => {
    dispatch(reGenerateCollage(id));
  }, [dispatch, id]);

  return (
    <div className={cn(styles.container, containerClassName)}>
      <AnswerToChat
        text={keepLabel || 'Keep the design'}
        onClick={onKeepCollage}
        answer={answer}
        className={answerClassName}
      />
      <AnswerToChat
        text={showLabel || 'Show me a new one'}
        onClick={showNewDesign}
        loading={loadingShowNewDesign}
        answer={answer}
        className={answerClassName}
      />
    </div>
  );
};

RegenerateCollageOption.propTypes = {
  answer: string,
  containerClassName: string,
  answerClassName: string,
  keepLabel: string,
  showLabel: string,
};

export { RegenerateCollageOption };
