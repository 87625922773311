import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { useWindowSize } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';

const useAmountOfTemplatesInARow = () => {
  const [amount, setAmount] = useState();

  const { windowSize } = useWindowSize();

  const { pathname } = useLocation();

  useEffect(() => {
    const mobileAmount = 2;
    const desktopAmount = pathname === routesPaths.templates ? 4 : 2;
    if (amount !== mobileAmount && windowSize.width <= SMALL_WIDTH) {
      setAmount(mobileAmount);
    } else if (amount !== desktopAmount && windowSize.width > SMALL_WIDTH) {
      setAmount(desktopAmount);
    }
  }, [amount, pathname, windowSize.width]);

  return { amountOfTemplatesInARow: amount };
};

export { useAmountOfTemplatesInARow };
