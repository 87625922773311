import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import {
  BRAND_ASSETS_STATUS,
  ENABLE_SUGGESTION_UPGRADE_BANNER,
} from 'src/constants/general';
import { getSuggestions, GET_SUGGESTIONS, setShowSuggestions } from 'src/actions/insightActions';
import { reset } from 'src/actions/statusActions';
import { useSession, useDelayUnmount, useWindowSize } from 'src/hooks';
import { getUserNameToShow } from 'src/utils/helpers';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { Slider } from 'src/common/slider';
import { SuggestionCard } from './suggestion-card';
import { LoadingCard } from './loading-card';
import styles from './Suggestions.module.scss';

const ANIMATION_DURATION = 300;

const Suggestions = () => {
  const dispatch = useDispatch();
  const { user, accountId, account } = useSession();
  const { brandAssetStatus } = account || {};

  const { showSuggestions, suggestions } = useSelector(({ insights }) => ({
    showSuggestions: insights.showSuggestions,
    suggestions: insights.suggestions,
  }));

  const { windowSize } = useWindowSize();

  const suggestionsAvailable = showSuggestions &&
    brandAssetStatus === BRAND_ASSETS_STATUS.DONE &&
    ENABLE_SUGGESTION_UPGRADE_BANNER;

  useEffect(() => {
    if (suggestionsAvailable) {
      dispatch(getSuggestions(accountId));
    }

    return () => {
      dispatch(reset(GET_SUGGESTIONS));
    };
  }, [dispatch, accountId, suggestionsAvailable]);

  const close = useCallback(() => {
    dispatch(setShowSuggestions(false));
  }, [dispatch]);

  const shouldRender = useDelayUnmount(suggestionsAvailable, ANIMATION_DURATION);

  const animationStyles = suggestionsAvailable ? styles.mount : styles.unmount;

  const suggestionCards = useMemo(() => {
    if (suggestions?.length) {
      return suggestions.map(suggestion => (
        <SuggestionCard {...suggestion} key={suggestion.id} />
      ));
    }
    const amountOfLoadingCards = windowSize.width > SMALL_WIDTH ? 3 : 1;
    return Array.from({ length: amountOfLoadingCards }, (v, i) => i).map((index) => (
      <LoadingCard key={index} />
    ));
  }, [suggestions, windowSize.width]);

  return shouldRender && (
    <div className={cn(styles.container, animationStyles)}>
      <button className={styles.close} onClick={close}>
        <img src={CloseWhiteIcon} alt="Close suggestions" />
      </button>
      <span className={styles.text}>
        {suggestions?.length ?
          `Templates with your brand in mind, ${getUserNameToShow(user, false)}.` :
          'We\'re gathering some templates for your brand ...'}
      </span>
      {windowSize.width > SMALL_WIDTH ? (
        <div className={styles.content}>
          {suggestionCards}
        </div>
      ) : (
        <div className={styles.content}>
          <Slider
            slides={suggestionCards}
            dots={false}
            showDisabledArrow={false}
            adaptiveHeight
          />
        </div>
      )}
    </div>
  );
};

export { Suggestions };
