import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateElement } from 'src/actions/projectActions';
import {
  CANVAS_SELECTION_ID,
  CANVAS_DRAWING_LAYER,
  CANVAS_SELECT_ALL_ID,
  CANVAS_SCROLL_ID,
  CANVAS_ADDING_ELEMENT,
} from 'src/constants/general';
import { ALIGNMENT_GUIDE_NAME } from 'src/utils/alignmentGuidesHelpers';
import {
  useShiftAddElements,
  useProjectSelector,
  usePrevious,
  useInterval,
  useKeyboardListener,
} from 'src/hooks';
import { SPACE_CHARACTER_CODE } from 'src/constants/keyboardCodes';

const useMoveTransformElement = (
  selectedElementId,
  uuid,
  refElement,
  selectedRef,
  isText = false,
) => {
  const dispatch = useDispatch();

  const { addElementRequest } = useShiftAddElements(refElement);

  const { elements } = useProjectSelector();

  const canvasDraggingShortcut = useKeyboardListener(SPACE_CHARACTER_CODE);

  const moveElementRequest = useCallback(({ target, evt }) => {
    const { attrs } = target;
    const newAttrs = { uuid: attrs.id, x: attrs.x, y: attrs.y };
    dispatch(updateElement(newAttrs, evt?.timeStamp));
  }, [dispatch]);

  const destroyAlignmentGuides = useCallback(() => {
    if (!refElement?.current) {
      return;
    }
    const drawingLayer = refElement.current.findAncestor(`#${CANVAS_DRAWING_LAYER}`);
    const guides = drawingLayer.find(`.${ALIGNMENT_GUIDE_NAME}`);
    guides.forEach(guide => {
      guide.destroy();
    });
  }, [refElement]);

  const transformElementRequest = useCallback(({ target, evt }) => {
    const { attrs } = target;
    const newAttrs = {
      uuid: attrs.id,
      rotation: attrs.rotation,
      scaleX: attrs.scaleX,
      scaleY: attrs.scaleY,
      x: attrs.x,
      y: attrs.y,
    };
    dispatch(updateElement(newAttrs, evt.timeStamp));
    destroyAlignmentGuides();
  }, [destroyAlignmentGuides, dispatch]);

  const selectElementRequest = useCallback(e => {
    const { target: { attrs }, origin, keepEditableText = false } = e;
    const wasFiredInCode = origin === CANVAS_SELECTION_ID || origin === CANVAS_SELECT_ALL_ID ||
      origin === CANVAS_ADDING_ELEMENT;
    addElementRequest(attrs.id, keepEditableText, wasFiredInCode);
  }, [addElementRequest]);

  const initScrollMovement = { x: 0, y: 0 };
  const [intervalDelay, setIntervalDelay] = useState(null);
  const [scrollMovement, setScrollMovement] = useState(initScrollMovement);

  useInterval(() => {
    const scrollContainer = document.getElementById(CANVAS_SCROLL_ID);
    if (scrollMovement.x) {
      scrollContainer.scrollLeft += scrollMovement.x;
    }
    if (scrollMovement.y) {
      scrollContainer.scrollTop += scrollMovement.y;
    }
  }, intervalDelay);

  const onDragMoveScroll = (e) => {
    const clientRect = e.target.getClientRect();
    const scrollContainer = document.getElementById(CANVAS_SCROLL_ID);
    const defaultIntervalDelay = 60;
    const amountToScroll = 10;
    const nextScrollMovement = initScrollMovement;
    let startToScroll = true;
    if (clientRect.x < -clientRect.width / 2) {
      nextScrollMovement.x = -amountToScroll;
    } else if (clientRect.y < -clientRect.height / 2) {
      nextScrollMovement.y = -amountToScroll;
    } else if (clientRect.x + clientRect.width / 2 > scrollContainer.offsetWidth) {
      nextScrollMovement.x = amountToScroll;
    } else if (clientRect.y + clientRect.height / 2 > scrollContainer.offsetHeight) {
      nextScrollMovement.y = amountToScroll;
    } else {
      startToScroll = false;
    }
    if (startToScroll) {
      setIntervalDelay(defaultIntervalDelay);
    } else {
      setIntervalDelay(null);
    }
    setScrollMovement(nextScrollMovement);
  };

  const onDragEndScroll = () => {
    if (intervalDelay) {
      setIntervalDelay(null);
    }
  };

  const allowDraggingElement = (e) => {
    const isSelected = selectedElementId === uuid;
    if ((!isSelected && e.evt.type === 'touchmove') || canvasDraggingShortcut) {
      e.target.stopDrag();
      const dawingLayer = e.target.getStage().findOne(`#${CANVAS_DRAWING_LAYER}`);
      dawingLayer.startDrag();
      return false;
    }
    return true;
  };

  const prevAmountOfElements = usePrevious(elements.length);

  useEffect(() => {
    // Select element when added to the canvas for the first time
    if (!prevAmountOfElements || (prevAmountOfElements > elements.length)) {
      return;
    }
    if (selectedElementId === uuid && !selectedRef?.current && refElement?.current) {
      selectElementRequest({
        target: { attrs: { id: uuid } },
        origin: isText ? null : CANVAS_ADDING_ELEMENT,
        keepEditableText: isText,
      });
    }
  }, [
    elements.length,
    isText,
    prevAmountOfElements,
    refElement,
    selectElementRequest,
    selectedElementId,
    selectedRef,
    uuid,
  ]);

  return {
    moveElementRequest,
    transformElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
    destroyAlignmentGuides,
  };
};

export { useMoveTransformElement };
