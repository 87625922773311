import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { Button } from 'src/common/button';
import { useSession } from 'src/hooks';
import { setCurrentBrandAssetStatus } from 'src/actions/accountActions';
import { routesPaths } from 'src/routes/routesPaths';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import styles from './WebsiteScrapped.module.scss';

const WebsiteScrapped = ({ closeEditWebsiteModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountId } = useSession();

  const goToBrandLibrary = useCallback(async () => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.DONE,
      id: accountId,
    };
    await dispatch(setCurrentBrandAssetStatus(params));
    history.push(routesPaths.brandLibrary);
  }, [dispatch, history, accountId]);

  return (
    <div className={styles.content}>
      <span role="img" aria-label="" className={styles.emoji}>✨</span>
      <span className={styles.title}>
        Ta-da!
      </span>
      <span className={styles.text}>
        Your Brand Library is ready to be previewed.
      </span>
      <div className={styles.actions}>
        <Button
          className={styles.actionButton}
          onClick={goToBrandLibrary}
        >
          Preview the Brand Library
        </Button>
        <Button
          className={styles.dismissButton}
          secondary
          onClick={closeEditWebsiteModal}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

WebsiteScrapped.propTypes = {
  closeEditWebsiteModal: func.isRequired,
};

export { WebsiteScrapped };
