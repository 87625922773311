import { AccountService } from 'src/services/accountService';
import { ProjectService } from 'src/services/projectService';
import { trackUserPosition, trackProjectsInRadius } from 'src/utils/analytics';
import createAction from './createAction';

export const FIND_ACCOUNT_BY_NAMES = 'FIND_ACCOUNT_BY_NAMES';
export const FIND_ACCOUNT_BY_NAMES_SUCCESS = 'FIND_ACCOUNT_BY_NAMES_SUCCESS';
export const FIND_ACCOUNT_BY_NAMES_REQUEST = 'FIND_ACCOUNT_BY_NAMES_REQUEST';
export const FIND_ACCOUNT_BY_NAMES_ERROR = 'FIND_ACCOUNT_BY_NAMES_ERROR';

export const FIND_ACCOUNT_BY_IDS = 'FIND_ACCOUNT_BY_IDS';
export const FIND_ACCOUNT_BY_IDS_SUCCESS = 'FIND_ACCOUNT_BY_IDS_SUCCESS';
export const FIND_ACCOUNT_BY_IDS_REQUEST = 'FIND_ACCOUNT_BY_IDS_REQUEST';
export const FIND_ACCOUNT_BY_IDS_ERROR = 'FIND_ACCOUNT_BY_IDS_ERROR';

export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS';
export const GET_CURRENT_LOCATION_REQUEST = 'GET_CURRENT_LOCATION_REQUEST';
export const GET_CURRENT_LOCATION_ERROR = 'GET_CURRENT_LOCATION_ERROR';

export const GET_PROJECTS_WITHIN = 'GET_PROJECTS_WITHIN';
export const GET_PROJECTS_WITHIN_SUCCESS = 'GET_PROJECTS_WITHIN_SUCCESS';
export const GET_PROJECTS_WITHIN_REQUEST = 'GET_PROJECTS_WITHIN_REQUEST';
export const GET_PROJECTS_WITHIN_ERROR = 'GET_PROJECTS_WITHIN_ERROR';

export const GET_ALL_PUBLIC_PROJECTS = 'GET_ALL_PUBLIC_PROJECTS';
export const GET_ALL_PUBLIC_PROJECTS_SUCCESS = 'GET_ALL_PUBLIC_PROJECTS_SUCCESS';
export const GET_ALL_PUBLIC_PROJECTS_REQUEST = 'GET_ALL_PUBLIC_PROJECTS_REQUEST';
export const GET_ALL_PUBLIC_PROJECTS_ERROR = 'GET_ALL_PUBLIC_PROJECTS_ERROR';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';

export const findAccountByNamesRequest = createAction(FIND_ACCOUNT_BY_NAMES_REQUEST);
export const findAccountByNamesSuccess = createAction(FIND_ACCOUNT_BY_NAMES_SUCCESS);
export const findAccountByNamesError = createAction(FIND_ACCOUNT_BY_NAMES_ERROR);

export const findAccountByIdsRequest = createAction(FIND_ACCOUNT_BY_IDS_REQUEST);
export const findAccountByIdsSuccess = createAction(FIND_ACCOUNT_BY_IDS_SUCCESS);
export const findAccountByIdsError = createAction(FIND_ACCOUNT_BY_IDS_ERROR);

export const getCurrentLocationRequest = createAction(GET_CURRENT_LOCATION_REQUEST);
export const getCurrentLocationSuccess = createAction(GET_CURRENT_LOCATION_SUCCESS);
export const getCurrentLocationError = createAction(GET_CURRENT_LOCATION_ERROR);

export const getProjectsWithinRequest = createAction(GET_PROJECTS_WITHIN_REQUEST);
export const getProjectsWithinSuccess = createAction(GET_PROJECTS_WITHIN_SUCCESS);
export const getProjectsWithinError = createAction(GET_PROJECTS_WITHIN_ERROR);

export const getAllPublicProjectsRequest = createAction(GET_ALL_PUBLIC_PROJECTS_REQUEST);
export const getAllPublicProjectsSuccess = createAction(GET_ALL_PUBLIC_PROJECTS_SUCCESS);
export const getAllPublicProjectsError = createAction(GET_ALL_PUBLIC_PROJECTS_ERROR);

export const setSelectedProject = createAction(SET_SELECTED_PROJECT);

const GET_CURRENT_POSITION_TIMEOUT = 5000;

const getCurrentPosition = (
  timeoutInMs = Infinity,
  enableHighAccuracy = true,
) => new Promise((resolve, reject) => {
  try {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({
          latitude: coords.latitude,
          longitude: coords.longitude,
          accuracy: coords.accuracy,
        });
      },
      (err) => reject(err),
      {
        enableHighAccuracy,
        timeout: timeoutInMs,
        maximumAge: 0, // don't use cached position
      },
    );
  } catch (err) {
    reject(err);
  }
});

export const findAccountByNames = (username, brandName) => async dispatch => {
  try {
    dispatch(findAccountByNamesRequest());
    const { data } = await AccountService.findByNames(username, brandName);
    dispatch(findAccountByNamesSuccess(data));
  } catch (data) {
    dispatch(findAccountByNamesError(data?.message));
  }
};

export const findAccountByIds = (userId, accountId) => async dispatch => {
  try {
    dispatch(findAccountByIdsRequest());
    const { data } = await AccountService.findByIds(userId, accountId);
    dispatch(findAccountByIdsSuccess(data));
  } catch (data) {
    dispatch(findAccountByIdsError(data?.message));
  }
};

export const getCurrentLocation = () => async dispatch => {
  try {
    dispatch(getCurrentLocationRequest());

    let data;
    try {
      data = await getCurrentPosition(GET_CURRENT_POSITION_TIMEOUT);
    } catch (err) {
      const timeoutErrorCode = 3;
      if (err.code === timeoutErrorCode) {
        data = await getCurrentPosition(GET_CURRENT_POSITION_TIMEOUT, false);
      } else {
        throw err;
      }
    }

    dispatch(getCurrentLocationSuccess(data));

    trackUserPosition(data.latitude, data.longitude, data.accuracy);
  } catch (err) {
    dispatch(getCurrentLocationError(err));
  }
};

export const getProjectsWithin = (accountId, lat, long, radius) => async dispatch => {
  try {
    dispatch(getProjectsWithinRequest());
    const { data: { projects } } =
      await ProjectService.getProjectsWithin(accountId, lat, long, radius);
    dispatch(getProjectsWithinSuccess(projects));

    trackProjectsInRadius(projects.map(({ id }) => id), radius);
  } catch (error) {
    dispatch(getProjectsWithinError(error?.data?.message));
  }
};

export const getAllPublicProjects = (accountId, lat, long) => async dispatch => {
  try {
    dispatch(getAllPublicProjectsRequest());
    const { data: { projects } } =
      await ProjectService.getAllPublicProjects(accountId, lat, long);
    dispatch(getAllPublicProjectsSuccess(projects));
  } catch (error) {
    dispatch(getAllPublicProjectsError(error?.data?.message));
  }
};
