import { useDispatch } from 'react-redux';

import { openRightClickOptions } from 'src/actions/canvasActions';
import { RIGHT_CLICK_CODE, LEFT_CLICK_CODE } from 'src/constants/keyboardCodes';
import {
  CANVAS_SELECTION_ID,
  CANVAS_SELECT_ALL_ID,
  CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT_FOR_IMAGES,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
} from 'src/constants/general';
import { isRightClick } from 'src/utils/helpers';
import { getRightOptionsPosition } from 'src/utils/canvasHelpers';

const useOnClickElement = (isInGroup, isNotSelected, selectElement, isImage = false) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    if (!isInGroup) {
      if (e.origin === CANVAS_SELECTION_ID || e.origin === CANVAS_SELECT_ALL_ID ||
        e.type === 'tap' || e.evt?.button === LEFT_CLICK_CODE ||
        (e.evt?.button === RIGHT_CLICK_CODE && isNotSelected)) {
        selectElement(e);
      }
      if (isRightClick(e.evt)) {
        const optionsHeight = isImage ?
          CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT_FOR_IMAGES :
          CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT;
        const pos = getRightOptionsPosition(
          e.evt,
          optionsHeight,
          CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
        );
        dispatch(openRightClickOptions({
          ...pos,
          type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT,
        }));
      }
    }
  };

  return { onClick };
};

export { useOnClickElement };
