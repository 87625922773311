import React, { useCallback } from 'react';
import { func, bool } from 'prop-types';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import styles from './ChangeUsernameModal.module.scss';

const ChangeUsernameModal = ({ isShowing, hide, update }) => {
  const updateAndHide = useCallback(() => {
    update();
    hide();
  }, [hide, update]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>Confirm Username Change</span>
        <span className={styles.subtitle}>
          When you change your username, your public Stagger URL will change.&nbsp;
          Be sure to update your profile URL in other places you have it posted.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={updateAndHide}
            alternativeCTA
          >
            Change My Username
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ChangeUsernameModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  update: func.isRequired,
};

export { ChangeUsernameModal };
