import React from 'react';
import { func, number, object } from 'prop-types';

import { useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { RadioButton } from 'src/common/radio-button';
import { KEEP_PROJECT_IN_DOWNGRADE, DELETE_PROJECT_IN_DOWNGRADE } from 'src/constants/general';
import styles from './DeleteProjectsChooser.module.scss';

const DeleteProjectsChooser = ({
  onClickNext,
  onClickCancel,
  mainAccountId,
  accountIdsActions,
  setAccountIdsActions,
}) => {
  const { user } = useSession();

  const accounts = user.membership.accounts.filter(acc => acc.id !== mainAccountId);

  return (
    <>
      <span className={styles.title}>Do you want to keep or delete your projects?</span>
      <div className={styles.content}>
        <span className={styles.header}>
          Choose to keep or delete your other account&apos;s projects.
        </span>
        {accounts.map(account => (
          <div className={styles.card} key={account.id}>
            <span className={styles.brandName}>{account.brandName}</span>
            <div className={styles.radiobuttons}>
              <RadioButton
                onChange={() => setAccountIdsActions(ids => (
                  { ...ids, [account.id]: KEEP_PROJECT_IN_DOWNGRADE }
                ))}
                isSelected={accountIdsActions[account.id] === KEEP_PROJECT_IN_DOWNGRADE}
                label="Keep projects"
                value={account.id.toString()}
                className={styles.radiobutton}
              />
              <RadioButton
                onChange={() => setAccountIdsActions(ids => (
                  { ...ids, [account.id]: DELETE_PROJECT_IN_DOWNGRADE }
                ))}
                isSelected={accountIdsActions[account.id] === DELETE_PROJECT_IN_DOWNGRADE}
                label="Delete projects"
                value={account.id.toString()}
                className={styles.radiobutton}
              />
            </div>
          </div>
        ))}
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onClickCancel}
            secondary
          >
            Back
          </Button>
          <Button
            className={styles.nextButton}
            onClick={onClickNext}
            disabled={Object.keys(accountIdsActions).length < accounts.length}
            alternativeCTA
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

DeleteProjectsChooser.propTypes = {
  onClickNext: func.isRequired,
  onClickCancel: func.isRequired,
  mainAccountId: number.isRequired,
  accountIdsActions: object,
  setAccountIdsActions: func.isRequired,
};

export { DeleteProjectsChooser };
