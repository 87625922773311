/* eslint-disable max-classes-per-file */

export class NoPagesError extends Error {
  constructor() {
    const message = 'Can\'t access any page on which you have a role';
    super(message);
    this.name = 'No pages';
    this.message = message;
  }
}

export class OnePageSupportError extends Error {
  constructor() {
    const message = 'You choose more than one FB pages on login. Logout/login to select only one';
    super(message);
    this.name = 'Only one page supported';
    this.message = message;
  }
}

export class NoIGBusinessAccountError extends Error {
  constructor() {
    const message =
      'You must have an Instagram Business Account associated with the Facebook page you selected';
    super(message);
    this.name = 'No IG Business Account';
    this.message = message;
  }
}

export class MediaRetrieveError extends Error {
  constructor() {
    const message = 'Something goes wrong retrieving your Instagram media';
    super(message);
    this.name = 'Can\'t retrieve media';
    this.message = message;
  }
}
