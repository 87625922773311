import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSession } from 'src/hooks';
import { ToggleSwitch } from 'src/common/toggle-switch';
import { setDesignTipsState } from 'src/actions/userSettingsActions';
import styles from './TipSettings.module.scss';

const TipSettings = () => {
  const dispatch = useDispatch();
  const { user } = useSession();

  const [checked, setChecked] = useState(user.settings.showDesignTips);

  const handleSubmit = useCallback(() => {
    dispatch(setDesignTipsState(!checked));
    setChecked(!checked);
  }, [checked, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.headerInfo}>Design tips</div>
      <div className={styles.enableSwitch}>
        <span className={styles.text}>Enable to activate the deisgn tips</span>
        <ToggleSwitch checked={checked} onChange={handleSubmit} />
      </div>
    </div>
  );
};

export { TipSettings };
