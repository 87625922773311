import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { toast } from 'react-toastify';

import { toastErrorConfig } from 'src/constants/toastConfig';
import { FONT_SIZE_OPTIONS } from 'src/constants/fonts';
import { CREATE_FONT_SIZE_PREFIX } from 'src/constants/general';
import { SelectCreator } from 'src/common/select-creator';
import styles from './FontSizeSelect.module.scss';

const SELECT_STYLES = {
  control: base => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 0,
  }),
  singleValue: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  input: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  menu: base => ({ ...base, color: '#555555', fontSize: '1.4rem' }),
  option: (base, state) => {
    if (state.data.label.startsWith(CREATE_FONT_SIZE_PREFIX)) {
      return { ...base, lineHeight: '1.6' };
    }
    return base;
  },
};

const MAX_FONT_SIZE = 400;

const FontSizeSelect = ({
  disabled = false,
  onChange,
  selectStyles = SELECT_STYLES,
  fontSize,
  className = '',
}) => {
  const handleFontSizeChange = ({ value }) => {
    const valueString = value.toString().replace('px', '');
    const valueNumber = +valueString;
    if (Number.isInteger(valueNumber) && valueNumber > 0 && valueNumber < MAX_FONT_SIZE) {
      onChange(valueNumber);
    } else if (Number.isInteger(valueNumber) && valueNumber > 0 && valueNumber >= MAX_FONT_SIZE) {
      toast.error(`Font size must be less than ${MAX_FONT_SIZE}px`, toastErrorConfig);
    }
  };

  return (
    <SelectCreator
      options={FONT_SIZE_OPTIONS}
      value={disabled ?
        FONT_SIZE_OPTIONS[7] :
        FONT_SIZE_OPTIONS.find(elem => elem.value === fontSize) ||
        { value: fontSize, label: `${fontSize}px` }}
      onChange={handleFontSizeChange}
      containerClassName={styles.fontSize}
      selectStyles={selectStyles}
      disabled={disabled}
      formatCreateLabel={value => `${CREATE_FONT_SIZE_PREFIX} ${value}`}
      className={className}
    />
  );
};

FontSizeSelect.propTypes = {
  disabled: bool,
  onChange: func.isRequired,
  selectStyles: object,
  fontSize: number,
  className: string,
};

export { FontSizeSelect };
