import React from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { setEyeDropperActive, setEyeDropperInactive } from 'src/actions/canvasActions';
import EyedropperIcon from 'src/assets/icons/eye-dropper.svg';
import EyedropperActiveIcon from 'src/assets/icons/eye-dropper-white.svg';
import { useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import styles from './CanvasEyedropper.module.scss';

const CanvasEyedropper = ({ source }) => {
  const dispatch = useDispatch();

  const { eyeDropper } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
  }));

  const onClick = () => {
    if (!eyeDropper.active) {
      dispatch(setEyeDropperActive({ source }));
    } else {
      dispatch(setEyeDropperInactive());
    }
  };

  const onCancel = () => {
    dispatch(setEyeDropperInactive());
  };

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  if (isMobile) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.divider} />
      <div className={styles.content}>
        <button
          className={cn(styles.button, { [styles.active]: eyeDropper.active })}
          onClick={onClick}
        >
          <img
            src={eyeDropper.active ? EyedropperActiveIcon : EyedropperIcon}
            alt="Eyedropper"
          />
        </button>
        {eyeDropper.active && (
          <button
            className={styles.cancelButton}
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

CanvasEyedropper.propTypes = {
  source: string.isRequired,
};

export { CanvasEyedropper };
