import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string } from 'prop-types';

import {
  answerToChat,
  askForCollageFromChat,
} from 'src/actions/collageActions';
import { DropdownAnswerToChat } from '../dropdown-answer-to-chat';
import { AnswerToChat } from '../answer-to-chat';
import styles from './AmountCanvasesCollage.module.scss';

const AmountCanvasesCollage = ({ answer, selectedValue }) => {
  const dispatch = useDispatch();

  const { chatMedia } = useSelector(({ collage }) => ({
    chatMedia: collage.chatMedia,
  }));

  const sendAmount = useCallback((selectedAmount) => {
    dispatch(answerToChat({ answer: 'Choose an amount', selectedValue: selectedAmount }));
    dispatch(askForCollageFromChat({ selectedAmount }));
  }, [dispatch]);

  const chooseForMe = useCallback(() => {
    dispatch(answerToChat({ answer: 'I\'m not sure, choose for me' }));
    dispatch(askForCollageFromChat({}));
  }, [dispatch]);

  const amountOptions = useMemo(() => {
    const media = Object.values(chatMedia).flatMap(m => m.media);
    const options = [
      { text: '2', action: () => sendAmount(2) },
      { text: '3', action: () => sendAmount(3) },
      { text: '4', action: () => sendAmount(4) },
      { text: '5', action: () => sendAmount(5) },
      { text: '6', action: () => sendAmount(6) },
      { text: '7', action: () => sendAmount(7) },
      { text: '8', action: () => sendAmount(8) },
    ];
    if (media.length === 2) {
      options.unshift({ text: '1', action: () => sendAmount(1) });
    }
    return options;
  }, [chatMedia, sendAmount]);

  return (
    <div className={styles.container}>
      <DropdownAnswerToChat
        text="Choose an amount"
        onSelect={sendAmount}
        answer={answer}
        options={amountOptions}
        selectedValue={selectedValue}
      />
      <AnswerToChat
        text="I'm not sure, choose for me"
        onClick={chooseForMe}
        answer={answer}
      />
    </div>
  );
};

AmountCanvasesCollage.propTypes = {
  answer: string,
  selectedValue: number,
};

export { AmountCanvasesCollage };
