import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

import { UserService } from 'src/services/userService';
import { OnboardingService } from 'src/services/onboardingService';
import { AccountService } from 'src/services/accountService';
import { AccountInviteService } from 'src/services/accountInviteService';
import { TeamService } from 'src/services/teamService';
import { trackWebsiteScraped, trackWebsiteScrapeRequested } from 'src/utils/analytics';
import {
  BRAND_ASSETS_STATUS,
  HTTP_STATUS,
  KEEP_PROJECT_IN_DOWNGRADE,
  FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS,
} from 'src/constants/general';
import { serializeUserAccounts } from 'src/utils/helpers';
import { toastSuccessConfig } from 'src/constants/toastConfig';
import createAction from './createAction';

export const PROCESS_WEBSITE = 'PROCESS_WEBSITE';
export const PROCESS_WEBSITE_SUCCESS = 'PROCESS_WEBSITE_SUCCESS';
export const PROCESS_WEBSITE_REQUEST = 'PROCESS_WEBSITE_REQUEST';
export const PROCESS_WEBSITE_ERROR = 'PROCESS_WEBSITE_ERROR';

export const SET_CURRENT_BRAND_ASSET_STATUS = 'SET_CURRENT_BRAND_ASSET_STATUS';
export const SET_CURRENT_BRAND_ASSET_STATUS_SUCCESS = 'SET_CURRENT_BRAND_ASSET_STATUS_SUCCESS';
export const SET_CURRENT_BRAND_ASSET_STATUS_REQUEST = 'SET_CURRENT_BRAND_ASSET_STATUS_REQUEST';
export const SET_CURRENT_BRAND_ASSET_STATUS_ERROR = 'SET_CURRENT_BRAND_ASSET_STATUS_ERROR';

export const FILL_BRAND_LIBRARY_FROM_WEBSITE = 'FILL_BRAND_LIBRARY_FROM_WEBSITE';
export const FILL_BRAND_LIBRARY_FROM_WEBSITE_SUCCESS = 'FILL_BRAND_LIBRARY_FROM_WEBSITE_SUCCESS';
export const FILL_BRAND_LIBRARY_FROM_WEBSITE_REQUEST = 'FILL_BRAND_LIBRARY_FROM_WEBSITE_REQUEST';
export const FILL_BRAND_LIBRARY_FROM_WEBSITE_ERROR = 'FILL_BRAND_LIBRARY_FROM_WEBSITE_ERROR';

export const RE_PROCESS_WEBSITE = 'RE_PROCESS_WEBSITE';
export const RE_PROCESS_WEBSITE_SUCCESS = 'RE_PROCESS_WEBSITE_SUCCESS';
export const RE_PROCESS_WEBSITE_REQUEST = 'RE_PROCESS_WEBSITE_REQUEST';
export const RE_PROCESS_WEBSITE_ERROR = 'RE_PROCESS_WEBSITE_ERROR';

export const UPDATE_AND_PROCESS_WEBSITE = 'UPDATE_AND_PROCESS_WEBSITE';
export const UPDATE_AND_PROCESS_WEBSITE_SUCCESS = 'UPDATE_AND_PROCESS_WEBSITE_SUCCESS';
export const UPDATE_AND_PROCESS_WEBSITE_REQUEST = 'UPDATE_AND_PROCESS_WEBSITE_REQUEST';
export const UPDATE_AND_PROCESS_WEBSITE_ERROR = 'UPDATE_AND_PROCESS_WEBSITE_ERROR';

export const GET_USER_WITH_ACCOUNT = 'GET_USER_WITH_ACCOUNT';
export const GET_USER_WITH_ACCOUNT_SUCCESS = 'GET_USER_WITH_ACCOUNT_SUCCESS';
export const GET_USER_WITH_ACCOUNT_REQUEST = 'GET_USER_WITH_ACCOUNT_REQUEST';
export const GET_USER_WITH_ACCOUNT_ERROR = 'GET_USER_WITH_ACCOUNT_ERROR';

export const GET_USER_FOR_AGENCY_DOWNGRADE = 'GET_USER_FOR_AGENCY_DOWNGRADE';
export const GET_USER_FOR_AGENCY_DOWNGRADE_SUCCESS = 'GET_USER_FOR_AGENCY_DOWNGRADE_SUCCESS';
export const GET_USER_FOR_AGENCY_DOWNGRADE_REQUEST = 'GET_USER_FOR_AGENCY_DOWNGRADE_REQUEST';
export const GET_USER_FOR_AGENCY_DOWNGRADE_ERROR = 'GET_USER_FOR_AGENCY_DOWNGRADE_ERROR';

export const GET_ONBOARDING_INFORMATION = 'GET_ONBOARDING_INFORMATION';
export const GET_ONBOARDING_INFORMATION_SUCCESS = 'GET_ONBOARDING_INFORMATION_SUCCESS';
export const GET_ONBOARDING_INFORMATION_REQUEST = 'GET_ONBOARDING_INFORMATION_REQUEST';
export const GET_ONBOARDING_INFORMATION_ERROR = 'GET_ONBOARDING_INFORMATION_ERROR';

export const SAVE_ONBOARDING_STEP = 'SAVE_ONBOARDING_STEP';
export const SAVE_ONBOARDING_STEP_REQUEST = 'SAVE_ONBOARDING_STEP_REQUEST';
export const SAVE_ONBOARDING_STEP_SUCCESS = 'SAVE_ONBOARDING_STEP_SUCCESS';
export const SAVE_ONBOARDING_STEP_ERROR = 'SAVE_ONBOARDING_STEP_ERROR';

export const GET_ACCOUNT_PROJECTS = 'GET_ACCOUNT_PROJECTS';
export const GET_ACCOUNT_PROJECTS_SUCCESS = 'GET_ACCOUNT_PROJECTS_SUCCESS';
export const GET_ACCOUNT_PROJECTS_REQUEST = 'GET_ACCOUNT_PROJECTS_REQUEST';
export const GET_ACCOUNT_PROJECTS_ERROR = 'GET_ACCOUNT_PROJECTS_ERROR';

export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_REQUEST = 'ADD_ACCOUNT_REQUEST';
export const ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR';

export const UPDATE_ACCOUNT_NAME = 'UPDATE_ACCOUNT_NAME';
export const UPDATE_ACCOUNT_NAME_SUCCESS = 'UPDATE_ACCOUNT_NAME_SUCCESS';
export const UPDATE_ACCOUNT_NAME_REQUEST = 'UPDATE_ACCOUNT_NAME_REQUEST';
export const UPDATE_ACCOUNT_NAME_ERROR = 'UPDATE_ACCOUNT_NAME_ERROR';

export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const DELETE_ACCOUNTS_AGENCY_DOWNGRADE = 'DELETE_ACCOUNTS_AGENCY_DOWNGRADE';
export const DELETE_ACCOUNTS_AGENCY_DOWNGRADE_SUCCESS = 'DELETE_ACCOUNTS_AGENCY_DOWNGRADE_SUCCESS';
export const DELETE_ACCOUNTS_AGENCY_DOWNGRADE_REQUEST = 'DELETE_ACCOUNTS_AGENCY_DOWNGRADE_REQUEST';
export const DELETE_ACCOUNTS_AGENCY_DOWNGRADE_ERROR = 'DELETE_ACCOUNTS_AGENCY_DOWNGRADE_ERROR';

export const DELETE_ACCOUNTS_MOVING_PROJECTS = 'DELETE_ACCOUNTS_MOVING_PROJECTS';
export const DELETE_ACCOUNTS_MOVING_PROJECTS_SUCCESS = 'DELETE_ACCOUNTS_MOVING_PROJECTS_SUCCESS';
export const DELETE_ACCOUNTS_MOVING_PROJECTS_REQUEST = 'DELETE_ACCOUNTS_MOVING_PROJECTS_REQUEST';
export const DELETE_ACCOUNTS_MOVING_PROJECTS_ERROR = 'DELETE_ACCOUNTS_MOVING_PROJECTS_ERROR';

export const MOVE_PROJECTS_AGENCY_DOWNGRADE = 'MOVE_PROJECTS_AGENCY_DOWNGRADE';
export const MOVE_PROJECTS_AGENCY_DOWNGRADE_SUCCESS = 'MOVE_PROJECTS_AGENCY_DOWNGRADE_SUCCESS';
export const MOVE_PROJECTS_AGENCY_DOWNGRADE_REQUEST = 'MOVE_PROJECTS_AGENCY_DOWNGRADE_REQUEST';
export const MOVE_PROJECTS_AGENCY_DOWNGRADE_ERROR = 'MOVE_PROJECTS_AGENCY_DOWNGRADE_ERROR';

export const DOWNGRADE_PLAN_FROM_AGENCY = 'DOWNGRADE_PLAN_FROM_AGENCY';
export const DOWNGRADE_PLAN_FROM_AGENCY_SUCCESS = 'DOWNGRADE_PLAN_FROM_AGENCY_SUCCESS';
export const DOWNGRADE_PLAN_FROM_AGENCY_REQUEST = 'DOWNGRADE_PLAN_FROM_AGENCY_REQUEST';
export const DOWNGRADE_PLAN_FROM_AGENCY_ERROR = 'DOWNGRADE_PLAN_FROM_AGENCY_ERROR';

export const GET_EDITORS_AND_INVITES = 'GET_EDITORS_AND_INVITES';
export const GET_EDITORS_AND_INVITES_SUCCESS = 'GET_EDITORS_AND_INVITES_SUCCESS';
export const GET_EDITORS_AND_INVITES_REQUEST = 'GET_EDITORS_AND_INVITES_REQUEST';
export const GET_EDITORS_AND_INVITES_ERROR = 'GET_EDITORS_AND_INVITES_ERROR';

export const INVITE_TO_ACCOUNTS = 'INVITE_TO_ACCOUNTS';
export const INVITE_TO_ACCOUNTS_SUCCESS = 'INVITE_TO_ACCOUNTS_SUCCESS';
export const INVITE_TO_ACCOUNTS_REQUEST = 'INVITE_TO_ACCOUNTS_REQUEST';
export const INVITE_TO_ACCOUNTS_ERROR = 'INVITE_TO_ACCOUNTS_ERROR';

export const EDIT_EDITOR_ACCOUNTS = 'EDIT_EDITOR_ACCOUNTS';
export const EDIT_EDITOR_ACCOUNTS_SUCCESS = 'EDIT_EDITOR_ACCOUNTS_SUCCESS';
export const EDIT_EDITOR_ACCOUNTS_REQUEST = 'EDIT_EDITOR_ACCOUNTS_REQUEST';
export const EDIT_EDITOR_ACCOUNTS_ERROR = 'EDIT_EDITOR_ACCOUNTS_ERROR';

export const DELETE_EDITOR = 'DELETE_EDITOR';
export const DELETE_EDITOR_SUCCESS = 'DELETE_EDITOR_SUCCESS';
export const DELETE_EDITOR_REQUEST = 'DELETE_EDITOR_REQUEST';
export const DELETE_EDITOR_ERROR = 'DELETE_EDITOR_ERROR';

export const RESEND_INVITE_TO_ACCOUNTS = 'RESEND_INVITE_TO_ACCOUNTS';
export const RESEND_INVITE_TO_ACCOUNTS_SUCCESS = 'RESEND_INVITE_TO_ACCOUNTS_SUCCESS';
export const RESEND_INVITE_TO_ACCOUNTS_REQUEST = 'RESEND_INVITE_TO_ACCOUNTS_REQUEST';
export const RESEND_INVITE_TO_ACCOUNTS_ERROR = 'RESEND_INVITE_TO_ACCOUNTS_ERROR';

export const GET_ACCOUNTS_OF_USER = 'GET_ACCOUNTS_OF_USER';
export const GET_ACCOUNTS_OF_USER_SUCCESS = 'GET_ACCOUNTS_OF_USER_SUCCESS';
export const GET_ACCOUNTS_OF_USER_REQUEST = 'GET_ACCOUNTS_OF_USER_REQUEST';
export const GET_ACCOUNTS_OF_USER_ERROR = 'GET_ACCOUNTS_OF_USER_ERROR';

export const processWebsiteRequest = createAction(PROCESS_WEBSITE_REQUEST);
export const processWebsiteSuccess = createAction(PROCESS_WEBSITE_SUCCESS);
export const processWebsiteError = createAction(PROCESS_WEBSITE_ERROR);

export const setCurrentBrandAssetStatusRequest =
  createAction(SET_CURRENT_BRAND_ASSET_STATUS_REQUEST);
export const setCurrentBrandAssetStatusSuccess =
  createAction(SET_CURRENT_BRAND_ASSET_STATUS_SUCCESS);
export const setCurrentBrandAssetStatusError =
  createAction(SET_CURRENT_BRAND_ASSET_STATUS_ERROR);

export const fillBrandLibraryFromWebsiteRequest =
  createAction(FILL_BRAND_LIBRARY_FROM_WEBSITE_REQUEST);
export const fillBrandLibraryFromWebsiteSuccess =
  createAction(FILL_BRAND_LIBRARY_FROM_WEBSITE_SUCCESS);
export const fillBrandLibraryFromWebsiteError = createAction(FILL_BRAND_LIBRARY_FROM_WEBSITE_ERROR);

export const reProcessWebsiteRequest = createAction(RE_PROCESS_WEBSITE_REQUEST);
export const reProcessWebsiteSuccess = createAction(RE_PROCESS_WEBSITE_SUCCESS);
export const reProcessWebsiteError = createAction(RE_PROCESS_WEBSITE_ERROR);

export const updateAndProcessWebsiteRequest = createAction(UPDATE_AND_PROCESS_WEBSITE_REQUEST);
export const updateAndProcessWebsiteSuccess = createAction(UPDATE_AND_PROCESS_WEBSITE_SUCCESS);
export const updateAndProcessWebsiteError = createAction(UPDATE_AND_PROCESS_WEBSITE_ERROR);

export const getUserWithAccountRequest = createAction(GET_USER_WITH_ACCOUNT_REQUEST);
export const getUserWithAccountSuccess = createAction(GET_USER_WITH_ACCOUNT_SUCCESS);
export const getUserWithAccountError = createAction(GET_USER_WITH_ACCOUNT_ERROR);

export const getUserForAgencyDowngradeRequest = createAction(GET_USER_FOR_AGENCY_DOWNGRADE_REQUEST);
export const getUserForAgencyDowngradeSuccess = createAction(GET_USER_FOR_AGENCY_DOWNGRADE_SUCCESS);
export const getUserForAgencyDowngradeError = createAction(GET_USER_FOR_AGENCY_DOWNGRADE_ERROR);

export const getOnboardingInformationRequest = createAction(GET_ONBOARDING_INFORMATION_REQUEST);
export const getOnboardingInformationSuccess = createAction(GET_ONBOARDING_INFORMATION_SUCCESS);
export const getOnboardingInformationError = createAction(GET_ONBOARDING_INFORMATION_ERROR);

export const saveOnboardingStepRequest = createAction(SAVE_ONBOARDING_STEP_REQUEST);
export const saveOnboardingStepSuccess = createAction(SAVE_ONBOARDING_STEP_SUCCESS);
export const saveOnboardingStepError = createAction(SAVE_ONBOARDING_STEP_ERROR);

export const getAccountProjectsRequest = createAction(GET_ACCOUNT_PROJECTS_REQUEST);
export const getAccountProjectsSuccess = createAction(GET_ACCOUNT_PROJECTS_SUCCESS);
export const getAccountProjectsError = createAction(GET_ACCOUNT_PROJECTS_ERROR);

export const addAccountRequest = createAction(ADD_ACCOUNT_REQUEST);
export const addAccountSuccess = createAction(ADD_ACCOUNT_SUCCESS);
export const addAccountError = createAction(ADD_ACCOUNT_ERROR);

export const updateAccountNameRequest = createAction(UPDATE_ACCOUNT_NAME_REQUEST);
export const updateAccountNameSuccess = createAction(UPDATE_ACCOUNT_NAME_SUCCESS);
export const updateAccountNameError = createAction(UPDATE_ACCOUNT_NAME_ERROR);

export const setAccountId = createAction(SET_ACCOUNT_ID);

export const deleteAccountRequest = createAction(DELETE_ACCOUNT_REQUEST);
export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS);
export const deleteAccountError = createAction(DELETE_ACCOUNT_ERROR);

export const deleteAccountsAgencyDowngradeRequest =
  createAction(DELETE_ACCOUNTS_AGENCY_DOWNGRADE_REQUEST);
export const deleteAccountsAgencyDowngradeSuccess =
  createAction(DELETE_ACCOUNTS_AGENCY_DOWNGRADE_SUCCESS);
export const deleteAccountsAgencyDowngradeError =
  createAction(DELETE_ACCOUNTS_AGENCY_DOWNGRADE_ERROR);

export const deleteAccountsMovingProjectsRequest =
  createAction(DELETE_ACCOUNTS_MOVING_PROJECTS_REQUEST);
export const deleteAccountsMovingProjectsSuccess =
  createAction(DELETE_ACCOUNTS_MOVING_PROJECTS_SUCCESS);
export const deleteAccountsMovingProjectsError =
  createAction(DELETE_ACCOUNTS_MOVING_PROJECTS_ERROR);

export const moveProjectsAgencyDowngradeRequest =
  createAction(MOVE_PROJECTS_AGENCY_DOWNGRADE_REQUEST);
export const moveProjectsAgencyDowngradeSuccess =
  createAction(MOVE_PROJECTS_AGENCY_DOWNGRADE_SUCCESS);
export const moveProjectsAgencyDowngradeError =
  createAction(MOVE_PROJECTS_AGENCY_DOWNGRADE_ERROR);

export const downgradePlanFromAgencyRequest = createAction(DOWNGRADE_PLAN_FROM_AGENCY_REQUEST);
export const downgradePlanFromAgencySuccess = createAction(DOWNGRADE_PLAN_FROM_AGENCY_SUCCESS);
export const downgradePlanFromAgencyError = createAction(DOWNGRADE_PLAN_FROM_AGENCY_ERROR);

export const getEditorsAndInvitesRequest = createAction(GET_EDITORS_AND_INVITES_REQUEST);
export const getEditorsAndInvitesSuccess = createAction(GET_EDITORS_AND_INVITES_SUCCESS);
export const getEditorsAndInvitesError = createAction(GET_EDITORS_AND_INVITES_ERROR);

export const inviteToAccountsRequest = createAction(INVITE_TO_ACCOUNTS_REQUEST);
export const inviteToAccountsSuccess = createAction(INVITE_TO_ACCOUNTS_SUCCESS);
export const inviteToAccountsError = createAction(INVITE_TO_ACCOUNTS_ERROR);

export const editEditorsAccountsRequest = createAction(EDIT_EDITOR_ACCOUNTS_REQUEST);
export const editEditorsAccountsSuccess = createAction(EDIT_EDITOR_ACCOUNTS_SUCCESS);
export const editEditorsAccountsError = createAction(EDIT_EDITOR_ACCOUNTS_ERROR);

export const deleteEditorRequest = createAction(DELETE_EDITOR_REQUEST);
export const deleteEditorSuccess = createAction(DELETE_EDITOR_SUCCESS);
export const deleteEditorError = createAction(DELETE_EDITOR_ERROR);

export const resendInviteToAccountsRequest = createAction(RESEND_INVITE_TO_ACCOUNTS_REQUEST);
export const resendInviteToAccountsSuccess = createAction(RESEND_INVITE_TO_ACCOUNTS_SUCCESS);
export const resendInviteToAccountsError = createAction(RESEND_INVITE_TO_ACCOUNTS_ERROR);

export const getAccountsOfUserRequest = createAction(GET_ACCOUNTS_OF_USER_REQUEST);
export const getAccountsOfUserSuccess = createAction(GET_ACCOUNTS_OF_USER_SUCCESS);
export const getAccountsOfUserError = createAction(GET_ACCOUNTS_OF_USER_ERROR);

export const processWebsite = (accountId, website, category) => async dispatch => {
  try {
    dispatch(processWebsiteRequest());
    await OnboardingService.saveBusinessInfo({
      website,
      category,
    }, accountId);
    const { data } = await AccountService.processWebsite(accountId);
    dispatch(processWebsiteSuccess(data));
    trackWebsiteScrapeRequested();
  } catch (error) {
    dispatch(processWebsiteError(error?.data?.message));
  }
};

export const setCurrentBrandAssetStatus = (params) => async dispatch => {
  try {
    dispatch(setCurrentBrandAssetStatusRequest());
    const { data } = await AccountService.updateAccount(params.id, params);
    dispatch(setCurrentBrandAssetStatusSuccess(data));
  } catch (error) {
    dispatch(setCurrentBrandAssetStatusError(error?.data?.message));
  }
};

export const fillBrandLibraryFromWebsite = (accountId, amountOfRequests) => async dispatch => {
  try {
    dispatch(fillBrandLibraryFromWebsiteRequest());
    const { data } = await AccountService.fillBrandLibraryFromWebsite(accountId, amountOfRequests);
    dispatch(fillBrandLibraryFromWebsiteSuccess(data));
    if (![
      FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.REQUESTED,
      FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.FILLING,
    ].includes(data.status)) {
      trackWebsiteScraped(data.status);
    }
  } catch (error) {
    if (error?.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
      const params = {
        brandAssetStatus: BRAND_ASSETS_STATUS.API_ERROR,
        id: accountId,
      };
      dispatch(setCurrentBrandAssetStatus(params));
      Sentry.captureMessage(
        `fillBrandLibraryFromWebsite failed with status 500
        for account ${accountId}`,
      );
    }
    dispatch(fillBrandLibraryFromWebsiteError(error?.data?.message));
  }
};

export const reProcessWebsite = (accountId) => async dispatch => {
  try {
    dispatch(reProcessWebsiteRequest());
    const { data } = await AccountService.reProcessWebsite(accountId);
    dispatch(reProcessWebsiteSuccess(data));
    trackWebsiteScrapeRequested();
  } catch (error) {
    dispatch(reProcessWebsiteError(error?.data?.message));
  }
};

export const updateAndProcessWebsite =
  (accountId, website, category) => async dispatch => {
    try {
      dispatch(updateAndProcessWebsiteRequest());
      await OnboardingService.saveBusinessInfo({ website, category }, accountId);
      const { data } = await AccountService.reProcessWebsite(accountId);
      dispatch(updateAndProcessWebsiteSuccess(data));
      trackWebsiteScrapeRequested();
    } catch (error) {
      dispatch(updateAndProcessWebsiteError(error?.data?.message));
    }
  };

export const getUserWithAccount = () => async dispatch => {
  try {
    dispatch(getUserWithAccountRequest());
    const amountOfProjects = 1;
    const { data } = await UserService.getUser('projects', amountOfProjects);
    const user = serializeUserAccounts(data.user);
    dispatch(getUserWithAccountSuccess({ user }));
  } catch (error) {
    dispatch(getUserWithAccountError(error?.data?.message));
  }
};

export const getUserForAgencyDowngrade = () => async dispatch => {
  try {
    dispatch(getUserForAgencyDowngradeRequest());
    const amountOfProjects = 1;
    const { data } = await UserService.getUser('projects', amountOfProjects);
    let user = serializeUserAccounts(data.user);
    const amountOfAccountsAllowed = user.membership.subscription?.quantity || 1;
    if (user.membership.accounts.length <= amountOfAccountsAllowed) {
      const { data: { user: userUpdated } } = await UserService.updateSettings(
        { mustRedirectToUrl: null },
      );
      user = userUpdated;
    }
    dispatch(getUserForAgencyDowngradeSuccess({ user }));
  } catch (error) {
    dispatch(getUserForAgencyDowngradeError(error?.data?.message));
  }
};

export const getOnboardingInformation = (accountId) => async dispatch => {
  try {
    dispatch(getOnboardingInformationRequest());
    const { data } = await OnboardingService.getOnboardingInfo(accountId);
    dispatch(getOnboardingInformationSuccess(data));
  } catch (error) {
    dispatch(getOnboardingInformationError(error?.data?.message));
  }
};

export const saveOnboardingStep = (step, stepData, accountId) => async dispatch => {
  try {
    dispatch(saveOnboardingStepRequest());

    let data;
    if (step === 1) {
      ({ data } = await OnboardingService.saveBusinessInfo(stepData, accountId));
    }
    if (step === 2) {
      ({ data } = await OnboardingService.saveTargetDemographic(stepData, accountId));
    }
    if (step === 3) {
      ({ data } = await OnboardingService.saveMainCTA(stepData, accountId));
    }

    dispatch(saveOnboardingStepSuccess(data));
  } catch (err) {
    dispatch(saveOnboardingStepError(err));
  }
};

export const getAccountProjects = (accountId, page, perPage) => async dispatch => {
  try {
    dispatch(getAccountProjectsRequest());
    const { data } = await AccountService.getAccountProjects(accountId, page, perPage);
    dispatch(getAccountProjectsSuccess(data));
  } catch (error) {
    dispatch(getAccountProjectsError(error?.data?.message));
  }
};

export const addAccount = (account) => async dispatch => {
  try {
    dispatch(addAccountRequest());
    const { data } = await AccountService.addAccount(account);
    dispatch(addAccountSuccess(data));
  } catch (error) {
    dispatch(addAccountError(error?.data?.message));
  }
};

export const updateAccountName = (id, params) => async dispatch => {
  try {
    dispatch(updateAccountNameRequest());
    const { data } = await AccountService.updateAccount(id, params);
    dispatch(updateAccountNameSuccess(data));
  } catch (error) {
    dispatch(updateAccountNameError(error?.data?.message));
  }
};

export const deleteAccount = (id) => async dispatch => {
  try {
    dispatch(deleteAccountRequest());
    await AccountService.deleteAccount(id);
    dispatch(deleteAccountSuccess({ accountId: id }));
  } catch (error) {
    dispatch(deleteAccountError(error?.data?.message));
  }
};

const moveProjects = async (accountIdsFrom, accountIdTo) => (
  Promise.all(
    accountIdsFrom.map(async id => {
      await AccountService.moveProjects(id, accountIdTo);
    }),
  )
);

const deleteAccounts = async (ids) => (
  Promise.all(
    ids.map(async id => {
      await AccountService.deleteAccount(id);
    }),
  )
);

export const deleteAccountsMovingProjects = (accountIdsFrom, accountIdTo) => async dispatch => {
  try {
    dispatch(deleteAccountsMovingProjectsRequest());
    await moveProjects(accountIdsFrom, accountIdTo);
    await deleteAccounts(accountIdsFrom);
    dispatch(deleteAccountsMovingProjectsSuccess({ accountIdsFrom }));
  } catch (error) {
    dispatch(deleteAccountsMovingProjectsError(error?.data?.message));
  }
};

export const deleteAccountsAgencyDowngrade = (ids) => async dispatch => {
  try {
    dispatch(deleteAccountsAgencyDowngradeRequest());
    await deleteAccounts(ids);
    const { data: { user } } = await UserService.updateSettings({ mustRedirectToUrl: null });
    dispatch(deleteAccountsAgencyDowngradeSuccess({ ids, user }));
  } catch (error) {
    dispatch(deleteAccountsAgencyDowngradeError(error?.data?.message));
  }
};

export const moveProjectsAgencyDowngrade = (accountIdsFrom, accountIdTo) => async dispatch => {
  try {
    dispatch(moveProjectsAgencyDowngradeRequest());
    await moveProjects(accountIdsFrom, accountIdTo);
    await deleteAccounts(accountIdsFrom);
    const { data: { user } } = await UserService.updateSettings({ mustRedirectToUrl: null });
    dispatch(moveProjectsAgencyDowngradeSuccess({ accountIdsFrom, user }));
  } catch (error) {
    dispatch(moveProjectsAgencyDowngradeError(error?.data?.message));
  }
};

export const downgradePlanFromAgency = (mainAccountId, accountIdsActions) => async dispatch => {
  try {
    dispatch(downgradePlanFromAgencyRequest());
    const accountIdsToMove = [];
    const accountIdsToDelete = [];
    Object.entries(accountIdsActions).forEach(([key, value]) => {
      if (value === KEEP_PROJECT_IN_DOWNGRADE) {
        accountIdsToMove.push(Number(key));
      }
      accountIdsToDelete.push(Number(key));
    });
    await moveProjects(accountIdsToMove, mainAccountId);
    await deleteAccounts(accountIdsToDelete);
    const { data: { user } } = await UserService.updateSettings({ mustRedirectToUrl: null });
    dispatch(downgradePlanFromAgencySuccess({
      user,
      ids: accountIdsToMove.concat(accountIdsToDelete),
    }));
  } catch (error) {
    dispatch(downgradePlanFromAgencyError(error?.data?.message));
  }
};

export const getEditorsAndInvites = (accountIds) => async dispatch => {
  try {
    dispatch(getEditorsAndInvitesRequest());
    const { data: { editors } } = await AccountService.getAccountsEditors(accountIds);
    const { data: { invites } } = await AccountInviteService.getAccountsInvites(accountIds);
    const editorsAndInvites = [].concat(
      editors.sort((a, b) => a.id - b.id),
      invites.sort((a, b) => a.id - b.id),
    );
    dispatch(getEditorsAndInvitesSuccess({ editorsAndInvites }));
  } catch (error) {
    dispatch(getEditorsAndInvitesError(error?.data?.message));
  }
};

export const inviteToAccounts = (invite) => async dispatch => {
  try {
    dispatch(inviteToAccountsRequest());

    const { accounts: accountIds, ...accountInviteParams } = invite;
    const { data: { accountInvite } } =
      await AccountInviteService.createAccountsInvite(accountInviteParams, accountIds);

    dispatch(inviteToAccountsSuccess({ accountInvite }));
  } catch (error) {
    dispatch(inviteToAccountsError(error?.data?.message));
  }
};

export const editEditorsAccounts = (editorId, accountIds) => async dispatch => {
  try {
    dispatch(editEditorsAccountsRequest());
    const { data: { accountInvite } } = await TeamService.editUserAccounts(editorId, accountIds);
    dispatch(editEditorsAccountsSuccess({ accountInvite }));
  } catch (error) {
    dispatch(editEditorsAccountsError(error?.data?.message));
  }
};

export const deleteEditor = (editorId) => async dispatch => {
  try {
    dispatch(deleteEditorRequest());
    await TeamService.deleteUser(editorId);
    dispatch(deleteEditorSuccess({ editorId }));
    toast.success('Editor successfully removed', toastSuccessConfig);
  } catch (error) {
    dispatch(deleteEditorError(error?.data?.message));
  }
};

export const resendInviteToAccounts = (inviteId) => async dispatch => {
  try {
    dispatch(resendInviteToAccountsRequest());

    const { data: { accountInvite } } =
      await AccountInviteService.resendInviteToAccountsSuccess(inviteId);

    dispatch(resendInviteToAccountsSuccess({ accountInvite }));
    toast.success('The invitation was successfully resent', toastSuccessConfig);
  } catch (error) {
    dispatch(resendInviteToAccountsError(error?.data?.message));
  }
};

export const getAccountsOfUserAsAdmin = (email) => async dispatch => {
  try {
    dispatch(getAccountsOfUserRequest());
    const { data } = await AccountService.getByEmailAsAdmin(email);
    dispatch(getAccountsOfUserSuccess(data));
  } catch (error) {
    dispatch(getAccountsOfUserError(error?.data?.message));
  }
};
