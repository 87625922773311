export const PLAN = {
  BETA: 'beta',
  FREE: 'free',
  CASUAL: 'casual',
  CORE: 'core',
  PERFORMANCE: 'performance',
};

export const BRAND_LIBRARY_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const MULTIPLE_EXPORTS_PLANS = [
  PLAN.CASUAL,
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const DASHBOARD_PLANS = [
  PLAN.PERFORMANCE,
];

export const AGENCY_PLANS = [
  PLAN.PERFORMANCE,
];

export const PRIVATE_TEMPLATES_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const CANVAS_LINKS_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const SIGN_PLANS = [
  PLAN.CASUAL,
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const REMOVE_BG_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const SET_SHOW_MADE_WITH_STAGGER_BADGE_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];

export const SQUAD_EVENT_PLANS = [
  PLAN.CORE,
  PLAN.PERFORMANCE,
];
