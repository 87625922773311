import axios from 'axios';

const CloudinaryClient = axios.create({
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/image',
  },
});

export { CloudinaryClient };
