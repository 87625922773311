import { combineReducers } from 'redux';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import undoable, { includeAction } from 'redux-undo';

import { CHANGE_LAYOUT, CHANGE_SIZE, UPDATE_ELEMENT } from 'src/actions/projectActions';
import { undoableActions } from 'src/constants/undoableActions';
import { isPublicPages } from 'src/utils/helpers';
import actionStatus from './statusReducer';
import session from './sessionReducer';
import project from './projectReducer';
import projectState from './projectStateReducer';
import canvas from './canvasReducer';
import templates from './templatesReducer';
import productTemplate from './productTemplateReducer';
import brandLibrary from './brandLibraryReducer';
import embedAnalytics from './embedAnalyticsReducer';
import instagramAnalytics from './instagramAnalyticsReducer';
import insights from './insightsReducer';
import publicPages from './publicPagesReducer';
import projectMemory from './projectMemoryReducer';
import rulesEngine from './rulesEngineReducer';
import collage from './collageReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'token', 'user', 'accountId'],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = () => combineReducers({
  session: persistReducer(sessionPersistConfig, session),
  actionStatus,
  project: undoable(project, {
    limit: 12,
    filter: includeAction(undoableActions),
    groupBy: action => {
      if (action.type === UPDATE_ELEMENT && action.payload.timestamp) {
        return `${UPDATE_ELEMENT}_${action.payload.timestamp}`;
      }
      if ((action.type === CHANGE_LAYOUT || action.type === CHANGE_SIZE) &&
        action.payload.timestamp) {
        return `CHANGE_LAYOUT_AND_SIZE_${action.payload.timestamp}`;
      }
      return null;
    },
    ignoreInitialState: true,
  }),
  projectState,
  canvas,
  templates,
  productTemplate,
  brandLibrary,
  embedAnalytics,
  instagramAnalytics,
  insights,
  projectMemory,
  rulesEngine,
  collage,
});

const publicPagesRootReducer = () => combineReducers({
  actionStatus,
  publicPages,
});

export default isPublicPages() ? publicPagesRootReducer : rootReducer;
