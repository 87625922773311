import { useEffect, useState } from 'react';

import { useStatus, useProjectSelector } from 'src/hooks';
import { SUCCESS } from 'src/constants/status';
import { GET_PROJECT } from 'src/actions/projectActions';

const useExportPreview = () => {
  const { videoIdsShotstack, videoUrls } = useProjectSelector();

  const [showSlider, setShowSlider] = useState(false);

  const { status: getProjectStatus } = useStatus(GET_PROJECT);

  useEffect(() => {
    if (getProjectStatus === SUCCESS) {
      const projectHasNoVideo = !videoIdsShotstack?.filter(elem => !!elem).length;
      if (projectHasNoVideo) {
        setShowSlider(true);
      } else {
        let videosLoaded = true;
        videoIdsShotstack.forEach((videoId, index) => {
          if (videoIdsShotstack[index] && !videoUrls[index]) {
            videosLoaded = false;
          }
        });
        setShowSlider(videosLoaded);
      }
    }
  }, [getProjectStatus, videoIdsShotstack, videoUrls]);

  return { showSlider };
};

export { useExportPreview };
