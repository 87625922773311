import { useSelector } from 'react-redux';

import { useProjectSelector } from 'src/hooks';
import { areSelectedElementsUnlocked } from 'src/utils/canvasHelpers';

const useDraggableElement = (isInGroup) => {
  const { elements, selectedElements, groupsOfElementsById } = useProjectSelector();

  const {
    pinchZoomGesture,
  } = useSelector(({ canvas }) => ({
    pinchZoomGesture: canvas.pinchZoomGesture,
  }));

  const selectedElementsUnlocked = areSelectedElementsUnlocked(
    selectedElements,
    elements,
    groupsOfElementsById,
  );

  return { draggableElement: selectedElementsUnlocked && !isInGroup && !pinchZoomGesture };
};

export { useDraggableElement };
