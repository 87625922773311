import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CREATE_ASSET_CATEGORY,
  CREATE_COLOR_PALETTE,
  CREATE_LOGO_CATEGORY,
  DELETE_ASSET_CATEGORY,
  DELETE_COLOR_PALETTE,
  DELETE_LOGO_CATEGORY,
} from 'src/actions/brandLibraryActions';
import { SUCCESS } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { reset } from 'src/actions/statusActions';

export const LOGOS_OPTION = 'logosOption';
export const ASSETS_OPTION = 'assetsOption';
export const COLORS_OPTION = 'colorsOption';
export const FONTS_OPTION = 'fontsOption';

const useCategoryChanges = (
  openStyle,
  setOpenStyle,
  setSelectedCategoryId,
  setSelectedNavigation,
) => {
  const dispatch = useDispatch();

  const {
    logoCategories,
    assetCategories,
    colorPalettes,
  } = useSelector(({ brandLibrary }) => ({
    logoCategories: brandLibrary.logoCategories,
    assetCategories: brandLibrary.assetCategories,
    colorPalettes: brandLibrary.colorPalettes,
  }));


  // When categories are added, re-calculate height and select new one
  const {
    status: createLogoCategoryStatus,
    statusInfo: createLogoCategoryStatusInfo,
  } = useStatus(CREATE_LOGO_CATEGORY);
  useEffect(() => {
    if (openStyle[LOGOS_OPTION] && createLogoCategoryStatus === SUCCESS) {
      const logosOption = document.getElementById(LOGOS_OPTION);
      setOpenStyle({ ...openStyle, [LOGOS_OPTION]: logosOption?.scrollHeight });
      setSelectedCategoryId(createLogoCategoryStatusInfo.logoCategory.id);
      setSelectedNavigation(LOGOS_OPTION);
      dispatch(reset(CREATE_LOGO_CATEGORY));
    }
  }, [
    dispatch,
    openStyle,
    createLogoCategoryStatus,
    setOpenStyle,
    setSelectedCategoryId,
    createLogoCategoryStatusInfo,
    setSelectedNavigation,
  ]);

  const {
    status: createAssetCategoryStatus,
    statusInfo: createAssetCategoryStatusInfo,
  } = useStatus(CREATE_ASSET_CATEGORY);
  useEffect(() => {
    if (openStyle[ASSETS_OPTION] && createAssetCategoryStatus === SUCCESS) {
      const assetsOption = document.getElementById(ASSETS_OPTION);
      setOpenStyle({ ...openStyle, [ASSETS_OPTION]: assetsOption?.scrollHeight });
      setSelectedCategoryId(createAssetCategoryStatusInfo.assetCategory.id);
      setSelectedNavigation(ASSETS_OPTION);
      dispatch(reset(CREATE_ASSET_CATEGORY));
    }
  }, [
    dispatch,
    openStyle,
    createAssetCategoryStatus,
    setOpenStyle,
    setSelectedCategoryId,
    createAssetCategoryStatusInfo,
    setSelectedNavigation,
  ]);

  const {
    status: createColorPaletteStatus,
    statusInfo: createColorPaletteStatusInfo,
  } = useStatus(CREATE_COLOR_PALETTE);
  useEffect(() => {
    if (openStyle[COLORS_OPTION] && createColorPaletteStatus === SUCCESS) {
      const colorsOption = document.getElementById(COLORS_OPTION);
      setOpenStyle({ ...openStyle, [COLORS_OPTION]: colorsOption?.scrollHeight });
      setSelectedCategoryId(createColorPaletteStatusInfo.colorPalette.id);
      setSelectedNavigation(COLORS_OPTION);
      dispatch(reset(CREATE_COLOR_PALETTE));
    }
  }, [
    dispatch,
    openStyle,
    createColorPaletteStatus,
    setOpenStyle,
    setSelectedCategoryId,
    createColorPaletteStatusInfo,
    setSelectedNavigation,
  ]);

  // When categories are deleted, select new one
  const { status: deleteLogoCategoryStatus } = useStatus(DELETE_LOGO_CATEGORY);
  useEffect(() => {
    if (deleteLogoCategoryStatus === SUCCESS) {
      if (logoCategories?.length) {
        setSelectedCategoryId(logoCategories[0].id);
      } else {
        setSelectedCategoryId();
      }
      dispatch(reset(DELETE_LOGO_CATEGORY));
    }
  }, [deleteLogoCategoryStatus, dispatch, logoCategories, setSelectedCategoryId]);

  const { status: deleteAssetCategoryStatus } = useStatus(DELETE_ASSET_CATEGORY);
  useEffect(() => {
    if (deleteAssetCategoryStatus === SUCCESS) {
      if (assetCategories?.length) {
        setSelectedCategoryId(assetCategories[0].id);
      } else {
        setSelectedCategoryId();
      }
      dispatch(reset(DELETE_ASSET_CATEGORY));
    }
  }, [assetCategories, deleteAssetCategoryStatus, dispatch, setSelectedCategoryId]);

  const { status: deletePaletteStatus } = useStatus(DELETE_COLOR_PALETTE);
  useEffect(() => {
    if (deletePaletteStatus === SUCCESS) {
      if (colorPalettes?.length) {
        setSelectedCategoryId(colorPalettes[0].id);
      } else {
        setSelectedCategoryId();
      }
      dispatch(reset(DELETE_COLOR_PALETTE));
    }
  }, [colorPalettes, deletePaletteStatus, dispatch, setSelectedCategoryId]);
};

export { useCategoryChanges };
