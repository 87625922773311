import React from 'react';
import ReactDOM from 'react-dom';
import { node, func, bool, string, object } from 'prop-types';
import cn from 'classnames';

import { useClickOutside } from 'src/hooks';
import CloseIcon from 'src/assets/icons/close.svg';
import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import styles from './Modal.module.scss';

const Modal = ({
  children,
  isShowing,
  hide,
  isEscEnabled = true,
  curvedContainer = false,
  backgroundLight = false,
  noBackground = false,
  hideClassName = '',
  icon = false,
  stylesProductTour = null,
  productTour = false,
  closeOnClickOutside = true,
  classNameContent = '',
  classNameContainer = '',
  id,
}) => {
  const ref = useClickOutside(closeOnClickOutside ? hide : () => {}, isShowing);
  return (
    isShowing
      ? ReactDOM.createPortal(
        <div className={cn(styles.container, {
          [styles.background]: !noBackground,
          [styles.backgroundLight]: backgroundLight,
          [classNameContainer]: classNameContainer,
        })}
        >
          <div
            className={cn(
              styles.content, {
                [styles.curvedContainer]: curvedContainer,
                [styles.contentAnimation]: productTour,
                [classNameContent]: classNameContent,
              },
            )}
            style={stylesProductTour}
            ref={ref}
            id={id}
          >
            {isEscEnabled && (
              <button className={cn(styles.hide, hideClassName)} onClick={hide}>
                <img src={icon ? CloseWhiteIcon : CloseIcon} alt="Close modal" />
              </button>
            )}
            {children}
          </div>
        </div>,
        document.getElementById('root'),
      )
      : null
  );
};

Modal.propTypes = {
  children: node.isRequired,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  isEscEnabled: bool,
  curvedContainer: bool,
  backgroundLight: bool,
  hideClassName: string,
  icon: bool,
  className: string,
  stylesProductTour: object,
  productTour: bool,
  id: string,
  classNameContent: string,
  classNameContainer: string,
};

export { Modal };
