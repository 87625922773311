import React from 'react';
import { toast } from 'react-toastify';

import { toastSuccessConfig, toastErrorConfig, toastInfoConfig } from 'src/constants/toastConfig';
import { ToastLoading } from 'src/common/toast-loading';
import { trackApplyTemplate } from 'src/utils/analytics';
import { TEMPLATES_CONTROL } from 'src/constants/canvasControls';
import { getMediaToKeepParsed } from 'src/utils/canvasHelpers';
import { routesPaths } from 'src/routes/routesPaths';
import { SHOWING_STATUS } from 'src/constants/general';
import createAction from './createAction';
import {
  savePreviewFiles,
  saveThumbnailFile,
  saveCanvasFiles,
  saveCanvasGroupsFiles,
} from './projectActions';
import { getCategoriesWithTemplates } from './templatesActions';
import { KeywordService } from '../services/keywordsService';
import { CategoryService } from '../services/categoryService';
import { TemplateService } from '../services/templateService';
import { ProjectService } from '../services/projectService';
import { showProductTip } from './userSettingsActions';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_KEYWORDS = 'GET_KEYWORDS';
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS';
export const GET_KEYWORDS_REQUEST = 'GET_KEYWORDS_REQUEST';
export const GET_KEYWORDS_ERROR = 'GET_KEYWORDS_ERROR';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_ERROR = 'SAVE_TEMPLATE_ERROR';

export const SAVE_TEMPLATE_RESET = 'SAVE_TEMPLATE_RESET';

export const UPDATE_EXISTING_TEMPLATE = 'UPDATE_EXISTING_TEMPLATE';
export const UPDATE_EXISTING_TEMPLATE_SUCCESS = 'UPDATE_EXISTING_TEMPLATE_SUCCESS';
export const UPDATE_EXISTING_TEMPLATE_REQUEST = 'UPDATE_EXISTING_TEMPLATE_REQUEST';
export const UPDATE_EXISTING_TEMPLATE_ERROR = 'UPDATE_EXISTING_TEMPLATE_ERROR';

export const UPDATE_EXISTING_TEMPLATE_RESET = 'UPDATE_EXISTING_TEMPLATE_RESET';

export const UPDATE_METADATA_TEMPLATE = 'UPDATE_METADATA_TEMPLATE';
export const UPDATE_METADATA_TEMPLATE_SUCCESS = 'UPDATE_METADATA_TEMPLATE_SUCCESS';
export const UPDATE_METADATA_TEMPLATE_REQUEST = 'UPDATE_METADATA_TEMPLATE_REQUEST';
export const UPDATE_METADATA_TEMPLATE_ERROR = 'UPDATE_METADATA_TEMPLATE_ERROR';

export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

export const APPLY_TEMPLATE_TO_PROJECT = 'APPLY_TEMPLATE_TO_PROJECT';
export const APPLY_TEMPLATE_TO_PROJECT_SUCCESS = 'APPLY_TEMPLATE_TO_PROJECT_SUCCESS';
export const APPLY_TEMPLATE_TO_PROJECT_REQUEST = 'APPLY_TEMPLATE_TO_PROJECT_REQUEST';
export const APPLY_TEMPLATE_TO_PROJECT_ERROR = 'APPLY_TEMPLATE_TO_PROJECT_ERROR';

export const getCategoriesRequest = createAction(GET_CATEGORIES_REQUEST);
export const getCategoriesSuccess = createAction(GET_CATEGORIES_SUCCESS);
export const getCategoriesError = createAction(GET_CATEGORIES_ERROR);

export const getKeywordsRequest = createAction(GET_KEYWORDS_REQUEST);
export const getKeywordsSuccess = createAction(GET_KEYWORDS_SUCCESS);
export const getKeywordsError = createAction(GET_KEYWORDS_ERROR);

export const saveTemplateRequest = createAction(SAVE_TEMPLATE_REQUEST);
export const saveTemplateSuccess = createAction(SAVE_TEMPLATE_SUCCESS);
export const saveTemplateError = createAction(SAVE_TEMPLATE_ERROR);

export const saveTemplateReset = createAction(SAVE_TEMPLATE_RESET);

export const updateExistingTemplateRequest = createAction(UPDATE_EXISTING_TEMPLATE_REQUEST);
export const updateExistingTemplateSuccess = createAction(UPDATE_EXISTING_TEMPLATE_SUCCESS);
export const updateExistingTemplateError = createAction(UPDATE_EXISTING_TEMPLATE_ERROR);

export const updateExistingTemplateReset = createAction(UPDATE_EXISTING_TEMPLATE_RESET);

export const updateMetadataTemplateRequest = createAction(UPDATE_METADATA_TEMPLATE_REQUEST);
export const updateMetadataTemplateSuccess = createAction(UPDATE_METADATA_TEMPLATE_SUCCESS);
export const updateMetadataTemplateError = createAction(UPDATE_METADATA_TEMPLATE_ERROR);

export const setSelectedTemplate = createAction(SET_SELECTED_TEMPLATE);

export const applyTemplateToProjectRequest = createAction(APPLY_TEMPLATE_TO_PROJECT_REQUEST);
export const applyTemplateToProjectSuccess = createAction(APPLY_TEMPLATE_TO_PROJECT_SUCCESS);
export const applyTemplateToProjectError = createAction(APPLY_TEMPLATE_TO_PROJECT_ERROR);

export const setDataForTemplateModal = (
  templateIndex,
  categoryId,
) => async (dispatch, getState) => {
  const state = getState();
  const selectedTemplate = state.templates.categories[categoryId].templates[templateIndex];
  selectedTemplate.keywords = selectedTemplate.keywords.map(
    value => ({ ...value, label: value.tag }),
  );
  dispatch(setSelectedTemplate(selectedTemplate));
};

export const getCategories = () => async dispatch => {
  try {
    dispatch(getCategoriesRequest());
    const { data } = await CategoryService.getCategories();
    dispatch(getCategoriesSuccess(data));
  } catch (error) {
    dispatch(getCategoriesError());
  }
};

export const getKeywords = () => async dispatch => {
  try {
    dispatch(getKeywordsRequest());
    const { data } = await KeywordService.getKeywords();
    dispatch(getKeywordsSuccess(data));
  } catch (error) {
    dispatch(getKeywordsError());
  }
};

const getParamsForTemplate = async (params, files, fileThumbnail, projectInState) => {
  let dataToUpdate = params;
  const elements = await saveCanvasFiles(params.elements);
  const groupsOfElementsById = await saveCanvasGroupsFiles(params.groupsOfElementsById);
  dataToUpdate = {
    ...dataToUpdate,
    elements: JSON.stringify(elements),
    videoIdsByPosition: JSON.stringify(params.videoIdsByPosition),
    gifIdsByPosition: JSON.stringify(params.gifIdsByPosition),
    groupsOfElementsById: JSON.stringify(groupsOfElementsById),
  };
  const { previews, videoIdsShotstack } = await savePreviewFiles(
    files,
    dataToUpdate,
    projectInState.type,
    projectInState.id,
  );
  const thumbnail = await saveThumbnailFile(fileThumbnail);
  dataToUpdate = {
    ...dataToUpdate,
    previews,
    thumbnail,
    videoIdsShotstack,
  };
  return dataToUpdate;
};

export const saveTemplate = (params, files, fileThumbnail) => async (dispatch, getState) => {
  const toastId = 'saveTemplate';
  try {
    const state = getState();
    const { project: { present } } = state;
    dispatch(saveTemplateRequest());
    toast(<ToastLoading message="Creating template..." />, toastInfoConfig(toastId));
    const { accountId } = state.session;
    const dataToUpdate = await getParamsForTemplate(
      params,
      files,
      fileThumbnail,
      present,
    );
    const template = {
      template: {
        accountId,
        ...dataToUpdate,
      },
    };
    const { data } = await TemplateService.createTemplate(
      template, state.project.present.id, state.session.user.id,
    );
    dispatch(saveTemplateSuccess(data));
    toast.success('Project Template created', toastSuccessConfig);
  } catch (error) {
    dispatch(saveTemplateError(error?.data?.message));
    toast.error(error?.data?.message, toastErrorConfig);
  } finally {
    toast.dismiss(toastId);
  }
};

export const updateExistingTemplate = (
  id,
  files,
  fileThumbnail,
) => async (dispatch, getState) => {
  const toastId = 'updateExitingTemplate';
  try {
    dispatch(updateExistingTemplateRequest());
    toast(<ToastLoading message="Updating template" />, toastInfoConfig(toastId));
    const state = getState();
    const { project: { present } } = state;
    const params = {
      name: present.template.name,
      description: present.template.description,
      size: present.size,
      color: present.color.hex,
      alpha: present.color.alpha,
      layoutWidth: present.layout.width,
      layoutHeight: present.layout.height,
      layoutSource: present.layout.source,
      elements: present.elements,
      groupsOfElementsById: present.groupsOfElementsById,
      videoIdsByPosition: present.videoIdsByPosition,
      gifIdsByPosition: present.gifIdsByPosition,
    };
    const dataToUpdate = await getParamsForTemplate(params, files, fileThumbnail, present);
    const { data } = await TemplateService.updateTemplate(
      id, dataToUpdate, state.project.present.id, state.session.user.id,
    );
    dispatch(updateExistingTemplateSuccess(data));
    toast.success('Updated template', toastSuccessConfig);
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(updateExistingTemplateError(error?.data?.message));
  } finally {
    toast.dismiss(toastId);
  }
};

export const updateMetadataTemplate = (id, params, history) => async (dispatch, getState) => {
  const toastId = 'updateMetadataTemplate';
  try {
    dispatch(updateMetadataTemplateRequest());
    toast(<ToastLoading message="Updating template data" />, toastInfoConfig(toastId));
    const { data } = await TemplateService.updateDataTemplate(id, params);
    dispatch(updateMetadataTemplateSuccess(data));
    const state = getState();
    const { canvas: { controlOpen }, session: { accountId }, project } = state;
    if (controlOpen === TEMPLATES_CONTROL) {
      const page = 1;
      const typeOfProjects = project.present.type;
      dispatch(getCategoriesWithTemplates(accountId, page, typeOfProjects));
    } else if (history.location.pathname === routesPaths.templates) {
      window.location.reload();
    }
    toast.success('Updated template data', toastSuccessConfig);
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(updateMetadataTemplateError(error?.data?.message));
  } finally {
    toast.dismiss(toastId);
  }
};

export const applyTemplateToProject = (template, mediaToKeep) => async (dispatch, getState) => {
  const toastId = 'applyTemplateToProject';
  const state = getState();
  const templateToApply = template || state.templates.selectedTemplatePreview;
  try {
    dispatch(applyTemplateToProjectRequest());
    toast(<ToastLoading message="Applying template to project" />, toastInfoConfig(toastId));
    const projectId = state.project.present.id;
    const parsedMedia = getMediaToKeepParsed(mediaToKeep);
    const mediaInCanvas = JSON.stringify(parsedMedia);
    const { data } = await ProjectService.applyTemplate(
      projectId,
      templateToApply.id,
      mediaInCanvas,
    );
    dispatch(applyTemplateToProjectSuccess(data));
    trackApplyTemplate(projectId, templateToApply.category.name, templateToApply.layoutSource);
    toast.success('Nice choice, that template looks great 🔮', toastSuccessConfig);
    if (state.session.user.settings.showTemplateTip === SHOWING_STATUS.DONT_SHOW) {
      dispatch(showProductTip('showTemplateTip'));
    }
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(applyTemplateToProjectError(error?.data?.message));
  } finally {
    toast.dismiss(toastId);
  }
};
