import { UserService } from 'src/services/userService';
import { setEmailSubscription } from 'src/utils/analytics';
import { SHOWING_STATUS } from 'src/constants/general';
import { DISMISSED_MODAL_FIELD } from 'src/constants/dismissibleModals';
import createAction from './createAction';

export const CHANGE_EMAIL_SUBSCRIPTION = 'CHANGE_EMAIL_SUBSCRIPTION';
export const CHANGE_EMAIL_SUBSCRIPTION_REQUEST = 'CHANGE_EMAIL_SUBSCRIPTION_REQUEST';
export const CHANGE_EMAIL_SUBSCRIPTION_SUCCESS = 'CHANGE_EMAIL_SUBSCRIPTION_SUCCESS';
export const CHANGE_EMAIL_SUBSCRIPTION_ERROR = 'CHANGE_EMAIL_SUBSCRIPTION_ERROR';

export const CANCEL_EMAIL_SUBSCRIPTION = 'CANCEL_EMAIL_SUBSCRIPTION';
export const CANCEL_EMAIL_SUBSCRIPTION_REQUEST = 'CANCEL_EMAIL_SUBSCRIPTION_REQUEST';
export const CANCEL_EMAIL_SUBSCRIPTION_SUCCESS = 'CANCEL_EMAIL_SUBSCRIPTION_SUCCESS';
export const CANCEL_EMAIL_SUBSCRIPTION_ERROR = 'CANCEL_EMAIL_SUBSCRIPTION_ERROR';

export const DISMISS_MODAL = 'DISMISS_MODAL';
export const DISMISS_MODAL_SUCCESS = 'DISMISS_MODAL_SUCCESS';
export const DISMISS_MODAL_REQUEST = 'DISMISS_MODAL_REQUEST';
export const DISMISS_MODAL_ERROR = 'DISMISS_MODAL_ERROR';

export const CLOSE_SUGGESTION_UPGRADE_INFO_SUCCESS = 'CLOSE_SUGGESTION_UPGRADE_INFO_SUCCESS';
export const CLOSE_SUGGESTION_UPGRADE_INFO_REQUEST = 'CLOSE_SUGGESTION_UPGRADE_INFO_REQUEST';
export const CLOSE_SUGGESTION_UPGRADE_INFO_ERROR = 'CLOSE_SUGGESTION_UPGRADE_INFO_ERROR';

export const CLOSE_FIRST_SIGN_MODAL = 'CLOSE_FIRST_SIGN_MODAL';
export const CLOSE_FIRST_SIGN_MODAL_SUCCESS = 'CLOSE_FIRST_SIGN_MODAL_SUCCESS';
export const CLOSE_FIRST_SIGN_MODAL_REQUEST = 'CLOSE_FIRST_SIGN_MODAL_REQUEST';
export const CLOSE_FIRST_SIGN_MODAL_ERROR = 'CLOSE_FIRST_SIGN_MODAL_ERROR';

export const USER_DOING_PRODUCT_TOUR = 'USER_DOING_PRODUCT_TOUR';

export const USER_FINISHES_PRODUCT_TOUR = 'USER_FINISHES_PRODUCT_TOUR';
export const USER_FINISHES_PRODUCT_TOUR_SUCCESS = 'USER_FINISHES_PRODUCT_TOUR_SUCCESS';
export const USER_FINISHES_PRODUCT_TOUR_REQUEST = 'USER_FINISHES_PRODUCT_TOUR_REQUEST';
export const USER_FINISHES_PRODUCT_TOUR_ERROR = 'USER_FINISHES_PRODUCT_TOUR_ERROR';

export const CLOSE_PRODUCT_TIP_SUCCESS = 'CLOSE_PRODUCT_TIP_SUCCESS';
export const CLOSE_PRODUCT_TIP_REQUEST = 'CLOSE_PRODUCT_TIP_REQUEST';
export const CLOSE_PRODUCT_TIP_ERROR = 'CLOSE_PRODUCT_TIP_ERROR';

export const SHOW_PRODUCT_TIP_SUCCESS = 'SHOW_PRODUCT_TIP_SUCCESS';
export const SHOW_PRODUCT_TIP_REQUEST = 'SHOW_PRODUCT_TIP_REQUEST';
export const SHOW_PRODUCT_TIP_ERROR = 'SHOW_PRODUCT_TIP_ERROR';

export const SET_DESIGN_TIPS_STATE = 'SET_DESIGN_TIPS_STATE';
export const SET_DESIGN_TIPS_STATE_SUCCESS = 'SET_DESIGN_TIPS_STATE_SUCCESS';
export const SET_DESIGN_TIPS_STATE_REQUEST = 'SET_DESIGN_TIPS_STATE_REQUEST';
export const SET_DESIGN_TIPS_STATE_ERROR = 'SET_DESIGN_TIPS_STATE_ERROR';

export const SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE = 'SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE';
export const SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_SUCCESS =
  'SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_SUCCESS';
export const SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_REQUEST =
  'SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_REQUEST';
export const SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_ERROR =
  'SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_ERROR';

export const changeEmailSubscriptionRequest = createAction(CHANGE_EMAIL_SUBSCRIPTION_REQUEST);
export const changeEmailSubscriptionSuccess = createAction(CHANGE_EMAIL_SUBSCRIPTION_SUCCESS);
export const changeEmailSubscriptionError = createAction(CHANGE_EMAIL_SUBSCRIPTION_ERROR);

export const cancelEmailSubscriptionRequest = createAction(CANCEL_EMAIL_SUBSCRIPTION_REQUEST);
export const cancelEmailSubscriptionSuccess = createAction(CANCEL_EMAIL_SUBSCRIPTION_SUCCESS);
export const cancelEmailSubscriptionError = createAction(CANCEL_EMAIL_SUBSCRIPTION_ERROR);

export const dismissModalRequest = createAction(DISMISS_MODAL_REQUEST);
export const dismissModalSuccess = createAction(DISMISS_MODAL_SUCCESS);
export const dismissModalError = createAction(DISMISS_MODAL_ERROR);

export const closeFirstSignModalRequest = createAction(CLOSE_FIRST_SIGN_MODAL_REQUEST);
export const closeFirstSignModalSuccess = createAction(CLOSE_FIRST_SIGN_MODAL_SUCCESS);
export const closeFirstSignModalError = createAction(CLOSE_FIRST_SIGN_MODAL_ERROR);

export const closeSuggestionUpgradeInfoRequest =
  createAction(CLOSE_SUGGESTION_UPGRADE_INFO_REQUEST);
export const closeSuggestionUpgradeInfoSuccess =
  createAction(CLOSE_SUGGESTION_UPGRADE_INFO_SUCCESS);
export const closeSuggestionUpgradeInfoError = createAction(CLOSE_SUGGESTION_UPGRADE_INFO_ERROR);

export const userDoingProductTour = createAction(USER_DOING_PRODUCT_TOUR);

export const userFinishesProductTourRequest = createAction(USER_FINISHES_PRODUCT_TOUR_REQUEST);
export const userFinishesProductTourSuccess = createAction(USER_FINISHES_PRODUCT_TOUR_SUCCESS);
export const userFinishesProductTourError = createAction(USER_FINISHES_PRODUCT_TOUR_ERROR);

export const closeProductTipRequest = createAction(CLOSE_PRODUCT_TIP_REQUEST);
export const closeProductTipSuccess = createAction(CLOSE_PRODUCT_TIP_SUCCESS);
export const closeProductTipError = createAction(CLOSE_PRODUCT_TIP_ERROR);

export const showProductTipRequest = createAction(SHOW_PRODUCT_TIP_REQUEST);
export const showProductTipSuccess = createAction(SHOW_PRODUCT_TIP_SUCCESS);
export const showProductTipError = createAction(SHOW_PRODUCT_TIP_ERROR);

export const setDesignTipsStateRequest = createAction(SET_DESIGN_TIPS_STATE_REQUEST);
export const setDesignTipsStateSuccess = createAction(SET_DESIGN_TIPS_STATE_SUCCESS);
export const setDesignTipsStateError = createAction(SET_DESIGN_TIPS_STATE_ERROR);

export const setShowMadeWithStaggerBadgeStateRequest =
  createAction(SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_REQUEST);
export const setShowMadeWithStaggerBadgeStateSuccess =
  createAction(SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_SUCCESS);
export const setShowMadeWithStaggerBadgeStateError =
  createAction(SET_SHOW_MADE_WITH_STAGGER_BADGE_STATE_ERROR);

export const changeEmailSubscription = params => async dispatch => {
  try {
    dispatch(changeEmailSubscriptionRequest());
    const { data } = await UserService.updateSettings(params);
    dispatch(changeEmailSubscriptionSuccess(data));
    const { email, subscribedToEmail } = data.user;
    setEmailSubscription(email, subscribedToEmail);
  } catch (error) {
    dispatch(changeEmailSubscriptionError('Error while changing subscription'));
  }
};

export const cancelEmailSubscription = token => async dispatch => {
  try {
    dispatch(cancelEmailSubscriptionRequest());
    const { data } = await UserService.cancelEmailSubscription(token);
    dispatch(cancelEmailSubscriptionSuccess(data));
    const { email } = data.user;
    setEmailSubscription(email, false);
  } catch (error) {
    dispatch(cancelEmailSubscriptionError('Error while changing subscription'));
  }
};

export const dismissModal = (dismissedModal) => async dispatch => {
  try {
    const dismissedModalField = DISMISSED_MODAL_FIELD[dismissedModal];
    if (!dismissedModalField) {
      throw new Error(`Unrecognized modal name: ${dismissedModal}`);
    }
    dispatch(dismissModalRequest());
    const { data: updatedUser } = await UserService.updateSettings({
      [dismissedModalField]: false,
    });
    dispatch(dismissModalSuccess({
      user: updatedUser,
      dismissedModal,
    }));
  } catch (error) {
    dispatch(dismissModalError(error?.data?.message));
  }
};

export const closeSuggestionUpgradeInfo = () => async dispatch => {
  try {
    dispatch(closeSuggestionUpgradeInfoRequest());
    const closedSuggestionUpgradeInfo = new Date();
    const { data } = await UserService.updateSettings({ closedSuggestionUpgradeInfo });
    dispatch(closeSuggestionUpgradeInfoSuccess(data));
  } catch (error) {
    dispatch(closeSuggestionUpgradeInfoError());
  }
};

export const closeFirstSignModal = () => async dispatch => {
  try {
    dispatch(closeFirstSignModalRequest());
    const { data } = await UserService.updateSettings({ closedFirstSignModal: true });
    dispatch(closeFirstSignModalSuccess(data));
  } catch (error) {
    dispatch(closeFirstSignModalError());
  }
};

export const userFinishesProductTour = (productTourParam) => async dispatch => {
  try {
    dispatch(userFinishesProductTourRequest());
    const { data } = await UserService.updateSettings({ [productTourParam]: true });
    dispatch(userFinishesProductTourSuccess(data));
  } catch (error) {
    dispatch(userFinishesProductTourError());
  }
};

export const showProductTip = (productTipParam) => async dispatch => {
  try {
    dispatch(showProductTipRequest());
    const { data } = await UserService.updateSettings({
      [productTipParam]: SHOWING_STATUS.SHOW,
    });
    dispatch(showProductTipSuccess(data));
  } catch (error) {
    dispatch(showProductTipError());
  }
};

export const closeProductTip = (productTipParam) => async dispatch => {
  try {
    dispatch(closeProductTipRequest());
    const { data } = await UserService.updateSettings({
      [productTipParam]: SHOWING_STATUS.ALREADY_CLOSED,
    });
    dispatch(closeProductTipSuccess(data));
  } catch (error) {
    dispatch(closeProductTipError());
  }
};

export const setDesignTipsState = (state) => async dispatch => {
  try {
    dispatch(setDesignTipsStateRequest());
    const { data } = await UserService.updateSettings({
      showDesignTips: state,
    });
    dispatch(setDesignTipsStateSuccess(data));
  } catch (error) {
    dispatch(setDesignTipsStateError());
  }
};

export const setShowMadeWithStaggerBadgeState = (state) => async dispatch => {
  try {
    dispatch(setShowMadeWithStaggerBadgeStateRequest());
    const { data } = await UserService.updateSettings({
      showMadeWithStaggerBadge: state,
    });
    dispatch(setShowMadeWithStaggerBadgeStateSuccess(data));
  } catch (error) {
    dispatch(setShowMadeWithStaggerBadgeStateError());
  }
};
