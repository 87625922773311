import { DESIGN_COMPONENT_TYPE } from 'src/constants/general';
import { HttpClient } from '../clients';

class DesignComponentsService {
  static getTextCombinations() {
    return HttpClient.get(`/design-components?type=${DESIGN_COMPONENT_TYPE.TEXTS}`);
  }

  static getObjectsCombinations() {
    return HttpClient.get(
      `/design-components?type=${DESIGN_COMPONENT_TYPE.FRAMES},${DESIGN_COMPONENT_TYPE.TAPE}`,
    );
  }
}

export { DesignComponentsService };
