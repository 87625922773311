import { toast } from 'react-toastify';

import { toastSuccessConfig } from 'src/constants/toastConfig';
import { ProjectService } from 'src/services/projectService';
import createAction from './createAction';

export const UPDATE_FLYER_SETTINGS = 'UPDATE_FLYER_SETTINGS';
export const UPDATE_FLYER_SETTINGS_SUCCESS = 'UPDATE_FLYER_SETTINGS_SUCCESS';
export const UPDATE_FLYER_SETTINGS_REQUEST = 'UPDATE_FLYER_SETTINGS_REQUEST';
export const UPDATE_FLYER_SETTINGS_ERROR = 'UPDATE_FLYER_SETTINGS_ERROR';

export const updateFlyerSettingsRequest = createAction(UPDATE_FLYER_SETTINGS_REQUEST);
export const updateFlyerSettingsSuccess = createAction(UPDATE_FLYER_SETTINGS_SUCCESS);
export const updateFlyerSettingsError = createAction(UPDATE_FLYER_SETTINGS_ERROR);

export const updateFlyerSettings = (projectId, params) => async dispatch => {
  try {
    dispatch(updateFlyerSettingsRequest());
    const { data: { project } } = await ProjectService.updateFlyerSettings(projectId, params);
    dispatch(updateFlyerSettingsSuccess({ project }));
    toast.success('Nice settings, we saved them for you!', toastSuccessConfig);
  } catch (error) {
    dispatch(updateFlyerSettingsError());
  }
};
