import React from 'react';
import { func, bool } from 'prop-types';
import { useDispatch } from 'react-redux';

import { updateProjectVideoInformation } from 'src/actions/projectActions';
import { Modal } from 'src/common/modal';
import { useProjectSelector } from 'src/hooks';
import { Button } from 'src/common/button';
import styles from './VideoModal.module.scss';


const VideoModal = ({ isShowing, setVideoModalOpen }) => {
  const dispatch = useDispatch();

  const { id } = useProjectSelector();

  const onClose = () => {
    dispatch(updateProjectVideoInformation(id, { showedVideoInformation: true }));
    setVideoModalOpen(false);
  };

  return (
    <Modal
      isShowing={isShowing}
      hide={onClose}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>Heads up about video</span>
        <span className={styles.text}>
          Any element placed on top of the video will
          be moved underneath the video when exported.
        </span>
        <Button
          onClick={onClose}
          alternativeCTA
          className={styles.button}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

VideoModal.propTypes = {
  isShowing: bool.isRequired,
  setVideoModalOpen: func.isRequired,
};

export { VideoModal };
