import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BucketPaintWhite from 'src/assets/icons/paint-bucket-white.svg';
import { changeColor } from 'src/actions/projectActions';
import {
  useProjectSelector,
  useWindowSize,
} from 'src/hooks';
import {
  BACKGROUND_COLOR_EYEDROPPER_SOURCE,
  CUSTOM,
  RIGHT,
} from 'src/constants/general';
import { ColorPickerCanvas } from 'src/pages/project/canvas/canvas-helper-elements';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { getRGBAColor } from 'src/utils/helpers';
import styles from './ColorControl.module.scss';

const ColorControl = () => {
  const dispatch = useDispatch();

  const { eyeDropper } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
  }));

  const { color } = useProjectSelector();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const changeColorRequest = useCallback(value => {
    dispatch(changeColor(value));
  }, [dispatch]);

  const fillColorForBucket = useMemo(() => {
    if (eyeDropper.active && eyeDropper.source === BACKGROUND_COLOR_EYEDROPPER_SOURCE) {
      return { backgroundColor: eyeDropper.color };
    }
    return { backgroundColor: getRGBAColor(color) };
  }, [color, eyeDropper.active, eyeDropper.color, eyeDropper.source]);

  return (
    <div className={styles.container}>
      {!isMobile && <span className={styles.colorText}>Canvas color</span>}
      <ColorPickerCanvas
        color={color}
        onColorChange={changeColorRequest}
        eyedropperSource={BACKGROUND_COLOR_EYEDROPPER_SOURCE}
        position={isMobile ? CUSTOM : RIGHT}
        customPosition={styles.customPosition}
        openInModal={isMobile}
        swatch={isMobile ? (onClick) => (
          <button className={styles.paintBucket} onClick={onClick}>
            <img
              src={BucketPaintWhite}
              alt="Choose background color"
            />
            <div className={styles.paintBucketLine}>
              <div style={fillColorForBucket} />
            </div>
          </button>
        ) : undefined}
        className={styles.colorContainer}
      />
      {!isMobile && <span className={styles.color}>{color.hex}</span>}
    </div>
  );
};

export { ColorControl };
