import React from 'react';
import { bool, func, number, oneOf } from 'prop-types';

import { RIGHT, LEFT } from 'src/constants/general';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ArrowLeftWhite from 'src/assets/icons/arrow-left-white.svg';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import ArrowRightWhite from 'src/assets/icons/arrow-right-white.svg';
import styles from './SliderArrow.module.scss';

const SliderArrow = ({ onClick, currentSlide, slideCount, direction, showDisabled }) => {
  const disableRight = currentSlide === slideCount - 1;
  const disableLeft = currentSlide === 0;

  const getSrc = () => {
    if (direction === RIGHT) {
      return disableRight ? ArrowRight : ArrowRightWhite;
    }
    return disableLeft ? ArrowLeft : ArrowLeftWhite;
  };

  const isDisabled = (direction === RIGHT && disableRight) || (direction === LEFT && disableLeft);

  const showArrow = !isDisabled || (isDisabled && showDisabled);

  return (
    <button
      className={styles.button}
      onClick={onClick}
      disabled={isDisabled}
    >
      {showArrow ? (
        <img src={getSrc()} alt="Arrow" className={styles.arrow} />
      ) : (
        <div className={styles.arrow} />
      )}
    </button>
  );
};

SliderArrow.propTypes = {
  onClick: func,
  currentSlide: number,
  slideCount: number,
  direction: oneOf([LEFT, RIGHT]),
  showDisabled: bool,
};

export { SliderArrow };
