import { PLAN } from 'src/constants/memberships';

const CASUAL_CREATOR_FEATURES = [
  'Unlimited Projects',
  'Access to Creator Collages',
  'Branded Embed Codes',
];

export const PLAN_CASUAL_CREATOR_YEARLY = {
  plan: PLAN.CASUAL,
  price: '$1.83/',
  promo: 'Save $2 yearly',
  features: CASUAL_CREATOR_FEATURES,
};

export const PLAN_CASUAL_CREATOR_MONTHLY = {
  plan: PLAN.CASUAL,
  price: '$2/',
  promo: '',
  features: CASUAL_CREATOR_FEATURES,
};

const CORE_CREATOR_FEATURES = [
  'Everything in Casual',
  'Brand Library',
  'Public Pages',
  'Custom Templates',
  'Unbranded Embed Codes',
  'Links in Embed Codes',
  'Remove background tool',
];

export const PLAN_CORE_CREATOR_YEARLY = {
  plan: PLAN.CORE,
  price: '$4.58/',
  promo: 'Save $5 yearly',
  features: CORE_CREATOR_FEATURES,
};

export const PLAN_CORE_CREATOR_MONTHLY = {
  plan: PLAN.CORE,
  price: '$5/',
  promo: '',
  features: CORE_CREATOR_FEATURES,
};

const PERFORMANCE_CREATOR_FEATURES = [
  'Everything in Core',
  'Dashboard Analytics for Embed Codes',
  '3 brand accounts',
];

export const PLAN_PERFORMANCE_CREATOR_YEARLY = {
  plan: PLAN.PERFORMANCE,
  price: '$8.33/',
  promo: 'Save $20 yearly',
  features: [...PERFORMANCE_CREATOR_FEATURES, '$10/yr each additional account'],
};

export const PLAN_PERFORMANCE_CREATOR_MONTHLY = {
  plan: PLAN.PERFORMANCE,
  price: '$10/',
  promo: '',
  features: [...PERFORMANCE_CREATOR_FEATURES, '$1/mo each additional account'],
};
