import { logout } from 'src/actions/userActions';
import { HTTP_STATUS } from 'src/constants/general';

const setInterceptors = (store, HttpClient) => {
  HttpClient.interceptors.response.use(response => response, error => {
    if (error.response?.status === HTTP_STATUS.UNAUTHORIZED) {
      store.dispatch(logout());
    }
    return Promise.reject(error.response);
  });

  HttpClient.interceptors.request.use(config => {
    const { token } = store.getState().session || {};
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });
};

export { setInterceptors };
