import React from 'react';
import { number } from 'prop-types';

import styles from './AngleCirclePicker.module.scss';

const AngleCirclePicker = ({ angle }) => (
  <div className={styles.container}>
    <span className={styles.content} style={{ transform: `rotate(${angle}deg)` }}>
      <i className={styles.dot} />
    </span>
  </div>
);

AngleCirclePicker.propTypes = {
  angle: number.isRequired,
};

export { AngleCirclePicker };
