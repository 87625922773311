import React from 'react';
import { shape, arrayOf, string, number, func } from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import styles from './CanvasColorSwatches.module.scss';

const CanvasColorSwatches = ({ colors, onColorChange, title }) => {
  const { eyeDropper } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
  }));

  const onClick = (color, event) => {
    onColorChange(color, event);
  };

  if (!colors?.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.divider} />
      <span className={styles.title}>{title}</span>
      <div className={styles.content}>
        {colors.map(color => (
          <button
            key={`${color.hex}_${color.alpha}`}
            className={cn(styles.swatch, { [styles.active]: eyeDropper.active })}
            style={{ backgroundColor: color.hex }}
            onClick={(e) => onClick(color, e)}
            aria-label="Change color"
          />
        ))}
      </div>
    </div>
  );
};

CanvasColorSwatches.propTypes = {
  colors: arrayOf(shape({
    hex: string,
    alpha: number,
  })),
  onColorChange: func.isRequired,
  title: string.isRequired,
};

export { CanvasColorSwatches };
