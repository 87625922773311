import React from 'react';
import { string } from 'prop-types';

import styles from './QuestionFromChat.module.scss';

const QuestionFromChat = ({ text }) => (
  <div className={styles.container}>
    <span className={styles.text}>{text}</span>
  </div>
);

QuestionFromChat.propTypes = {
  text: string.isRequired,
};

export { QuestionFromChat };
