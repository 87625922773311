import { bool, func, number, string } from 'prop-types';
import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

import PauseIcon from 'src/assets/icons/pause-button.svg';
import PlayIcon from 'src/assets/icons/play-button.svg';
import { VIDEO_BUTTON_PREFIX_ID } from 'src/constants/general';

const WIDTH = 150;
const HEIGHT = 150;

const PlayPauseButton = ({ uuid, x, y, listening, onClick, isPlaying }) => {
  const [playImage] = useImage(PlayIcon);
  const [pauseImage] = useImage(PauseIcon);

  return (
    <Image
      image={isPlaying ? pauseImage : playImage}
      id={`${VIDEO_BUTTON_PREFIX_ID}_${uuid}`}
      x={x}
      y={y}
      offsetX={WIDTH / 2}
      offsetY={HEIGHT / 2}
      width={WIDTH}
      height={HEIGHT}
      onClick={onClick}
      onTap={onClick}
      listening={listening}
    />
  );
};

PlayPauseButton.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  listening: bool.isRequired,
  onClick: func.isRequired,
  isPlaying: bool,
};

export { PlayPauseButton };
