import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveZoom } from 'src/actions/canvasActions';
import {
  CANVAS_SCROLL_ID,
  CANVAS_TITLE_SPACE,
  CANVAS_ZOOM_FIT,
  ZOOM_OPTIONS,
} from 'src/constants/general';
import { calculateAspectRatioFit } from 'src/utils/canvasHelpers';
import { useProjectSelector } from './useProjectSelector';

const useZoomOptions = () => {
  const dispatch = useDispatch();

  const { zoom } = useSelector(({ canvas }) => ({
    zoom: canvas.zoom,
  }));

  const { layout } = useProjectSelector();

  const getOptionsOrdered = useCallback(() => {
    const canvasElem = document.getElementById(CANVAS_SCROLL_ID);
    if (!canvasElem) {
      return ZOOM_OPTIONS;
    }
    const { ratio } = calculateAspectRatioFit(
      layout.width,
      layout.height,
      canvasElem.offsetWidth,
      canvasElem.offsetHeight - CANVAS_TITLE_SPACE,
    );
    const options = ZOOM_OPTIONS.map(elem => {
      elem.valueToOrder = elem.value === CANVAS_ZOOM_FIT ? ratio * 100 : elem.value;
      return elem;
    });
    return options.sort((a, b) => b.valueToOrder - a.valueToOrder);
  }, [layout.height, layout.width]);

  const onClickMinus = useCallback(() => {
    const ordered = getOptionsOrdered();
    const indexFound = ordered.findIndex(elem => elem.value === zoom);
    if (indexFound === -1) {
      dispatch(saveZoom({ zoom: CANVAS_ZOOM_FIT }));
    } else if (indexFound < ordered.length - 1) {
      const prevZoom = ordered[indexFound + 1];
      dispatch(saveZoom({ zoom: prevZoom.value }));
    }
  }, [dispatch, getOptionsOrdered, zoom]);

  const onClickPlus = useCallback(() => {
    const ordered = getOptionsOrdered();
    const indexFound = ordered.findIndex(elem => elem.value === zoom);
    if (indexFound === -1) {
      dispatch(saveZoom({ zoom: CANVAS_ZOOM_FIT }));
    } else if (indexFound > 0) {
      const prevZoom = ordered[indexFound - 1];
      dispatch(saveZoom({ zoom: prevZoom.value }));
    }
  }, [dispatch, getOptionsOrdered, zoom]);

  return { onClickPlus, onClickMinus, getOptionsOrdered };
};

export { useZoomOptions };
