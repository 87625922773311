import { logout } from 'src/actions/userActions';
import {
  getTimeSpentInFramesSuccess,
  getExportDateSuccess,
  getReferrersSuccess,
  getLinksClickedSuccess,
  setSelectedProject,
  setSelectedTimeRange,
  getUserForDashboardSuccess,
  getImpressionsSuccess,
  getTimeInStaggerSuccess,
} from 'src/actions/embedAnalyticsActions';
import createReducer from './createReducer';

const initialState = {
  disabled: true,
  selectedProject: undefined,
  selectedTimeRange: undefined,
  timeSpentInFrames: [],
  exportDate: undefined,
  referrers: [],
  linksClicked: [],
  impressions: undefined,
  timeInStagger: undefined,
};

const actionHandlers = {
  [getUserForDashboardSuccess]: (state, { payload: { disabled } }) => {
    state.disabled = disabled;
  },
  [setSelectedProject]: (state, { payload: { project } }) => {
    state.selectedProject = project;
  },
  [setSelectedTimeRange]: (state, { payload: { from, to } }) => {
    state.selectedTimeRange = { from, to };
  },
  [getTimeSpentInFramesSuccess]: (state, { payload: { result, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.timeSpentInFrames = result;
    }
  },
  [getExportDateSuccess]: (state, { payload: { time, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.exportDate = time;
    }
  },
  [getReferrersSuccess]: (state, { payload: { referrers, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.referrers = referrers;
    }
  },
  [getLinksClickedSuccess]: (state, { payload: { result, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.linksClicked = result;
    }
  },
  [getImpressionsSuccess]: (state, { payload: { impressions, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.impressions = impressions;
    }
  },
  [getTimeInStaggerSuccess]: (state, { payload: { time, projectId } }) => {
    if (state.selectedProject.id === projectId) {
      state.timeInStagger = time;
    }
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
