import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import UploadIcon from 'src/assets/icons/upload-accent.svg';
import { UploadFontPermission } from '../upload-font-permission';
import styles from './UploadFont.module.scss';

const UploadFont = () => {
  const refInput = useRef();

  const [filesToSave, setFilesToSave] = useState();

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const onClick = () => {
    if (!disabled && refInput?.current) {
      refInput.current.value = '';
      refInput.current.click();
    }
  };

  const uploadFont = event => {
    event.stopPropagation();
    event.preventDefault();
    const { target: { files } } = event;
    setFilesToSave(Object.values(files));
  };

  return (
    <>
      <button className={styles.container} onClick={onClick} disabled={disabled}>
        <input
          type="file"
          accept=".otf, .ttf, .woff"
          className={styles.input}
          ref={refInput}
          onChange={uploadFont}
          multiple
          value=""
        />
        <img src={UploadIcon} alt="Upload" />
        <span className={styles.upload}>Upload font</span>
      </button>
      {!!filesToSave && (
        <UploadFontPermission
          isShowing={!!filesToSave}
          hide={() => setFilesToSave()}
          filesToSave={filesToSave}
        />
      )}
    </>
  );
};

export { UploadFont };
