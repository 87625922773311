import React from 'react';
import { string } from 'prop-types';

import { GoogleButton } from '../google-button';
import { FacebookButton } from '../facebook-button';
import styles from './SocialButtons.module.scss';

const SocialButtons = ({ facebookText, googleText, createdOnCampaign }) => (
  <div>
    <GoogleButton text={googleText} createdOnCampaign={createdOnCampaign} />
    <FacebookButton text={facebookText} createdOnCampaign={createdOnCampaign} />
    <div className={styles.textContainer}>
      <hr className={styles.line} />
      <span className={styles.text}>or</span>
      <hr className={styles.line} />
    </div>
  </div>
);


SocialButtons.propTypes = {
  facebookText: string.isRequired,
  googleText: string.isRequired,
  createdOnCampaign: string,
};

export { SocialButtons };
