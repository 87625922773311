import { useEffect, useRef, useCallback } from 'react';

const useClickOutside = (
  callback,
  tracking = true,
  exceptions = [],
  ignore = false,
) => {
  const node = useRef();

  const handleClick = useCallback(event => {
    if (node.current && node.current.contains(event.target)) {
      return;
    }
    const exceptionsApproved = exceptions.length ? (
      exceptions.some(except => (
        ![event.target.className, event.target.id].includes(except)
      ))) : true;
    exceptionsApproved && !ignore && callback();
  }, [callback, exceptions, ignore]);

  useEffect(() => {
    tracking && document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick, tracking]);

  useEffect(() => {
    tracking && document.addEventListener('touchstart', handleClick);
    return () => document.removeEventListener('touchstart', handleClick);
  }, [handleClick, tracking]);

  // Use node as a ref for the component for which you want to handle outside clicks.
  return node;
};

export { useClickOutside };
