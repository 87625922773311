import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useWindowSize } from 'src/hooks';
import {
  LeftImg,
  LeftImg2x,
  LeftImg3x,
  RightImg,
  RightImg2x,
  RightImg3x,
} from 'src/assets/images/background';
import Logo from 'src/assets/icons/logo.svg';
import { LARGE_WIDTH } from 'src/constants/breakpoints';
import { routesPaths } from 'src/routes/routesPaths';
import { Footer } from 'src/common/footer';
import { ChangePasswordForm } from './form';
import styles from './ChangePassword.module.scss';

const ChangePassword = () => {
  const { windowSize: { width } } = useWindowSize();
  const isLargeScreen = useMemo(() => width > LARGE_WIDTH, [width]);

  return (
    <>
      <div className={styles.container}>
        {isLargeScreen && (
          <img
            src={LeftImg}
            alt=""
            srcSet={`${LeftImg2x} 2x, ${LeftImg3x} 3x`}
            className={styles.image}
          />
        )}
        <div className={styles.content}>
          <div className={styles.form}>
            <img src={Logo} alt="Stagger logo" className={styles.logo} />
            <span className={styles.title}>
              Reset your password.
            </span>
            <ChangePasswordForm />
          </div>
          <div className={styles.signupContainer}>
            <span>Don&apos;t have an account?</span>
            <Link to={routesPaths.signup} className={styles.link}>Sign up now</Link>
          </div>
        </div>
        {isLargeScreen && (
          <img
            src={RightImg}
            alt=""
            srcSet={`${RightImg2x} 2x, ${RightImg3x} 3x`}
            className={styles.image}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export { ChangePassword };
