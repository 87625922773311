import React from 'react';
import { string, func } from 'prop-types';

import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { useForm, useTextInputProps } from 'src/hooks';
import { validateCategoryName } from 'src/utils/constrains';
import styles from './CategoryNameInput.module.scss';

const CategoryNameInput = ({
  defaultName,
  updateCategory,
  onUpdatedSuccess,
  onCancel,
}) => {
  const updateName = async (values) => {
    if (values.name === defaultName) {
      onCancel();
    } else {
      await updateCategory(values);
      onUpdatedSuccess();
    }
  };

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(updateName, { validate: validateCategoryName, initialValues: { name: defaultName } });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Input
        name="name"
        containerClassName={styles.inputContainer}
        inputClassName={styles.input}
        disabled={false}
        {...inputProps('name')}
      />
      <Button
        className={styles.buttonCancel}
        onClick={onCancel}
        short
      >
        Cancel
      </Button>
      <Button
        type="submit"
        className={styles.saveButton}
        short
      >
        Save
      </Button>
    </form>

  );
};

CategoryNameInput.propTypes = {
  defaultName: string.isRequired,
  updateCategory: func.isRequired,
  onUpdatedSuccess: func.isRequired,
  onCancel: func.isRequired,
};

export { CategoryNameInput };
