import { useEffect, useRef } from 'react';

// NOTE: From https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback, delay = 100) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export { useInterval };
