import React from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import {
  serializeUserAccounts,
  promiseInListWithLimit,
} from 'src/utils/helpers';
import {
  getCloudinaryFilenameFromUrl,
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import {
  toastSuccessConfig,
  toastErrorConfig,
  toastSuccessMarkupConfig,
} from 'src/constants/toastConfig';
import { UserService } from 'src/services/userService';
import { ColorPaletteService } from 'src/services/colorPaletteService';
import { BrandLibraryService } from 'src/services/brandLibraryService';
import { AssetCategoryService } from 'src/services/assetCategoryService';
import { LogoCategoryService } from 'src/services/logoCategoryService';
import { AssetService } from 'src/services/assetService';
import { LogoService } from 'src/services/logoService';
import { AccountService } from 'src/services/accountService';
import { BRAND_LIBRARY_PLANS } from 'src/constants/memberships';
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_STYLE } from 'src/constants/fonts';
import { GOOGLE_UPLOAD } from 'src/constants/uploadFile';
import {
  trackAddLogoToBrandLibrary,
  trackAddAssetToBrandLibrary,
  trackDeleteLogoToBrandLibrary,
  trackDeleteAssetToBrandLibrary,
  trackCreateAssetCategoryOfBrandLibrary,
  trackDeleteAssetCategoryOfBrandLibrary,
  trackCreateLogoCategoryOfBrandLibrary,
  trackDeleteLogoCategoryOfBrandLibrary,
  trackCreateColorPaletteOfBrandLibrary,
  trackDeleteColorPaletteOfBrandLibrary,
  trackAddColorToPaletteOfBrandLibrary,
  trackDeleteColorToPaletteOfBrandLibrary,
  trackAddFontStyleToBrandLibrary,
  trackUpdateFontStyleOfBrandLibrary,
  trackDeleteFontStyleOfBrandLibrary,
  trackUploadFontToBrandLibrary,
  trackDeleteUploadedFontOfBrandLibrary,
} from 'src/utils/analytics';
import { duplicateAssetFiles } from 'src/utils/canvasHelpers';
import { ProjectService } from 'src/services/projectService';
import { replaceImageFromRightClick } from 'src/actions/projectActions';
import { ToastMarkup } from 'src/common/toast-markup';
import { isSubscriptionActive, getMembershipPlan } from 'src/utils/sessionHelpers';
import { addMediaElements } from './projectActions';
import createAction from './createAction';

export const GET_USER_WITH_BRAND_LIBRARY = 'GET_USER_WITH_BRAND_LIBRARY';
export const GET_USER_WITH_BRAND_LIBRARY_SUCCESS = 'GET_USER_WITH_BRAND_LIBRARY_SUCCESS';
export const GET_USER_WITH_BRAND_LIBRARY_REQUEST = 'GET_USER_WITH_BRAND_LIBRARY_REQUEST';
export const GET_USER_WITH_BRAND_LIBRARY_ERROR = 'GET_USER_WITH_BRAND_LIBRARY_ERROR';

export const GET_LOGOS_OF_CATEGORY = 'GET_LOGOS_OF_CATEGORY';
export const GET_LOGOS_OF_CATEGORY_SUCCESS = 'GET_LOGOS_OF_CATEGORY_SUCCESS';
export const GET_LOGOS_OF_CATEGORY_REQUEST = 'GET_LOGOS_OF_CATEGORY_REQUEST';
export const GET_LOGOS_OF_CATEGORY_ERROR = 'GET_LOGOS_OF_CATEGORY_ERROR';

export const GET_ASSETS_OF_CATEGORY = 'GET_ASSETS_OF_CATEGORY';
export const GET_ASSETS_OF_CATEGORY_SUCCESS = 'GET_ASSETS_OF_CATEGORY_SUCCESS';
export const GET_ASSETS_OF_CATEGORY_REQUEST = 'GET_ASSETS_OF_CATEGORY_REQUEST';
export const GET_ASSETS_OF_CATEGORY_ERROR = 'GET_ASSETS_OF_CATEGORY_ERROR';

export const ADD_ASSETS_TO_BRAND_LIBRARY = 'ADD_ASSETS_TO_BRAND_LIBRARY';
export const ADD_ASSETS_TO_BRAND_LIBRARY_REQUEST = 'ADD_ASSETS_TO_BRAND_LIBRARY_REQUEST';
export const ADD_ASSETS_TO_BRAND_LIBRARY_SUCCESS = 'ADD_ASSETS_TO_BRAND_LIBRARY_SUCCESS';
export const ADD_ASSETS_TO_BRAND_LIBRARY_ERROR = 'ADD_ASSETS_TO_BRAND_LIBRARY_ERROR';

export const ADD_SINGLE_ASSET_TO_CATEGORY = 'ADD_SINGLE_ASSET_TO_CATEGORY';
export const ADD_SINGLE_ASSET_TO_CATEGORY_REQUEST = 'ADD_SINGLE_ASSET_TO_CATEGORY_REQUEST';
export const ADD_SINGLE_ASSET_TO_CATEGORY_SUCCESS = 'ADD_SINGLE_ASSET_TO_CATEGORY_SUCCESS';
export const ADD_SINGLE_ASSET_TO_CATEGORY_ERROR = 'ADD_SINGLE_ASSET_TO_CATEGORY_ERROR';

export const ADD_LOGOS_TO_BRAND_LIBRARY = 'ADD_LOGOS_TO_BRAND_LIBRARY';
export const ADD_LOGOS_TO_BRAND_LIBRARY_SUCCESS = 'ADD_LOGOS_TO_BRAND_LIBRARY_SUCCESS';
export const ADD_LOGOS_TO_BRAND_LIBRARY_REQUEST = 'ADD_LOGOS_TO_BRAND_LIBRARY_REQUEST';
export const ADD_LOGOS_TO_BRAND_LIBRARY_ERROR = 'ADD_LOGOS_TO_BRAND_LIBRARY_ERROR';

export const ADD_SINGLE_LOGO_TO_CATEGORY = 'ADD_SINGLE_LOGO_TO_CATEGORY';
export const ADD_SINGLE_LOGO_TO_CATEGORY_REQUEST = 'ADD_SINGLE_LOGO_TO_CATEGORY_REQUEST';
export const ADD_SINGLE_LOGO_TO_CATEGORY_SUCCESS = 'ADD_SINGLE_LOGO_TO_CATEGORY_SUCCESS';
export const ADD_SINGLE_LOGO_TO_CATEGORY_ERROR = 'ADD_SINGLE_LOGO_TO_CATEGORY_ERROR';

export const DELETE_ASSET_FROM_BRAND_LIBRARY = 'DELETE_ASSET_FROM_BRAND_LIBRARY';
export const DELETE_ASSET_FROM_BRAND_LIBRARY_SUCCESS = 'DELETE_ASSET_FROM_BRAND_LIBRARY_SUCCESS';
export const DELETE_ASSET_FROM_BRAND_LIBRARY_REQUEST = 'DELETE_ASSET_FROM_BRAND_LIBRARY_REQUEST';
export const DELETE_ASSET_FROM_BRAND_LIBRARY_ERROR = 'DELETE_ASSET_FROM_BRAND_LIBRARY_ERROR';

export const DELETE_LOGO_FROM_BRAND_LIBRARY = 'DELETE_LOGO_FROM_BRAND_LIBRARY';
export const DELETE_LOGO_FROM_BRAND_LIBRARY_SUCCESS = 'DELETE_LOGO_FROM_BRAND_LIBRARY_SUCCESS';
export const DELETE_LOGO_FROM_BRAND_LIBRARY_REQUEST = 'DELETE_LOGO_FROM_BRAND_LIBRARY_REQUEST';
export const DELETE_LOGO_FROM_BRAND_LIBRARY_ERROR = 'DELETE_LOGO_FROM_BRAND_LIBRARY_ERROR';

export const ADD_ASSETS_TO_PROJECT = 'ADD_ASSETS_TO_PROJECT';
export const ADD_ASSETS_TO_PROJECT_SUCCESS = 'ADD_ASSETS_TO_PROJECT_SUCCESS';
export const ADD_ASSETS_TO_PROJECT_REQUEST = 'ADD_ASSETS_TO_PROJECT_REQUEST';
export const ADD_ASSETS_TO_PROJECT_ERROR = 'ADD_ASSETS_TO_PROJECT_ERROR';

export const REPLACE_ASSET_FROM_RIGHT_CLICK = 'REPLACE_ASSET_FROM_RIGHT_CLICK';
export const REPLACE_ASSET_FROM_RIGHT_CLICK_SUCCESS = 'REPLACE_ASSET_FROM_RIGHT_CLICK_SUCCESS';
export const REPLACE_ASSET_FROM_RIGHT_CLICK_REQUEST = 'REPLACE_ASSET_FROM_RIGHT_CLICK_REQUEST';
export const REPLACE_ASSET_FROM_RIGHT_CLICK_ERROR = 'REPLACE_ASSET_FROM_RIGHT_CLICK_ERROR';

export const CREATE_ASSET_CATEGORY = 'CREATE_ASSET_CATEGORY';
export const CREATE_ASSET_CATEGORY_REQUEST = 'CREATE_ASSET_CATEGORY_REQUEST';
export const CREATE_ASSET_CATEGORY_SUCCESS = 'CREATE_ASSET_CATEGORY_SUCCESS';
export const CREATE_ASSET_CATEGORY_ERROR = 'CREATE_ASSET_CATEGORY_ERROR';

export const UPDATE_ASSET_CATEGORY_NAME_REQUEST = 'UPDATE_ASSET_CATEGORY_NAME_REQUEST';
export const UPDATE_ASSET_CATEGORY_NAME_SUCCESS = 'UPDATE_ASSET_CATEGORY_NAME_SUCCESS';
export const UPDATE_ASSET_CATEGORY_NAME_ERROR = 'UPDATE_ASSET_CATEGORY_NAME_ERROR';

export const DELETE_ASSET_CATEGORY = 'DELETE_ASSET_CATEGORY';
export const DELETE_ASSET_CATEGORY_SUCCESS = 'DELETE_ASSET_CATEGORY_SUCCESS';
export const DELETE_ASSET_CATEGORY_REQUEST = 'DELETE_ASSET_CATEGORY_REQUEST';
export const DELETE_ASSET_CATEGORY_ERROR = 'DELETE_ASSET_CATEGORY_ERROR';

export const CREATE_LOGO_CATEGORY = 'CREATE_LOGO_CATEGORY';
export const CREATE_LOGO_CATEGORY_REQUEST = 'CREATE_LOGO_CATEGORY_REQUEST';
export const CREATE_LOGO_CATEGORY_SUCCESS = 'CREATE_LOGO_CATEGORY_SUCCESS';
export const CREATE_LOGO_CATEGORY_ERROR = 'CREATE_LOGO_CATEGORY_ERROR';

export const UPDATE_LOGO_CATEGORY_NAME_REQUEST = 'UPDATE_LOGO_CATEGORY_NAME_REQUEST';
export const UPDATE_LOGO_CATEGORY_NAME_SUCCESS = 'UPDATE_LOGO_CATEGORY_NAME_SUCCESS';
export const UPDATE_LOGO_CATEGORY_NAME_ERROR = 'UPDATE_LOGO_CATEGORY_NAME_ERROR';

export const DELETE_LOGO_CATEGORY = 'DELETE_LOGO_CATEGORY';
export const DELETE_LOGO_CATEGORY_SUCCESS = 'DELETE_LOGO_CATEGORY_SUCCESS';
export const DELETE_LOGO_CATEGORY_REQUEST = 'DELETE_LOGO_CATEGORY_REQUEST';
export const DELETE_LOGO_CATEGORY_ERROR = 'DELETE_LOGO_CATEGORY_ERROR';

export const CREATE_COLOR_PALETTE = 'CREATE_COLOR_PALETTE';
export const CREATE_COLOR_PALETTE_SUCCESS = 'CREATE_COLOR_PALETTE_SUCCESS';
export const CREATE_COLOR_PALETTE_REQUEST = 'CREATE_COLOR_PALETTE_REQUEST';
export const CREATE_COLOR_PALETTE_ERROR = 'CREATE_COLOR_PALETTE_ERROR';

export const UPDATE_PALETTE_NAME = 'UPDATE_PALETTE_NAME';
export const UPDATE_PALETTE_NAME_SUCCESS = 'UPDATE_PALETTE_NAME_SUCCESS';
export const UPDATE_PALETTE_NAME_REQUEST = 'UPDATE_PALETTE_NAME_REQUEST';
export const UPDATE_PALETTE_NAME_ERROR = 'UPDATE_PALETTE_NAME_ERROR';

export const DELETE_COLOR_PALETTE = 'DELETE_COLOR_PALETTE';
export const DELETE_COLOR_PALETTE_SUCCESS = 'DELETE_COLOR_PALETTE_SUCCESS';
export const DELETE_COLOR_PALETTE_REQUEST = 'DELETE_COLOR_PALETTE_REQUEST';
export const DELETE_COLOR_PALETTE_ERROR = 'DELETE_COLOR_PALETTE_ERROR';

export const CREATE_PALETTE_COLOR = 'CREATE_PALETTE_COLOR';
export const CREATE_PALETTE_COLOR_SUCCESS = 'CREATE_PALETTE_COLOR_SUCCESS';
export const CREATE_PALETTE_COLOR_REQUEST = 'CREATE_PALETTE_COLOR_REQUEST';
export const CREATE_PALETTE_COLOR_ERROR = 'CREATE_PALETTE_COLOR_ERROR';

export const UPDATE_PALETTE_COLOR = 'UPDATE_PALETTE_COLOR';
export const UPDATE_PALETTE_COLOR_SUCCESS = 'UPDATE_PALETTE_COLOR_SUCCESS';
export const UPDATE_PALETTE_COLOR_REQUEST = 'UPDATE_PALETTE_COLOR_REQUEST';
export const UPDATE_PALETTE_COLOR_ERROR = 'UPDATE_PALETTE_COLOR_ERROR';

export const DELETE_PALETTE_COLOR = 'DELETE_PALETTE_COLOR';
export const DELETE_PALETTE_COLOR_SUCCESS = 'DELETE_PALETTE_COLOR_SUCCESS';
export const DELETE_PALETTE_COLOR_REQUEST = 'DELETE_PALETTE_COLOR_REQUEST';
export const DELETE_PALETTE_COLOR_ERROR = 'DELETE_PALETTE_COLOR_ERROR';

export const ADD_FONT = 'ADD_FONT';
export const ADD_FONT_SUCCESS = 'ADD_FONT_SUCCESS';
export const ADD_FONT_REQUEST = 'ADD_FONT_REQUEST';
export const ADD_FONT_ERROR = 'ADD_FONT_ERROR';

export const UPLOAD_FONTS = 'UPLOAD_FONTS';
export const UPLOAD_FONTS_SUCCESS = 'UPLOAD_FONTS_SUCCESS';
export const UPLOAD_FONTS_REQUEST = 'UPLOAD_FONTS_REQUEST';
export const UPLOAD_FONTS_ERROR = 'UPLOAD_FONTS_ERROR';

export const UPDATE_FONT = 'UPDATE_FONT';
export const UPDATE_FONT_SUCCESS = 'UPDATE_FONT_SUCCESS';
export const UPDATE_FONT_REQUEST = 'UPDATE_FONT_REQUEST';
export const UPDATE_FONT_ERROR = 'UPDATE_FONT_ERROR';

export const DELETE_FONT = 'DELETE_FONT';
export const DELETE_FONT_SUCCESS = 'DELETE_FONT_SUCCESS';
export const DELETE_FONT_REQUEST = 'DELETE_FONT_REQUEST';
export const DELETE_FONT_ERROR = 'DELETE_FONT_ERROR';

export const DELETE_UPLOADED_FONT = 'DELETE_UPLOADED_FONT';
export const DELETE_UPLOADED_FONT_SUCCESS = 'DELETE_UPLOADED_FONT_SUCCESS';
export const DELETE_UPLOADED_FONT_REQUEST = 'DELETE_UPLOADED_FONT_REQUEST';
export const DELETE_UPLOADED_FONT_ERROR = 'DELETE_UPLOADED_FONT_ERROR';

export const UPDATE_TEXT_WITH_FONT_STYLE = 'UPDATE_TEXT_WITH_FONT_STYLE';

export const UPDATE_PROJECT_WITH_FONT_STYLE = 'UPDATE_PROJECT_WITH_FONT_STYLE';
export const UPDATE_PROJECT_WITH_FONT_STYLE_SUCCESS = 'UPDATE_PROJECT_WITH_FONT_STYLE_SUCCESS';
export const UPDATE_PROJECT_WITH_FONT_STYLE_REQUEST = 'UPDATE_PROJECT_WITH_FONT_STYLE_REQUEST';
export const UPDATE_PROJECT_WITH_FONT_STYLE_ERROR = 'UPDATE_PROJECT_WITH_FONT_STYLE_ERROR';

export const SAVE_SEARCH_TERM_BRAND_LIBRARY = 'SAVE_SEARCH_TERM_BRAND_LIBRARY';

export const SEARCH_IN_BRAND_LIBRARY = 'SEARCH_IN_BRAND_LIBRARY';
export const SEARCH_IN_BRAND_LIBRARY_SUCCESS = 'SEARCH_IN_BRAND_LIBRARY_SUCCESS';
export const SEARCH_IN_BRAND_LIBRARY_REQUEST = 'SEARCH_IN_BRAND_LIBRARY_REQUEST';
export const SEARCH_IN_BRAND_LIBRARY_ERROR = 'SEARCH_IN_BRAND_LIBRARY_ERROR';

export const CLEAR_SEARCH_IN_BRAND_LIBRARY = 'CLEAR_SEARCH_IN_BRAND_LIBRARY';

export const SHOW_ASSET_DETAILS = 'SHOW_ASSET_DETAILS';
export const SHOW_ASSET_DETAILS_SUCCESS = 'SHOW_ASSET_DETAILS_SUCCESS';
export const SHOW_ASSET_DETAILS_REQUEST = 'SHOW_ASSET_DETAILS_REQUEST';
export const SHOW_ASSET_DETAILS_ERROR = 'SHOW_ASSET_DETAILS_ERROR';
export const SHOW_ASSET_DETAILS_RESET = 'SHOW_ASSET_DETAILS_RESET';

export const SHOW_LOGO_DETAILS = 'SHOW_LOGO_DETAILS';
export const SHOW_LOGO_DETAILS_SUCCESS = 'SHOW_LOGO_DETAILS_SUCCESS';
export const SHOW_LOGO_DETAILS_REQUEST = 'SHOW_LOGO_DETAILS_REQUEST';
export const SHOW_LOGO_DETAILS_ERROR = 'SHOW_LOGO_DETAILS_ERROR';
export const SHOW_LOGO_DETAILS_RESET = 'SHOW_LOGO_DETAILS_RESET';

export const RECEIVE_ASSET_METADATA = 'RECEIVE_ASSET_METADATA';
export const RECEIVE_LOGO_METADATA = 'RECEIVE_LOGO_METADATA';

export const getUserWithBrandLibraryRequest = createAction(GET_USER_WITH_BRAND_LIBRARY_REQUEST);
export const getUserWithBrandLibrarySuccess = createAction(GET_USER_WITH_BRAND_LIBRARY_SUCCESS);
export const getUserWithBrandLibraryError = createAction(GET_USER_WITH_BRAND_LIBRARY_ERROR);

export const getLogosOfCategoryRequest = createAction(GET_LOGOS_OF_CATEGORY_REQUEST);
export const getLogosOfCategorySuccess = createAction(GET_LOGOS_OF_CATEGORY_SUCCESS);
export const getLogosOfCategoryError = createAction(GET_LOGOS_OF_CATEGORY_ERROR);

export const getAssetsOfCategoryRequest = createAction(GET_ASSETS_OF_CATEGORY_REQUEST);
export const getAssetsOfCategorySuccess = createAction(GET_ASSETS_OF_CATEGORY_SUCCESS);
export const getAssetsOfCategoryError = createAction(GET_ASSETS_OF_CATEGORY_ERROR);

export const addLogosToBrandLibraryRequest = createAction(ADD_LOGOS_TO_BRAND_LIBRARY_REQUEST);
export const addLogosToBrandLibrarySuccess = createAction(ADD_LOGOS_TO_BRAND_LIBRARY_SUCCESS);
export const addLogosToBrandLibraryError = createAction(ADD_LOGOS_TO_BRAND_LIBRARY_ERROR);

export const deleteAssetFromBrandLibraryRequest =
  createAction(DELETE_ASSET_FROM_BRAND_LIBRARY_REQUEST);
export const deleteAssetFromBrandLibrarySuccess =
  createAction(DELETE_ASSET_FROM_BRAND_LIBRARY_SUCCESS);
export const deleteAssetFromBrandLibraryError = createAction(DELETE_ASSET_FROM_BRAND_LIBRARY_ERROR);

export const addAssetsToBrandLibraryRequest = createAction(ADD_ASSETS_TO_BRAND_LIBRARY_REQUEST);
export const addAssetsToBrandLibrarySuccess = createAction(ADD_ASSETS_TO_BRAND_LIBRARY_SUCCESS);
export const addAssetsToBrandLibraryError = createAction(ADD_ASSETS_TO_BRAND_LIBRARY_ERROR);

export const addSingleAssetToCategoryRequest = createAction(ADD_SINGLE_ASSET_TO_CATEGORY_REQUEST);
export const addSingleAssetToCategorySuccess = createAction(ADD_SINGLE_ASSET_TO_CATEGORY_SUCCESS);
export const addSingleAssetToCategoryError = createAction(ADD_SINGLE_ASSET_TO_CATEGORY_ERROR);

export const addSingleLogoToCategoryRequest = createAction(ADD_SINGLE_LOGO_TO_CATEGORY_REQUEST);
export const addSingleLogoToCategorySuccess = createAction(ADD_SINGLE_LOGO_TO_CATEGORY_SUCCESS);
export const addSingleLogoToCategoryError = createAction(ADD_SINGLE_LOGO_TO_CATEGORY_ERROR);

export const deleteLogoFromBrandLibraryRequest =
  createAction(DELETE_LOGO_FROM_BRAND_LIBRARY_REQUEST);
export const deleteLogoFromBrandLibrarySuccess =
  createAction(DELETE_LOGO_FROM_BRAND_LIBRARY_SUCCESS);
export const deleteLogoFromBrandLibraryError = createAction(DELETE_LOGO_FROM_BRAND_LIBRARY_ERROR);

export const addAssetsToProjectRequest = createAction(ADD_ASSETS_TO_PROJECT_REQUEST);
export const addAssetsToProjectSuccess = createAction(ADD_ASSETS_TO_PROJECT_SUCCESS);
export const addAssetsToProjectError = createAction(ADD_ASSETS_TO_PROJECT_ERROR);

export const replaceAssetFromRightClickRequest =
  createAction(REPLACE_ASSET_FROM_RIGHT_CLICK_REQUEST);
export const replaceAssetFromRightClickSuccess =
  createAction(REPLACE_ASSET_FROM_RIGHT_CLICK_SUCCESS);
export const replaceAssetFromRightClickError = createAction(REPLACE_ASSET_FROM_RIGHT_CLICK_ERROR);

export const createAssetCategoryRequest = createAction(CREATE_ASSET_CATEGORY_REQUEST);
export const createAssetCategorySuccess = createAction(CREATE_ASSET_CATEGORY_SUCCESS);
export const createAssetCategoryError = createAction(CREATE_ASSET_CATEGORY_ERROR);

export const updateAssetCategoryNameRequest = createAction(UPDATE_ASSET_CATEGORY_NAME_REQUEST);
export const updateAssetCategoryNameSuccess = createAction(UPDATE_ASSET_CATEGORY_NAME_SUCCESS);
export const updateAssetCategoryNameError = createAction(UPDATE_ASSET_CATEGORY_NAME_ERROR);

export const deleteAssetCategoryRequest = createAction(DELETE_ASSET_CATEGORY_REQUEST);
export const deleteAssetCategorySuccess = createAction(DELETE_ASSET_CATEGORY_SUCCESS);
export const deleteAssetCategoryError = createAction(DELETE_ASSET_CATEGORY_ERROR);

export const createLogoCategoryRequest = createAction(CREATE_LOGO_CATEGORY_REQUEST);
export const createLogoCategorySuccess = createAction(CREATE_LOGO_CATEGORY_SUCCESS);
export const createLogoCategoryError = createAction(CREATE_LOGO_CATEGORY_ERROR);

export const updateLogoCategoryNameRequest = createAction(UPDATE_LOGO_CATEGORY_NAME_REQUEST);
export const updateLogoCategoryNameSuccess = createAction(UPDATE_LOGO_CATEGORY_NAME_SUCCESS);
export const updateLogoCategoryNameError = createAction(UPDATE_LOGO_CATEGORY_NAME_ERROR);

export const deleteLogoCategoryRequest = createAction(DELETE_LOGO_CATEGORY_REQUEST);
export const deleteLogoCategorySuccess = createAction(DELETE_LOGO_CATEGORY_SUCCESS);
export const deleteLogoCategoryError = createAction(DELETE_LOGO_CATEGORY_ERROR);

export const createColorPaletteRequest = createAction(CREATE_COLOR_PALETTE_REQUEST);
export const createColorPaletteSuccess = createAction(CREATE_COLOR_PALETTE_SUCCESS);
export const createColorPaletteError = createAction(CREATE_COLOR_PALETTE_ERROR);

export const updatePaletteNameRequest = createAction(UPDATE_PALETTE_NAME_REQUEST);
export const updatePaletteNameSuccess = createAction(UPDATE_PALETTE_NAME_SUCCESS);
export const updatePaletteNameError = createAction(UPDATE_PALETTE_NAME_ERROR);

export const deleteColorPaletteRequest = createAction(DELETE_COLOR_PALETTE_REQUEST);
export const deleteColorPaletteSuccess = createAction(DELETE_COLOR_PALETTE_SUCCESS);
export const deleteColorPaletteError = createAction(DELETE_COLOR_PALETTE_ERROR);

export const createPaletteColorRequest = createAction(CREATE_PALETTE_COLOR_REQUEST);
export const createPaletteColorSuccess = createAction(CREATE_PALETTE_COLOR_SUCCESS);
export const createPaletteColorError = createAction(CREATE_PALETTE_COLOR_ERROR);

export const updatePaletteColorRequest = createAction(UPDATE_PALETTE_COLOR_REQUEST);
export const updatePaletteColorSuccess = createAction(UPDATE_PALETTE_COLOR_SUCCESS);
export const updatePaletteColorError = createAction(UPDATE_PALETTE_COLOR_ERROR);

export const deletePaletteColorRequest = createAction(DELETE_PALETTE_COLOR_REQUEST);
export const deletePaletteColorSuccess = createAction(DELETE_PALETTE_COLOR_SUCCESS);
export const deletePaletteColorError = createAction(DELETE_PALETTE_COLOR_ERROR);

export const addFontRequest = createAction(ADD_FONT_REQUEST);
export const addFontSuccess = createAction(ADD_FONT_SUCCESS);
export const addFontError = createAction(ADD_FONT_ERROR);

export const uploadFontsRequest = createAction(UPLOAD_FONTS_REQUEST);
export const uploadFontsSuccess = createAction(UPLOAD_FONTS_SUCCESS);
export const uploadFontsError = createAction(UPLOAD_FONTS_ERROR);

export const updateFontRequest = createAction(UPDATE_FONT_REQUEST);
export const updateFontSuccess = createAction(UPDATE_FONT_SUCCESS);
export const updateFontError = createAction(UPDATE_FONT_ERROR);

export const deleteFontRequest = createAction(DELETE_FONT_REQUEST);
export const deleteFontSuccess = createAction(DELETE_FONT_SUCCESS);
export const deleteFontError = createAction(DELETE_FONT_ERROR);

export const deleteUploadedFontRequest = createAction(DELETE_UPLOADED_FONT_REQUEST);
export const deleteUploadedFontSuccess = createAction(DELETE_UPLOADED_FONT_SUCCESS);
export const deleteUploadedFontError = createAction(DELETE_UPLOADED_FONT_ERROR);

export const updateTextWithFontStyle = createAction(UPDATE_TEXT_WITH_FONT_STYLE);

export const updateProjectWithFontStyleSuccess =
  createAction(UPDATE_PROJECT_WITH_FONT_STYLE_SUCCESS);
export const updateProjectWithFontStyleRequest =
  createAction(UPDATE_PROJECT_WITH_FONT_STYLE_REQUEST);
export const updateProjectWithFontStyleError =
  createAction(UPDATE_PROJECT_WITH_FONT_STYLE_ERROR);

export const saveSearchTermBrandLibrary = createAction(SAVE_SEARCH_TERM_BRAND_LIBRARY);

export const searchInBrandLibraryRequest = createAction(SEARCH_IN_BRAND_LIBRARY_REQUEST);
export const searchInBrandLibrarySuccess = createAction(SEARCH_IN_BRAND_LIBRARY_SUCCESS);
export const searchInBrandLibraryError = createAction(SEARCH_IN_BRAND_LIBRARY_ERROR);

export const clearSearchInBrandLibrary = createAction(CLEAR_SEARCH_IN_BRAND_LIBRARY);

export const showAssetDetailsRequest = createAction(SHOW_ASSET_DETAILS_REQUEST);
export const showAssetDetailsSuccess = createAction(SHOW_ASSET_DETAILS_SUCCESS);
export const showAssetDetailsError = createAction(SHOW_ASSET_DETAILS_ERROR);
export const showAssetDetailsReset = createAction(SHOW_ASSET_DETAILS_RESET);

export const showLogoDetailsRequest = createAction(SHOW_LOGO_DETAILS_REQUEST);
export const showLogoDetailsSuccess = createAction(SHOW_LOGO_DETAILS_SUCCESS);
export const showLogoDetailsError = createAction(SHOW_LOGO_DETAILS_ERROR);
export const showLogoDetailsReset = createAction(SHOW_LOGO_DETAILS_RESET);

export const receiveAssetMetadata = createAction(RECEIVE_ASSET_METADATA);
export const receiveLogoMetadata = createAction(RECEIVE_LOGO_METADATA);

const getBrandLibraryFromAccount = async (accountId, disabled, includeRelations) => {
  const { data: { brandLibrary } } = await BrandLibraryService.getFromAccountId(
    accountId,
    includeRelations.toString(),
  );
  if (!brandLibrary && !disabled) {
    const { data: { brandLibrary: library } } = await BrandLibraryService.create(accountId);
    return library;
  }
  return brandLibrary;
};

export const getUserWithBrandLibrary = (
  accountId,
  includeRelations = ['colors', 'logos', 'assets'],
) => async dispatch => {
  try {
    dispatch(getUserWithBrandLibraryRequest());
    const { data } = await UserService.getUser();
    const user = serializeUserAccounts(data.user);
    const currentAccountId = accountId || user.defaultAccountId;

    const disabled = !BRAND_LIBRARY_PLANS.includes(getMembershipPlan(user, currentAccountId)) ||
      !isSubscriptionActive(user, currentAccountId);
    const library = await getBrandLibraryFromAccount(currentAccountId, disabled, includeRelations);
    dispatch(getUserWithBrandLibrarySuccess({ user, library, disabled }));
  } catch (error) {
    dispatch(getUserWithBrandLibraryError(error?.data?.message));
    Sentry.captureException(error);
  }
};

export const getLogosOfCategory = (categoryId, page, perPage) => async dispatch => {
  try {
    dispatch(getLogosOfCategoryRequest());
    const { data } = await LogoCategoryService.getLogosFromCategory(categoryId, page, perPage);
    dispatch(getLogosOfCategorySuccess({
      categoryId,
      logos: data.logos,
      hasNextPage: data.logos.length === perPage,
    }));
  } catch (error) {
    dispatch(getLogosOfCategoryError(error?.data?.message));
  }
};

export const getAssetsOfCategory = (categoryId, page, perPage) => async dispatch => {
  try {
    dispatch(getAssetsOfCategoryRequest());
    const { data } = await AssetCategoryService.getAssetsFromCategory(categoryId, page, perPage);
    dispatch(getAssetsOfCategorySuccess({
      categoryId,
      assets: data.assets,
      hasNextPage: data.assets.length === perPage,
    }));
  } catch (error) {
    dispatch(getAssetsOfCategoryError(error?.data?.message));
  }
};

const saveLocalFiles = async (filesToSave, folder) => (
  promiseInListWithLimit(filesToSave, async file => {
    const { data: { url } } = await BrandLibraryService.saveFileFromLocal(file, folder);
    return url;
  })
);

export const addAssetsToBrandLibrary = (assetCategoryId, filesToSave) => async dispatch => {
  try {
    dispatch(addAssetsToBrandLibraryRequest({
      files: filesToSave,
      assetCategoryId,
    }));

    toast(
      <ToastMarkup emoji="🛎">
        <b>Don&apos;t be afraid to explore.</b><br />
        We&apos;ll ping you when they&apos;re uploaded.
      </ToastMarkup>,
      toastSuccessMarkupConfig,
    );

    await promiseInListWithLimit(filesToSave, async file => {
      dispatch(addSingleAssetToCategoryRequest());
      const { data: { asset } } = await AssetCategoryService.createAsset(assetCategoryId, file);
      dispatch(addSingleAssetToCategorySuccess({ assetCategoryId, asset }));
      return asset;
    });

    dispatch(addAssetsToBrandLibrarySuccess({ assetCategoryId }));
    toast(
      <ToastMarkup emoji="😍">
        Ping! Assets are uploaded and lookin&apos; good.
      </ToastMarkup>,
      toastSuccessMarkupConfig,
    );
    trackAddAssetToBrandLibrary();
  } catch (error) {
    dispatch(addAssetsToBrandLibraryError({ assetCategoryId }));
    toast.error('Error while importing the media', toastErrorConfig);
  }
};

export const addLogosToBrandLibrary = (logoCategoryId, filesToSave) => async dispatch => {
  try {
    dispatch(addLogosToBrandLibraryRequest({
      files: filesToSave,
      logoCategoryId,
    }));

    toast(
      <ToastMarkup emoji="🛎">
        <b>Don&apos;t be afraid to explore.</b><br />
        We&apos;ll ping you when they&apos;re uploaded.
      </ToastMarkup>,
      toastSuccessMarkupConfig,
    );

    await promiseInListWithLimit(filesToSave, async file => {
      dispatch(addSingleLogoToCategoryRequest());
      const { data: { logo } } = await LogoCategoryService.createLogo(logoCategoryId, file);
      dispatch(addSingleLogoToCategorySuccess({ logoCategoryId, logo }));
      return logo;
    });

    dispatch(addLogosToBrandLibrarySuccess({ logoCategoryId }));
    toast(
      <ToastMarkup emoji="😍">
        Ping! Logos are uploaded and lookin&apos; good.
      </ToastMarkup>,
      toastSuccessMarkupConfig,
    );
    trackAddLogoToBrandLibrary();
  } catch (error) {
    dispatch(addLogosToBrandLibraryError({ logoCategoryId }));
    toast.error('Error while importing the media', toastErrorConfig);
  }
};

export const deleteAssetFromBrandLibrary = (assetCategoryId, assetId) => async dispatch => {
  try {
    dispatch(deleteAssetFromBrandLibraryRequest());
    await AssetCategoryService.deleteAsset(assetCategoryId, assetId);
    dispatch(deleteAssetFromBrandLibrarySuccess({ assetCategoryId, assetId }));
    trackDeleteAssetToBrandLibrary();
  } catch (error) {
    dispatch(deleteAssetFromBrandLibraryError());
    toast.error('Error while deleting the asset', toastErrorConfig);
  }
};

export const deleteLogoFromBrandLibrary = (logoCategoryId, logoId) => async dispatch => {
  try {
    dispatch(deleteLogoFromBrandLibraryRequest());
    await LogoCategoryService.deleteLogo(logoCategoryId, logoId);
    dispatch(deleteLogoFromBrandLibrarySuccess({ logoCategoryId, logoId }));
    trackDeleteLogoToBrandLibrary();
  } catch (error) {
    dispatch(deleteLogoFromBrandLibraryError());
    toast.error('Error while deleting the logo', toastErrorConfig);
  }
};

export const addAssetsToProject = (assets, x, y) => async dispatch => {
  try {
    dispatch(addAssetsToProjectRequest());
    const duplicatedFiles = await duplicateAssetFiles(assets);
    dispatch(addMediaElements({ media: duplicatedFiles, x, y }));
    dispatch(addAssetsToProjectSuccess());
  } catch (error) {
    dispatch(addAssetsToProjectError());
    toast.error('Error while importing the assets', toastErrorConfig);
  }
};

export const replaceAssetFromRightClick = (asset, replaceableImage) => async dispatch => {
  try {
    dispatch(replaceAssetFromRightClickRequest());
    const [duplicatedFile] = await duplicateAssetFiles([asset]);
    dispatch(replaceImageFromRightClick({ media: duplicatedFile, replaceableImage }));
    dispatch(replaceAssetFromRightClickSuccess());
  } catch (error) {
    dispatch(replaceAssetFromRightClickError());
  }
};

export const createAssetCategory = (id, params) => async dispatch => {
  try {
    dispatch(createAssetCategoryRequest());
    const { data: { assetCategory } } = await BrandLibraryService.createAssetCategory(id, params);
    dispatch(createAssetCategorySuccess({ assetCategory }));
    trackCreateAssetCategoryOfBrandLibrary();
  } catch (error) {
    dispatch(createAssetCategoryError());
    throw error.data.details || {};
  }
};

export const createLogoCategory = (id, params) => async dispatch => {
  try {
    dispatch(createLogoCategoryRequest());
    const { data: { logoCategory } } = await BrandLibraryService.createLogoCategory(id, params);
    dispatch(createLogoCategorySuccess({ logoCategory }));
    trackCreateLogoCategoryOfBrandLibrary();
  } catch (error) {
    dispatch(createLogoCategoryError());
    throw error.data.details || {};
  }
};

export const createColorPalette = (id, params) => async dispatch => {
  try {
    dispatch(createColorPaletteRequest());
    const { data: { colorPalette } } = await BrandLibraryService.createColorPalette(id, params);
    dispatch(createColorPaletteSuccess({ colorPalette }));
    trackCreateColorPaletteOfBrandLibrary();
  } catch (error) {
    dispatch(createColorPaletteError());
    throw error.data.details || {};
  }
};

export const updateAssetCategoryName = (id, assetCategoryId, params) => async dispatch => {
  try {
    dispatch(updateAssetCategoryNameRequest());
    const { data: { assetCategory: { name } } } = await BrandLibraryService
      .updateAssetCategory(id, assetCategoryId, params);
    dispatch(updateAssetCategoryNameSuccess({ id: assetCategoryId, name }));
  } catch (error) {
    dispatch(updateAssetCategoryNameError());
    if (error.data.details) {
      throw error.data.details;
    }
  }
};

export const updateLogoCategoryName = (id, logoCategoryId, params) => async dispatch => {
  try {
    dispatch(updateLogoCategoryNameRequest());
    const { data: { logoCategory: { name } } } = await BrandLibraryService
      .updateLogoCategory(id, logoCategoryId, params);
    dispatch(updateLogoCategoryNameSuccess({ id: logoCategoryId, name }));
  } catch (error) {
    dispatch(updateLogoCategoryNameError());
    if (error.data.details) {
      throw error.data.details;
    }
  }
};

export const deleteAssetCategory = (id, assetCategoryId) => async dispatch => {
  try {
    dispatch(deleteAssetCategoryRequest());
    await BrandLibraryService.deleteAssetCategory(id, assetCategoryId);
    dispatch(deleteAssetCategorySuccess({ assetCategoryId }));
    trackDeleteAssetCategoryOfBrandLibrary();
  } catch (error) {
    dispatch(deleteAssetCategoryError());
  }
};

export const deleteLogoCategory = (id, logoCategoryId) => async dispatch => {
  try {
    dispatch(deleteLogoCategoryRequest());
    await BrandLibraryService.deleteLogoCategory(id, logoCategoryId);
    dispatch(deleteLogoCategorySuccess({ logoCategoryId }));
    trackDeleteLogoCategoryOfBrandLibrary();
  } catch (error) {
    dispatch(deleteLogoCategoryError());
  }
};

export const updatePaletteName = (id, paletteId, params) => async dispatch => {
  try {
    dispatch(updatePaletteNameRequest());
    const { data: { colorPalette: { name } } } = await BrandLibraryService
      .updateColorPalette(id, paletteId, params);
    dispatch(updatePaletteNameSuccess({ id: paletteId, name }));
  } catch (error) {
    dispatch(updatePaletteNameError());
    if (error.data.details) {
      throw error.data.details;
    }
  }
};

export const deleteColorPalette = (id, paletteId) => async dispatch => {
  try {
    dispatch(deleteColorPaletteRequest());
    await BrandLibraryService.deleteColorPalette(id, paletteId);
    dispatch(deleteColorPaletteSuccess({ paletteId }));
    trackDeleteColorPaletteOfBrandLibrary();
  } catch (error) {
    dispatch(deleteColorPaletteError());
  }
};

export const createPaletteColor = (paletteId, params) => async dispatch => {
  try {
    dispatch(createPaletteColorRequest());
    const { data: { color } } = await ColorPaletteService.createColor(paletteId, params);
    dispatch(createPaletteColorSuccess({ paletteId, color }));
    trackAddColorToPaletteOfBrandLibrary();
  } catch (error) {
    dispatch(createPaletteColorError());
    toast.error(error?.data?.message, toastErrorConfig);
  }
};

export const updatePaletteColor = (paletteId, colorId, params) => async dispatch => {
  try {
    dispatch(updatePaletteColorRequest());
    const { data: { color } } = await ColorPaletteService
      .updateColor(paletteId, colorId, params);
    dispatch(updatePaletteColorSuccess({ paletteId, color }));
  } catch (error) {
    dispatch(updatePaletteColorError());
    toast.error(error?.data?.message, toastErrorConfig);
  }
};

export const deletePaletteColor = (paletteId, colorId) => async dispatch => {
  try {
    dispatch(deletePaletteColorRequest());
    await ColorPaletteService.deleteColor(paletteId, colorId);
    dispatch(deletePaletteColorSuccess({ paletteId, colorId }));
    trackDeleteColorToPaletteOfBrandLibrary();
  } catch (error) {
    dispatch(deletePaletteColorError());
    toast.error('Error while deleting the color', toastErrorConfig);
  }
};

export const addFont = (id, params) => async dispatch => {
  try {
    dispatch(addFontRequest());
    const { data: { font } } = await BrandLibraryService.createFont(id, params);
    dispatch(addFontSuccess({ font }));
    trackAddFontStyleToBrandLibrary(font.uploadType);
  } catch (error) {
    dispatch(addFontError());
    toast.error('Error while adding the font', toastErrorConfig);
  }
};

const uploadFonts = async (fontsToUpload, id) => (
  promiseInListWithLimit(fontsToUpload, async font => {
    const { data: { uploadedFont } } = await BrandLibraryService.createUploadedFont(id, font);
    return uploadedFont;
  })
);

const getFontsToUploadWithName = (savedFiles) => (
  savedFiles.map(url => {
    const filename = getCloudinaryFilenameFromUrl(url);
    const nameWithoutTimestamp = getCloudinaryFilenameWithoutTimestamp(filename);
    const filenameParts = nameWithoutTimestamp.split('.');
    const name = filenameParts[0].replace(/_|-/g, ' ');
    return {
      name,
      url,
    };
  })
);

export const uploadFontsToBrandLibrary = (id, filesToSave) => async dispatch => {
  try {
    dispatch(uploadFontsRequest());
    const savedFiles = await saveLocalFiles(filesToSave, 'fonts');
    const fontsToUpload = getFontsToUploadWithName(savedFiles);
    const uploadedFonts = await uploadFonts(fontsToUpload, id);
    dispatch(uploadFontsSuccess({ uploadedFonts }));
    toast.success('Font uploaded successfully', toastSuccessConfig);
    trackUploadFontToBrandLibrary();
  } catch (error) {
    toast.error('Error while uploading the font', toastErrorConfig);
    dispatch(uploadFontsError());
  }
};

export const updateFont = (id, fontId, params) => async dispatch => {
  try {
    dispatch(updateFontRequest());
    const { data: { font } } = await BrandLibraryService.updateFont(id, fontId, params);
    dispatch(updateFontSuccess({ font }));
    trackUpdateFontStyleOfBrandLibrary(font.uploadType);
  } catch (error) {
    dispatch(updateFontError());
  }
};

export const deleteFont = (id, fontId, uploadType) => async dispatch => {
  try {
    dispatch(deleteFontRequest());
    await BrandLibraryService.deleteFont(id, fontId);
    dispatch(deleteFontSuccess({ fontId }));
    trackDeleteFontStyleOfBrandLibrary(uploadType);
  } catch (error) {
    toast.error('Error while deleting the font style', toastErrorConfig);
    dispatch(deleteFontError());
  }
};

const updateDeletedFonts = async (fontsToUpdate, id) => {
  const params = {
    fontFamily: DEFAULT_FONT_FAMILY.value,
    fontStyle: DEFAULT_FONT_STYLE.value,
    uploadType: GOOGLE_UPLOAD,
  };
  return Promise.all(fontsToUpdate.map(async ({ id: fontId }) => {
    const { data: { font } } = await BrandLibraryService.updateFont(id, fontId, params);
    return font;
  }));
};

export const deleteUploadedFont = (id, uploadedFontId, fontsToUpdate = []) => async dispatch => {
  try {
    dispatch(deleteUploadedFontRequest());
    await BrandLibraryService.deleteUploadedFont(id, uploadedFontId);
    const updatedFonts = await updateDeletedFonts(fontsToUpdate, id);
    dispatch(deleteUploadedFontSuccess({ uploadedFontId, updatedFonts }));
    trackDeleteUploadedFontOfBrandLibrary();
  } catch (error) {
    toast.error('Error while deleting the font', toastErrorConfig);
    dispatch(deleteUploadedFontError());
  }
};

export const updateProjectWithFontStyle = () => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(updateProjectWithFontStyleRequest());
    const params = {
      elements: JSON.stringify(state.project.present.elements),
      groupsOfElementsById: JSON.stringify(state.project.present.groupsOfElementsById),
    };
    const { id } = state.project.present;
    const { data: { project } } = await ProjectService.updateProject(id, params);
    dispatch(updateProjectWithFontStyleSuccess({ project }));
  } catch (error) {
    dispatch(updateProjectWithFontStyleError());
  }
};

export const searchInBrandLibrary = (accountId, searchTerm, page, perPage) => async dispatch => {
  try {
    dispatch(searchInBrandLibraryRequest());
    const { data } = await BrandLibraryService.search(accountId, searchTerm, page, perPage);
    dispatch(searchInBrandLibrarySuccess({
      ...data,
      hasNextPage: data.searchMatches.length === perPage,
    }));
  } catch (err) {
    dispatch(searchInBrandLibraryError(err));
  }
};

export const showAssetDetails = (asset) => async dispatch => {
  dispatch(showAssetDetailsRequest());
  try {
    await AssetService.getAssetDetails(asset.id);
  } catch (error) {
    dispatch(showAssetDetailsError());
  }
  dispatch(showAssetDetailsSuccess({
    asset,
  }));
};

export const refetchBrandLibrary = (userParams, accountId) => async dispatch => {
  try {
    await AccountService.updateAccount(accountId, userParams);
    dispatch(getUserWithBrandLibrary(accountId));
  } catch ({ data }) {}
};

export const showLogoDetails = (logo) => async dispatch => {
  dispatch(showLogoDetailsRequest());
  try {
    await LogoService.getLogoDetails(logo.id);
  } catch (error) {
    dispatch(showLogoDetailsError());
  }
  dispatch(showLogoDetailsSuccess({
    logo,
  }));
};
