import {
  IMAGE_ELEMENT,
  LINE_ELEMENT,
  SQUARE_ELEMENT,
  ROUNDED_RECT_ELEMENT,
  VIDEO_ELEMENT,
} from 'src/constants/canvasElements';

export const decompose = mat => {
  const a = mat[0];
  const b = mat[1];
  const c = mat[2];
  const d = mat[3];
  const e = mat[4];
  const f = mat[5];

  const delta = a * d - b * c;

  const result = {
    x: e,
    y: f,
    rotation: 0,
    scaleX: 0,
    scaleY: 0,
    skewX: 0,
    skewY: 0,
  };

  // Apply the QR-like decomposition.
  if (a !== 0 || b !== 0) {
    const r = Math.sqrt(a * a + b * b);
    result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r);
    result.scaleX = r;
    result.scaleY = delta / r;
    result.skewX = Math.atan((a * c + b * d) / (r * r));
  } else if (c !== 0 || d !== 0) {
    const s = Math.sqrt(c * c + d * d);
    result.rotation =
      Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s));
    result.scaleX = delta / s;
    result.scaleY = s;
    result.skewX = 0;
    result.skewY = Math.atan((a * c + b * d) / (s * s));
  } else {
    // For future reference
    // a = b = c = d = 0
  }

  result.rotation *= 180 / Math.PI;
  return result;
};

export const getGroupItemAttrs = (
  attrs, elementsInGroup, index, groupRotation, scale, canvasAttrs,
) => {
  const rotation = elementsInGroup[index].rotation + groupRotation;
  const rotationRad = rotation * (Math.PI / 180);

  const attrsItem = {
    x: attrs.x * scale - canvasAttrs.x,
    y: attrs.y * scale - canvasAttrs.y,
    width: elementsInGroup[index].width,
    height: elementsInGroup[index].height,
    scaleX: attrs.scaleX * scale,
    scaleY: attrs.scaleY * scale,
    rotation,
  };

  const halfWidth = (attrsItem.width * attrsItem.scaleX) / 2;
  const halfHeight = (attrsItem.height * attrsItem.scaleY) / 2;

  const x = attrsItem.x
    + Math.cos(rotationRad) * halfWidth + Math.sin(-rotationRad) * halfHeight;
  const y = attrsItem.y
    + Math.cos(rotationRad) * halfHeight + Math.sin(rotationRad) * halfWidth;

  let item;
  switch (elementsInGroup[index].type) {
    case VIDEO_ELEMENT:
    case IMAGE_ELEMENT:
    case SQUARE_ELEMENT:
      item = {
        ...elementsInGroup[index],
        x,
        y,
        scaleX: attrsItem.scaleX,
        scaleY: attrsItem.scaleY,
        skewX: attrsItem.skewX,
        skewY: attrsItem.skewY,
        rotation,
      };
      return item;
    case ROUNDED_RECT_ELEMENT:
      item = {
        ...elementsInGroup[index],
        x,
        y,
        width: attrsItem.width * attrsItem.scaleX,
        height: attrsItem.height * attrsItem.scaleY,
        skewX: attrsItem.skewX,
        skewY: attrsItem.skewY,
        scaleY: attrsItem.scaleY,
        rotation,
      };
      return item;
    case LINE_ELEMENT:
      item = {
        ...elementsInGroup[index],
        x: attrsItem.x + Math.sin(-rotationRad) + Math.cos(rotationRad) * halfWidth,
        y: attrsItem.y + Math.sin(rotationRad) * halfWidth,
        scaleX: attrsItem.scaleX,
        skewX: attrsItem.skewX,
        rotation,
      };
      return item;
    default:
      item = {
        ...elementsInGroup[index],
        x: attrsItem.x,
        y: attrsItem.y,
        scaleX: attrsItem.scaleX,
        scaleY: attrsItem.scaleY,
        skewX: attrsItem.skewX,
        skewY: attrsItem.skewY,
        rotation,
      };
      return item;
  }
};
