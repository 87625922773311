import React from 'react';
import { Link } from 'react-router-dom';
import { getYear } from 'date-fns';

import { LANDING_PAGE_LINK } from 'src/constants/general';
import Logotype from 'src/assets/icons/logotype.svg';
import { routesPaths } from 'src/routes/routesPaths';

import styles from './Footer.module.scss';

const Footer = () => {
  const now = new Date();
  const year = getYear(now);

  return (
    <div className={styles.footer}>
      <div className={styles.logos}>
        <a href={LANDING_PAGE_LINK}>
          <img src={Logotype} alt="Stagger logotype" />
        </a>
        <span className={styles.copyrights}>
          © Copyright {year} Stagger Inc. All rights reserved.
        </span>
      </div>
      <div className={styles.links}>
        <Link
          to={routesPaths.terms}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Terms of use
        </Link>
        <Link
          to={routesPaths.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export { Footer };
