import { v4 as uuidv4 } from 'uuid';

import {
  GROUP_ELEMENT,
  IMAGE_ELEMENT,
  MEDIA_ELEMENTS,
  VIDEO_ELEMENT,
} from 'src/constants/canvasElements';
import { deleteFromGifList, deleteFromVideoList } from './videoHelpers';
import { isGif } from './helpers';

export const findInGroupAndReplace = (group, groupsOfElementsById, id, getNewAttrs) => {
  const elementsInGroup = groupsOfElementsById[group.uuid];
  let found = false;
  groupsOfElementsById[group.uuid] = elementsInGroup.map(elem => {
    if (elem.uuid === id) {
      found = true;
      const newAtts = getNewAttrs(elem);
      return { ...elem, ...newAtts };
    }
    return elem;
  });
  if (!found) {
    const groupsInGroup = elementsInGroup.filter(elem => elem.type === GROUP_ELEMENT);
    groupsInGroup.forEach(groupInGroup => {
      findInGroupAndReplace(groupInGroup, groupsOfElementsById, id, getNewAttrs);
    });
  }
};

export const deleteGroup = (state, group) => {
  const groupElements = state.groupsOfElementsById[group.uuid];
  const mediaInGroup = groupElements.filter(element => MEDIA_ELEMENTS.includes(element.type));
  if (mediaInGroup.length) {
    mediaInGroup.forEach(file => {
      if (file.type === VIDEO_ELEMENT) {
        deleteFromVideoList(state, file);
      } else if (file.type === IMAGE_ELEMENT && isGif(file.src)) {
        deleteFromGifList(state, file);
      }
    });
  }
  const groupsInGroup = groupElements.filter(element => element.type === GROUP_ELEMENT);
  groupsInGroup.forEach(groupInGroup => {
    deleteGroup(state, groupInGroup);
    delete state.groupsOfElementsById[groupInGroup.uuid];
  });
  delete state.groupsOfElementsById[group.uuid];
};

export const copyGroup = (state, uuidToClone, newUuid) => {
  const groupElements = state.groupsOfElementsById[uuidToClone];
  const result = groupElements.reduce((res, element) => {
    const newElement = { ...element };
    if (MEDIA_ELEMENTS.includes(element.type)) {
      element.duplicated = true;
    }
    const uuid = uuidv4();
    if (newElement.type === GROUP_ELEMENT) {
      copyGroup(state, newElement.uuid, uuid);
    }
    newElement.uuid = uuid;
    res.push(newElement);
    return res;
  }, []);
  state.groupsOfElementsById[newUuid] = result;
};

export const getFirstSingleElement = (group, groupsOfElementsById, accGroupX, accGroupY) => {
  const elementsInGroup = groupsOfElementsById[group.uuid];
  let result;
  const elementFound = elementsInGroup.find(elem => elem.type !== GROUP_ELEMENT);
  if (elementFound) {
    result = {
      x: elementFound.x,
      y: elementFound.y,
      accGroupX,
      accGroupY,
    };
  }
  if (!result) {
    const groupsInGroup = elementsInGroup.filter(elem => elem.type === GROUP_ELEMENT);
    const firstGroup = groupsInGroup[0];
    result = getFirstSingleElement(
      firstGroup,
      groupsOfElementsById,
      accGroupX + (firstGroup.x || 0),
      accGroupY + (firstGroup.y || 0),
    );
  }
  return result;
};
