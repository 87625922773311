import { HttpClient } from 'src/clients';

class LogoCategoryService {
  static getLogosFromCategory(id, page, perPage) {
    return HttpClient.get(`/logo-categories/${id}/logos`, { params: { page, perPage } });
  }

  static createLogo(id, logo) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', logo);
    return HttpClient.post(`/logo-categories/${id}/logo`, formData, config);
  }

  static deleteLogo(id, logoId) {
    return HttpClient.delete(`/logo-categories/${id}/logo/${logoId}`);
  }
}

export { LogoCategoryService };
