import {
  Home,
  NotFound,
  Login,
  Project,
  Projects,
  Signup,
  ExportPreview,
  Terms,
  PrivacyPolicy,
  BrandLibrary,
  Profile,
  ForgotPassword,
  ChangePassword,
  UnsubscribeToEmail,
  ChoosePlan,
  Dashboard,
  Onboarding,
  AccountSettings,
  DowngradeAgency,
  AccountInvite,
  Templates,
  ExportPreviewBreakDown,
} from 'src/pages';
import {
  Closest,
  AccountProfile,
} from 'src/pages/public-pages';
import { isPublicPages } from 'src/utils/helpers';
import { routesPaths, publicPagesRoutesPaths } from './routesPaths';

const routes = [
  {
    exact: true,
    path: routesPaths.index,
    component: Home,
  },
  {
    exact: true,
    path: routesPaths.signup,
    component: Signup,
  },
  {
    exact: true,
    path: routesPaths.signin,
    component: Login,
  },
  {
    exact: true,
    path: routesPaths.projects,
    component: Projects,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.project,
    component: Project,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.preview,
    component: ExportPreview,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.previewBreakDown,
    component: ExportPreviewBreakDown,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.brandLibrary,
    component: BrandLibrary,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.terms,
    component: Terms,
  },
  {
    exact: true,
    path: routesPaths.privacyPolicy,
    component: PrivacyPolicy,
  },
  {
    exact: true,
    path: routesPaths.profile,
    component: Profile,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.accountSettings,
    component: AccountSettings,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.forgotPassword,
    component: ForgotPassword,
  },
  {
    exact: true,
    path: routesPaths.changePassword,
    component: ChangePassword,
  },
  {
    exact: true,
    path: routesPaths.unsubscribeToEmail,
    component: UnsubscribeToEmail,
  },
  {
    exact: true,
    path: routesPaths.choosePlan,
    component: ChoosePlan,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.dashboard,
    component: Dashboard,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.onboarding,
    component: Onboarding,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.downgradeAgency,
    component: DowngradeAgency,
    authenticated: true,
  },
  {
    exact: true,
    path: routesPaths.accountInvite,
    component: AccountInvite,
  },
  {
    exact: true,
    path: routesPaths.templates,
    component: Templates,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const publicPagesRoutes = [
  {
    exact: true,
    path: publicPagesRoutesPaths.closest,
    component: Closest,
  },
  {
    exact: true,
    path: publicPagesRoutesPaths.accountProfile,
    component: AccountProfile,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default isPublicPages() ? publicPagesRoutes : routes;
