import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT,
  EDITABLE_TITLE_FRAME_TYPE,
} from 'src/constants/general';
import { setEditableFrameTitle } from 'src/actions/canvasActions';
import EditIcon from 'src/assets/icons/edit.svg';
import styles from './CanvasRightClickOptionsTitle.module.scss';

const CanvasRightClickOptionsTitle = () => {
  const dispatch = useDispatch();

  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  const rename = useCallback(() => {
    dispatch(setEditableFrameTitle({
      frame: rightClickOptions.frame,
      type: EDITABLE_TITLE_FRAME_TYPE.TITLE_INPUT,
    }));
  }, [dispatch, rightClickOptions]);

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: rightClickOptions.y,
        left: rightClickOptions.x,
        height: `${CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT}rem`,
        width: `${CANVAS_RIGHT_CLICK_OPTIONS_WIDTH}rem`,
      }}
    >
      <button
        className={styles.option}
        onClick={rename}
      >
        <img src={EditIcon} alt="Rename" />
        Rename
      </button>
    </div>,
    document.getElementById('root'),
  );
};

export { CanvasRightClickOptionsTitle };
