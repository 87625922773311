import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string, arrayOf, shape } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LOADING, SUCCESS } from 'src/constants/status';
import {
  updateLogoCategoryName,
  deleteLogoCategory,
  addLogosToBrandLibrary,
  ADD_LOGOS_TO_BRAND_LIBRARY,
  SHOW_LOGO_DETAILS,
  getLogosOfCategory,
  GET_LOGOS_OF_CATEGORY,
} from 'src/actions/brandLibraryActions';
import { LoadingCard } from 'src/common/loading-card';
import { useStatus, usePagination } from 'src/hooks';
import { UploadFilesButton } from '../upload-files-button';
import { Logo } from './logo';
import { DeleteLogoCategoryModal } from './delete-logo-category-modal';
import { CategoryName } from '../category-name';
import { LogoDetailsModal } from './logo-details-modal';
import { EmptyFilesDropzone } from '../empty-files-dropzone';
import styles from './LogoCategory.module.scss';

const SCROLLABLE_CONTAINER_ID = 'logosScrollableContainer';

const LogoCategory = ({ id, name, logos }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { status: addLogosStatus } = useStatus(ADD_LOGOS_TO_BRAND_LIBRARY);

  const { status: showDetailsStatus } = useStatus(SHOW_LOGO_DETAILS);

  const { id: libraryId, loadingLogos } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
    loadingLogos: brandLibrary.loadingLogos,
  }));

  const deleteCategory = useCallback(() => {
    dispatch(deleteLogoCategory(libraryId, id));
  }, [dispatch, id, libraryId]);

  const onClickDelete = useCallback(() => {
    if (logos?.length) {
      setModalOpen(true);
    } else {
      deleteCategory();
    }
  }, [logos, deleteCategory]);

  const uploadFiles = (filesToSave) => {
    dispatch(addLogosToBrandLibrary(id, filesToSave));
  };

  const updateCategory = async (params) => {
    await dispatch(updateLogoCategoryName(libraryId, id, params));
  };

  const getLogosRequest = useCallback(async page => {
    const perPage = 12;
    await dispatch(getLogosOfCategory(id, page, perPage));
  }, [dispatch, id]);

  const { nextPage } = usePagination(getLogosRequest);

  const {
    status: getLogosStatus,
    statusInfo: getLogosStatusInfo,
  } = useStatus(GET_LOGOS_OF_CATEGORY);

  useEffect(() => {
    if (!logos) {
      getLogosRequest(1);
    }
  }, [getLogosRequest, logos]);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (getLogosStatus !== LOADING && getLogosStatusInfo?.hasNextPage &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [getLogosStatus, nextPage, getLogosStatusInfo]);

  return (
    <>
      <div className={styles.header}>
        <CategoryName
          name={name}
          onClickDelete={onClickDelete}
          updateCategory={updateCategory}
        />
        <UploadFilesButton uploadFiles={uploadFiles} acceptType="image/*, .heic" />
      </div>
      <div className={styles.logos} id={SCROLLABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={logos?.length || 0}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {logos?.map(logo => <Logo key={logo.url} logo={logo} logoCategoryId={id} />)}
          {addLogosStatus === LOADING && loadingLogos[id] &&
            Array.from({ length: loadingLogos[id] }, (v, i) => i).map(
              (elem) => <LoadingCard key={elem} />,
            )}
        </InfiniteScroll>
        {!logos?.length && getLogosStatus === SUCCESS &&
          (addLogosStatus !== LOADING || (addLogosStatus === LOADING && !loadingLogos[id])) &&
          <EmptyFilesDropzone addFiles={uploadFiles} acceptType={{ 'image/*': ['.heic'] }} />}
      </div>
      {modalOpen && (
        <DeleteLogoCategoryModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          id={id}
        />
      )}
      {showDetailsStatus === SUCCESS && <LogoDetailsModal />}
    </>
  );
};

LogoCategory.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  logos: arrayOf(shape({
    id: number,
    url: string,
  })),
};

export { LogoCategory };
