import React, { useRef } from 'react';
import { Rect } from 'react-konva';
import { string, number, shape, bool, func, object, arrayOf } from 'prop-types';
import { connect } from 'react-redux';

import {
  useMoveTransformElement,
  useKeyboardListener,
  useOnClickElement,
  useDraggableElement,
  useElementColor,
  useDecreaseCanvasSize,
} from 'src/hooks';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { getRGBAColor } from 'src/utils/helpers';
import {
  getPosDragBoundFunc,
  getStrokeDash,
  getShadowOffset,
  getFillLinearGradientColorStops,
} from 'src/utils/canvasHelpers';
import { SQUARE_ELEMENT } from 'src/constants/canvasElements';

const CanvasSquareComponent = ({
  uuid,
  x,
  y,
  width,
  height,
  scaleX,
  scaleY,
  skewX,
  skewY,
  color,
  borderColor: { hex: borderHex },
  strokeWidth,
  selectedElement: selectedElementId,
  isInGroup,
  rotation,
  selectedRef,
  scale,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  strokeDash = [],
  shadowEnabled = false,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor = { alpha: 0 },
  fillLinearGradientEnabled = false,
  fillLinearGradientColorStops,
  fillLinearGradientStartPoint = {},
  fillLinearGradientEndPoint = {},
  pinchZoomGesture,
}) => {
  const refSquare = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const {
    moveElementRequest,
    transformElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refSquare, selectedRef);

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const onDragStart = (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && selectElementRequest(e);
      hideTransformerAnchors();
    }
  };

  const onDragEnd = (e) => {
    if (!isInGroup && e.evt) {
      moveElementRequest(e);
      showTransformerAnchors();
      onDragEndScroll();
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    selectElementRequest,
  );

  const { draggableElement } = useDraggableElement(isInGroup);

  const colorRGB = getRGBAColor(color);
  const { fillColor, borderColor, dropShadow } = useElementColor(
    selectedElementId === uuid,
    eyeDropper,
    SQUARE_ELEMENT,
    { fill: colorRGB, border: borderHex, shadow: shadowColor },
  );

  useDecreaseCanvasSize(refSquare, isInGroup);

  return (
    <Rect
      id={uuid}
      ref={refSquare}
      x={x}
      y={y}
      fill={!fillLinearGradientEnabled ? fillColor : undefined}
      stroke={borderColor}
      strokeWidth={strokeWidth}
      strokeScaleEnabled={false}
      dash={getStrokeDash(strokeDash, strokeWidth, scale)}
      draggable={draggableElement}
      width={width}
      height={height}
      scaleX={scaleX}
      scaleY={scaleY}
      skewX={skewX}
      skewY={skewY}
      offsetX={width / 2}
      offsetY={height / 2}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onClick={onClick}
      onTap={onClick}
      onTransformEnd={!isInGroup && transformElementRequest}
      onTransform={onTransformElement}
      rotation={rotation}
      dragBoundFunc={(pos) => getPosDragBoundFunc(
        refPositions.current,
        pos,
        1 / scale,
        refSquare.current?.absolutePosition(),
        shiftShortcutPressed,
      )}
      shadowOpacity={dropShadow.alpha / 100}
      shadowColor={dropShadow.hex}
      shadowBlur={shadowBlur}
      shadowOffset={getShadowOffset(shadowOffsetX, shadowOffsetY, strokeWidth)}
      shadowEnabled={shadowEnabled}
      elementType={SQUARE_ELEMENT}
      listening={!pinchZoomGesture && !eyeDropper.active}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      fillLinearGradientStartPoint={fillLinearGradientStartPoint}
      fillLinearGradientEndPoint={fillLinearGradientEndPoint}
      fillLinearGradientColorStops={getFillLinearGradientColorStops(fillLinearGradientColorStops)}
    />
  );
};

CanvasSquareComponent.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  scaleX: number.isRequired,
  scaleY: number.isRequired,
  skewX: number,
  skewY: number,
  width: number.isRequired,
  height: number.isRequired,
  strokeWidth: number.isRequired,
  color: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  borderColor: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  selectedElement: string,
  isInGroup: bool,
  rotation: number.isRequired,
  selectedRef: object,
  scale: number,
  eyeDropper: shape({ active: bool }),
  onTransformElement: func,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  strokeDash: arrayOf(number),
  shadowOffsetX: number,
  shadowOffsetY: number,
  shadowBlur: number,
  shadowColor: shape({
    hex: string,
    alpha: number,
  }),
  shadowEnabled: bool,
  fillLinearGradientEnabled: bool,
  fillLinearGradientColorStops: arrayOf(shape({
    color: string,
    offset: string,
  })),
  fillLinearGradientStartPoint: shape({ x: number, y: number }),
  fillLinearGradientEndPoint: shape({ x: number, y: number }),
  pinchZoomGesture: bool,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElement: store.project.present.selectedElements[ownProps.uuid],
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  scale: store.canvas.scale,
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const CanvasSquare = connect(mapStateToProps)(CanvasSquareComponent);

export { CanvasSquare };
