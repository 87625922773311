import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string } from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  getCategoryTemplates,
  setLoadingPaginationCategoryId,
} from 'src/actions/templatesActions';
import { SeeAllNavigation } from 'src/pages/templates/categories-list/see-all-navigation';
import { usePagination } from 'src/hooks';
import { TemplateList } from 'src/pages/templates/categories-list/template-list';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './TemplateCategory.module.scss';

const TemplateCategory = ({ name, categoryId }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const { templatesByCategoryPages } = useSelector(({ templates }) => ({
    templatesByCategoryPages: templates.templatesByCategoryPages[categoryId],
  }));

  const handleFetch = useCallback(async (page) => {
    const accountId = -1;
    await dispatch(getCategoryTemplates(accountId, categoryId, page));
  }, [categoryId, dispatch]);

  const optimized = true;
  const { page, nextPage, prevPage } = usePagination(handleFetch, optimized);

  const requestNextPage = () => {
    dispatch(setLoadingPaginationCategoryId(categoryId));
    nextPage();
  };

  let isNextPageDisabled = false;
  if (templatesByCategoryPages?.total) {
    const { total } = templatesByCategoryPages;
    isNextPageDisabled = page === Math.ceil(total / amountOfTemplatesInARow);
  }

  const goToSeeAll = () => {
    history.replace(`${routesPaths.templates}?seeAll=${categoryId}`);
  };

  return (
    <>
      <SeeAllNavigation
        name={name}
        goToSeeAll={goToSeeAll}
        next={requestNextPage}
        prev={prevPage}
        isNextPageDisabled={isNextPageDisabled}
        isPrevDisabled={page === 1}
      />
      {amountOfTemplatesInARow && (
        <TemplateList
          categoryId={categoryId}
          amountOfTemplatesInARow={amountOfTemplatesInARow}
          page={page}
          spinnerIconClassName={styles.spinnerIcon}
        />
      )}
    </>
  );
};

TemplateCategory.propTypes = {
  name: string.isRequired,
  categoryId: number.isRequired,
};

export { TemplateCategory };
