import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CloseIcon from 'src/assets/icons/close.svg';
import {
  addMessagesToChat,
  answerToChat,
  removeLastMessageOfType,
} from 'src/actions/collageActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import { useStatus, useWindowSize } from 'src/hooks';
import { changeControlOpen } from 'src/actions/canvasActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { GET_PROJECT } from 'src/actions/projectActions';
import { SUCCESS } from 'src/constants/status';
import { isVideo } from 'src/utils/videoHelpers';
import { isGif } from 'src/utils/helpers';
import { QuestionFromChat } from './question-from-chat';
import { UploadMediaToChat } from './upload-media-to-chat';
import { MediaInChat } from './media-in-chat';
import { LoadingCollage } from './loading-collage';
import { RegenerateCollageOption } from './regenerate-collage-option';
import { CollageThumbnail } from './collage-thumbnail';
import { AnswerToChat } from './answer-to-chat';
import { FinishChat } from './finish-chat';
import { RegenerateCollageMobile } from './regenerate-collage-mobile';
import { AddProjectMediaToChat } from './add-project-media-to-chat';
import { AmountCanvasesCollage } from './amount-canvases-collage';
import styles from './CollageControl.module.scss';

const CollageControl = () => {
  const dispatch = useDispatch();

  const ref = useRef();

  const {
    chatMessages,
    isGeneratedCollageVisible,
    uploadedMedia,
    userInteraction,
  } = useSelector(({ collage, canvas }) => ({
    chatMessages: collage.chatMessages,
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
    uploadedMedia: canvas.uploadedMedia,
    userInteraction: collage.userInteraction,
  }));

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const onClose = useCallback(() => {
    dispatch(changeControlOpen(''));
  }, [dispatch]);

  const { status: getProjectStatus } = useStatus(GET_PROJECT);

  const uploadImages = useCallback(async () => {
    await dispatch(removeLastMessageOfType({ type: COLLAGE_CHAT_TYPE.ADD_PROJECT_MEDIA }));
    dispatch(answerToChat({
      answer: 'No, I\'ll upload some',
      messages: [
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Ok, let\'s upload some images then. We need at least two to start with.',
          id: uuidv4(),
        },
        { type: COLLAGE_CHAT_TYPE.UPLOAD_MEDIA, id: uuidv4() },
      ],
    }));
  }, [dispatch]);

  useEffect(() => {
    // Initial messages
    if (getProjectStatus === SUCCESS && !userInteraction) {
      const imageUrls = uploadedMedia.filter(media => !isVideo(media) && !isGif(media));
      if (imageUrls.length) {
        dispatch(addMessagesToChat({
          messages: [
            {
              type: COLLAGE_CHAT_TYPE.QUESTION,
              text: 'Select the images you want and we’ll design something for you',
              id: uuidv4(),
            },
            { type: COLLAGE_CHAT_TYPE.ADD_PROJECT_MEDIA, id: uuidv4() },
            {
              type: COLLAGE_CHAT_TYPE.ANSWER,
              text: 'No, I\'ll upload some',
              id: uuidv4(),
              onClick: uploadImages,
            },
          ],
          clean: true,
        }));
      } else {
        dispatch(addMessagesToChat({
          messages: [
            {
              type: COLLAGE_CHAT_TYPE.QUESTION,
              text: `Which images do you want to us to use for your
              Creator Collage? We need at least two to start with.`,
              id: uuidv4(),
            },
            { type: COLLAGE_CHAT_TYPE.UPLOAD_MEDIA, id: uuidv4() },
          ],
          clean: true,
        }));
      }
    }
  }, [dispatch, getProjectStatus, uploadImages, uploadedMedia, userInteraction]);

  useLayoutEffect(() => {
    if (ref?.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
    }
  }, [chatMessages]);

  if (isGeneratedCollageVisible && isMobile) {
    return (
      <div className={styles.container}>
        <RegenerateCollageMobile />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </button>
      <div className={styles.titleContainer}>
        <span className={styles.beta}>Beta</span>
        <span className={styles.title}>Generate a Creator Collage</span>
      </div>
      <span className={styles.subtitle}>Answer a few quick questions for us.</span>
      {chatMessages.map(message => {
        if (message.type === COLLAGE_CHAT_TYPE.QUESTION) {
          return <QuestionFromChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.ANSWER) {
          return <AnswerToChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.UPLOAD_MEDIA) {
          return <UploadMediaToChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.MEDIA_IN_CHAT) {
          return <MediaInChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.ADD_PROJECT_MEDIA) {
          return <AddProjectMediaToChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.LOADING) {
          return <LoadingCollage {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.COLLAGE_THUMBNAIL) {
          return <CollageThumbnail {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.ANSWER_REGENERATE) {
          return <RegenerateCollageOption {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.FINISH_ANSWER) {
          return <FinishChat {...message} key={message.id} />;
        }
        if (message.type === COLLAGE_CHAT_TYPE.AMOUNT_CANVASES_ANSWER) {
          return <AmountCanvasesCollage {...message} key={message.id} />;
        }
        return null;
      })}
    </div>
  );
};

export { CollageControl };
