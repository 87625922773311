import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chunk from 'lodash.chunk';
import { string, number } from 'prop-types';

import {
  GET_CATEGORIES_WITH_TEMPLATES,
  getTemplatesSeeAll,
  SEE_ALL,
} from 'src/actions/templatesActions';
import { reset } from 'src/actions/statusActions';
import { LOADING } from 'src/constants/status';
import { useStatus, usePagination, useSession } from 'src/hooks';
import { TemplateGrid } from 'src/pages/templates/template-grid';
import styles from './CategoryGrid.module.scss';

const CategoryGrid = ({ name, amountOfTemplatesInARow, categoryId }) => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const {
    templatesInSeeAllByPages,
    templates,
  } = useSelector(({ templates: templatesByCategories }) => ({
    templatesInSeeAllByPages: templatesByCategories.templatesInSeeAllByPages[categoryId],
    templates: templatesByCategories.categories[categoryId].templates,
  }));

  useEffect(() => {
    const initialPage = 1;
    dispatch(getTemplatesSeeAll(accountId, categoryId, initialPage));

    return () => dispatch(reset(SEE_ALL));
  }, [categoryId, dispatch, accountId]);

  const { status: getSeeAllStatus } = useStatus(SEE_ALL);
  const { status: getCategoriesStatus } = useStatus(GET_CATEGORIES_WITH_TEMPLATES);

  const handleFetch = useCallback(async (page) => {
    await dispatch(getTemplatesSeeAll(accountId, categoryId, page));
  }, [dispatch, accountId, categoryId]);

  const { page, nextPage, prevPage } = usePagination(handleFetch);

  let isNextPageDisabled = false;
  if (templatesInSeeAllByPages?.total >= 0) {
    const { total, current } = templatesInSeeAllByPages;
    isNextPageDisabled = current === total;
  }

  const rows = chunk(templates, amountOfTemplatesInARow);

  return (
    <>
      <span className={styles.title}>{name}</span>
      <TemplateGrid
        isMainHeader={false}
        name={name}
        showLoading={getCategoriesStatus === LOADING || getSeeAllStatus === LOADING}
        templates={rows}
        status={getSeeAllStatus}
        page={page}
        isNextPageDisabled={isNextPageDisabled}
        prevPage={prevPage}
        nextPage={nextPage}
        amountOfTemplatesInARow={amountOfTemplatesInARow}
        categoryId={categoryId}
        templateCardClassName={styles.card}
      />
    </>

  );
};

CategoryGrid.propTypes = {
  name: string.isRequired,
  amountOfTemplatesInARow: number,
  categoryId: number.isRequired,
};

export { CategoryGrid };
