import React, { useEffect, useRef, useMemo } from 'react';
import { shape, number } from 'prop-types';
import { Rect, Image as ImageKonva, Group } from 'react-konva';
import 'gifler';

import { PROCESSING_OVERLAY_ID } from 'src/constants/general';
import LoadingAnimation from 'src/assets/images/dots-loading.gif';

const LOADING_ANIMATION_CONF = {
  width: 711,
  height: 400,
  scaleX: 0.2,
  scaleY: 0.2,
};

const CanvasProcessingElement = ({ element }) => {
  const imageRef = useRef(null);

  const canvas = useMemo(() => {
    const node = document.createElement('canvas');
    return node;
  }, []);

  useEffect(() => {
    // save animation instance to stop it on unmount
    let anim;
    window.gifler(LoadingAnimation).get(a => {
      anim = a;
      anim.animateInCanvas(canvas);
      anim.onDrawFrame = (ctx, frame) => {
        ctx.drawImage(frame.buffer, frame.x, frame.y);
        if (imageRef?.current) {
          imageRef.current.getLayer().draw();
        }
      };
    });
    return () => anim?.stop();
  }, [canvas]);

  const offset = useMemo(() => {
    const result = { x: element.offsetX, y: element.offsetY };
    if (!element.offsetX && element.offsetX !== 0) {
      result.x = element.width / 2;
    }
    if (!element.offsetY && element.offsetY !== 0) {
      result.y = element.height / 2;
    }
    return result;
  }, [element]);

  return (
    <Group
      id={PROCESSING_OVERLAY_ID}
      x={element.x}
      y={element.y}
      scaleX={element.scaleX}
      scaleY={element.scaleY}
      offsetX={offset.x}
      offsetY={offset.y}
      rotation={element.rotation}
      width={element.width}
      height={element.height}
    >
      <Rect
        fill="white"
        strokeWidth={0}
        width={element.width}
        height={element.height}
        opacity={0.7}
        shadowEnabled={false}
        shadowForStrokeEnabled={false}
      />
      <ImageKonva
        image={canvas}
        ref={imageRef}
        x={(element.width / 2) -
          ((LOADING_ANIMATION_CONF.width * LOADING_ANIMATION_CONF.scaleX) / 2)}
        y={(element.height / 2) -
          ((LOADING_ANIMATION_CONF.height * LOADING_ANIMATION_CONF.scaleY) / 2)}
        scaleX={LOADING_ANIMATION_CONF.scaleX}
        scaleY={LOADING_ANIMATION_CONF.scaleY}
        width={LOADING_ANIMATION_CONF.width}
        height={LOADING_ANIMATION_CONF.height}
      />
    </Group>
  );
};

CanvasProcessingElement.propTypes = {
  element: shape({
    x: number,
    y: number,
    width: number,
    height: number,
    scaleX: number,
    scaleY: number,
    rotation: number,
    offsetX: number,
    offsetY: number,
  }).isRequired,
};

export { CanvasProcessingElement };
