import { useCallback } from 'react';

const useTextInputProps = ({ handleInputChange, values, errors, touched, refs }) => (
  useCallback(
    fieldKey => {
      if (refs?.current) {
        refs.current = { ...refs.current, [fieldKey]: true };
      }
      return {
        value: values[fieldKey] || '',
        onChange: ({ target: { value } }) => handleInputChange(fieldKey, value),
        error: errors[fieldKey],
        touched: touched[fieldKey],
      };
    },
    [handleInputChange, values, errors, touched, refs],
  ));

export { useTextInputProps };
