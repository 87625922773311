import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { bool } from 'prop-types';

import {
  loginToFB,
  logoutFromFB,
} from 'src/actions/instagramAnalyticsActions';
import styles from './FBLogin.module.scss';

const FBLogin = ({ loggedIn }) => {
  const dispatch = useDispatch();

  const login = useCallback(() => {
    dispatch(loginToFB());
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutFromFB());
  }, [dispatch]);

  const btnMessage = loggedIn ? 'Log Out' : 'Log in With Facebook';

  return (
    <button className={styles.loginButton} onClick={loggedIn ? logout : login}>
      <img
        className={styles.image}
        src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png"
        alt=""
        width="24"
        height="24"
      />
      {btnMessage}
    </button>
  );
};

FBLogin.propTypes = {
  loggedIn: bool,
};

export { FBLogin };
