import WebFont from 'webfontloader';

import { FONT_FAMILY_OPTIONS, DEFAULT_FONT_FAMILY } from 'src/constants/fonts';
import { FONT_STYLES_FILE_NAME } from 'src/constants/general';
import {
  LOCAL_UPLOAD,
  TEMPLATE_UPLOAD,
  GOOGLE_UPLOAD,
  SYSTEM_UPLOAD,
} from 'src/constants/uploadFile';

export const getGoogleFamilies = (fonts = []) => {
  const googleFonts = fonts.filter(elem => elem.uploadType === GOOGLE_UPLOAD)
    .map(elem => {
      const fontFamily = FONT_FAMILY_OPTIONS.find(option => option.value === elem.fontFamily);
      return fontFamily.font;
    });
  const googleFontsNotRepeated = googleFonts.filter((c, index) => googleFonts.indexOf(c) === index);
  return googleFontsNotRepeated;
};

export const getSystemFamilies = (fonts = []) => {
  const systemFonts = fonts.filter(elem => elem.uploadType === SYSTEM_UPLOAD);
  const systemFontsNotRepeated = systemFonts.filter((c, index) => systemFonts.indexOf(c) === index);
  return systemFontsNotRepeated;
};

export const getCustomFamilies = (fonts = []) => {
  const customFonts = fonts.filter(
    elem => elem.uploadType === LOCAL_UPLOAD || elem.uploadType === TEMPLATE_UPLOAD,
  );
  const customFontsNotRepeated = customFonts.filter((c, index) => (
    customFonts.findIndex(i => i.fontFamily === c.fontFamily) === index
  ));
  return customFontsNotRepeated;
};

export const loadUploadedFontsToStylesheet = (uploadedFonts = []) => {
  const markups = uploadedFonts.map(({ name, url }) => {
    const markup = [
      '@font-face {\n',
      '\tfont-family: \'', name, '\';\n',
      '\tsrc: url(\'', url, '\');\n',
      '}\n',
    ].join('');
    return markup;
  }).join('');
  const style = document.getElementById(FONT_STYLES_FILE_NAME);
  style.innerHTML = markups;
};

export const addUploadedFontToStylesheet = ({ name, url }) => {
  const markup = [
    '@font-face {\n',
    '\tfont-family: \'', name, '\';\n',
    '\tsrc: url(\'', url, '\');\n',
    '}\n',
  ].join('');
  const style = document.getElementById(FONT_STYLES_FILE_NAME);
  let innerHtml = style.innerHTML;
  if (!style.innerHTML.includes(markup)) {
    innerHtml = [style.innerHTML, '\n', markup].join('');
  }
  style.innerHTML = innerHtml;
};

export const getFontsRegisteredInStylesheet = () => {
  const style = document.getElementById(FONT_STYLES_FILE_NAME).innerHTML;
  const regex = /font-family: '([\w\d\-\s]*)';/gm;
  const matches = [];
  let match = regex.exec(style);
  while (match != null) {
    matches.push(match[1]);
    match = regex.exec(style);
  }
  return matches;
};

export const addTextToCanvas = (fontMemory, callback) => {
  if (!fontMemory) {
    WebFont.load({
      google: { families: [DEFAULT_FONT_FAMILY.font] },
      active: callback,
    });
  } else if (fontMemory.uploadType === GOOGLE_UPLOAD) {
    WebFont.load({
      google: { families: [fontMemory.fontFamily] },
      active: callback,
    });
  } else if (fontMemory.uploadType === LOCAL_UPLOAD) {
    const uploadedFont = { name: fontMemory.fontFamily, url: fontMemory.fontFamilyUrl };
    addUploadedFontToStylesheet(uploadedFont);
    const fontsRegistered = getFontsRegisteredInStylesheet();
    WebFont.load({
      custom: { families: fontsRegistered },
      active: callback,
    });
  } else if (fontMemory.uploadType === SYSTEM_UPLOAD) {
    const systemFont = { name: fontMemory.fontFamily, url: fontMemory.fontFamilyUrl };
    addUploadedFontToStylesheet(systemFont);
    const fontsRegistered = getFontsRegisteredInStylesheet();
    WebFont.load({
      custom: { families: fontsRegistered },
      active: callback,
    });
  }
};

export const addTextCombinationToCanvas = (elements, callback) => {
  const webFontConfig = {};
  const googleFamilies = getGoogleFamilies(elements);
  if (googleFamilies.length) {
    webFontConfig.google = { families: googleFamilies };
  }
  const systemFonts = getSystemFamilies(elements);
  if (systemFonts.length) {
    systemFonts.forEach(elem => {
      const fontFamily = FONT_FAMILY_OPTIONS.find(option => option.value === elem.fontFamily);
      const systemFont = { name: elem.fontFamily, url: fontFamily.font };
      addUploadedFontToStylesheet(systemFont);
    });
    const fontsRegistered = getFontsRegisteredInStylesheet();
    webFontConfig.custom = { families: fontsRegistered };
  }
  WebFont.load({
    ...webFontConfig,
    active: callback,
  });
};
