import React from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import styles from './PreviewCard.module.scss';

const PreviewCard = ({ preview, onClick, isSelected }) => (
  <button className={cn(styles.container, { [styles.selected]: isSelected })} onClick={onClick}>
    <img src={preview} alt="Preview thumbnail" className={styles.image} />
  </button>
);

PreviewCard.propTypes = {
  preview: string.isRequired,
  onClick: func.isRequired,
  isSelected: bool.isRequired,
};

export { PreviewCard };
