const INSTAGRAM_SOURCE = 'instagram';
const FACEBOOK_SOURCE = 'facebook';
const TWITTER_SOURCE = 'twitter';
const LINKEDIN_SOURCE = 'linkedin';
const PRESENTATION_SOURCE = 'presentation';
const WEB_SOURCE = 'web';
const FLYER_SOURCE = 'flyer';
export const SIGN_SOURCE = 'sign';
export const EMAIL_SOURCE = 'email';
export const CUSTOM_SOURCE = 'custom';

export const INSTAGRAM_LAYOUTS = {
  SQUARE_LAYOUT: { width: 1080, height: 1080, source: INSTAGRAM_SOURCE },
  PORTRAIT_LAYOUT: { width: 1080, height: 1350, source: INSTAGRAM_SOURCE },
  LANDSCAPE_LAYOUT: { width: 1080, height: 566, source: INSTAGRAM_SOURCE },
  STORY_LAYOUT: { width: 1080, height: 1920, source: INSTAGRAM_SOURCE },
};

export const FACEBOOK_LAYOUTS = {
  SQUARE_LAYOUT: { width: 1200, height: 1200, source: FACEBOOK_SOURCE },
  STORY_LAYOUT: { width: 1080, height: 1920, source: FACEBOOK_SOURCE },
  POST_LAYOUT: { width: 1200, height: 630, source: FACEBOOK_SOURCE },
};

export const TWITTER_LAYOUTS = {
  POST_LAYOUT: { width: 1200, height: 675, source: TWITTER_SOURCE },
};

export const LINKEDIN_LAYOUTS = {
  POST_LAYOUT: { width: 1200, height: 627, source: LINKEDIN_SOURCE },
  COVER_LAYOUT: { width: 1128, height: 191, source: LINKEDIN_SOURCE },
};

export const PRESENTATION_LAYOUTS = {
  '169_LAYOUT': { width: 2048, height: 1152, source: PRESENTATION_SOURCE },
  '32_LAYOUT': { width: 2048, height: 1365, source: PRESENTATION_SOURCE },
  '21_LAYOUT': { width: 2048, height: 1024, source: PRESENTATION_SOURCE },
};

export const WEB_LAYOUTS = {
  BANNER_LAYOUT: { width: 1350, height: 650, source: WEB_SOURCE },
};

export const EMAIL_LAYOUTS = {
  SHORT_LAYOUT: { width: 700, height: 100, source: EMAIL_SOURCE },
  MEDIUM_LAYOUT: { width: 700, height: 200, source: EMAIL_SOURCE },
  TALL_LAYOUT: { width: 700, height: 350, source: EMAIL_SOURCE },
};

export const FLYER_LAYOUTS = {
  SQUARE_LAYOUT: { width: 1080, height: 1080, source: FLYER_SOURCE },
  PORTRAIT_LAYOUT: { width: 1080, height: 1350, source: FLYER_SOURCE },
  LANDSCAPE_LAYOUT: { width: 1080, height: 566, source: FLYER_SOURCE },
};

export const SIGN_LAYOUTS = {
  HORIZONTAL_LAYOUT: { width: 1763, height: 1332, source: SIGN_SOURCE },
  VERTICAL_LAYOUT: { width: 1332, height: 1763, source: SIGN_SOURCE },
};
