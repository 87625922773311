import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chunk from 'lodash.chunk';
import { bool, string } from 'prop-types';

import {
  getSearchTemplates,
  resetSearchTemplates,
  SEARCH_TEMPLATES,
} from 'src/actions/templatesActions';
import { LOADING, SUCCESS } from 'src/constants/status';
import { useStatus, usePagination, useProjectSelector } from 'src/hooks';
import { TemplateGrid } from 'src/pages/templates/template-grid';
import { reset } from 'src/actions/statusActions';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import styles from './SearchTemplates.module.scss';

const SearchTemplates = ({ searchPrivateTemplates, templateCardClassName }) => {
  const dispatch = useDispatch();

  const { type } = useProjectSelector();

  const { status } = useStatus(SEARCH_TEMPLATES);

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const goToTemplates = useCallback(() => {
    dispatch(resetSearchTemplates());
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(reset(SEARCH_TEMPLATES));
    dispatch(resetSearchTemplates());
  }, [dispatch]);

  const {
    searchTemplatesPage,
    searchTemplates,
    searchKeyword,
    accountId,
  } = useSelector(({ templates, session }) => ({
    searchTemplatesPage: templates.searchTemplatesPage,
    searchTemplates: templates.searchTemplates,
    searchKeyword: templates.searchKeyword,
    accountId: session.accountId,
  }));

  const handleFetchAll = useCallback(async (page) => {
    const accountIdToSend = searchPrivateTemplates ? accountId : -1;
    await dispatch(getSearchTemplates(accountIdToSend, searchKeyword, type, page));
  }, [searchPrivateTemplates, accountId, dispatch, searchKeyword, type]);

  const { page, prevPage, nextPage } = usePagination(handleFetchAll);

  const rows = chunk(searchTemplates, amountOfTemplatesInARow);

  let isNextPageDisabled = false;
  if (searchTemplatesPage?.total) {
    const { total, current } = searchTemplatesPage;
    isNextPageDisabled = total === current;
  }

  if (!searchTemplates.length && searchKeyword && status === SUCCESS) {
    return (
      <div className={styles.errorContainer}>
        <span className={styles.errorTitle}>No results.</span>
        <span className={styles.errorSubtitle}>
          We couldn&apos;t find any templates matching &quot;{searchKeyword}&quot;
        </span>
      </div>
    );
  }

  return (
    <TemplateGrid
      goToTemplates={goToTemplates}
      showLoading={status === LOADING}
      templates={rows}
      status={status}
      page={page}
      isNextPageDisabled={isNextPageDisabled}
      prevPage={prevPage}
      nextPage={nextPage}
      isMainHeader={false}
      amountOfTemplatesInARow={amountOfTemplatesInARow}
      templateCardClassName={templateCardClassName}
    />
  );
};

SearchTemplates.propTypes = {
  searchPrivateTemplates: bool,
  templateCardClassName: string,
};

export { SearchTemplates };
