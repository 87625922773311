import { HttpClient } from 'src/clients';

class AssetCategoryService {
  static getAssetsFromCategory(id, page, perPage) {
    return HttpClient.get(`/asset-categories/${id}/assets`, { params: { page, perPage } });
  }

  static createAsset(id, asset) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', asset);
    return HttpClient.post(`/asset-categories/${id}/asset`, formData, config);
  }

  static deleteAsset(id, assetId) {
    return HttpClient.delete(`/asset-categories/${id}/asset/${assetId}`);
  }
}

export { AssetCategoryService };
