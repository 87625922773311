export const ADD_TEXT_CONTROL = 'addTextControl';

export const EDIT_CANVAS_CONTROL = 'editCanvasControl';

export const ADD_OBJECT_CONTROL = 'addObjectControl';

export const IMAGE_CONTROL = 'imageControl';

export const CHOOSE_IMAGE_CONTROL = 'chooseImageControl';

export const TEMPLATES_CONTROL = 'templatesControl';

export const SEE_ALL_TEMPLATES_CONTROL = 'seeAllTemplatesControl';

export const SEE_ALL_SUGGESTIONS_CONTROL = 'seeAllSuggestionsControl';

export const COLLAGE_CONTROL = 'collageControl';

export const TEMPLATE_RELATED_CONTROLS = [
  TEMPLATES_CONTROL,
  SEE_ALL_TEMPLATES_CONTROL,
  SEE_ALL_SUGGESTIONS_CONTROL,
];
