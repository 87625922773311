import React, { useCallback } from 'react';
import { func, bool, number } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import {
  DELETE_ASSET_FROM_BRAND_LIBRARY,
  deleteAssetFromBrandLibrary,
} from 'src/actions/brandLibraryActions';
import { useLoading } from 'src/hooks';
import styles from './DeleteAssetModal.module.scss';

const DeleteAssetModal = ({ isShowing, hide, assetCategoryId, assetId }) => {
  const dispatch = useDispatch();

  const deleteAsset = useCallback(() => {
    dispatch(deleteAssetFromBrandLibrary(assetCategoryId, assetId));
  }, [dispatch, assetId, assetCategoryId]);

  const loading = useLoading(DELETE_ASSET_FROM_BRAND_LIBRARY);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete this asset?
        </span>
        <span className={styles.subtitle}>
          This action will permanently delete your asset and can&apos;t be undone.
          This doesn&apos;t affect your projects in any way.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={deleteAsset}
            loading={loading}
            alternativeCTA
          >
            Delete
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteAssetModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  assetCategoryId: number.isRequired,
  assetId: number.isRequired,
};

export { DeleteAssetModal };
