import React, { useCallback, useRef } from 'react';
import { bool, func, shape, string, number } from 'prop-types';
import WebFont from 'webfontloader';
import { useSelector, useDispatch } from 'react-redux';

import { addUploadedFontToStylesheet, getFontsRegisteredInStylesheet } from 'src/utils/fontHelpers';
import { GOOGLE_UPLOAD, SYSTEM_UPLOAD, LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { FONT_FAMILY_OPTIONS } from 'src/constants/fonts';
import { Select } from 'src/common/select';
import { Tooltip } from 'src/common/tooltip';
import { useTooltip } from 'src/hooks';
import { setFontInMemory } from 'src/actions/projectMemoryActions';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import styles from './BrandLibraryFontSelectControl.module.scss';

const DEFAULT_STYLE = { value: 'Default', label: 'Default' };

const BrandLibraryFontSelectControl = ({
  disabled,
  element,
  updateElementRequest,
  groupClassName,
  scrollPosition,
}) => {
  const dispatch = useDispatch();

  const {
    fonts,
    disabled: brandLibraryDisabled,
    uploadedFonts,
  } = useSelector(({ brandLibrary }) => ({
    fonts: brandLibrary.fonts,
    disabled: brandLibrary.disabled,
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  const getFontStyle = () => {
    if (element) {
      const { brandLibraryStyleId } = element;
      if (brandLibraryStyleId) {
        const font = fonts.find(elem => elem.id === brandLibraryStyleId);
        return { value: font.name, label: font.name };
      }
    }
    return DEFAULT_STYLE;
  };

  const { ref: containerRef, showTooltip } = useTooltip();
  const stylesRef = useRef();

  const onChangeFontStyle = ({
    uploadType,
    id,
    fontStyle,
    align,
    fontFamily,
    hex,
    alpha,
    fontSize,
    lineHeight,
    letterSpacing,
  }) => {
    let newFont;
    if (uploadType === GOOGLE_UPLOAD) {
      const googleFont = FONT_FAMILY_OPTIONS.find(option => option.value === fontFamily);
      WebFont.load({
        google: { families: [googleFont.font] },
        active: () => {
          newFont = {
            fontFamily,
            fontStyle,
            uploadType,
            fontFamilyUrl: '',
          };
          updateElementRequest({
            brandLibraryStyleId: id,
            align,
            color: { hex, alpha },
            fontSize,
            lineHeight,
            letterSpacing,
            ...newFont,
          });
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    } else if (uploadType === LOCAL_UPLOAD) {
      const customFont = uploadedFonts.find(uploaded => uploaded.name === fontFamily);
      const uploadedFont = { name: fontFamily, url: customFont.url };
      addUploadedFontToStylesheet(uploadedFont);
      const fontsRegistered = getFontsRegisteredInStylesheet();
      WebFont.load({
        custom: { families: fontsRegistered },
        active: () => {
          newFont = {
            fontFamily,
            fontStyle,
            uploadType,
            fontFamilyUrl: customFont.url,
          };
          updateElementRequest({
            brandLibraryStyleId: id,
            align,
            color: { hex, alpha },
            fontSize,
            lineHeight,
            letterSpacing,
            ...newFont,
          });
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    } else if (uploadType === SYSTEM_UPLOAD) {
      const systemFont = FONT_FAMILY_OPTIONS.find(option => option.value === fontFamily);
      const uploadedFont = { name: fontFamily, url: systemFont.font };
      addUploadedFontToStylesheet(uploadedFont);
      const fontsRegistered = getFontsRegisteredInStylesheet();
      WebFont.load({
        custom: { families: fontsRegistered },
        active: () => {
          newFont = {
            fontFamily,
            fontStyle,
            uploadType,
            fontFamilyUrl: systemFont.font,
          };
          updateElementRequest({
            brandLibraryStyleId: id,
            align,
            color: { hex, alpha },
            fontSize,
            lineHeight,
            letterSpacing,
            ...newFont,
          });
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    }
  };

  const options = fonts.map(elem => ({
    value: elem.name,
    label: elem.name,
    ...elem,
  }));

  const containerId = 'brandLibrarySelect';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  const selectStyles = useCallback(() => ({
    control: base => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 0,
    }),
    singleValue: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    input: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    menu: base => ({
      ...base,
      color: '#555555',
      fontSize: '1.4rem',
      width: '16rem',
      left: optionsSize.left,
    }),
    option: base => ({
      ...base,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    group: (base, state) => {
      if (!state.label) {
        return base;
      }
      return { ...base, borderBottom: '1px solid #c2c2c2' };
    },
  }), [optionsSize.left]);

  const tooltipStyles = useCallback(() => {
    const paddingLeft = 30;
    return (
      {
        left: optionsSize.left + paddingLeft,
        right: 'unset',
        transform: 'unset',
      }
    );
  }, [optionsSize.left]);

  return (
    !brandLibraryDisabled && (
      <div className={groupClassName} ref={containerRef} id={containerId}>
        <Select
          options={options}
          value={getFontStyle()}
          onChange={onChangeFontStyle}
          className={styles.fontStyle}
          selectStyles={selectStyles()}
          disabled={disabled}
          ref={stylesRef}
        />
        {showTooltip && stylesRef?.current?.state.isFocused === false && (
          <Tooltip style={tooltipStyles()}>Font Styles</Tooltip>
        )}
      </div>
    )
  );
};

BrandLibraryFontSelectControl.propTypes = {
  disabled: bool,
  updateElementRequest: func.isRequired,
  element: shape({ fontStyle: string, fontFamily: string, uploadType: string }),
  groupClassName: string.isRequired,
  scrollPosition: shape({
    x: number,
  }),
};

export { BrandLibraryFontSelectControl };
