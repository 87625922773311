import { HttpClient } from '../clients';

class CampaignAssetService {
  static getSquadEventAssets(page, perPage) {
    return HttpClient.get('/squad-event-assets', {
      params: { page, perPage },
    });
  }
}

export { CampaignAssetService };
