import React from 'react';
import { string, shape, func, bool } from 'prop-types';
import cn from 'classnames';

import CheckIcon from 'src/assets/icons/check.svg';
import { UNSPLASH_UPLOAD } from 'src/constants/uploadFile';
import { IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { getImageDimensions } from 'src/utils/helpers';
import { UTM_SOURCE } from 'src/constants/general';
import styles from './UnsplashCard.module.scss';

const UnsplashCard = ({
  preview,
  id,
  downloadLocation,
  author: { name, link },
  addImage,
  isSelected,
  className,
  imageClassName,
  imageButtonClassName,
  showAuthor = true,
}) => {
  const selectImage = (width, height) => {
    const image = {
      src: preview,
      id,
      name,
      downloadLocation,
      uploadType: UNSPLASH_UPLOAD,
      width,
      height,
      type: IMAGE_ELEMENT,
    };
    addImage(image);
  };

  const onClick = () => {
    getImageDimensions(preview, selectImage);
  };

  return (
    <div className={cn(styles.container, className)}>
      <button
        className={cn(styles.imageContainer, imageButtonClassName)}
        onClick={onClick}
      >
        <img src={preview} alt="" className={cn(styles.image, imageClassName)} />
        {isSelected && (
          <div className={styles.overlay}>
            <div className={styles.check}>
              <img src={CheckIcon} alt="Check" />
            </div>
          </div>
        )}
      </button>
      {showAuthor && (
        <div className={styles.name}>
          <a
            className={styles.author}
            href={`${link}?${UTM_SOURCE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
          <span>&nbsp;via&nbsp;</span>
          <a
            className={styles.author}
            href={`https://unsplash.com/?${UTM_SOURCE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsplash
          </a>
        </div>
      )}
    </div>
  );
};

UnsplashCard.propTypes = {
  preview: string.isRequired,
  id: string.isRequired,
  downloadLocation: string.isRequired,
  addImage: func.isRequired,
  isSelected: bool.isRequired,
  author: shape({
    name: string,
    link: string,
  }).isRequired,
  className: string,
  imageClassName: string,
  imageButtonClassName: string,
  showAuthor: bool,
};

export { UnsplashCard };
