import React, { forwardRef, useRef, useEffect, useCallback, useMemo, memo } from 'react';
import { Rect, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { object, number, shape } from 'prop-types';

import { cropImage } from 'src/actions/projectActions';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { cropperTransformerBound } from 'src/utils/imageCropperHelper';

const CROP_INITIAL_SCALE = 1;
const CROPPER_COLOR = '#2daf92';

const CanvasImageCropper = memo(forwardRef(({
  refImage,
  temporaryCropAttrs,
  canvasAttrs,
}, refCropper) => {
  const refTransform = useRef();

  const dispatch = useDispatch();
  const { current: { attrs: imageAttrs } } = refImage;

  const cropperInitialAttrs = useMemo(() => ({
    x: temporaryCropAttrs.x,
    y: temporaryCropAttrs.y,
    scaleX: temporaryCropAttrs?.crop ? temporaryCropAttrs.scaleX :
      temporaryCropAttrs.scaleX * CROP_INITIAL_SCALE,
    scaleY: temporaryCropAttrs?.crop ? temporaryCropAttrs.scaleY :
      temporaryCropAttrs.scaleY * CROP_INITIAL_SCALE,
  }
  ), [temporaryCropAttrs]);

  useEffect(() => {
    if (refTransform?.current && refCropper?.current) {
      refTransform.current.nodes([refCropper.current]);
      refTransform.current.getLayer().batchDraw();
    }
  }, [refCropper]);

  const { scale } = useSelector(({ canvas }) => ({ scale: canvas.scale }));

  const onCrop = useCallback(() => {
    if (!refCropper?.current) {
      return;
    }
    const imageWidth = imageAttrs.width * imageAttrs.scaleX;
    const imageHeight = imageAttrs.height * imageAttrs.scaleY;
    const imageTopLeftCoordinateX = imageAttrs.x - (imageWidth / 2);
    const imageTopLeftCoordinateY = imageAttrs.y - (imageHeight / 2);
    const { current: { attrs: cropperAttrs } } = refCropper;
    const cropperWidth = cropperAttrs.width * cropperAttrs.scaleX;
    const cropperHeight = cropperAttrs.height * cropperAttrs.scaleY;
    const cropperTopLeftCoordinateX = cropperAttrs.x - (cropperWidth / 2);
    const cropperTopLeftCoordinateY = cropperAttrs.y - (cropperHeight / 2);
    const crop = {
      x: (cropperTopLeftCoordinateX - imageTopLeftCoordinateX) / imageAttrs.scaleX,
      y: (cropperTopLeftCoordinateY - imageTopLeftCoordinateY) / imageAttrs.scaleY,
      width: cropperWidth / imageAttrs.scaleX,
      height: cropperHeight / imageAttrs.scaleY,
    };
    dispatch(cropImage({
      crop,
      scaleX: cropperAttrs.scaleX,
      scaleY: cropperAttrs.scaleY,
      x: cropperAttrs.x,
      y: cropperAttrs.y,
    }));
  }, [dispatch, imageAttrs, refCropper]);

  useEffect(() => {
    const handleKeydown = ({ code }) => {
      if (code === ENTER_CHARACTER_CODE) {
        onCrop();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [dispatch, onCrop]);

  return (
    <>
      <Rect
        ref={refCropper}
        x={cropperInitialAttrs.x}
        y={cropperInitialAttrs.y}
        stroke={CROPPER_COLOR}
        strokeWidth={2}
        strokeScaleEnabled={false}
        width={imageAttrs.width}
        height={imageAttrs.height}
        offsetX={imageAttrs.width / 2}
        offsetY={imageAttrs.height / 2}
        scaleX={cropperInitialAttrs.scaleX}
        scaleY={cropperInitialAttrs.scaleY}
        draggable
        onDragStart={() => {
          refCropper.current.stopDrag();
          refImage.current.startDrag();
        }}
      />
      <Transformer
        ref={refTransform}
        anchorStroke={CROPPER_COLOR}
        borderStroke="transparent"
        rotateEnabled={false}
        ignoreStroke
        boundBoxFunc={(oldBox, newBox) => cropperTransformerBound(
          oldBox, newBox, scale, imageAttrs, canvasAttrs,
        )}
      />
    </>
  );
}));

CanvasImageCropper.propTypes = {
  refImage: object.isRequired,
  temporaryCropAttrs: object.isRequired,
  canvasAttrs: shape({
    x: number,
    y: number,
  }),
};

export { CanvasImageCropper };
