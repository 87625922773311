import React from 'react';
import { func, string, bool } from 'prop-types';
import cn from 'classnames';

import styles from './ButtonSecondaryTools.module.scss';

const ButtonSecondaryTools = ({
  text,
  onClick,
  icon = '',
  alt = '',
  disabled = false,
  className = '',
}) => (
  <button
    className={cn(styles.button, className)}
    onClick={onClick}
    disabled={disabled}
  >
    <img src={icon} alt={alt} />
    {text}
  </button>
);

ButtonSecondaryTools.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  icon: string,
  alt: string,
  disabled: bool,
  className: string,
};

export { ButtonSecondaryTools };
