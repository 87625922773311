import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { oneOfType, node, object } from 'prop-types';

const DEFAULT_STYLES = {
  bmBurgerButton: {
    position: 'absolute',
    width: '3.2rem',
    height: '3rem',
    right: '1.6rem',
    top: '1.7rem',
  },
  bmBurgerBars: {
    height: '10%',
    background: '#000',
  },
  bmCrossButton: {
    width: '2.9rem',
    height: '2.9rem',
    right: '1.7rem',
    top: '1.7rem',
  },
  bmCross: {
    background: '#000',
    height: '2.4rem',
    width: '2px',
  },
  bmMenu: {
    background: '#fff',
    paddingTop: '4.5rem',
  },
  bmMenuWrap: {
    top: 0,
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.25)',
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const HamburgerMenu = ({ children, styles = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={(state) => setIsOpen(state.isOpen)}
      styles={{ ...DEFAULT_STYLES, ...styles }}
      right
    >
      {children}
    </Menu>
  );
};

HamburgerMenu.propTypes = {
  children: oneOfType([node, object]).isRequired,
  styles: object,
};

export { HamburgerMenu };
