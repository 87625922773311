import React from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import cn from 'classnames';

import { RIGHT, LEFT } from 'src/constants/general';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ArrowLeftWhite from 'src/assets/icons/arrow-left-white.svg';
import ArrowRight from 'src/assets/icons/arrow-right.svg';
import ArrowRightWhite from 'src/assets/icons/arrow-right-white.svg';
import styles from './MenuArrow.module.scss';

const MenuArrow = ({ onClick, disabled, direction, className = '' }) => {
  const getSrc = () => {
    if (direction === RIGHT) {
      return disabled ? ArrowRight : ArrowRightWhite;
    }
    return disabled ? ArrowLeft : ArrowLeftWhite;
  };

  return (
    <button
      className={cn(styles.button, className)}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={getSrc()} alt="Arrow" className={styles.arrow} />
    </button>
  );
};

MenuArrow.propTypes = {
  onClick: func,
  disabled: bool.isRequired,
  direction: oneOf([LEFT, RIGHT]),
  className: string,
};

export { MenuArrow };
