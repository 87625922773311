import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { FONT_FAMILY_OPTIONS } from 'src/constants/fonts';

const useBrandLibraryFonts = () => {
  const { uploadedFonts, disabled } = useSelector(({ brandLibrary }) => ({
    uploadedFonts: brandLibrary.uploadedFonts,
    disabled: brandLibrary.disabled,
  }));

  const availableFonts = useMemo(() => {
    if (uploadedFonts.length && !disabled) {
      return [
        {
          label: 'Brand Fonts',
          options: uploadedFonts.map(elem => {
            elem.value = elem.name;
            elem.label = elem.name;
            elem.styles = [];
            elem.uploadType = LOCAL_UPLOAD;
            return elem;
          }),
        },
        {
          label: '',
          options: FONT_FAMILY_OPTIONS,
        },
      ];
    }
    return FONT_FAMILY_OPTIONS;
  }, [disabled, uploadedFonts]);

  return { availableFonts };
};

export { useBrandLibraryFonts };
