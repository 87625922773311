import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, number, bool, string, arrayOf, shape } from 'prop-types';

import { Select } from 'src/common/select';
import { Button } from 'src/common/button';
import { useForm, useStatus, useSession } from 'src/hooks';
import { validateEditEditor } from 'src/utils/constrains';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { editEditorsAccounts, EDIT_EDITOR_ACCOUNTS } from 'src/actions/accountActions';
import { reset } from 'src/actions/statusActions';
import styles from './EditEditorForm.module.scss';

const EditEditorForm = ({ id, accounts, onCancel, disabled }) => {
  const dispatch = useDispatch();
  const { user } = useSession();

  const availableAccounts = user.membership.accounts.map(account => ({
    value: account.id,
    label: account.brandName,
  }));

  const editorAccounts = accounts.map(account => ({
    value: account.id,
    label: account.brandName,
  }));

  const editEditorRequest = useCallback(async (values) => {
    const accountIds = values.accounts.map(account => account.value);
    await dispatch(editEditorsAccounts(id, accountIds));
  }, [dispatch, id]);

  const { status: statusEditEditorAccounts, error } = useStatus(EDIT_EDITOR_ACCOUNTS);

  useEffect(() => {
    if (statusEditEditorAccounts === SUCCESS) {
      onCancel();
    }

    return () => {
      if (statusEditEditorAccounts === SUCCESS || statusEditEditorAccounts === ERROR) {
        dispatch(reset(EDIT_EDITOR_ACCOUNTS));
      }
    };
  }, [dispatch, onCancel, statusEditEditorAccounts]);

  const {
    values,
    handleInputChange,
    handleSubmit,
    errors,
  } = useForm(editEditorRequest, {
    validate: validateEditEditor,
    initialValues: { accounts: editorAccounts },
  });

  const selectOnChange = useCallback((name, value) => {
    handleInputChange(name, value);
  }, [handleInputChange]);

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.fieldsContainer}>
          <div className={styles.selectWrapper}>
            <Select
              options={availableAccounts}
              onChange={(items) => {
                selectOnChange('accounts', items);
              }}
              value={values.accounts}
              closeMenuOnSelect
              isMulti
              placeholder="Choose accounts"
            />
            {errors?.accounts && <span className={styles.error}>{errors.accounts}</span>}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onCancel}
            secondary
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={styles.saveButton}
            loading={statusEditEditorAccounts === LOADING}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </form>
      {statusEditEditorAccounts === ERROR && <span className={styles.error}>{error}</span>}
    </>
  );
};

EditEditorForm.propTypes = {
  onCancel: func.isRequired,
  id: number.isRequired,
  accounts: arrayOf(shape({
    id: number,
    brandName: string,
  })).isRequired,
  disabled: bool.isRequired,
};

export { EditEditorForm };
