import React from 'react';
import { toast } from 'react-toastify';

import { toastSuccessConfig, toastErrorConfig, toastInfoConfig } from 'src/constants/toastConfig';
import { ToastLoading } from 'src/common/toast-loading';
import { AccountService } from 'src/services/accountService';
import { ProjectService } from 'src/services/projectService';
import { SuggestionService } from 'src/services/suggestionService';
import { routeWithProps } from 'src/utils/helpers';
import { routesPaths } from 'src/routes/routesPaths';
import { trackSuggestionUsed, trackApplyTemplate } from 'src/utils/analytics';
import { getMediaToKeepParsed } from 'src/utils/canvasHelpers';
import createAction from './createAction';

export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS';
export const GET_SUGGESTIONS_REQUEST = 'GET_SUGGESTIONS_REQUEST';
export const GET_SUGGESTIONS_ERROR = 'GET_SUGGESTIONS_ERROR';

export const SET_SHOW_SUGGESTIONS = 'SET_SHOW_SUGGESTIONS';

export const CREATE_PROJECT_FROM_SUGGESTION = 'CREATE_PROJECT_FROM_SUGGESTION';
export const CREATE_PROJECT_FROM_SUGGESTION_SUCCESS = 'CREATE_PROJECT_FROM_SUGGESTION_SUCCESS';
export const CREATE_PROJECT_FROM_SUGGESTION_REQUEST = 'CREATE_PROJECT_FROM_SUGGESTION_REQUEST';
export const CREATE_PROJECT_FROM_SUGGESTION_ERROR = 'CREATE_PROJECT_FROM_SUGGESTION_ERROR';

export const APPLY_SUGGESTION_TO_PROJECT = 'APPLY_SUGGESTION_TO_PROJECT';
export const APPLY_SUGGESTION_TO_PROJECT_SUCCESS = 'APPLY_SUGGESTION_TO_PROJECT_SUCCESS';
export const APPLY_SUGGESTION_TO_PROJECT_REQUEST = 'APPLY_SUGGESTION_TO_PROJECT_REQUEST';
export const APPLY_SUGGESTION_TO_PROJECT_ERROR = 'APPLY_SUGGESTION_TO_PROJECT_ERROR';

export const getSuggestionsRequest = createAction(GET_SUGGESTIONS_REQUEST);
export const getSuggestionsSuccess = createAction(GET_SUGGESTIONS_SUCCESS);
export const getSuggestionsError = createAction(GET_SUGGESTIONS_ERROR);

export const setShowSuggestions = createAction(SET_SHOW_SUGGESTIONS);

export const createProjectFromSuggestionRequest =
  createAction(CREATE_PROJECT_FROM_SUGGESTION_REQUEST);
export const createProjectFromSuggestionSuccess =
  createAction(CREATE_PROJECT_FROM_SUGGESTION_SUCCESS);
export const createProjectFromSuggestionError = createAction(CREATE_PROJECT_FROM_SUGGESTION_ERROR);

export const applySuggestionToProjectRequest = createAction(APPLY_SUGGESTION_TO_PROJECT_REQUEST);
export const applySuggestionToProjectSuccess = createAction(APPLY_SUGGESTION_TO_PROJECT_SUCCESS);
export const applySuggestionToProjectError = createAction(APPLY_SUGGESTION_TO_PROJECT_ERROR);

export const getSuggestions = (accountId, page = 1, perPage = 3) => async dispatch => {
  try {
    dispatch(getSuggestionsRequest());
    const { data } = await AccountService.getAccountSuggestions(accountId, page, perPage);
    dispatch(getSuggestionsSuccess({ ...data, page, perPage }));
  } catch (error) {
    dispatch(getSuggestionsError());
  }
};

export const createProjectFromSuggestion = (suggestionId, history) => async dispatch => {
  try {
    dispatch(createProjectFromSuggestionRequest());
    const { data } = await ProjectService.createProjectFromSuggestion(suggestionId);
    dispatch(createProjectFromSuggestionSuccess());
    trackSuggestionUsed();
    history.push(routeWithProps(routesPaths.project, { id: data.project.id }));
  } catch (error) {
    dispatch(createProjectFromSuggestionError());
  }
};

export const applySuggestionToProject = (suggestion, mediaToKeep) => async (dispatch, getState) => {
  const toastId = 'applySuggestionToProject';
  const state = getState();
  try {
    dispatch(applySuggestionToProjectRequest());
    toast(<ToastLoading message="Applying template to project" />, toastInfoConfig(toastId));
    const projectId = state.project.present.id;
    const parsedMedia = getMediaToKeepParsed(mediaToKeep);
    const mediaInCanvas = JSON.stringify(parsedMedia);
    const { data } = await ProjectService.applySuggestion(projectId, suggestion.id, mediaInCanvas);
    await SuggestionService.delete(suggestion.id);
    dispatch(applySuggestionToProjectSuccess(data));
    trackSuggestionUsed();
    trackApplyTemplate(projectId, 'Recommendation', suggestion.layoutSource);
    toast.success('Nice choice, that template looks great 🔮', toastSuccessConfig);
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(applySuggestionToProjectError());
  } finally {
    toast.dismiss(toastId);
  }
};
