import React, { useCallback, useEffect, useState } from 'react';
import { func, bool, number } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { Input } from 'src/common/input';
import { TRANSFER_PROJECT, transferProject } from 'src/actions/projectActions';
import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { validateProjectTransfer } from 'src/utils/constrains';
import { Select } from 'src/common/select';
import { GET_ACCOUNTS_OF_USER, getAccountsOfUserAsAdmin } from 'src/actions/accountActions';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import styles from './TransferProjectModal.module.scss';

const TransferProjectModal = ({ isShowing, hide, projectId }) => {
  const dispatch = useDispatch();

  const {
    status: accountsStatus,
    statusInfo: accountsStatusInfo,
  } = useStatus(GET_ACCOUNTS_OF_USER);

  const { status: statusTransfer } = useStatus(TRANSFER_PROJECT);

  const transferProjectRequest = useCallback(async (values) => {
    await dispatch(transferProject(projectId, values.account.value));
  }, [dispatch, projectId]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
    setErrors,
  } = useForm(transferProjectRequest, { validate: validateProjectTransfer });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const [accounts, setAccounts] = useState([]);

  const searchAccounts = async () => {
    await dispatch(getAccountsOfUserAsAdmin(values.email));
  };

  useEffect(() => {
    if (accountsStatus === SUCCESS) {
      const selectOptions = accountsStatusInfo.accounts.map(acc => (
        { value: acc.id, label: acc.brandName }
      ));
      setAccounts(selectOptions);
      handleInputChange('account', selectOptions[0]);
      dispatch(reset(GET_ACCOUNTS_OF_USER));
    }
    if (accountsStatus === ERROR) {
      setErrors({ email: accountsStatusInfo });
      dispatch(reset(GET_ACCOUNTS_OF_USER));
    }
  }, [accountsStatus, accountsStatusInfo, dispatch, handleInputChange, setErrors]);

  useEffect(() => {
    if (statusTransfer === SUCCESS) {
      hide();
      dispatch(reset(TRANSFER_PROJECT));
    }
  }, [dispatch, statusTransfer, hide]);

  const handleChangeAccount = (item) => {
    handleInputChange('account', item);
  };

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>Transfer project</span>
        <span className={styles.subtitle}>
          This action will duplicate this project into the recipient&apos;s account
        </span>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.emailContainer}>
            <Input
              name="email"
              placeholder="Email"
              disabled={statusTransfer === LOADING}
              containerClassName={styles.emailInput}
              {...inputProps('email')}
            />
            <Button
              className={styles.emailButton}
              onClick={searchAccounts}
              disabled={!values.email?.trim()}
              loading={accountsStatus === LOADING}
              short
            >
              Search accounts
            </Button>
          </div>
          <Select
            name="account"
            label="Account"
            options={accounts}
            {...inputProps('account')}
            onChange={handleChangeAccount}
            value={values?.account || undefined}
            closeMenuOnSelect
          />
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              type="submit"
              disabled={!values?.account}
              loading={statusTransfer === LOADING}
              alternativeCTA
            >
              Transfer
            </Button>
            <Button
              className={styles.buttonCancel}
              onClick={hide}
              disabled={statusTransfer === LOADING}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

TransferProjectModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  projectId: number.isRequired,
};

export { TransferProjectModal };
