import React, { useCallback, useMemo } from 'react';
import { bool, func } from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import ArrowUp from 'src/assets/icons/arrow-up-white.svg';
import { useProjectSelector, useSession, useWindowSize } from 'src/hooks';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import { SHOWING_STATUS } from 'src/constants/general';
import { showProductTip } from 'src/actions/userSettingsActions';
import { Button } from 'src/common/button';
import { EnableDownloadModal } from 'src/pages/export-preview/enable-download-modal';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import styles from './PublishProjectButton.module.scss';

const PublishProjectButton = ({
  exportAndDownloadPreviews,
  copyEmbedCodeAndExport,
  exportWithToast,
  disabled,
  exportAndGoToPreview,
}) => {
  const dispatch = useDispatch();

  const { embedCodeGenerated } = useProjectSelector();

  const { user } = useSession();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const onClickDownload = useCallback(() => {
    if (user.settings.showDownloadProjectTip === SHOWING_STATUS.ALREADY_CLOSED ||
      isMobile) {
      exportAndDownloadPreviews();
    } else {
      dispatch(showProductTip('showDownloadProjectTip'));
    }
  }, [dispatch, exportAndDownloadPreviews, user, isMobile]);

  const menuOptions = useMemo(() => (
    [
      { text: 'Preview', action: exportAndGoToPreview, className: styles.previewOption },
      { text: 'Export images', action: onClickDownload },
      { text: 'Generate embed code', action: copyEmbedCodeAndExport },
    ]
  ), [onClickDownload, copyEmbedCodeAndExport, exportAndGoToPreview]);

  return (
    <>
      {isMobile ? (
        <Button
          className={styles.publishSingleButton}
          onClick={exportAndGoToPreview}
          alternativeCTA
          short
        >
          Publish
        </Button>
      ) : (
        <CollapsibleOptions
          options={menuOptions}
          className={styles.container}
          optionsClassName={styles.options}
          optionClassName={styles.option}
          buttonClassName={cn(styles.button, { [styles.embedButton]: embedCodeGenerated })}
          iconOpened={ArrowUp}
          closeOnClickOption
          disabled={disabled}
          buttonPrefix={embedCodeGenerated ? (
            <Button
              onClick={exportWithToast}
              className={styles.publishButton}
              alternativeCTA
              short
              disabled={disabled}
            >
              Publish
            </Button>
          ) :
            undefined}
        >
          {!embedCodeGenerated ? <span className={styles.publishText}>Publish</span> : undefined}
        </CollapsibleOptions>
      )}
      {user?.settings?.showDownloadProjectTip === SHOWING_STATUS.SHOW && (
        <EnableDownloadModal
          onClose={exportAndDownloadPreviews}
        />
      )}
    </>
  );
};

PublishProjectButton.propTypes = {
  disabled: bool.isRequired,
  exportAndDownloadPreviews: func.isRequired,
  copyEmbedCodeAndExport: func.isRequired,
  exportWithToast: func.isRequired,
  exportAndGoToPreview: func.isRequired,
};

export { PublishProjectButton };
