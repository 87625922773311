import { useSelector } from 'react-redux';

const useStatus = action => (
  useSelector(({ actionStatus }) => {
    const { status, error, payload, statusInfo } = actionStatus[action] || {};
    return {
      status,
      error,
      payload,
      statusInfo,
    };
  })
);

export { useStatus };
