import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import ArrowLeftWhite from 'src/assets/icons/arrow-left-white.svg';
import ArrowRightWhite from 'src/assets/icons/arrow-right-white.svg';
import {
  setRuleState,
  discardTip,
  setReadyToShowDesignTip,
} from 'src/actions/rulesEngineActions';
import { RULE_STATE } from 'src/constants/rulesEngine';
import styles from './DesignTip.module.scss';

/* eslint-disable max-len */
const RULE_TIPS_BY_NAME = {
  limitCanvasCount: {
    name: 'Limit your frames',
    tip: 'Data says most users don\'t past three frames of content. Keep your message short with a call-to-action at the end.',
    action: null,
  },
  limitFontsCount: {
    name: 'Multiple fonts',
    tip: 'Good rule of thumb is to not use multiple fonts in a design. Using 1-2 fonts help keep a design legible and concise.',
    action: null,
  },
  imagesScores: {
    name: 'Image insight',
    tip: null,
    buildTip: (payload) => {
      // eslint-disable-next-line no-unused-vars
      const { uuid, score, scoreReason } = payload;
      return `Your projects tend to have better results when you use pictures with closer relationship to your business. Want to choose a new one? Score: ${score}`;
    },
    action: null,
  },
};
/* eslint-enable max-len */

const DesignTip = () => {
  const dispatch = useDispatch();
  const {
    showDesignTipForElementUUID,
    tipsObjects,
  } = useSelector(({ rulesEngine }) => ({
    showDesignTipForElementUUID: rulesEngine.showDesignTipForElementUUID,
    tipsObjects: rulesEngine.tipsObjects,
  }));

  const [title, setTitle] = useState('');
  const [tip, setTip] = useState('');

  const [tipIds, setTipIds] = useState(null);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const tipObjForElement = tipsObjects[showDesignTipForElementUUID];

  useEffect(() => {
    if (!tipObjForElement) {
      return;
    }
    const tipObject = RULE_TIPS_BY_NAME[tipObjForElement.ruleName];
    if (tipObject) {
      setTitle(tipObject.name);
      let tipText = tipObject.tip;
      if (tipObjForElement && tipObject.buildTip) {
        tipText = tipObject.buildTip(tipObjForElement.payload);
      }
      setTip(tipText);
    } else {
      setTitle('Unhandled rule');
      setTip(`We don't have a tip for the rule '${tipObjForElement.ruleName}'.`);
    }
  }, [showDesignTipForElementUUID, tipObjForElement]);

  const close = useCallback(() => {
    dispatch(discardTip({
      ruleName: tipObjForElement.ruleName,
      designTipElementUUID: showDesignTipForElementUUID,
    }));
  }, [dispatch, tipObjForElement, showDesignTipForElementUUID]);

  const dontShowTipAgain = useCallback(() => {
    dispatch(setRuleState(tipObjForElement.ruleName, RULE_STATE.IGNORED));
  }, [dispatch, tipObjForElement]);

  useEffect(() => {
    if (tipsObjects) {
      const localTipIds = Object.keys(tipsObjects);

      setTipIds(localTipIds);
    }
  }, [tipsObjects, setTipIds]);

  useEffect(() => {
    if (tipIds?.length > 0) {
      setCurrentTipIndex(tipIds.findIndex((tipId) => tipId === showDesignTipForElementUUID));
    }
  }, [showDesignTipForElementUUID, tipIds]);

  const showPrevTip = useCallback(() => {
    if (currentTipIndex > 0) {
      const prevTipIndex = currentTipIndex - 1;
      dispatch(setReadyToShowDesignTip({ status: true, uuid: tipIds[prevTipIndex] }));
      setCurrentTipIndex(prevTipIndex);
    }
  }, [dispatch, currentTipIndex, tipIds]);

  const showNextTip = useCallback(() => {
    if (currentTipIndex < tipIds.length) {
      const nextTipIndex = currentTipIndex + 1;
      dispatch(setReadyToShowDesignTip({ status: true, uuid: tipIds[nextTipIndex] }));
      setCurrentTipIndex(nextTipIndex);
    }
  }, [dispatch, currentTipIndex, tipIds]);

  return (
    <div className={styles.container}>
      <button className={styles.close} onClick={close}>
        <img src={CloseWhiteIcon} alt="Close" />
      </button>
      <div className={styles.tip}>
        <div>
          <span className={styles.icon} role="img" aria-label="lightbulb">💡</span>
        </div>
        <div className={styles.content}>
          <span className={styles.title}>
            <span className={styles.titleHighlight}>Design tip:</span>
            {title}
          </span>
          <span className={styles.text}>{tip}</span>
          <div className={styles.actions}>
            {tipIds?.length > 1 && (
            <div className={styles.pager}>
              <button
                onClick={showPrevTip}
                onTap={showPrevTip}
                disabled={currentTipIndex === 0}
              >
                <img src={ArrowLeftWhite} alt="prev" />
              </button>
              <span>{currentTipIndex + 1} of {tipIds.length}</span>
              <button
                onClick={showNextTip}
                onTap={showNextTip}
                disabled={currentTipIndex === tipIds?.length - 1}
              >
                <img src={ArrowRightWhite} alt="next" />
              </button>
            </div>
            )}
            <div>
              <button onClick={dontShowTipAgain}>
                Don&apos;t show this tip again
              </button>
              <button className={styles.gotIt} onClick={close}>
                Got it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DesignTip };
