import React from 'react';
import { format } from 'date-fns';
import chunk from 'lodash.chunk';
import getAgent from '@egjs/agent';

import { BROWSERS } from 'src/constants/general';
import { LEFT_CLICK_CODE, RIGHT_CLICK_CODE } from 'src/constants/keyboardCodes';
import { isVideo } from 'src/utils/videoHelpers';
import {
  getCloudinaryFilenameFromUrl,
} from 'src/utils/cloudinaryHelpers';

export const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

export const isValidUsername = username => /^[\w-]+$/.test(username);

export const isValidTemplateName = name => name?.length > 0;

export const hexToRgb = hex => {
  const color = hex === 'transparent' ? '#000000' : hex;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

export const rgbToHex = (r, g, b) => (
  `#${[r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }).join('')}`
);

export const getRGBAColor = ({ hex, alpha }) => {
  const color = hex === 'transparent' ? '#000000' : hex;
  const { r, g, b } = hexToRgb(color);
  return `rgb(${r}, ${g}, ${b}, ${alpha / 100})`;
};

export const parseFontStyle = fontStyle => {
  const styles = fontStyle.split(' ');
  return styles.reduce((res, current) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(current)) {
      res.fontWeight = parseInt(current, 10);
    } else {
      res.fontStyle = current;
    }
    return res;
  }, { fontWeight: 400, fontStyle: 'normal' });
};

export const routeWithProps = (route, props) => {
  let newRoute = route;
  Object.keys(props).forEach(prop => {
    newRoute = newRoute.replace(new RegExp(`:${prop}(?:\\?|)`), props[prop]);
  });
  return newRoute;
};

export const dataURItoBlob = dataURI => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

export const showPreviews = (previews, videoUrls) => (
  previews.map((preview, index) => {
    if (!videoUrls || !videoUrls[index]) {
      return <img src={preview} alt="" key={preview} />;
    }
    return (
      <video key={preview} controls muted>
        <source src={videoUrls[index]} type="video/mp4" />
      </video>
    );
  })
);

export const getImageDimensions = (url, callback) => {
  let img = new Image();
  img.addEventListener('load', ({ target }) => {
    callback(target.naturalWidth, target.naturalHeight);
    img = null;
  });
  img.src = url;
};

export const cleanEditableImage = (elements, editableImage) => (
  elements.map(elem => {
    if (elem.uuid === editableImage) {
      elem = {
        ...elem,
        ...elem.temporaryCropAttrs,
        rotation: elem.temporaryRotation,
      };
      delete elem.temporaryRotation;
      delete elem.temporaryCropAttrs;
      return elem;
    }
    return elem;
  })
);

export const getNextAvailableName = (existentNames, namePrefix) => {
  const existentNamesWithPrefix = existentNames.filter(name => (
    name.startsWith(namePrefix)
  ));
  let number = existentNamesWithPrefix.length + 1;
  const ids = existentNamesWithPrefix?.map((name) => {
    const splitted = name.split(' ');
    const num = Number(splitted.pop()) || 0;
    return num;
  });
  const sorted = ids?.sort((a, b) => (a - b));
  if (sorted?.length && sorted?.includes(number)) {
    number = sorted?.pop() + 1;
  }
  return number > 1 ? `${namePrefix} ${number}` : namePrefix;
};

export const getStringCapitalized = word => word.charAt(0).toUpperCase() + word.slice(1);

const getFormattedDateForAnalytic = date => format(date, 'yyyy-MM-dd');

export const getFormattedDateRangeForAnalytic = (dateFrom, dateTo) => {
  const from = getFormattedDateForAnalytic(dateFrom);
  const to = getFormattedDateForAnalytic(dateTo);
  return { from, to };
};

export const secsToHHMMSS = (secs) => {
  const roundedSecs = Math.floor(secs);
  const hours = Math.floor(roundedSecs / 3600);
  const minutes = Math.floor(roundedSecs / 60) % 60;
  const seconds = roundedSecs % 60;
  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const getClientIANATimeZone = () => (
  Intl.DateTimeFormat().resolvedOptions().timeZone || 'Etc/GMT'
);

export const truncateFilename = (filename, limit = -1) => {
  if (limit > 1) {
    const filenameArr = filename.split('.');
    const ext = filenameArr.pop();
    const name = filenameArr.join('.');
    if (name.length > limit) {
      return `${name.substr(0, limit)}...${ext}`;
    }
    return `${name}.${ext}`;
  }

  return filename;
};

export const isHtmlTagFocused = () => document.activeElement.tagName === 'INPUT' ||
  document.activeElement.tagName === 'TEXTAREA';

export const getRootElementFontSize = () => (
  parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  )
);

export const serializeUserAccounts = user => {
  user.membership.accounts.sort((a, b) => (
    a.brandName.localeCompare(b.brandName)
  ));
  return user;
};

export const getUserNameToShow = (user, showLastName = true) => {
  if (!user) {
    return '';
  }
  if (user.name && user.lastName && showLastName) {
    return `${user.name} ${user.lastName}`;
  }
  if (user.name) {
    return user.name;
  }
  return user.username;
};

export const isPublicPages = () => {
  const { hostname } = window.location;

  return hostname === process.env.REACT_APP_MY_HOSTNAME;
};

export const promiseInListWithLimit = async (list, callback, limit = 5) => {
  const chunks = chunk(list, limit);
  let result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [i, subList] of chunks.entries()) {
    // eslint-disable-next-line no-await-in-loop
    const newSublist = await Promise.all(subList.map((elem, index) => (
      callback(elem, index + limit * i)
    )));
    result = [...result, ...newSublist];
  }
  return result;
};

export const getBrowserName = () => {
  const agent = getAgent();

  switch (agent.browser.name) {
    case 'chrome':
      return BROWSERS.CHROME;

    case 'safari':
      return BROWSERS.SAFARI;

    default:
      return BROWSERS.OTHERS;
  }
};

export const isUrlAbsolute = url => {
  const absRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
  return absRegex.test(url);
};

export const isRightClick = evt => evt?.button === RIGHT_CLICK_CODE ||
  (evt?.ctrlKey && evt?.button === LEFT_CLICK_CODE);

export const isTouchDevice = () => (
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
);

export const isGif = (assetUrl) => (
  /(gif)$/ig.test(assetUrl)
);

export const getAssetFilenameFromUrl = (assetUrl) => {
  let filename = '';
  if (isVideo(assetUrl)) {
    if (assetUrl.includes('#')) {
      // video url from api.video
      filename = assetUrl.split('#').pop();
    } else {
      // video url from cloudinary (backwards compatibility)
      filename = getCloudinaryFilenameFromUrl(assetUrl);
    }
  } else {
    filename = getCloudinaryFilenameFromUrl(assetUrl);
  }
  return filename;
};
