import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import {
  processWebsite,
  setCurrentBrandAssetStatus,
  updateAndProcessWebsite,
} from 'src/actions/accountActions';
import { Input } from 'src/common/input';
import { useSession } from 'src/hooks';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import { BusinessCategorySelect } from '../common/business-category-select';
import styles from './EnterWebsite.module.scss';

const EnterWebsite = ({
  setCurrentStep,
  setModalOpen,
  editMode,
  closeEditWebsiteModal,
  inferredWebsite,
}) => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const [website, setWebsite] = useState(inferredWebsite);
  const [error, setError] = useState('');
  const [businessCategory, setBusinessCategory] = useState();

  const saveWebsite = () => {
    if (editMode) {
      dispatch(updateAndProcessWebsite(accountId, website, businessCategory));
    } else {
      dispatch(processWebsite(accountId, website, businessCategory));
    }
    setCurrentStep(2);
  };

  const onSave = () => {
    // eslint-disable-next-line max-len
    const isLink = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/.test(website);
    if (!website) {
      setError('Enter a URL');
    } else if (!isLink) {
      setError('That URL doesn’t look correct.');
    } else if (!error) {
      saveWebsite();
    }
  };

  const dismiss = () => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.NOT_APPLICABLE,
      id: accountId,
    };
    dispatch(setCurrentBrandAssetStatus(params));
    setModalOpen(false);
    closeEditWebsiteModal && closeEditWebsiteModal();
  };

  const onChange = (value) => {
    setWebsite(value);
    if (error) {
      setError('');
    }
  };

  const onKeyDown = ({ key }) => {
    if (key === ENTER_CHARACTER_CODE) {
      saveWebsite();
    }
  };

  return (
    <div className={styles.content}>
      <span className={styles.question}>What&apos;s your brand&apos;s website?</span>
      <Input
        containerClassName={styles.input}
        placeholder="https://"
        onChange={({ target }) => onChange(target.value)}
        value={website}
        onKeyDown={onKeyDown}
        error={error}
        touched
        name="website"
      />
      <BusinessCategorySelect setBusinessCategory={setBusinessCategory} />
      <div className={styles.actions}>
        <Button
          className={styles.actionButton}
          onClick={onSave}
        >
          Next
        </Button>
        <Button
          className={styles.dismissButton}
          secondary
          onClick={dismiss}
        >
          I don&apos;t have one
        </Button>
      </div>
    </div>
  );
};

EnterWebsite.propTypes = {
  setCurrentStep: func.isRequired,
  setModalOpen: func.isRequired,
  editMode: bool.isRequired,
  closeEditWebsiteModal: func,
  inferredWebsite: string,
};

export { EnterWebsite };
