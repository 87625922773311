import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { func, object } from 'prop-types';

import styles from './UploadFilesButton.module.scss';

const UploadFilesButton = ({
  uploadFiles,
  acceptType = 'image/*, .heic, video/*',
}) => {
  const refInput = useRef();

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const onClick = () => {
    if (!disabled && refInput.current) {
      refInput.current.click();
    }
  };

  const onClickUploadFiles = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { target: { files } } = event;
    const filesToSave = Object.values(files);
    uploadFiles(filesToSave);
  };

  return (
    <button className={styles.container} disabled={disabled} onClick={onClick}>
      <input
        type="file"
        accept={acceptType}
        className={styles.input}
        ref={refInput}
        onChange={onClickUploadFiles}
        multiple
        value=""
      />
      <span className={styles.upload}>Upload Files</span>
    </button>
  );
};

UploadFilesButton.propTypes = {
  uploadFiles: func.isRequired,
  acceptType: object,
};

export { UploadFilesButton };
