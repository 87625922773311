import React, { useEffect, useState, useMemo } from 'react';
import { string, func, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import CheckIcon from 'src/assets/icons/check.svg';
import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { VIDEO_ELEMENT, IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { snapImageOfVideo, isVideo } from 'src/utils/videoHelpers';
import { optimizeCloudinaryThumbnail } from 'src/utils/cloudinaryHelpers';
import styles from './BrandLibraryCard.module.scss';

const BrandLibraryCard = ({
  preview,
  addImage,
  isSelected,
  id,
  name,
  showName = true,
  isQrImage,
  className,
  imageClassName,
  imageButtonClassName,
  optimizeImage = true,
}) => {
  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const asset = useMemo(() => ({
    src: preview,
    id,
    name,
    uploadType: LOCAL_UPLOAD,
    ...(isQrImage && { qrImage: true }),
  }), [id, name, preview, isQrImage]);

  if (isVideo(preview)) {
    asset.type = VIDEO_ELEMENT;
  } else {
    asset.type = IMAGE_ELEMENT;
  }

  const onClick = () => {
    addImage(asset);
  };

  const [previewSrc, setPreviewSrc] = useState();

  useEffect(() => {
    let mounted = true;
    if (isVideo(preview)) {
      snapImageOfVideo(preview, (videoThumbSrc) => {
        if (mounted) {
          setPreviewSrc(videoThumbSrc);
        }
      });
    } else if (optimizeImage) {
      const imageThumbnail = optimizeCloudinaryThumbnail(preview);
      setPreviewSrc(imageThumbnail);
    } else {
      setPreviewSrc(preview);
    }

    return () => { mounted = false; };
  }, [preview, asset, optimizeImage]);

  return (
    <div className={cn(styles.container, className)}>
      <button
        className={cn(styles.imageContainer, imageButtonClassName)}
        onClick={onClick}
        disabled={disabled}
      >
        <img src={previewSrc} alt="" className={cn(styles.image, imageClassName)} />
        {isSelected && (
          <div className={styles.overlay}>
            <div className={styles.check}>
              <img src={CheckIcon} alt="Check" />
            </div>
          </div>
        )}
      </button>
      {showName && (
        <span className={styles.name}>
          {name}
        </span>
      )}
    </div>
  );
};

BrandLibraryCard.propTypes = {
  preview: string.isRequired,
  addImage: func.isRequired,
  isSelected: bool.isRequired,
  id: string.isRequired,
  name: string,
  isQrImage: bool,
  className: string,
  imageClassName: string,
  imageButtonClassName: string,
  showName: bool,
  optimizeImage: bool,
};

export { BrandLibraryCard };
