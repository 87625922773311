import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import LayoutIcon from 'src/assets/icons/layout-white.svg';
import CloseIcon from 'src/assets/icons/close-white.svg';
import { changeControlOpen } from 'src/actions/canvasActions';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import { PROJECT_TYPE } from 'src/constants/general';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { LayoutControl } from '../layout-control';
import { SizeControl } from '../size-control';
import { ColorControl } from '../color-control';
import styles from './EditCanvasControl.module.scss';

const EditCanvasControl = () => {
  const dispatch = useDispatch();

  const { type } = useProjectSelector();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const [layoutOnMobileOpen, setLayoutOnMobileOpen] = useState(false);

  const ref = useRef();

  const onClose = useCallback(() => {
    dispatch(changeControlOpen(''));
  }, [dispatch]);

  const getMobileContent = useMemo(() => (
    layoutOnMobileOpen ? (
      <LayoutControl onCloseMobile={() => setLayoutOnMobileOpen(false)} />
    ) : (
      <>
        {type !== PROJECT_TYPE.SIGN && <SizeControl />}
        <ColorControl />
        <button onClick={() => setLayoutOnMobileOpen(true)} className={styles.layoutButton}>
          <img src={LayoutIcon} alt="Choose layout" />
        </button>
      </>
    )), [layoutOnMobileOpen, type]);

  return (
    <div className={styles.container} ref={ref}>
      {!isMobile ? (
        <>
          <div className={styles.closeContainer}>
            <button className={styles.closeButton} onClick={onClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <span className={styles.title}>Canvas Editor</span>
          <ColorControl />
          {type !== PROJECT_TYPE.SIGN && <SizeControl />}
          <LayoutControl />
        </>
      ) : (
        getMobileContent
      )}
    </div>
  );
};

export { EditCanvasControl };
