import React, { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { reset } from 'src/actions/statusActions';
import { useWindowSize } from 'src/hooks';
import {
  LeftImg,
  LeftImg2x,
  LeftImg3x,
  RightImg,
  RightImg2x,
  RightImg3x,
} from 'src/assets/images/background';
import { SEND_EMAIL_PASSWORD } from 'src/actions/userActions';
import { LANDING_PAGE_LINK } from 'src/constants/general';
import Logotype from 'src/assets/icons/logotype.svg';
import { LARGE_WIDTH } from 'src/constants/breakpoints';
import { routesPaths } from 'src/routes/routesPaths';
import { Footer } from 'src/common/footer';
import { ForgotPasswordContent } from './forgot-password-content';
import styles from './ForgotPassword.module.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { windowSize: { width } } = useWindowSize();
  const isLargeScreen = useMemo(() => width > LARGE_WIDTH, [width]);

  useEffect(() => () => dispatch(reset(SEND_EMAIL_PASSWORD)), [dispatch]);

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <a href={LANDING_PAGE_LINK}>
          <img src={Logotype} alt="Stagger logotype" className={styles.logotype} />
        </a>
        <div className={styles.container}>
          {isLargeScreen && (
          <img
            src={LeftImg}
            alt=""
            srcSet={`${LeftImg2x} 2x, ${LeftImg3x} 3x`}
            className={styles.image}
          />
          )}
          <div className={styles.content}>
            <div className={styles.form}>
              <h1 className={styles.title}>Reset your password</h1>
              <ForgotPasswordContent />
            </div>
            <div className={styles.signupContainer}>
              <span>Don&apos;t have an account?</span>
              <Link to={routesPaths.signup} className={styles.link}>Sign up now</Link>
            </div>
          </div>
          {isLargeScreen && (
          <img
            src={RightImg}
            alt=""
            srcSet={`${RightImg2x} 2x, ${RightImg3x} 3x`}
            className={styles.image}
          />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { ForgotPassword };
