/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import styles from './RadioButton.module.scss';

const RadioButton = ({ onChange, isSelected, label, value, className }) => (
  <label className={cn(styles.radio, className)}>
    <label className={styles.radioInput}>
      <input
        type="radio"
        onChange={onChange}
        value={value}
        checked={isSelected}
        className={styles.radioInputDefault}
      />
      <span className={styles.radioControl} />
    </label>
    <span className={styles.radioLabel}>{label}</span>
  </label>
);

RadioButton.propTypes = {
  onChange: func.isRequired,
  isSelected: bool.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  className: string,
};

export { RadioButton };
