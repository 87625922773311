import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';

import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import ArrowLeft from 'src/assets/icons/arrow-complete-left.svg';
import ArrowRight from 'src/assets/icons/arrow-complete-right.svg';
import ArrowDown from 'src/assets/icons/arrow-complete-down.svg';
import ArrowUp from 'src/assets/icons/arrow-complete-up.svg';
import { moveElementWithKeyboard } from 'src/actions/projectActions';
import {
  BOTTOM_ARROW_CODE,
  LEFT_ARROW_CODE,
  RIGHT_ARROW_CODE,
  TOP_ARROW_CODE,
} from 'src/constants/keyboardCodes';

const ElementMovementOptions = ({ optionClassName, showGeneralOptions }) => {
  const dispatch = useDispatch();

  const moveElementPosition = useCallback((code) => {
    let delta = 1;
    if (code === LEFT_ARROW_CODE || code === TOP_ARROW_CODE) {
      delta = -delta;
    }
    dispatch(moveElementWithKeyboard({ code, delta }));
  }, [dispatch]);

  return (
    <>
      <button
        className={optionClassName}
        onClick={showGeneralOptions}
        onTouchEnd={showGeneralOptions}
      >
        <img
          src={CloseWhiteIcon}
          alt="Close"
        />
      </button>
      <button
        className={optionClassName}
        onClick={() => moveElementPosition(LEFT_ARROW_CODE)}
        onTouchEnd={() => moveElementPosition(LEFT_ARROW_CODE)}
      >
        <img
          src={ArrowLeft}
          alt="Move Left"
        />
      </button>
      <button
        className={optionClassName}
        onClick={() => moveElementPosition(RIGHT_ARROW_CODE)}
        onTouchEnd={() => moveElementPosition(RIGHT_ARROW_CODE)}
      >
        <img
          src={ArrowRight}
          alt="Move Right"
        />
      </button>
      <button
        className={optionClassName}
        onClick={() => moveElementPosition(TOP_ARROW_CODE)}
        onTouchEnd={() => moveElementPosition(TOP_ARROW_CODE)}
      >
        <img
          src={ArrowUp}
          alt="Move Up"
        />
      </button>
      <button
        className={optionClassName}
        onClick={() => moveElementPosition(BOTTOM_ARROW_CODE)}
        onTouchEnd={() => moveElementPosition(BOTTOM_ARROW_CODE)}
      >
        <img
          src={ArrowDown}
          alt="Move Down"
        />
      </button>
    </>
  );
};

ElementMovementOptions.propTypes = {
  optionClassName: string,
  showGeneralOptions: func.isRequired,
};

export { ElementMovementOptions };
