import React, { forwardRef, useState } from 'react';
import { arrayOf, string, shape, func, node, bool, number, oneOfType, object } from 'prop-types';
import cn from 'classnames';

import ArrowUp from 'src/assets/icons/arrow-up.svg';
import ArrowDown from 'src/assets/icons/arrow-down.svg';
import { useClickOutside } from 'src/hooks';
import { SimpleOption } from './simple-option';
import styles from './CollapsibleOptions.module.scss';

const CollapsibleOptions = forwardRef(({
  options,
  className = '',
  buttonClassName = '',
  optionsClassName = '',
  optionClassName = '',
  iconClassName = '',
  iconOpened = ArrowUp,
  iconClosed = ArrowDown,
  animateIcon = true,
  children,
  closeOnClickOption = false,
  closeOnClickOutside = true,
  disabled = false,
  selectedValue,
  buttonPrefix,
  optionsStyle,
}, buttonRef) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false), isOpen, [], !closeOnClickOutside);

  const optionClick = (action, opCloseOnClickOption = closeOnClickOption) => {
    if (opCloseOnClickOption) {
      setIsOpen(false);
    }
    action();
  };

  const renderSimpleOption = (option) => {
    const {
      text,
      action,
      href,
      value,
      className: opClassName,
      closeOnClickOption: opCloseOnClickOption,
      prefix,
      posfix,
    } = option;
    return (
      <SimpleOption
        key={text || value}
        action={action}
        text={text}
        href={href}
        optionClassName={optionClassName}
        isActive={value && selectedValue && selectedValue === value}
        closeOnClickOption={opCloseOnClickOption}
        opClassName={opClassName}
        optionClick={optionClick}
        prefix={prefix}
        posfix={posfix}
      />
    );
  };

  return (
    <div className={`${styles.container} ${className}`} ref={ref}>
      {buttonPrefix}
      <button
        className={`${styles.button} ${buttonClassName}`}
        onClick={() => setIsOpen(open => !open)}
        disabled={disabled}
        ref={buttonRef}
      >
        {children}
        <img
          src={!isOpen && !animateIcon ? iconClosed : iconOpened}
          alt=""
          className={cn(
            styles.icon,
            iconClassName,
            { [styles.closedIcon]: animateIcon && !isOpen, [styles.animationIcon]: animateIcon },
          )}
        />
      </button>
      {isOpen && (
        <div className={`${styles.options} ${optionsClassName}`} style={optionsStyle}>
          {options.map((option) => {
            if (option.group) {
              return (
                <div className={styles.group} key={option.groupTitle}>
                  <span className={styles.groupTitle}>{option.groupTitle}</span>
                  {option.group.map(op => renderSimpleOption(op))}
                </div>
              );
            }
            return renderSimpleOption(option);
          })}
        </div>
      )}
    </div>
  );
});

const simpleOption = shape({
  text: string,
  action: func,
  href: string,
  value: oneOfType([string, number]),
  className: string,
  closeOnClickOption: bool,
  prefix: oneOfType([node, object]),
  posfix: oneOfType([node, object]),
});

const groupOption = shape({
  groupTitle: string,
  group: arrayOf(simpleOption),
});

CollapsibleOptions.propTypes = {
  options: arrayOf(oneOfType([
    simpleOption,
    groupOption,
  ])).isRequired,
  selectedValue: string,
  className: string,
  buttonClassName: string,
  optionsClassName: string,
  optionClassName: string,
  iconClassName: string,
  children: node,
  iconOpened: string,
  iconClosed: string,
  animateIcon: bool,
  closeOnClickOption: bool,
  disabled: bool,
  closeOnClickOutside: bool,
  buttonPrefix: node,
  optionsStyle: object,
};

export { CollapsibleOptions };
