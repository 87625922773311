import React from 'react';
import { func, bool } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { deleteUser, DELETE_USER } from 'src/actions/userActions';
import { useStatus } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import styles from './ConfirmationModal.module.scss';

const ConfirmationModal = ({ isShowing, hide }) => {
  const dispatch = useDispatch();

  const { status: statusDelete } = useStatus(DELETE_USER);

  const onDelete = () => {
    dispatch(deleteUser());
  };

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Permanently delete your account?
        </span>
        <span className={styles.subtitle}>
          We will permanently delete all of your projects and information.
          Are you sure you want to continue?
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={hide}
            alternativeCTA
            disabled={statusDelete === LOADING}
          >
            Nevermind, keep my account
          </Button>
          <Button
            className={styles.buttonDelete}
            onClick={onDelete}
            loading={statusDelete === LOADING}
            spinnerClassName={styles.spinnerIcon}
          >
            Delete Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
};

export { ConfirmationModal };
