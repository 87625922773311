import { HttpClient } from 'src/clients';

class TeamService {
  static editUserAccounts(userId, accountIds) {
    return HttpClient.put('/teams/edit-user-accounts', { userId, accountIds });
  }

  static deleteUser(userId) {
    return HttpClient.delete(`/teams/user/${userId}`);
  }
}

export { TeamService };
