import { ERROR } from 'src/constants/status';
import { isValidEmail, isValidTemplateName, isValidUsername } from './helpers';

const validateUsername = (errors, username, usernameStatus) => {
  if (!username) {
    errors.username = 'You must enter a username';
  } else if (username.length < 4) {
    errors.username = 'Username length must be at least 4 characters long';
  } else if (!isValidUsername(username)) {
    errors.username = 'Username can only contain numbers, letters and dashes';
  } else if (usernameStatus === ERROR) {
    errors.username = 'Sorry, someone has that username already.';
  }
  return errors;
};

export const validateSignup = (values, usernameStatus) => {
  const errors = {};
  validateUsername(errors, values.username, usernameStatus);
  if (!values.email) {
    errors.email = 'You must enter an email';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'You must enter a valid email';
  }
  if (!values.password) {
    errors.password = 'You must enter a password';
  } else if (values.password.length < 6) {
    errors.password = 'Password length must be at least 6 characters long';
  }
  return errors;
};

export const validateSignupSquadEvent = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'You must enter an email';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'You must enter a valid email';
  }
  return errors;
};

export const validateLogin = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'You must enter an email';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'You must enter a valid email';
  }
  if (!values.password) {
    errors.password = 'You must enter a password';
  }
  return errors;
};

export const validateCreateTemplate = (values) => {
  const errors = {};
  if (!isValidTemplateName(values?.name)) {
    errors.name = 'You must enter a valid name';
  }
  if (!values.description) {
    errors.description = 'You must enter a description';
  }
  if (!values.templateCategory) {
    errors.templateCategory = 'You must assign a category';
  }
  return errors;
};

export const validateEditProfileInformation = (values, usernameStatus) => {
  const errors = {};
  if (values?.newPassword) {
    if (values?.newPassword?.length < 6) {
      errors.newPassword = 'Password length must be at least 6 characters long';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'You must enter a password confirmation';
    }
  }
  if (values.confirmPassword) {
    if (values.confirmPassword.length < 6) {
      errors.confirmPassword = 'Password length must be at least 6 characters long';
    }
    if (!values.newPassword) {
      errors.newPassword = 'You need to enter a new password';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
    }
  }
  if (!values.oldPassword && values.newPassword && values.confirmPassword) {
    errors.oldPassword = 'You must enter the old password in order to continue';
  }
  if (values.name === '') {
    errors.name = 'You must enter a name';
  }
  if (values.lastName === '') {
    errors.lastName = 'You must enter a last name';
  }
  if (values.username === '') {
    errors.username = 'You must enter a username';
  }
  if (values.username) {
    validateUsername(errors, values.username, usernameStatus);
  }
  return errors;
};

export const validateEditable = (initialValues, values) => {
  const hasChanges = initialValues.name !== values.name ||
    initialValues.lastName !== values.lastName ||
    initialValues.username !== values.username;
  const isPasswordValid = !!values.confirmPassword && !!values.newPassword &&
    values.confirmPassword.length && values.newPassword.length &&
    values.confirmPassword === values.newPassword;
  return hasChanges || isPasswordValid;
};

export const validateEmailForgotPassword = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'You must enter an email';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'You must enter a valid email';
  }
  return errors;
};

export const validateChangePassword = (values) => {
  const errors = {};
  if (values?.newPassword) {
    if (values?.newPassword?.length < 6) {
      errors.newPassword = 'Password length must be at least 6 characters long';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'You must enter a password confirmation';
    }
  }
  if (values.confirmPassword) {
    if (values.confirmPassword.length < 6) {
      errors.confirmPassword = 'Password length must be at least 6 characters long';
    }
    if (!values.newPassword) {
      errors.newPassword = 'You need to enter a new password';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
    }
  }
  if (!values.newPassword && !values.confirmPassword) {
    errors.newPassword = 'You must enter a new password';
    errors.confirmPassword = 'You must enter a password confirmation';
  }
  return errors;
};

export const validateAccount = (values) => {
  const errors = {};
  if (!values.brandName) {
    errors.brandName = 'You must enter a name';
  }
  return errors;
};

export const validateInvite = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'You must enter a name';
  }
  if (!values.lastName) {
    errors.lastName = 'You must enter a last name';
  }
  if (!values.email) {
    errors.email = 'You must enter an email address';
  }
  if (!values.accounts || values.accounts.length === 0) {
    errors.accounts = 'You must select at least one account';
  }

  return errors;
};

export const validateEditEditor = (values) => {
  const errors = {};
  if (values.accounts.length === 0) {
    errors.accounts = 'You must select at least one account';
  }
  return errors;
};

export const validateCategoryName = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name cannot be empty';
  }
  return errors;
};

export const validateFrameTitle = (values, frameTitles, editableFrameTitleIndex) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title cannot be empty';
  } else if (frameTitles.find((title, index) => title === values.title &&
    index !== editableFrameTitleIndex)) {
    errors.title = 'Title cannot be repeated';
  }
  return errors;
};

export const validateProjectTransfer = (values) => {
  const errors = {};
  if (!values.account) {
    errors.account = 'You must enter an account';
  }
  return errors;
};
