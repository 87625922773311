import React, { useMemo, useCallback, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';

import SaveIcon from 'src/assets/icons/save.svg';
import { updateProject, updateProjectRequest } from 'src/actions/projectActions';
import { useProjectSelector, useSession, useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import {
  ROLE_ADMIN,
  ENABLE_PRIVATE_TEMPLATES,
  VISIBILITY,
  PROJECT_TYPE,
} from 'src/constants/general';
import { PRIVATE_TEMPLATES_PLANS } from 'src/constants/memberships';
import {
  updateExistingTemplate,
  saveTemplate,
  setSelectedTemplate,
} from 'src/actions/productTemplateActions';
import { SaveTemplateModal } from 'src/pages/project/toolbar/save-template-modal';
import ArrowUp from 'src/assets/icons/arrow-up-white.svg';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import styles from './SaveButton.module.scss';

const SaveButton = memo(({
  disabled,
  exportAndDownloadPreviews,
  exportAndDownloadPdf,
  generatePreviews,
  prepareCanvas,
  undoPrepareCanvas,
  openFlyerSettingsModal,
}) => {
  const dispatch = useDispatch();
  const { user, isSubscriptionActive, membershipPlan } = useSession();

  const {
    id,
    size,
    layout,
    name,
    elements,
    groupsOfElementsById,
    videoIdsByPosition,
    gifIdsByPosition,
    color,
    frameTitles,
  } = useProjectSelector();

  const saveAsDraft = useCallback(async () => {
    dispatch(updateProjectRequest());
    const [fileThumbnail] = await generatePreviews({ nameFile: name, isThumbnail: true });
    const newParams = {
      size,
      color: color.hex,
      alpha: color.alpha,
      layoutWidth: layout.width,
      layoutHeight: layout.height,
      layoutSource: layout.source,
      elements,
      groupsOfElementsById,
      videoIdsByPosition,
      gifIdsByPosition,
      frameTitles,
    };
    dispatch(updateProject(id, newParams, fileThumbnail));
  }, [
    color,
    dispatch,
    elements,
    generatePreviews,
    groupsOfElementsById,
    id,
    layout,
    name,
    size,
    videoIdsByPosition,
    gifIdsByPosition,
    frameTitles,
  ]);

  const { template, flyerSettings, type } = useProjectSelector();

  const templateId = template?.id;
  const templateName = template?.name;

  const saveExistingTemplate = useCallback(async () => {
    prepareCanvas();
    const files = await generatePreviews({
      nameFile: templateName,
      isTemplate: true,
    });
    const [fileThumbnail] = await generatePreviews({
      nameFile: templateName,
      isThumbnail: true,
      isTemplate: true,
    });
    undoPrepareCanvas();
    dispatch(updateExistingTemplate(templateId, files, fileThumbnail));
  }, [prepareCanvas, generatePreviews, templateName, undoPrepareCanvas, dispatch, templateId]);

  const [modalOpen, setModalOpen] = useState(false);
  const onOpenSaveTemplate = useCallback(() => setModalOpen(true), []);
  const onClose = useCallback(() => setModalOpen(false), []);

  const onOpenEditTemplate = useCallback(() => {
    const keywords = template.keywords.map(
      value => ({ ...value, label: value.tag }),
    );
    dispatch(setSelectedTemplate({ ...template, keywords, project: { id } }));
  }, [dispatch, template, id]);

  const templateOption = useMemo(() => {
    if (templateId) {
      return ([
        {
          text: 'Save existing template',
          action: saveExistingTemplate,
        },
        {
          text: 'Edit template details',
          action: onOpenEditTemplate,
        },
      ]);
    }
    return ([{ text: 'Save as template', action: onOpenSaveTemplate }]);
  }, [onOpenSaveTemplate, saveExistingTemplate, templateId, onOpenEditTemplate]);

  const templateOptionForWriter = useMemo(() => {
    const privateTemplatesEnabled =
      isSubscriptionActive && PRIVATE_TEMPLATES_PLANS.includes(membershipPlan);
    if (ENABLE_PRIVATE_TEMPLATES && privateTemplatesEnabled && ROLE_ADMIN !== user.role?.id) {
      return templateOption;
    }
    return [];
  }, [isSubscriptionActive, membershipPlan, templateOption, user.role?.id]);

  const flyerOptions = useMemo(() => {
    const isSaved = flyerSettings && (flyerSettings.locationBasedSharing ||
      flyerSettings.visibility === VISIBILITY.PUBLIC);
    if (isSaved) {
      return [{
        text: 'Update settings',
        action: openFlyerSettingsModal,
      }];
    }
    return [];
  }, [flyerSettings, openFlyerSettingsModal]);

  const signOptions = useMemo(() => {
    if (type === PROJECT_TYPE.SIGN) {
      return [
        { text: 'Export as JPG', action: exportAndDownloadPreviews },
        { text: 'Export as PDF', action: exportAndDownloadPdf },
      ];
    }
    return [];
  }, [exportAndDownloadPdf, exportAndDownloadPreviews, type]);


  const menuOptionsWriter = useMemo(() => (
    [
      ...flyerOptions,
      { text: 'Save project', action: saveAsDraft },
      ...templateOptionForWriter,
      ...signOptions,
    ]
  ), [flyerOptions, signOptions, saveAsDraft, templateOptionForWriter]);

  const menuOptionsAdmin = useMemo(() => (
    [...menuOptionsWriter, ...templateOption]
  ), [menuOptionsWriter, templateOption]);

  const onSaveOrEditTemplate = useCallback(async (data) => {
    prepareCanvas();
    const files = await generatePreviews({ nameFile: data.name, isTemplate: true });
    const [fileThumbnail] = await generatePreviews({
      nameFile: data.name,
      isThumbnail: true,
      isTemplate: true,
    });
    undoPrepareCanvas();
    dispatch(saveTemplate(data, files, fileThumbnail));
  }, [dispatch, generatePreviews, prepareCanvas, undoPrepareCanvas]);

  const { windowSize: { width } } = useWindowSize();

  return (
    <>
      <CollapsibleOptions
        options={ROLE_ADMIN === user.role?.id ? menuOptionsAdmin : menuOptionsWriter}
        optionsClassName={styles.options}
        optionClassName={styles.option}
        iconOpened={ArrowUp}
        closeOnClickOption
        disabled={disabled}
      >
        {width <= SMALL_WIDTH ? (
          <img src={SaveIcon} alt="Save as" />
        ) : (
          <span className={styles.saveText}>Project</span>
        )}
      </CollapsibleOptions>
      <SaveTemplateModal
        isShowing={modalOpen}
        onClose={onClose}
        onSaveOrEditTemplate={onSaveOrEditTemplate}
      />
    </>
  );
});

SaveButton.propTypes = {
  disabled: bool.isRequired,
  exportAndDownloadPreviews: func.isRequired,
  exportAndDownloadPdf: func.isRequired,
  generatePreviews: func.isRequired,
  prepareCanvas: func.isRequired,
  undoPrepareCanvas: func.isRequired,
  openFlyerSettingsModal: func.isRequired,
};

export { SaveButton };
