import React, { useRef, useMemo, useState } from 'react';
import { Image } from 'react-konva';
import Konva from 'konva';
import { string, number, bool, func, object, shape } from 'prop-types';
import { connect } from 'react-redux';

import { VIDEO_ELEMENT } from 'src/constants/canvasElements';
import {
  useMoveTransformElement,
  useKeyboardListener,
  useOnClickElement,
  useDraggableElement,
  useDecreaseCanvasSize,
} from 'src/hooks';
import { getPosDragBoundFunc } from 'src/utils/canvasHelpers';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { PlayPauseButton } from './PlayPauseButton';
import { CanvasProcessingElement } from '../../canvas-helper-elements';

const CanvasVideoComponent = ({
  src,
  x,
  y,
  uuid,
  scaleX,
  scaleY,
  rotation,
  width,
  height,
  selectedElement: selectedElementId,
  isInGroup,
  file,
  saved,
  selectedRef,
  scale,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  pinchZoomGesture,
}) => {
  const refVideo = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const {
    moveElementRequest,
    transformElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refVideo, selectedRef);

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const [isTransforming, setIsTransforming] = useState(false);

  const onDragStart = (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && selectElementRequest(e);
      hideTransformerAnchors();
    }
  };

  const onDragEnd = event => {
    if (!isInGroup && event.evt) {
      moveElementRequest(event);
      showTransformerAnchors();
      onDragEndScroll();
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    selectElementRequest,
  );

  const onTransformStart = () => {
    if (!isInGroup) {
      setIsTransforming(true);
    }
  };

  const onTransformEnd = (e) => {
    if (!isInGroup) {
      transformElementRequest(e);
      setIsTransforming(false);
    }
  };

  const { draggableElement } = useDraggableElement(isInGroup);

  useDecreaseCanvasSize(refVideo, isInGroup);

  const [isPlaying, setIsPlaying] = useState(false);

  const videoElement = useMemo(() => {
    const element = document.createElement('video');
    element.crossOrigin = 'Anonymous';
    element.src = `${saved ? src : URL.createObjectURL(file)}#t=1`;
    element.muted = true;
    element.addEventListener('ended', () => {
      element.currentTime = 0;
    });
    element.addEventListener('canplay', () => {
      if (refVideo?.current) {
        refVideo.current.image(videoElement);
      }
    });
    element.addEventListener('play', () => {
      setIsPlaying(true);
    });
    element.addEventListener('pause', () => {
      setIsPlaying(false);
    });
    return element;
  }, [file, saved, src]);

  const onClickPlayPause = (e) => {
    onClick({
      ...e,
      target: { ...e.target, attrs: { ...e.target.attrs, id: uuid } },
    });
    const layer = refVideo.current.getLayer();
    const anim = new Konva.Animation(() => {}, layer);
    if (!isPlaying) {
      videoElement.play();
      anim.start();
    } else {
      videoElement.pause();
      anim.stop();
    }
  };

  return (
    <>
      <Image
        image={videoElement}
        id={uuid}
        ref={refVideo}
        x={x}
        y={y}
        scaleX={scaleX}
        scaleY={scaleY}
        rotation={rotation}
        offsetX={width / 2}
        offsetY={height / 2}
        width={width}
        height={height}
        draggable={draggableElement}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
        onClick={onClick}
        onTap={onClick}
        onTransformStart={onTransformStart}
        onTransformEnd={onTransformEnd}
        onTransform={onTransformElement}
        dragBoundFunc={(pos) => getPosDragBoundFunc(
          refPositions.current,
          pos,
          1 / scale,
        refVideo.current?.absolutePosition(),
        shiftShortcutPressed,
        )}
        elementType={VIDEO_ELEMENT}
        listening={!pinchZoomGesture && !eyeDropper.active}
      />
      {refVideo?.current?.attrs && saved && !isTransforming && (
        <PlayPauseButton
          uuid={uuid}
          x={refVideo.current.attrs.x}
          y={refVideo.current.attrs.y}
          listening={!pinchZoomGesture && !eyeDropper.active}
          onClick={onClickPlayPause}
          isPlaying={isPlaying}
        />
      )}
      {!saved && (
        <CanvasProcessingElement element={{ x, y, width, height, scaleX, scaleY, rotation }} />
      )}
    </>
  );
};

CanvasVideoComponent.propTypes = {
  src: string.isRequired,
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  scaleX: number.isRequired,
  scaleY: number.isRequired,
  rotation: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  selectedElement: string,
  isInGroup: bool,
  file: object,
  saved: bool.isRequired,
  selectedRef: object,
  scale: number,
  eyeDropper: shape({ active: bool }),
  onTransformElement: func.isRequired,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  pinchZoomGesture: bool,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElement: store.project.present.selectedElements[ownProps.uuid],
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  scale: store.canvas.scale,
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const CanvasVideo = connect(mapStateToProps)(CanvasVideoComponent);

export { CanvasVideo };
