import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, number, string } from 'prop-types';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { updateAccountName, UPDATE_ACCOUNT_NAME } from 'src/actions/accountActions';
import { validateAccount } from 'src/utils/constrains';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import styles from './EditAccountForm.module.scss';

const EditAccountForm = ({ id, brandName, onCancel }) => {
  const dispatch = useDispatch();

  const editAccountRequest = useCallback(async (values) => {
    await dispatch(updateAccountName(id, values));
  }, [dispatch, id]);

  const { status: statusEditAccount, error } = useStatus(UPDATE_ACCOUNT_NAME);

  useEffect(() => {
    if (statusEditAccount === SUCCESS) {
      onCancel();
    }

    return () => {
      if (statusEditAccount === SUCCESS || statusEditAccount === ERROR) {
        dispatch(reset(UPDATE_ACCOUNT_NAME));
      }
    };
  }, [dispatch, onCancel, statusEditAccount]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(editAccountRequest, { validate: validateAccount, initialValues: { brandName } });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <div className={styles.formContainer}>
      <form className={styles.container} onSubmit={handleSubmit}>
        <Input
          name="brandName"
          placeholder="Name"
          containerClassName={styles.input}
          disabled={statusEditAccount === LOADING}
          {...inputProps('brandName')}
        />
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onCancel}
            className={styles.cancelButton}
            secondary
            disabled={statusEditAccount === LOADING}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={styles.saveButton}
            loading={statusEditAccount === LOADING}
          >
            Save
          </Button>
        </div>
      </form>
      {statusEditAccount === ERROR && <span className={styles.error}>{error}</span>}
    </div>
  );
};

EditAccountForm.propTypes = {
  id: number.isRequired,
  onCancel: func.isRequired,
  brandName: string.isRequired,
};

export { EditAccountForm };
