import { useCallback, useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { toastErrorConfig } from 'src/constants/toastConfig';
import {
  getGoogleFamilies,
  getCustomFamilies,
  getSystemFamilies,
  loadUploadedFontsToStylesheet,
} from 'src/utils/fontHelpers';
import { FONT_FAMILY_OPTIONS } from 'src/constants/fonts';
import { TEXT_ELEMENT } from 'src/constants/canvasElements';
import { useStatus, useProjectSelector } from 'src/hooks';
import { SUCCESS, NOT_STARTED, ERROR, LOADING } from 'src/constants/status';
import { GET_PROJECT } from 'src/actions/projectActions';
import {
  GET_USER_WITH_BRAND_LIBRARY,
  updateTextWithFontStyle,
  updateProjectWithFontStyle,
} from 'src/actions/brandLibraryActions';
import { GOOGLE_UPLOAD, LOCAL_UPLOAD, SYSTEM_UPLOAD } from 'src/constants/uploadFile';
import { APPLY_TEMPLATE_TO_PROJECT } from 'src/actions/productTemplateActions';

const useProjectFonts = () => {
  const dispatch = useDispatch();
  const { elements, groupsOfElementsById } = useProjectSelector();

  const { fonts, uploadedFonts } = useSelector(({ brandLibrary }) => ({
    fonts: brandLibrary.fonts,
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  const { status: projectStatus } = useStatus(GET_PROJECT);
  const { status: brandLibraryStatus } = useStatus(GET_USER_WITH_BRAND_LIBRARY);
  const { status: applyTemplateStatus } = useStatus(APPLY_TEMPLATE_TO_PROJECT);

  const [fontStatus, setFontStatus] = useState(NOT_STARTED);

  const getTextsOfProject = useCallback(() => {
    const texts = elements.filter(elem => elem.type === TEXT_ELEMENT);
    Object.entries(groupsOfElementsById).forEach(([, group]) => {
      group
        .forEach((elem) => {
          if (elem.type === TEXT_ELEMENT) {
            texts.push(elem);
          }
        });
    });

    return texts.map(elem => {
      const font = { ...elem };
      font.uploadType = elem.uploadType || GOOGLE_UPLOAD;
      return font;
    });
  }, [elements, groupsOfElementsById]);

  const updateElementRequest = useCallback((textId, attrs) => {
    const newAttrs = {
      uuid: textId,
      ...attrs,
    };
    dispatch(updateTextWithFontStyle(newAttrs));
  }, [dispatch]);

  const updateStyledTexts = useCallback((styledTexts = []) => {
    let isUpdated = false;
    const updatedStyledTexts = styledTexts.map(text => {
      const { brandLibraryStyleId, uuid } = text;
      const styleFound = fonts.find(font => font.id === brandLibraryStyleId);
      if (!styleFound) {
        updateElementRequest(uuid, { brandLibraryStyleId: undefined });
        isUpdated = true;
        return text;
      }
      let needsUpdate = false;
      if (text.align !== styleFound.align) {
        text.align = styleFound.align;
        needsUpdate = true;
      }
      if (text.color.hex !== styleFound.hex) {
        text.color.hex = styleFound.hex;
        needsUpdate = true;
      }
      if (text.color.alpha !== styleFound.alpha) {
        text.color.alpha = styleFound.alpha;
        needsUpdate = true;
      }
      if (text.fontSize !== styleFound.fontSize) {
        text.fontSize = styleFound.fontSize;
        needsUpdate = true;
      }
      if (text.fontStyle !== styleFound.fontStyle) {
        text.fontStyle = styleFound.fontStyle;
        needsUpdate = true;
      }
      if (text.lineHeight !== styleFound.lineHeight) {
        text.lineHeight = styleFound.lineHeight;
        needsUpdate = true;
      }
      if (text.letterSpacing !== styleFound.letterSpacing) {
        text.letterSpacing = styleFound.letterSpacing;
        needsUpdate = true;
      }
      if (text.fontFamily !== styleFound.fontFamily) {
        text.fontFamily = styleFound.fontFamily;
        text.uploadType = styleFound.uploadType;
        needsUpdate = true;
        if (text.uploadType === GOOGLE_UPLOAD) {
          text.fontFamilyUrl = '';
        } else if (text.uploadType === LOCAL_UPLOAD) {
          const customFont = uploadedFonts.find(uploaded => (
            uploaded.name === text.fontFamily
          ));
          text.fontFamilyUrl = customFont.url;
        } else if (text.uploadType === SYSTEM_UPLOAD) {
          const systemFont = FONT_FAMILY_OPTIONS.find(sysFont => (
            sysFont.value === text.fontFamily
          ));
          text.fontFamilyUrl = systemFont.font;
        }
      }
      if (needsUpdate) {
        updateElementRequest(uuid, text);
        isUpdated = true;
      }
      return text;
    });
    if (isUpdated) {
      dispatch(updateProjectWithFontStyle());
    }
    return updatedStyledTexts;
  }, [dispatch, fonts, updateElementRequest, uploadedFonts]);

  const loadFontFamilies = useCallback(() => {
    const texts = getTextsOfProject();
    if (texts.length) {
      let styledTexts = texts.filter(text => text.brandLibraryStyleId);
      styledTexts = updateStyledTexts(styledTexts);
      const notStyledTexts = texts.filter(text => !text.brandLibraryStyleId);
      const fontFamilies = styledTexts.concat(notStyledTexts);
      const webFontConfig = {};
      const googleFamilies = getGoogleFamilies(fontFamilies);
      if (googleFamilies.length) {
        webFontConfig.google = { families: googleFamilies };
      }
      let customFamilies = getCustomFamilies(fontFamilies);
      let systemFamilies = getSystemFamilies(fontFamilies);
      if (customFamilies.length || systemFamilies.length) {
        customFamilies = customFamilies.map(elem => ({
          name: elem.fontFamily,
          url: elem.fontFamilyUrl,
        }));
        systemFamilies = systemFamilies.map(elem => {
          const fontFamily = FONT_FAMILY_OPTIONS.find(option => option.value === elem.fontFamily);
          return {
            name: fontFamily.value,
            url: fontFamily.font,
          };
        });
        customFamilies = customFamilies.concat(systemFamilies);
        loadUploadedFontsToStylesheet(customFamilies);
        webFontConfig.custom = { families: customFamilies.map(elem => elem.name) };
      }
      WebFont.load({
        ...webFontConfig,
        active: () => {
          setFontStatus(SUCCESS);
        },
        inactive: () => {
          setFontStatus(SUCCESS);
          toast.error('Font wasn\'t able to load', toastErrorConfig);
        },
      });
    } else {
      setFontStatus(SUCCESS);
    }
  }, [getTextsOfProject, updateStyledTexts]);

  useEffect(() => {
    if (applyTemplateStatus === SUCCESS) {
      setFontStatus(LOADING);
      loadFontFamilies();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyTemplateStatus]);

  useEffect(() => {
    setFontStatus(LOADING);
    if (projectStatus === SUCCESS && brandLibraryStatus === SUCCESS) {
      loadFontFamilies();
    } else if (projectStatus === ERROR || brandLibraryStatus === ERROR) {
      setFontStatus(ERROR);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatus, brandLibraryStatus]);

  return { fontStatus };
};

export { useProjectFonts };
