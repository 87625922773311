import React from 'react';
import { string } from 'prop-types';

import styles from './PlanSection.module.scss';

const PlanSection = ({ plan, frequency }) => (
  <>
    <div className={styles.section}>
      <p className={styles.header}>Current Plan</p>
      <div className={styles.content}>
        <span className={styles.info}>{plan}</span>
      </div>
    </div>
    <div className={styles.section}>
      <p className={styles.header}>Payment Frequency</p>
      <div className={styles.content}>
        <span className={styles.info}>{frequency}</span>
      </div>
    </div>
  </>
);

PlanSection.propTypes = {
  plan: string,
  frequency: string,
};

export { PlanSection };
