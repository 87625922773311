import React, { useCallback } from 'react';
import { func, bool, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import {
  DELETE_COLOR_PALETTE,
  deleteColorPalette,
} from 'src/actions/brandLibraryActions';
import { useLoading } from 'src/hooks';
import styles from './DeleteColorPaletteModal.module.scss';

const DeleteColorPaletteModal = ({ isShowing, hide, id }) => {
  const dispatch = useDispatch();

  const { id: libraryId } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const deletePalette = useCallback(() => {
    dispatch(deleteColorPalette(libraryId, id));
  }, [dispatch, id, libraryId]);

  const loading = useLoading(DELETE_COLOR_PALETTE);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete the whole palette?
        </span>
        <span className={styles.subtitle}>
          If you delete the entire selected color palette, it will delete all of your colors
          inside of it – permanently. Are you sure you want to delete it?
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={deletePalette}
            loading={loading}
            alternativeCTA
          >
            Yes, delete the palette
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteColorPaletteModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  id: number.isRequired,
};

export { DeleteColorPaletteModal };
