import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPostData,
  getPostInsights,
  isStaggerPost,
  GET_POST_DATA,
} from 'src/actions/instagramAnalyticsActions';
import { ERROR, SUCCESS } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { PostedDate } from '../posted-date';
import { PostPreview } from '../post-preview';
import { FeaturedInfo } from '../featured-info';
import styles from './PostData.module.scss';

const PostData = () => {
  const dispatch = useDispatch();

  const { selectedPost, permalink } = useSelector(({ instagramAnalytics }) => ({
    selectedPost: instagramAnalytics.selectedPost,
    permalink: instagramAnalytics.permalink,
  }));

  useEffect(() => {
    dispatch(getPostData(selectedPost.id));
    dispatch(getPostInsights(selectedPost.id));
    dispatch(isStaggerPost(selectedPost.id));
  }, [dispatch, selectedPost]);

  const { status: getDataStatus } = useStatus(GET_POST_DATA);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.permalinkContainer}>
          <span className={styles.permalinkLabel}>Instagram URL</span>
          {getDataStatus === SUCCESS && (
            <span className={styles.permalink}>{permalink}</span>
          )}
          {getDataStatus === ERROR && (
            <span className={styles.permalink}>No url found</span>
          )}
        </div>
        <PostedDate />
      </div>
      <div className={styles.body}>
        <PostPreview />
        <FeaturedInfo />
      </div>
    </>
  );
};

export { PostData };
