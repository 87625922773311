import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CloseIcon from 'src/assets/icons/close.svg';
import { SearchTemplatesInput } from 'src/pages/templates/search-templates-input';
import { SearchTemplates } from 'src/pages/templates/search-templates';
import {
  CategoriesList,
} from 'src/pages/project/controls-options/templates-control/categories-list';
import { changeControlOpen } from 'src/actions/canvasActions';
import {
  SEE_ALL_SUGGESTIONS_CONTROL,
  SEE_ALL_TEMPLATES_CONTROL,
  TEMPLATES_CONTROL,
} from 'src/constants/canvasControls';
import { useStatus } from 'src/hooks';
import { SUCCESS } from 'src/constants/status';
import { GET_PROJECT } from 'src/actions/projectActions';
import { SeeAllTemplates } from './see-all-templates';
import { SeeAllSuggestions } from './see-all-suggestions';
import styles from './TemplatesControl.module.scss';

const TemplatesControl = () => {
  const dispatch = useDispatch();

  const { searchKeyword, searchTemplates, controlOpen } = useSelector(({ templates, canvas }) => ({
    searchKeyword: templates.searchKeyword,
    searchTemplates: templates.searchTemplates,
    controlOpen: canvas.controlOpen,
  }));

  const { status: statusGetProject } = useStatus(GET_PROJECT);

  const onClose = useCallback(() => {
    dispatch(changeControlOpen(''));
  }, [dispatch]);

  return (
    <div className={styles.content}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </button>
      {controlOpen === SEE_ALL_TEMPLATES_CONTROL && <SeeAllTemplates />}
      {controlOpen === SEE_ALL_SUGGESTIONS_CONTROL && <SeeAllSuggestions />}
      {controlOpen === TEMPLATES_CONTROL && statusGetProject === SUCCESS && (
        <>
          <div className={styles.header}>
            <span className={styles.title}>
              {!searchKeyword && !searchTemplates.length && 'Templates'}
              {searchTemplates.length ? `Results for "${searchKeyword}"` : ''}
            </span>
            <SearchTemplatesInput
              searchPrivateTemplates
              className={styles.searchContainer}
              inputClassName={styles.searchInput}
            />
          </div>
          {searchKeyword ? (
            <SearchTemplates
              searchPrivateTemplates
              templateCardClassName={styles.card}
            />
          ) : <CategoriesList />}
        </>
      )}
    </div>
  );
};

export { TemplatesControl };
