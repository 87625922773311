import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CloseIcon from 'src/assets/icons/close-white.svg';
import { DESIGN_COMPONENT_TYPE } from 'src/constants/general';
import { changeControlOpen, getObjectsCombinations } from 'src/actions/canvasActions';
import { AddShapeControl } from './add-shape-control';
import { ObjectCombinations } from './object-combinations';
import { SeeAllObjects } from './see-all-objetcs';
import styles from './AddObjectControl.module.scss';

const GENERAL_VIEW = 'general';
const SEE_ALL_TAPES = 'seeAllTapes';
const SEE_ALL_FRAMES = 'seeAllFrames';

const AddObjectControl = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getObjectsCombinations());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(changeControlOpen(''));
  }, [dispatch]);

  const [view, setView] = useState(GENERAL_VIEW);

  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </button>
      {view === GENERAL_VIEW && (
        <>
          <span className={styles.title}>Shapes</span>
          <AddShapeControl onClose={onClose} />
          <ObjectCombinations
            type={DESIGN_COMPONENT_TYPE.TAPE}
            title="Tape"
            onClickSeeAll={() => setView(SEE_ALL_TAPES)}
          />
          <ObjectCombinations
            type={DESIGN_COMPONENT_TYPE.FRAMES}
            title="Frames"
            onClickSeeAll={() => setView(SEE_ALL_FRAMES)}
          />
        </>
      )}
      {view === SEE_ALL_TAPES && (
        <SeeAllObjects
          type={DESIGN_COMPONENT_TYPE.TAPE}
          title="Tape"
          onClickBack={() => setView(GENERAL_VIEW)}
        />
      )}
      {view === SEE_ALL_FRAMES && (
        <SeeAllObjects
          type={DESIGN_COMPONENT_TYPE.FRAMES}
          title="Frames"
          onClickBack={() => setView(GENERAL_VIEW)}
        />
      )}
    </div>
  );
};

export { AddObjectControl };
