import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import chunk from 'lodash.chunk';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LOADING, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { Navbar } from 'src/common/navbar';
import { reset } from 'src/actions/statusActions';
import { useStatus, useWindowSize, useSession, usePagination } from 'src/hooks';
import { LARGE_WIDTH, SMALL_WIDTH } from 'src/constants/breakpoints';
import {
  PROJECT_TYPE,
  SHOWING_STATUS,
} from 'src/constants/general';
import { AGENCY_PLANS, SIGN_PLANS } from 'src/constants/memberships';
import { getUserWithProjects, GET_USER_WITH_PROJECTS } from 'src/actions/userActions';
import { getAccountProjects } from 'src/actions/accountActions';
import { ScrapperAlert } from 'src/common/scrapper-alert';
import { ProjectsRows } from './projects-rows';
import { NewProject } from './new-project';
import { Suggestions } from './suggestions';
import { EmptyProjects } from './empty-projects';
import { FirstSignModal } from './first-sign-modal';
import { Banner } from '../templates/banner';
import { WhatsNewModal } from './whats-new-modal';
import styles from './Projects.module.scss';

const Projects = () => {
  const dispatch = useDispatch();

  const { user, accountId, account, isSubscriptionActive, membershipPlan } = useSession();
  const { status: getUserStatus } = useStatus(GET_USER_WITH_PROJECTS);

  useEffect(() => {
    dispatch(getUserWithProjects());

    return () => {
      dispatch(reset(GET_USER_WITH_PROJECTS));
    };
  }, [dispatch]);

  const getProjectsRequest = useCallback(async page => {
    const perPage = 12;
    await dispatch(getAccountProjects(accountId, page, perPage));
  }, [dispatch, accountId]);

  const { nextPage } = usePagination(getProjectsRequest);

  const { windowSize: { width } } = useWindowSize();

  const userProjects = useMemo(() => {
    if (!user?.membership?.accounts) return [];
    const projects = account?.projects || [];
    const sortedProjects = projects.sort(
      (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
    );
    return sortedProjects;
  }, [user, account]);

  const title = useMemo(() => {
    if (!user?.membership?.accounts || !AGENCY_PLANS.includes(user.membership?.plan)) {
      return 'My Staggers';
    }
    return `${account?.brandName || ''} Staggers`;
  }, [user, account]);

  const amountRows = useMemo(() => {
    if (width > LARGE_WIDTH) {
      return 4;
    }
    if (width > SMALL_WIDTH) {
      return 3;
    }
    return 1;
  }, [width]);

  const rows = chunk(userProjects, amountRows);

  const showFirstSignModal = useMemo(() => {
    if (getUserStatus === SUCCESS && !user.settings.closedFirstSignModal && userProjects.length) {
      const createSignEnabled = isSubscriptionActive && SIGN_PLANS.includes(membershipPlan);
      const amountOfSigns = userProjects.filter(p => p.type === PROJECT_TYPE.SIGN);
      const amountOfFlyersWithAddress = userProjects.filter(p => p.type === PROJECT_TYPE.FLYER &&
        p.flyerSettings?.locationBasedSharing);
      if (createSignEnabled && !amountOfSigns.length && amountOfFlyersWithAddress.length === 1) {
        return true;
      }
      return false;
    }
    return false;
  }, [getUserStatus, isSubscriptionActive, membershipPlan, user, userProjects]);

  const userNeverSubscribed = user && !user.customerId && !user.membership?.subscription;

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.subnav}>
        <h1 className={styles.title}>{title}</h1>
        {getUserStatus === SUCCESS && <NewProject />}
      </div>
      {getUserStatus === SUCCESS && <ScrapperAlert />}
      <div className={styles.content}>
        <div className={styles.bannerContainer}><Banner /></div>
        {getUserStatus === SUCCESS && !userNeverSubscribed && <Suggestions />}
        {getUserStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {getUserStatus === SUCCESS && !userProjects.length && <EmptyProjects />}
        {getUserStatus === SUCCESS && (
          <InfiniteScroll
            dataLength={userProjects.length}
            next={nextPage}
            hasMore
            className={styles.infiniteScroll}
          >
            <ProjectsRows rows={rows} amountRows={amountRows} />
          </InfiniteScroll>
        )}
        {showFirstSignModal && <FirstSignModal />}
        {getUserStatus === SUCCESS && user?.settings?.showWhatsNewModal === SHOWING_STATUS.SHOW &&
          <WhatsNewModal />}
      </div>
    </div>
  );
};

export { Projects };
