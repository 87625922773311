import React, { useCallback, useEffect } from 'react';
import { func, bool, arrayOf, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { UPLOAD_FONTS, uploadFontsToBrandLibrary } from 'src/actions/brandLibraryActions';
import { useStatus } from 'src/hooks';
import { LOADING, SUCCESS } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import styles from './UploadFontPermission.module.scss';

const UploadFontPermission = ({ isShowing, hide, filesToSave }) => {
  const dispatch = useDispatch();

  const { id } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const uploadFonts = useCallback(() => {
    dispatch(uploadFontsToBrandLibrary(id, filesToSave));
  }, [dispatch, id, filesToSave]);

  const { status } = useStatus(UPLOAD_FONTS);

  useEffect(() => {
    if (status === SUCCESS) {
      hide();
      dispatch(reset(UPLOAD_FONTS));
    }
  }, [dispatch, hide, status]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Confirm font permission
        </span>
        <span className={styles.subtitle}>
          By uploading this font file, you acknowledge that you have
          rights to use this font in your projects.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={uploadFonts}
            loading={status === LOADING}
            alternativeCTA
          >
            I accept
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadFontPermission.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  filesToSave: arrayOf(object).isRequired,
};

export { UploadFontPermission };
