/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cn from 'classnames';
import { string, func, bool } from 'prop-types';

import styles from './CheckBox.module.scss';

const CheckBox = ({ onChange, isSelected, label, value, className }) => (
  <label className={cn(styles.checkbox, className)}>
    <label className={styles.checkboxInput}>
      <input
        type="checkbox"
        onChange={onChange}
        value={value}
        checked={isSelected}
        className={styles.checkboxInputDefault}
      />
      <span className={styles.checkboxControl}>
        <span className={styles.checkboxChecked} />
      </span>
    </label>
    {label && <span className={styles.checkboxLabel}>{label}</span>}
  </label>

);

CheckBox.propTypes = {
  onChange: func.isRequired,
  isSelected: bool.isRequired,
  label: string,
  value: string.isRequired,
  className: string,
};

export { CheckBox };
