import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { useStatus, useWindowSize } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { secsToHHMMSS } from 'src/utils/helpers';
import { getTimeSpentInFrames, GET_TIME_SPENT_IN_FRAMES } from 'src/actions/embedAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import styles from './GraphTimePerFrame.module.scss';

const GraphTimePerFrame = () => {
  const dispatch = useDispatch();

  const { windowSize: { width } } = useWindowSize();
  const isSmallScreen = useMemo(() => width <= SMALL_WIDTH, [width]);

  const {
    timeSpentInFrames,
    selectedProject,
    selectedTimeRange,
  } = useSelector(({ embedAnalytics }) => ({
    timeSpentInFrames: embedAnalytics.timeSpentInFrames,
    selectedProject: embedAnalytics.selectedProject,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
  }));

  useEffect(() => {
    const { from, to } = selectedTimeRange;
    dispatch(getTimeSpentInFrames(selectedProject.id, from, to));
  }, [dispatch, selectedProject, selectedTimeRange]);

  const { status } = useStatus(GET_TIME_SPENT_IN_FRAMES);

  return (
    <div className={styles.container}>
      <span className={styles.title}>Time Spent Per Frame</span>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {(status === SUCCESS || status === ERROR) && (
        <ResponsiveContainer
          width={isSmallScreen ? '100%' : '80%'}
          height={isSmallScreen ? '95%' : '90%'}
          debounce={300}
        >
          <BarChart
            data={timeSpentInFrames}
            margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="frame"
              tickFormatter={(v) => `Frame ${v}`}
              tick={timeSpentInFrames.length}
            />
            <YAxis
              tickFormatter={v => secsToHHMMSS(v)}
              type="number"
              allowDecimals={false}
            />
            <Tooltip
              formatter={(value, name) => ([secsToHHMMSS(value), name])}
              labelFormatter={(v) => `Frame ${v}`}
              cursor={{ fill: '#e6e6e6' }}
              contentStyle={{ padding: '2.4rem' }}
              itemStyle={{ marginTop: '1rem' }}
            />
            <Bar dataKey="avg" fill="#276df1" maxBarSize={16} radius={[16, 16, 16, 16]} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export { GraphTimePerFrame };
