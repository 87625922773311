import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CUSTOM_SOURCE } from 'src/constants/layout';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import { changeLayout } from 'src/actions/projectActions';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import styles from './CustomLayoutControlOption.module.scss';

const CustomLayoutControlOption = () => {
  const dispatch = useDispatch();

  const [dimesions, setDimensions] = useState({});

  const { layout } = useProjectSelector();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  useEffect(() => {
    setDimensions({ width: layout.width, height: layout.height });
  }, [layout]);

  const onKeyDown = useCallback(({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  }, []);

  const onChange = useCallback(({ target: { value } }, key) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value) || value < 0) {
      return;
    }
    setDimensions(d => ({ ...d, [key]: value }));
  }, []);

  const onBlur = useCallback(() => {
    if (!dimesions.width || !dimesions.height) {
      setDimensions({ width: layout.width, height: layout.height });
      return;
    }
    if (layout.width !== dimesions.width || layout.height !== dimesions.height) {
      dispatch(changeLayout({
        layout: {
          width: Number(dimesions.width),
          height: Number(dimesions.height),
          source: CUSTOM_SOURCE,
        },
      }));
    }
  }, [dimesions, dispatch, layout.height, layout.width]);

  return (
    <div className={styles.option}>
      <span className={styles.title}>Custom</span>
      <div className={styles.content}>
        <label htmlFor="width" className={styles.label}>
          Width
          <input
            name="width"
            value={dimesions.width || ''}
            onChange={(e) => onChange(e, 'width')}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            className={styles.input}
          />
        </label>
        <label htmlFor="height" className={styles.label}>
          Height
          <input
            name="height"
            value={dimesions.height || ''}
            onChange={(e) => onChange(e, 'height')}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            className={styles.input}
          />
        </label>
        {!isMobile && <span className={styles.pixels}>px</span>}
      </div>
    </div>
  );
};

export { CustomLayoutControlOption };
