import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool } from 'prop-types';
import { toast } from 'react-toastify';

import { Modal } from 'src/common/modal';
import { CheckBox } from 'src/common/check-box';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { AddressInput } from 'src/common/address-input';
import { ToggleSwitch } from 'src/common/toggle-switch';
import { useProjectSelector, useSession, useStatus } from 'src/hooks';
import { UPDATE_FLYER_SETTINGS, updateFlyerSettings } from 'src/actions/flyerActions';
import { toastSuccessConfig } from 'src/constants/toastConfig';
import { VISIBILITY } from 'src/constants/general';
import { SUCCESS, LOADING } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import { UPDATE_PROJECT_TO_EXPORT } from 'src/actions/projectActions';
import styles from './FlyerSettingsModal.module.scss';

const FlyerSettingsModal = ({ isShowing, onClose, exportProject }) => {
  const dispatch = useDispatch();
  const { status: statusUpdateFlyer } = useStatus(UPDATE_FLYER_SETTINGS);

  const { id, flyerSettings } = useProjectSelector();
  const { account } = useSession();

  const [isPublic, setIsPublic] = useState(flyerSettings.visibility === VISIBILITY.PUBLIC);

  const [
    locationBasedSharing,
    setLocationBasedSharing,
  ] = useState(flyerSettings.locationBasedSharing);

  const initialLocation = flyerSettings.googlePlaceId ? {
    lat: flyerSettings.location.coordinates[1],
    long: flyerSettings.location.coordinates[0],
    placeId: flyerSettings.googlePlaceId,
    description: flyerSettings.addressDescription,
  } : undefined;
  const [location, setLocation] = useState(initialLocation);

  const [locationError, setLocationError] = useState('');

  const validateLocation = useCallback((paramLocation) => {
    if (locationBasedSharing && !paramLocation) {
      setLocationError('For location-based sharing, you must enter a valid address');
      return false;
    }
    return true;
  }, [locationBasedSharing]);

  const {
    status: statusExport,
    statusInfo: statusInfoExport,
  } = useStatus(UPDATE_PROJECT_TO_EXPORT);

  const saveSettings = () => {
    const isValidLocation = validateLocation(location);
    if (isValidLocation) {
      const callbackFn = () => {
        const newSettings = {
          visibility: isPublic ? VISIBILITY.PUBLIC : VISIBILITY.PRIVATE,
          locationBasedSharing,
        };
        if (locationBasedSharing) {
          newSettings.location = {
            type: 'Point',
            coordinates: [location.long, location.lat],
          };
          newSettings.googlePlaceId = location.placeId;
          newSettings.addressDescription = location.description;
        } else {
          newSettings.location = null;
          newSettings.googlePlaceId = null;
          newSettings.addressDescription = null;
        }
        dispatch(updateFlyerSettings(id, newSettings));
      };

      exportProject(callbackFn, false);
    }
  };

  useEffect(() => {
    if (statusExport === SUCCESS && typeof statusInfoExport.callback === 'function') {
      statusInfoExport.callback();
    }
  }, [
    dispatch,
    exportProject,
    id,
    isPublic,
    location,
    locationBasedSharing,
    statusExport,
    statusInfoExport,
    validateLocation,
  ]);

  useEffect(() => {
    if (statusUpdateFlyer === SUCCESS) {
      onClose();
      dispatch(reset(UPDATE_FLYER_SETTINGS));
    }
  }, [dispatch, onClose, statusInfoExport, statusUpdateFlyer]);

  const onCopyLinkClick = useCallback(async () => {
    const projectUrl = `${account.publicUrl}?pjid=${id}`;
    await window.navigator.clipboard.writeText(projectUrl);
    toast.success('Project link copied and ready to paste!', toastSuccessConfig);
  }, [account, id]);

  return (
    <Modal isShowing={isShowing} hide={onClose} curvedContainer>
      <div className={styles.content}>
        <span className={styles.title}>Flyer Settings</span>
        <div className={styles.form}>
          <div className={styles.locationSwitch}>
            <span className={styles.textSwitch}>Create location-based sharing</span>
            <ToggleSwitch
              checked={locationBasedSharing}
              onChange={() => setLocationBasedSharing(lb => !lb)}
            />
          </div>
          <AddressInput
            result={location}
            setResult={setLocation}
            error={locationError}
            setError={setLocationError}
          />
          {account.publicUrl && (
            <div className={styles.publicUrlContainer}>
              <Input
                label="Shareable link"
                disabled
                value={`${account.publicUrl}?pjid=${id}`}
                name="publicUrl"
                onChange={() => {}}
                containerClassName={styles.publicUrlInput}
              />
              <Button
                onClick={onCopyLinkClick}
                disabled={!isPublic}
                className={styles.copyLink}
                secondary
              >
                Copy Link
              </Button>
            </div>
          )}
          <CheckBox
            onChange={() => setIsPublic(p => !p)}
            isSelected={isPublic}
            label="Make public"
            value=""
          />
          <div className={styles.submitContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              disabled={statusExport === LOADING}
              secondary
            >
              Cancel
            </Button>
            <Button
              onClick={saveSettings}
              className={styles.button}
              loading={statusExport === LOADING || statusUpdateFlyer === LOADING}
              alternativeCTA
            >
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

FlyerSettingsModal.propTypes = {
  isShowing: bool.isRequired,
  onClose: func.isRequired,
  exportProject: func.isRequired,
};

export { FlyerSettingsModal };
