export const getDirtyFields = (initialValues, values) => {
  const dirtyFields =
    Object.keys(initialValues).filter((key) => initialValues[key] !== values[key]);
  return dirtyFields;
};

export const checkForChanges = (initialValues, values) => {
  const dirtyFields = getDirtyFields(initialValues, values);
  return dirtyFields.length > 0;
};
