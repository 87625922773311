import React, { useMemo, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { subDays, isSameDay, parseISO } from 'date-fns';

import { CollapsibleOptions } from 'src/common/collapsible-options';
import { setSelectedTimeRange } from 'src/actions/embedAnalyticsActions';
import { getFormattedDateRangeForAnalytic } from 'src/utils/helpers';
import { useClickOutside } from 'src/hooks';
import ArrowUp from 'src/assets/icons/arrow-up.svg';
import CalendarIcon from 'src/assets/icons/calendar.svg';
import { DatePickerRange } from 'src/common/date-picker-range';
import styles from './SelectTimeRange.module.scss';

const SelectTimeRange = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef();

  const { selectedTimeRange, disabled } = useSelector(({ embedAnalytics }) => ({
    selectedTimeRange: embedAnalytics.selectedTimeRange,
    disabled: embedAnalytics.disabled,
  }));

  const [showCustom, setShowCustom] = useState(false);
  const [selectedTimeRangeIsCustom, setSelectedTimeRangeIsCustom] = useState(false);

  const onChange = useCallback(({ from, to }) => {
    dispatch(setSelectedTimeRange({ from, to }));
    setSelectedTimeRangeIsCustom(false);
  }, [dispatch]);

  const getLastDays = (days) => {
    const from = subDays(new Date(), days);
    const to = new Date();
    return getFormattedDateRangeForAnalytic(from, to);
  };

  const getOptionValue = ({ from, to }) => (
    `${from},${to}`
  );

  const options = useMemo(() => ([{
    text: 'Today',
    value: getOptionValue(getLastDays(0)),
    action: () => onChange(getLastDays(0)),
  },
  {
    text: 'Last 7 days',
    value: getOptionValue(getLastDays(7)),
    action: () => onChange(getLastDays(7)),
  },
  {
    text: 'Last 30 days',
    value: getOptionValue(getLastDays(30)),
    action: () => onChange(getLastDays(30)),
  },
  {
    text: 'Last 60 days',
    value: getOptionValue(getLastDays(60)),
    action: () => onChange(getLastDays(60)),
  },
  {
    text: 'Last 90 days',
    value: getOptionValue(getLastDays(90)),
    action: () => onChange(getLastDays(90)),
  },
  {
    text: 'Custom',
    value: 'custom',
    action: () => setShowCustom(c => !c),
    className: styles.customOption,
    closeOnClickOption: false,
  }]), [onChange]);

  const onSelectCustomRange = (range) => {
    setShowCustom(false);
    setSelectedTimeRangeIsCustom(true);
    buttonRef.current.click();
    const { from, to } = getFormattedDateRangeForAnalytic(range.from, range.to);
    dispatch(setSelectedTimeRange({ from, to }));
  };

  const displayText = useMemo(() => {
    if (!selectedTimeRange) {
      return '';
    }
    if (selectedTimeRangeIsCustom) {
      return 'Custom';
    }
    const option = options.find(elem => {
      const splittedValue = elem.value.split(',');
      const from = splittedValue[0];
      const to = splittedValue[1];
      return isSameDay(parseISO(to), parseISO(selectedTimeRange.to)) &&
        isSameDay(parseISO(from), parseISO(selectedTimeRange.from));
    });
    return option?.text || '';
  }, [options, selectedTimeRange, selectedTimeRangeIsCustom]);

  const selectedValue = useMemo(() => {
    if (!selectedTimeRange) {
      return '';
    }
    if (selectedTimeRangeIsCustom) {
      return 'custom';
    }
    return getOptionValue(selectedTimeRange);
  }, [selectedTimeRange, selectedTimeRangeIsCustom]);

  const ref = useClickOutside(() => setShowCustom(false), showCustom);

  return (
    <div className={styles.date}>
      <CollapsibleOptions
        options={options}
        optionsClassName={styles.options}
        buttonClassName={styles.collapsibleButton}
        optionClassName={styles.option}
        iconOpened={ArrowUp}
        closeOnClickOption={!showCustom}
        disabled={disabled}
        selectedValue={selectedValue}
        closeOnClickOutside={!showCustom}
        ref={buttonRef}
      >
        <img alt="calendar" src={CalendarIcon} />
        <span className={styles.text}>{displayText}</span>
      </CollapsibleOptions>
      {showCustom && (
        <div className={styles.datePicker} ref={ref}>
          <DatePickerRange
            onSelectRange={onSelectCustomRange}
            initialStartDate={selectedTimeRangeIsCustom ?
              new Date(`${selectedTimeRange.from} 00:00`) : undefined}
            initialEndDate={selectedTimeRangeIsCustom ?
              new Date(`${selectedTimeRange.to} 00:00`) : undefined}
          />
        </div>
      )}
    </div>
  );
};

export { SelectTimeRange };
