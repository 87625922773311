import React, { useState, useEffect, useCallback } from 'react';
import { number, string, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useLoading, useTooltip, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import {
  ADD_ASSETS_TO_BRAND_LIBRARY,
  showAssetDetails,
  SHOW_ASSET_DETAILS,
} from 'src/actions/brandLibraryActions';
import { snapImageOfVideo, isVideo } from 'src/utils/videoHelpers';
import CloseIcon from 'src/assets/icons/close-white.svg';
import {
  truncateFilename,
  getAssetFilenameFromUrl,
} from 'src/utils/helpers';
import {
  optimizeCloudinaryThumbnail,
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import { Tooltip } from 'src/common/tooltip';
import { DeleteAssetModal } from '../delete-asset-modal';
import styles from './Asset.module.scss';

const MAX_FILENAME_LENGTH = 10;

const Asset = ({ asset, assetCategoryId }) => {
  const dispatch = useDispatch();

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const [thumbnail, setThumbnail] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH && !showDelete) {
      setShowDelete(true);
    }
  }, [showDelete, windowSize.width]);

  const addAssetLoading = useLoading(ADD_ASSETS_TO_BRAND_LIBRARY);
  const getAssetDetailsLoading = useLoading(SHOW_ASSET_DETAILS);

  useEffect(() => {
    let mounted = true;
    if (isVideo(asset.url)) {
      snapImageOfVideo(asset.url, (thumbnailSrc) => {
        mounted && setThumbnail(thumbnailSrc);
      });
    } else {
      const imageThumbnail = optimizeCloudinaryThumbnail(asset.url);
      setThumbnail(imageThumbnail);
    }
    return () => { mounted = false; };
  }, [asset]);

  let filename = getAssetFilenameFromUrl(asset.url);
  if (!isVideo(asset.url)) {
    filename = getCloudinaryFilenameWithoutTimestamp(filename);
  }

  const followMouse = true;
  const { ref, showTooltip, setShowTooltip, topLeftPosition } = useTooltip(followMouse);

  const showDetails = useCallback(() => {
    dispatch(showAssetDetails({
      ...asset,
      filename,
    }));
  }, [dispatch, asset, filename]);

  const onMouseLeave = () => {
    if (showDelete) {
      setShowDelete(false);
      setShowTooltip(false);
    }
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <button
        className={styles.assetButton}
        onClick={showDetails}
        disabled={getAssetDetailsLoading}
      >
        <img
          src={thumbnail}
          alt=""
          className={styles.image}
          disabled={addAssetLoading}
        />
      </button>
      <span className={styles.name}>
        {truncateFilename(filename, MAX_FILENAME_LENGTH)}
      </span>
      {showDelete && assetCategoryId && (
        <button
          className={styles.delete}
          onClick={() => setModalOpen(true)}
          disabled={addAssetLoading || disabled}
        >
          <img src={CloseIcon} alt="Delete" />
        </button>
      )}
      {modalOpen && (
        <DeleteAssetModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          assetCategoryId={assetCategoryId}
          assetId={asset.id}
        />
      )}
      {showTooltip && (
        <Tooltip followMouse topLeftPosition={topLeftPosition}>
          {filename}
        </Tooltip>
      )}
    </div>
  );
};

Asset.propTypes = {
  asset: shape({
    id: number,
    url: string,
  }),
  assetCategoryId: number,
};

export { Asset };
