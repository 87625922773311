import React from 'react';

import Heart from './heart.svg';
import styles from './Step0Welcome.module.scss';

const Step0Welcome = () => (
  <div>
    <div className={styles.circle}>
      <img src={Heart} alt="" className={styles.heart} />
    </div>
    <div className={styles.welcomeMsg}>
      Welcome to Stagger!
    </div>
    <p className={styles.message}>
      Tell us about your business to help us tailor data insights on
      your storytelling to help you refine and tell the right story.
    </p>
  </div>
);

export { Step0Welcome };
