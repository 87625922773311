import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactSlider from 'react-slick';
import { arrayOf, node, bool } from 'prop-types';

import { RIGHT, LEFT } from 'src/constants/general';
import { SliderArrow } from './arrow';
import styles from './Slider.module.scss';

const Slider = ({
  slides,
  infinite = false,
  dots = true,
  showDisabledArrow = true,
  adaptiveHeight = false,
}) => (
  slides.length > 1 ? (
    <ReactSlider
      slidesToShow={1}
      slidesToScroll={1}
      className={styles.slider}
      nextArrow={<SliderArrow direction={RIGHT} showDisabled={showDisabledArrow} />}
      prevArrow={<SliderArrow direction={LEFT} showDisabled={showDisabledArrow} />}
      dots={dots}
      infinite={infinite}
      adaptiveHeight={adaptiveHeight}
    >
      {slides}
    </ReactSlider>
  ) : (
    <div className={styles.singleSlide}>
      {slides[0]}
    </div>
  )
);

Slider.propTypes = {
  slides: arrayOf(node).isRequired,
  infinite: bool,
  dots: bool,
  showDisabledArrow: bool,
  adaptiveHeight: bool,
};

export { Slider };
