import React, { useCallback, forwardRef, useEffect } from 'react';
import { Circle, Group, Rect } from 'react-konva';
import { object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_ELEMENT_MENU,
  CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
  CANVAS_SCROLL_ID,
} from 'src/constants/general';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import {
  drawCanvasElementoptionsButton,
  getRightOptionsPosition,
  setCursorToDefault,
  setCursorToPointer,
} from 'src/utils/canvasHelpers';
import { openRightClickOptions } from 'src/actions/canvasActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';

const CIRCLE_RADIUS = 5;
const CIRCLE_COLOR = 'white';
const RECT_WIDTH = 100;

const CanvasElementOptionsButton = forwardRef(({ refTransform }, refMenu) => {
  const dispatch = useDispatch();

  const { eyeDropper, scale } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
    scale: canvas.scale,
  }));

  const { elements, selectedElements, editableImage, editableText } = useProjectSelector();

  const onClick = useCallback((e) => {
    const canvasElem = document.getElementById(CANVAS_SCROLL_ID);
    const canvasPos = canvasElem.getBoundingClientRect();
    const elemPos = e.target.getParent().getAbsolutePosition();
    const leftMargin = 4;
    const fakeEvent = {
      clientX: canvasPos.x + elemPos.x + (RECT_WIDTH + leftMargin) * scale,
      clientY: canvasPos.y + elemPos.y,
    };
    const pos = getRightOptionsPosition(
      fakeEvent,
      CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT,
      CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_WIDTH,
    );
    dispatch(openRightClickOptions({
      ...pos,
      type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT_FLOATING_MOBILE,
    }));
  }, [dispatch, scale]);

  useEffect(() => {
    drawCanvasElementoptionsButton(refMenu, refTransform);
  }, [elements, refMenu, refTransform]);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  if (!Object.keys(selectedElements).length || editableImage || editableText || !isMobile) {
    return null;
  }

  return (
    <Group
      ref={refMenu}
      onClick={onClick}
      onTap={onClick}
      id={CANVAS_ELEMENT_MENU}
      listening={!eyeDropper?.active}
      onMouseEnter={setCursorToPointer}
      onMouseLeave={setCursorToDefault}
    >
      <Rect
        fill="#313437"
        cornerRadius={10}
        width={RECT_WIDTH}
        height={100}
      />
      <Circle
        radius={CIRCLE_RADIUS}
        x={RECT_WIDTH / 2}
        y={30}
        fill={CIRCLE_COLOR}
      />
      <Circle
        radius={CIRCLE_RADIUS}
        x={RECT_WIDTH / 2}
        y={50}
        fill={CIRCLE_COLOR}
      />
      <Circle
        radius={CIRCLE_RADIUS}
        x={RECT_WIDTH / 2}
        y={70}
        fill={CIRCLE_COLOR}
      />
    </Group>
  );
});

CanvasElementOptionsButton.propTypes = {
  refTransform: object,
};

export { CanvasElementOptionsButton };
