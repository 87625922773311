import React, { useMemo, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { useWindowSize, useSession, useStatus } from 'src/hooks';
import {
  LeftImg,
  LeftImg2x,
  LeftImg3x,
  RightImg,
  RightImg2x,
  RightImg3x,
} from 'src/assets/images/background';
import Logotype from 'src/assets/icons/logotype.svg';
import { LANDING_PAGE_LINK } from 'src/constants/general';
import { LARGE_WIDTH } from 'src/constants/breakpoints';
import { routesPaths } from 'src/routes/routesPaths';
import { SocialButtons } from 'src/common/social-buttons';
import { Footer } from 'src/common/footer';
import { CHECK_INVITE_NEEDS_NEW_USER } from 'src/actions/accountInviteActions';
import { SUCCESS } from 'src/constants/status';
import { SIGNUP } from 'src/actions/userActions';
import { trackPageView } from 'src/utils/analytics';
import { SignupForm } from './form';
import styles from './Signup.module.scss';

const Signup = () => {
  const { authenticated } = useSession();
  const { windowSize: { width } } = useWindowSize();
  const isLargeScreen = useMemo(() => width > LARGE_WIDTH, [width]);
  const [inviteToken, setInviteToken] = useState('');
  const [newUserInfo, setNewUserInfo] = useState(null);
  const history = useHistory();

  const {
    status: checkInviteNeedsNewUserStatus,
    statusInfo: checkInviteNeedsNewUserStatusInfo,
  } = useStatus(CHECK_INVITE_NEEDS_NEW_USER);

  const { status: signupStatus } = useStatus(SIGNUP);

  useEffect(() => {
    if (
      checkInviteNeedsNewUserStatus === SUCCESS
    ) {
      setInviteToken(checkInviteNeedsNewUserStatusInfo.inviteToken);
      setNewUserInfo(checkInviteNeedsNewUserStatusInfo.newUserInfo);
    }
  }, [checkInviteNeedsNewUserStatus, checkInviteNeedsNewUserStatusInfo]);

  useEffect(() => {
    if (signupStatus === SUCCESS && inviteToken) {
      history.push(`/account-invite/${inviteToken}`);
    }
  }, [signupStatus, inviteToken, history]);

  useEffect(() => {
    trackPageView('/signup');
  }, []);

  if (authenticated && !inviteToken) {
    return <Redirect to={routesPaths.projects} />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <a href={LANDING_PAGE_LINK}>
          <img src={Logotype} alt="Stagger logotype" className={styles.logotype} />
        </a>
        <div className={styles.container}>
          {isLargeScreen && (
            <img
              src={LeftImg}
              alt=""
              srcSet={`${LeftImg2x} 2x, ${LeftImg3x} 3x`}
              className={styles.image}
            />
          )}
          <div className={styles.content}>
            <div className={styles.form}>
              <h1>Create Your Stagger Account</h1>
              <SocialButtons
                googleText="Sign up with Google"
                facebookText="Sign up with Facebook"
              />
              <SignupForm newUserInfo={newUserInfo} />
            </div>
            <div className={styles.signinContainer}>
              <span>Already have an account?</span>
              <Link to={routesPaths.signin} className={styles.link}>Sign in</Link>
            </div>
          </div>
          {isLargeScreen && (
            <img
              src={RightImg}
              alt=""
              srcSet={`${RightImg2x} 2x, ${RightImg3x} 3x`}
              className={styles.image}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Signup };
