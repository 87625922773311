import { HttpClient, ShareClient } from 'src/clients';

class AccountService {
  static processWebsite(accountId) {
    return HttpClient.post(`/accounts/${accountId}/process-website`);
  }

  static reProcessWebsite(accountId) {
    return HttpClient.post(`/accounts/${accountId}/re-process-website`);
  }

  static fillBrandLibraryFromWebsite(accountId, amountOfRequests) {
    return HttpClient.post(`/accounts/${accountId}/fill-brand-library-from-website`, {
      amountOfRequests,
    });
  }

  static getAccountProjects(accountId, page, perPage) {
    return HttpClient.get(`/accounts/${accountId}/projects`, { params: { page, perPage } });
  }

  static getAccountSuggestions(accountId, page, perPage) {
    return HttpClient.get(`/accounts/${accountId}/suggestions`, { params: { page, perPage } });
  }

  static addAccount(account) {
    return HttpClient.post('/accounts', { account });
  }

  static updateAccount(accountId, account) {
    return HttpClient.put(`/accounts/${accountId}`, { account });
  }

  static moveProjects(from, to) {
    return HttpClient.post('/accounts/moveProjects', {}, { params: { from, to } });
  }

  static deleteAccount(accountId) {
    return HttpClient.delete(`/accounts/${accountId}`);
  }

  static getAccountsEditors(accountIds) {
    return HttpClient.get('/accounts/accounts-editors', {
      params: { accountIds: JSON.stringify(accountIds) },
    });
  }

  static findByNames(username, brandName) {
    if (brandName) {
      return ShareClient.get(`/accounts/find-by-names/${username}/${brandName}`);
    }

    return ShareClient.get(`/accounts/find-by-names/${username}`);
  }

  static findByIds(userId, accountId) {
    return ShareClient.get(`/accounts/find-by-ids/${userId}/${accountId}`);
  }

  static getByEmailAsAdmin(email) {
    return HttpClient.get(`/accounts/admin/find-by-email/${email}`);
  }
}

export { AccountService };
