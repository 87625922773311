import React, { memo, forwardRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import cn from 'classnames';
import { string, func, arrayOf, shape, number, object, bool, oneOfType } from 'prop-types';

import ArrowUp from 'src/assets/icons/arrow-up.svg';
import { SELECT_THEME, SELECT_STYLES } from 'src/constants/general';
import { ArrowSelect } from 'src/common/select/arrow-select';
import styles from './SelectCreator.module.scss';

const SelectCreator = memo(forwardRef(({
  label,
  name,
  onChange,
  options,
  isMulti = false,
  placeholder = 'Select',
  containerClassName = '',
  value,
  disabled = false,
  selectStyles = SELECT_STYLES,
  formatCreateLabel = v => `Create ${v}`,
  error = '',
  touched = false,
  className = '',
  acceptNewOption = true,
  icon = ArrowUp,
}, ref) => {
  const hasError = error && touched;
  return (
    <div className={cn(styles.container, containerClassName)}>
      {label && <label htmlFor={name} className={styles.selectLabel}>{label}</label>}
      <div className={styles.select}>
        <CreatableSelect
          name={name}
          isMulti={isMulti}
          onChange={onChange}
          options={options}
          styles={selectStyles}
          theme={SELECT_THEME}
          components={{
            IndicatorSeparator: () => null,
            IndicatorsContainer: (props) => <ArrowSelect {...props} icon={icon} />,
          }}
          isValidNewOption={() => acceptNewOption}
          placeholder={placeholder}
          value={value}
          isDisabled={disabled}
          formatCreateLabel={formatCreateLabel}
          ref={ref}
          className={cn(className, { [styles.selectError]: hasError })}
        />
      </div>
      {hasError && <span className={styles.error}>{error}</span>}
    </div>
  );
}));

const valueType = shape({
  value: oneOfType([object, number]),
  label: string,
});

SelectCreator.propTypes = {
  label: string,
  name: string,
  placeholder: string,
  onChange: func.isRequired,
  options: arrayOf(valueType).isRequired,
  isMulti: bool,
  containerClassName: string,
  value: oneOfType([object, arrayOf(object), string]),
  disabled: bool,
  selectStyles: object,
  formatCreateLabel: func,
  error: string,
  touched: bool,
  className: string,
  acceptNewOption: bool,
  icon: string,
};

export { SelectCreator };
