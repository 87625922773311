import React from 'react';
import { bool, func } from 'prop-types';

import { useProjectSelector } from 'src/hooks';
import { Button } from 'src/common/button';
import { VISIBILITY } from 'src/constants/general';
import styles from './PublishFlyerButton.module.scss';

const PublishFlyerButton = ({ disabled, exportWithToast, openFlyerSettingsModal }) => {
  const { flyerSettings } = useProjectSelector();

  const onClick = () => {
    const isSaved = flyerSettings.locationBasedSharing ||
      flyerSettings.visibility === VISIBILITY.PUBLIC;
    if (isSaved) {
      exportWithToast();
    } else {
      openFlyerSettingsModal();
    }
  };

  return (
    <>
      <Button
        onClick={onClick}
        disabled={disabled}
        className={styles.button}
        alternativeCTA
        short
      >
        Publish
      </Button>
    </>
  );
};

PublishFlyerButton.propTypes = {
  disabled: bool.isRequired,
  exportWithToast: func.isRequired,
  openFlyerSettingsModal: func.isRequired,
};

export { PublishFlyerButton };
