import { arrayOf, bool, func, number, shape } from 'prop-types';
import React, { useRef } from 'react';

import { ColorStop } from '../color-stop';
import styles from './ColorStopsHolder.module.scss';

const ColorStopsHolder = ({
  width,
  stops,
  disabled = false,
  onAddColor,
  limits,
  onPosChange,
  onDeleteColor,
  onDragStart,
  onDragEnd,
}) => {
  const handleColorAdd = (e) => {
    e.preventDefault();
    if (e.button) {
      return;
    }
    const offset = e.clientX - e.target.getBoundingClientRect().left;
    onAddColor({ offset });
  };

  const ref = useRef();

  return (
    <button
      className={styles.container}
      style={{ cursor: disabled ? 'default' : 'crosshair', width }}
      onMouseDown={handleColorAdd}
      ref={ref}
    >
      {stops.map(stop => (
        <ColorStop
          key={stop.id}
          stop={stop}
          limits={limits}
          onPosChange={onPosChange}
          onDeleteColor={onDeleteColor}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          handlerRef={ref}
        />
      ))}
    </button>
  );
};

ColorStopsHolder.propTypes = {
  width: number,
  stops: arrayOf(shape({
    id: number,
    offset: number,
    isActive: bool,
  })),
  disabled: bool,
  onAddColor: func,
  limits: shape({
    min: number,
    max: number,
    drop: number,
  }),
  onPosChange: func,
  onDeleteColor: func,
  onDragStart: func,
  onDragEnd: func,
};

export { ColorStopsHolder };
