import React, { useState } from 'react';
import { CheckBox } from 'src/common/check-box';

import { useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { DeleteAccounts } from './delete-accounts';
import styles from './QuantityDowngrade.module.scss';

const QuantityDowngrade = () => {
  const { user } = useSession();
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const onClickCheckbox = ({ target }) => {
    if (selected.includes(target.value)) {
      const filteredSelected = selected.filter(item => item !== target.value);
      setSelected(filteredSelected);
    } else {
      setSelected([...selected, target.value]);
    }
  };

  const onClickDelete = () => {
    setModalOpen(true);
  };

  const amountToDelete = user.membership.accounts.length - user.membership.subscription.quantity;

  const isDisabled = amountToDelete > selected.length;

  return (
    <>
      <span className={styles.title}>
        Which {amountToDelete > 1 ? `${amountToDelete} ` : ''}
        account{amountToDelete > 1 ? 's' : ''} would you like to delete?
      </span>
      <div className={styles.content}>
        <span className={styles.header}>Accounts ({user.membership.accounts.length})</span>
        {user.membership.accounts.map(account => (
          <div className={styles.card} key={account.id}>
            <CheckBox
              onChange={onClickCheckbox}
              isSelected={selected.includes(account.id.toString())}
              label={account.brandName}
              value={account.id.toString()}
              className={styles.checkbox}
            />
          </div>
        ))}
        <Button
          className={styles.delete}
          onClick={onClickDelete}
          disabled={isDisabled}
          alternativeCTA
        >
          Delete
        </Button>
        {modalOpen && (
          <DeleteAccounts
            isShowing={modalOpen}
            hide={() => setModalOpen(false)}
            ids={selected.map(s => +s)}
          />
        )}
      </div>
    </>
  );
};

export { QuantityDowngrade };
