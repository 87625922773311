import React, { memo, forwardRef, useState, useCallback } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { Spinner } from 'src/common/spinner';
import { Input } from 'src/common/input';
import SearchIcon from 'src/assets/icons/search.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import styles from './InputSearch.module.scss';

const InputSearch = memo(forwardRef(({
  placeholder = '',
  containerClassName = '',
  inputClassName = '',
  isLoading = false,
  searchAction,
  clearAllAction,
  enableClearAll = false,
  buttonId,
}, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inSearch, setInSearch] = useState(false);

  const search = useCallback((term) => {
    if (term) {
      searchAction(term);
      setInSearch(term.length > 0);
    }
  }, [searchAction]);

  const clearAll = useCallback(() => {
    setSearchTerm('');
    setInSearch(false);
    clearAllAction();
  }, [clearAllAction]);

  const doAction = useCallback(() => {
    inSearch && enableClearAll ? clearAll() : search(searchTerm);
  }, [inSearch, enableClearAll, clearAll, search, searchTerm]);

  const onKeyDown = useCallback(({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      if (searchTerm) {
        search(searchTerm);
      } else {
        clearAll();
      }
      target.blur();
    }
  }, [search, searchTerm, clearAll]);

  return (
    <div className={cn(styles.container, containerClassName)}>
      <Input
        name="search"
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        containerClassName={cn(styles.input, inputClassName)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        sufix={(
          <button
            onClick={doAction}
            className={styles.button}
            disabled={isLoading}
            id={buttonId}
          >
            {isLoading ? (
              <Spinner
                iconClassName={styles.spinnerIcon}
                containerClassName={styles.spinnerContainer}
              />
            ) : (
              <img
                src={inSearch && enableClearAll ? CloseIcon : SearchIcon}
                alt="Search action"
                className={styles.icon}
              />
            )}
          </button>
          )}
      />
    </div>
  );
}));

InputSearch.propTypes = {
  searchAction: func.isRequired,
  clearAllAction: func,
  placeholder: string,
  containerClassName: string,
  inputClassName: string,
  isLoading: bool,
  enableClearAll: bool,
  buttonId: string,
};

export { InputSearch };
