import React from 'react';
import { func, number } from 'prop-types';

import { useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { RadioButton } from 'src/common/radio-button';
import styles from './MainAccountChooser.module.scss';

const MainAccountChooser = ({ onClickNext, mainAccountId, setMainAccountId }) => {
  const { user } = useSession();

  return (
    <>
      <span className={styles.title}>Let&apos;s start to downgrade your plan.</span>
      <div className={styles.content}>
        <span className={styles.header}>Choose an account to be your main account.</span>
        {user.membership.accounts.map(account => (
          <div className={styles.card} key={account.id}>
            <RadioButton
              onChange={() => setMainAccountId(account.id)}
              isSelected={mainAccountId === account.id}
              label={account.brandName}
              value={account.id.toString()}
              className={styles.radiobutton}
            />
          </div>
        ))}
        <Button
          className={styles.next}
          onClick={onClickNext}
          disabled={!mainAccountId}
          alternativeCTA
        >
          Next
        </Button>
      </div>
    </>
  );
};

MainAccountChooser.propTypes = {
  mainAccountId: number,
  onClickNext: func.isRequired,
  setMainAccountId: func.isRequired,
};

export { MainAccountChooser };
