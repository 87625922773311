import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { string, number, object, oneOf } from 'prop-types';
import { formatDistanceToNow, parseISO } from 'date-fns';

import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import {
  routeWithProps,
} from 'src/utils/helpers';
import { optimizeCloudinaryBigThumbnail } from 'src/utils/cloudinaryHelpers';
import { useSession, useWindowSize } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { duplicateProject } from 'src/actions/projectActions';
import MenuIcon from 'src/assets/icons/menu.svg';
import FlyerIcon from 'src/assets/icons/flyer-icon.svg';
import { PROJECT_TYPE, ROLE_ADMIN } from 'src/constants/general';
import { DeleteProjectModal } from './delete-project-modal';
import { TransferProjectModal } from './transfer-project-modal';
import styles from './ProjectCard.module.scss';

const DELETE_MODAL = 'deleteModal';
const TRANSFER_MODAL = 'transferModal';

const ProjectCard = ({
  name,
  id,
  lastUpdated,
  thumbnail,
  template,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { windowSize: { width } } = useWindowSize();

  const { user } = useSession();

  const [modalOpen, setModalOpen] = useState();
  const [showOptions, setShowOptions] = useState(false);

  const goToProject = useCallback(() => (
    history.push(routeWithProps(routesPaths.project, { id }))
  ), [history, id]);

  const duplicateProjectRequest = useCallback(() => (
    dispatch(duplicateProject(id))
  ), [dispatch, id]);

  const projectOptions = useMemo(() => {
    const options = [
      { text: 'Delete', action: () => setModalOpen(DELETE_MODAL) },
      { text: 'Duplicate', action: duplicateProjectRequest },
    ];
    const isAdmin = ROLE_ADMIN === user.role?.id;
    if (isAdmin) {
      options.push({ text: 'Transfer', action: () => setModalOpen(TRANSFER_MODAL) });
    }
    return options;
  }, [duplicateProjectRequest, user]);

  const isSmallWidth = width <= SMALL_WIDTH;

  const onMouseEnter = () => {
    !isSmallWidth && setShowOptions(true);
  };

  const onMouseLeave = () => {
    !isSmallWidth && setShowOptions(false);
  };

  const onProjectNameClick = () => {
    isSmallWidth && goToProject();
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <button className={styles.content} onClick={goToProject}>
        {thumbnail && (
          <img
            src={optimizeCloudinaryBigThumbnail(thumbnail)}
            alt=""
            className={styles.thumbnail}
          />
        )}
        {type === PROJECT_TYPE.FLYER && (
          <div className={styles.flyer}>
            <img src={FlyerIcon} alt="flyer" />
          </div>
        )}
      </button>
      <div className={styles.footer}>
        <div className={styles.info}>
          <button className={styles.projectName} onClick={onProjectNameClick}>
            {name}
          </button>
          <span className={styles.timeAgo}>
            {formatDistanceToNow(parseISO(lastUpdated), { addSuffix: true })}
          </span>
        </div>
        {showOptions && (
          <CollapsibleOptions
            options={projectOptions}
            optionsClassName={styles.options}
            optionClassName={styles.option}
            iconClassName={styles.menuIcon}
            className={styles.menuContainer}
            buttonClassName={styles.buttonMenu}
            closeOnClickOption
            iconOpened={MenuIcon}
            iconClosed={MenuIcon}
            animateIcon={false}
          />
        )}
        {isSmallWidth && (
          <div className={styles.smallOptions}>
            {projectOptions.map(option => (
              <button className={styles.smallOption} onClick={option.action}>
                {option.text}
              </button>
            ))}
          </div>
        )}
      </div>
      {modalOpen === DELETE_MODAL && (
        <DeleteProjectModal
          isShowing={modalOpen === DELETE_MODAL}
          hide={() => setModalOpen()}
          projectId={id}
          template={template}
        />
      )}
      {modalOpen === TRANSFER_MODAL && (
        <TransferProjectModal
          isShowing={modalOpen === TRANSFER_MODAL}
          hide={() => setModalOpen()}
          projectId={id}
        />
      )}
    </div>
  );
};

ProjectCard.propTypes = {
  name: string.isRequired,
  id: number.isRequired,
  lastUpdated: string.isRequired,
  template: object,
  thumbnail: string,
  type: oneOf(Object.values(PROJECT_TYPE)).isRequired,
};

export { ProjectCard };
