import React from 'react';
import { string, number, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './ProjectCard.module.scss';

const ProjectCard = ({ id, name, thumbnail, showOnlyThumbnail = false }) => {
  const { account } = useSelector(({ publicPages }) => ({
    account: publicPages.account,
    allPublicProjects: publicPages.allPublicProjects,
  }));

  return (
    <div className={styles.container}>
      <Link to={`${account.publicRelUrl}?pjid=${id}`}>
        <div className={styles.gradient} />
        <img className={styles.thumbnail} src={thumbnail} alt={name} />
        {!showOnlyThumbnail && <span className={styles.projectName}>{name}</span>}
      </Link>
    </div>
  );
};

ProjectCard.propTypes = {
  id: number,
  name: string,
  thumbnail: string,
  showOnlyThumbnail: bool,
};

export { ProjectCard };
