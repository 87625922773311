import React from 'react';
import { number, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { addTextCombination } from 'src/actions/projectActions';
import { addTextCombinationToCanvas } from 'src/utils/fontHelpers';
import styles from './AddTextCombination.module.scss';

const AddTextCombination = ({ thumbnail, elements, layoutWidth, layoutHeight, position }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    const parsedElements = JSON.parse(elements) || [];
    addTextCombinationToCanvas(parsedElements, () => {
      dispatch(addTextCombination({
        elements: parsedElements,
        layoutWidth,
        layoutHeight,
        position,
      }));
    });
  };

  return (
    <button className={styles.content} onClick={onClick}>
      <img src={thumbnail} alt="Add Text Combination" />
    </button>
  );
};

AddTextCombination.propTypes = {
  thumbnail: string.isRequired,
  elements: string.isRequired,
  layoutWidth: number.isRequired,
  layoutHeight: number.isRequired,
  position: shape({
    x: number,
    y: number,
  }),
};

export { AddTextCombination };
