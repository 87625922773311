import React, { useState } from 'react';

import { DeleteProjectsChooser } from './delete-projects-chooser';
import { DowngradeConfirmation } from './downgrade-confirmation';
import { MainAccountChooser } from './main-account-chooser';

const CHOOSE_MAIN_ACCOUNT_STEP = 'chooseMainAccountStep';
const DELETE_PROJECTS_STEP = 'deleteProjectsStep';
const CONFIRMATION_STEP = 'confirmationStep';

const PlanDowngrade = () => {
  const [currentStep, setCurrentStep] = useState(CHOOSE_MAIN_ACCOUNT_STEP);
  const [mainAccountId, setMainAccountId] = useState();
  const [accountIdsActions, setAccountIdsActions] = useState({});

  const onCancelInDelete = () => {
    setAccountIdsActions({});
    setCurrentStep(CHOOSE_MAIN_ACCOUNT_STEP);
  };

  const onCancelInConfirmation = () => {
    setAccountIdsActions({});
    setCurrentStep(DELETE_PROJECTS_STEP);
  };

  return (
    <>
      {currentStep === CHOOSE_MAIN_ACCOUNT_STEP && (
        <MainAccountChooser
          onClickNext={() => setCurrentStep(DELETE_PROJECTS_STEP)}
          mainAccountId={mainAccountId}
          setMainAccountId={setMainAccountId}
        />
      )}
      {currentStep === DELETE_PROJECTS_STEP && (
        <DeleteProjectsChooser
          onClickNext={() => setCurrentStep(CONFIRMATION_STEP)}
          onClickCancel={onCancelInDelete}
          mainAccountId={mainAccountId}
          accountIdsActions={accountIdsActions}
          setAccountIdsActions={setAccountIdsActions}
        />
      )}
      {currentStep === CONFIRMATION_STEP && (
        <DowngradeConfirmation
          onClickCancel={onCancelInConfirmation}
          mainAccountId={mainAccountId}
          accountIdsActions={accountIdsActions}
        />
      )}
    </>
  );
};

export { PlanDowngrade };
