import InstagramWhiteIcon from 'src/assets/icons/instagram-white.svg';
import InstagramIcon from 'src/assets/icons/instagram.svg';
import FacebookWhiteIcon from 'src/assets/icons/facebook-white.svg';
import FacebookIcon from 'src/assets/icons/facebook.svg';
import TwitterWhiteIcon from 'src/assets/icons/twitter-white.svg';
import TwitterIcon from 'src/assets/icons/twitter.svg';
import LinkedinWhiteIcon from 'src/assets/icons/linkedin-white.svg';
import LinkedinIcon from 'src/assets/icons/linkedin.svg';
import {
  INSTAGRAM_LAYOUTS,
  FACEBOOK_LAYOUTS,
  TWITTER_LAYOUTS,
  LINKEDIN_LAYOUTS,
  PRESENTATION_LAYOUTS,
  WEB_LAYOUTS,
  EMAIL_LAYOUTS,
  EMAIL_SOURCE,
  FLYER_LAYOUTS,
  SIGN_LAYOUTS,
  SIGN_SOURCE,
} from 'src/constants/layout';

export const socialMediaLayouts = {
  title: 'Social Media',
  options: [
    {
      icon: InstagramIcon,
      iconSelected: InstagramWhiteIcon,
      layout: INSTAGRAM_LAYOUTS.SQUARE_LAYOUT,
      text: 'Square',
      buttonStyles: { width: '6.4rem', height: '6.4rem' },
    },
    {
      icon: InstagramIcon,
      iconSelected: InstagramWhiteIcon,
      layout: INSTAGRAM_LAYOUTS.STORY_LAYOUT,
      text: 'Story',
      buttonStyles: { width: '6.4rem', height: '8.5rem' },
    },
    {
      icon: InstagramIcon,
      iconSelected: InstagramWhiteIcon,
      layout: INSTAGRAM_LAYOUTS.PORTRAIT_LAYOUT,
      text: 'Portrait',
      buttonStyles: { width: '6.4rem', height: '7.5rem' },
    },
    {
      icon: InstagramIcon,
      iconSelected: InstagramWhiteIcon,
      layout: INSTAGRAM_LAYOUTS.LANDSCAPE_LAYOUT,
      text: 'Landscape',
      buttonStyles: { width: '6.4rem', height: '4.4rem' },
    },
    {
      icon: FacebookIcon,
      iconSelected: FacebookWhiteIcon,
      layout: FACEBOOK_LAYOUTS.SQUARE_LAYOUT,
      text: 'Square',
      buttonStyles: { width: '6.4rem', height: '6.4rem' },
    },
    {
      icon: FacebookIcon,
      iconSelected: FacebookWhiteIcon,
      layout: FACEBOOK_LAYOUTS.STORY_LAYOUT,
      text: 'Story',
      buttonStyles: { width: '6.4rem', height: '8.5rem' },
    },
    {
      icon: FacebookIcon,
      iconSelected: FacebookWhiteIcon,
      layout: FACEBOOK_LAYOUTS.POST_LAYOUT,
      text: 'Post',
      buttonStyles: { width: '6.4rem', height: '4rem' },
    },
    {
      icon: TwitterIcon,
      iconSelected: TwitterWhiteIcon,
      layout: TWITTER_LAYOUTS.POST_LAYOUT,
      text: 'Post',
      buttonStyles: { width: '6.4rem', height: '4rem' },
    },
    {
      icon: LinkedinIcon,
      iconSelected: LinkedinWhiteIcon,
      layout: LINKEDIN_LAYOUTS.POST_LAYOUT,
      text: 'Post',
      buttonStyles: { width: '6.4rem', height: '4rem' },
    },
    {
      icon: LinkedinIcon,
      iconSelected: LinkedinWhiteIcon,
      layout: LINKEDIN_LAYOUTS.COVER_LAYOUT,
      text: 'Cover',
      buttonStyles: { width: '6.4rem', height: '2.8rem' },
      allowsOnlyOneFrame: true,
    },
  ],
};

export const presentationLayouts = {
  title: 'Presentation',
  options: [
    {
      layout: PRESENTATION_LAYOUTS['169_LAYOUT'],
      text: '16:9',
      buttonStyles: { width: '6.4rem', height: '3.6rem' },
    },
    {
      layout: PRESENTATION_LAYOUTS['32_LAYOUT'],
      text: '3:2',
      buttonStyles: { width: '6.4rem', height: '4.3rem' },
    },
    {
      layout: PRESENTATION_LAYOUTS['21_LAYOUT'],
      text: '2:1',
      buttonStyles: { width: '6.4rem', height: '3.2rem' },
    },
  ],
};

export const webLayouts = {
  title: 'Web',
  options: [
    {
      layout: WEB_LAYOUTS.BANNER_LAYOUT,
      text: 'Banner 1350x650',
      buttonStyles: { width: '6.4rem', height: '4rem' },
    },
  ],
};

export const emailLayouts = {
  title: 'Email Banners',
  options: [
    {
      layout: EMAIL_LAYOUTS.SHORT_LAYOUT,
      text: 'Short',
      buttonStyles: { width: '6.4rem', height: '2rem' },
    },
    {
      layout: EMAIL_LAYOUTS.MEDIUM_LAYOUT,
      text: 'Medium',
      buttonStyles: { width: '6.4rem', height: '3rem' },
    },
    {
      layout: EMAIL_LAYOUTS.TALL_LAYOUT,
      text: 'Tall',
      buttonStyles: { width: '6.4rem', height: '4rem' },
    },
  ],
};

export const flyerLayouts = {
  title: 'Flyer',
  options: [
    {
      layout: FLYER_LAYOUTS.SQUARE_LAYOUT,
      text: 'Square',
      buttonStyles: { width: '6.4rem', height: '6.4rem' },
    },
    {
      layout: FLYER_LAYOUTS.PORTRAIT_LAYOUT,
      text: 'Portrait',
      buttonStyles: { width: '6.4rem', height: '7.5rem' },
    },
    {
      layout: FLYER_LAYOUTS.LANDSCAPE_LAYOUT,
      text: 'Landscape',
      buttonStyles: { width: '6.4rem', height: '4.4rem' },
    },
  ],
};

export const signLayouts = {
  title: 'Sign',
  options:
  [
    {
      layout: SIGN_LAYOUTS.HORIZONTAL_LAYOUT,
      text: 'Horizontal',
      buttonStyles: { width: '6.4rem', height: '4.8rem' },
    },
    {
      layout: SIGN_LAYOUTS.VERTICAL_LAYOUT,
      text: 'Vertical',
      buttonStyles: { width: '6.4rem', height: '8.2rem' },
    },
  ],
};

export const layoutAllowsOnlyOneFrame = (layout) => (
  layout.source === EMAIL_SOURCE ||
  layout.source === SIGN_SOURCE || (
    layout.source === LINKEDIN_LAYOUTS.COVER_LAYOUT.source &&
    layout.width === LINKEDIN_LAYOUTS.COVER_LAYOUT.width &&
    layout.height === LINKEDIN_LAYOUTS.COVER_LAYOUT.height
  )
);
