import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { bool, func } from 'prop-types';

import { useSession } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import CheckIcon from 'src/assets/icons/check-black.svg';
import CheckIconBlue from 'src/assets/icons/check-blue.svg';
import { createCustomerPortalSession } from 'src/actions/userActions';
import { dismissModal } from 'src/actions/userSettingsActions';
import { UPGRADE_BRAND_LIBRARY } from 'src/constants/dismissibleModals';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import styles from './UpgradeModal.module.scss';

const UpgradeModal = ({ showAnyway, setShowAnyway }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user, account } = useSession();
  const { brandAssetStatus } = account || {};
  const { customerId, membership: { subscription } } = user;

  const [modalOpen, setModalOpen] = useState(user.settings.showBrandLibraryModal);

  const upgradeRequest = useCallback(() => {
    dispatch(dismissModal(UPGRADE_BRAND_LIBRARY));
    if (subscription && customerId) {
      dispatch(createCustomerPortalSession(customerId, pathname));
    } else {
      history.push(routesPaths.choosePlan, { pathname });
    }
  }, [dispatch, subscription, customerId, pathname, history]);

  const dismissRequest = useCallback(() => {
    dispatch(dismissModal(UPGRADE_BRAND_LIBRARY));
    setModalOpen(false);
    setShowAnyway(false);
  }, [dispatch, setShowAnyway]);

  const userNeverSubscribed = !user.customerId && !subscription;

  const previewAssets = userNeverSubscribed &&
    (brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY ||
    brandAssetStatus === BRAND_ASSETS_STATUS.DONE);

  return (
    <Modal
      isShowing={showAnyway || modalOpen}
      hide={dismissRequest}
      isEscEnabled={false}
      closeOnClickOutside={previewAssets}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.headerFirstLine}>
              {previewAssets ? 'WE MADE A FEW ADDITIONS FOR YOU' : 'WELCOME TO YOUR BRAND LIBRARY'}
            </div>
            <div className={styles.headerTitle}>
              {previewAssets ?
                'Preview Your Brand Library and All of Your Assets' :
                'Upgrade to unlock powerful brand features'}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={cn(styles.secondTitle, { [styles.previewAssetsText]: previewAssets })}>
            {previewAssets ?
              'Increase workflow and maintain your brand' :
              `Maintain your brand's consistency and increase workflow when
                you define all of your brand elements in one place.`}
          </div>
          <div className={styles.featureGrid}>
            <div className={cn(styles.row, styles.bottomLine)}>
              <div className={styles.title}>
                <div className={styles.featureAvailable}>
                  <img src={previewAssets ? CheckIconBlue : CheckIcon} alt="" />
                </div>
                <div
                  className={cn(styles.featureName, { [styles.previewAssetsText]: previewAssets })}
                >
                  Logos
                </div>
              </div>
              <div className={styles.featureDesc}>
                Easily add every version of your logo to your creative
              </div>
            </div>
            <div className={cn(styles.row, styles.bottomLine)}>
              <div className={styles.title}>
                <div className={styles.featureAvailable}>
                  <img src={previewAssets ? CheckIconBlue : CheckIcon} alt="" />
                </div>
                <div
                  className={cn(styles.featureName, { [styles.previewAssetsText]: previewAssets })}
                >
                  Assets
                </div>
              </div>
              <div className={styles.featureDesc}>
                Maintain your images, videos, and illustrations with easy categorization
              </div>
            </div>
            <div className={cn(styles.row, styles.bottomLine)}>
              <div className={styles.title}>
                <div className={styles.featureAvailable}>
                  <img src={previewAssets ? CheckIconBlue : CheckIcon} alt="" />
                </div>
                <div
                  className={cn(styles.featureName, { [styles.previewAssetsText]: previewAssets })}
                >
                  Colors
                </div>
              </div>
              <div className={styles.featureDesc}>
                Define brand colors to help Stagger recommend assets aligned with your color profile
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.title}>
                <div className={styles.featureAvailable}>
                  <img src={previewAssets ? CheckIconBlue : CheckIcon} alt="" />
                </div>
                <div
                  className={cn(styles.featureName, { [styles.previewAssetsText]: previewAssets })}
                >
                  Fonts
                </div>
              </div>
              <div className={styles.featureDesc}>
                Use custom fonts and set commonly used type styles in your creative
              </div>
            </div>
          </div>
          {previewAssets ? (
            <div className={styles.actions}>
              <Button
                className={styles.actionButton}
                onClick={dismissRequest}
              >
                Show Me My Assets
              </Button>
              <Button
                className={styles.freeTrialButton}
                secondary
                onClick={upgradeRequest}
              >
                Start Your Free Trial
              </Button>
            </div>
          ) : (
            <div className={styles.actions}>
              <Button
                className={styles.actionButton}
                onClick={upgradeRequest}
              >
                {userNeverSubscribed ? 'Start Your Free Trial' : 'Upgrade Your Account'}
              </Button>
              <Button
                className={styles.dismissButton}
                secondary
                onClick={dismissRequest}
              >
                Dismiss
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  showAnyway: bool,
  setShowAnyway: func,
};

export { UpgradeModal };
