import React, { useCallback } from 'react';
import { number, shape, string } from 'prop-types';
import cn from 'classnames';

import { useDropdownWithScroll } from '../../useDropdownsWithScroll';
import { ShadowOptions } from '../shadow-options';
import styles from './ElementShadow.module.scss';

const PADDING_LEFT_DROPDOWN = 25;

const ElementShadow = ({
  scrollPosition,
  paddingLeftTooltip = PADDING_LEFT_DROPDOWN,
  paddingLeftOptions = 0,
  className,
}) => {
  const containerId = 'elementShadow';
  const { optionsSize } = useDropdownWithScroll(scrollPosition, containerId);

  const tooltipStyles = useCallback(() => (
    {
      left: optionsSize.left - paddingLeftTooltip,
      right: 'unset',
      transform: 'unset',
    }
  ), [optionsSize.left, paddingLeftTooltip]);

  return (
    <ShadowOptions
      scrollPosition={scrollPosition}
      className={cn(styles.container, className)}
      optionsStyle={{ left: optionsSize.left - paddingLeftOptions }}
      tooltipStyle={tooltipStyles()}
      containerId={containerId}
      tooltipClassName={styles.button}
    />
  );
};

ElementShadow.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
  paddingLeftTooltip: number,
  paddingLeftOptions: number,
  className: string,
};

export { ElementShadow };
