import React from 'react';
import { bool, node, number, object, oneOfType, string } from 'prop-types';
import cn from 'classnames';

import styles from './SpinnerControlled.module.scss';

const SpinnerControlled = ({ width = '7rem', color = '#276DF1', children, animate = true }) => (
  <div style={{ width }}>
    <svg viewBox="0 0 36 36" className={styles.circularChart}>
      <path
        className={styles.circleBg}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        className={cn(styles.circle, { [styles.animation]: animate })}
        strokeDasharray="100, 100"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={color}
      />
      {children}
    </svg>
  </div>
);

SpinnerControlled.propTypes = {
  width: number,
  color: string,
  children: oneOfType([node, object]),
  animate: bool,
};

export { SpinnerControlled };
