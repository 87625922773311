import React from 'react';
import cn from 'classnames';
import { number } from 'prop-types';

import styles from './Dots.module.scss';

const Dots = ({ activeIndex, size }) => (
  <div className={styles.dots}>
    {Array.from({ length: size }, (v, i) => i).map(
      (index) => (
        <div
          className={cn(styles.dot, { [styles.dotActive]: activeIndex === index })}
          key={index}
        />
      ),
    )}
  </div>
);

Dots.propTypes = {
  activeIndex: number.isRequired,
  size: number.isRequired,
};

export { Dots };
