import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';

import {
  DISCARD_GENERATED_COLLAGE_DATA,
  answerToChat,
  startOverChat,
} from 'src/actions/collageActions';
import { useLoading } from 'src/hooks';
import { AnswerToChat } from '../answer-to-chat';
import styles from './FinishChat.module.scss';

const FinishChat = ({ answer }) => {
  const dispatch = useDispatch();

  const loadingDiscardGeneratedCollageData = useLoading(DISCARD_GENERATED_COLLAGE_DATA);

  const finishChat = useCallback(() => {
    dispatch(answerToChat({ answer: 'End chat' }));
  }, [dispatch]);

  const startNewChat = useCallback(() => {
    dispatch(startOverChat());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <AnswerToChat
        text="End chat"
        onClick={finishChat}
        disabled={loadingDiscardGeneratedCollageData}
        answer={answer}
      />
      <AnswerToChat
        text="Start over"
        onClick={startNewChat}
        disabled={loadingDiscardGeneratedCollageData}
        answer={answer}
      />
      {answer === 'End chat' && (
        <>
          <span className={styles.endText}>
            We&apos;ve ended this collage generator session.
          </span>
          <AnswerToChat
            text="Start a new chat"
            onClick={startNewChat}
            disabled={loadingDiscardGeneratedCollageData}
            className={styles.startNew}
          />
        </>
      )}
    </div>
  );
};

FinishChat.propTypes = {
  answer: string,
};

export { FinishChat };
