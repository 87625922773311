import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool } from 'prop-types';

import WandIcon from 'src/assets/icons/wand-white.svg';
import { useProjectSelector, useSession, useStatus, useTooltip } from 'src/hooks';
import { Tooltip } from 'src/common/tooltip';
import { PRODUCT_TOUR_PREFIX_ID, PROJECT_TYPE, SHOWING_STATUS } from 'src/constants/general';
import { COLLAGE_CONTROL } from 'src/constants/canvasControls';
import { Pulse } from 'src/common/pulse';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { SUCCESS } from 'src/constants/status';
import { closeProductTip } from 'src/actions/userSettingsActions';
import { Control } from '../control';
import { CollageTip } from '../collage-tip';
import styles from './CollageControl.module.scss';

const CollageControl = ({ disabled }) => {
  const dispatch = useDispatch();

  const { type } = useProjectSelector();

  const { user } = useSession();

  const {
    productTour,
    controlOpen,
  } = useSelector(({ session, canvas }) => ({
    productTour: session.productTour,
    controlOpen: canvas.controlOpen,
  }));

  const { ref, showTooltip } = useTooltip();

  const { status: statusGetBrandLibrary } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  const showTip = statusGetBrandLibrary === SUCCESS &&
    user.settings.showSquadEventCollageTip === SHOWING_STATUS.SHOW;


  const onClickControlCallback = () => {
    if (showTip) {
      dispatch(closeProductTip('showSquadEventCollageTip'));
    }
  };

  return (
    <div className={styles.container} ref={ref}>
      <Control
        icon={WandIcon}
        control={COLLAGE_CONTROL}
        text="Collages"
        disabled={disabled}
        iconClassName={styles.icon}
        onClickCallback={onClickControlCallback}
      />
      {type === PROJECT_TYPE.PROJECT && (
        <div id={`${PRODUCT_TOUR_PREFIX_ID}-3`} className={styles.productTour}>
          {productTour && <Pulse />}
        </div>
      )}
      {showTooltip && controlOpen !== COLLAGE_CONTROL && !showTip && (
        <Tooltip className={styles.tooltip}>Make a Creator Collage</Tooltip>
      )}
      {showTip && (
        <div className={styles.collageTip}>
          <Pulse />
          <CollageTip />
        </div>
      )}
    </div>
  );
};

CollageControl.propTypes = {
  disabled: bool,
};

export { CollageControl };
