import React, { useEffect, useMemo, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { useDispatch } from 'react-redux';

import { useWindowSize, useSession } from 'src/hooks';
import {
  LeftImg,
  LeftImg2x,
  LeftImg3x,
  RightImg,
  RightImg2x,
  RightImg3x,
} from 'src/assets/images/background';
import { LANDING_PAGE_LINK } from 'src/constants/general';
import Logotype from 'src/assets/icons/logotype.svg';
import { LARGE_WIDTH } from 'src/constants/breakpoints';
import { routesPaths } from 'src/routes/routesPaths';
import { routeWithProps } from 'src/utils/helpers';
import { SocialButtons } from 'src/common/social-buttons';
import { Footer } from 'src/common/footer';
import { socialLogin } from 'src/actions/userActions';
import { LoginForm } from './form';
import styles from './Login.module.scss';

const Login = () => {
  const { authenticated } = useSession();
  const { windowSize: { width } } = useWindowSize();
  const isLargeScreen = useMemo(() => width > LARGE_WIDTH, [width]);
  const dispatch = useDispatch();
  const history = useHistory();

  const [fromResetPassword, setFromResetPassword] = useState(false);

  useEffect(() => {
    const { location: { state } } = history;
    if (state?.isReset) {
      setFromResetPassword(true);
    }
  }, [history]);

  const queryParams = useMemo(() => {
    const { location: { search } } = history;
    if (search) {
      const { token, alreadyExisted, projectId } = parse(search, { parseBooleans: true });
      return { token, alreadyExisted, projectId };
    }
    return {};
  }, [history]);

  useEffect(() => {
    if (queryParams?.token) {
      const { token, alreadyExisted } = queryParams;
      dispatch(socialLogin({ token, alreadyExisted }));
    }
  }, [dispatch, history, queryParams]);

  if (authenticated && queryParams?.projectId) {
    return <Redirect to={routeWithProps(routesPaths.project, { id: queryParams.projectId })} />;
  }

  if (authenticated) {
    return <Redirect to={routesPaths.projects} />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <a href={LANDING_PAGE_LINK}>
          <img src={Logotype} alt="Stagger logotype" className={styles.logotype} />
        </a>
        <div className={styles.container}>
          {isLargeScreen && (
          <img
            src={LeftImg}
            alt=""
            srcSet={`${LeftImg2x} 2x, ${LeftImg3x} 3x`}
            className={styles.image}
          />
          )}
          <div className={styles.content}>
            <div className={styles.form}>
              <h1>Sign in</h1>
              {fromResetPassword && (
              <div className={styles.passwordReset}>
                <span>Password updated successfully.</span>
              </div>
              )}
              <SocialButtons
                googleText="Sign in with Google"
                facebookText="Sign in with Facebook"
              />
              <LoginForm />
            </div>
            <div className={styles.signupContainer}>
              <span>Don&apos;t have an account?</span>
              <Link to={routesPaths.signup} className={styles.link}>Sign up now</Link>
            </div>
          </div>
          {isLargeScreen && (
          <img
            src={RightImg}
            alt=""
            srcSet={`${RightImg2x} 2x, ${RightImg3x} 3x`}
            className={styles.image}
          />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Login };
