import React from 'react';
import { string } from 'prop-types';

import styles from './Spinner.module.scss';

const Spinner = ({ iconClassName = '', containerClassName = '', spinnerClassName = '' }) => (
  <div className={`${styles.container} ${containerClassName}`}>
    <div className={`${styles.spinner} ${spinnerClassName}`}>
      <div className={`${styles.icon} ${iconClassName}`} />
    </div>
  </div>
);

Spinner.propTypes = {
  iconClassName: string,
  containerClassName: string,
  spinnerClassName: string,
};

export { Spinner };
