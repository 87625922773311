import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool, number, arrayOf } from 'prop-types';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { useLoading } from 'src/hooks';
import { MoveProjects } from 'src/pages/account-settings/accounts-manager/move-projects';
import {
  deleteAccountsAgencyDowngrade,
  DELETE_ACCOUNTS_AGENCY_DOWNGRADE,
  moveProjectsAgencyDowngrade,
  MOVE_PROJECTS_AGENCY_DOWNGRADE,
} from 'src/actions/accountActions';
import styles from './DeleteAccounts.module.scss';

const CHOOSE_STEP = 'chooseStep';
const MOVE_PROJECTS_STEP = 'moveProjectsStep';

const DeleteAccounts = ({ isShowing, hide, ids }) => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(CHOOSE_STEP);

  const onMoveToAccount = useCallback(() => {
    setCurrentStep(MOVE_PROJECTS_STEP);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deleteAccountsAgencyDowngrade(ids));
  }, [ids, dispatch]);

  const loadingDeleteAccounts = useLoading(DELETE_ACCOUNTS_AGENCY_DOWNGRADE);
  const loadingMoveProjects = useLoading(MOVE_PROJECTS_AGENCY_DOWNGRADE);

  const onMoveProjects = useCallback((selectedOption) => {
    dispatch(moveProjectsAgencyDowngrade(ids, selectedOption));
  }, [dispatch, ids]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      curvedContainer
      classNameContent={styles.modal}
      closeOnClickOutside={false}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete your account?
        </span>
        {currentStep === CHOOSE_STEP && (
          <>
            <span className={styles.subtitle}>
              If you have projects in your account,
              do you want to permanently delete them or move them to another account?
            </span>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={onMoveToAccount}
                alternativeCTA
              >
                Move to Another Account
              </Button>
              <Button
                className={styles.buttonDelete}
                onClick={onDelete}
                loading={loadingDeleteAccounts}
              >
                Delete Them Forever
              </Button>
            </div>
          </>
        )}
        {currentStep === MOVE_PROJECTS_STEP && (
          <MoveProjects
            ids={ids}
            onCancel={hide}
            loading={loadingMoveProjects}
            onMoveProjects={onMoveProjects}
          />
        )}
      </div>
    </Modal>
  );
};

DeleteAccounts.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  ids: arrayOf(number).isRequired,
};

export { DeleteAccounts };
