import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DataIcon from 'src/assets/icons/data-icon.svg';
import { DEFAULT_PROJECT } from 'src/constants/general';
import { Button } from 'src/common/button';
import { createProject } from 'src/actions/projectActions';
import { useSession } from 'src/hooks';
import styles from './EmbedEmptyState.module.scss';

const EmbedEmptyState = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { account } = useSession();

  const onClick = () => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: DEFAULT_PROJECT.LAYOUT.width,
      layoutHeight: DEFAULT_PROJECT.LAYOUT.height,
      layoutSource: DEFAULT_PROJECT.LAYOUT.source,
    };
    dispatch(createProject(project, account, history));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.list}>
          <span className={styles.listTitle}>
            Project List (0)
          </span>
          <div className={styles.listText}>
            Well, let&apos;s get some projects going in here!
          </div>
        </div>
        <div className={styles.analytics}>
          <img
            src={DataIcon}
            alt=""
          />
          <span className={styles.analyticsTitle}>
            Data needs a starting point.
          </span>
          <span className={styles.analyticsText}>
            Start seeing data when you create a project and copy th embed code into your website.
          </span>
          <Button
            className={styles.buttonCreate}
            onClick={() => onClick(false)}
            secondary
          >
            Create a new project
          </Button>
        </div>
      </div>
    </div>
  );
};

export { EmbedEmptyState };
