import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStatus } from 'src/hooks';
import { SEND_EMAIL_PASSWORD } from 'src/actions/userActions';
import { Button } from 'src/common/button';
import { routesPaths } from 'src/routes/routesPaths';
import { SUCCESS } from 'src/constants/status';
import { ForgotPasswordForm } from '../form';
import styles from './ForgotPasswordContent.module.scss';

const ForgotPasswordContent = () => {
  const history = useHistory();
  const { status } = useStatus(SEND_EMAIL_PASSWORD);

  const goToLogin = () => {
    history.push(routesPaths.signin);
  };

  return (
    <>
      {status === SUCCESS ? (
        <>
          <div className={styles.successContainer}>
            <span>Reset instructions sent!</span>
          </div>
          <span className={styles.subtitle}>
            Great, we’ve emailed you password reset
            instructions to your email on file. You’ll have
            one hour to reset your password.
          </span>
          <Button className={styles.button} onClick={goToLogin}>
            Go To Login
          </Button>
        </>
      ) : (
        <>
          <span className={styles.subtitle}>
            Enter the email address you used when you joined and we’ll
            send you instructions to reset your password.
          </span>
          <ForgotPasswordForm />
        </>
      )}
    </>
  );
};

export { ForgotPasswordContent };
