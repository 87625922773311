import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { userDoingProductTour, userFinishesProductTour } from 'src/actions/userSettingsActions';
import { useProjectSelector } from 'src/hooks';
import { getTourProductName } from '../bottom-options/product-tour/tourHelper';
import styles from './WelcomeModal.module.scss';

const WelcomeModal = ({ setIsOpenWelcomeModal }) => {
  const dispatch = useDispatch();

  const { type } = useProjectSelector();

  const onCloseModal = useCallback(() => {
    setIsOpenWelcomeModal(false);
  }, [setIsOpenWelcomeModal]);

  const uploadImages = useCallback(() => {
    const tourName = getTourProductName(type);
    dispatch(userFinishesProductTour(tourName));
    onCloseModal();
  }, [dispatch, onCloseModal, type]);

  const takeProductTour = useCallback(() => {
    dispatch(userDoingProductTour(true));
    onCloseModal();
  }, [dispatch, onCloseModal]);

  return (
    <Modal
      isShowing
      hide={takeProductTour}
      isEscEnabled={false}
      closeOnClickOutside={false}
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>
            <span role="img" aria-label="hello">👋 </span>
            Welcome to Stagger
          </span>
        </div>
        <div className={styles.body}>
          <span className={styles.text}>
            Design and download images for your social media in minutes.
            How do you want to get started?
          </span>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.buttonUpload}
              onClick={uploadImages}
              alternativeCTA
            >
              Upload my images
            </Button>
            <Button
              className={styles.buttonTour}
              onClick={takeProductTour}
            >
              Take a product tour
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  setIsOpenWelcomeModal: func.isRequired,
};

export { WelcomeModal };
