import React from 'react';
import { bool } from 'prop-types';

import { useCopyEmbedCode, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { Button } from 'src/common/button';
import styles from './CopyEmbedButton.module.scss';

const CopyEmbedButton = ({ disabled }) => {
  const { generateEmbedCode, linksOvelapping } = useCopyEmbedCode();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  return (
    <Button
      className={styles.button}
      onClick={generateEmbedCode}
      disabled={disabled || !!linksOvelapping.length}
      optionsClassName={styles.embedOptions}
      secondary
      short
    >
      Copy Embed {!isMobile ? 'Code' : ''}
    </Button>
  );
};

CopyEmbedButton.propTypes = {
  disabled: bool.isRequired,
};

export { CopyEmbedButton };
