import React from 'react';
import { bool, shape, string } from 'prop-types';
import cn from 'classnames';

import styles from './ArrowSelect.module.scss';

const ArrowSelect = ({ selectProps, icon }) => {
  const isOpen = selectProps.menuIsOpen;
  return (
    <img
      src={icon}
      alt=""
      className={cn(
        styles.icon,
        { [styles.closedIcon]: !isOpen },
      )}
    />
  );
};

ArrowSelect.propTypes = {
  selectProps: shape({
    menuIsOpen: bool,
  }),
  icon: string.isRequired,
};

export { ArrowSelect };
