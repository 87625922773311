export const toastSuccessConfig = {
  className: 'toastSuccess',
  bodyClassName: 'toastSuccessBody',
};

export const toastInfoConfig = toastId => ({
  className: 'toastDefault',
  bodyClassName: 'toastDefaultBody',
  toastId,
  autoClose: false,
});

export const toastErrorConfig = {
  className: 'toastError',
  bodyClassName: 'toastErrorBody',
};

export const toastSuccessMarkupConfig = {
  className: 'toastSuccessMarkup',
  bodyClassName: 'toastSuccessBody',
};
