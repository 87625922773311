import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { bool, func, node, string } from 'prop-types';

import {
  useWindowSize,
  useClickOutside,
  useDelayUnmount,
} from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import CloseIcon from 'src/assets/icons/close-white.svg';
import LeftIcon from 'src/assets/icons/arrow-left-white.svg';
import { saveSelectedTemplatePreview } from 'src/actions/templatesActions';
import { PreviewTemplateSlider } from '../preview-template-slider';
import styles from './PreviewModal.module.scss';

const ANIMATION_DURATION = 250;

const PreviewModal = ({ disableClickOutside, actionButton, actionButtonId, onCloseModal }) => {
  const dispatch = useDispatch();

  const [isClosing, setIsClosing] = useState(false);

  const { selectedTemplatePreview } = useSelector(({ templates }) => ({
    selectedTemplatePreview: templates.selectedTemplatePreview,
  }));

  const { windowSize } = useWindowSize();

  const tags = selectedTemplatePreview?.keywords?.map(key => key.tag).join(', ');

  const onClose = () => {
    setIsClosing(true);
  };

  const close = () => {
    setIsClosing(false);
    dispatch(saveSelectedTemplatePreview(undefined));
    onCloseModal && onCloseModal();
  };

  const isSmallScreen = useMemo(() => windowSize.width <= SMALL_WIDTH, [windowSize.width]);

  const refSlider = useClickOutside(
    onClose,
    true,
    [actionButtonId],
    isSmallScreen || disableClickOutside,
  );

  const shouldRenderPreview = useDelayUnmount(!isClosing, ANIMATION_DURATION, close);
  const animationStyles = !isClosing ? styles.mount : styles.unmount;

  return shouldRenderPreview && ReactDOM.createPortal(
    <div className={cn(styles.page, animationStyles)}>
      <div className={styles.header}>
        <button className={styles.close} onClick={onClose}>
          <img src={isSmallScreen ? LeftIcon : CloseIcon} alt="Close" />
        </button>
        {isSmallScreen && (
          <div className={styles.title}>
            {selectedTemplatePreview.name}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {!isSmallScreen && (
          <div className={styles.slider}>
            <PreviewTemplateSlider ref={refSlider} />
          </div>
        )}
        <div className={styles.info}>
          {!isSmallScreen && (
            <div className={styles.title}>
              {selectedTemplatePreview.name}
            </div>
          )}
          {actionButton}
          {isSmallScreen && (
            <div className={styles.slider}>
              <PreviewTemplateSlider ref={refSlider} />
            </div>
          )}
          <div className={styles.detailsContainer}>
            <div className={styles.detailsInfo}>
              <span className={styles.headerDetail}>Details</span>
              <span className={styles.details}>
                {selectedTemplatePreview.layoutWidth}x{selectedTemplatePreview.layoutHeight}
              </span>
              <span className={styles.details}>{selectedTemplatePreview.description}</span>
            </div>
            <div className={styles.detailsInfo}>
              {tags && (
                <>
                  <span className={styles.headerDetail}>Tags</span>
                  <span className={styles.details}>
                    {tags}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  );
};

PreviewModal.propTypes = {
  disableClickOutside: bool,
  actionButton: node,
  actionButtonId: string,
  onCloseModal: func,
};

export { PreviewModal };
