import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';

import { Button } from 'src/common/button';
import { getTemplate } from 'src/actions/templatesActions';
import {
  createProjectFromTemplate,
  CREATE_PROJECT_FROM_TEMPLATE,
} from 'src/actions/projectActions';
import { PreviewModal } from 'src/pages/templates/preview-template/preview-modal';
import { useLoading, useSession } from 'src/hooks';
import styles from './PreviewTemplate.module.scss';

const CREATE_FROM_TEMPLATE_BUTTON_ID = 'createFromTemplateButton';

const PreviewTemplate = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { accountId, authenticated } = useSession();

  const loading = useLoading(CREATE_PROJECT_FROM_TEMPLATE);

  const {
    selectedTemplatePreview,
  } = useSelector(({ templates }) => ({
    selectedTemplatePreview: templates.selectedTemplatePreview,
  }));

  useEffect(() => {
    const { preview } = parse(history.location.search, { parseBooleans: true });
    if (preview && !selectedTemplatePreview) {
      dispatch(getTemplate(preview));
    }
  }, [dispatch, history.location.search, selectedTemplatePreview]);

  const onClose = useCallback(() => {
    const queryParams = new URLSearchParams(history.location.search);
    if (queryParams.has('preview')) {
      queryParams.delete('preview');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history]);

  const createProject = useCallback(() => {
    dispatch(createProjectFromTemplate(selectedTemplatePreview, accountId, history));
  }, [accountId, dispatch, history, selectedTemplatePreview]);

  if (!selectedTemplatePreview) {
    return null;
  }

  return (
    <PreviewModal
      onCloseModal={onClose}
      actionButton={authenticated && accountId && (
        <Button
          onClick={createProject}
          alternativeCTA
          className={styles.applyTemplate}
          id={CREATE_FROM_TEMPLATE_BUTTON_ID}
          loading={loading}
        >
          Apply Template
        </Button>
      )}
      actionButtonId={CREATE_FROM_TEMPLATE_BUTTON_ID}
    />
  );
};

export { PreviewTemplate };
