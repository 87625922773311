import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CloseIcon from 'src/assets/icons/close-white.svg';
import { setSmallScreen } from 'src/actions/userActions';
import { MOBILE_SCREEN_CLOSED_MODAL, MOBILE_SCREEN_SEEING_MODAL } from 'src/constants/general';
import { DEVELOPMENT } from 'src/constants/environments';
import styles from './SmallScreenModal.module.scss';

const SmallScreenModal = () => {
  const dispatch = useDispatch();

  const { fromSmallScreen } = useSelector(({ session }) => ({
    fromSmallScreen: session.fromSmallScreen,
  }));

  const onClose = () => {
    dispatch(setSmallScreen(MOBILE_SCREEN_CLOSED_MODAL));
  };

  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (fromSmallScreen !== MOBILE_SCREEN_SEEING_MODAL || environment === DEVELOPMENT) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.closeContainer}>
        <button onClick={onClose} className={styles.close}>
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <span className={styles.title}>
        You&apos;re on a mobile device?
      </span>
      <span className={styles.text}>
        Storytelling with Stagger is best experienced on a desktop.
        Functionality is limited on a mobile device.
      </span>
    </div>
  );
};

export { SmallScreenModal };
