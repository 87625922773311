import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { useSelector } from 'react-redux';

import {
  getGoogleFamilies,
  getSystemFamilies,
  loadUploadedFontsToStylesheet,
} from 'src/utils/fontHelpers';
import { FONT_FAMILY_OPTIONS } from 'src/constants/fonts';
import { SUCCESS } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { FontsDrawer } from './fonts-drawer';
import { AddFont } from './add-font';
import { UploadFont } from './upload-font';
import { UploadedFonts } from './uploaded-fonts';
import styles from './Fonts.module.scss';

const Fonts = () => {
  const { fonts, uploadedFonts } = useSelector(({ brandLibrary }) => ({
    fonts: brandLibrary.fonts,
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  const { status } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    if (status === SUCCESS) {
      const webFontConfig = {};
      let systemFonts = getSystemFamilies(fonts);
      if (uploadedFonts.length || systemFonts.length) {
        systemFonts = systemFonts.map(elem => {
          const fontFamily = FONT_FAMILY_OPTIONS.find(option => option.value === elem.fontFamily);
          return { name: elem.fontFamily, url: fontFamily.font };
        });
        const customFonts = uploadedFonts.concat(systemFonts);
        loadUploadedFontsToStylesheet(customFonts);
        webFontConfig.custom = { families: customFonts.map(elem => elem.name) };
      }
      const googleFamilies = getGoogleFamilies(fonts);
      if (googleFamilies.length) {
        webFontConfig.google = { families: googleFamilies };
      }
      if (fonts.length || uploadedFonts.length) {
        WebFont.load({
          ...webFontConfig,
          active: () => {
            setFontsLoaded(true);
          },
        });
      } else {
        setFontsLoaded(true);
      }
    }
  }, [fonts, status, uploadedFonts]);

  return (
    <>
      <div className={styles.header}>
        <span className={styles.title}>Font Styles</span>
        <UploadFont />
      </div>
      {fontsLoaded && <FontsDrawer />}
      <AddFont fontsLoaded={fontsLoaded} />
      {fontsLoaded && !fonts?.length && <div className={styles.emptyFonts} />}
      {fontsLoaded && <UploadedFonts />}
    </>
  );
};

export { Fonts };
