import {
  findAccountByNamesSuccess,
  findAccountByIdsSuccess,
  getProjectsWithinSuccess,
  setSelectedProject,
  getCurrentLocationSuccess,
  getAllPublicProjectsSuccess,
} from 'src/actions/publicPagesActions';
import urlSlug from 'url-slug';
import { routeWithProps } from 'src/utils/helpers';
import { publicPagesRoutesPaths } from 'src/routes/routesPaths';
import { trackProjectViewed, trackQRScanned } from 'src/utils/analytics';
import createReducer from './createReducer';

const initialState = {
  account: null,
  onRadiusProjects: null,
  selectedProject: null,
  location: null,
  allPublicProjects: null,
};

const buildAccountRelativePublicUrl =
  (account) => {
    const isDefaultAccount = account.id === account.membership.user.defaultAccountId;
    let fixedRelativeUrl = routeWithProps(publicPagesRoutesPaths.accountProfile, {
      username: urlSlug(account.membership.user.username),
      brandName: isDefaultAccount ? '' : urlSlug(account.brandName),
    });
    if (isDefaultAccount) {
      fixedRelativeUrl = fixedRelativeUrl.slice(0, -1);
    }
    return fixedRelativeUrl;
  };

const actionHandlers = {
  [findAccountByNamesSuccess]: (state, { payload: { account, businessInfo } }) => {
    state.account = account;
    if (account) {
      state.account.publicRelUrl = buildAccountRelativePublicUrl(account);
      state.account.businessInfo = businessInfo;
    }
  },
  [findAccountByIdsSuccess]: (state, { payload: { account, businessInfo } }) => {
    state.account = account;
    if (account) {
      state.account.publicRelUrl = buildAccountRelativePublicUrl(account);
      state.account.businessInfo = businessInfo;

      trackQRScanned(account.id);
    }
  },
  [getProjectsWithinSuccess]: (state, { payload: projects }) => {
    state.onRadiusProjects = projects;
  },
  [setSelectedProject]: (state, { payload: project }) => {
    state.selectedProject = project;
    if (project) {
      trackProjectViewed(project.id);
    }
  },
  [getCurrentLocationSuccess]: (state, { payload: location }) => {
    state.location = location;
  },
  [getAllPublicProjectsSuccess]: (state, { payload: projects }) => {
    state.allPublicProjects = projects;
  },
};

export default createReducer(initialState, actionHandlers);
