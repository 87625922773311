import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SMALL_HEIGHT, MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { SQUAD_EVENT_PLANS } from 'src/constants/memberships';
import { useWindowSize, useSession, useStageCenteredPosition, useStatus } from 'src/hooks';
import { UpgradeAlert } from 'src/common/upgrade-alert';
import {
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import {
  addAssetsToMediaDrawer,
  getSquadEventAssets,
  getSquadEventAssetsReset,
} from 'src/actions/canvasActions';
import { Button } from 'src/common/button';
import { addMediaElements, replaceImageFromRightClick } from 'src/actions/projectActions';
import { getAssetFilenameFromUrl } from 'src/utils/helpers';
import { isVideo } from 'src/utils/videoHelpers';
import { SUCCESS } from 'src/constants/status';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { CAMPAIGN_UPLOAD } from 'src/constants/uploadFile';
import { FullscreenButton } from '../fullscreen-button';
import { SquadEventCard } from '../squad-event-card';
import styles from './SquadEventUpload.module.scss';

const SCROLLABLE_CONTAINER_ID = 'sweatScrollableContainer';

const SquadEventUpload = ({ saveSourceInMemory }) => {
  const dispatch = useDispatch();

  const { isSubscriptionActive, membershipPlan } = useSession();

  const position = useStageCenteredPosition();

  const {
    replaceableImageRightClick,
    squadEventAssets,
  } = useSelector(({ canvas }) => ({
    replaceableImageRightClick: canvas.replaceableImageRightClick,
    squadEventAssets: canvas.squadEventAssets,
  }));

  const isReplacingImage = !!replaceableImageRightClick;

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);

  const onClickAsset = image => {
    if (selected.find(elem => elem.id === image.id)) {
      const filteredList = selected.filter(elem => elem.id !== image.id);
      setSelected(filteredList);
    } else if (isReplacingImage) {
      setSelected([image]);
    } else {
      setSelected([...selected, image]);
    }
  };

  const importAssets = useCallback(() => {
    if (isReplacingImage) {
      dispatch(replaceImageFromRightClick({
        media: selected[0],
        replaceableImage: replaceableImageRightClick,
      }));
    } else {
      dispatch(addMediaElements({ media: selected, x: position.x, y: position.y }));
    }
    setSelected([]);
    saveSourceInMemory();
    dispatch(addAssetsToMediaDrawer(selected));
  }, [
    dispatch,
    isReplacingImage,
    position,
    replaceableImageRightClick,
    saveSourceInMemory,
    selected,
  ]);

  const { windowSize } = useWindowSize();

  const perPage = useMemo(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH) {
      return 6;
    }
    return windowSize.height > SMALL_HEIGHT ? 12 : 9;
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    dispatch(getSquadEventAssets(page, perPage));
  }, [dispatch, page, perPage]);

  useEffect(() => () => dispatch(getSquadEventAssetsReset()), [dispatch]);

  const nextPage = useCallback(() => {
    setPage(p => p + 1);
  }, []);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (squadEventAssets.assets.length < squadEventAssets.totalAssets &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [squadEventAssets, nextPage]);

  const { status: statusGetBrandLibrary } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  const enabled = isSubscriptionActive && SQUAD_EVENT_PLANS.includes(membershipPlan);

  return (
    <div className={styles.container}>
      <span className={styles.title}>#SweatWithStagger</span>
      <span className={styles.subtitle}>
        Photos from our event
      </span>
      {statusGetBrandLibrary === SUCCESS && !enabled && (
        <UpgradeAlert
          plans={SQUAD_EVENT_PLANS}
          upgradeChildren={(
            <span>
              Upgrade your account to use your Sweat with Stagger assets
            </span>
          )}
          upgradeButtonText="Upgrade now"
          containerClassName={styles.upgrade}
          buttonClassName={styles.upgradeButton}
        />
      )}
      <div className={styles.results} id={SCROLLABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={squadEventAssets.assets.length || 0}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {squadEventAssets.assets.map(asset => {
            const id = getAssetFilenameFromUrl(asset.url);
            const name = isVideo(asset.url) ? id : getCloudinaryFilenameWithoutTimestamp(id);
            return (
              <SquadEventCard
                key={id}
                preview={asset.url}
                id={id}
                name={name}
                addImage={onClickAsset}
                isSelected={!!selected.find(elem => elem.id === id)}
                disabled={!enabled}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      {!!squadEventAssets.totalAssets && (
        <div className={styles.bottom}>
          <FullscreenButton
            title="Sweat with Stagger Gallery"
            pagesToShow={squadEventAssets.assets}
            nextPage={nextPage}
            cardComponent={(asset) => {
              const id = getAssetFilenameFromUrl(asset.url);
              const name = isVideo(asset.url) ? id : getCloudinaryFilenameWithoutTimestamp(id);
              return (
                <SquadEventCard
                  key={id}
                  preview={asset.url}
                  id={id}
                  name={name}
                  showName={false}
                  addImage={onClickAsset}
                  isSelected={!!selected.find(elem => elem.id === id)}
                  disabled={!enabled}
                  className={styles.fullscreenImageContainer}
                  imageClassName={styles.fullscreenImage}
                  imageButtonClassName={styles.fullscreenImageButton}
                  optimizeImage={false}
                />
              );
            }}
            selected={selected}
            setSelected={setSelected}
            selectDisabled={!enabled}
            actionButton={{
              disabled: !selected.length,
              onClick: importAssets,
              className: styles.import,
              text: isReplacingImage ?
                'Replace' :
                `Place in canvas ${selected.length ? `(${selected.length})` : ''}`,
            }}
            source={CAMPAIGN_UPLOAD}
          />
          <Button
            disabled={!selected.length}
            onClick={importAssets}
            className={styles.import}
          >
            {isReplacingImage ?
              'Replace' :
              `Place in canvas ${selected.length ? `(${selected.length})` : ''}`}
          </Button>
        </div>
      )}
    </div>
  );
};

SquadEventUpload.propTypes = {
  saveSourceInMemory: func.isRequired,
};

export { SquadEventUpload };
