import React, { useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import CheckIcon from 'src/assets/icons/check.svg';
import { SYSTEM_UPLOAD } from 'src/constants/uploadFile';
import { VIDEO_ELEMENT, IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { snapImageOfVideo, isVideo } from 'src/utils/videoHelpers';
import { optimizeCloudinaryThumbnail } from 'src/utils/cloudinaryHelpers';
import { getImageDimensions } from 'src/utils/helpers';
import styles from './SquadEventCard.module.scss';

const SquadEventCard = ({
  preview,
  addImage,
  isSelected,
  id,
  name,
  showName = true,
  className,
  imageClassName,
  imageButtonClassName,
  disabled,
  optimizeImage = true,
}) => {
  const selectImage = (width, height) => {
    const image = {
      src: preview,
      id,
      name,
      uploadType: SYSTEM_UPLOAD,
      width,
      height,
    };
    if (isVideo(preview)) {
      image.type = VIDEO_ELEMENT;
    } else {
      image.type = IMAGE_ELEMENT;
    }
    addImage(image);
  };

  const onClick = () => {
    getImageDimensions(preview, selectImage);
  };

  const [previewSrc, setPreviewSrc] = useState();

  useEffect(() => {
    let mounted = true;
    if (isVideo(preview)) {
      snapImageOfVideo(preview, (videoThumbSrc) => {
        if (mounted) {
          setPreviewSrc(videoThumbSrc);
        }
      });
    } else if (optimizeImage) {
      const imageThumbnail = optimizeCloudinaryThumbnail(preview);
      setPreviewSrc(imageThumbnail);
    } else {
      setPreviewSrc(preview);
    }

    return () => { mounted = false; };
  }, [preview, optimizeImage]);

  return (
    <div className={cn(styles.container, className)}>
      <button
        className={cn(styles.imageContainer, imageButtonClassName)}
        onClick={onClick}
        disabled={disabled}
      >
        <img src={previewSrc} alt="" className={cn(styles.image, imageClassName)} />
        {isSelected && (
          <div className={styles.overlay}>
            <div className={styles.check}>
              <img src={CheckIcon} alt="Check" />
            </div>
          </div>
        )}
      </button>
      {showName && (
        <span className={styles.name}>
          {name}
        </span>
      )}
    </div>
  );
};

SquadEventCard.propTypes = {
  preview: string.isRequired,
  addImage: func.isRequired,
  isSelected: bool.isRequired,
  id: string.isRequired,
  name: string,
  className: string,
  imageClassName: string,
  imageButtonClassName: string,
  showName: bool,
  disabled: bool.isRequired,
  optimizeImage: bool,
};

export { SquadEventCard };
