import { HttpClient } from '../clients';

class CategoryService {
  static getCategories() {
    return HttpClient.get('/categories');
  }

  static getCategoriesWithTemplates(accountId, page, perPage, typeOfProjects) {
    return HttpClient.get('/categories', {
      params:
      { includeTemplates: true, page, perPage, accountId, typeOfProjects },
    });
  }
}

export { CategoryService };
