import React, { useMemo } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';

import { CANVAS_SELECTED_FRAME_PREFIX, LEFT, RIGHT } from 'src/constants/general';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { AddFrame } from './add-frame';

const SelectedFrameBorders = () => {
  const { layout, size } = useProjectSelector();

  const { selectedFrame, scale } = useSelector(({ canvas }) => ({
    selectedFrame: canvas.selectedFrame,
    scale: canvas.scale,
  }));

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const canvasLines = useMemo(() => {
    const lines = [];
    lines.push([layout.width * selectedFrame, 0, layout.width * selectedFrame, layout.height]);
    lines.push([
      layout.width * selectedFrame + layout.width,
      0,
      layout.width * selectedFrame + layout.width,
      layout.height,
    ]);
    lines.push([layout.width * selectedFrame, 0, layout.width * selectedFrame + layout.width, 0]);
    lines.push([
      layout.width * selectedFrame,
      layout.height,
      layout.width * selectedFrame + layout.width,
      layout.height,
    ]);
    return lines;
  }, [layout, selectedFrame]);

  if (selectedFrame === null) {
    return null;
  }

  const circleWidth = isMobile ? 120 : 120 * scale;

  return (
    <>
      {canvasLines.map((points, index) => (
        <Line
          key={points}
          points={points}
          stroke="#276DF1"
          strokeWidth={5}
          id={`${CANVAS_SELECTED_FRAME_PREFIX}Border_${index}`}
        />
      ))}
      <AddFrame
        x={selectedFrame ? layout.width * selectedFrame : circleWidth / 2}
        y={layout.height / 2}
        id={LEFT}
        circleWidth={circleWidth}
      />
      <AddFrame
        x={selectedFrame !== size - 1 ?
          (layout.width * selectedFrame + layout.width) :
          (layout.width * selectedFrame + layout.width - circleWidth / 2)}
        y={layout.height / 2}
        id={RIGHT}
        circleWidth={circleWidth}
      />
    </>
  );
};

export { SelectedFrameBorders };
