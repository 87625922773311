import React, { useCallback } from 'react';
import { number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { createProjectFromSuggestion } from 'src/actions/insightActions';
import styles from './SuggestionCard.module.scss';

const SuggestionCard = ({ id, thumbnail, className }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = useCallback(() => {
    dispatch(createProjectFromSuggestion(id, history));
  }, [dispatch, history, id]);

  return (
    <button className={cn(styles.container, className)} onClick={onClick}>
      <img src={thumbnail} alt="" className={styles.thumbnail} />
    </button>
  );
};

SuggestionCard.propTypes = {
  id: number.isRequired,
  thumbnail: string.isRequired,
  className: string,
};

export { SuggestionCard };
