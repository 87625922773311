import React, { forwardRef } from 'react';
import { Rect } from 'react-konva';

import { CANVAS_SELECTION_ID } from 'src/constants/general';

const CanvasSelection = forwardRef((_, ref) => (
  <Rect
    id={CANVAS_SELECTION_ID}
    fill="rgba(0, 161, 255, 0.3)"
    ref={ref}
  />
));

export { CanvasSelection };
