import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLinksClicked, GET_LINKS_CLICKED } from 'src/actions/embedAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import styles from './LinksClicked.module.scss';

const LinksClicked = () => {
  const dispatch = useDispatch();

  const {
    linksClicked,
    selectedProject,
    selectedTimeRange,
  } = useSelector(({ embedAnalytics }) => ({
    linksClicked: embedAnalytics.linksClicked,
    selectedProject: embedAnalytics.selectedProject,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
  }));

  useEffect(() => {
    const { from, to } = selectedTimeRange;
    dispatch(getLinksClicked(selectedProject.id, from, to));
  }, [dispatch, selectedProject, selectedTimeRange]);

  const { status } = useStatus(GET_LINKS_CLICKED);

  return (
    <div className={styles.container}>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>Links clicked</th>
              <th>Frame</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {status === SUCCESS && (
              linksClicked.map(({ link, frameIdx, count }) => (
                <tr key={link}>
                  <td className={styles.linkCell}>
                    <div className={styles.linkContainer}>
                      <a
                        className={styles.linkText}
                        href={link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {link}
                      </a>
                    </div>
                  </td>
                  <td>{frameIdx}</td>
                  <td>{count}</td>
                </tr>
              ))
            )}
            {status === SUCCESS && !linksClicked.length && (
              <tr>
                <td>No links found</td>
                <td />
                <td />
              </tr>
            )}
            {status === ERROR && (
              <tr>
                <td>No links found</td>
                <td />
                <td />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
    </div>
  );
};

export { LinksClicked };
