import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useProjectSelector } from 'src/hooks';
import { CanvasFrameTitleInput } from '../canvas-frame-title-input';

const CanvasEditableFrameTitle = () => {
  const {
    scale,
    stageOffset,
  } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
  }));

  const { frameTitles, editableFrameTitle } = useProjectSelector();

  const top = useMemo(() => {
    const scrollY = stageOffset.y * scale;
    return editableFrameTitle.frame.y + scrollY;
  }, [editableFrameTitle.frame.y, scale, stageOffset.y]);

  const left = useMemo(() => {
    const scrollX = stageOffset.x * scale;
    return editableFrameTitle.frame.x + scrollX + editableFrameTitle.frame.prefixWidth;
  }, [stageOffset.x, scale, editableFrameTitle.frame]);

  return (
    <CanvasFrameTitleInput
      defaultTitle={frameTitles[editableFrameTitle.frame.index]}
      style={{ top, left, position: 'absolute' }}
    />
  );
};

export { CanvasEditableFrameTitle };
