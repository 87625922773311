import React, { useMemo, useCallback, memo, useState } from 'react';
import { string, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useProjectMedia, useSession, useWindowSize } from 'src/hooks';
import { ROLE_ADMIN } from 'src/constants/general';
import { optimizeCloudinaryBigThumbnail } from 'src/utils/cloudinaryHelpers';
import { routesPaths } from 'src/routes/routesPaths';
import { TemplateOptions } from 'src/pages/templates/template-card/template-options';
import {
  setDataForTemplateModal,
  applyTemplateToProject,
} from 'src/actions/productTemplateActions';
import { changeControlOpen } from 'src/actions/canvasActions';
import { Button } from 'src/common/button';
import { saveSelectedTemplatePreview } from 'src/actions/templatesActions';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import {
  TemplateMediaModal,
} from 'src/pages/project/controls-options/templates-control/preview-template/template-media-modal';
import styles from './TemplateCard.module.scss';

const TemplateCard = memo(({
  thumbnail,
  templateIndex,
  categoryId,
  className,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);

  const { categories, searchTemplates } = useSelector(({ templates }) => ({
    categories: templates.categories,
    searchTemplates: templates.searchTemplates,
  }));

  const { authenticated, user } = useSession();

  const { media } = useProjectMedia();

  const { windowSize } = useWindowSize();

  const template = categoryId ?
    categories[categoryId].templates[templateIndex] :
    searchTemplates[templateIndex];

  const isInTemplatePage = history.location.pathname === routesPaths.templates;

  const openPreview = useCallback(() => {
    dispatch(saveSelectedTemplatePreview(template));
    if (isInTemplatePage) {
      if (history.location.search) {
        history.replace(
          { search: `${history.location.search}&preview=${template.id}` },
        );
      } else {
        history.replace({ search: `preview=${template.id}` });
      }
    }
  }, [template, dispatch, isInTemplatePage, history]);

  const onOpenEditTemplate = useCallback(() => {
    dispatch(setDataForTemplateModal(templateIndex, categoryId));
  }, [categoryId, dispatch, templateIndex]);

  const templateOptions = useMemo(() => (
    [
      { text: 'Preview', action: openPreview },
      { text: 'Edit details', action: onOpenEditTemplate },
    ]
  ), [onOpenEditTemplate, openPreview]);

  const useTemplate = () => {
    if (!media.length) {
      dispatch(applyTemplateToProject(template));
      dispatch(saveSelectedTemplatePreview(undefined));
      dispatch(changeControlOpen(''));
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      {windowSize.width <= SMALL_WIDTH ? (
        <div className={cn(styles.card, className)}>
          <button onClick={openPreview} className={styles.smallCard}>
            <img
              className={styles.image}
              src={optimizeCloudinaryBigThumbnail(thumbnail)}
              alt=""
            />
          </button>
        </div>
      ) : (
        <div className={cn(styles.card, className)}>
          <img
            className={styles.image}
            src={optimizeCloudinaryBigThumbnail(thumbnail)}
            alt=""
          />
          {authenticated && ROLE_ADMIN === user?.role?.id ? (
            <div className={styles.overlay}>
              <TemplateOptions options={templateOptions} />
            </div>
          ) : (
            <div className={styles.overlayButtons}>
              <div className={styles.buttonsContainer}>
                {!isInTemplatePage && (
                  <Button
                    className={styles.buttonSelect}
                    onClick={useTemplate}
                    secondary
                    short
                  >
                    Select
                  </Button>
                )}
                <Button
                  className={styles.buttonPreview}
                  onClick={openPreview}
                  alternativeCTA
                  short
                >
                  Preview
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {authenticated && (
        <TemplateMediaModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          media={media}
          template={template}
        />
      )}
    </>
  );
});

TemplateCard.propTypes = {
  thumbnail: string.isRequired,
  templateIndex: number.isRequired,
  categoryId: number,
  className: string,
};

export { TemplateCard };
