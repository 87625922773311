import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import MinusIcon from 'src/assets/icons/minus-white.svg';
import PlusIcon from 'src/assets/icons/plus-white.svg';
import { useClickOutside, useZoomOptions } from 'src/hooks';
import { saveZoom } from 'src/actions/canvasActions';
import { ZOOM_OPTIONS } from 'src/constants/general';
import styles from './ZoomOption.module.scss';

const ZoomOption = () => {
  const dispatch = useDispatch();

  const { zoom } = useSelector(({ canvas }) => ({
    zoom: canvas.zoom,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const ref = useClickOutside(() => setIsOpen(false), isOpen);

  const saveZoomRequest = useCallback((newZoom) => {
    dispatch(saveZoom({ zoom: newZoom }));
  }, [dispatch]);

  const { getOptionsOrdered, onClickMinus, onClickPlus } = useZoomOptions();

  const optionText = useMemo(() => {
    const selected = ZOOM_OPTIONS.find(option => option.value === zoom);
    return selected?.text || 'Zoom';
  }, [zoom]);

  return (
    <div className={styles.container} ref={ref}>
      <button className={styles.control} onClick={onClickMinus}>
        <img src={MinusIcon} alt="Less zoom" />
      </button>
      <button
        className={cn(styles.button, { [styles.active]: isOpen })}
        onClick={() => setIsOpen(open => !open)}
      >
        {optionText}
      </button>
      <button className={styles.control} onClick={onClickPlus}>
        <img src={PlusIcon} alt="More zoom" />
      </button>
      {isOpen && (
        <div className={styles.options}>
          {getOptionsOrdered().map(({ text, value }) => (
            <button className={styles.option} onClick={() => saveZoomRequest(value)} key={text}>
              {text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { ZoomOption };
