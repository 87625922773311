import React from 'react';
import { useSelector } from 'react-redux';

import { GET_POST_DATA } from 'src/actions/instagramAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import styles from './PostedDate.module.scss';

const PostedDate = () => {
  const { postedDate } = useSelector(({ instagramAnalytics }) => ({
    postedDate: instagramAnalytics.postedDate,
  }));

  const { status } = useStatus(GET_POST_DATA);

  return (
    <div className={styles.container}>
      <span className={styles.label}>Posted date</span>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {status === SUCCESS && (
        <span className={styles.postedDate}>{postedDate}</span>
      )}
      {status === ERROR && (
        <span className={styles.postedDate}>No date found</span>
      )}
    </div>
  );
};

export { PostedDate };
