import React, { useCallback } from 'react';
import { string, number } from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Image as ImageKonva } from 'react-konva';
import useImage from 'use-image';

import TipLightbulbIcon from 'src/assets/icons/tip-lightbulb.svg';
import { TIP_LIGHTBULB_PREFIX_ID } from 'src/constants/general';
import { setReadyToShowDesignTip } from 'src/actions/rulesEngineActions';
import { setCursorToDefault, setCursorToPointer } from 'src/utils/canvasHelpers';
import { TEXT_ELEMENT } from 'src/constants/canvasElements';

const LIGHTBULB_ICON_HEIGHT = 44;
const LIGHTBULB_SCALE_X = 1.5;
const LIGHTBULB_SCALE_Y = 1.5;
const LIGHTBULB_OFFSET = 12;

const CanvasTipLightbulbComponent = ({
  uuid,
  x,
  y,
  scaleX = 1,
  scaleY = 1,
  width,
  height,
  type,
}) => {
  const dispatch = useDispatch();

  const {
    showDesignTipForElementUUID,
  } = useSelector(({ rulesEngine }) => ({
    showDesignTipForElementUUID: rulesEngine.showDesignTipForElementUUID,
  }));

  const [tipLightbulbImage] = useImage(TipLightbulbIcon);

  const lightBulbClicked = useCallback(() => dispatch(
    setReadyToShowDesignTip({ status: true, uuid }),
  ),
  [dispatch, uuid]);

  const lightbulbPos = {
    x: 0,
    y: 0,
  };

  if (type === TEXT_ELEMENT) {
    lightbulbPos.x = x;
    lightbulbPos.y = y - height / 2;
  } else {
    lightbulbPos.x = x - (width * scaleX) / 2;
    lightbulbPos.y = y - (height * scaleY) / 2 -
      ((LIGHTBULB_ICON_HEIGHT - LIGHTBULB_OFFSET) * LIGHTBULB_SCALE_Y);
  }

  return (
    <ImageKonva
      image={tipLightbulbImage}
      id={`${TIP_LIGHTBULB_PREFIX_ID}_${uuid}`}
      x={lightbulbPos.x}
      y={lightbulbPos.y}
      scaleX={LIGHTBULB_SCALE_X}
      scaleY={LIGHTBULB_SCALE_Y}
      onClick={lightBulbClicked}
      onTap={lightBulbClicked}
      onMouseEnter={setCursorToPointer}
      onMouseLeave={setCursorToDefault}
      opacity={showDesignTipForElementUUID === uuid ? 1 : 0.5}
    />
  );
};

CanvasTipLightbulbComponent.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  scaleX: number,
  scaleY: number,
  width: number.isRequired,
  height: number.isRequired,
  type: string.isRequired,
};

const mapStateToProps = () => ({});

const CanvasTipLightbulb = connect(mapStateToProps)(CanvasTipLightbulbComponent);

export { CanvasTipLightbulb };
