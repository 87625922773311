import React from 'react';
import { func, oneOf, string } from 'prop-types';
import { useSelector } from 'react-redux';

import ArrowLeftWhite from 'src/assets/icons/arrow-left-white.svg';
import { useStageCenteredPosition } from 'src/hooks';
import { DESIGN_COMPONENT_TYPE } from 'src/constants/general';
import { AddObjectCombination } from '../object-combinations/add-object-combination';
import styles from './SeeAllObjects.module.scss';

const SeeAllObjects = ({ title, type, onClickBack }) => {
  const { objectCombinations } = useSelector(({ canvas }) => ({
    objectCombinations: canvas.objectCombinations,
  }));

  const position = useStageCenteredPosition();

  const objectsToShow = objectCombinations.filter(o => o.type === type);

  return (
    <div className={styles.combinations}>
      <div className={styles.header}>
        <button className={styles.goBack} onClick={onClickBack}>
          <img src={ArrowLeftWhite} alt="Go back" />
        </button>
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.content}>
        {objectsToShow.map(text => (
          <AddObjectCombination {...text} position={position} key={text.id} />
        ))}
      </div>
    </div>
  );
};

SeeAllObjects.propTypes = {
  type: oneOf(Object.values(DESIGN_COMPONENT_TYPE)),
  title: string.isRequired,
  onClickBack: func.isRequired,
};

export { SeeAllObjects };
