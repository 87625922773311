import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { AGENCY_PLANS } from 'src/constants/memberships';
import { ButtonTooltip } from 'src/common/button-tooltip';
import { DOCUMENTATION_LINK } from 'src/constants/general';
import { getUserNameToShow } from 'src/utils/helpers';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { useSession, useWindowSize, useStatus } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import { logout } from 'src/actions/userActions';
import { setAccountId } from 'src/actions/accountActions';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { reset } from 'src/actions/statusActions';
import Logo from 'src/assets/icons/logo.svg';
import SettingsIcon from 'src/assets/icons/settings.svg';
import { NavbarTabs } from './tabs';
import { SmallScreenMenu } from './small-screen-menu';
import styles from './Navbar.module.scss';

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, accountId } = useSession();

  const { windowSize: { width } } = useWindowSize();
  const isSmallScreen = useMemo(() => width <= SMALL_WIDTH, [width]);

  const { status: getBrandLibraryStatus } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  const goToProfile = useCallback(() => {
    history.push(routesPaths.profile);
  }, [history]);

  const goToProjects = useCallback(() => {
    history.push(routesPaths.projects);
  }, [history]);

  const goToAccountSettings = useCallback(() => {
    history.push(routesPaths.accountSettings);
  }, [history]);

  const logoutRequest = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const getListOfAllAccesibleAccounts = useCallback(() => {
    if (user.teams?.length > 0) {
      return user.membership.accounts.concat(user.teams.map(team => team.account));
    }
    return user.membership.accounts;
  }, [user]);

  const getAccountsSection = useCallback((withPrefix = false) => {
    if (AGENCY_PLANS.includes(user.membership?.plan) || user.teams?.length > 0) {
      return {
        groupTitle: 'Accounts',
        group: getListOfAllAccesibleAccounts()?.map(acc => {
          const prefixAttrs = withPrefix ? {
            className: cn(styles.accountOption, { [styles.activeOption]: acc.id === accountId }),
            prefix: acc.id === accountId ? <div className={styles.activeAccount} /> : null,
          } : {};
          return ({
            text: acc.brandName,
            action: () => {
              dispatch(setAccountId({ accountId: acc.id }));
              if (getBrandLibraryStatus) {
                dispatch(reset(GET_USER_WITH_BRAND_LIBRARY));
              }
            },
            ...prefixAttrs,
          });
        }),
      };
    }
    return null;
  }, [
    dispatch,
    user,
    accountId,
    getListOfAllAccesibleAccounts,
    getBrandLibraryStatus,
  ]);

  const menuOptions = useMemo(() => {
    const options = [
      { text: 'My info', action: goToProfile },
      { text: 'Documentation', href: DOCUMENTATION_LINK },
      { text: 'Sign out', action: logoutRequest, className: styles.signout },
    ];
    const accountsSection = getAccountsSection(true);
    if (accountsSection) {
      options.splice(1, 0, accountsSection);
    }
    return options;
  }, [getAccountsSection, goToProfile, logoutRequest]);

  const smallScreenMenuOptions = useMemo(() => {
    const options = [
      { text: 'Projects', action: () => history.push(routesPaths.projects) },
      { text: 'Templates', action: () => history.push(routesPaths.templates) },
      { text: 'Brand Library', action: () => history.push(routesPaths.brandLibrary) },
      { text: 'Dashboard', action: () => history.push(routesPaths.dashboard) },
      { text: 'Business Settings', action: goToAccountSettings },
      { text: 'My info', action: goToProfile },
      { text: 'Sign out', action: logoutRequest },
    ];
    const accountsSection = getAccountsSection();
    if (accountsSection) {
      options.splice(0, 0, accountsSection);
    }
    return options;
  }, [getAccountsSection, goToAccountSettings, goToProfile, history, logoutRequest]);

  const showCog = user.membership?.accounts?.find(account => account.id === accountId);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button className={styles.logoNav} onClick={goToProjects}>
          <img src={Logo} alt="Stagger logo" className={styles.logo} />
        </button>
        {!isSmallScreen && <NavbarTabs />}
      </div>
      {isSmallScreen ? (
        <SmallScreenMenu options={smallScreenMenuOptions} />
      ) : (
        <div className={styles.tabs}>
          {showCog && (
            <ButtonTooltip
              text="Business Settings"
              icon={SettingsIcon}
              onClick={goToAccountSettings}
              className={styles.settings}
              alt="Account settings"
            />
          )}
          <div className={styles.menu}>
            <CollapsibleOptions
              options={menuOptions}
              optionsClassName={styles.options}
              optionClassName={styles.option}
            >
              <span className={styles.greeting}>Hey, {getUserNameToShow(user, false)}</span>
            </CollapsibleOptions>
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
