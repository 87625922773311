const routesPaths = {
  index: '/',
  login: '/login',
  signin: '/signin-stagger',
  signup: '/signup-stagger',
  projects: '/projects',
  project: '/project/:id',
  preview: '/project/:id/preview',
  previewBreakDown: '/project/:id/preview-breakdown',
  terms: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  brandLibrary: '/brand-library',
  profile: '/profile',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password/:token',
  unsubscribeToEmail: '/unsubscribe-emails/:token',
  choosePlan: '/choose-plan',
  dashboard: '/dashboard',
  onboarding: '/onboarding',
  accountSettings: '/account-settings',
  downgradeAgency: '/downgrade-agency',
  accountInvite: '/account-invite/:token',
  templates: '/templates',
};

const publicPagesRoutesPaths = {
  index: '/',
  accountProfile: '/p/:username/:brandName?',
  closest: '/i/:userId/:accountId',
};

export { routesPaths };
export { publicPagesRoutesPaths };
