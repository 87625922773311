import React, { useCallback, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Text } from 'react-konva';
import { string, number, oneOf, shape, bool, func, object } from 'prop-types';

import { updateElement } from 'src/actions/projectActions';
import { setEditableText } from 'src/actions/canvasActions';
import {
  useMoveTransformElement,
  useKeyboardListener,
  useOnClickElement,
  useDraggableElement,
  useDecreaseCanvasSize,
  useElementColor,
} from 'src/hooks';
import { getPosDragBoundFunc, getShadowOffset } from 'src/utils/canvasHelpers';
import {
  TEXT_ALIGN,
  CANVAS_TEXT_PADDING,
  TEXT_DEFAULT_LINE_HEIGHT,
  CANVAS_DRAWING_LAYER,
} from 'src/constants/general';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { TEXT_ELEMENT } from 'src/constants/canvasElements';
import { ALIGNMENT_GUIDE_NAME } from 'src/utils/alignmentGuidesHelpers';
import { getRGBAColor } from 'src/utils/helpers';

const CanvasTextComponent = ({
  uuid,
  x,
  y,
  width,
  text,
  align,
  color,
  borderColor: { hex: borderHex } = {},
  strokeWidth,
  fontSize,
  fontFamily,
  fontStyle,
  lineHeight = TEXT_DEFAULT_LINE_HEIGHT,
  selectedElement: selectedElementId,
  editableText,
  isInGroup,
  rotation,
  selectedRef,
  scale,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  letterSpacing,
  pinchZoomGesture,
  shadowEnabled = false,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor = { alpha: 0 },
}) => {
  const dispatch = useDispatch();
  const refText = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const {
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refText, selectedRef, true);

  const moveTextElementRequest = useCallback(({ target, evt }) => {
    const { attrs } = target;
    const newAttrs = { uuid: attrs.id, x: attrs.x, y: attrs.y };
    dispatch(updateElement(newAttrs, evt.timeStamp));
    selectedElementId !== uuid && selectElementRequest({ target });
  }, [dispatch, selectElementRequest, selectedElementId, uuid]);

  const transformTextElementRequest = useCallback(({ target, evt }) => {
    const { attrs, textHeight, textArr } = target;
    const newAttrs = {
      uuid: attrs.id,
      rotation: attrs.rotation,
      width: attrs.width,
      height: (textHeight * lineHeight) * textArr.length + CANVAS_TEXT_PADDING * 2,
      x: attrs.x,
      y: attrs.y,
    };
    dispatch(updateElement(newAttrs, evt.timeStamp));
    if (!refText?.current) {
      return;
    }
    const drawingLayer = refText.current.findAncestor(`#${CANVAS_DRAWING_LAYER}`);
    const guides = drawingLayer.find(`.${ALIGNMENT_GUIDE_NAME}`);
    guides.forEach(guide => {
      guide.destroy();
    });
  }, [dispatch, lineHeight]);

  const transformText = (e) => {
    if (!refText?.current) {
      return;
    }
    refText.current.setAttrs({
      width: refText.current.attrs.width * refText.current.attrs.scaleX,
      scaleX: 1,
    });
    onTransformElement(e);
  };

  const editElement = () => {
    dispatch(setEditableText(uuid));
  };

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const onDragStart = async (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && await selectElementRequest(e);
      hideTransformerAnchors();
    }
  };

  const onDragEnd = (e) => {
    if (!isInGroup && e.evt) {
      moveTextElementRequest(e);
      showTransformerAnchors();
      onDragEndScroll();
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    selectElementRequest,
  );

  const { draggableElement } = useDraggableElement(isInGroup);

  const colorRGB = getRGBAColor(color);
  const { fillColor, borderColor, dropShadow } = useElementColor(
    selectedElementId === uuid,
    eyeDropper,
    TEXT_ELEMENT,
    { fill: colorRGB, border: borderHex, shadow: shadowColor },
  );

  useDecreaseCanvasSize(refText, isInGroup);

  return (
    <Text
      text={text}
      x={x}
      y={y}
      fontSize={fontSize}
      draggable={draggableElement}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      width={width}
      id={uuid}
      ref={refText}
      onClick={onClick}
      onTap={onClick}
      onDblClick={!isInGroup && editElement}
      onDblTap={!isInGroup && editElement}
      onTransform={!isInGroup && transformText}
      onTransformEnd={!isInGroup && transformTextElementRequest}
      padding={CANVAS_TEXT_PADDING}
      visible={editableText !== uuid}
      align={align}
      fill={fillColor}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      rotation={rotation}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      dragBoundFunc={(pos) => getPosDragBoundFunc(
        refPositions.current,
        pos,
        1 / scale,
        refText.current?.absolutePosition(),
        shiftShortcutPressed,
      )}
      shadowOpacity={dropShadow.alpha / 100}
      shadowColor={dropShadow.hex}
      shadowBlur={shadowBlur}
      shadowOffset={getShadowOffset(shadowOffsetX, shadowOffsetY)}
      shadowEnabled={shadowEnabled}
      perfectDrawEnabled={false}
      fillAfterStrokeEnabled
      strokeWidth={strokeWidth}
      stroke={borderColor}
      elementType={TEXT_ELEMENT}
      listening={!pinchZoomGesture && !eyeDropper.active}
    />
  );
};

CanvasTextComponent.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  text: string,
  align: oneOf(Object.values(TEXT_ALIGN)).isRequired,
  color: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  fontSize: number.isRequired,
  fontFamily: string.isRequired,
  fontStyle: string.isRequired,
  lineHeight: number,
  selectedElement: string,
  editableText: string,
  isInGroup: bool,
  rotation: number.isRequired,
  selectedRef: object,
  scale: number,
  eyeDropper: shape({ active: bool }),
  onTransformElement: func,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  letterSpacing: number,
  pinchZoomGesture: bool,
  shadowEnabled: bool,
  shadowOffsetX: number,
  shadowOffsetY: number,
  shadowBlur: number,
  shadowColor: shape({
    hex: string,
    alpha: number,
  }),
  borderColor: shape({
    hex: string,
    alpha: number,
  }),
  strokeWidth: number,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElement: store.project.present.selectedElements[ownProps.uuid],
  editableText: store.project.present.editableText,
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  scale: store.canvas.scale,
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const CanvasText = connect(mapStateToProps)(CanvasTextComponent);

export { CanvasText };
