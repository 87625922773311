import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UndoIconWhite from 'src/assets/icons/undo-white.svg';
import UndoIcon from 'src/assets/icons/undo.svg';
import { undo } from 'src/actions/projectActions';
import styles from './UndoRedoButtons.module.scss';

const UndoRedoButtons = () => {
  const dispatch = useDispatch();

  const { past } = useSelector(({ project }) => project);

  const callUndo = () => dispatch(undo());

  return (
    <div className={styles.container}>
      <button
        className={styles.button}
        onClick={callUndo}
        disabled={!past.length}
      >
        <img src={past.length ? UndoIconWhite : UndoIcon} alt="undo" />
      </button>
    </div>
  );
};

export { UndoRedoButtons };
