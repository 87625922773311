import React from 'react';
import { string, oneOfType, node, object, shape, func } from 'prop-types';

import styles from './ToastMarkup.module.scss';

const ToastMarkup = ({ children, emoji, action }) => (
  <div className={styles.container}>
    <div className={styles.text}>{children}</div>
    {emoji && <div className={styles.emoji}>{emoji}</div>}
    {action && <button onClick={action.onClick}>{action.label}</button>}
  </div>
);

ToastMarkup.propTypes = {
  emoji: string,
  children: oneOfType([node, object]).isRequired,
  action: shape({
    label: string,
    onClick: func,
  }),
};

export { ToastMarkup };
