import React, { useMemo, forwardRef, useEffect } from 'react';
import { Layer } from 'react-konva';
import { useSelector, useDispatch } from 'react-redux';
import { func, object } from 'prop-types';

import {
  useProjectSelector,
  useAlignmentGuides,
} from 'src/hooks';
import {
  CANVAS_DRAWING_LAYER,
  PROJECT_TYPE,
  CANVAS_TITLE_SPACE,
} from 'src/constants/general';
import { ALIGNMENT_GUIDE_NAME } from 'src/utils/alignmentGuidesHelpers';
import {
  CanvasSelection,
} from 'src/pages/project/canvas/canvas-helper-elements';
import { isHtmlTagFocused, isTouchDevice } from 'src/utils/helpers';
import { useKeyboardListener } from 'src/hooks/useKeyboardListener';
import { SPACE_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { closeRightClickOptions } from 'src/actions/canvasActions';
import { DrawingContent } from './drawing-content';
import { Separators } from './separators';
import { BleedBorders } from './bleed-borders';
import { CanvasBackground } from './background';
import { CanvasFrameTitles } from './canvas-frame-titles';
import { SelectedFrameBorders } from './selected-frame-borders';

const DrawingLayer = forwardRef(({
  refScroll,
  refSimpleBar,
  refSelection,
  onTouchMoveDrawingLayer,
}, refDrawingLayer) => {
  const dispatch = useDispatch();

  const {
    stageOffset,
    baseCanvasWidth,
    baseCanvasHeight,
    scale,
    draggableOffset,
    rightClickOptions,
  } = useSelector(({ canvas }) => ({
    stageOffset: canvas.stageOffset,
    baseCanvasWidth: canvas.baseCanvasWidth,
    baseCanvasHeight: canvas.baseCanvasHeight,
    scale: canvas.scale,
    draggableOffset: canvas.draggableOffset,
    rightClickOptions: canvas.rightClickOptions,
  }));

  const { size, type } = useProjectSelector();

  const canvasWidth = useMemo(() => baseCanvasWidth * size, [baseCanvasWidth, size]);

  const drawingLayerDragBoundFunc = (pos) => {
    pos.x = Math.min(
      Math.max(pos.x, refScroll?.current?.offsetWidth - canvasWidth),
      stageOffset.x * scale,
    );
    pos.y = Math.min(
      Math.max(pos.y, refScroll?.current?.offsetHeight + CANVAS_TITLE_SPACE - baseCanvasHeight),
      stageOffset.y * scale,
    );
    return pos;
  };

  const drawingLayerOnDragEnd = ({ target }) => {
    const { attrs: { x, y, id } } = target;
    if (id === CANVAS_DRAWING_LAYER) {
      if (refSimpleBar?.current) {
        refSimpleBar.current.getScrollElement().scrollTo(
          -x * scale,
          -y * scale + CANVAS_TITLE_SPACE,
        );
      }
    } else if (refDrawingLayer?.current) {
      const guides = refDrawingLayer.current.find(`.${ALIGNMENT_GUIDE_NAME}`);
      guides.forEach(guide => {
        guide.destroy();
      });
    }
  };

  const {
    onDragMoveElements,
    onResizeElement,
    hideTransformerAnchors,
    showTransformerAnchors,
  } = useAlignmentGuides(refDrawingLayer);

  const drawingLayerOnDragMove = (e) => {
    if (rightClickOptions) {
      dispatch(closeRightClickOptions());
    }
    onDragMoveElements(e);
  };

  const spacePressed = useKeyboardListener(SPACE_CHARACTER_CODE);

  const dragCanvasShortcut = !isHtmlTagFocused() && spacePressed;

  useEffect(() => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const stage = refDrawingLayer.current.getStage();
    if (dragCanvasShortcut) {
      stage.container().style.cursor = 'grab';
    } else {
      stage.container().style.cursor = 'default';
    }
  }, [refDrawingLayer, dragCanvasShortcut]);

  return (
    <Layer
      id={CANVAS_DRAWING_LAYER}
      ref={refDrawingLayer}
      x={stageOffset.x + draggableOffset.x}
      y={stageOffset.y + draggableOffset.y}
      draggable={isTouchDevice() || dragCanvasShortcut}
      dragBoundFunc={drawingLayerDragBoundFunc}
      onDragEnd={drawingLayerOnDragEnd}
      onDragMove={drawingLayerOnDragMove}
      onTouchMove={onTouchMoveDrawingLayer}
    >
      <CanvasBackground />
      <DrawingContent
        canvasWidth={canvasWidth}
        canvasHeight={baseCanvasHeight - CANVAS_TITLE_SPACE}
        canvasAttrs={{
          x: refDrawingLayer?.current?.attrs.x,
          y: refDrawingLayer?.current?.attrs.y,
        }}
        onTransformElement={onResizeElement}
        hideTransformerAnchors={hideTransformerAnchors}
        showTransformerAnchors={showTransformerAnchors}
        refDrawingLayer={refDrawingLayer}
      />
      <CanvasFrameTitles />
      <Separators />
      {type === PROJECT_TYPE.SIGN && <BleedBorders />}
      <SelectedFrameBorders />
      <CanvasSelection ref={refSelection} />
    </Layer>
  );
});

DrawingLayer.propTypes = {
  refScroll: object,
  refSimpleBar: object,
  refSelection: object,
  onTouchMoveDrawingLayer: func.isRequired,
};

export { DrawingLayer };
