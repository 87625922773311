import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { string, number, bool, object, arrayOf, shape, func } from 'prop-types';
import { Group } from 'react-konva';

import { CanvasCircle } from 'src/pages/project/canvas/canvas-elements/canvas-circle';
import { CanvasLine } from 'src/pages/project/canvas/canvas-elements/canvas-line';
import { CanvasImage } from 'src/pages/project/canvas/canvas-elements/canvas-image';
import { CanvasText } from 'src/pages/project/canvas/canvas-elements/canvas-text';
import { CanvasRoundedRect } from 'src/pages/project/canvas/canvas-elements/canvas-rounded-rect';
import { CanvasSquare } from 'src/pages/project/canvas/canvas-elements/canvas-square';
import { CanvasTriangle } from 'src/pages/project/canvas/canvas-elements/canvas-triangle';
import {
  useMoveTransformElement,
  useOnClickElement,
  useDraggableElement,
  useKeyboardListener,
  useDecreaseCanvasSize,
  useSession,
} from 'src/hooks';
import {
  IMAGE_ELEMENT,
  TEXT_ELEMENT,
  LINE_ELEMENT,
  CIRCLE_ELEMENT,
  SQUARE_ELEMENT,
  ROUNDED_RECT_ELEMENT,
  TRIANGLE_ELEMENT,
  GROUP_ELEMENT,
} from 'src/constants/canvasElements';
import {
  CanvasTagMarker,
} from 'src/pages/project/canvas/canvas-helper-elements';
import { ROLE_ADMIN, TAG_MARKER_PREFIX_ID } from 'src/constants/general';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { getPosDragBoundFunc } from 'src/utils/canvasHelpers';

const CanvasGroupComponent = ({
  uuid,
  x,
  y,
  width,
  height,
  unlocked,
  scaleX,
  scaleY,
  selectedElementId,
  elementsInGroup,
  isInGroup,
  rotation,
  selectedRef,
  scale,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  pinchZoomGesture,
}) => {
  const refGroup = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const { user } = useSession();

  const {
    moveElementRequest,
    transformElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refGroup, selectedRef);

  const onDragStart = (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && selectElementRequest({ target: { attrs: { id: uuid } }, origin });
      hideTransformerAnchors();
    }
  };

  const onDragEnd = (e) => {
    if (!isInGroup && e.evt) {
      moveElementRequest(e);
      showTransformerAnchors();
      onDragEndScroll();
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    ({ origin }) => selectElementRequest({ target: { attrs: { id: uuid } }, origin }),
  );

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const groupedElements = [];
  elementsInGroup && elementsInGroup.forEach(element => {
    switch (element.type) {
      case IMAGE_ELEMENT: {
        groupedElements.push(<CanvasImage key={element.uuid} {...element} isInGroup />);
        break;
      }
      case TEXT_ELEMENT: {
        groupedElements.push(<CanvasText key={element.uuid} {...element} isInGroup />);
        break;
      }
      case LINE_ELEMENT: {
        groupedElements.push(<CanvasLine key={element.uuid} {...element} isInGroup />);
        break;
      }
      case CIRCLE_ELEMENT: {
        groupedElements.push(<CanvasCircle key={element.uuid} {...element} isInGroup />);
        break;
      }
      case SQUARE_ELEMENT: {
        groupedElements.push(<CanvasSquare key={element.uuid} {...element} isInGroup />);
        break;
      }
      case ROUNDED_RECT_ELEMENT: {
        groupedElements.push(<CanvasRoundedRect key={element.uuid} {...element} isInGroup />);
        break;
      }
      case TRIANGLE_ELEMENT: {
        groupedElements.push(<CanvasTriangle key={element.uuid} {...element} isInGroup />);
        break;
      }
      case GROUP_ELEMENT: {
        groupedElements.push(<CanvasGroup key={element.uuid} {...element} isInGroup />);
        break;
      }
      default: {
        break;
      }
    }
    if (user.role.id === ROLE_ADMIN && element.tag) {
      groupedElements.push(
        <CanvasTagMarker
          key={`${TAG_MARKER_PREFIX_ID}_${element.uuid}`}
          {...element}
          eyeDropper={eyeDropper}
          isInGroup
        />,
      );
    }
  });

  const { draggableElement } = useDraggableElement(isInGroup);

  useDecreaseCanvasSize(refGroup, isInGroup);

  return (
    <Group
      id={uuid}
      ref={refGroup}
      x={x}
      y={y}
      width={width}
      height={height}
      scaleX={scaleX}
      scaleY={scaleY}
      draggable={draggableElement}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onClick={onClick}
      onTap={onClick}
      onTransformEnd={!isInGroup && transformElementRequest}
      onTransform={onTransformElement}
      rotation={rotation}
      dragBoundFunc={(pos) => getPosDragBoundFunc(
        refPositions.current,
        pos,
        1 / scale,
        refGroup.current?.absolutePosition(),
        shiftShortcutPressed,
      )}
      elementType={GROUP_ELEMENT}
      listening={!pinchZoomGesture && !eyeDropper.active}
      unlocked={unlocked}
    >
      {groupedElements}
    </Group>
  );
};

CanvasGroupComponent.propTypes = {
  uuid: string.isRequired,
  x: number,
  y: number,
  width: number,
  height: number,
  unlocked: bool.isRequired,
  scaleX: number,
  scaleY: number,
  selectedElementId: string,
  elementsInGroup: arrayOf(object),
  isInGroup: bool,
  rotation: number.isRequired,
  selectedRef: object,
  scale: number,
  eyeDropper: shape({ active: bool }),
  onTransformElement: func,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  pinchZoomGesture: bool,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElementId: store.project.present.selectedElements[ownProps.uuid],
  elementsInGroup: store.project.present.groupsOfElementsById[ownProps.uuid],
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  scale: store.canvas.scale,
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const CanvasGroup = connect(mapStateToProps)(CanvasGroupComponent);

export { CanvasGroup };
