import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { DEFAULT_PROJECT, OPEN_FULLSCREEN_ACTION, PROJECT_TYPE } from 'src/constants/general';
import { useSession, useStatus } from 'src/hooks';
import { createProject, CREATE_PROJECT } from 'src/actions/projectActions';
import { LOADING } from 'src/constants/status';
import { closeProductTip } from 'src/actions/userSettingsActions';
import styles from './WhatsNewModal.module.scss';

const WhatsNewModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { account } = useSession();

  const { status } = useStatus(CREATE_PROJECT);

  const hide = useCallback(() => {
    dispatch(closeProductTip('showWhatsNewModal'));
  }, [dispatch]);

  const onActionClick = useCallback(() => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: DEFAULT_PROJECT.LAYOUT.width,
      layoutHeight: DEFAULT_PROJECT.LAYOUT.height,
      layoutSource: DEFAULT_PROJECT.LAYOUT.source,
      type: PROJECT_TYPE.PROJECT,
    };
    const historyAction = { action: OPEN_FULLSCREEN_ACTION };
    dispatch(createProject(project, account, history, historyAction));
    hide();
  }, [account, dispatch, history, hide]);

  return (
    <Modal
      isShowing
      hide={hide}
      isEscEnabled={false}
      classNameContent={styles.modal}
      closeOnClickOutside={false}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>
            Check out our Sweat with Stagger Gallery!
          </span>
        </div>
        <div className={styles.body}>
          <span className={styles.text}>
            View photos from our event and easily select which ones you want to design a
            Creator Collage with to post to social media.
          </span>
          <img
            alt="Example frames"
            // eslint-disable-next-line max-len
            src="https://res.cloudinary.com/beta-stagger/image/upload/v1698329435/squad-event/0Z5A9260_vslvrn.jpg"
            className={styles.image}
          />
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onActionClick}
              loading={status === LOADING}
            >
              View Gallery
            </Button>
            <Button
              className={styles.buttonClose}
              onClick={hide}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { WhatsNewModal };
