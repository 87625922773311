import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool } from 'prop-types';

import LineHeightDarkIcon from 'src/assets/icons/line-height.svg';
import LetterSpacingDarkIcon from 'src/assets/icons/letter-spacing.svg';
import TextAlignLeftBlackIcon from 'src/assets/icons/text-align-left-black.svg';
import TextAlignCenterBlackIcon from 'src/assets/icons/text-align-center-black.svg';
import TextAlignRightBlackIcon from 'src/assets/icons/text-align-right-black.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import EmptyFontsIcon from 'src/assets/icons/brand-library-fonts-empty.svg';
import { addFont, ADD_FONT } from 'src/actions/brandLibraryActions';
import { DEFAULT_FONT_FAMILY, FONT_SIZE_OPTIONS, DEFAULT_FONT_STYLE } from 'src/constants/fonts';
import { TEXT_ALIGN, TEXT_DEFAULT_LINE_HEIGHT } from 'src/constants/general';
import { GOOGLE_UPLOAD } from 'src/constants/uploadFile';
import {
  FontFamilySelect,
} from 'src/pages/brand-library/brand-library-content/fonts/font-family-select';
import { FontSizeSelect } from 'src/common/font-size-select';
import { TextAlignment } from 'src/common/text-alignment';
import { ColorPicker } from 'src/common/color-picker';
import { LineHeightInput } from 'src/common/line-height-input';
import { LetterSpacingInput } from 'src/common/letter-spacing-input';
import { useStatus } from 'src/hooks';
import { SUCCESS, LOADING } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import { Spinner } from 'src/common/spinner';
import styles from './AddFont.module.scss';

const DEFAULT_VALUES = {
  name: '',
  fontFamily: DEFAULT_FONT_FAMILY.value,
  fontStyle: DEFAULT_FONT_STYLE.value,
  fontSize: FONT_SIZE_OPTIONS[7].value,
  align: TEXT_ALIGN.LEFT,
  uploadType: GOOGLE_UPLOAD,
  color: {
    hex: '#000000',
    alpha: 100,
  },
  lineHeight: TEXT_DEFAULT_LINE_HEIGHT,
};

const AddFont = ({ fontsLoaded }) => {
  const dispatch = useDispatch();

  const { disabled, id, fonts } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
    id: brandLibrary.id,
    fonts: brandLibrary.fonts,
  }));

  const [showOptions, setShowOptions] = useState(false);
  const [values, setValues] = useState(DEFAULT_VALUES);

  const updateValues = newValues => {
    setValues(v => ({ ...v, ...newValues }));
  };

  const onClose = useCallback(() => {
    setValues(DEFAULT_VALUES);
    setShowOptions(false);
  }, []);

  const addFontToBrandLibrary = () => {
    if (!values.name) {
      return;
    }
    const fontToAdd = {
      ...values,
      hex: values.color.hex,
      alpha: values.color.alpha,
    };
    dispatch(addFont(id, fontToAdd));
  };

  const onChangeFontFamily = ({ value, uploadType }) => {
    updateValues({
      fontFamily: value,
      fontStyle: DEFAULT_FONT_STYLE.value,
      uploadType,
    });
  };

  const { status } = useStatus(ADD_FONT);

  useEffect(() => {
    if (status === SUCCESS) {
      onClose();
      dispatch(reset(ADD_FONT));
    }
  }, [dispatch, onClose, status]);

  const showEmpty = fontsLoaded && !fonts?.length && !showOptions;

  return (
    <div className={styles.container}>
      {!showOptions && (
        <button className={styles.button} disabled={disabled} onClick={() => setShowOptions(true)}>
          <span className={styles.add}>+</span>
        </button>
      )}
      {showEmpty && (
        <div className={styles.emptyContainer}>
          <img src={EmptyFontsIcon} alt="Empty fonts" />
          <span className={styles.emptyTitle}>What&apos;s your type?</span>
          <span className={styles.emptyText}>
            Click the + button to create common font styles.
          </span>
        </div>
      )}
      {showOptions && (
        <>
          <div className={styles.options}>
            <input
              className={styles.name}
              placeholder="Label Name"
              value={values.name}
              onChange={({ target }) => updateValues({ name: target.value })}
            />
            <FontFamilySelect
              onChangeFontFamily={onChangeFontFamily}
              onChangeFontStyle={({ value }) => updateValues({ fontStyle: value })}
              uploadType={values.uploadType}
              fontFamily={values.fontFamily}
              fontStyle={values.fontStyle}
            />
            <FontSizeSelect
              onChange={fontSize => updateValues({ fontSize })}
              fontSize={values.fontSize}
            />
            <LetterSpacingInput
              letterSpacing={values.letterSpacing}
              onBlur={letterSpacing => updateValues({ letterSpacing })}
              onChange={letterSpacing => updateValues({ letterSpacing })}
              inputClassName={styles.letterSpacing}
              icon={LetterSpacingDarkIcon}
            />
            <LineHeightInput
              lineHeight={values.lineHeight}
              onBlur={lineHeight => updateValues({ lineHeight })}
              onChange={lineHeight => updateValues({ lineHeight })}
              inputClassName={styles.lineHeight}
              icon={LineHeightDarkIcon}
            />
            <TextAlignment
              alignment={values.align}
              changeAlignment={align => updateValues({ align })}
              iconLeftActive={TextAlignLeftBlackIcon}
              iconCenterActive={TextAlignCenterBlackIcon}
              iconRightActive={TextAlignRightBlackIcon}
            />
            <ColorPicker
              color={values.color}
              onColorChange={color => updateValues({ color })}
              className={styles.colorPicker}
            />
            <button className={styles.close} onClick={onClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <button
            className={styles.save}
            onClick={addFontToBrandLibrary}
            disabled={status === LOADING || !values.name}
          >
            {status === LOADING ? <Spinner iconClassName={styles.spinner} /> : 'Save'}
          </button>
        </>
      )}
    </div>
  );
};

AddFont.propTypes = {
  fontsLoaded: bool.isRequired,
};

export { AddFont };
