import React from 'react';
import { func, number, object } from 'prop-types';
import { useDispatch } from 'react-redux';

import { useLoading, useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { downgradePlanFromAgency, DOWNGRADE_PLAN_FROM_AGENCY } from 'src/actions/accountActions';
import { KEEP_PROJECT_IN_DOWNGRADE, DELETE_PROJECT_IN_DOWNGRADE } from 'src/constants/general';
import styles from './DowngradeConfirmation.module.scss';

const DowngradeConfirmation = ({ mainAccountId, accountIdsActions, onClickCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSession();

  const isLoading = useLoading(DOWNGRADE_PLAN_FROM_AGENCY);

  const mainAccount = user.membership.accounts.find(acc => acc.id === mainAccountId);

  const onDowngrade = () => {
    dispatch(downgradePlanFromAgency(mainAccountId, accountIdsActions));
  };

  return (
    <>
      <span className={styles.title}>Confirm your plan update.</span>
      <div className={styles.content}>
        <span className={styles.header}>
          We&apos;re about to downgrade your plan.
        </span>
        <span className={styles.subheader}>Let&apos;s review the details.</span>
        <div className={styles.note}>
          <span className={styles.noteTitle}>Important Note: </span>
          <span className={styles.noteText}>
            Your projects will be permanently deleted if you choose not to
            keep them when deleting your other accounts.
          </span>
        </div>
        <div className={styles.accountContainer}>
          <span className={styles.accountTitle}>
            New Primary Account
          </span>
          <span className={styles.brandName}>{mainAccount.brandName}</span>
        </div>
        <div className={styles.accountContainer}>
          <span className={styles.accountTitle}>
            Accounts Removed
          </span>
          {Object.entries(accountIdsActions).map(([key, value]) => {
            const account = user.membership.accounts.find(acc => acc.id === Number(key));
            return (
              <div className={styles.card} key={account.id}>
                <span className={styles.brandName}>{account.brandName}</span>
                {value === DELETE_PROJECT_IN_DOWNGRADE && (
                  <span className={styles.deleteProjects}>Delete projects</span>
                )}
                {value === KEEP_PROJECT_IN_DOWNGRADE && (
                  <span className={styles.keepProjects}>Keep projects</span>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onClickCancel}
            secondary
            disabled={isLoading}
          >
            Back
          </Button>
          <Button
            className={styles.downgradeButton}
            onClick={onDowngrade}
            alternativeCTA
            loading={isLoading}
          >
            Downgrade My Plan
          </Button>
        </div>
      </div>
    </>
  );
};

DowngradeConfirmation.propTypes = {
  mainAccountId: number.isRequired,
  accountIdsActions: object,
  onClickCancel: func.isRequired,
};

export { DowngradeConfirmation };
