import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { DEFAULT_PROJECT, PROJECT_TYPE } from 'src/constants/general';
import { SIGN_LAYOUTS } from 'src/constants/layout';
import { CLOSE_FIRST_SIGN_MODAL, closeFirstSignModal } from 'src/actions/userSettingsActions';
import { createProject, CREATE_PROJECT } from 'src/actions/projectActions';
import { useStatus, useSession } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import styles from './FirstSignModal.module.scss';

const FirstSignModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(true);

  const { account } = useSession();

  const onSignCreate = useCallback(() => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.width,
      layoutHeight: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.height,
      layoutSource: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.source,
      type: PROJECT_TYPE.SIGN,
    };
    dispatch(createProject(project, account, history));
    dispatch(closeFirstSignModal());
  }, [account, dispatch, history]);

  const { status: statusCreateSign } = useStatus(CREATE_PROJECT);
  const { status: statusClose } = useStatus(CLOSE_FIRST_SIGN_MODAL);

  const onClose = useCallback(() => {
    dispatch(closeFirstSignModal());
    setIsOpen(false);
  }, [dispatch]);

  return (
    <Modal
      isShowing={isOpen}
      hide={onClose}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          <span role="img" aria-label="celebration">🎉 </span>
          Design a Printed Sign
        </span>
        <span className={styles.subtitle}>
          You created your first flyer! Want to design a printed sign to help promote it?
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onSignCreate}
            loading={statusCreateSign === LOADING}
            alternativeCTA
          >
            Yes, I want to design a sign
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={onClose}
            loading={statusClose === LOADING}
            spinnerClassName={styles.spinnerIcon}
          >
            Maybe later
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { FirstSignModal };
