import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import chunk from 'lodash.chunk';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import {
  getCloudinaryFilenameWithoutTimestamp,
  getCloudinaryFilenameFromUrl,
} from 'src/utils/cloudinaryHelpers';
import { useWindowSize } from 'src/hooks';
import { Button } from 'src/common/button';
import { MOBILE_THRESHOLD_WIDTH, SMALL_HEIGHT } from 'src/constants/breakpoints';
import {
  addMessagesToChat,
  addProjectMediaToChat,
  answerToChat,
  askForAmountFromChat,
} from 'src/actions/collageActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import { isVideo } from 'src/utils/videoHelpers';
import { isGif } from 'src/utils/helpers';
import { UploadedMediaCard } from '../../add-media-control/uploaded-media-card';
import styles from './AddProjectMediaToChat.module.scss';

const SCROLLABLE_CONTAINER_ID = 'addProjMediaScrollableContainer';

const AddProjectMediaToChat = () => {
  const dispatch = useDispatch();

  const { uploadedMedia } = useSelector(({ canvas }) => ({
    uploadedMedia: canvas.uploadedMedia,
  }));

  const [selected, setSelected] = useState([]);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);

  const onClickAsset = image => {
    if (selected.find(elem => elem.id === image.id)) {
      const filteredList = selected.filter(elem => elem.id !== image.id);
      setSelected(filteredList);
    } else {
      setSelected([...selected, image]);
    }
  };

  const uploadImages = useCallback(() => {
    dispatch(answerToChat({
      answer: 'Yes!',
      messages: [
        { type: COLLAGE_CHAT_TYPE.UPLOAD_MEDIA, id: uuidv4() },
      ],
    }));
  }, [dispatch]);

  const createCollage = useCallback(async () => {
    await dispatch(answerToChat({ answer: 'No thanks' }));
    dispatch(askForAmountFromChat());
  }, [dispatch]);

  const addImages = async () => {
    const loadingId = `chat_${uuidv4()}`;
    await dispatch(addProjectMediaToChat({ media: selected.map(s => s.src), loadingId }));
    dispatch(addMessagesToChat({
      messages: [
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Do you want to upload new images?',
          id: uuidv4(),
        },
        {
          type: COLLAGE_CHAT_TYPE.ANSWER,
          text: 'Yes!',
          id: uuidv4(),
          onClick: uploadImages,
        },
        {
          type: COLLAGE_CHAT_TYPE.ANSWER,
          text: 'No thanks',
          id: uuidv4(),
          onClick: createCollage,
        },
      ],
    }));
    setSelected([]);
  };

  const { windowSize } = useWindowSize();

  const perPage = useMemo(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH) {
      return 6;
    }
    return windowSize.height > SMALL_HEIGHT ? 9 : 6;
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    const imageUrls = uploadedMedia.filter(media => !isVideo(media) && !isGif(media));
    const elemList = imageUrls.map(media => ({ url: media }));
    setPages(chunk(elemList, perPage));
  }, [perPage, uploadedMedia]);

  const pagesToShow = useMemo(() => {
    let result = [];
    for (let index = 0; index <= page; index++) {
      result = [...result, ...(pages[index] || [])];
    }
    return result;
  }, [page, pages]);

  const nextPage = useCallback(() => {
    setPage(p => p + 1);
  }, []);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    const totalOfElements = pages?.flat()?.length || 0;
    if (pagesToShow.length < totalOfElements &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [pages, pagesToShow, nextPage]);

  return (
    <div className={styles.container}>
      <div className={styles.results} id={SCROLLABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={pagesToShow.length || 0}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {pagesToShow.map((elem) => {
            const id = getCloudinaryFilenameFromUrl(elem.url);
            const name = getCloudinaryFilenameWithoutTimestamp(id);
            return (
              <UploadedMediaCard
                key={elem.url}
                preview={elem.url}
                id={id}
                name={name}
                addImage={onClickAsset}
                isSelected={!!selected.find(e => e.id === id)}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      {!!pages.length && (
        <div className={styles.bottom}>
          <Button
            disabled={!selected.length}
            onClick={addImages}
            className={styles.addImages}
          >
            Add images {selected.length ? `(${selected.length})` : ''}
          </Button>
        </div>
      )}
    </div>
  );
};

export { AddProjectMediaToChat };
