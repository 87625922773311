import React, { useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { number, oneOf } from 'prop-types';
import { useSelector } from 'react-redux';

import {
  CANVAS_DRAWING_CONTENT,
  CANVAS_SELECTED_FRAME_PREFIX,
  LEFT,
  RIGHT,
} from 'src/constants/general';
import { useCanvasFrameActions } from 'src/hooks';
import { setCursorToDefault, setCursorToPointer } from 'src/utils/canvasHelpers';

const AddFrame = ({ x, y, id, circleWidth }) => {
  const { selectedFrame } = useSelector(({ canvas }) => ({
    selectedFrame: canvas.selectedFrame,
  }));

  const { addFrameRequest } = useCanvasFrameActions();

  const ref = useRef();

  const onClick = () => {
    if (!ref?.current) {
      return;
    }
    const canvasDrawingContent = ref.current.getParent().findOne(`#${CANVAS_DRAWING_CONTENT}`);
    if (id === RIGHT) {
      addFrameRequest(selectedFrame + 1, canvasDrawingContent);
    } else if (id === LEFT) {
      addFrameRequest(selectedFrame, canvasDrawingContent);
    }
  };

  const marginForPlusIcon = circleWidth / 5; // Random portion of total width

  return (
    <Group
      onClick={onClick}
      onTap={onClick}
      ref={ref}
      id={`${CANVAS_SELECTED_FRAME_PREFIX}Plus_${id}`}
      onMouseEnter={setCursorToPointer}
      onMouseLeave={setCursorToDefault}
    >
      <Circle
        fill="#276DF1"
        x={x}
        y={y}
        height={circleWidth}
        width={circleWidth}
      />
      <Line
        points={[
          x - (circleWidth / 2) + marginForPlusIcon,
          y,
          x + (circleWidth / 2) - marginForPlusIcon,
          y,
        ]}
        stroke="#FFFFFF"
        strokeWidth={4}
      />
      <Line
        points={[
          x,
          y - (circleWidth / 2) + marginForPlusIcon,
          x,
          y + (circleWidth / 2) - marginForPlusIcon,
        ]}
        stroke="#FFFFFF"
        strokeWidth={4}
      />
    </Group>
  );
};

AddFrame.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  id: oneOf([LEFT, RIGHT]).isRequired,
  circleWidth: number.isRequired,
};

export { AddFrame };
