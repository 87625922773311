import React from 'react';
import { bool, func } from 'prop-types';

import { Button } from 'src/common/button';
import styles from './OrderSignButton.module.scss';

const OrderSignButton = ({ exportAndOrder, disabled }) => (
  <Button
    disabled={disabled}
    onClick={exportAndOrder}
    className={styles.button}
    alternativeCTA
    short
  >
    Order
  </Button>
);

OrderSignButton.propTypes = {
  disabled: bool.isRequired,
  exportAndOrder: func.isRequired,
};

export { OrderSignButton };
