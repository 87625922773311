import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useProjectSelector, usePasteImageInCanvas } from 'src/hooks';
import { isHtmlTagFocused } from 'src/utils/helpers';
import { copyPasteElements } from 'src/actions/projectActions';

const useCopyPasteShortcuts = () => {
  const dispatch = useDispatch();

  const [copiedElements, setCopiedElements] = useState([]);

  const { addImageInCanvas } = usePasteImageInCanvas();

  const { elements, selectedElements } = useProjectSelector();

  const onPaste = useCallback(async () => {
    if (isHtmlTagFocused()) {
      return;
    }
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems?.length) {
        const [item] = clipboardItems;
        const hasImage = item.types.find(type => type === 'image/png');
        if (hasImage) {
          item.getType('image/png').then(blob => addImageInCanvas(blob));
        } else if (copiedElements?.length) {
          dispatch(copyPasteElements({ copiedElements }));
        }
      } else if (copiedElements?.length) {
        dispatch(copyPasteElements({ copiedElements }));
      }
    } catch (e) {
      if (copiedElements?.length) {
        dispatch(copyPasteElements({ copiedElements }));
      }
    }
  }, [addImageInCanvas, copiedElements, dispatch]);

  const emptyClipboard = useCallback(() => {
    navigator.clipboard.writeText(' ');
  }, []);

  const onCopy = useCallback(() => {
    if (isHtmlTagFocused()) {
      return;
    }
    const uuidOfSelectedElements = Object.keys(selectedElements);
    const elementsToCopy = elements.filter(elem => (
      uuidOfSelectedElements.find(selected => elem.uuid === selected)));
    emptyClipboard();
    setCopiedElements(elementsToCopy);
  }, [elements, emptyClipboard, selectedElements]);

  useEffect(() => {
    window.addEventListener('copy', onCopy);
    window.addEventListener('paste', onPaste);
    return () => {
      window.removeEventListener('copy', onCopy);
      window.removeEventListener('paste', onPaste);
    };
  }, [onPaste, onCopy]);

  return { onCopy, onPaste, copiedElements };
};

export { useCopyPasteShortcuts };
