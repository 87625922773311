import React from 'react';

import styles from './Pulse.module.scss';

const Pulse = () => (
  <span>
    <span className={styles.ripple} />
    <span className={styles.ripple} />
    <span className={styles.ripple} />
  </span>
);

export { Pulse };
