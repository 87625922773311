import React, { useCallback } from 'react';
import { func, bool, object, arrayOf } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { applyTemplateToProject } from 'src/actions/productTemplateActions';
import { saveSelectedTemplatePreview } from 'src/actions/templatesActions';
import { changeControlOpen } from 'src/actions/canvasActions';
import { applySuggestionToProject } from 'src/actions/insightActions';
import styles from './TemplateMediaModal.module.scss';

const TemplateMediaModal = ({ media, isShowing, hide, template, suggestion }) => {
  const dispatch = useDispatch();

  const applyTemplate = useCallback((mediaToKeep) => {
    if (suggestion) {
      dispatch(applySuggestionToProject(suggestion, mediaToKeep));
    } else {
      dispatch(applyTemplateToProject(template, mediaToKeep));
      dispatch(saveSelectedTemplatePreview(undefined));
    }
    dispatch(changeControlOpen(''));
    hide();
  }, [dispatch, hide, suggestion, template]);

  const keepMediaRequest = useCallback(() => {
    applyTemplate(media);
  }, [applyTemplate, media]);

  const removeMediaRequest = useCallback(() => {
    applyTemplate();
  }, [applyTemplate]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Canvas media
        </span>
        <span className={styles.subtitle}>
          You have existing media on your canvas. Would you like to keep
          it for this template?
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={removeMediaRequest}
            secondary
          >
            Remove it
          </Button>
          <Button
            className={styles.button}
            onClick={keepMediaRequest}
            alternativeCTA
          >
            Keep Media
          </Button>
        </div>
      </div>
    </Modal>
  );
};

TemplateMediaModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  media: arrayOf(object),
  template: object,
  suggestion: object,
};

export { TemplateMediaModal };
