import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool, object } from 'prop-types';

import { Modal } from 'src/common/modal';
import { SaveTemplateForm } from 'src/pages/project/toolbar/save-template-form';
import { SAVE_TEMPLATE, saveTemplateReset } from 'src/actions/productTemplateActions';
import { useStatus, useSession } from 'src/hooks';
import { ROLE_ADMIN } from 'src/constants/general';
import { SUCCESS } from 'src/constants/status';
import styles from './SaveTemplateModal.module.scss';

const SaveTemplateModal = ({
  isShowing,
  isEdit = false,
  onClose,
  onSaveOrEditTemplate,
  selectedTemplate,
}) => {
  const dispatch = useDispatch();
  const { status } = useStatus(SAVE_TEMPLATE);

  const { user } = useSession();

  const isAdmin = ROLE_ADMIN === user.role?.id;

  useEffect(() => {
    if (status === SUCCESS) {
      onClose();
      dispatch(saveTemplateReset());
    }
  }, [dispatch, onClose, status]);

  return (
    <Modal isShowing={isShowing} hide={onClose} isEscEnabled={false} curvedContainer>
      <div className={styles.content}>
        <span className={styles.title}>Save as Product Template</span>
        <span className={styles.subtitle}>
          {isAdmin ?
            'This template will be saved to the public library for use.' :
            'This template will be saved to your private library for use.'}
        </span>
        <SaveTemplateForm
          selectedTemplate={selectedTemplate}
          onClose={onClose}
          onSaveOrEditTemplate={onSaveOrEditTemplate}
          buttonLabel={isEdit ? 'Save Changes' : 'Create Template'}
        />
      </div>
    </Modal>
  );
};

SaveTemplateModal.propTypes = {
  isShowing: bool.isRequired,
  isEdit: bool,
  onClose: func.isRequired,
  onSaveOrEditTemplate: func.isRequired,
  selectedTemplate: object,
};

export { SaveTemplateModal };
