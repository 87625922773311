import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import OverflowIcon from 'src/assets/icons/overflow-white.svg';
import { TEXT_ELEMENT, SHAPES_ELEMENTS, IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { TextControl } from '../text-control';
import { ShapeControl } from '../shape-control';
import { ImageControl } from '../image-control';
import { SecondaryTools } from '../secondary-tools';
import { MultipleSelectControl } from './multiple-select-control';
import styles from './ElementControl.module.scss';

const ElementControl = () => {
  const {
    editableText,
    elements,
    selectedRefs,
    selectedElements,
    editableImage,
  } = useProjectSelector();

  const { selectingMultipleMobile } = useSelector(({ canvas }) => ({
    selectingMultipleMobile: canvas.selectingMultipleMobile,
  }));

  const selectedElementsList = Object.values(selectedRefs).map(r => r?.current);
  let selectedElementType;
  let selectedElementIsUnlocked = false;

  if (selectedElementsList.length === 1) {
    const selectedElementStore = elements.find(
      element => element?.uuid === selectedElementsList[0]?.attrs?.id,
    );
    selectedElementType = selectedElementStore?.type;
    selectedElementIsUnlocked = selectedElementStore?.unlocked;
  } else if (selectedElementsList.length > 1) {
    const firstElemType = SHAPES_ELEMENTS.includes(selectedElementsList[0]?.attrs?.elementType) ?
      'SHAPE_ELEMENT' :
      selectedElementsList[0]?.attrs?.elementType;
    const areAllSameType = selectedElementsList.every(elem => {
      if (SHAPES_ELEMENTS.includes(elem?.attrs?.elementType)) {
        return firstElemType === 'SHAPE_ELEMENT';
      }
      return elem?.attrs?.elementType === firstElemType;
    });
    if (areAllSameType) {
      selectedElementType = selectedElementsList[0]?.attrs?.elementType;
    }
  }

  const { windowSize: { width } } = useWindowSize();
  const isSmallScreen = useMemo(() => width <= SMALL_WIDTH, [width]);

  const [collapsed, setCollapsed] = useState(true);

  const closeOptions = useCallback(() => {
    setCollapsed(true);
  }, []);

  useEffect(() => {
    // Close collapsed options when selecting element
    closeOptions();
  }, [selectedElements, closeOptions]);

  const parseControls = () => {
    if (selectingMultipleMobile) {
      return <MultipleSelectControl />;
    }
    if (SHAPES_ELEMENTS.includes(selectedElementType)) {
      return <ShapeControl />;
    }
    if (selectedElementType === IMAGE_ELEMENT) {
      return <ImageControl />;
    }
    if (selectedElementType === TEXT_ELEMENT) {
      return <TextControl />;
    }
    return <div />;
  };

  return (
    <div className={styles.container}>
      {parseControls()}
      {!!selectedElementsList.length && !editableImage && !selectingMultipleMobile && (
        <div className={styles.collapseContainer}>
          <button className={styles.collapseButton} onClick={() => setCollapsed(c => !c)}>
            {isSmallScreen ? <img src={OverflowIcon} alt="More options" /> : 'More options'}
          </button>
          {!collapsed && (
            <div className={styles.overflowContainer}>
              <SecondaryTools
                disabled={!!editableText}
                selectedElementIsUnlocked={selectedElementIsUnlocked}
                closeOptions={closeOptions}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { ElementControl };
