import React from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import ArrowLeft from 'src/assets/icons/arrow-left-black.svg';
import ArrowRight from 'src/assets/icons/arrow-right-black.svg';
import ArrowLeftDisabled from 'src/assets/icons/arrow-left.svg';
import ArrowRightDisabled from 'src/assets/icons/arrow-right.svg';
import styles from './SeeAllNavigation.module.scss';

const SeeAllNavigation = ({
  name,
  goToSeeAll,
  next,
  prev,
  isPrevDisabled,
  isNextPageDisabled,
  seeAllButtonClassName,
  arrowLeft = ArrowLeft,
  arrowRight = ArrowRight,
  templateTextClassName,
}) => (
  <div className={styles.templatesHeader}>
    <span className={cn(styles.categoryHeader, templateTextClassName)}>{name}</span>
    <div className={styles.pagination}>
      <button
        className={cn(styles.seeAll, seeAllButtonClassName)}
        onClick={goToSeeAll}
      >
        See All
      </button>
      <button
        className={styles.button}
        disabled={isPrevDisabled}
        onClick={prev}
      >
        <img
          className={styles.imageIcon}
          src={isPrevDisabled ? ArrowLeftDisabled : arrowLeft}
          alt="Arrow Left"
        />
      </button>
      <button
        className={styles.button}
        disabled={isNextPageDisabled}
        onClick={next}
      >
        <img
          className={styles.imageIcon}
          src={isNextPageDisabled ? ArrowRightDisabled : arrowRight}
          alt="Arrow Right"
        />
      </button>
    </div>
  </div>
);

SeeAllNavigation.propTypes = {
  name: string.isRequired,
  goToSeeAll: func.isRequired,
  next: func.isRequired,
  prev: func.isRequired,
  isPrevDisabled: bool.isRequired,
  isNextPageDisabled: bool.isRequired,
  seeAllButtonClassName: string,
  templateTextClassName: string,
  arrowLeft: string,
  arrowRight: string,
};

export { SeeAllNavigation };
