import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { func, string, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'src/common/input';
import { SelectCreator } from 'src/common/select-creator';
import { Select } from 'src/common/select';
import { CheckBox } from 'src/common/check-box';
import { TextArea } from 'src/common/text-area';
import { Button } from 'src/common/button';
import { validateCreateTemplate } from 'src/utils/constrains';
import {
  SAVE_TEMPLATE,
  getCategories,
  getKeywords,
  GET_CATEGORIES,
} from 'src/actions/productTemplateActions';
import {
  useForm,
  useTextInputProps,
  useStatus,
  useProjectSelector,
  useSession,
} from 'src/hooks';
import { ROLE_ADMIN, VISIBILITY, CUSTOM_TEMPLATE_CATEGORY } from 'src/constants/general';
import { LOADING, SUCCESS } from 'src/constants/status';
import styles from './SaveTemplateForm.module.scss';

const VISIBILITY_OPTIONS = Object.values(VISIBILITY).map(value => (
  { value, label: value }
));

const SaveTemplateForm = ({ onClose, onSaveOrEditTemplate, buttonLabel, selectedTemplate }) => {
  const dispatch = useDispatch();

  const { status: statusSave } = useStatus(SAVE_TEMPLATE);
  const { status: statusGetCategories } = useStatus(GET_CATEGORIES);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getKeywords());
  }, [dispatch]);

  const { user } = useSession();

  const isAdmin = ROLE_ADMIN === user.role?.id;

  const {
    categoryNames,
    keywordsTags,
  } = useSelector(({ productTemplate }) => ({
    categoryNames: productTemplate.categoryNames,
    keywordsTags: productTemplate.keywordsTags,
  }));

  const {
    size,
    color,
    layout,
    elements,
    groupsOfElementsById,
    videoIdsByPosition,
    gifIdsByPosition,
  } = useProjectSelector();

  const [tagBackgroundChecked, setTagBackgroundChecked] = useState(false);

  const createTemplateRequest = useCallback((values) => {
    const keywords = values.keywords?.map((keyword) => keyword.label);
    const projectId = selectedTemplate.project.id;
    const tagBackground = tagBackgroundChecked ? { category: 'color' } : null;
    const data = {
      name: values.name,
      description: values.description,
      categoryName: values.templateCategory.label,
      keywords,
      size,
      color: color.hex,
      alpha: color.alpha,
      layoutWidth: layout.width,
      layoutHeight: layout.height,
      layoutSource: layout.source,
      elements,
      groupsOfElementsById,
      videoIdsByPosition,
      gifIdsByPosition,
      tagBackground,
      visibility: values.visibility.label,
    };
    onSaveOrEditTemplate(data, projectId);
  }, [color.alpha, color.hex, elements, groupsOfElementsById, layout.height, layout.width,
    layout.source, onSaveOrEditTemplate, selectedTemplate.project.id, size,
    videoIdsByPosition, tagBackgroundChecked, gifIdsByPosition]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(createTemplateRequest, { validate: validateCreateTemplate });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const categoryOptions = useMemo(() => {
    const formattedCategories = Object.values(categoryNames).map((categoriesName) => {
      const option = {
        label: categoriesName.name,
        value: categoriesName,
      };
      return option;
    });
    return formattedCategories;
  }, [categoryNames]);

  const categoryOptionsToShow = useMemo(() => {
    if (values?.visibility?.value === VISIBILITY.PUBLIC) {
      return categoryOptions.filter(cat => cat.label !== CUSTOM_TEMPLATE_CATEGORY);
    }
    if (values?.visibility?.value === VISIBILITY.PRIVATE) {
      return categoryOptions.filter(cat => cat.label === CUSTOM_TEMPLATE_CATEGORY);
    }
    return categoryOptions;
  }, [categoryOptions, values]);

  useEffect(() => {
    if (selectedTemplate.name) {
      handleInputChange('name', selectedTemplate.name);
    }
    if (selectedTemplate.description) {
      handleInputChange('description', selectedTemplate.description);
    }
    const selectedName = categoryNames[selectedTemplate.category.id]?.name;
    if (selectedTemplate.category.id && selectedName) {
      const newSelectedCategory = {
        id: selectedTemplate.category.id,
        label: selectedName,
        value: {
          id: selectedTemplate.category.id,
          name: selectedName,
        },
      };
      handleInputChange('templateCategory', newSelectedCategory);
    }
    if (selectedTemplate.visibility) {
      const newVisibility = VISIBILITY_OPTIONS.find(v => v.value === selectedTemplate.visibility);
      handleInputChange('visibility', newVisibility);
    } else {
      const newVisibility = isAdmin ?
        VISIBILITY_OPTIONS.find(v => v.value === VISIBILITY.PUBLIC) :
        VISIBILITY_OPTIONS.find(v => v.value === VISIBILITY.PRIVATE);
      handleInputChange('visibility', newVisibility);
    }
    if (selectedTemplate.tagBackground) {
      setTagBackgroundChecked(true);
    }
    if (selectedTemplate.keywords) {
      handleInputChange('keywords', selectedTemplate.keywords);
    }
  }, [categoryNames, handleInputChange, isAdmin, selectedTemplate]);

  useEffect(() => {
    if (statusGetCategories === SUCCESS && !isAdmin && !selectedTemplate.category.id) {
      const customCategory = Object.values(categoryNames).find(cat => (
        cat.name === CUSTOM_TEMPLATE_CATEGORY
      ));
      const newSelectedCategory = {
        id: customCategory.id,
        label: customCategory.name,
        value: customCategory,
      };
      handleInputChange('templateCategory', newSelectedCategory);
    }
  }, [categoryNames, handleInputChange, isAdmin, selectedTemplate, statusGetCategories]);

  const keywordOptions = useMemo(() => {
    const list = Object.values(keywordsTags)?.filter(keyword => (
      !values.keywords?.map(item => item.id).includes(keyword.id)
    ));
    return list?.map((keyword) => {
      const option = {
        label: keyword.tag,
        value: keyword.id,
      };
      return option;
    });
  }, [keywordsTags, values]);

  const handleChangeCategory = (newValue, actionMeta) => {
    if (actionMeta.action !== 'clear') {
      handleInputChange('templateCategory', newValue);
    }
  };

  const handleChangeKeywords = (newValue, actionMeta) => {
    if (actionMeta.action === 'remove-value') {
      const keywordsUpdate = values.keywords
        .filter(item => item.label !== actionMeta.removedValue.label);
      handleInputChange('keywords', keywordsUpdate);
    } else if (actionMeta.action === 'clear') {
      handleInputChange('keywords', []);
    } else {
      handleInputChange('keywords', newValue);
    }
  };

  const handleChangeVisibility = (item) => {
    if (item.value === VISIBILITY.PUBLIC) {
      const randomCategory = categoryOptions.find(cat => cat.label !== CUSTOM_TEMPLATE_CATEGORY);
      handleInputChange('templateCategory', randomCategory);
    } else if (item.value === VISIBILITY.PRIVATE) {
      const customCategory = categoryOptions.find(cat => cat.label === CUSTOM_TEMPLATE_CATEGORY);
      handleInputChange('templateCategory', customCategory);
    }
    handleInputChange('visibility', item);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Input
        name="name"
        type="name"
        placeholder="Name"
        label="Template Name"
        {...inputProps('name')}
        containerClassName={styles.inputContainer}
      />
      <SelectCreator
        options={categoryOptionsToShow}
        label="Template Category"
        name="templateCategory"
        placeholder="Select or enter category..."
        {...inputProps('templateCategory')}
        containerClassName={styles.inputContainer}
        onChange={handleChangeCategory}
        disabled={values?.visibility?.value === VISIBILITY.PRIVATE}
      />
      <TextArea
        name="description"
        placeholder="Description"
        label="Description of Template"
        inputBox
        {...inputProps('description')}
        containerClassName={styles.inputContainer}
      />
      <SelectCreator
        name="keywords"
        placeholder={isAdmin ? 'Select or enter tag...' : 'Select tag...'}
        label="Tags"
        isMulti
        containerClassName={styles.inputContainer}
        options={keywordOptions}
        {...inputProps('keywords')}
        onChange={handleChangeKeywords}
        acceptNewOption={isAdmin}
      />
      {isAdmin && (
        <>
          <div className={styles.inputContainer}>
            <Select
              name="visibility"
              label="Visibility"
              options={VISIBILITY_OPTIONS}
              {...inputProps('visibility')}
              onChange={handleChangeVisibility}
              value={values?.visibility || undefined}
              closeMenuOnSelect
            />
          </div>
          <div className={styles.checkboxContainer}>
            <CheckBox
              value=""
              isSelected={tagBackgroundChecked}
              onChange={() => setTagBackgroundChecked(t => !t)}
            />
            <span className={styles.checkboxText}>Tag canvas background as changeable</span>
          </div>
        </>
      )}
      <div className={styles.submitContainer}>
        <Button
          type="submit"
          className={styles.button}
          loading={statusSave === LOADING}
          alternativeCTA
        >
          {buttonLabel}
        </Button>
        <Button
          className={styles.buttonCancel}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

SaveTemplateForm.propTypes = {
  onClose: func.isRequired,
  buttonLabel: string.isRequired,
  onSaveOrEditTemplate: func.isRequired,
  selectedTemplate: object,
};

SaveTemplateForm.defaultProps = {
  selectedTemplate: {
    keywords: [],
    category: {
      id: '',
    },
    project: {
      id: undefined,
    },
  },
};

export { SaveTemplateForm };
