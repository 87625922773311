import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';

import { useProjectSelector, useSession } from 'src/hooks';
import { areSelectedElementsDeleteable } from 'src/utils/canvasHelpers';
import {
  moveElementBackward,
  moveElementForward,
  lockElement,
  unlockElement,
  deleteElement,
  addGroupElement,
  ungroupElements,
} from 'src/actions/projectActions';
import {
  setReplaceableImageRightClick,
  setEditableImage,
  setEditableTag,
  setEditableHyperlink,
} from 'src/actions/canvasActions';
import { ROLE_ADMIN } from 'src/constants/general';
import MoveBackwardIcon from 'src/assets/icons/move-backward-tool.svg';
import MoveBackwardWhiteIcon from 'src/assets/icons/move-backward-tool-white.svg';
import ReplaceImageIcon from 'src/assets/icons/replace-image-tool.svg';
import ReplaceImageWhiteIcon from 'src/assets/icons/replace-image-tool-white.svg';
import MoveForwardIcon from 'src/assets/icons/move-forward-tool.svg';
import MoveForwardWhiteIcon from 'src/assets/icons/move-forward-tool-white.svg';
import LinkIcon from 'src/assets/icons/link-tool.svg';
import LinkIconWhite from 'src/assets/icons/link-tool-white.svg';
import LockIcon from 'src/assets/icons/lock-tool.svg';
import LockWhiteIcon from 'src/assets/icons/lock-tool-white.svg';
import UnlockIcon from 'src/assets/icons/unlock-tool.svg';
import UnlockWhiteIcon from 'src/assets/icons/unlock-tool-white.svg';
import DeleteIcon from 'src/assets/icons/delete-tool.svg';
import DeleteIconWhite from 'src/assets/icons/delete-tool-white.svg';
import GroupIcon from 'src/assets/icons/group-tool.svg';
import GroupIconWhite from 'src/assets/icons/group-tool-white.svg';
import UngroupIconWhite from 'src/assets/icons/ungroup-tool-white.svg';
import MaskIcon from 'src/assets/icons/mask-tool.svg';
import MaskIconWhite from 'src/assets/icons/mask-tool-white.svg';
import TagIcon from 'src/assets/icons/tag.svg';
import TagIconWhite from 'src/assets/icons/tag-white.svg';
import { isGif } from 'src/utils/helpers';
import {
  GROUP_ELEMENT,
  VIDEO_ELEMENT,
  IMAGE_ELEMENT,
} from 'src/constants/canvasElements';
import { ButtonSecondaryTools } from './button-secondary-tools';
import styles from './SecondaryTools.module.scss';

const SecondaryTools = ({ disabled, selectedElementIsUnlocked, closeOptions }) => {
  const dispatch = useDispatch();

  const {
    elements,
    selectedElements,
    editableImage,
    groupsOfElementsById,
    type,
  } = useProjectSelector();

  const { user } = useSession();

  const selectedElementsList = Object.keys(selectedElements);
  const moreThanOneSelected = selectedElementsList.length > 1;
  let selectedElementStore;
  let selectedElementType;


  if (selectedElementsList.length === 1) {
    selectedElementStore = elements.find(element => element?.uuid === selectedElementsList[0]);
    selectedElementType = selectedElementStore?.type;
  }

  const moveElementBackwardRequest = useCallback(() => {
    dispatch(moveElementBackward());
  }, [dispatch]);

  const moveElementForwardRequest = useCallback(() => {
    dispatch(moveElementForward());
  }, [dispatch]);

  const lockElementRequest = useCallback(() => {
    dispatch(lockElement());
  }, [dispatch]);

  const unlockElementRequest = useCallback(() => {
    dispatch(unlockElement());
  }, [dispatch]);

  const deleteElementRequest = useCallback(() => {
    dispatch(deleteElement());
  }, [dispatch]);

  const groupElementsRequest = useCallback(() => {
    dispatch(addGroupElement());
  }, [dispatch]);

  const ungroupElementsRequest = useCallback(() => {
    dispatch(ungroupElements(selectedElementStore?.uuid));
  }, [dispatch, selectedElementStore]);

  const enterMaskModeRequest = useCallback(() => {
    if (!editableImage) {
      dispatch(setEditableImage({ uuid: selectedElementStore?.uuid }));
      closeOptions();
    }
  }, [dispatch, selectedElementStore, editableImage, closeOptions]);

  const replaceImage = useCallback(() => {
    dispatch(setReplaceableImageRightClick({
      attrs: {
        ...selectedElementStore,
      },
    }));
    closeOptions();
  }, [dispatch, selectedElementStore, closeOptions]);

  const enterTagModeRequest = useCallback(() => {
    dispatch(setEditableTag({ uuid: selectedElementStore?.uuid }));
  }, [dispatch, selectedElementStore]);

  const linkRequest = useCallback(() => {
    dispatch(setEditableHyperlink(selectedElementStore?.uuid));
    closeOptions();
  }, [dispatch, selectedElementStore, closeOptions]);

  const isLockDisabled = disabled || moreThanOneSelected;

  const isLinkDisabled = disabled || moreThanOneSelected || selectedElementType === VIDEO_ELEMENT;

  const isTagDisabled = disabled || moreThanOneSelected || selectedElementType === GROUP_ELEMENT ||
    selectedElementType === VIDEO_ELEMENT ||
    (selectedElementType === IMAGE_ELEMENT && isGif(selectedElementStore.src));

  const isGroupDisabled = useMemo(() => {
    const hasVideoOrGif = selectedElementsList.reduce((res, selectedElemId) => {
      const selectedElement = elements.find(elem => elem.uuid === selectedElemId);
      return res || selectedElement?.type === VIDEO_ELEMENT ||
      (selectedElement?.type === IMAGE_ELEMENT && isGif(selectedElement?.src));
    }, false);
    return disabled || !moreThanOneSelected || (moreThanOneSelected && hasVideoOrGif);
  }, [disabled, elements, moreThanOneSelected, selectedElementsList]);

  const multipleElementsDeleteable = areSelectedElementsDeleteable(
    selectedElements,
    elements,
    groupsOfElementsById,
    type,
  );

  return (
    <div className={styles.container}>
      {ROLE_ADMIN === user.role.id && (
        <ButtonSecondaryTools
          text="Tag"
          icon={isTagDisabled ? TagIcon : TagIconWhite}
          onClick={enterTagModeRequest}
          className={`${styles.button} ${styles.tagButton}`}
          disabled={isTagDisabled}
          alt="tag"
        />
      )}
      <ButtonSecondaryTools
        text="Move forward"
        icon={disabled || moreThanOneSelected ? MoveForwardIcon : MoveForwardWhiteIcon}
        onClick={moveElementForwardRequest}
        className={styles.button}
        disabled={disabled || moreThanOneSelected}
        alt="move forward"
      />
      <ButtonSecondaryTools
        text="Move backward"
        icon={disabled || moreThanOneSelected ? MoveBackwardIcon : MoveBackwardWhiteIcon}
        onClick={moveElementBackwardRequest}
        className={styles.button}
        disabled={disabled || moreThanOneSelected}
        alt="move backward"
      />
      {selectedElementType === IMAGE_ELEMENT && (
        <>
          <ButtonSecondaryTools
            text="Change image"
            icon={disabled || moreThanOneSelected || !selectedElementIsUnlocked ?
              ReplaceImageIcon : ReplaceImageWhiteIcon}
            onClick={replaceImage}
            className={styles.button}
            disabled={disabled || !selectedElementIsUnlocked}
            alt="change image"
          />
          <ButtonSecondaryTools
            text="Mask image"
            icon={disabled || moreThanOneSelected || !selectedElementIsUnlocked ?
              MaskIcon : MaskIconWhite}
            onClick={enterMaskModeRequest}
            className={styles.button}
            disabled={disabled || !selectedElementIsUnlocked}
            alt="mask image"
          />
        </>
      )}
      <ButtonSecondaryTools
        text="Hyperlink"
        icon={isLinkDisabled ? LinkIcon : LinkIconWhite}
        onClick={linkRequest}
        className={styles.button}
        disabled={isLinkDisabled}
        alt="link"
      />
      {selectedElementIsUnlocked ? (
        <ButtonSecondaryTools
          text="Lock"
          onClick={lockElementRequest}
          icon={isLockDisabled ? LockIcon : LockWhiteIcon}
          className={styles.button}
          disabled={isLockDisabled}
          alt="lock"
        />
      )
        : (
          <ButtonSecondaryTools
            text="Unlock"
            onClick={unlockElementRequest}
            icon={isLockDisabled ? UnlockIcon : UnlockWhiteIcon}
            className={styles.button}
            disabled={isLockDisabled}
            alt="unlock"
          />
        )}
      {(selectedElementType !== GROUP_ELEMENT || moreThanOneSelected) && (
        <ButtonSecondaryTools
          text="Group"
          icon={isGroupDisabled ? GroupIcon : GroupIconWhite}
          onClick={isGroupDisabled ? () => {} : groupElementsRequest}
          className={styles.button}
          disabled={isGroupDisabled}
          alt="group"
        />
      )}
      {selectedElementType === GROUP_ELEMENT && !moreThanOneSelected && (
        <ButtonSecondaryTools
          text="Ungroup"
          icon={UngroupIconWhite}
          onClick={ungroupElementsRequest}
          className={styles.button}
          disabled={disabled}
          alt="ungroup"
        />
      )}
      <ButtonSecondaryTools
        text="Delete"
        icon={disabled || !multipleElementsDeleteable ? DeleteIcon : DeleteIconWhite}
        onClick={deleteElementRequest}
        className={`${styles.button} ${styles.deleteButton}`}
        disabled={disabled || !multipleElementsDeleteable}
        alt="delete"
      />
    </div>
  );
};

SecondaryTools.propTypes = {
  disabled: bool.isRequired,
  selectedElementIsUnlocked: bool,
  closeOptions: func.isRequired,
};

export { SecondaryTools };
