import React from 'react';
import { string } from 'prop-types';

import FacebookIcon from './facebook.svg';
import styles from './FacebookButton.module.scss';

const FacebookButton = ({ text, createdOnCampaign }) => {
  const campaign = createdOnCampaign ? `/${createdOnCampaign}` : '';

  return (
    <a
      href={`${process.env.REACT_APP_URL_BASE_API}/users/login/facebook${campaign}`}
      className={styles.button}
    >
      <img src={FacebookIcon} alt="Facebook signup" />
      <span className={styles.text}>{text}</span>
      <div />
    </a>
  );
};

FacebookButton.propTypes = {
  text: string.isRequired,
  createdOnCampaign: string,
};

export { FacebookButton };
