import React, { useCallback, forwardRef, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool } from 'prop-types';

import { Input } from 'src/common/input';
import { TextArea } from 'src/common/text-area';
import { useForm, useSession, useTextInputProps } from 'src/hooks';
import { saveOnboardingStep } from 'src/actions/accountActions';
import { checkForChanges, getDirtyFields } from 'src/utils/formHelpers';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import { StepsSelect } from '../steps-select';
import styles from './Step1BusinessInfo.module.scss';

const Step1BusinessInfo = forwardRef(({
  setFormIsDirty = () => {},
  fromProfile = false,
  setUpdatedFields = () => {},
}, ref) => {
  const dispatch = useDispatch();

  const { onboarding: { businessInfo }, accountId, account } = useSession();
  const { brandAssetStatus } = account || {};

  const initialValues = useMemo(() => ({
    name: businessInfo?.name,
    employees: businessInfo?.employees,
    description: businessInfo?.description,
    website: businessInfo?.website,
  }), [businessInfo]);

  const saveOnboardingStepRequest = useCallback(async (values) => {
    await dispatch(saveOnboardingStep(1, values, accountId));
    setUpdatedFields(getDirtyFields(initialValues, values));
  }, [dispatch, setUpdatedFields, initialValues, accountId]);

  const {
    values,
    setValues,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(saveOnboardingStepRequest, { initialValues });

  useEffect(() => {
    setValues(initialValues);
  }, [setValues, initialValues]);

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const selectOnChange = useCallback((name, value) => {
    handleInputChange(name, value);
  }, [handleInputChange]);

  useEffect(() => {
    const haveChanges = checkForChanges(initialValues, values);
    setFormIsDirty(haveChanges);
  }, [setFormIsDirty, initialValues, values]);

  return (
    <div className={styles.step1container}>
      {!fromProfile && <div className={styles.headerInfo}>Tell us about your business.</div>}
      <form onSubmit={handleSubmit} ref={ref}>
        <div className={styles.row}>
          <Input
            name="name"
            label="Name"
            placeholder=""
            containerClassName={styles.inputNameContainer}
            {...inputProps('name')}
            inputClassName={styles.inputBox}
          />
          <div className={styles.inputEmployeeContainer}>
            <StepsSelect
              name="employees"
              label="# of Employees"
              options={['1-3', '3-7', '7-10', '10+']}
              placeholder="Choose # of employees"
              value={values.employees}
              onChange={selectOnChange}
            />
          </div>
        </div>
        <TextArea
          name="description"
          label="Business Description"
          placeholder=""
          inputBox
          {...inputProps('description')}
          containerClassName={styles.inputDescriptionContainer}
        />
        <Input
          name="website"
          label="Website"
          placeholder=""
          containerClassName={styles.inputWebsiteContainer}
          {...inputProps('website')}
          inputClassName={styles.inputBox}
          disabled={brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY}
        />
      </form>
    </div>
  );
});

Step1BusinessInfo.propTypes = {
  setFormIsDirty: func,
  fromProfile: bool,
  setUpdatedFields: func,
};

export { Step1BusinessInfo };
