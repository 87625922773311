import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getExportDate, GET_EXPORT_DATE } from 'src/actions/embedAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import styles from './ExportDate.module.scss';

const ExportDate = () => {
  const dispatch = useDispatch();

  const {
    exportDate,
    selectedProject,
    selectedTimeRange,
  } = useSelector(({ embedAnalytics }) => ({
    exportDate: embedAnalytics.exportDate,
    selectedProject: embedAnalytics.selectedProject,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
  }));

  useEffect(() => {
    dispatch(getExportDate(selectedProject.id, selectedTimeRange.to));
  }, [dispatch, selectedProject, selectedTimeRange.to]);

  const { status } = useStatus(GET_EXPORT_DATE);

  return (
    <div className={styles.container}>
      <span className={styles.label}>Export date</span>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {status === SUCCESS && (
        <span className={styles.exportDate}>{exportDate}</span>
      )}
      {status === ERROR && (
        <span className={styles.exportDate}>No date found</span>
      )}
    </div>
  );
};

export { ExportDate };
