import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import CommentIcon from 'src/assets/icons/comment.svg';
import LikeIcon from 'src/assets/icons/like.svg';
import ImpressionsIcon from 'src/assets/icons/impressions.svg';
import ReachIcon from 'src/assets/icons/reach.svg';
import {
  GET_POST_DATA,
  GET_POST_INSIGHTS,
  SET_IS_STAGGER_POST,
  setIsStaggerPost,
} from 'src/actions/instagramAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus, useTooltip, useSession } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import { CheckBox } from 'src/common/check-box';
import { Tooltip } from 'src/common/tooltip';
import info from './info.svg';
import styles from './FeaturedInfo.module.scss';

const FeaturedInfo = () => {
  const dispatch = useDispatch();

  const {
    likeCount,
    commentsCount,
    impressions,
    reach,
    isStaggerPost,
    username,
    selectedPost,
    children,
  } = useSelector(({ instagramAnalytics }) => ({
    likeCount: instagramAnalytics.likeCount,
    commentsCount: instagramAnalytics.commentsCount,
    impressions: instagramAnalytics.impressions,
    reach: instagramAnalytics.reach,
    isStaggerPost: instagramAnalytics.isStaggerPost,
    username: instagramAnalytics.username,
    selectedPost: instagramAnalytics?.selectedPost,
    children: instagramAnalytics.selectedPostChildren,
  }));

  const { status: postDataStatus } = useStatus(GET_POST_DATA);
  const { status: postInsightsStatus } = useStatus(GET_POST_INSIGHTS);
  const { status: setIsStaggerPostStatus } = useStatus(SET_IS_STAGGER_POST);
  const { accountId } = useSession();

  const onChange = useCallback(({ target }) => {
    dispatch(setIsStaggerPost(
      accountId,
      selectedPost,
      target.checked,
      {
        username,
        children,
      },
    ));
  }, [accountId, dispatch, username, selectedPost, children]);

  const { ref, showTooltip } = useTooltip();
  const showCheckbox = (!setIsStaggerPostStatus || setIsStaggerPostStatus === SUCCESS);

  return (
    <div className={styles.container}>
      <div className={styles.chkboxRectangle}>
        {setIsStaggerPostStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {showTooltip && (
          <Tooltip className={styles.tooltip}>
            If you created this post with Stagger,<br />
            checking this box helps us give you <br />
            creative insights in the future.
          </Tooltip>
        )}
        {showCheckbox && (
        <div className={styles.title}>
          <CheckBox value="" isSelected={isStaggerPost} onChange={onChange} />
          <span className={styles.chkBoxText}>Created with Stagger</span>
        </div>
        )}
        <img
          src={info}
          alt=""
          ref={ref}
          className={cn(styles.infoIcon, { [styles.hide]: !showCheckbox })}
        />
      </div>
      <div className={styles.rectangle}>
        <div className={styles.title}>
          <img src={LikeIcon} alt="like" />
          Likes
        </div>
        {postDataStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {postDataStatus === SUCCESS && (
          <span className={styles.info}>
            {likeCount}
          </span>
        )}
        {postDataStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
      <div className={styles.rectangle}>
        <div className={styles.title}>
          <img src={CommentIcon} alt="comments" />
          Comments
        </div>
        {postDataStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {postDataStatus === SUCCESS && (
          <span className={styles.info}>
            {commentsCount}
          </span>
        )}
        {postDataStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
      <div className={styles.rectangle}>
        <div className={styles.title}>
          <img src={ImpressionsIcon} alt="impressions" />
          Impressions
        </div>
        {postInsightsStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {postInsightsStatus === SUCCESS && (
          <span className={styles.info}>{impressions}</span>
        )}
        {postInsightsStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
      <div className={styles.rectangle}>
        <div className={styles.title}>
          <img src={ReachIcon} alt="reach" />
          Reach
        </div>
        {postInsightsStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {postInsightsStatus === SUCCESS && (
          <span className={styles.info}>{reach}</span>
        )}
        {postInsightsStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
    </div>
  );
};

export { FeaturedInfo };
