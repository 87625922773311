import React from 'react';
import { number, shape, string } from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { setSelectedProject } from 'src/actions/embedAnalyticsActions';
import styles from './ProjectCard.module.scss';

const ProjectCard = ({ project, selectedProject }) => {
  const dispatch = useDispatch();

  return (
    <button
      className={cn(styles.card, { [styles.active]: project.id === selectedProject?.id })}
      onClick={() => dispatch(setSelectedProject({ project }))}
    >
      {project.name}
    </button>
  );
};

ProjectCard.propTypes = {
  project: shape({
    name: string,
    id: number,
  }).isRequired,
  selectedProject: shape({
    id: number,
  }),
};

export { ProjectCard };
