import { SYSTEM_UPLOAD, GOOGLE_UPLOAD } from 'src/constants/uploadFile';

export const FONT_SIZE_OPTIONS = [
  { value: 14, label: '14px' },
  { value: 16, label: '16px' },
  { value: 18, label: '18px' },
  { value: 20, label: '20px' },
  { value: 24, label: '24px' },
  { value: 30, label: '30px' },
  { value: 36, label: '36px' },
  { value: 48, label: '48px' },
  { value: 60, label: '60px' },
  { value: 72, label: '72px' },
  { value: 96, label: '96px' },
  { value: 102, label: '102px' },
  { value: 118, label: '118px' },
  { value: 140, label: '140px' },
];

const THIN = { value: '100', label: 'Thin' };
const EXTRA_LIGHT = { value: '200', label: 'Extra Light' };
const LIGHT = { value: '300', label: 'Light' };
export const REGULAR = { value: '400', label: 'Regular' };
const ITALIC = { value: 'italic', label: 'Italic' };
const SEMI_BOLD = { value: '600', label: 'Semi Bold' };
const BOLD = { value: '700', label: 'Bold' };
const EXTRA_BOLD = { value: '800', label: 'Extra Bold' };
const BLACK = { value: '900', label: 'Black' };
const THIN_ITALIC = { value: '100 italic', label: 'Thin Italic' };
const EXTRA_LIGHT_ITALIC = { value: '200 italic', label: 'Extra Light Italic' };
const LIGHT_ITALIC = { value: '300 italic', label: 'Light Italic' };
const SEMI_BOLD_ITALIC = { value: '600 italic', label: 'Semi Bold Italic' };
const BOLD_ITALIC = { value: '700 italic', label: 'Bold Italic' };
const BLACK_ITALIC = { value: '900 italic', label: 'Black Italic' };

// DISCLAIMER:
// Remember that this should have the same values for the
// following const as /canvas/constants/fonts.ts file at the batch
export const FONT_FAMILY_OPTIONS = [
  {
    label: 'Abel',
    value: 'Abel',
    styles: [REGULAR],
    font: 'Abel',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Abril Fatface',
    value: 'Abril Fatface',
    styles: [REGULAR],
    font: 'Abril+Fatface',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Alegreya',
    value: 'Alegreya',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Alegreya:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Archivo',
    value: 'Archivo',
    styles: [REGULAR, ITALIC, SEMI_BOLD, SEMI_BOLD_ITALIC, BOLD, BOLD_ITALIC],
    font: 'Archivo:400,400i,600,600i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Arizonia',
    value: 'Arizonia',
    styles: [REGULAR],
    font: 'Arizonia',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Arvo',
    value: 'Arvo',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Arvo:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Avalanche',
    value: 'Avalanche',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317537/system-fonts/Avalanche_cb5jqi.otf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'B612',
    value: 'B612',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'B612:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Bebas Neue',
    value: 'Bebas Neue',
    styles: [REGULAR],
    font: 'Bebas+Neue',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Berkshire Swash',
    value: 'Berkshire Swash',
    styles: [REGULAR],
    font: 'Berkshire+Swash',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'BioRhyme',
    value: 'BioRhyme',
    styles: [LIGHT, REGULAR, BOLD, EXTRA_BOLD],
    font: 'BioRhyme:300,400,700,800',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Branch',
    value: 'Branch',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655390857/system-fonts/Branch_Updated_k2wkia.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Bulgari',
    value: 'Bulgari',
    styles: [REGULAR],
    // eslint-disable-next-line
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655397380/system-fonts/Bulgari_y5kfbi.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Cairo',
    value: 'Cairo',
    styles: [REGULAR, SEMI_BOLD, BOLD, BLACK],
    font: 'Cairo:400,600,700,900',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Carphe',
    value: 'Carphe',
    styles: [REGULAR],
    // eslint-disable-next-line
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655397667/system-fonts/Carphe_liwhx0.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Cinzel',
    value: 'Cinzel',
    styles: [REGULAR, BOLD, BLACK],
    font: 'Cinzel:400,700,900',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Cookie',
    value: 'Cookie',
    styles: [REGULAR],
    font: 'Cookie',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Cormorant',
    value: 'Cormorant',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Cormorant:300,300i,400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Crimson Text',
    value: 'Crimson Text',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Crimson+Text:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'EB Garamond',
    value: 'EB Garamond',
    styles: [REGULAR, ITALIC, BOLD],
    font: 'EB+Garamond:400,400i,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Fjalla One',
    value: 'Fjalla One',
    styles: [REGULAR],
    font: 'Fjalla+One',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Gourmet Le French Script',
    value: 'Gourmet Le French Script',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655414268/system-fonts/Gourmet_Le_French_Script_ffqems.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Great Vibes',
    value: 'Great Vibes',
    styles: [REGULAR],
    font: 'Great+Vibes',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Housky',
    value: 'Housky',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317538/system-fonts/Housky_rd1ddo.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'IBM Plex Serif',
    value: 'IBM Plex Serif',
    styles: [REGULAR, SEMI_BOLD, BOLD],
    font: 'IBM+Plex+Serif:400,600,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Just Another Hand',
    value: 'Just Another Hand',
    styles: [REGULAR],
    font: 'Just+Another+Hand',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Karla',
    value: 'Karla',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Karla:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Kristi',
    value: 'Kristi',
    styles: [REGULAR],
    font: 'Kristi',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Lato',
    value: 'Lato',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC],
    font: 'Lato:300,300i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Leckerli One',
    value: 'Leckerli One',
    styles: [REGULAR],
    font: 'Leckerli+One',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Libre Baskerville',
    value: 'Libre Baskerville',
    styles: [REGULAR, ITALIC, BOLD],
    font: 'Libre+Baskerville:400,400i,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Lobster Two',
    value: 'Lobster Two',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Lobster+Two:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Lora',
    value: 'Lora',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Lora:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Mango',
    value: 'Mango',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655414850/system-fonts/Mango_pt9wxj.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Merienda One',
    value: 'Merienda One',
    styles: [REGULAR],
    font: 'Merienda+One',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC],
    font: 'Merriweather:300,300i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Modernist Milk',
    value: 'Modernist Milk',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317537/system-fonts/Modernist-Milk_fvgvtf.otf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Modernline',
    value: 'Modernline',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317537/system-fonts/Modernline_xumzxf.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    styles: [THIN, THIN_ITALIC, LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK],
    font: 'Montserrat:100,100i,300,300i,400,400i,700,700i,900',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Mr Dafoe',
    value: 'Mr Dafoe',
    styles: [REGULAR],
    font: 'Mr+Dafoe',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Muli',
    value: 'Muli',
    styles: [REGULAR, SEMI_BOLD, EXTRA_BOLD, BLACK],
    font: 'Muli:400,600,800,900',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Noto Sans',
    value: 'Noto Sans',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Noto+Sans:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Nunito',
    value: 'Nunito',
    styles: [
      EXTRA_LIGHT, EXTRA_LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC,
    ],
    font: 'Nunito:200,200i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Oleo Script',
    value: 'Oleo Script',
    styles: [REGULAR, BOLD],
    font: 'Oleo+Script:400,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Olivia James',
    value: 'Olivia James',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655416193/system-fonts/Olivia_James_ffp312.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Open Sans',
    value: 'Open Sans',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Open+Sans:300,300i,400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Oxygen',
    value: 'Oxygen',
    styles: [LIGHT, REGULAR, BOLD],
    font: 'Oxygen:300,400,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Pacifico',
    value: 'Pacifico',
    styles: [REGULAR],
    font: 'Pacifico',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Peach',
    value: 'Peach',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655416349/system-fonts/Peach_be5w2v.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Playfair Display',
    value: 'Playfair Display',
    styles: [REGULAR, ITALIC, SEMI_BOLD, SEMI_BOLD_ITALIC, BLACK, BLACK_ITALIC],
    font: 'Playfair+Display:400,400i,600,600i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'PT Serif',
    value: 'PT Serif',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'PT+Serif:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Poppins',
    value: 'Poppins',
    styles: [
      EXTRA_LIGHT, EXTRA_LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC,
    ],
    font: 'Poppins:200,200i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Rakkas',
    value: 'Rakkas',
    styles: [REGULAR],
    font: 'Rakkas',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Roboto',
    value: 'Roboto',
    styles: [THIN, THIN_ITALIC, LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Roboto:100,100i,300,300i,400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Rubik',
    value: 'Rubik',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC],
    font: 'Rubik:300,300i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Salty Feathers',
    value: 'Salty Feathers',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1655416552/system-fonts/SaltyFeathers_a5tzdz.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Seaspray',
    value: 'Seaspray',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317537/system-fonts/Seaspray_tg8cul.otf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Sebastian Bobby',
    value: 'Sebastian Bobby',
    styles: [REGULAR],
    // eslint-disable-next-line max-len
    font: 'https://res.cloudinary.com/beta-stagger/raw/upload/v1627317537/system-fonts/Sebastian_Bobby_ikagkc.ttf',
    uploadType: SYSTEM_UPLOAD,
  },
  {
    label: 'Shrikhand',
    value: 'Shrikhand',
    styles: [REGULAR],
    font: 'Shrikhand',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Source Sans Pro',
    value: 'Source Sans Pro',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC, BLACK, BLACK_ITALIC],
    font: 'Source+Sans+Pro:300,300i,400,400i,700,700i,900,900i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Spectral',
    value: 'Spectral',
    styles: [REGULAR, SEMI_BOLD, BOLD],
    font: 'Spectral:400,600,700',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Staatliches',
    value: 'Staatliches',
    styles: [REGULAR],
    font: 'Staatliches',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Titillium',
    value: 'Titillium Web',
    styles: [EXTRA_LIGHT, EXTRA_LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Titillium+Web:200,200i,400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Ubuntu',
    value: 'Ubuntu',
    styles: [LIGHT, LIGHT_ITALIC, REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Ubuntu:300,300i,400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Varela',
    value: 'Varela',
    styles: [REGULAR],
    font: 'Varela',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Vollkorn',
    value: 'Vollkorn',
    styles: [REGULAR, ITALIC, BOLD, BOLD_ITALIC],
    font: 'Vollkorn:400,400i,700,700i',
    uploadType: GOOGLE_UPLOAD,
  },
  {
    label: 'Yatra One',
    value: 'Yatra One',
    styles: [REGULAR],
    font: 'Yatra+One',
    uploadType: GOOGLE_UPLOAD,
  },
];

export const DEFAULT_FONT_FAMILY = FONT_FAMILY_OPTIONS[45]; // Open sans
export const DEFAULT_FONT_STYLE = REGULAR;
