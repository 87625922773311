import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { CollapsibleOptions } from 'src/common/collapsible-options';
import styles from './ColorTypeSelect.module.scss';

const ColorTypeSelect = ({ options, selectedOption }) => (
  <CollapsibleOptions
    options={options}
    className={styles.container}
    optionsClassName={styles.options}
    buttonClassName={styles.collapsibleButton}
    optionClassName={styles.option}
    closeOnClickOption
  >
    <span className={styles.text}>
      {options.find(elem => elem.value === selectedOption)?.text}
    </span>
  </CollapsibleOptions>
);

ColorTypeSelect.propTypes = {
  options: arrayOf(shape({
    text: string,
    value: string,
    action: func,
  })).isRequired,
  selectedOption: string.isRequired,
};

export { ColorTypeSelect };
