import { AccountInviteService } from 'src/services/accountInviteService';
import createAction from './createAction';

export const CHECK_INVITE_NEEDS_NEW_USER = 'CHECK_INVITE_NEEDS_NEW_USER';
export const CHECK_INVITE_NEEDS_NEW_USER_REQUEST = 'CHECK_INVITE_NEEDS_NEW_USER_REQUEST';
export const CHECK_INVITE_NEEDS_NEW_USER_SUCCESS = 'CHECK_INVITE_NEEDS_NEW_USER_SUCCESS';
export const CHECK_INVITE_NEEDS_NEW_USER_ERROR = 'CHECK_INVITE_NEEDS_NEW_USER_ERROR';

export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_ERROR = 'ACCEPT_INVITE_ERROR';

export const checkInviteNeedsNewUserRequest = createAction(CHECK_INVITE_NEEDS_NEW_USER_REQUEST);
export const checkInviteNeedsNewUserSuccess = createAction(CHECK_INVITE_NEEDS_NEW_USER_SUCCESS);
export const checkInviteNeedsNewUserError = createAction(CHECK_INVITE_NEEDS_NEW_USER_ERROR);

export const acceptInviteRequest = createAction(ACCEPT_INVITE_REQUEST);
export const acceptInviteSuccess = createAction(ACCEPT_INVITE_SUCCESS);
export const acceptInviteError = createAction(ACCEPT_INVITE_ERROR);

export const checkInviteNeedsNewUser = token => async dispatch => {
  try {
    dispatch(checkInviteNeedsNewUserRequest());
    const {
      data: { needsNewUser, newUserInfo },
    } = await AccountInviteService.inviteNeedsNewUser(token);

    dispatch(checkInviteNeedsNewUserSuccess({
      needsNewUser,
      newUserInfo,
      inviteToken: token,
    }));
  } catch (error) {
    dispatch(checkInviteNeedsNewUserError(error?.data?.message));
  }
};

export const acceptInvite = token => async dispatch => {
  try {
    dispatch(acceptInviteRequest());
    await AccountInviteService.accept(token);
    dispatch(acceptInviteSuccess());
  } catch (error) {
    dispatch(acceptInviteError());
  }
};
