import {
  CHANGE_LAYOUT,
  CHANGE_SIZE,
  CHANGE_COLOR,
  CLONE_ELEMENTS,
  COPY_PASTE_ELEMENTS,
  ADD_TEXT_ELEMENT,
  ADD_LINE_ELEMENT,
  ADD_CIRCLE_ELEMENT,
  ADD_SQUARE_ELEMENT,
  ADD_ROUNDED_RECT_ELEMENT,
  ADD_TRIANGLE_ELEMENT,
  ADD_GROUP_ELEMENT,
  UPDATE_ELEMENT,
  MOVE_ELEMENT_BACKWARD,
  MOVE_ELEMENT_FORWARD,
  LOCK_ELEMENT,
  UNLOCK_ELEMENT,
  DELETE_ELEMENT,
  UNGROUP_ELEMENTS,
  GET_PROJECT_SUCCESS,
  ADD_TO_MEDIA_BAR_SUCCESS,
  DELETE_FROM_MEDIA_BAR,
  CROP_IMAGE,
  MOVE_ELEMENT_TO_BACK,
  MOVE_ELEMENT_TO_FRONT,
  MOVE_ELEMENT_WITH_KEYBOARD,
  ADD_TAG_TO_ELEMENT,
  REMOVE_TAG_FROM_ELEMENT,
  UPDATE_PROJECT_NAME_SUCCESS,
  UPDATE_PROJECT_TITLES_SUCCESS,
  MOVE_FRAME,
  ADD_FRAME,
  REPLACE_BG_REMOVED_IMAGE,
  ADD_TEXT_COMBINATION,
  ADD_OBJECT_COMBINATION,
  SAVE_MEDIA_ELEMENTS,
} from 'src/actions/projectActions';
import { SHOW_GENERATED_COLLAGE_SUCCESS } from 'src/actions/collageActions';
import { APPLY_TEMPLATE_TO_PROJECT_SUCCESS } from 'src/actions/productTemplateActions';
import { APPLY_SUGGESTION_TO_PROJECT_SUCCESS } from 'src/actions/insightActions';
import { CHOOSE_EYEDROPPER_COLOR, SET_EDITABLE_IMAGE } from 'src/actions/canvasActions';

export const undoableActions = [
  GET_PROJECT_SUCCESS,
  CHANGE_LAYOUT,
  CHANGE_SIZE,
  CHANGE_COLOR,
  CLONE_ELEMENTS,
  COPY_PASTE_ELEMENTS,
  SAVE_MEDIA_ELEMENTS,
  ADD_TO_MEDIA_BAR_SUCCESS,
  DELETE_FROM_MEDIA_BAR,
  ADD_TEXT_ELEMENT,
  ADD_LINE_ELEMENT,
  ADD_CIRCLE_ELEMENT,
  ADD_SQUARE_ELEMENT,
  ADD_ROUNDED_RECT_ELEMENT,
  ADD_TRIANGLE_ELEMENT,
  ADD_GROUP_ELEMENT,
  UPDATE_ELEMENT,
  UPDATE_PROJECT_NAME_SUCCESS,
  UPDATE_PROJECT_TITLES_SUCCESS,
  MOVE_ELEMENT_BACKWARD,
  MOVE_ELEMENT_FORWARD,
  LOCK_ELEMENT,
  UNLOCK_ELEMENT,
  DELETE_ELEMENT,
  UNGROUP_ELEMENTS,
  SET_EDITABLE_IMAGE,
  CROP_IMAGE,
  MOVE_ELEMENT_TO_BACK,
  MOVE_ELEMENT_TO_FRONT,
  MOVE_ELEMENT_WITH_KEYBOARD,
  CHOOSE_EYEDROPPER_COLOR,
  APPLY_TEMPLATE_TO_PROJECT_SUCCESS,
  APPLY_SUGGESTION_TO_PROJECT_SUCCESS,
  ADD_TAG_TO_ELEMENT,
  REMOVE_TAG_FROM_ELEMENT,
  MOVE_FRAME,
  ADD_FRAME,
  REPLACE_BG_REMOVED_IMAGE,
  ADD_TEXT_COMBINATION,
  ADD_OBJECT_COMBINATION,
  SHOW_GENERATED_COLLAGE_SUCCESS,
];
