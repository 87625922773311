import { HttpClient } from '../clients';

class BrandLibraryService {
  static create(accountId) {
    return HttpClient.post('/libraries', { accountId });
  }

  static getFromAccountId(accountId, include) {
    return HttpClient.get(`/libraries/account/${accountId}`, { params: { include } });
  }

  static saveFileFromLocal(file, folder = 'brand-assets') {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', file);
    return HttpClient.post(`/libraries/fileLocal?folder=${folder}`, formData, config);
  }

  static deleteLogo(id, index) {
    return HttpClient.delete(`/libraries/${id}/logo/${index}`);
  }

  static createAssetCategory(id, assetCategory) {
    return HttpClient.post(`/libraries/${id}/asset-category`, { assetCategory });
  }

  static updateAssetCategory(id, assetCategoryId, assetCategory) {
    return HttpClient.put(`/libraries/${id}/asset-category/${assetCategoryId}`, { assetCategory });
  }

  static deleteAssetCategory(id, assetCategoryId) {
    return HttpClient.delete(`/libraries/${id}/asset-category/${assetCategoryId}`);
  }

  static createLogoCategory(id, logoCategory) {
    return HttpClient.post(`/libraries/${id}/logo-category`, { logoCategory });
  }

  static updateLogoCategory(id, logoCategoryId, logoCategory) {
    return HttpClient.put(`/libraries/${id}/logo-category/${logoCategoryId}`, { logoCategory });
  }

  static deleteLogoCategory(id, logoCategoryId) {
    return HttpClient.delete(`/libraries/${id}/logo-category/${logoCategoryId}`);
  }

  static createColorPalette(id, colorPalette) {
    return HttpClient.post(`/libraries/${id}/color-palette`, { colorPalette });
  }

  static updateColorPalette(id, paletteId, colorPalette) {
    return HttpClient.put(`/libraries/${id}/color-palette/${paletteId}`, { colorPalette });
  }

  static deleteColorPalette(id, paletteId) {
    return HttpClient.delete(`/libraries/${id}/color-palette/${paletteId}`);
  }

  static createFont(id, font) {
    return HttpClient.post(`/libraries/${id}/font`, { font });
  }

  static createUploadedFont(id, uploadedFont) {
    return HttpClient.post(`/libraries/${id}/uploaded-font`, { uploadedFont });
  }

  static updateFont(id, fontId, font) {
    return HttpClient.put(`/libraries/${id}/font/${fontId}`, { font });
  }

  static deleteFont(id, fontId) {
    return HttpClient.delete(`/libraries/${id}/font/${fontId}`);
  }

  static deleteUploadedFont(id, uploadedFontId) {
    return HttpClient.delete(`/libraries/${id}/uploaded-font/${uploadedFontId}`);
  }

  static async search(accountId, searchTerm, page, perPage) {
    return HttpClient.get('/libraries/search', {
      params: { accountId, searchTerm, page, perPage },
    });
  }
}

export { BrandLibraryService };
