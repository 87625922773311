import { HttpClient } from '../clients';

class ColorPaletteService {
  static createColor(id, color) {
    return HttpClient.post(`/color-palettes/${id}/color`, { color });
  }

  static updateColor(id, colorId, color) {
    return HttpClient.put(`/color-palettes/${id}/color/${colorId}`, { color });
  }

  static deleteColor(id, colorId) {
    return HttpClient.delete(`/color-palettes/${id}/color/${colorId}`);
  }
}

export { ColorPaletteService };
