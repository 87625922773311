import { v4 as uuidv4 } from 'uuid';

import {
  changeLayout,
  changeSize,
  changeColor,
  unsplashSearchSuccess,
  unsplashReset,
  cloneElements,
  copyPasteElements,
  replaceImageFromRightClick,
  updateElementSuccess,
  moveElementBackward,
  moveElementForward,
  moveElementToFront,
  moveElementToBack,
  addTextElementSuccess,
  addLineElement,
  addCircleElement,
  addSquareElement,
  addRoundedRectElement,
  addTriangleElement,
  addTagToElement,
  removeTagFromElement,
  lockElement,
  unlockElement,
  cropImage,
  createProjectSuccess,
  getProjectSuccess,
  updateProjectSuccess,
  updateProjectNameSuccess,
  updateProjectToExportSuccess,
  updateProjectVideoInformationSuccess,
  updateProjectEmbedCodeGeneratedSuccess,
  deleteElementSuccess,
  deleteElementById,
  getProjectError,
  addSelectedElement,
  cleanSelectedElement,
  removeSelectedElement,
  addGroupElement,
  ungroupElementsSuccess,
  writeTextElement,
  addLinkToElement,
  saveVideosPosition,
  setUnsplashImageDimensions,
  moveElementWithKeyboard,
  addMediaElements,
  updateProjectAutomaticallySuccess,
  updateProjectTitlesSuccess,
  moveFrame,
  addFrame,
  removeImageBackgroundSuccess,
  removeImageBackgroundRequest,
  removeImageBackgroundError,
  replaceBgRemovedImage,
  addTextCombination,
  getProjectReset,
  unsplashNextPageSuccess,
  receiveShotstackVideo,
  saveGifsPosition,
  addObjectCombination,
} from 'src/actions/projectActions';
import { showGeneratedCollageSuccess, undoCollage } from 'src/actions/collageActions';
import { updateFlyerSettingsSuccess } from 'src/actions/flyerActions';
import {
  applyTemplateToProjectSuccess,
  saveTemplateSuccess,
  updateExistingTemplateSuccess,
  updateMetadataTemplateSuccess,
} from 'src/actions/productTemplateActions';
import { applySuggestionToProjectSuccess } from 'src/actions/insightActions';
import {
  updateTextWithFontStyle,
  updateProjectWithFontStyleSuccess,
} from 'src/actions/brandLibraryActions';
import {
  chooseEyeDropperColor,
  setEditableText,
  setEditableImage,
  setEditableTag,
  setEditableFrameTitle,
  saveZoom,
  selectFrame,
} from 'src/actions/canvasActions';
import { logout } from 'src/actions/userActions';
import {
  TEXT_ELEMENT,
  LINE_ELEMENT,
  CIRCLE_ELEMENT,
  SQUARE_ELEMENT,
  ROUNDED_RECT_ELEMENT,
  TRIANGLE_ELEMENT,
  GROUP_ELEMENT,
  VIDEO_ELEMENT,
  MEDIA_ELEMENTS,
  IMAGE_ELEMENT,
} from 'src/constants/canvasElements';
import {
  TEXT_ALIGN,
  DEFAULT_BORDER_SIZE,
  DEFAULT_PROJECT,
  BACKGROUND_COLOR_EYEDROPPER_SOURCE,
  FILL_COLOR_EYEDROPPER_SOURCE,
  BORDER_COLOR_EYEDROPPER_SOURCE,
  TEXT_COLOR_EYEDROPPER_SOURCE,
  TEXT_DEFAULT_LINE_HEIGHT,
  DEFAULT_BORDER_DASH,
  SHADOW_COLOR_EYEDROPPER_SOURCE,
  DEFAULT_BORDER_COLOR,
  TAG_MARKER_PREFIX_ID,
  CANVAS_OFFSET_FOR_ADDING_IMAGES,
} from 'src/constants/general';
import { FONT_SIZE_OPTIONS, DEFAULT_FONT_FAMILY } from 'src/constants/fonts';
import { LOCAL_UPLOAD, GOOGLE_UPLOAD } from 'src/constants/uploadFile';
import { cleanEditableImage, isGif } from 'src/utils/helpers';
import {
  calculateAspectRatioFit,
  getReplacedImageAttrs,
  isElementOverlapping,
  saveNewMediaFilesInCanvas,
} from 'src/utils/canvasHelpers';
import {
  decompose,
  getGroupItemAttrs,
} from 'src/utils/ungroupHelpers';
import {
  deleteFromGifList,
  deleteFromVideoList,
  saveGifCanvasPosition,
  saveVideoCanvasPosition,
} from 'src/utils/videoHelpers';
import { resetCroppedAttributes } from 'src/utils/imageCropperHelper';
import {
  trackAddVideoToCanvas,
  trackAddImageToCanvas,
  trackReplaceImage,
} from 'src/utils/analytics';
import {
  RIGHT_ARROW_CODE,
  LEFT_ARROW_CODE,
  BOTTOM_ARROW_CODE,
  TOP_ARROW_CODE,
} from 'src/constants/keyboardCodes';
import {
  copyGroup,
  deleteGroup,
  findInGroupAndReplace,
  getFirstSingleElement,
} from 'src/utils/groupHelpers';
import createReducer from './createReducer';

const SHAPE_SIZE = 500;

const DEFAULT_SHAPE_FILL_COLOR = { hex: '#EEEEEE', alpha: 100 };

const initialState = {
  id: undefined,
  name: '',
  layout: DEFAULT_PROJECT.LAYOUT,
  size: DEFAULT_PROJECT.SIZE,
  color: {
    hex: DEFAULT_PROJECT.COLOR,
    alpha: DEFAULT_PROJECT.ALPHA,
  },
  unsplashImages: { images: [], totalPages: 0 },
  elements: [],
  groupsOfElementsById: {},
  gifIdsByPosition: {},
  videoIdsByPosition: {},
  videoIdsShotstack: [],
  videoUrls: [],
  selectedElements: {},
  selectedRefs: {},
  editableText: undefined,
  editableImage: undefined,
  editableTag: undefined,
  editableFrameTitle: undefined,
  previews: [],
  template: undefined,
  thumbnail: undefined,
  showedVideoInformation: false,
  embedCodeGenerated: false,
  type: undefined,
  flyerSettings: undefined,
  signSettings: undefined,
  frameTitles: [],
  imageUUIDToRemoveBackground: undefined,
};

const getElementBeforeCrop = elem => {
  const oldScaleX = (elem.width * elem.scaleX) / elem.crop.width;
  const oldScaleY = (elem.height * elem.scaleY) / elem.crop.height;
  const { crop, ...rest } = elem;
  return {
    ...rest,
    x: elem.x - (-elem.width / 2 + elem.crop.x + elem.crop.width / 2) * oldScaleX,
    y: elem.y - (-elem.height / 2 + elem.crop.y + elem.crop.height / 2) * oldScaleY,
    scaleX: oldScaleX,
    scaleY: oldScaleY,
  };
};

const cropMediaElement = (elem, cropBBox) => {
  // validate that the cropBBox is inside the elem
  if (
    cropBBox.topLeft.x < 0 ||
    cropBBox.topLeft.y < 0 ||
    cropBBox.topLeft.x + cropBBox.width > elem.width ||
    cropBBox.topLeft.y + cropBBox.height > elem.height
  ) {
    throw new Error(
      `cropBBox is not inside the elem. cropBBox: ${
        JSON.stringify(cropBBox)
      } elem: ${
        JSON.stringify(elem)}`,
    );
  }

  elem.x += (-elem.width / 2 + cropBBox.topLeft.x + cropBBox.width / 2) * elem.scaleX;
  elem.y += (-elem.height / 2 + cropBBox.topLeft.y + cropBBox.height / 2) * elem.scaleY;

  elem.scaleX = (cropBBox.width * elem.scaleX) / elem.width;
  elem.scaleY = (cropBBox.height * elem.scaleY) / elem.height;

  delete elem.crop;
  return {
    ...elem,
    crop: {
      x: cropBBox.topLeft.x,
      y: cropBBox.topLeft.y,
      width: cropBBox.width,
      height: cropBBox.height,
    },
  };
};

const saveProjectState = (state, project) => {
  if (state.size !== project.size) {
    state.size = project.size;
  }
  if (state.color.hex !== project.color ||
    state.color.alpha !== project.alpha) {
    state.color = { hex: project?.color, alpha: project?.alpha };
  }
  if (state.layout.width !== project.layoutWidth ||
    state.layout.height !== project.layoutHeight ||
    state.layout.source !== project.layoutSource) {
    state.layout = {
      width: project.layoutWidth,
      height: project.layoutHeight,
      source: project.layoutSource,
    };
  }
  state.elements = JSON.parse(project.elements || null) || [];
  state.groupsOfElementsById = JSON.parse(project.groupsOfElementsById || null) || {};
  state.gifIdsByPosition = JSON.parse(project.gifIdsByPosition || null) || {};
  state.videoIdsByPosition = JSON.parse(project.videoIdsByPosition || null) || {};
  state.videoIdsShotstack = project.videoIdsShotstack || [];
  state.thumbnail = project.thumbnail;
};

const cleanEditableElements = (state, keepEditableText) => {
  if (state.editableText && !keepEditableText) {
    state.elements = state.elements.map(elem => {
      if (elem.uuid === state.editableText && !elem.text) {
        elem.text = 'Add text';
      }
      return elem;
    });
    state.editableText = undefined;
  }
  if (state.editableImage) {
    state.elements = cleanEditableImage(state.elements, state.editableImage);
    state.editableImage = undefined;
  }
  state.editableFrameTitle = undefined;
};

const actionHandlers = {
  [createProjectSuccess]: (state, { payload: { project } }) => {
    state.name = project.name;
    state.id = project.id;
  },
  [getProjectSuccess]: (state, { payload: { project } }) => {
    state.name = project.name;
    state.id = project.id;
    state.size = project.size;
    state.color = { hex: project.color, alpha: project.alpha };
    state.layout = {
      width: project.layoutWidth,
      height: project.layoutHeight,
      source: project.layoutSource,
    };
    state.unsplashImages = { images: [], totalPages: 0 };
    state.elements = JSON.parse(project.elements) || [];
    state.groupsOfElementsById = JSON.parse(project.groupsOfElementsById) || {};
    state.gifIdsByPosition = JSON.parse(project.gifIdsByPosition) || {};
    state.videoIdsByPosition = JSON.parse(project.videoIdsByPosition) || {};
    state.videoIdsShotstack = project.videoIdsShotstack || [];
    state.videoUrls = project.videoUrls || [];
    state.selectedElements = {};
    state.selectedRefs = {};
    state.previews = project.previews || [];
    state.template = project.template;
    state.thumbnail = project.thumbnail;
    state.showedVideoInformation = project.showedVideoInformation;
    state.embedCodeGenerated = project.embedCodeGenerated;
    state.type = project.type;
    state.flyerSettings = project.flyerSettings;
    state.signSettings = project.signSettings;
    state.frameTitles = project.frameTitles;
    cleanEditableElements(state);
  },
  [updateProjectSuccess]: (state, { payload: { project } }) => {
    if (project.id === state.id) {
      saveProjectState(state, project);
    }
  },
  [updateProjectAutomaticallySuccess]: (state, { payload: { project } }) => {
    if (project.id === state.id) {
      const elements = JSON.parse(project.elements) || [];
      saveNewMediaFilesInCanvas(elements, state);
      const groupsOfElementsById = JSON.parse(project.groupsOfElementsById) || {};
      saveNewMediaFilesInCanvas(Object.values(groupsOfElementsById).flatMap(e => e), state);
    }
  },
  [updateProjectWithFontStyleSuccess]: (state, { payload: { project } }) => {
    state.elements = JSON.parse(project.elements) || [];
    state.groupsOfElementsById = JSON.parse(project.groupsOfElementsById) || {};
  },
  [updateProjectNameSuccess]: (state, { payload: { name } }) => {
    state.name = name;
  },
  [updateProjectTitlesSuccess]: (state, { payload: { frameTitles } }) => {
    state.frameTitles = frameTitles;
  },
  [updateProjectVideoInformationSuccess]: (state, { payload: { showedVideoInformation } }) => {
    state.showedVideoInformation = showedVideoInformation;
  },
  [updateProjectEmbedCodeGeneratedSuccess]: (state, { payload: { embedCodeGenerated } }) => {
    state.embedCodeGenerated = embedCodeGenerated;
  },
  [updateProjectToExportSuccess]: (
    state,
    { payload: { id, previews, thumbnail, videoIds, videoUrls, signSettings } },
  ) => {
    if (id === state.id) {
      state.previews = previews;
      state.thumbnail = thumbnail;
      state.videoIdsShotstack = videoIds || [];
      state.videoUrls = videoUrls || [];
      state.signSettings = signSettings;
    }
  },
  [updateFlyerSettingsSuccess]: (state, { payload: { project } }) => {
    state.flyerSettings = project.flyerSettings;
  },
  [changeLayout]: (state, { payload: { layout } }) => {
    state.elements = state.elements.map(elem => {
      if (elem.type === GROUP_ELEMENT) {
        const firstElem = getFirstSingleElement(
          elem,
          state.groupsOfElementsById,
          0,
          0,
        );

        const totalX = firstElem.x + (firstElem.accGroupX || 0) + (elem.x || 0);
        const totalY = firstElem.y + (firstElem.accGroupY || 0) + (elem.y || 0);

        const newTotalY = (layout.height * totalY) / state.layout.height;
        const newTotalX = (layout.width * totalX) / state.layout.width;

        const diffY = newTotalY - (firstElem.y + (firstElem.accGroupY || 0));
        const diffX = newTotalX - (firstElem.x + (firstElem.accGroupX || 0));

        elem.y = diffY;
        elem.x = diffX;
      } else {
        elem.y = (layout.height * elem.y) / state.layout.height;
        elem.x = (layout.width * elem.x) / state.layout.width;
        const isOverlapping = isElementOverlapping({
          xElement: elem.x,
          widthElement: elem.width,
          scaleXElement: elem.scaleX,
          widthLayout: layout.width,
          isText: elem.type === TEXT_ELEMENT,
        });
        elem.isOverlapping = isOverlapping;
      }
      return elem;
    });
    state.layout = layout;
    state.selectedElements = {};
    state.selectedRefs = {};
    state.editableFrameTitle = undefined;
  },
  [changeSize]: (state, { payload: { size } }) => {
    if (size < state.size) {
      cleanEditableElements(state);
      delete state.frameTitles[state.frameTitles.length - 1];
    }
    state.size = size;
  },
  [saveZoom]: state => {
    state.editableFrameTitle = undefined;
  },
  [changeColor]: (state, { payload }) => {
    state.color = payload;
  },
  [unsplashSearchSuccess]: (
    state,
    { payload: { images, totalPages } },
  ) => {
    state.unsplashImages = { images, totalPages };
  },
  [unsplashNextPageSuccess]: (state, { payload: { images, totalPages } }) => {
    state.unsplashImages.images = [...state.unsplashImages.images, ...images];
    state.unsplashImages.totalPages = totalPages;
  },
  [unsplashReset]: state => {
    state.unsplashImages = { images: [], totalPages: 0 };
  },
  [setUnsplashImageDimensions]: (state, { payload: { uuid, ...attrs } }) => {
    let isDimensionsSet = false;
    state.elements = state.elements.map(elem => {
      if (elem.uuid === uuid) {
        isDimensionsSet = true;
        return { ...elem, ...attrs };
      }
      return elem;
    });
    if (!isDimensionsSet) {
      const groups = state.elements.filter(elem => elem.type === GROUP_ELEMENT);
      groups.forEach((group) => {
        findInGroupAndReplace(
          group,
          state.groupsOfElementsById,
          uuid,
          () => attrs,
        );
      });
    }
  },
  [cloneElements]: (state, { payload: { uuids, newElementDistance = 0 } }) => {
    uuids.sort((a, b) => (
      state.elements.findIndex(elem => elem.uuid === a) -
      state.elements.findIndex(elem => elem.uuid === b)
    ));
    uuids.forEach(uuid => {
      const element = state.elements.find(current => current.uuid === uuid);
      const cloned = { ...element };
      cloned.x = (cloned.x || 0) + newElementDistance;
      cloned.y = (cloned.y || 0) + newElementDistance;
      const elementNewUuid = uuidv4();
      if (MEDIA_ELEMENTS.includes(element.type)) {
        cloned.duplicated = true;
      }
      element.uuid = elementNewUuid;
      state.elements.push(cloned);
      if (GROUP_ELEMENT === element.type) {
        copyGroup(state, uuid, elementNewUuid);
      }
      if (element.type === VIDEO_ELEMENT) {
        const videos = saveVideoCanvasPosition(state, element);
        state.videoIdsByPosition = videos;
      }
      if (element.type === IMAGE_ELEMENT && isGif(element.src)) {
        const gifs = saveGifCanvasPosition(state, element);
        state.gifIdsByPosition = gifs;
      }
    });
  },
  [copyPasteElements]: (state, { payload: { copiedElements } }) => {
    copiedElements.forEach(element => {
      const copied = { ...element };
      const newElementDistance = 30;
      copied.x = (element.x || 0) + newElementDistance;
      copied.y = (element.y || 0) + newElementDistance;
      if (MEDIA_ELEMENTS.includes(element.type)) {
        copied.duplicated = true;
      }
      const elementNewUuid = uuidv4();
      element.uuid = elementNewUuid;
      state.elements = [...state.elements, copied];
      if (GROUP_ELEMENT === element.type) {
        copyGroup(state, copied.uuid, elementNewUuid);
      }
      if (element.type === VIDEO_ELEMENT) {
        const videos = saveVideoCanvasPosition(state, element);
        state.videoIdsByPosition = videos;
      }
      if (element.type === IMAGE_ELEMENT && isGif(element.src)) {
        const gifs = saveGifCanvasPosition(state, element);
        state.gifIdsByPosition = gifs;
      }
    });
  },
  [addMediaElements]: (state, { payload: { media, x, y } }) => {
    const newElems = media.map((elem, index) => {
      const { ratio } = calculateAspectRatioFit(
        elem.width, elem.height, state.layout.width * 0.70, state.layout.height * 0.70,
      );
      const uuid = uuidv4();
      const mediaElement = {
        type: elem.type,
        uploadType: elem.uploadType,
        saved: elem.saved || elem.uploadType !== LOCAL_UPLOAD,
        src: elem.src,
        x: x + index * CANVAS_OFFSET_FOR_ADDING_IMAGES,
        y: y + index * CANVAS_OFFSET_FOR_ADDING_IMAGES,
        scaleX: ratio,
        scaleY: ratio,
        rotation: 0,
        uuid,
        width: elem.width,
        height: elem.height,
        unlocked: true,
        file: elem.file,
        sourceId: elem.sourceId,
        ...(elem.qrImage && { qrImage: true }),
      };
      if (elem.type === VIDEO_ELEMENT) {
        mediaElement.duration = elem.duration;
        const videos = saveVideoCanvasPosition(state, mediaElement);
        state.videoIdsByPosition = videos;
        trackAddVideoToCanvas(state.id, elem.duration);
      } else {
        if (isGif(elem.src)) {
          mediaElement.duration = elem.duration;
          const gifs = saveGifCanvasPosition(state, mediaElement);
          state.gifIdsByPosition = gifs;
        }
        trackAddImageToCanvas(state.id, elem.uploadType);
      }
      return mediaElement;
    });
    state.elements = [...state.elements, ...newElems];
    const lastNewElem = newElems[newElems.length - 1];
    state.selectedElements = { [lastNewElem.uuid]: lastNewElem.uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [replaceImageFromRightClick]: (state, { payload: { media, replaceableImage } }) => {
    const uuid = uuidv4();
    let newMedia;
    state.elements = state.elements.map(elem => {
      if (elem.uuid === replaceableImage.uuid) {
        const newAtts = getReplacedImageAttrs(elem, media);
        elem = { ...elem, ...newAtts };
        elem.uuid = uuid;
        newMedia = elem;
      }
      return elem;
    });
    if (replaceableImage.type === VIDEO_ELEMENT) {
      deleteFromVideoList(state, replaceableImage);
    } else if (replaceableImage.type === IMAGE_ELEMENT && isGif(replaceableImage.src)) {
      deleteFromGifList(state, replaceableImage);
    }

    if (newMedia.type === VIDEO_ELEMENT) {
      const videos = saveVideoCanvasPosition(state, newMedia);
      state.videoIdsByPosition = videos;
    } else if (newMedia.type === IMAGE_ELEMENT && isGif(newMedia.src)) {
      const gifs = saveGifCanvasPosition(state, newMedia);
      state.gifIdsByPosition = gifs;
    }

    trackAddImageToCanvas(state.id, newMedia.uploadType);
    trackReplaceImage(state.id, newMedia.uploadType);
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addTextElementSuccess]: (state, { payload: { x, y, fontMemory, ...props } }) => {
    const uuid = uuidv4();
    const text = {
      type: TEXT_ELEMENT,
      x,
      y,
      rotation: 0,
      uuid,
      text: '',
      align: TEXT_ALIGN.LEFT,
      color: { hex: '#000000', alpha: 100 },
      fontSize: FONT_SIZE_OPTIONS[7].value,
      fontFamily: DEFAULT_FONT_FAMILY.value,
      fontStyle: '400',
      uploadType: GOOGLE_UPLOAD,
      fontFamilyUrl: '',
      unlocked: true,
      lineHeight: TEXT_DEFAULT_LINE_HEIGHT,
      letterSpacing: 0,
      ...fontMemory,
      ...props,
    };
    state.elements = [...state.elements, text];
    cleanEditableElements(state);
    state.editableText = uuid;
    state.selectedElements = { [uuid]: uuid };
  },
  [addTextCombination]: (state, { payload: { elements, layoutWidth, layoutHeight, position } }) => {
    elements.forEach(elem => {
      const uuid = uuidv4();
      const text = {
        ...elem,
        uuid,
        x: position.x + elem.x - layoutWidth / 2,
        y: position.y + elem.y - layoutHeight / 2,
      };
      state.elements = [...state.elements, text];
    });
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addObjectCombination]: (
    state,
    { payload: { elements, layoutWidth, layoutHeight, position } },
  ) => {
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
    elements.forEach(elem => {
      const uuid = uuidv4();
      const img = {
        ...elem,
        uuid,
        x: position.x + elem.x - layoutWidth / 2,
        y: position.y + elem.y - layoutHeight / 2,
      };
      state.elements = [...state.elements, img];
      state.selectedElements[uuid] = uuid;
    });
  },
  [addLineElement]: (state, { payload: { x, y } }) => {
    const { ratio } = calculateAspectRatioFit(
      SHAPE_SIZE, SHAPE_SIZE, state.layout.width * 0.70, state.layout.height * 0.70,
    );
    const uuid = uuidv4();
    const line = {
      type: LINE_ELEMENT,
      x,
      y,
      points: [0, 0, SHAPE_SIZE, 0],
      rotation: 0,
      color: DEFAULT_SHAPE_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      uuid,
      width: SHAPE_SIZE,
      height: SHAPE_SIZE,
      scaleX: ratio,
      strokeWidth: 1,
      strokeDash: DEFAULT_BORDER_DASH,
      unlocked: true,
    };
    state.elements = [...state.elements, line];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addCircleElement]: (state, { payload: { x, y } }) => {
    const { ratio } = calculateAspectRatioFit(
      SHAPE_SIZE, SHAPE_SIZE, state.layout.width * 0.70, state.layout.height * 0.70,
    );
    const uuid = uuidv4();
    const circle = {
      type: CIRCLE_ELEMENT,
      x,
      y,
      color: DEFAULT_SHAPE_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      uuid,
      scaleX: ratio,
      scaleY: ratio,
      width: SHAPE_SIZE,
      height: SHAPE_SIZE,
      strokeWidth: DEFAULT_BORDER_SIZE,
      strokeDash: DEFAULT_BORDER_DASH,
      unlocked: true,
      rotation: 0,
    };
    state.elements = [...state.elements, circle];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addSquareElement]: (state, { payload: { x, y } }) => {
    const { ratio } = calculateAspectRatioFit(
      SHAPE_SIZE, SHAPE_SIZE, state.layout.width * 0.70, state.layout.height * 0.70,
    );
    const uuid = uuidv4();
    const square = {
      type: SQUARE_ELEMENT,
      x,
      y,
      color: DEFAULT_SHAPE_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      width: SHAPE_SIZE,
      height: SHAPE_SIZE,
      scaleX: ratio,
      scaleY: ratio,
      strokeWidth: DEFAULT_BORDER_SIZE,
      strokeDash: DEFAULT_BORDER_DASH,
      unlocked: true,
      uuid,
      rotation: 0,
    };
    state.elements = [...state.elements, square];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addRoundedRectElement]: (state, { payload: { x, y } }) => {
    const { ratio } = calculateAspectRatioFit(
      SHAPE_SIZE, SHAPE_SIZE, state.layout.width * 0.70, state.layout.height * 0.70,
    );
    const uuid = uuidv4();
    const roundedRect = {
      type: ROUNDED_RECT_ELEMENT,
      x,
      y,
      color: DEFAULT_SHAPE_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      width: SHAPE_SIZE,
      height: SHAPE_SIZE / 2,
      scaleX: ratio,
      scaleY: ratio,
      strokeWidth: DEFAULT_BORDER_SIZE,
      strokeDash: DEFAULT_BORDER_DASH,
      unlocked: true,
      uuid,
      rotation: 0,
    };
    state.elements = [...state.elements, roundedRect];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addTriangleElement]: (state, { payload: { x, y } }) => {
    const { ratio } = calculateAspectRatioFit(
      SHAPE_SIZE, SHAPE_SIZE, state.layout.width * 0.70, state.layout.height * 0.70,
    );
    const uuid = uuidv4();
    const triangle = {
      type: TRIANGLE_ELEMENT,
      x,
      y,
      color: DEFAULT_SHAPE_FILL_COLOR,
      borderColor: DEFAULT_BORDER_COLOR,
      width: SHAPE_SIZE,
      height: SHAPE_SIZE,
      scaleX: ratio,
      scaleY: ratio,
      strokeWidth: DEFAULT_BORDER_SIZE,
      strokeDash: DEFAULT_BORDER_DASH,
      unlocked: true,
      uuid,
      rotation: 0,
    };
    state.elements = [...state.elements, triangle];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [addGroupElement]: state => {
    const uuid = uuidv4();
    const group = {
      type: GROUP_ELEMENT,
      unlocked: true,
      rotation: 0,
      uuid,
    };
    const elementsToGroup = Object.keys(state.selectedElements).map(elementId => {
      const element = state.elements.find(item => item.uuid === elementId);
      const elementIndex = state.elements.findIndex(item => item.uuid === elementId);
      state.elements = state.elements.filter(item => item.uuid !== elementId);
      return { element, elementIndex };
    });
    elementsToGroup.sort((a, b) => a.elementIndex - b.elementIndex);
    state.groupsOfElementsById[uuid] = elementsToGroup.map(item => item.element);
    state.elements = [...state.elements, group];
    state.selectedElements = { [uuid]: uuid };
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [updateElementSuccess]: (state, { payload: { attrs: { uuid, ...attrs } } }) => {
    state.elements = state.elements.map(elem => {
      if (elem.uuid === uuid) {
        return { ...elem, ...attrs };
      }
      return elem;
    });
  },
  [updateTextWithFontStyle]: (state, { payload: { uuid, ...attrs } }) => {
    let belongsToGroup = true;
    state.elements = state.elements.map(elem => {
      if (elem.uuid === uuid) {
        belongsToGroup = false;
        return { ...elem, ...attrs };
      }
      return elem;
    });
    if (belongsToGroup) {
      const groups = state.elements.filter(elem => elem.type === GROUP_ELEMENT);
      groups.forEach((group) => {
        findInGroupAndReplace(
          group,
          state.groupsOfElementsById,
          uuid,
          () => attrs,
        );
      });
    }
  },
  [writeTextElement]: (state, { payload: { uuid, text, height } }) => {
    state.elements = state.elements.map(elem => {
      if (elem.uuid === uuid) {
        return { ...elem, text, height };
      }
      return elem;
    });
  },
  [addSelectedElement]: (state, { payload: {
    id,
    clean = false,
    refElement,
    keepEditableText = false,
  } }) => {
    if (clean) {
      state.selectedElements = {};
      state.selectedRefs = {};
    }
    state.selectedElements[id] = id;
    state.selectedRefs[id] = refElement;
    cleanEditableElements(state, keepEditableText);
  },
  [cleanSelectedElement]: state => {
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [selectFrame]: state => {
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [removeSelectedElement]: (state, { payload }) => {
    delete state.selectedElements[payload];
    delete state.selectedRefs[payload];
  },
  [setEditableText]: (state, { payload }) => {
    cleanEditableElements(state);
    state.editableText = payload;
  },
  [setEditableImage]: (state, { payload: { uuid } }) => {
    state.elements = state.elements.map(element => {
      if (element.uuid === uuid) {
        element.temporaryRotation = element.rotation;
        const attrs = resetCroppedAttributes(element.crop, element);
        element.temporaryCropAttrs = {
          scaleX: element.scaleX,
          scaleY: element.scaleY,
          x: element.x,
          y: element.y,
          crop: element.crop,
        };
        element = { ...element, ...attrs, rotation: 0 };
      }
      return element;
    });
    state.editableImage = uuid;
  },
  [setEditableTag]: (state, { payload }) => {
    cleanEditableElements(state);
    state.editableTag = payload;
    state.selectedElements = {};
    state.selectedRefs = {};
  },
  [setEditableFrameTitle]: (state, { payload }) => {
    state.editableFrameTitle = payload;
  },
  [moveElementBackward]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    if (selectedElementsList.length === 1) {
      const selectedElementId = selectedElementsList[0];
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === selectedElementId);
      if (selectedElementIndex !== 0) {
        const temp = state.elements[selectedElementIndex];
        state.elements[selectedElementIndex] = state.elements[selectedElementIndex - 1];
        state.elements[selectedElementIndex - 1] = temp;
      }
    }
  },
  [moveElementToBack]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    if (selectedElementsList.length === 1) {
      const selectedElementId = selectedElementsList[0];
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === selectedElementId);
      if (selectedElementIndex !== 0) {
        const temp = state.elements.splice(selectedElementIndex, 1)[0];
        state.elements.unshift(temp);
      }
    }
  },
  [moveElementForward]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    if (selectedElementsList.length === 1) {
      const selectedElementId = selectedElementsList[0];
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === selectedElementId);
      if (selectedElementIndex !== state.elements.length - 1) {
        const temp = state.elements[selectedElementIndex];
        state.elements[selectedElementIndex] = state.elements[selectedElementIndex + 1];
        state.elements[selectedElementIndex + 1] = temp;
      }
    }
  },
  [moveElementToFront]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    if (selectedElementsList.length === 1) {
      const selectedElementId = selectedElementsList[0];
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === selectedElementId);
      if (selectedElementIndex !== state.elements.length - 1) {
        const temp = state.elements.splice(selectedElementIndex, 1)[0];
        state.elements.push(temp);
      }
    }
  },
  [lockElement]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    selectedElementsList.forEach(elemId => {
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
      state.elements[selectedElementIndex].unlocked = false;
    });
  },
  [unlockElement]: state => {
    const selectedElementsList = Object.keys(state.selectedElements);
    selectedElementsList.forEach(elemId => {
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
      state.elements[selectedElementIndex].unlocked = true;
    });
  },
  [cropImage]: (state, { payload: { crop, scaleX, scaleY, x, y } }) => {
    if (state.editableImage) {
      state.elements.map(element => {
        if (element.uuid === state.editableImage) {
          element.temporaryCropAttrs = { crop, scaleX, scaleY, x, y };
        }
        return element;
      });
      state.selectedElements[state.editableImage] = state.editableImage;
      state.selectedRefs = {};
      state.elements = cleanEditableImage(state.elements, state.editableImage);
      state.editableImage = undefined;
    }
  },
  [moveElementWithKeyboard]: (state, { payload: { code, delta } }) => {
    const selectedElementsList = Object.keys(state.selectedElements);
    selectedElementsList.forEach(elemId => {
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
      if (code === LEFT_ARROW_CODE || code === RIGHT_ARROW_CODE) {
        state.elements[selectedElementIndex].x = (state.elements[selectedElementIndex].x || 0) +
          delta;
      } else if (code === TOP_ARROW_CODE || code === BOTTOM_ARROW_CODE) {
        state.elements[selectedElementIndex].y = (state.elements[selectedElementIndex].y || 0) +
        delta;
      }
    });
  },
  [deleteElementSuccess]: state => {
    const filesDeleted = state.elements.filter(element => (
      state.selectedElements[element.uuid] && MEDIA_ELEMENTS.includes(element.type)
    ));

    if (filesDeleted.length) {
      filesDeleted.forEach(file => {
        if (file.type === VIDEO_ELEMENT) {
          deleteFromVideoList(state, file);
        } else if (file.type === IMAGE_ELEMENT && isGif(file.src)) {
          deleteFromGifList(state, file);
        }
      });
    }

    const groups = state.elements.filter(
      element => (state.selectedElements[element.uuid] && element.type === GROUP_ELEMENT),
    );
    groups.forEach(group => {
      deleteGroup(state, group);
    });
    state.elements = state.elements.filter(element => !state.selectedElements[element.uuid]);
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [deleteElementById]: (state, { payload: { id } }) => {
    const element = state.elements.find(elem => elem.uuid === id);
    if (element.type === VIDEO_ELEMENT) {
      deleteFromVideoList(state, element);
    } else if (element.type === IMAGE_ELEMENT && isGif(element.src)) {
      deleteFromGifList(state, element);
    }
    if (element.type === GROUP_ELEMENT) {
      deleteGroup(state, element);
    }
    state.elements = state.elements.filter(elem => elem.uuid !== id);
  },
  [ungroupElementsSuccess]: (state, { payload: { scale: scaleCanvas, uuid } }) => {
    const scale = 1 / scaleCanvas;
    const elementsInGroup = state.groupsOfElementsById[uuid];
    const refGroup = state.selectedRefs[uuid]?.current;
    const canvasAttrs = {
      x: refGroup.getLayer().getX(),
      y: refGroup.getLayer().getY(),
    };

    const children = refGroup.getChildren(node => !node.getId().startsWith(TAG_MARKER_PREFIX_ID));
    children.forEach((element, index) => {
      const matrix = element.getAbsoluteTransform().getMatrix();
      const attrs = decompose(matrix);
      const item = getGroupItemAttrs(
        attrs, elementsInGroup, index, refGroup.rotation(), scale, canvasAttrs,
      );
      state.elements = [...state.elements, item];
    });
    state.elements = state.elements.filter(element => element.uuid !== uuid);
    delete state.groupsOfElementsById[uuid];
    state.selectedElements = {};
    state.selectedRefs = {};
  },
  [applyTemplateToProjectSuccess]: (state, { payload: { project } }) => {
    saveProjectState(state, project);
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [applySuggestionToProjectSuccess]: (state, { payload: { project } }) => {
    saveProjectState(state, project);
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [saveTemplateSuccess]: (state, { payload: { template } }) => {
    state.template = template;
    const { project } = template;
    saveProjectState(state, project);
  },
  [updateExistingTemplateSuccess]: (state, { payload: { template } }) => {
    state.template = template;
    const { project } = template;
    saveProjectState(state, project);
  },
  [updateMetadataTemplateSuccess]: (state, { payload: { template } }) => {
    if (template.project.id === state.id) {
      delete template.project;
      state.template = template;
    }
  },
  [addLinkToElement]: (state, { payload: { link } }) => {
    const selectedElementsList = Object.keys(state.selectedElements);
    selectedElementsList.forEach(elemId => {
      const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
      state.elements[selectedElementIndex].link = link;
    });
  },
  [saveVideosPosition]: (state, { payload: { videos } }) => {
    state.videoIdsByPosition = videos;
  },
  [saveGifsPosition]: (state, { payload: { gifs } }) => {
    state.gifIdsByPosition = gifs;
  },
  [receiveShotstackVideo]: (state, { payload: { projectId, videoUrls } }) => {
    if (projectId === state.id) {
      state.videoUrls = videoUrls;
    }
  },
  [chooseEyeDropperColor]: (state, { payload: { color, source } }) => {
    if (source === BACKGROUND_COLOR_EYEDROPPER_SOURCE) {
      state.color = { hex: color, alpha: 100 };
    } else if (source === FILL_COLOR_EYEDROPPER_SOURCE) {
      const selectedElementsList = Object.keys(state.selectedElements);
      selectedElementsList.forEach(elemId => {
        const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
        state.elements[selectedElementIndex].color = { hex: color, alpha: 100 };
      });
    } else if (source === BORDER_COLOR_EYEDROPPER_SOURCE) {
      const selectedElementsList = Object.keys(state.selectedElements);
      selectedElementsList.forEach(elemId => {
        const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
        state.elements[selectedElementIndex].borderColor = { hex: color, alpha: 100 };
      });
    } else if (source === TEXT_COLOR_EYEDROPPER_SOURCE) {
      const selectedElementsList = Object.keys(state.selectedElements);
      selectedElementsList.forEach(elemId => {
        const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
        state.elements[selectedElementIndex].color = { hex: color, alpha: 100 };
        state.elements[selectedElementIndex].brandLibraryStyleId = undefined;
      });
    } else if (source === SHADOW_COLOR_EYEDROPPER_SOURCE) {
      const selectedElementsList = Object.keys(state.selectedElements);
      selectedElementsList.forEach(elemId => {
        const selectedElementIndex = state.elements.findIndex(e => e.uuid === elemId);
        state.elements[selectedElementIndex].shadowColor = { hex: color, alpha: 100 };
      });
    }
  },
  [addTagToElement]: (state, { payload: { category } }) => {
    state.elements = state.elements.map(elem => {
      if (elem.uuid === state.editableTag.uuid) {
        elem.tag = { category };
      }
      return elem;
    });
    state.editableTag = undefined;
  },
  [removeTagFromElement]: state => {
    state.elements = state.elements.map(elem => {
      if (elem.uuid === state.editableTag.uuid) {
        delete elem.tag;
      }
      return elem;
    });
    state.editableTag = undefined;
  },
  [moveFrame]: (state, { payload: { indexes, result } }) => {
    state.elements = state.elements.map(elem => {
      if (result[elem.uuid]) {
        elem.x = result[elem.uuid];
      }
      return elem;
    });
    const oldSourceTitle = state.frameTitles[indexes.source];
    state.frameTitles.splice(indexes.source, 1);
    state.frameTitles.splice(indexes.destination, 0, oldSourceTitle);
  },
  [addFrame]: (state, { payload: { result, index } }) => {
    state.elements = state.elements.map(elem => {
      if (result[elem.uuid]) {
        elem.x = result[elem.uuid];
      }
      return elem;
    });
    state.size += 1;
    state.frameTitles.splice(index, 0, '');
  },
  [removeImageBackgroundSuccess]: (state, { payload: { imageUUID } }) => {
    state.imageUUIDToRemoveBackground = imageUUID;
    state.selectedElements = {};
    state.selectedRefs = {};
    cleanEditableElements(state);
  },
  [removeImageBackgroundRequest]: (state, { payload: { imageUUID } }) => {
    state.imageUUIDToRemoveBackground = imageUUID;
  },
  [removeImageBackgroundError]: state => {
    state.imageUUIDToRemoveBackground = undefined;
  },
  [replaceBgRemovedImage]: (state, { payload: { projectId, bgRemovedImage } }) => {
    if (state.id === projectId) {
      state.elements = state.elements.map(elem => {
        if (elem.uuid === state.imageUUIDToRemoveBackground) {
          elem.src = bgRemovedImage.url;

          if (elem.crop) {
            elem = getElementBeforeCrop(elem);
          }

          // If the bgRemoveImage is different from the original, we have to scale the element
          // This only happens when the original image is > 2048*2048 (plugin imposed limit)
          if (elem.width !== bgRemovedImage.width || elem.height !== bgRemovedImage.height) {
            elem.scaleX = (elem.width * elem.scaleX) / bgRemovedImage.width;
            elem.width = bgRemovedImage.width;
            elem.scaleY = (elem.height * elem.scaleY) / bgRemovedImage.height;
            elem.height = bgRemovedImage.height;
          }

          const cropWidth = bgRemovedImage.cropBottomRight.x - bgRemovedImage.cropTopLeft.x;
          const cropHeight = bgRemovedImage.cropBottomRight.y - bgRemovedImage.cropTopLeft.y;

          if (bgRemovedImage.width !== cropWidth || bgRemovedImage.height !== cropHeight) {
            elem = cropMediaElement(elem, {
              topLeft: {
                x: bgRemovedImage.cropTopLeft.x,
                y: bgRemovedImage.cropTopLeft.y,
              },
              width: cropWidth,
              height: cropHeight,
            });
          }
        }
        return elem;
      });

      state.imageUUIDToRemoveBackground = undefined;
    }
  },
  [showGeneratedCollageSuccess]: (state, { payload: { projectId, generatedCollage } }) => {
    if (projectId === state.id) {
      saveProjectState(state, generatedCollage);
      state.selectedElements = {};
      state.selectedRefs = {};
      cleanEditableElements(state);
    }
  },
  [undoCollage]: (state, { payload: { projectId, projectForUndo } }) => {
    if (projectId === state.id) {
      Object.keys(projectForUndo).forEach(key => {
        state[key] = projectForUndo[key];
      });
    }
  },
  [getProjectReset]: () => initialState,
  [getProjectError]: () => initialState,
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
