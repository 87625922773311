import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  getBillingInformation,
  GET_BILLING_INFORMATION,
  getPersonalInformation,
  GET_PERSONAL_INFORMATION,
} from 'src/actions/userActions';
import { reset } from 'src/actions/statusActions';
import { PersonalInformation } from 'src/pages/profile/personal-information';
import { EmailNotification } from 'src/pages/profile/email-notification';
import { BillingInformation } from 'src/pages/profile/billing-information';
import { TipSettings } from 'src/pages/profile/tips-settings';
import { EmbedSettings } from 'src/pages/profile/embed-settings';
import { DeleteUser } from 'src/pages/profile/delete-user';
import { ENABLE_DESIGN_TIPS } from 'src/constants/general';
import { Navbar } from 'src/common/navbar';
import styles from './Profile.module.scss';

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonalInformation());
    dispatch(getBillingInformation());

    return () => {
      dispatch(reset(GET_PERSONAL_INFORMATION));
      dispatch(reset(GET_BILLING_INFORMATION));
    };
  }, [dispatch]);

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.subnav}>
        <h1 className={styles.title}>My info</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.contentForm}>
          <PersonalInformation />
          <BillingInformation />
          <EmailNotification />
          {ENABLE_DESIGN_TIPS && <TipSettings />}
          <EmbedSettings />
          <DeleteUser />
        </div>
      </div>
    </div>
  );
};
export { Profile };
