import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { reset } from 'src/actions/statusActions';
import {
  useStatus,
  useExportPreview,
  useDownloadPreviews,
  useCopyEmbedCode,
  useProjectSelector,
} from 'src/hooks';
import { ERROR, SUCCESS } from 'src/constants/status';
import {
  getProject,
  GET_PROJECT,
} from 'src/actions/projectActions';
import { EXPORT_CALLBACK } from 'src/constants/general';
import { getPersonalInformation, GET_PERSONAL_INFORMATION } from 'src/actions/userActions';
import { ExportButtons } from './export-buttons';
import { ExportSlider } from './export-slider';
import { ExportPreviewSelection } from './export-preview-selection';
import styles from './ExportPreview.module.scss';

const ExportPreview = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const { showSlider } = useExportPreview();
  const { name, previews } = useProjectSelector();

  useEffect(() => {
    dispatch(getPersonalInformation());
    dispatch(getProject(id));

    return () => {
      dispatch(reset(GET_PERSONAL_INFORMATION));
      dispatch(reset(GET_PROJECT));
    };
  }, [dispatch, id]);

  const { status: getProjectStatus } = useStatus(GET_PROJECT);
  const { status: getUserStatus } = useStatus(GET_PERSONAL_INFORMATION);

  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const { downloadPreviews } = useDownloadPreviews();
  const { generateEmbedCode } = useCopyEmbedCode();

  const actionToTake = history.location.state?.data?.action;

  useLayoutEffect(() => {
    if (getProjectStatus === SUCCESS && actionToTake && showSlider) {
      if (actionToTake === EXPORT_CALLBACK.DOWNLOAD_PREVIEWS) {
        downloadPreviews();
      } else if (actionToTake === EXPORT_CALLBACK.GENERATE_EMBED_CODE) {
        generateEmbedCode(false);
      }
      history.replace({ state: {} });
    }
  }, [history, getProjectStatus, downloadPreviews, generateEmbedCode, showSlider, actionToTake]);

  useLayoutEffect(() => {
    if (getProjectStatus === SUCCESS) {
      setSelectedIndexes(Array.from({ length: previews.length }, (v, i) => i));
    }
  }, [getProjectStatus, previews]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <span className={styles.name}>{name}</span>
        <ExportButtons
          disabled={getProjectStatus === ERROR || getUserStatus === ERROR || !showSlider}
          selectedIndexes={selectedIndexes}
        />
      </div>
      {(getProjectStatus === ERROR || getUserStatus === ERROR) && (
        <div className={styles.error}>
          Oops! This project couldn&apos;t be loaded, please try again.
        </div>
      )}
      {getProjectStatus === SUCCESS && getUserStatus === SUCCESS && (
        <>
          <ExportSlider loading={!showSlider} />
          <ExportPreviewSelection
            setSelectedIndexes={setSelectedIndexes}
            selectedIndexes={selectedIndexes}
          />
        </>
      )}
    </div>
  );
};

export { ExportPreview };
