import React from 'react';
import * as Sentry from '@sentry/browser';

import { ErrorImg, ErrorImg2x, ErrorImg3x } from 'src/assets/images/error-boundary';
import { Button } from 'src/common/button';
import styles from './ErrorBoundary.module.scss';

const ErrorBoundary = () => (
  <div className={styles.container}>
    <img
      src={ErrorImg}
      alt="Error"
      srcSet={`${ErrorImg2x} 2x, ${ErrorImg3x} 3x`}
      className={styles.image}
    />
    <span className={styles.text}>Something isn’t right.</span>
    <Button
      onClick={() => {
        const eventId = Sentry.lastEventId();
        if (!eventId) {
          Sentry.captureMessage('Someone is trying to send feedback for an error');
        } else {
          Sentry.showReportDialog({ eventId });
        }
      }}
      alternativeCTA
      className={styles.feedbackButton}
    >
      Send feedback
    </Button>
  </div>
);

export { ErrorBoundary };
