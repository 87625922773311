export const getPositionInCanvas = ({ x, width, scaleX, widthLayout }) => {
  let position = 0;
  const targetWidth = width * scaleX;
  const xCoordinate = x - (targetWidth / 2);
  position = Math.ceil(xCoordinate / widthLayout) - 1;
  return position;
};

export const calculatePositionVideoExport = (video, page, layoutWidth, layoutHeight) => {
  const xCoordinate = video.x - (page * layoutWidth);
  const yCoordinate = video.y;
  const width = video.width * video.scaleX;
  const height = video.height * video.scaleY;
  const xPos = xCoordinate - (width / 2);
  const yPos = -(yCoordinate - (height / 2));
  const xExport = ((xPos * 100) / layoutWidth) / 100;
  const yExport = ((yPos * 100) / layoutHeight) / 100;
  return { xExport, yExport };
};

export const calculateScaleVideo = (video, layoutWidth, layoutHeight) => {
  const targetWidth = video.width * video.scaleX;
  const targetHeight = video.height * video.scaleY;
  const percentageScaleX = ((targetWidth * 100) / layoutWidth) / 100;
  const percentageScaleY = ((targetHeight * 100) / layoutHeight) / 100;
  let scale = 0;
  if (percentageScaleX > percentageScaleY) {
    scale = percentageScaleY;
  } else {
    scale = percentageScaleX;
  }
  return (scale || 0);
};

export const snapImageOfVideo = (videoUrl, callbackFn) => {
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.muted = true;
  video.playsInline = true;
  video.setAttribute('crossOrigin', '*');
  video.src = videoUrl;
  video.addEventListener('loadeddata', () => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    video.pause();
    const canvasImage = canvas.toDataURL();

    callbackFn(canvasImage, video);

    video.src = ''; // setting to an empty string stops video from loading
    video.remove();
    canvas.remove();
  }, false);
  video.play();
};

export const isVideo = (assetUrl) => (
  /(mp4|mov)$/ig.test(assetUrl)
);

const saveIdPosition = (id, idsByPosition, positionInCanvas) => {
  const keys = Object.keys(idsByPosition);
  if (!idsByPosition[positionInCanvas]) {
    idsByPosition[positionInCanvas] = [];
  }
  keys.forEach(page => {
    if (idsByPosition[page].includes(id)) {
      idsByPosition[page] = idsByPosition[page].filter(uuid => uuid !== id);
    }
  });
  idsByPosition[positionInCanvas].push(id);
  return idsByPosition;
};

export const saveVideoCanvasPosition = (project, attrsVideo) => {
  const { videoIdsByPosition, layout } = project;
  const positionInCanvas = getPositionInCanvas({
    x: attrsVideo.x,
    width: attrsVideo.width,
    scaleX: attrsVideo.scaleX,
    widthLayout: layout.width,
  });
  return saveIdPosition(attrsVideo.uuid, videoIdsByPosition, positionInCanvas);
};

export const saveGifCanvasPosition = (project, attrsGif) => {
  const { gifIdsByPosition, layout } = project;
  const positionInCanvas = getPositionInCanvas({
    x: attrsGif.x,
    width: attrsGif.width,
    scaleX: attrsGif.scaleX,
    widthLayout: layout.width,
  });
  return saveIdPosition(attrsGif.uuid, gifIdsByPosition, positionInCanvas);
};

export const deleteFromVideoList = (state, video) => {
  Object.keys(state.videoIdsByPosition).forEach(page => {
    if (state.videoIdsByPosition[page].includes(video.uuid)) {
      state.videoIdsByPosition[page] = state.videoIdsByPosition[page].filter(
        uuid => uuid !== video.uuid,
      );
    }
  });
};

export const deleteFromGifList = (state, gif) => {
  Object.keys(state.gifIdsByPosition).forEach(page => {
    if (state.gifIdsByPosition[page].includes(gif.uuid)) {
      state.gifIdsByPosition[page] = state.gifIdsByPosition[page].filter(
        uuid => uuid !== gif.uuid,
      );
    }
  });
};
