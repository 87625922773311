import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import UnsubscribeImg from 'src/assets/images/unsubscribe/mail.png';
import { cancelEmailSubscription } from 'src/actions/userSettingsActions';
import styles from './UnsubscribeToEmail.module.scss';

const UnsubscribeToEmail = () => {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    const cancelSubscription = async () => dispatch(cancelEmailSubscription(token));
    cancelSubscription();
  }, [dispatch, token]);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img
            src={UnsubscribeImg}
            alt="Unsubscribe"
            className={styles.image}
          />
          <h1 className={styles.header}>Sorry to see you go!</h1>
          <span className={styles.text}>
            You&apos;ve successfully unsubscribed from our emails.
          </span>
          <span className={styles.textSecondary}>
            You will continue to receive essential account-related information.
          </span>
        </div>
      </div>
    </div>
  );
};

export { UnsubscribeToEmail };
