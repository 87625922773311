import * as Sentry from '@sentry/browser';

/* eslint-disable max-len */
import {
  OnePageSupportError,
  NoIGBusinessAccountError,
  NoPagesError,
  MediaRetrieveError,
} from 'src/utils/igIntegrationErrors';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const facebookPermissions = [
  'public_profile',
  'pages_show_list',
  'pages_read_engagement',
  'instagram_basic',
  'instagram_manage_insights',
];

const sendToSentry = (msg, err) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    // eslint-disable-next-line no-console
    console.log(msg, err);
  }
  msg && Sentry.captureMessage(msg);
  err && Sentry.captureException(err);
};

const includedIn = (arr, target) => arr.every(v => target.includes(v));

const logout = () => new Promise((resolve, reject) => {
  try {
    window.FB.logout(() => {
      resolve();
    });
  } catch (err) {
    sendToSentry('Logout Err', err);
    reject(err);
  }
});

const getIGBusinessAccountIdFromLoggedUser = () => new Promise((resolve, reject) => {
  try {
    window.FB.api(
      '/me/accounts',
      'GET',
      {},
      (accountRes) => {
        if (!accountRes) {
          sendToSentry(`NoPagesError (1) => ${JSON.stringify(accountRes)}`);
          reject(new NoPagesError());
        } else {
          const { data } = accountRes;
          if (!data || !Array.isArray(data)) {
            sendToSentry(`NoPagesError (2) => ${JSON.stringify(data)}`);
            logout();
            reject(new NoPagesError());
          } else if (data.length !== 1) {
            logout();
            if (data.length === 0) {
              sendToSentry(`NoPagesError (3) => ${JSON.stringify(data)}`);
              reject(new NoPagesError());
            } else {
              sendToSentry(`OnePageSupportError => ${JSON.stringify(data)}`);
              reject(new OnePageSupportError());
            }
          } else {
            const page = data[0];

            try {
              window.FB.api(
                `/${page.id}/`,
                'GET',
                { fields: 'instagram_business_account' },
                (igBusinessRes) => {
                  if (!igBusinessRes || !igBusinessRes.instagram_business_account) {
                    sendToSentry(`NoIGBusinessAccountError => ${JSON.stringify(igBusinessRes)}`);
                    logout();
                    reject(new NoIGBusinessAccountError());
                  } else {
                    const { instagram_business_account: igBusinessAccount } = igBusinessRes;

                    resolve(igBusinessAccount.id);
                  }
                },
              );
            } catch (err) {
              sendToSentry('Unexpected error (1)', err);
              logout();
              reject(err);
            }
          }
        }
      },
    );
  } catch (err) {
    sendToSentry('Unexpected error (2)', err);
    logout();
    reject(err);
  }
});

const processPostChildren = (children) => new Promise((resolve, reject) => {
  try {
    Promise.all(
      children.map((c) => new Promise((cResolve, cReject) => {
        try {
          window.FB.api(
            `/${c.id}/`,
            'GET',
            { fields: 'media_url,shortcode,timestamp' },
            (cData) => {
              cResolve({
                id: cData.id,
                mediaUrl: cData.media_url,
                shortcode: cData.shortcode,
                timestamp: cData.timestamp,
              });
            },
          );
        } catch (err) {
          sendToSentry('Unexpected error (3)', err);
          cReject(err);
        }
      })),
    ).then((processedChildren) => {
      resolve(processedChildren);
    }).catch((err) => {
      sendToSentry('Unexpected error (4)', err);
      reject(err);
    });
  } catch (err) {
    reject(err);
  }
});

class InstagramAnalyticsService {
  static initFacebookSDK() {
    return new Promise((resolve, reject) => {
      try {
        // wait for facebook sdk to initialize before continue
        window.fbAsyncInit = () => {
          window.FB && window.FB.init({
            appId: facebookAppId,
            cookie: true,
            xfbml: true,
            version: 'v9.0',
          });

          resolve(true);
        };

        // load facebook sdk script
        // eslint-disable-next-line func-names
        (function (d, s, id) {
          const
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          const js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      } catch (err) {
        sendToSentry('Unexpected error (5)', err);
        reject(err);
      }
    });
  }

  static isLoggedInToFB() {
    return new Promise((resolve, reject) => {
      try {
        window.FB.getLoginStatus((response) => {
          if (response?.status === 'connected') {
            getIGBusinessAccountIdFromLoggedUser().then((igBusinessAccountId) => {
              resolve({
                loggedIn: true,
                igBusinessAccountId,
              });
            }).catch(err => reject(err));
          } else {
            resolve({
              loggedIn: false,
              igBusinessAccountId: null,
            });
          }
        });
      } catch (err) {
        sendToSentry('Unexpected error (6)', err);
        reject(err);
      }
    });
  }

  static loginToFB() {
    return new Promise((resolve, reject) => {
      try {
        window.FB.login((loginRes) => {
          if (loginRes.status === 'connected') {
            const hasEveryPermision = includedIn(facebookPermissions, loginRes.authResponse.grantedScopes.split(','));
            if (!hasEveryPermision) {
              logout().then(() => {
                reject(new Error(`User doesn't give all the needed permissions, only granted this: ${loginRes.authResponse.grantedScopes}`));
              });
            } else {
              resolve();
            }
          } else {
            sendToSentry(`FB login status is not connected: => ${loginRes}`);
            reject(new Error(loginRes.status));
          }
        },
        {
          scope: facebookPermissions.join(','),
          return_scopes: true,
        });
      } catch (err) {
        sendToSentry('Unexpected error (7)', err);
        reject(err);
      }
    });
  }

  static logoutFromFB() {
    return logout();
  }

  static getPosts(igBusinessAccountId) {
    return new Promise((resolve, reject) => {
      try {
        window.FB.api(
          `/${igBusinessAccountId}`,
          'GET',
          { fields: 'media{media_url,thumbnail_url,caption,timestamp,shortcode}' }, // Using nested requests https://developers.facebook.com/docs/graph-api/advanced/
          (queryRes) => {
            if (!queryRes) {
              sendToSentry('MediaRetrieveError');
              reject(new MediaRetrieveError());
            } else {
              const data = queryRes.media?.data.map((post) => ({
                id: post.id,
                mediaUrl: post.media_url,
                caption: post.caption,
                thumbnailUrl: post.thumbnail_url,
                timestamp: post.timestamp,
                shortcode: post.shortcode,
              }));
              resolve({ posts: data || [] });
            }
          },
        );
      } catch (err) {
        sendToSentry('Unexpected error (8)', err);
        reject(err);
      }
    });
  }

  static getPostData(postId) {
    return new Promise((resolve, reject) => {
      try {
        window.FB.api(
          `/${postId}/`,
          'GET',
          { fields: 'media_url,timestamp,permalink,like_count,comments_count,username,children' },
          (response) => {
            const {
              permalink,
              like_count: likeCount,
              comments_count: commentsCount,
              timestamp,
              media_url: mediaUrl,
              children: rawChildren,
              username,
            } = response;

            const commonRes = { permalink, likeCount, commentsCount, timestamp, mediaUrl, postId, username };

            if (rawChildren) {
              processPostChildren(rawChildren.data).then((children) => {
                resolve({ ...commonRes, children });
              }).catch((err) => reject(err));
            } else {
              resolve({ ...commonRes, children: null });
            }
          },
        );
      } catch (err) {
        sendToSentry('Unexpected error (9)', err);
        reject(err);
      }
    });
  }

  static getPostInsights(postId) {
    return new Promise((resolve, reject) => {
      try {
        window.FB.api(
          `/${postId}/insights`,
          'GET',
          { metric: 'engagement,impressions,reach' },
          (insightsRes) => {
            const { data } = insightsRes;
            const impressionsObj = data?.find(elem => elem.name === 'impressions');
            const impressions = impressionsObj?.values?.length ? impressionsObj.values[0].value : 0;

            const reachObj = data?.find(elem => elem.name === 'reach');
            const reach = reachObj?.values?.length ? reachObj.values[0].value : 0;

            resolve({ impressions, reach, postId });
          },
        );
      } catch (err) {
        sendToSentry('Unexpected error (10)', err);
        reject(err);
      }
    });
  }
}

export { InstagramAnalyticsService };
