import React from 'react';
import { func } from 'prop-types';

import { useSession } from 'src/hooks';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import styles from './UpdateUrl.module.scss';

const UpdateUrl = ({ onClick }) => {
  const { account } = useSession();
  const { brandAssetStatus } = account || {};

  return (
    <button
      onClick={onClick}
      className={styles.updateUrlButton}
      disabled={brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY}
    >
      Update URL
    </button>
  );
};

UpdateUrl.propTypes = {
  onClick: func.isRequired,
};

export { UpdateUrl };
