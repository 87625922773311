import React, { useCallback, useLayoutEffect, useState } from 'react';
import { arrayOf, func, string, shape, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import ExpandIcon from 'src/assets/icons/expand-content.svg';
import { OPEN_FULLSCREEN_ACTION } from 'src/constants/general';
import { CAMPAIGN_UPLOAD } from 'src/constants/uploadFile';
import { trackFullscreenViewOfSquadEvent } from 'src/utils/analytics';
import { FullscreenModal } from '../fullscreen-modal';
import styles from './FullscreenButton.module.scss';

const FullscreenButton = ({
  title,
  pagesToShow,
  nextPage,
  cardComponent,
  selected,
  setSelected,
  actionButton,
  selectDisabled = false,
  source,
}) => {
  const history = useHistory();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const launchFullscreen = useCallback((origin = 'userClick') => {
    setIsFullscreen(true);
    if (source === CAMPAIGN_UPLOAD) {
      trackFullscreenViewOfSquadEvent(origin);
    }
  }, [source]);

  const onClose = () => {
    setIsFullscreen(false);
  };

  useLayoutEffect(() => {
    const actionToTake = history.location.state?.data?.action;
    if (source === CAMPAIGN_UPLOAD && actionToTake === OPEN_FULLSCREEN_ACTION) {
      launchFullscreen('whatsNewModal');
      history.replace({ state: {} });
    }
  }, [history, launchFullscreen, source]);

  return (
    <>
      <button className={styles.button} onClick={() => launchFullscreen()}>
        <span className={styles.text}>Launch Fullscreen</span>
        <img src={ExpandIcon} alt="Launch Fullscreen" />
      </button>
      {isFullscreen && (
        <FullscreenModal
          title={title}
          onClose={onClose}
          pagesToShow={pagesToShow}
          nextPage={nextPage}
          cardComponent={cardComponent}
          selected={selected}
          setSelected={setSelected}
          actionButton={actionButton}
          selectDisabled={selectDisabled}
          source={source}
        />
      )}
    </>
  );
};

FullscreenButton.propTypes = {
  title: string.isRequired,
  pagesToShow: arrayOf(shape({ url: string })),
  nextPage: func.isRequired,
  cardComponent: func.isRequired,
  selected: arrayOf(shape({ url: string })),
  setSelected: func.isRequired,
  actionButton: shape({
    disabled: bool,
    onClick: func.isRequired,
    className: string,
    loading: bool,
    text: string.isRequired,
  }),
  selectDisabled: bool,
  source: string.isRequired,
};

export { FullscreenButton };
