import { HttpClient } from '../clients';

class KeywordService {
  static getKeywords() {
    return HttpClient.get('/keywords');
  }

  static searchTemplatesByKeywords(keyword, page, perPage, accountId, typeOfResults) {
    return HttpClient.get('/keywords/search', {
      params: { keyword, page, perPage, accountId, typeOfResults },
    });
  }
}

export { KeywordService };
