import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  addMessagesToChat,
  answerToChat,
  discardGeneratedCollageData,
  showGeneratedCollage,
} from 'src/actions/collageActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import styles from './CollageThumbnail.module.scss';

const CollageThumbnail = () => {
  const dispatch = useDispatch();

  const {
    generatedCollage,
    isGeneratedCollageVisible,
    projectId,
    chatMessages,
  } = useSelector(({ collage }) => ({
    generatedCollage: collage.generatedCollageToShow,
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
    projectId: collage.projectId,
    chatMessages: collage.chatMessages,
  }));

  const showMe = useCallback(async (userAnswer) => {
    await dispatch(answerToChat({ answer: userAnswer }));
    dispatch(showGeneratedCollage(projectId, generatedCollage));
  }, [dispatch, generatedCollage, projectId]);

  const noThanks = useCallback(async () => {
    await dispatch(discardGeneratedCollageData(projectId));
    dispatch(answerToChat({
      answer: 'No thanks',
      messages: [
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Okay, we\'ll be here if you want to design another one.',
          id: uuidv4(),
        },
        {
          type: COLLAGE_CHAT_TYPE.FINISH_ANSWER,
          id: uuidv4(),
        },
      ],
    }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (chatMessages[chatMessages.length - 1].type === COLLAGE_CHAT_TYPE.COLLAGE_THUMBNAIL) {
      dispatch(addMessagesToChat({
        messages: [
          {
            type: COLLAGE_CHAT_TYPE.ANSWER,
            text: 'Show me',
            id: uuidv4(),
            onClick: () => showMe('Show me'),
          },
          {
            type: COLLAGE_CHAT_TYPE.ANSWER,
            text: 'No thanks',
            id: uuidv4(),
            onClick: noThanks,
          },
        ],
      }));
    }
  }, [chatMessages, dispatch, noThanks, showMe]);

  return (
    <div className={styles.container}>
      {generatedCollage && !isGeneratedCollageVisible && (
        <img
          src={generatedCollage.thumbnail}
          className={styles.thumbnail}
          alt="Generated project thumbnail"
        />
      )}
    </div>
  );
};

export { CollageThumbnail };
