import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useProjectSelector, useSession } from 'src/hooks';
import { CANVAS_LINKS_PLANS } from 'src/constants/memberships';
import { addLinkToElement } from 'src/actions/projectActions';
import { Modal } from 'src/common/modal';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { isElementOverlapping } from 'src/utils/canvasHelpers';
import { TEXT_ELEMENT } from 'src/constants/canvasElements';
import { setEditableHyperlink } from 'src/actions/canvasActions';
import styles from './CanvasHyperlink.module.scss';

const CanvasHyperlink = () => {
  const dispatch = useDispatch();
  const { elements, selectedElements, layout: { width: widthLayout } } = useProjectSelector();

  const { isSubscriptionActive, membershipPlan } = useSession();

  const selectedElementsList = Object.keys(selectedElements);
  let selectedElementStore;
  let isOverlapping = false;

  if (selectedElementsList.length === 1) {
    selectedElementStore = elements.find(
      element => element?.uuid === selectedElementsList[0],
    );
    const isText = selectedElementStore?.type === TEXT_ELEMENT;
    isOverlapping = isElementOverlapping({
      xElement: selectedElementStore?.x,
      widthElement: selectedElementStore?.width,
      scaleXElement: selectedElementStore?.scaleX,
      widthLayout,
      isText,
    });
  }

  const linkEnabled = isSubscriptionActive && CANVAS_LINKS_PLANS.includes(membershipPlan);

  const [link, setLink] = useState(selectedElementStore?.link || 'https://');

  const handleChange = useCallback(({ target: { value } }) => setLink(value), []);

  const onClose = () => {
    dispatch(setEditableHyperlink());
  };

  const handleSubmit = () => {
    dispatch(addLinkToElement({ link }));
    onClose();
  };

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Modal
      isShowing
      hide={onClose}
      noBackground
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>Add a link</span>
        </div>
        {isOverlapping && linkEnabled && (
          <div className={styles.errorContainer}>
            <span className={styles.errorText}>
              Linked objects cannot overlap canvases
            </span>
          </div>
        )}
        {!linkEnabled && (
          <div className={styles.errorContainer}>
            <span className={styles.errorText}>
              Upgrade to add links
            </span>
          </div>
        )}
        <span className={styles.website}>Website URL</span>
        <Input
          type="link"
          name="link"
          containerClassName={styles.inputContainer}
          disabled={isOverlapping}
          value={link}
          onChange={handleChange}
          ref={inputRef}
        />
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onClose}
            secondary
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            disabled={isOverlapping || !linkEnabled}
            onClick={handleSubmit}
            alternativeCTA
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { CanvasHyperlink };
