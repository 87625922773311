import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import {
  GET_USER_FOR_AGENCY_DOWNGRADE,
  getUserForAgencyDowngrade,
} from 'src/actions/accountActions';
import { Spinner } from 'src/common/spinner';
import { reset } from 'src/actions/statusActions';
import { useSession, useStatus } from 'src/hooks';
import { Navbar } from 'src/common/navbar';
import { LOADING, SUCCESS } from 'src/constants/status';
import { PLAN } from 'src/constants/memberships';
import { PlanDowngrade } from 'src/pages/downgrade-agency/plan-downgrade';
import { QuantityDowngrade } from 'src/pages/downgrade-agency/quantity-downgrade';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './DowngradeAgency.module.scss';

const DowngradeAgency = () => {
  const dispatch = useDispatch();

  const { user } = useSession();

  useEffect(() => {
    dispatch(getUserForAgencyDowngrade());

    return () => {
      dispatch(reset(GET_USER_FOR_AGENCY_DOWNGRADE));
    };
  }, [dispatch]);

  const { status: statusGetUser } = useStatus(GET_USER_FOR_AGENCY_DOWNGRADE);

  const downgradedPlan = useMemo(() => (user?.membership?.plan !== PLAN.PERFORMANCE), [user]);

  const downgradedQuantity = useMemo(() => {
    const amountOfAccounts = user?.membership?.accounts?.length || 0;
    const amountAllowed = user?.membership?.subscription?.quantity || 1;
    return user?.membership?.plan === PLAN.PERFORMANCE && amountOfAccounts > amountAllowed;
  }, [user]);

  if (user.settings.mustRedirectToUrl !== routesPaths.downgradeAgency) {
    return (
      <Redirect to={routesPaths.projects} />
    );
  }
  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.content}>
        {statusGetUser === SUCCESS && downgradedPlan && <PlanDowngrade />}
        {statusGetUser === SUCCESS && downgradedQuantity && <QuantityDowngrade />}
        {statusGetUser === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
      </div>
    </div>
  );
};

export { DowngradeAgency };
