import React from 'react';

import { useSession, useStatus, useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { HelpOption } from 'src/pages/project/bottom-options/help-option';
import { ZoomOption } from 'src/pages/project/bottom-options/zoom-option';
import { GET_USER_WITH_BRAND_LIBRARY } from 'src/actions/brandLibraryActions';
import { SHOWING_STATUS } from 'src/constants/general';
import { SUCCESS } from 'src/constants/status';
import { ResizeElementTip } from './resize-element-tip';
import styles from './BottomOptions.module.scss';

const BottomOptions = () => {
  const { windowSize } = useWindowSize();

  const { user } = useSession();

  const { status: statusGetBrandLibrary } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  return (
    <div className={styles.container}>
      {windowSize.width > SMALL_WIDTH && <ZoomOption />}
      <HelpOption />
      {statusGetBrandLibrary === SUCCESS &&
        user.settings.showElementResizeMobileTip === SHOWING_STATUS.SHOW && (
        <div className={styles.resizeTip}>
          <ResizeElementTip />
        </div>
      )}
    </div>
  );
};

export { BottomOptions };
