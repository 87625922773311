import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { number, shape } from 'prop-types';

import RemovebgIcon from 'src/assets/icons/removebg.svg';
import RemovebgIconWhite from 'src/assets/icons/removebg-white.svg';
import { removeImageBackground } from 'src/actions/projectActions';
import { Tooltip } from 'src/common/tooltip';
import { useProjectSelector, useTooltip, useSession } from 'src/hooks';
import { isGif } from 'src/utils/helpers';
import { REMOVE_BG_PLANS } from 'src/constants/memberships';
import { useDropdownWithScroll } from '../../useDropdownsWithScroll';
import styles from './RemoveBackground.module.scss';

const PADDING_LEFT_DROPDOWN = 30;

const RemoveBackground = ({ scrollPosition }) => {
  const dispatch = useDispatch();

  const { ref, showTooltip } = useTooltip();
  const {
    selectedElements,
    elements,
    id: projectId,
    imageUUIDToRemoveBackground,
  } = useProjectSelector();
  const { isSubscriptionActive, membershipPlan } = useSession();

  const selectedElementsList = Object.keys(selectedElements);
  const moreThanOneSelected = selectedElementsList.length > 1;
  let selectedElement;

  if (selectedElementsList.length === 1) {
    selectedElement = elements.find(element => element?.uuid === selectedElementsList[0]);
  }

  const imageIsGif = isGif(selectedElement?.src);

  const removeBgEnabled = isSubscriptionActive && REMOVE_BG_PLANS.includes(membershipPlan);

  const onClick = useCallback(() => {
    if (selectedElement?.src) {
      dispatch(removeImageBackground(projectId, selectedElement));
    }
  }, [dispatch, projectId, selectedElement]);

  const isDisabled = !removeBgEnabled ||
    !!imageUUIDToRemoveBackground ||
    moreThanOneSelected ||
    imageIsGif;

  const containerId = 'removeBg';
  const { optionsSize } = useDropdownWithScroll(scrollPosition, containerId);

  const tooltipStyles = useCallback(() => (
    {
      left: optionsSize.left - PADDING_LEFT_DROPDOWN,
      right: 'unset',
      transform: 'unset',
    }
  ), [optionsSize.left]);

  return (
    <div className={styles.container} id={containerId}>
      <button
        className={styles.button}
        onClick={onClick}
        disabled={isDisabled}
      >
        <img
          src={isDisabled ? RemovebgIcon : RemovebgIconWhite}
          alt="Remove background"
          ref={ref}
        />
        {showTooltip && (
          <Tooltip style={tooltipStyles()}>
            {removeBgEnabled ?
              'Remove Background' :
              'Upgrade to unlock the Remove Background tool'}
          </Tooltip>
        )}
      </button>
    </div>
  );
};

RemoveBackground.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
};

export { RemoveBackground };
