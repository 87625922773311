import React from 'react';
import { useSelector } from 'react-redux';
import { number, string } from 'prop-types';
import cn from 'classnames';

import {
  GET_CATEGORY_TEMPLATES,
} from 'src/actions/templatesActions';
import { LOADING, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import { TemplateCard } from 'src/pages/templates/template-card';
import styles from './TemplateList.module.scss';

const TemplateList = ({
  categoryId,
  amountOfTemplatesInARow,
  page,
  spinnerIconClassName,
  templateCardClassName,
  spinnerContainerClassName,
}) => {
  const {
    templates,
    loadingPaginationCategoryId,
  } = useSelector(({ templates: templatesByCategories }) => ({
    templates: templatesByCategories.categories[categoryId].templates,
    loadingPaginationCategoryId: templatesByCategories.loadingPaginationCategoryId,
  }));

  const { status } = useStatus(GET_CATEGORY_TEMPLATES);
  let showLoading = false;

  if (status === LOADING && loadingPaginationCategoryId) {
    showLoading = loadingPaginationCategoryId === categoryId;
  }

  const startSlice = (page - 1) * amountOfTemplatesInARow;
  const allTemplates = templates.slice(startSlice, page * amountOfTemplatesInARow);

  return (
    <>
      <div className={styles.templates}>
        {showLoading ? (
          <Spinner
            iconClassName={cn(styles.spinnerIcon, spinnerIconClassName)}
            containerClassName={cn(styles.spinnerContainer, spinnerContainerClassName)}
            spinnerClassName={styles.spinner}
          />
        ) : (
          <>
            {allTemplates.map((template, index) => (
              <TemplateCard
                thumbnail={template?.thumbnail}
                key={template.id}
                templateIndex={index + startSlice}
                categoryId={categoryId}
                className={templateCardClassName}
                {...template}
              />
            ))}
            {Array.from({ length: amountOfTemplatesInARow - allTemplates.length }, (v, i) => i)
              .map(elem => (
                <div className={cn(styles.blankcard, templateCardClassName)} key={elem} />
              ))}
          </>
        )}
      </div>
      {status === ERROR && (
        <span className={styles.error}>
          Oops! The templates for this category are not available right now.
        </span>
      )}
    </>
  );
};

TemplateList.propTypes = {
  page: number.isRequired,
  categoryId: number.isRequired,
  amountOfTemplatesInARow: number.isRequired,
  spinnerIconClassName: string,
  templateCardClassName: string,
  spinnerContainerClassName: string,
};

export { TemplateList };
