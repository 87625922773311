import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DEFAULT_PROJECT, PROJECT_TYPE, ENABLE_FLYERS, ENABLE_SIGNS } from 'src/constants/general';
import { FLYER_LAYOUTS, SIGN_LAYOUTS } from 'src/constants/layout';
import { createProject, CREATE_PROJECT } from 'src/actions/projectActions';
import { useSession, useStatus } from 'src/hooks';
import { SIGN_PLANS } from 'src/constants/memberships';
import { LOADING } from 'src/constants/status';
import { Button } from 'src/common/button';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import { Spinner } from 'src/common/spinner';
import ArrowUp from 'src/assets/icons/arrow-up-white.svg';
import styles from './NewProject.module.scss';

const NewProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { account, isSubscriptionActive, membershipPlan, user } = useSession();

  const { status } = useStatus(CREATE_PROJECT);

  const onProjectCreate = useCallback(() => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: DEFAULT_PROJECT.LAYOUT.width,
      layoutHeight: DEFAULT_PROJECT.LAYOUT.height,
      layoutSource: DEFAULT_PROJECT.LAYOUT.source,
      type: PROJECT_TYPE.PROJECT,
    };
    dispatch(createProject(project, account, history));
  }, [account, dispatch, history]);

  const onFlyerCreate = useCallback(() => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: FLYER_LAYOUTS.SQUARE_LAYOUT.width,
      layoutHeight: FLYER_LAYOUTS.SQUARE_LAYOUT.height,
      layoutSource: FLYER_LAYOUTS.SQUARE_LAYOUT.source,
      type: PROJECT_TYPE.FLYER,
    };
    dispatch(createProject(project, account, history));
  }, [account, dispatch, history]);

  const onSignCreate = useCallback(() => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.width,
      layoutHeight: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.height,
      layoutSource: SIGN_LAYOUTS.HORIZONTAL_LAYOUT.source,
      type: PROJECT_TYPE.SIGN,
    };
    dispatch(createProject(project, account, history));
  }, [account, dispatch, history]);

  const projectOptions = useMemo(() => {
    const options = [
      { text: 'Social/Web', action: onProjectCreate },
    ];
    if (ENABLE_FLYERS(user.email)) {
      options.push({ text: 'Flyer', action: onFlyerCreate });
    }
    const createSignEnabled = isSubscriptionActive && SIGN_PLANS.includes(membershipPlan);
    if (ENABLE_SIGNS(user.email) && createSignEnabled) {
      options.push({ text: 'Printable sign', action: onSignCreate });
    }
    return options;
  }, [onProjectCreate, isSubscriptionActive, membershipPlan, onFlyerCreate, onSignCreate, user]);

  if (projectOptions.length === 1) {
    return (
      <Button
        loading={status === LOADING}
        onClick={projectOptions[0].action}
        alternativeCTA
      >
        New Project
      </Button>
    );
  }
  return (
    <CollapsibleOptions
      options={projectOptions}
      className={styles.container}
      optionsClassName={styles.options}
      optionClassName={styles.option}
      buttonClassName={styles.button}
      iconOpened={ArrowUp}
      disabled={status === LOADING}
      closeOnClickOption
    >
      {status === LOADING ?
        <Spinner containerClassName={styles.spinnerContainer} /> :
        <span className={styles.text}>New Project</span>}
    </CollapsibleOptions>
  );
};

export { NewProject };
