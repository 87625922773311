import React, { useState } from 'react';
import { number, string } from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { DeleteUploadedFont } from '../delete-uploaded-font';
import styles from './UploadedFont.module.scss';

const UploadedFont = ({ id, name }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  return (
    <div
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      className={styles.container}
    >
      <span
        style={{ fontFamily: name }}
        className={styles.font}
      >
        {name}
      </span>
      {showDelete && (
        <button
          className={cn(styles.delete, { [styles.disabled]: disabled })}
          onClick={() => !disabled && setModalOpen(true)}
        >
          Delete
        </button>
      )}
      {modalOpen && (
        <DeleteUploadedFont
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          fontId={id}
          fontName={name}
        />
      )}
    </div>
  );
};

UploadedFont.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
};

export { UploadedFont };
