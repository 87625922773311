import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { func, shape, number, string, bool } from 'prop-types';

import { useClickOutside } from 'src/hooks';
import { Input } from 'src/common/input';
import { PRODUCTION } from 'src/constants/environments';
import styles from './AddressInput.module.scss';

// eslint-disable-next-line max-len
const GOOGLE_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`;

const loadScript = (callback) => {
  const scripts = Array.from(document.querySelectorAll('script')).map(scr => scr.src);
  if (scripts.includes(GOOGLE_URL)) {
    callback();
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = GOOGLE_URL;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const AddressInput = ({ result, setResult, error, setError, disabled = false }) => {
  const autoCompleteRef = useRef();
  const [search, setSearch] = useState(result?.description);
  const [predictions, setPredictions] = useState([]);
  const [sessionToken, setSessionToken] = useState();

  const handleScriptLoad = () => {
    autoCompleteRef.current = new window.google.maps.places.AutocompleteService();
    const initialToken = new window.google.maps.places.AutocompleteSessionToken();
    setSessionToken(initialToken);
  };

  useEffect(() => {
    loadScript(() => handleScriptLoad(setSearch, autoCompleteRef));
  }, []);

  const getPlacePredictions = useCallback((input) => {
    if (!autoCompleteRef?.current) {
      return;
    }
    try {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      const countries = environment === PRODUCTION ? 'us' : ['us', 'uy'];

      autoCompleteRef.current.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: countries },
          sessionToken,
          types: ['address'],
        },
        (foundPredictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(foundPredictions);
          } else if (status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            setError('Welp, looks like there\'s no result for that address');
            setPredictions([]);
          } else {
            setError('We\'re having some trouble finding this address, let\'s try again');
            setPredictions([]);
          }
        },
      );
    } catch (e) {
      Sentry.captureException(e);
      setError('Oops, error checking the address with Google');
    }
  }, [sessionToken, setError]);

  const predictionClick = (prediction) => {
    try {
      const map = document.createElement('div');
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails({
        placeId: prediction.place_id,
        fields: ['geometry'],
        sessionToken,
      }, (res, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPredictions([]);
          setSearch(prediction.description);
          const location = {
            lat: res?.geometry?.location?.lat(),
            long: res?.geometry?.location?.lng(),
            placeId: prediction.place_id,
            description: prediction.description,
          };
          setResult(location);
        } else {
          setError('We\'re having some trouble finding this address, let\'s try again');
        }
      });
      const newToken = new window.google.maps.places.AutocompleteSessionToken();
      setSessionToken(newToken);
    } catch (e) {
      Sentry.captureException(e);
      setError('Oops, error checking the address with Google');
    }
  };

  const onAddressChange = ({ target }) => {
    setSearch(target.value);
    if (result) {
      setResult();
    }
    if (error) {
      setError('');
    }
    if (target.value.length > 2) {
      getPlacePredictions(target.value);
    }
  };

  const ref = useClickOutside(() => setPredictions([]), !!predictions?.length, []);

  return (
    <div className={styles.container} ref={ref}>
      <Input
        name="address"
        label="Project location"
        value={search}
        onChange={onAddressChange}
        error={error}
        touched={!!error}
        disabled={disabled}
      />
      {!!predictions?.length && (
        <div className={styles.options}>
          {predictions.map((prediction) => (
            <button
              onClick={() => predictionClick(prediction)}
              className={styles.option}
              key={prediction.place_id}
            >
              {prediction.description}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

AddressInput.propTypes = {
  result: shape({
    lat: number,
    long: number,
    placeId: string,
    description: string,
  }),
  setResult: func.isRequired,
  error: string,
  setError: func.isRequired,
  disabled: bool,
};

export { AddressInput };
