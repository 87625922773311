import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape, number, string, oneOf } from 'prop-types';

import LineHeightDarkIcon from 'src/assets/icons/line-height.svg';
import LetterSpacingDarkIcon from 'src/assets/icons/letter-spacing.svg';
import TextAlignLeftBlackIcon from 'src/assets/icons/text-align-left-black.svg';
import TextAlignCenterBlackIcon from 'src/assets/icons/text-align-center-black.svg';
import TextAlignRightBlackIcon from 'src/assets/icons/text-align-right-black.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import { updateFont, UPDATE_FONT } from 'src/actions/brandLibraryActions';
import { DEFAULT_FONT_STYLE } from 'src/constants/fonts';
import { TEXT_ALIGN, LEFT } from 'src/constants/general';
import {
  FontFamilySelect,
} from 'src/pages/brand-library/brand-library-content/fonts/font-family-select';
import { FontSizeSelect } from 'src/common/font-size-select';
import { TextAlignment } from 'src/common/text-alignment';
import { ColorPicker } from 'src/common/color-picker';
import { Spinner } from 'src/common/spinner';
import { LineHeightInput } from 'src/common/line-height-input';
import { LetterSpacingInput } from 'src/common/letter-spacing-input';
import { useStatus } from 'src/hooks';
import { SUCCESS, LOADING } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import styles from './EditFont.module.scss';

const EditFont = ({ defaultValues, fontId, onClose }) => {
  const dispatch = useDispatch();

  const { id } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const [values, setValues] = useState(defaultValues);

  const updateValues = newValues => {
    setValues(v => ({ ...v, ...newValues }));
  };

  const close = useCallback(() => {
    setValues(defaultValues);
    onClose();
  }, [defaultValues, onClose]);

  const editFontOfBrandLibrary = () => {
    if (!values.name) {
      return;
    }
    const fontToEdit = {
      ...values,
      hex: values.color.hex,
      alpha: values.color.alpha,
    };
    dispatch(updateFont(id, fontId, fontToEdit));
  };

  const onChangeFontFamily = ({ value, uploadType }) => {
    updateValues({
      fontFamily: value,
      fontStyle: DEFAULT_FONT_STYLE.value,
      uploadType,
    });
  };

  const { status } = useStatus(UPDATE_FONT);

  useEffect(() => {
    if (status === SUCCESS) {
      onClose();
      dispatch(reset(UPDATE_FONT));
    }
  }, [dispatch, onClose, status]);

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        <input
          className={styles.name}
          placeholder="Label Name"
          value={values.name}
          onChange={({ target }) => updateValues({ name: target.value })}
        />
        <FontFamilySelect
          onChangeFontFamily={onChangeFontFamily}
          onChangeFontStyle={({ value }) => updateValues({ fontStyle: value })}
          uploadType={values.uploadType}
          fontFamily={values.fontFamily}
          fontStyle={values.fontStyle}
        />
        <FontSizeSelect
          onChange={fontSize => updateValues({ fontSize })}
          fontSize={values.fontSize}
        />
        <LetterSpacingInput
          letterSpacing={values.letterSpacing}
          onBlur={letterSpacing => updateValues({ letterSpacing })}
          onChange={letterSpacing => updateValues({ letterSpacing })}
          inputClassName={styles.letterSpacing}
          icon={LetterSpacingDarkIcon}
        />
        <LineHeightInput
          lineHeight={values.lineHeight}
          onBlur={lineHeight => updateValues({ lineHeight })}
          onChange={lineHeight => updateValues({ lineHeight })}
          inputClassName={styles.lineHeight}
          icon={LineHeightDarkIcon}
        />
        <TextAlignment
          alignment={values.align}
          changeAlignment={align => updateValues({ align })}
          iconLeftActive={TextAlignLeftBlackIcon}
          iconCenterActive={TextAlignCenterBlackIcon}
          iconRightActive={TextAlignRightBlackIcon}
        />
        <ColorPicker
          color={values.color}
          onColorChange={color => updateValues({ color })}
          className={styles.colorPicker}
          position={LEFT}
        />
        <button className={styles.close} onClick={close}>
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <div className={styles.saveContainer}>
        <button
          className={styles.save}
          onClick={editFontOfBrandLibrary}
          disabled={status === LOADING || !values.name}
        >
          {status === LOADING ? <Spinner iconClassName={styles.spinner} /> : 'Save'}
        </button>
      </div>
    </div>
  );
};

EditFont.propTypes = {
  defaultValues: shape({
    name: string.isRequired,
    color: shape({
      hex: string.isRequired,
      alpha: number.isRequired,
    }).isRequired,
    fontFamily: string.isRequired,
    fontStyle: string.isRequired,
    fontSize: number.isRequired,
    align: oneOf(Object.values(TEXT_ALIGN)).isRequired,
  }).isRequired,
  fontId: number.isRequired,
  onClose: func.isRequired,
};

export { EditFont };
