import React, { useMemo } from 'react';

import { HamburgerMenu } from 'src/common/hamburger-menu';
import Logotype from 'src/assets/icons/logotype-white.svg';
import { MEDIUM_WIDTH } from 'src/constants/breakpoints';
import { useWindowSize } from 'src/hooks';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import ArrowUp from 'src/assets/icons/arrow-up-white.svg';
import styles from './Navbar.module.scss';

const HAMBURGER_STYLES = {
  bmBurgerButton: {
    position: 'absolute',
    width: '3rem',
    height: '2rem',
    right: '2.4rem',
    top: '3.2rem',
  },
  bmBurgerBars: {
    height: '12%',
    background: '#fff',
  },
  bmCrossButton: {
    display: 'none',
  },
  bmCross: {
    background: '#000',
    height: '2.4rem',
  },
  bmMenu: {
    background: '#fff',
    padding: '0',
  },
  bmMenuWrap: {
    top: '8rem',
    width: '100vw',
  },
  bmOverlay: {
    width: '100vw',
    top: '0',
    left: '0',
  },
};

const STAGGER_APP_URL = 'https://app.stagger.com';

const PRODUCT_LINKS = [
  { text: 'Brand Library', href: 'https://www.stagger.com/product#product-brand-library' },
  { text: 'Editor', href: 'https://www.stagger.com/product#product-editor' },
  { text: 'Output for Web', href: 'https://www.stagger.com/product#product-embed-for-web' },
  { text: 'Output for Social', href: 'https://www.stagger.com/product#post-on-social-media' },
  { text: 'Pricing', href: 'https://www.stagger.com/product#pricing' },
];

const USE_CASES_LINKS = [
  { text: 'Small Business', href: 'https://www.stagger.com/small-business' },
  { text: 'Agency', href: 'https://www.stagger.com/agencies' },
  { text: 'Influencer', href: 'https://www.stagger.com/influencers' },
];

const RESOURCES_LINKS = [
  { text: 'Blog', href: 'https://www.stagger.com/blog' },
  {
    text: 'Documentation',
    // eslint-disable-next-line max-len
    href: 'https://staggerinc.notion.site/staggerinc/Stagger-Documentation-f2830e3f847b4eea9f3c7663e9f0b0e5',
  },
  {
    text: 'Changelog',
    // eslint-disable-next-line max-len
    href: 'https://staggerinc.notion.site/staggerinc/Stagger-Changelog-f05f637b50ca463fa7e48af7762ffb14',
  },
];

const COMPANY_LINKS = [
  { text: 'About', href: 'https://www.stagger.com/company' },
  { text: 'Our Team', href: 'https://www.stagger.com/company#our-team' },
];

const LINKS = [
  {
    title: 'Product',
    href: 'https://www.stagger.com/product',
    options: PRODUCT_LINKS,
  },
  {
    title: 'Use Cases',
    href: 'https://www.stagger.com/use-cases',
    options: USE_CASES_LINKS,
  },
  {
    title: 'Resources',
    href: 'https://www.stagger.com',
    options: RESOURCES_LINKS,
  },
  {
    title: 'Company',
    href: 'https://www.stagger.com/company',
    options: COMPANY_LINKS,
  },
];

const Navbar = () => {
  const { windowSize: { width } } = useWindowSize();
  const isMediumScreen = useMemo(() => width <= MEDIUM_WIDTH, [width]);

  const signButtons = useMemo(() => (
    <>
      <a
        className={styles.signin}
        href={`${STAGGER_APP_URL}/signin`}
        rel="noopener noreferrer"
      >
        Log In
      </a>
      <a
        className={styles.signup}
        href={`${STAGGER_APP_URL}/signup`}
        rel="noopener noreferrer"
      >
        Sign Up
      </a>
    </>
  ), []);

  return (
    <div className={styles.container}>
      <img src={Logotype} alt="logo" />
      {isMediumScreen ? (
        <HamburgerMenu styles={HAMBURGER_STYLES}>
          <div className={styles.content}>
            <div className={styles.linksContainer}>
              {signButtons}
            </div>
            {LINKS.map(link => (
              <div className={styles.linksContainer} key={link.title}>
                <a
                  className={styles.listTitle}
                  href={link.href}
                  rel="noopener noreferrer"
                >
                  {link.title}
                </a>
                <div className={styles.listContent}>
                  {link.options.map(elem => (
                    <a
                      key={elem.text}
                      className={styles.listItem}
                      href={elem.href}
                      rel="noopener noreferrer"
                    >
                      {elem.text}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </HamburgerMenu>
      ) : (
        <div className={styles.content}>
          {LINKS.map(link => (
            <CollapsibleOptions
              options={link.options}
              className={styles.collapsibleContainer}
              optionsClassName={styles.collapsibleOptions}
              iconOpened={ArrowUp}
              key={link.title}
            >
              <span className={styles.collapsibleTitle}>
                {link.title}
              </span>
            </CollapsibleOptions>
          ))}
          <div className={styles.buttonsContainer}>
            {signButtons}
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
