import React, { useCallback } from 'react';
import { bool, func, number, string, shape } from 'prop-types';

import { CREATE_FONT_SIZE_PREFIX } from 'src/constants/general';
import { FontSizeSelect } from 'src/common/font-size-select';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import styles from './FontSizeSelectControl.module.scss';

const FontSizeSelectControl = ({
  disabled,
  onChange,
  fontSize,
  groupClassName,
  scrollPosition,
}) => {
  const containerId = 'fontSizeSelect';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  const selectStyles = useCallback(() => ({
    control: base => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 0,
    }),
    singleValue: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    input: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    menu: base => (
      {
        ...base,
        color: '#555555',
        fontSize: '1.4rem',
        width: '10rem',
        left: optionsSize.left,
      }),
    option: (base, state) => {
      if (state.data.label.startsWith(CREATE_FONT_SIZE_PREFIX)) {
        return { ...base, lineHeight: '1.6' };
      }
      return base;
    },
  }), [optionsSize.left]);

  return (
    <div className={groupClassName} id={containerId}>
      <FontSizeSelect
        disabled={disabled}
        selectStyles={selectStyles()}
        onChange={onChange}
        fontSize={fontSize}
        className={styles.fontSize}
      />
    </div>
  );
};

FontSizeSelectControl.propTypes = {
  disabled: bool,
  onChange: func.isRequired,
  fontSize: number,
  groupClassName: string,
  scrollPosition: shape({
    x: number,
  }),
};

export { FontSizeSelectControl };
