import React, { useEffect, useState, useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { string } from 'prop-types';

import { RIGHT } from 'src/constants/general';
import { MenuArrow } from '../menu-arrow';

const RightArrow = ({ className }) => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <MenuArrow
      disabled={disabled}
      onClick={() => scrollNext()}
      direction={RIGHT}
      className={className}
    />
  );
};

RightArrow.propTypes = {
  className: string,
};

export { RightArrow };
