import React, { useEffect, useMemo } from 'react';
import { arrayOf, object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { subDays } from 'date-fns';

import { setSelectedProject, setSelectedTimeRange } from 'src/actions/embedAnalyticsActions';
import { getFormattedDateRangeForAnalytic } from 'src/utils/helpers';
import { useWindowSize } from 'src/hooks';
import { Select } from 'src/common/select';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { ProjectCard } from './project-card';
import { GraphTimePerFrame } from './graph-time-per-frame';
import { ExportDate } from './export-date';
import { Referrers } from './referrers';
import { LinksClicked } from './links-clicked';
import { DisabledContent } from '../disabled-content';
import { FeaturedInfo } from './featured-info';
import { SelectTimeRange } from './select-time-range';
import styles from './EmbedDashboard.module.scss';

const EmbedDashboard = ({ projects }) => {
  const dispatch = useDispatch();

  const { windowSize: { width } } = useWindowSize();
  const isSmallScreen = useMemo(() => width <= SMALL_WIDTH, [width]);

  const { selectedProject, disabled, selectedTimeRange } = useSelector(({ embedAnalytics }) => ({
    selectedProject: embedAnalytics.selectedProject,
    disabled: embedAnalytics.disabled,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
  }));

  useEffect(() => {
    if (projects.length) {
      dispatch(setSelectedProject({ project: projects[0] }));
    }
  }, [dispatch, projects]);

  useEffect(() => () => {
    dispatch(setSelectedProject({ project: undefined }));
  }, [dispatch]);

  useEffect(() => {
    const from = subDays(new Date(), 7);
    const to = new Date();
    const dateRange = getFormattedDateRangeForAnalytic(from, to);
    dispatch(setSelectedTimeRange(dateRange));
  }, [dispatch, projects]);

  const onSelectProject = ({ project }) => {
    dispatch(setSelectedProject({ project }));
  };

  const showInformation = !disabled && selectedProject && selectedTimeRange;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.list}>
          <span className={styles.listTitle}>
            Project List ({projects.length})
          </span>
          {isSmallScreen ? (
            <Select
              options={projects.map(p => ({ label: p.name, value: p.id, project: p }))}
              closeMenuOnSelect
              value={{ label: selectedProject?.name, value: selectedProject?.id }}
              onChange={onSelectProject}
              className={styles.selectContainer}
            />
          ) : (
            <div className={styles.listProjects}>
              {projects.map(project => (
                <ProjectCard
                  project={project}
                  selectedProject={selectedProject}
                  key={project.id}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.analytics}>
          <SelectTimeRange />
          <div className={styles.header}>
            <div className={styles.projectNameContainer}>
              <span className={styles.projectNameLabel}>Project Name</span>
              <span className={styles.projectName}>{selectedProject?.name}</span>
            </div>
            {showInformation && (
              <>
                <Referrers />
                <ExportDate />
              </>
            )}
          </div>
          {showInformation && (
            <>
              <FeaturedInfo />
              <GraphTimePerFrame />
              <LinksClicked />
            </>
          )}
          {disabled && (
            <DisabledContent
              text={`When you upgrade to a Performance plan,
              your project embed data will be displayed.`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EmbedDashboard.propTypes = {
  projects: arrayOf(object),
};

export { EmbedDashboard };
