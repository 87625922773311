import React from 'react';
import { func, bool } from 'prop-types';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import styles from './RestrictionModal.module.scss';

const RestrictionModal = ({ isShowing, hide }) => (
  <Modal
    isShowing={isShowing}
    hide={hide}
    isEscEnabled={false}
    curvedContainer
    classNameContent={styles.modal}
  >
    <div className={styles.content}>
      <span className={styles.title}>
        Permanently delete your Performance Creator account?
      </span>
      <span className={styles.subtitle}>
        To delete this account please get in contact with support or send
        an email to app@stagger.com
      </span>
      <Button
        className={styles.button}
        onClick={hide}
        alternativeCTA
      >
        Got it
      </Button>
    </div>
  </Modal>
);

RestrictionModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
};

export { RestrictionModal };
