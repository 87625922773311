import React, { useCallback, useMemo } from 'react';
import { Line, Text } from 'react-konva';

import { CANVAS_BLEED_PREFIX } from 'src/constants/general';
import { useProjectSelector } from 'src/hooks';

const BLEED_SPACE = 18;
const TEXT_FONT_SIZE = 16;

const BleedBorders = () => {
  const { layout } = useProjectSelector();

  const canvasLines = useMemo(() => {
    const lines = [];
    lines.push([BLEED_SPACE, 0, BLEED_SPACE, layout.height]);
    lines.push([layout.width - BLEED_SPACE, 0, layout.width - BLEED_SPACE, layout.height]);
    lines.push([0, BLEED_SPACE, layout.width, BLEED_SPACE]);
    lines.push([0, layout.height - BLEED_SPACE, layout.width, layout.height - BLEED_SPACE]);
    return lines;
  }, [layout]);

  const drawBorders = useCallback(() => (
    canvasLines.map((points, index) => (
      <Line
        key={points}
        points={points}
        stroke="#83fbfd"
        strokeWidth={4}
        id={`${CANVAS_BLEED_PREFIX}Border_${index}`}
      />
    ))
  ), [canvasLines]);

  return (
    <>
      {drawBorders()}
      <Text
        text="DO NOT DESIGN OUTSIDE THIS BORDER"
        x={BLEED_SPACE * 2}
        y={layout.height - BLEED_SPACE * 2 - TEXT_FONT_SIZE}
        fontSize={TEXT_FONT_SIZE}
        id={`${CANVAS_BLEED_PREFIX}Text`}
      />
    </>
  );
};

export { BleedBorders };
