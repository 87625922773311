import React, { useCallback } from 'react';
import { func, bool, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { DELETE_FONT, deleteFont } from 'src/actions/brandLibraryActions';
import { useStatus } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import styles from './DeleteFont.module.scss';

const DeleteFont = ({ isShowing, hide, fontId, uploadType }) => {
  const dispatch = useDispatch();

  const { id } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const onDelete = useCallback(() => {
    dispatch(deleteFont(id, fontId, uploadType));
  }, [dispatch, id, fontId, uploadType]);

  const { status } = useStatus(DELETE_FONT);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete your font style?
        </span>
        <span className={styles.subtitle}>
          This action will permanently delete your font style and can&apos;t be undone.
          This doesn&apos;t affect your projects in any way.
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onDelete}
            loading={status === LOADING}
            alternativeCTA
          >
            Delete
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteFont.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  fontId: number.isRequired,
  uploadType: string.isRequired,
};

export { DeleteFont };
