import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';

import { Spinner } from 'src/common/spinner';
import styles from './BillingSection.module.scss';

const BillingSection = ({
  title,
  defaultMessage,
  line1,
  line2,
  loading = false,
}) => {
  const content = useMemo(() => {
    if (line1 && line2) {
      return (
        <>
          <span className={styles.info}>{line1}</span>
          <span className={styles.info}>{line2}</span>
        </>
      );
    }
    return (
      <span className={styles.info}>{defaultMessage}</span>
    );
  }, [defaultMessage, line1, line2]);

  return (
    <div className={styles.section}>
      <p className={styles.header}>{title}</p>
      <div className={styles.content}>
        {loading ? <Spinner iconClassName={styles.spinnerIcon} /> : content}
      </div>
    </div>
  );
};

BillingSection.propTypes = {
  title: string,
  defaultMessage: string,
  line1: string,
  line2: string,
  loading: bool,
};

export { BillingSection };
