import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useProjectSelector } from 'src/hooks';
import { updateProjectName, cleanSelectedElement } from 'src/actions/projectActions';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import styles from './ProjectName.module.scss';

const ProjectName = memo(() => {
  const dispatch = useDispatch();

  const { id, name: defaultName } = useProjectSelector();

  const [name, setName] = useState(defaultName);
  const [error, setError] = useState('');

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const updateName = async () => {
    if (!name) {
      setError('Name cannot be empty');
    } else if (name !== defaultName) {
      try {
        await dispatch(updateProjectName(id, { name }));
      } catch (errors) {
        Object.entries(errors).forEach(([key, value]) => {
          key && setError(value);
        });
      }
    }
  };

  const onKeyDown = ({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
    if (error) {
      setError('');
    }
  };

  const onFocus = () => dispatch(cleanSelectedElement());

  return (
    <div className={styles.container}>
      <input
        className={cn(styles.projectName, { [styles.error]: error })}
        value={name}
        onChange={({ target }) => setName(target.value)}
        onBlur={updateName}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
      {error && (
        <div className={styles.errorLabel}>
          {error}
        </div>
      )}
    </div>
  );
});

export { ProjectName };
