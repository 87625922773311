import { HttpClient } from 'src/clients';
import { getClientIANATimeZone } from '../utils/helpers';

class EmbedAnalyticsService {
  static getTimeSpentInFrames(projectId, from, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/time-by-frame`, {
      params: { from, to, tz: getClientIANATimeZone() },
    });
  }

  static getExportDate(projectId, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/publish-dates`, {
      params: { to, tz: getClientIANATimeZone() },
    });
  }

  static getReferrers(projectId, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/referrers`, {
      params: { to, tz: getClientIANATimeZone() },
    });
  }

  static getLinksClicked(projectId, from, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/links-clicked`, {
      params: { from, to, tz: getClientIANATimeZone() },
    });
  }

  static getImpressions(projectId, from, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/impressions`, {
      params: { from, to, tz: getClientIANATimeZone() },
    });
  }

  static getTimeInStagger(projectId, from, to) {
    return HttpClient.get(`/analytics/embed/${projectId}/time-in-stagger`,
      { params: { from, to, tz: getClientIANATimeZone() } });
  }
}

export { EmbedAnalyticsService };
