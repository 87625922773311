import {
  setSelectedCategoryId,
  getCategoriesWithTemplatesSuccess,
  getCategoryTemplatesSuccess,
  seeAllSuccess,
  setLoadingPaginationCategoryId,
  searchTemplatesSuccess,
  setSearchKeyword,
  resetSearchTemplates,
  saveSelectedTemplatePreview,
} from 'src/actions/templatesActions';
import { createProjectFromTemplateSuccess } from 'src/actions/projectActions';
import { updateExistingTemplateSuccess } from 'src/actions/productTemplateActions';
import { logout } from 'src/actions/userActions';
import createReducer from './createReducer';

const initialState = {
  categories: {},
  selectedCategoryId: undefined,
  loadingPaginationCategoryId: undefined,
  templatesByCategoryPages: {},
  templatesInSeeAllByPages: {},
  searchTemplates: [],
  searchTemplatesPage: {},
  searchKeyword: '',
  selectedTemplatePreview: undefined,
};

const actionHandlers = {
  [getCategoriesWithTemplatesSuccess]: (state, { payload: { categories, page, perPage } }) => {
    state.categories = {};
    state.templatesByCategoryPages = {};
    categories.forEach(cat => {
      state.templatesByCategoryPages[cat.id] = {
        current: cat.templates.length + ((page - 1) * perPage),
        total: cat.totalTemplates,
      };
      delete cat.totalTemplates;
      state.categories[cat.id] = cat;
    });
  },
  [getCategoryTemplatesSuccess]: (state, { payload: {
    templates,
    totalTemplates,
    categoryId,
    page,
    perPage,
  } }) => {
    state.categories[categoryId].templates = [
      ...(state.categories[categoryId].templates || []),
      ...templates,
    ];
    state.templatesByCategoryPages[categoryId] = {
      current: templates.length + ((page - 1) * perPage),
      total: totalTemplates,
    };
  },
  [seeAllSuccess]: (state, { payload: {
    templates,
    totalTemplates,
    categoryId,
    page,
    perPage,
    category,
  } }) => {
    state.categories[categoryId] = category;
    state.categories[categoryId].templates = templates;
    state.templatesInSeeAllByPages = {
      [categoryId]: {
        current: templates.length + ((page - 1) * perPage),
        total: totalTemplates,
      },
    };
  },
  [setSelectedCategoryId]: (state, { payload }) => {
    state.selectedCategoryId = payload;
  },
  [setLoadingPaginationCategoryId]: (state, { payload }) => {
    state.loadingPaginationCategoryId = payload;
  },
  [setSearchKeyword]: (state, { payload }) => {
    state.searchKeyword = payload;
  },
  [resetSearchTemplates]: (state) => {
    state.searchTemplates = [];
    state.searchTemplatesPage = {};
    state.searchKeyword = '';
  },
  [searchTemplatesSuccess]: (state, { payload: { templates, totalTemplates, page, perPage } }) => {
    state.searchTemplates = templates;
    state.searchTemplatesPage = {
      current: templates.length + ((page - 1) * perPage),
      total: totalTemplates,
    };
  },
  [updateExistingTemplateSuccess]: (state, { payload: { template } }) => {
    const categoryId = template.category.id;
    if (state.categories[categoryId]) {
      const selectedTemplateIndex = state.categories[categoryId].templates
        .findIndex(item => item.id === template.id);
      state.categories[categoryId].templates[selectedTemplateIndex].preview = template.preview;
    }
  },
  [saveSelectedTemplatePreview]: (state, { payload }) => {
    state.selectedTemplatePreview = payload;
  },
  [createProjectFromTemplateSuccess]: state => {
    state.selectedTemplatePreview = undefined;
  },
  [logout]: () => initialState,
};


export default createReducer(initialState, actionHandlers);
