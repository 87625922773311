import { useMemo } from 'react';

import { useProjectSelector } from 'src/hooks';
import { MEDIA_ELEMENTS } from 'src/constants/canvasElements';

const useProjectMedia = () => {
  const { elements, groupsOfElementsById } = useProjectSelector();

  const media = useMemo(() => {
    const elementsMedia = elements.filter(elem => MEDIA_ELEMENTS.includes(elem.type));
    Object.entries(groupsOfElementsById).forEach(([, group]) => {
      group
        .forEach((elem) => {
          if (MEDIA_ELEMENTS.includes(elem.type)) {
            elementsMedia.push(elem);
          }
        });
    });
    return elementsMedia;
  }, [elements, groupsOfElementsById]);

  return { media };
};

export { useProjectMedia };
