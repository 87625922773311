import {
  UnsplashClient,
  HttpClient,
  HttpHeavyClient,
  CloudinaryClient,
  ShareClient,
} from '../clients';

class ProjectService {
  static unsplashSearch(param, page, perPage) {
    return UnsplashClient.get('https://api.unsplash.com/search/photos',
      { params: { query: param, per_page: perPage, page } });
  }

  static unsplashDownload(link) {
    return UnsplashClient.get(link);
  }

  static createProject(data) {
    return HttpClient.post('/projects', data);
  }

  static deleteProject(id) {
    return HttpClient.delete(`/projects/${id}`);
  }

  static getProject(id) {
    return HttpClient.get(`/projects/${id}`);
  }

  static duplicateProject(id) {
    return HttpHeavyClient.post(`/projects/${id}/duplicate`);
  }

  static transferProject(id, accountIdTo) {
    return HttpClient.post(`/projects/${id}/duplicateToAccount`, { accountIdTo });
  }

  static updateProject(id, project) {
    return HttpClient.put(`/projects/${id}`, { project });
  }

  static updateProjectAutomatically(id, project) {
    return HttpClient.put(`/projects/auto/${id}`, { project });
  }

  static updateFlyerSettings(id, flyerSettings) {
    return HttpClient.put(`/projects/${id}/flyerSettings`, { flyerSettings });
  }

  static createProjectFromSuggestion(suggestionId) {
    return HttpClient.post('/projects/suggestion', { suggestionId });
  }

  static createProjectFromTemplate(templateId, accountId) {
    return HttpClient.post('/projects/template', { templateId, accountId });
  }

  static applyTemplate(id, templateId, mediaInCanvas) {
    return HttpClient.put(`/projects/${id}/applyTemplate`, { templateId, mediaInCanvas });
  }

  static applySuggestion(id, suggestionId, mediaInCanvas) {
    return HttpClient.put(`/projects/${id}/applySuggestion`, { suggestionId, mediaInCanvas });
  }

  static saveFileFromLocal({ file }) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', file);
    return HttpClient.post('/projects/fileLocal', formData, config);
  }

  static savePreviewFile(file, previewData, videoData, gifData) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('canvasIndex', previewData.canvasIndex);
    formData.append('projectType', previewData.projectType);
    formData.append('projectId', previewData.projectId);
    formData.append('layoutWidth', previewData.layoutWidth);
    formData.append('layoutHeight', previewData.layoutHeight);
    if (videoData) {
      formData.append('videoData', JSON.stringify(videoData));
    }
    if (gifData) {
      formData.append('gifData', JSON.stringify(gifData));
    }
    return HttpClient.post('/projects/previews/fileLocal', formData, config);
  }

  static saveFileFromUrl(url, name) {
    return HttpClient.post('/projects/fileUrl', { url, name });
  }

  static deleteFileFromUrl(url) {
    return HttpClient.post('/projects/fileUrl/delete', { url });
  }

  static getPreviewOfProject(url) {
    return CloudinaryClient.get(url);
  }

  static getProjectsWithin(accountId, lat, long, radius) {
    return ShareClient.get(`/projects/within/${accountId}/${lat}/${long}`, {
      params: {
        radius,
      },
    });
  }

  static getAllPublicProjects(accountId, lat, long) {
    return ShareClient.get(`/projects/all/${accountId}${lat && long ? `/${lat}/${long}` : ''}`);
  }

  static getProjectById(projectId, accountId) {
    return ShareClient.get(`/projects/by-id-for-account/${projectId}/${accountId}`);
  }

  static removeImageBackground(projectId, imageUrl) {
    return HttpClient.post(`/projects/${projectId}/removeBackground`, { imageUrl });
  }

  static discardGeneratedCollage(projectId) {
    return HttpClient.delete(`/projects/${projectId}/generatedCollage`);
  }

  static generateCollage(projectId, imageUrls, parameters) {
    return HttpClient.post(`/projects/${projectId}/generateCollage`, { imageUrls, parameters });
  }

  static reGenerateCollage(projectId, parameters) {
    return HttpClient.post(`/projects/${projectId}/regenerateCollage`, { parameters });
  }
}

export { ProjectService };
