export const BACKSPACE_CHARACTER_CODE = 'Backspace';

export const DELETE_CHARACTER_CODE = 'Delete';

export const SPACE_CHARACTER_CODE = 'Space';

export const SHIFT_CHARACTER_CODE = 'ShiftLeft';

export const ALT_CHARACTER_CODE = 'AltLeft';

export const COMMAND_CHARACTER_CODE = 'MetaLeft';

export const COMMAND_CHARACTER_CODE_RIGHT = 'MetaRight';

export const CONTROL_CHARACTER_CODE = 'ControlLeft';

export const ENTER_CHARACTER_CODE = 'Enter';

export const LEFT_ARROW_CODE = 'ArrowLeft';

export const RIGHT_ARROW_CODE = 'ArrowRight';

export const TOP_ARROW_CODE = 'ArrowUp';

export const BOTTOM_ARROW_CODE = 'ArrowDown';

export const PLUS_NUMPAD_CODE = 'NumpadAdd';

export const MINUS_NUMPAD_CODE = 'NumpadSubtract';

export const EQUAL_CODE = 'Equal';

export const MINUS_CODE = 'Minus';

export const RIGHT_CLICK_CODE = 2;

export const LEFT_CLICK_CODE = 0;

export const KEY_A_CHARACTER_CODE = 'KeyA';

export const KEY_C_CHARACTER_CODE = 'KeyC';

export const KEY_D_CHARACTER_CODE = 'KeyD';

export const KEY_G_CHARACTER_CODE = 'KeyG';

export const KEY_L_CHARACTER_CODE = 'KeyL';

export const KEY_O_CHARACTER_CODE = 'KeyO';

export const KEY_R_CHARACTER_CODE = 'KeyR';

export const KEY_T_CHARACTER_CODE = 'KeyT';

export const KEY_V_CHARACTER_CODE = 'KeyV';

export const KEY_Z_CHARACTER_CODE = 'KeyZ';
