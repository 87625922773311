import {
  changeLayout,
  changeSize,
  changeColor,
  cloneElements,
  copyPasteElements,
  addMediaElements,
  replaceImageFromRightClick,
  addTextElementSuccess,
  addLineElement,
  addCircleElement,
  addSquareElement,
  addRoundedRectElement,
  addTriangleElement,
  addGroupElement,
  updateElementSuccess,
  moveElementBackward,
  moveElementForward,
  lockElement,
  unlockElement,
  deleteElementSuccess,
  ungroupElementsSuccess,
  moveElementToBack,
  moveElementToFront,
  moveElementWithKeyboard,
  addTagToElement,
  removeTagFromElement,
  moveFrame,
  addFrame,
  addTextCombination,
  addObjectCombination,
  replaceBgRemovedImage,
} from 'src/actions/projectActions';
import { showGeneratedCollageSuccess } from 'src/actions/collageActions';
import { applyTemplateToProjectSuccess } from 'src/actions/productTemplateActions';
import { applySuggestionToProjectSuccess } from 'src/actions/insightActions';
import { chooseEyeDropperColor } from 'src/actions/canvasActions';

export const actionsToSave = [
  changeLayout,
  changeSize,
  changeColor,
  cloneElements,
  copyPasteElements,
  addMediaElements,
  replaceImageFromRightClick,
  addTextElementSuccess,
  addLineElement,
  addCircleElement,
  addSquareElement,
  addRoundedRectElement,
  addTriangleElement,
  addGroupElement,
  updateElementSuccess,
  moveElementBackward,
  moveElementForward,
  lockElement,
  unlockElement,
  deleteElementSuccess,
  ungroupElementsSuccess,
  applyTemplateToProjectSuccess,
  applySuggestionToProjectSuccess,
  moveElementToBack,
  moveElementToFront,
  moveElementWithKeyboard,
  chooseEyeDropperColor,
  addTagToElement,
  removeTagFromElement,
  moveFrame,
  addFrame,
  addTextCombination,
  addObjectCombination,
  showGeneratedCollageSuccess,
  replaceBgRemovedImage,
];

export const actionsToForceSaveMedia = [
  addMediaElements,
  replaceImageFromRightClick,
  replaceBgRemovedImage,
];
