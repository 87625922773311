import React from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';

import { Button } from 'src/common/button';
import { AddEditorForm } from '../add-editor-form';

const ADD_EDITOR_FORM_ID = 'addEditor';

const AddEditor = ({
  addingForTheFirstTime,
  setAddingForTheFirstTime,
  openFormId,
  setOpenFormId,
  disabled,
}) => {
  const onAddEditor = () => {
    setOpenFormId(ADD_EDITOR_FORM_ID);
  };

  const onCancel = () => {
    setOpenFormId();
    setAddingForTheFirstTime(false);
  };

  return (
    <div>
      {addingForTheFirstTime || openFormId === ADD_EDITOR_FORM_ID ? (
        <AddEditorForm onCancel={onCancel} />
      ) : (
        <Button
          onClick={onAddEditor}
          secondary
          disabled={disabled}
        >
          Add another
        </Button>
      )}
    </div>
  );
};

AddEditor.propTypes = {
  addingForTheFirstTime: bool.isRequired,
  setAddingForTheFirstTime: func.isRequired,
  openFormId: oneOfType([string, number]),
  setOpenFormId: func.isRequired,
  disabled: bool.isRequired,
};

export { AddEditor };
