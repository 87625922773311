import React from 'react';
import { bool, string, oneOfType, node, object, number } from 'prop-types';
import cn from 'classnames';

import { CollapsibleOptions } from 'src/common/collapsible-options';
import styles from './DropdownAnswerToChat.module.scss';

const DropdownAnswerToChat = ({
  text,
  options,
  answer,
  className,
  selectedValue,
}) => (
  (!answer || answer === text) && (
    <div
      className={cn(
        styles.container, className,
        { [styles.answer]: answer === text },
      )}
    >
      {text}
      <CollapsibleOptions
        options={options}
        className={styles.select}
        optionsClassName={styles.options}
        optionClassName={styles.option}
        disabled={answer === text}
        closeOnClickOption
      >
        <span className={styles.selectedText}>{selectedValue || '#'}</span>
      </CollapsibleOptions>
    </div>
  )
);

DropdownAnswerToChat.propTypes = {
  text: string.isRequired,
  loading: bool,
  disabled: bool,
  answer: string,
  className: string,
  posfix: oneOfType([node, object]),
  selectedValue: number,
};

export { DropdownAnswerToChat };
