import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bool, number, string, oneOfType, func, arrayOf, object } from 'prop-types';

import { Button } from 'src/common/button';
import { useSession, useStatus, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { changeDefaultAccountId, CHANGE_DEFAULT_ACCOUNT_ID } from 'src/actions/userActions';
import { LOADING } from 'src/constants/status';
import { EditAccountForm } from 'src/pages/account-settings/accounts-manager/edit-account-form';
import { DeleteAccount } from 'src/pages/account-settings/accounts-manager/delete-account';
import styles from './AccountCard.module.scss';

const AccountCard = ({ brandName, id, disabled, openFormId, setOpenFormId, projects }) => {
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { windowSize } = useWindowSize();

  const { user } = useSession();

  const { status: statusChangeDefault } = useStatus(CHANGE_DEFAULT_ACCOUNT_ID);

  useEffect(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH && !showOptions && openFormId !== id) {
      setShowOptions(true);
    }
  }, [showOptions, windowSize.width, openFormId, id]);

  const onClickEdit = () => {
    setShowOptions(false);
    setOpenFormId(id);
  };

  const onCancelEdit = () => {
    setOpenFormId();
  };

  const onMouseEnter = () => {
    if (openFormId !== id) {
      setShowOptions(true);
    }
  };

  const onClickSetAsDefault = () => {
    dispatch(changeDefaultAccountId(user, id));
  };

  const onClickDelete = () => {
    setModalOpen(true);
  };

  const isDefaultAccount = id === user.defaultAccountId;

  return (
    <div
      className={styles.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setShowOptions(false)}
    >
      {openFormId === id ?
        <EditAccountForm id={id} onCancel={onCancelEdit} brandName={brandName} /> : (
          <div className={styles.card}>
            <span className={styles.brandName}>{brandName}</span>
            {isDefaultAccount && <span className={styles.default}>Default</span>}
          </div>
        )}
      {showOptions && (
        <div className={styles.buttonsContainer}>
          <button
            className={styles.edit}
            onClick={onClickEdit}
            disabled={disabled}
          >
            Edit
          </button>
          {!isDefaultAccount && user.membership.accounts.length > 1 && (
            <>
              <Button
                className={styles.setDefault}
                onClick={onClickSetAsDefault}
                disabled={disabled}
                loading={statusChangeDefault === LOADING}
                spinnerClassName={styles.spinnerIcon}
              >
                Set as default
              </Button>
              <Button
                className={styles.setDefault}
                onClick={onClickDelete}
                disabled={disabled}
              >
                Delete
              </Button>
            </>
          )}
        </div>
      )}
      {modalOpen && (
        <DeleteAccount
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          brandName={brandName}
          id={id}
          projects={projects}
        />
      )}
    </div>
  );
};

AccountCard.propTypes = {
  brandName: string.isRequired,
  id: number.isRequired,
  disabled: bool.isRequired,
  openFormId: oneOfType([string, number]),
  setOpenFormId: func.isRequired,
  projects: arrayOf(object),
};

export { AccountCard };
