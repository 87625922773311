import React, {
  useRef, useMemo, forwardRef, useCallback, useLayoutEffect, useState,
} from 'react';
import { string } from 'prop-types';
import { Stage } from 'react-konva';
import { ReactReduxContext, Provider, useSelector, useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import cn from 'classnames';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import {
  closeRightClickOptions,
  chooseEyeDropperColor,
  deselectFrame,
} from 'src/actions/canvasActions';
import {
  useCanvasSize,
  useWindowSize,
  useProjectSelector,
  useMouseEventsStage,
  useLeftClickOnScreen,
  useTouchEventsCanvas,
  useScrollEventsCanvas,
  useCopyPasteShortcuts,
  useSession,
} from 'src/hooks';
import {
  CANVAS_DROPPABLE_ID,
  STAGE_ID,
  PRODUCT_TOUR_PREFIX_ID,
  CANVAS_SCROLL_ID,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
  EDITABLE_TITLE_FRAME_TYPE,
  SHOWING_STATUS,
} from 'src/constants/general';
import {
  CanvasEditableText,
  CanvasRightClickOptions,
  CanvasRightClickOptionsCanvas,
  CanvasRightClickOptionsTitle,
  CanvasEditableTag,
  CanvasEditableFrameTitle,
  CanvasRightClickOptionsFrameCard,
  CanvasHyperlink,
  CanvasElementOptions,
} from 'src/pages/project/canvas/canvas-helper-elements';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { Pulse } from 'src/common/pulse';
import { KeyboardListener } from 'src/hooks/useKeyboardListener';
import { RenameFrameTitleModal } from './rename-frame-title-modal';
import { DrawingLayer } from './drawing-layer';
import styles from './Canvas.module.scss';
import { TemplateTip } from './template-tip';

// Padding will increase the size of stage so scrolling will look smoother
const PADDING_STAGE = 200;

const Canvas = forwardRef((_, refDrawingLayer) => {
  const refScroll = useRef();
  const refSimpleBar = useRef();
  const dispatch = useDispatch();

  useCanvasSize(refScroll, refDrawingLayer);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const { user } = useSession();

  const {
    controlOpen,
    productTour,
    rightClickOptions,
    eyeDropper,
    baseCanvasWidth,
    baseCanvasHeight,
    scale,
    selectedFrame,
    editableHyperlink,
  } = useSelector(({ canvas, session }) => ({
    controlOpen: canvas.controlOpen,
    productTour: session.productTour,
    rightClickOptions: canvas.rightClickOptions,
    eyeDropper: canvas.eyeDropper,
    baseCanvasWidth: canvas.baseCanvasWidth,
    baseCanvasHeight: canvas.baseCanvasHeight,
    scale: canvas.scale,
    selectedFrame: canvas.selectedFrame,
    editableHyperlink: canvas.editableHyperlink,
  }));

  const {
    size,
    editableText,
    editableTag,
    editableFrameTitle,
  } = useProjectSelector();

  const canvasWidth = useMemo(() => baseCanvasWidth * size, [baseCanvasWidth, size]);

  const handleRightClickOptions = useCallback(() => {
    if (rightClickOptions
      && rightClickOptions.type !== CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT_FLOATING_MOBILE) {
      dispatch(closeRightClickOptions());
    }
  }, [dispatch, rightClickOptions]);

  const onClickScreen = useCallback((e) => {
    handleRightClickOptions();
    if (selectedFrame !== null && e.target.nodeName !== 'CANVAS') {
      dispatch(deselectFrame());
    }
    if (eyeDropper.active && e.target.nodeName === 'CANVAS') {
      dispatch(chooseEyeDropperColor(eyeDropper));
    }
  }, [dispatch, eyeDropper, handleRightClickOptions, selectedFrame]);

  useLeftClickOnScreen(onClickScreen);

  const {
    onMouseDownStage,
    onMouseMoveStage,
    onMouseUpStage,
    onMouseLeaveStage,
    onTapStage,
    refSelection,
  } = useMouseEventsStage(refDrawingLayer);

  const {
    onTouchEndStage,
    onTouchMoveDrawingLayer,
  } = useTouchEventsCanvas(refDrawingLayer);

  const [largeContainer, setLargeContainer] = useState({ width: 0, height: 0 });
  const [stageDimensions, setStageDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    setLargeContainer({
      width: Math.max(canvasWidth, (refScroll?.current?.offsetWidth || 0)),
      height: Math.max(baseCanvasHeight, (refScroll?.current?.offsetHeight || 0)),
    });
    setStageDimensions({
      width: refScroll?.current?.offsetWidth + PADDING_STAGE || 0,
      height: refScroll?.current?.offsetHeight + PADDING_STAGE || 0,
    });
  }, [baseCanvasHeight, canvasWidth, controlOpen]);

  useLayoutEffect(() => {
    if (refSimpleBar?.current) {
      refSimpleBar.current.recalculate();
    }
  }, [largeContainer]);

  useScrollEventsCanvas(refDrawingLayer, refScroll);
  const { onCopy, onPaste, copiedElements } = useCopyPasteShortcuts();

  return (
    <>
      <div id={`${PRODUCT_TOUR_PREFIX_ID}-2`} className={styles.productTour}>
        {productTour && <Pulse />}
      </div>
      <SimpleBar
        scrollableNodeProps={{ ref: refScroll, id: CANVAS_SCROLL_ID }}
        forceVisible={!isMobile}
        autoHide={isMobile}
        ref={refSimpleBar}
        className={cn(
          styles.canvas,
          { [styles.eyeDropper]: eyeDropper.active },
        )}
      >
        <div
          className={styles.largeContainer}
          style={{ width: `${largeContainer.width}px`, height: `${largeContainer.height}px` }}
        >
          <Droppable droppableId={CANVAS_DROPPABLE_ID} direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <ReactReduxContext.Consumer>
                  {({ store }) => (
                    <Stage
                      width={stageDimensions.width}
                      height={stageDimensions.height}
                      scaleX={scale}
                      scaleY={scale}
                      id={STAGE_ID}
                      onContextMenu={e => e.evt.preventDefault()}
                      onMouseDown={onMouseDownStage}
                      onMouseMove={onMouseMoveStage}
                      onMouseUp={onMouseUpStage}
                      onMouseLeave={onMouseLeaveStage}
                      onTap={onTapStage}
                      onTouchEnd={onTouchEndStage}
                    >
                      <Provider store={store}>
                        <KeyboardListener>
                          <DrawingLayer
                            refScroll={refScroll}
                            refSimpleBar={refSimpleBar}
                            refSelection={refSelection}
                            ref={refDrawingLayer}
                            onTouchMoveDrawingLayer={onTouchMoveDrawingLayer}
                          />
                        </KeyboardListener>
                      </Provider>
                    </Stage>
                  )}
                </ReactReduxContext.Consumer>
                <div className={styles.placeholder}>
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          {editableText && <CanvasEditableText />}
          {editableTag && <CanvasEditableTag />}
          {editableHyperlink && <CanvasHyperlink />}
          {editableFrameTitle?.type === EDITABLE_TITLE_FRAME_TYPE.TITLE_INPUT &&
            <CanvasEditableFrameTitle />}
          {editableFrameTitle?.type === EDITABLE_TITLE_FRAME_TYPE.MODAL &&
            <RenameFrameTitleModal />}
          {rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT && (
            <CanvasRightClickOptions
              onCopy={onCopy}
              onPaste={onPaste}
              copiedElements={copiedElements}
            />
          )}
          {rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.BACKGROUND &&
            <CanvasRightClickOptionsCanvas />}
          {rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.TITLE &&
            <CanvasRightClickOptionsTitle />}
          {rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.FRAME_CARD &&
            <CanvasRightClickOptionsFrameCard />}
          {rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT_FLOATING_MOBILE &&
            <CanvasElementOptions />}
          {user.settings.showTemplateTip === SHOWING_STATUS.SHOW && (
            <div className={styles.templateTip}>
              <TemplateTip />
            </div>
          )}
        </div>
      </SimpleBar>
    </>
  );
});

Canvas.propTypes = {
  dragStartResult: string,
};

export { Canvas };
