import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

import { toastErrorConfig } from 'src/constants/toastConfig';
import { useProjectSelector } from 'src/hooks';
import { promiseInListWithLimit } from 'src/utils/helpers';
import {
  getCloudinaryFilenameFromUrl,
  getCloudinaryFilenameWithoutTimestamp,
  makeCloudinaryImageDownloable,
} from 'src/utils/cloudinaryHelpers';
import { ProjectService } from 'src/services/projectService';

const useDownloadPreviews = () => {
  const { previews, videoUrls, signSettings } = useProjectSelector();

  const [imagesDownloaded, setImagesDownloaded] = useState(0);

  const downloadFile = useCallback((url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const downloadImage = useCallback((url, fileName) => {
    downloadFile(url, fileName);
    setImagesDownloaded(i => i + 1);
  }, [downloadFile]);

  const getImageUrlAndName = async (urlOfPreview) => {
    const urlToDownload = makeCloudinaryImageDownloable(urlOfPreview);
    const response = await ProjectService.getPreviewOfProject(urlToDownload);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const decodedUrlToDownload = decodeURIComponent(urlToDownload);
    const filename = getCloudinaryFilenameFromUrl(decodedUrlToDownload);
    const nameWithoutTimestamp = getCloudinaryFilenameWithoutTimestamp(filename);
    const fileName = nameWithoutTimestamp;
    return { fileName, url };
  };

  const getVideoUrlAndName = async (urlOfPreview) => {
    const [urlToDownload, filename] = urlOfPreview.split('#');
    const response = await ProjectService.getPreviewOfProject(urlToDownload);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return { fileName: decodeURIComponent(filename), url };
  };

  const downloadPreviews = useCallback(async (selectedIndexes) => {
    try {
      const previewList = signSettings?.downloads || previews;
      const downloadList = await promiseInListWithLimit(previewList, async (preview, index) => {
        if (selectedIndexes && !(selectedIndexes.find(s => s === index) >= 0)) {
          return {};
        }
        const canvasIsVideo = !!videoUrls && videoUrls[index];
        const urlToDownload = canvasIsVideo ? videoUrls[index] : preview;
        const { url, fileName } = canvasIsVideo ?
          await getVideoUrlAndName(urlToDownload) :
          await getImageUrlAndName(urlToDownload);
        index += 1;
        return { url, fileName };
      });
      downloadList.forEach(({ url, fileName }, index) => {
        if (url) {
          const randomTimeOut = 500 + 100 * index;
          setTimeout(() => {
            downloadImage(url, fileName);
          }, randomTimeOut);
        }
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error('There was an error while exporting the media.', toastErrorConfig);
    }
  }, [downloadImage, previews, videoUrls, signSettings]);

  const downloadSinglePreview = useCallback(async (indexInPreviews) => {
    try {
      const canvasIsVideo = !!videoUrls && videoUrls[indexInPreviews];
      const urlToDownload = canvasIsVideo ? videoUrls[indexInPreviews] : previews[indexInPreviews];
      const { url, fileName } = canvasIsVideo ?
        await getVideoUrlAndName(urlToDownload) :
        await getImageUrlAndName(urlToDownload);
      downloadImage(url, fileName);
    } catch (error) {
      Sentry.captureException(error);
      toast.error('There was an error while downloading the media.', toastErrorConfig);
    }
  }, [downloadImage, previews, videoUrls]);

  const downloadPdf = useCallback(async () => {
    if (!signSettings?.pdf) {
      Sentry.captureMessage(`No PDF found for this sign settings ${signSettings?.id}`);
      toast.error('There was an error while searching for the PDF.', toastErrorConfig);
      return;
    }
    try {
      const { url, fileName } = await getImageUrlAndName(signSettings.pdf);
      downloadFile(url, fileName);
    } catch (error) {
      Sentry.captureException(error);
      toast.error('There was an error while downloading the PDF.', toastErrorConfig);
    }
  }, [signSettings, downloadFile]);

  return { imagesDownloaded, downloadPreviews, downloadPdf, downloadSinglePreview };
};

export { useDownloadPreviews };
