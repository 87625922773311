import React from 'react';
import { oneOfType, node, object, oneOf, string, bool, func } from 'prop-types';
import cn from 'classnames';

import { Spinner } from 'src/common/spinner';
import styles from './Button.module.scss';

const Button = ({
  children,
  type,
  className,
  spinnerClassName,
  loading,
  onClick,
  disabled,
  alternativeCTA,
  secondary,
  short,
  id,
}) => (
  <button
    type={type}
    className={cn(
      styles.button,
      className,
      {
        [styles.cta]: !alternativeCTA && !secondary,
        [styles.alternativeCTA]: alternativeCTA,
        [styles.secondary]: secondary,
        [styles.short]: short,
      },
    )}
    disabled={disabled || loading}
    onClick={onClick}
    id={id}
  >
    {loading ?
      <Spinner iconClassName={cn(spinnerClassName, { [styles.secondarySpinner]: secondary })} /> :
      children}
  </button>
);

Button.propTypes = {
  children: oneOfType([node, object]).isRequired,
  type: oneOf(['button', 'submit']),
  className: string,
  spinnerClassName: string,
  loading: bool,
  onClick: func,
  disabled: bool,
  alternativeCTA: bool,
  secondary: bool,
  id: string,
  short: bool,
};

Button.defaultProps = {
  type: 'button',
  className: '',
  spinnerClassName: '',
  loading: false,
  onClick: () => { },
  disabled: false,
  alternativeCTA: false,
  secondary: false,
  short: false,
};

export { Button };
