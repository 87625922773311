
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import rootReducer from '../reducers';
import { createWebsocketMiddleware } from '../middlewares/websocketMiddleware';

export default function configureStore(initialState) {
  const websocket = createWebsocketMiddleware();
  const middlewares = [thunk, websocket];

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: state => {
      const transformedState = {
        ...state,
        session: {
          ...state.session,
          token: null,
        },
      };
      return transformedState;
    },
  });

  const store = createStore(
    rootReducer(),
    initialState,
    compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
