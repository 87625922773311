import React from 'react';

import { RegenerateCollageOption } from '../regenerate-collage-option';
import styles from './RegenerateCollageMobile.module.scss';

const RegenerateCollageMobile = () => (
  <RegenerateCollageOption
    containerClassName={styles.container}
    answerClassName={styles.answerClassName}
    keepLabel="Keep this"
    showLabel="Show new one"
  />
);

export { RegenerateCollageMobile };
