import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getImpressions,
  GET_IMPRESSIONS,
  getTimeInStagger,
  GET_TIME_IN_STAGGER,
  GET_TIME_SPENT_IN_FRAMES,
} from 'src/actions/embedAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { secsToHHMMSS } from 'src/utils/helpers';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import styles from './FeaturedInfo.module.scss';

const FeaturedInfo = () => {
  const dispatch = useDispatch();

  const {
    selectedProject,
    selectedTimeRange,
    impressions,
    timeInStagger,
    timeSpentInFrames,
  } = useSelector(({ embedAnalytics }) => ({
    impressions: embedAnalytics.impressions,
    selectedProject: embedAnalytics.selectedProject,
    selectedTimeRange: embedAnalytics.selectedTimeRange,
    timeInStagger: embedAnalytics.timeInStagger,
    timeSpentInFrames: embedAnalytics.timeSpentInFrames,
  }));

  useEffect(() => {
    const { from, to } = selectedTimeRange;
    dispatch(getImpressions(selectedProject.id, from, to));
    dispatch(getTimeInStagger(selectedProject.id, from, to));
  }, [dispatch, selectedProject, selectedTimeRange]);

  const { status: impressionsStatus } = useStatus(GET_IMPRESSIONS);
  const { status: timeInStaggerStatus } = useStatus(GET_TIME_IN_STAGGER);
  const { status: mostPopularStatus } = useStatus(GET_TIME_SPENT_IN_FRAMES);

  const mostPopularFrame = timeSpentInFrames.reduce((res, current) => {
    if (current.avg > res.avg) {
      res = current;
    }
    return res;
  }, { frame: '', avg: 0 });

  return (
    <div className={styles.container}>
      <div className={styles.rectangle}>
        <span className={styles.title}>Avg Time Spent</span>
        {timeInStaggerStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {timeInStaggerStatus === SUCCESS && (
          <span className={styles.info}>
            {timeInStagger ? secsToHHMMSS(timeInStagger) : '-'}
          </span>
        )}
        {timeInStaggerStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
      <div className={styles.rectangle}>
        <span className={styles.title}>Impressions</span>
        {impressionsStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {impressionsStatus === SUCCESS && (
          <span className={styles.info}>{impressions}</span>
        )}
        {impressionsStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
      <div className={styles.rectangle}>
        <span className={styles.title}>Most Popular</span>
        {mostPopularStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {mostPopularStatus === SUCCESS && (
          <span className={styles.info}>
            {mostPopularFrame.avg ? `Frame ${mostPopularFrame.frame}` : '-'}
          </span>
        )}
        {mostPopularStatus === ERROR && (
          <span className={styles.info}>&#45;</span>
        )}
      </div>
    </div>
  );
};

export { FeaturedInfo };
