import React from 'react';
import { string } from 'prop-types';

import { useTooltip } from 'src/hooks';
import { Tooltip } from 'src/common/tooltip';
import styles from './Referrer.module.scss';

const Referrer = ({ referrer }) => {
  const { ref, showTooltip } = useTooltip();

  return (
    <div ref={ref} className={styles.referrer}>
      <div className={styles.text}>
        {referrer || 'No source found'}
      </div>
      {showTooltip && referrer && (
        <Tooltip className={styles.tooltip}>
          {referrer}
        </Tooltip>
      )}
    </div>
  );
};

Referrer.propTypes = {
  referrer: string,
};

export { Referrer };
