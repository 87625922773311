import React, { useState, useEffect, useRef } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import { useClickOutside } from 'src/hooks';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import styles from './AddNewCategoryInput.module.scss';

const AddNewCategoryInput = ({
  defaultName,
  addCategory,
  disabled = false,
  placeholder = '',
  onCreatedSuccess,
}) => {
  const [name, setName] = useState(defaultName);
  const [error, setError] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const updateName = async () => {
    if (!name) {
      setError('Name cannot be empty');
    } else if (name !== defaultName) {
      try {
        await addCategory({ name });
        onCreatedSuccess();
      } catch (errors) {
        Object.entries(errors).forEach(([key, value]) => {
          key && setError(value);
        });
      }
    }
  };

  const onKeyDown = ({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
      updateName();
    }
    if (error) {
      setError('');
    }
  };

  const ref = useClickOutside(updateName);

  return (
    <div className={styles.container} ref={ref}>
      <input
        value={name}
        className={cn(styles.name, {
          [styles.error]: error,
        })}
        onChange={({ target }) => setName(target.value)}
        onKeyDown={onKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
      />
      <span className={styles.errorLabel}>
        {error || ''}
      </span>
    </div>
  );
};

AddNewCategoryInput.propTypes = {
  defaultName: string.isRequired,
  addCategory: func.isRequired,
  onCreatedSuccess: func.isRequired,
  disabled: bool,
  placeholder: string,
};

export { AddNewCategoryInput };
