import React from 'react';
import { createRoot } from 'react-dom/client';
import { setAutoFreeze } from 'immer';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import SentryFullStory from '@sentry/fullstory';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from 'mixpanel-browser';
import * as FullStory from '@fullstory/browser';
import { HelmetProvider } from 'react-helmet-async';

import './index.scss';
import './toast.scss';
import './pollyfills';
import { isPublicPages } from 'src/utils/helpers';
import App from './App';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import { HttpClient, HttpHeavyClient } from './clients/httpClient';
import { setInterceptors } from './services/setInterceptors';
import { ErrorBoundary } from './error-boundary';
import { SmallScreenModal } from './small-screen-modal';
import { STAGING, PRODUCTION, DEVELOPMENT } from './constants/environments';

setAutoFreeze(false);

const { persistor, store } = configureStore();

const environment = process.env.REACT_APP_ENVIRONMENT;

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  devMode: environment !== PRODUCTION || isPublicPages(),
});

if (environment === PRODUCTION || environment === STAGING) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_APP_ID,
    environment,
    normalizeDepth: 10,
    integrations: [new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_SLUG)],
    maxBreadcrumbs: 30,
  });
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.init(process.env.REACT_APP_MIXPANEL_ANALYTICS_TOKEN, {}, 'analytics');

if (environment === DEVELOPMENT) {
  mixpanel.disable();
  mixpanel.analytics.disable();
}

ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID, {
  testMode: environment === DEVELOPMENT,
});

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = Component => {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SentryReact.ErrorBoundary
          fallback={ErrorBoundary}
          beforeCapture={scope => {
            scope.setTag('showedErrorPage', 'yes');
          }}
        >
          <Component />
          <ToastContainer
            autoClose={4000}
            transition={Slide}
            hideProgressBar
            position="bottom-left"
          />
          <SmallScreenModal />
        </SentryReact.ErrorBoundary>
      </PersistGate>
    </Provider>,
  );
};

const renderPublicPagesApp = Component => {
  root.render(
    <Provider store={store}>
      <SentryReact.ErrorBoundary
        fallback={ErrorBoundary}
        beforeCapture={scope => {
          scope.setTag('showedPublicErrorPage', 'yes');
        }}
      >
        <HelmetProvider>
          <Component />
        </HelmetProvider>
        <ToastContainer
          autoClose={4000}
          transition={Slide}
          hideProgressBar
          position="bottom-left"
        />
      </SentryReact.ErrorBoundary>
    </Provider>,
  );
};

setInterceptors(store, HttpClient);
setInterceptors(store, HttpHeavyClient);

isPublicPages() ? renderPublicPagesApp(App) : renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
