import React from 'react';
import { useSelector } from 'react-redux';
import { func, object } from 'prop-types';

import { Dropzone } from 'src/common/dropzone';
import styles from './EmptyFilesDropzone.module.scss';

const EmptyFilesDropzone = ({ addFiles, acceptType = { 'image/*': ['.heic'], 'video/*': [] } }) => {
  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  return (
    <Dropzone
      onAcceptCallback={addFiles}
      acceptType={acceptType}
      buttonFile
      noClick
      className={styles.container}
      disabled={disabled}
    />
  );
};

EmptyFilesDropzone.propTypes = {
  addFiles: func.isRequired,
  acceptType: object,
};

export { EmptyFilesDropzone };
