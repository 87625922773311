import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { routesPaths } from 'src/routes/routesPaths';
import { reset } from 'src/actions/statusActions';
import { logout } from 'src/actions/userActions';

import {
  checkInviteNeedsNewUser,
  CHECK_INVITE_NEEDS_NEW_USER,
  acceptInvite,
  ACCEPT_INVITE,
} from 'src/actions/accountInviteActions';
import { useStatus } from 'src/hooks';
import { SUCCESS, ERROR } from 'src/constants/status';
import styles from './AccountInvite.module.scss';

const AccountInvite = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const {
    status: checkInviteNeedsNewUserStatus,
    statusInfo,
  } = useStatus(CHECK_INVITE_NEEDS_NEW_USER);

  const { status: acceptInviteStatus } = useStatus(ACCEPT_INVITE);

  useEffect(() => {
    dispatch(logout()); // just in case other user is logged in
    dispatch(checkInviteNeedsNewUser(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (checkInviteNeedsNewUserStatus === SUCCESS) {
      if (statusInfo?.needsNewUser) {
        history.push(routesPaths.signup);
      } else {
        dispatch(acceptInvite(token));
      }
    }
  }, [checkInviteNeedsNewUserStatus, statusInfo, dispatch, token, history]);

  useEffect(() => {
    if (acceptInviteStatus === SUCCESS) {
      history.push(routesPaths.projects);
    }
  }, [acceptInviteStatus, history]);

  useEffect(() => () => dispatch(reset(CHECK_INVITE_NEEDS_NEW_USER)), [dispatch]);
  return (
    <>
      {checkInviteNeedsNewUserStatus === ERROR && (
        <div className={styles.page}>
          <div className={styles.container}>
            <div className={styles.content}>
              <h1 className={styles.header}>Unexpected error</h1>
              <span className={styles.text}>
                Something wrong happened with your invite.
              </span>
              { statusInfo && (
              <span className={styles.textSecondary}>
                {statusInfo}
              </span>
              ) }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { AccountInvite };
