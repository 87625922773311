import React, { useMemo } from 'react';
import { node, element, oneOfType, string, bool, number, shape, object } from 'prop-types';
import cn from 'classnames';

import styles from './Tooltip.module.scss';

const Tooltip = ({
  children,
  className = '',
  followMouse = false,
  offsetX = 6,
  offsetY = 6,
  topLeftPosition,
  style,
}) => {
  const tooltipStyle = useMemo(() => {
    if (followMouse) {
      return { left: topLeftPosition.x + offsetX, top: topLeftPosition.y + offsetY };
    }
    return style;
  }, [followMouse, offsetX, offsetY, topLeftPosition, style]);

  return (
    <div
      className={cn(styles.tooltip, className, { [styles.followMouse]: followMouse })}
      style={tooltipStyle}
    >
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  className: string,
  children: oneOfType([node, element]).isRequired,
  followMouse: bool,
  offsetX: number,
  offsetY: number,
  topLeftPosition: shape({
    y: number,
    x: number,
  }),
  style: object,
};

export { Tooltip };
