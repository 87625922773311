import React, { useRef, useState, useCallback } from 'react';
import { func, bool } from 'prop-types';

import { Dots } from 'src/common/modal-slider/dots';
import { Modal } from 'src/common/modal';
import { Button } from 'src/common/button';
import { ModalSlider } from 'src/common/modal-slider';
import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ArrowLeftBlack from 'src/assets/icons/arrow-left-black.svg';
import ArrowRightBlack from 'src/assets/icons/arrow-right-black.svg';
import { STORYTELLING_PREVIEWS, STORYTELLING_TEXT } from 'src/constants/storytelling';
import styles from './StorytellingModal.module.scss';

const LAST_SLIDE = 2;
const StorytellingModal = ({
  isShowing,
  onClose,
}) => {
  const refSlider = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const finishStorytelling = useCallback(() => {
    setCurrentSlide(0);
    refSlider.current.slickGoTo(0);
    onClose();
  }, [onClose]);

  const previews = STORYTELLING_PREVIEWS.map(preview => (
    <img src={preview} alt="" key={preview} />
  ));

  const previousButton = (
    <button
      className={styles.buttonArrow}
      onClick={() => {
        refSlider.current.slickGoTo(currentSlide - 1);
        setCurrentSlide(currentSlide - 1);
      }}
      disabled={currentSlide === 0}
    >
      <img src={currentSlide === 0 ? ArrowLeft : ArrowLeftBlack} alt="Left arrow" />
    </button>
  );

  let buttons;
  if (currentSlide === LAST_SLIDE) {
    buttons = (
      <>
        {previousButton}
        <Button
          className={styles.buttonGetStarted}
          onClick={finishStorytelling}
          alternativeCTA
          short
        >
          Get Started
        </Button>
      </>
    );
  } else {
    buttons = (
      <>
        {previousButton}
        <button
          className={styles.buttonArrow}
          onClick={() => {
            refSlider.current.slickNext();
            setCurrentSlide(currentSlide + 1);
          }}
        >
          <img src={ArrowRightBlack} alt="Right arrow" />
        </button>
      </>
    );
  }

  return (
    <Modal
      isShowing={isShowing}
      hide={finishStorytelling}
      isEscEnabled
      curvedContainer
      backgroundLight
      hideClassName={styles.close}
      productTour
      closeOnClickOutside={false}
      classNameContent={styles.modalContent}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>{STORYTELLING_TEXT[currentSlide].title}</span>
          <span className={styles.subtitle}>
            {STORYTELLING_TEXT[currentSlide].subtitle()}
          </span>
        </div>
        <div className={styles.body}>
          <ModalSlider
            ref={refSlider}
            slides={previews}
          />
        </div>
        <div className={styles.bottom}>
          <Dots activeIndex={currentSlide} size={LAST_SLIDE + 1} />
          <div className={styles.buttons}>
            {buttons}
          </div>
        </div>
      </div>
    </Modal>
  );
};

StorytellingModal.propTypes = {
  isShowing: bool.isRequired,
  onClose: func.isRequired,
};

export { StorytellingModal };
