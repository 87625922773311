import React, { useMemo } from 'react';
import { arrayOf, func, number } from 'prop-types';

import { useProjectSelector } from 'src/hooks';
import { PreviewCard } from './preview-card';
import styles from './ExportPreviewSelection.module.scss';

const ExportPreviewSelection = ({ selectedIndexes, setSelectedIndexes }) => {
  const { previews } = useProjectSelector();

  const onClickCard = (index) => {
    setSelectedIndexes(s => {
      if (s.find(elem => elem === index) >= 0) {
        return s.filter(elem => elem !== index);
      }
      return [...s, index];
    });
  };

  const textIndicator = useMemo(() => {
    if (!selectedIndexes.length) {
      return 'No canvas selected';
    }
    if (selectedIndexes.length === 1) {
      return 'Downloading 1 canvas';
    }
    return `Downloading ${selectedIndexes.length} canvases`;
  }, [selectedIndexes.length]);

  return (
    <div className={styles.container}>
      <span className={styles.indicator}>
        {textIndicator}
      </span>
      <div className={styles.thumbs}>
        {previews.map((preview, index) => (
          <PreviewCard
            key={preview}
            preview={preview}
            onClick={() => onClickCard(index)}
            isSelected={selectedIndexes.find(s => s === index) >= 0}
          />
        ))}
      </div>
    </div>
  );
};

ExportPreviewSelection.propTypes = {
  selectedIndexes: arrayOf(number).isRequired,
  setSelectedIndexes: func.isRequired,
};

export { ExportPreviewSelection };
