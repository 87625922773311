import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { ERROR, LOADING, SUCCESS, NOT_STARTED } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { Navbar } from 'src/common/navbar';
import { Alert } from 'src/common/alert';
import {
  getUserForDashboard,
  GET_USER_FOR_DASHBOARD,
  GET_EXPORT_DATE,
  GET_REFERRERS,
  GET_TIME_SPENT_IN_FRAMES,
  GET_LINKS_CLICKED,
  GET_TIME_IN_STAGGER,
  GET_IMPRESSIONS,
} from 'src/actions/embedAnalyticsActions';
import {
  FB_SDK_INIT,
  GET_POST_DATA,
  GET_POST_INSIGHTS,
  IS_LOGGED_IN_TO_FB,
  LOGIN_TO_FB,
} from 'src/actions/instagramAnalyticsActions';
import { useStatus, useSession, useCheckFBLoginStatus } from 'src/hooks';
import { DASHBOARD_PLANS } from 'src/constants/memberships';
import { Button } from 'src/common/button';
import { reset } from 'src/actions/statusActions';
import { PROJECT_TYPE, VISIBILITY } from 'src/constants/general';
import { UpgradeAlert } from 'src/common/upgrade-alert';
import { EmbedEmptyState } from './embed-empty-state';
import { EmbedDashboard } from './embed-dashboard';
import { InstagramDashboard } from './instagram-dashboard';
import { UpgradeModal } from './upgrade-modal';
import styles from './Dashboard.module.scss';

const EMBED_CODES_OPTION = 'embedCodes';
const INSTAGRAM_POSTS_OPTION = 'instagramPosts';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { user, accountId } = useSession();
  const { status: getUserStatus } = useStatus(GET_USER_FOR_DASHBOARD);

  useEffect(() => {
    if (!getUserStatus || getUserStatus === NOT_STARTED) {
      dispatch(getUserForDashboard(accountId));
    }
  }, [accountId, dispatch, getUserStatus]);

  useEffect(() => () => {
    dispatch(reset(GET_USER_FOR_DASHBOARD));
  }, [dispatch]);

  useCheckFBLoginStatus();

  const { disabled } = useSelector(({ embedAnalytics }) => ({
    disabled: embedAnalytics.disabled,
  }));

  const { status: getGraph } = useStatus(GET_TIME_SPENT_IN_FRAMES);
  const { status: getLinks } = useStatus(GET_LINKS_CLICKED);
  const { status: getExportDate } = useStatus(GET_EXPORT_DATE);
  const { status: getReferrers } = useStatus(GET_REFERRERS);
  const { status: getTimeInStagger } = useStatus(GET_TIME_IN_STAGGER);
  const { status: getImpressions } = useStatus(GET_IMPRESSIONS);
  const { status: getPostData } = useStatus(GET_POST_DATA);
  const { status: getPostInsights } = useStatus(GET_POST_INSIGHTS);
  const { status: fbSDKInitStatus } = useStatus(FB_SDK_INIT);
  const { status: isLoggedInToFBStatus, error: isLoggedInError } = useStatus(IS_LOGGED_IN_TO_FB);
  const { status: loginToFBStatus, error: loginToFBError } = useStatus(LOGIN_TO_FB);

  const [navigationOpen, setNavigationOpen] = useState(EMBED_CODES_OPTION);
  const [showAnyway, setShowAnyway] = useState(false);

  const userProjects = useMemo(() => {
    if (!user?.membership?.accounts) return [];
    const account = user.membership.accounts.find(a => a.id === accountId);
    const projects = account?.projects;
    const sortedProjects = projects?.sort(
      (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
    );
    return sortedProjects;
  }, [user, accountId]);


  const dashboardContent = useMemo(() => {
    if (navigationOpen === EMBED_CODES_OPTION) {
      const projectsToTrack = userProjects?.filter(project => project.embedCodeGenerated ||
          (project.type === PROJECT_TYPE.FLYER &&
            project.flyerSettings?.visibility === VISIBILITY.PUBLIC));
      if (projectsToTrack?.length) {
        return <EmbedDashboard projects={projectsToTrack} />;
      }
      return <EmbedEmptyState />;
    }
    return <InstagramDashboard />;
  }, [navigationOpen, userProjects]);

  const errorInEmbedAnalytics = navigationOpen === EMBED_CODES_OPTION && (getGraph === ERROR ||
    getLinks === ERROR || getExportDate === ERROR || getReferrers === ERROR ||
    getTimeInStagger === ERROR || getImpressions === ERROR);

  const errorInInstagramAnalytics = navigationOpen === INSTAGRAM_POSTS_OPTION &&
    (fbSDKInitStatus === ERROR || getPostData === ERROR || getPostInsights === ERROR);

  let specificErrorMessage;
  const specificErrorInInstagramAnalytics = loginToFBStatus === ERROR ||
    isLoggedInToFBStatus === ERROR;
  if (specificErrorInInstagramAnalytics) {
    specificErrorMessage = loginToFBError ? loginToFBError.message : isLoggedInError.message;
  }

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.subnav}>
        <h1 className={styles.title}>Dashboard</h1>
        <div className={styles.tabs}>
          <button
            onClick={() => setNavigationOpen(EMBED_CODES_OPTION)}
            className={cn(styles.headerOption, {
              [styles.active]: navigationOpen === EMBED_CODES_OPTION,
            })}
          >
            Embed Codes
          </button>
          <button
            onClick={() => setNavigationOpen(INSTAGRAM_POSTS_OPTION)}
            className={cn(styles.headerOption, {
              [styles.active]: navigationOpen === INSTAGRAM_POSTS_OPTION,
            })}
          >
            Instagram Posts
          </button>
        </div>
      </div>
      {getUserStatus === SUCCESS && (errorInEmbedAnalytics || errorInInstagramAnalytics) && (
        <Alert>
          <span>
            An error occurred while requesting the analytics. Please try again.
          </span>
        </Alert>
      )}
      {specificErrorInInstagramAnalytics && (
        <Alert>
          <span>
            {specificErrorMessage}
          </span>
        </Alert>
      )}
      {getUserStatus === SUCCESS && disabled && (
        <>
          <UpgradeAlert
            plans={DASHBOARD_PLANS}
            upgradeChildren={(
              <>
                <span className={styles.upgradeAlertText}>
                  Get realtime analytics on your Stagger embeds and Instagram posts.
                </span>
                <Button
                  className={styles.alertButton}
                  onClick={() => setShowAnyway(true)}
                  short
                >
                  More info
                </Button>
              </>
          )}
          />
          <UpgradeModal showAnyway={showAnyway} setShowAnyway={setShowAnyway} />
        </>
      )}
      <div className={styles.content}>
        {getUserStatus === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {getUserStatus === SUCCESS && dashboardContent}
      </div>
    </div>
  );
};

export { Dashboard };
