import React, { useCallback, useMemo, useState } from 'react';
import { number, func, arrayOf, bool } from 'prop-types';

import { Button } from 'src/common/button';
import { Select } from 'src/common/select';
import { useSession } from 'src/hooks';
import styles from './MoveProjects.module.scss';

const MoveProjects = ({ ids, onCancel, onMoveProjects, loading }) => {
  const { user } = useSession();

  const [selectedOption, setSelectedOption] = useState();

  const onChange = useCallback((item) => {
    setSelectedOption(item);
  }, []);

  const options = useMemo(() => {
    const filteredOptions = user.membership.accounts.filter(acc => !ids.includes(acc.id));
    return filteredOptions.map(account => ({ label: account.brandName, value: account.id }));
  }, [ids, user.membership.accounts]);

  return (
    <>
      <span className={styles.subtitle}>
        Great, we&apos;ll move your projects. Which account do you want them moved to?
      </span>
      <Select
        options={options}
        onChange={onChange}
        value={selectedOption}
        placeholder="Select account"
        className={styles.select}
        closeMenuOnSelect
      />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          onClick={() => onMoveProjects(selectedOption.value)}
          alternativeCTA
          disabled={!selectedOption}
          loading={loading}
        >
          Move Projects
        </Button>
        <Button
          className={styles.buttonDelete}
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

MoveProjects.propTypes = {
  ids: arrayOf(number).isRequired,
  onCancel: func.isRequired,
  loading: bool.isRequired,
  onMoveProjects: func.isRequired,
};

export { MoveProjects };
