import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chunk from 'lodash.chunk';

import {
  setSelectedCategoryId,
  getTemplatesSeeAll,
  SEE_ALL,
} from 'src/actions/templatesActions';
import { LOADING } from 'src/constants/status';
import { changeControlOpen } from 'src/actions/canvasActions';
import { TEMPLATES_CONTROL } from 'src/constants/canvasControls';
import { useStatus, usePagination } from 'src/hooks';
import { reset } from 'src/actions/statusActions';
import { TemplateGrid } from 'src/pages/templates/template-grid';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import { CUSTOM_TEMPLATE_CATEGORY } from 'src/constants/general';
import styles from './SeeAllTemplates.module.scss';

const SeeAllTemplates = () => {
  const dispatch = useDispatch();

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const goToTemplates = () => {
    dispatch(changeControlOpen(TEMPLATES_CONTROL));
    dispatch(setSelectedCategoryId(undefined));
  };

  const {
    selectedCategoryId: categoryId,
    categories,
    loadingPaginationCategoryId,
    templatesInSeeAllByPages,
    accountId,
  } = useSelector(({ templates, session }) => ({
    selectedCategoryId: templates.selectedCategoryId,
    categories: templates.categories,
    loadingPaginationCategoryId: templates.loadingPaginationCategoryId,
    templatesInSeeAllByPages: templates.templatesInSeeAllByPages,
    accountId: session.accountId,
  }));

  const { templates } = categories[categoryId];
  const { name } = categories[categoryId];

  const handleFetchAll = useCallback(async (page) => {
    await dispatch(getTemplatesSeeAll(accountId, categoryId, page));
  }, [categoryId, dispatch, accountId]);

  const { status } = useStatus(SEE_ALL);
  let showLoading = false;

  if (status === LOADING) {
    showLoading = loadingPaginationCategoryId === categoryId;
  }

  const { page, prevPage, nextPage } = usePagination(handleFetchAll);

  useEffect(() => {
    const initialPage = 1;
    dispatch(getTemplatesSeeAll(accountId, categoryId, initialPage));

    return () => dispatch(reset(SEE_ALL));
  }, [categoryId, dispatch, accountId]);

  const rows = chunk(templates, amountOfTemplatesInARow);

  let isNextPageDisabled = false;
  if (templatesInSeeAllByPages[categoryId]?.total) {
    const { total, current } = templatesInSeeAllByPages[categoryId];
    isNextPageDisabled = current === total;
  }

  return (
    <TemplateGrid
      isMainHeader
      name={name === CUSTOM_TEMPLATE_CATEGORY ? 'My Custom Templates' : name}
      goToTemplates={goToTemplates}
      showLoading={showLoading}
      templates={rows}
      status={status}
      page={page}
      isNextPageDisabled={isNextPageDisabled}
      prevPage={prevPage}
      nextPage={nextPage}
      categoryId={categoryId}
      amountOfTemplatesInARow={amountOfTemplatesInARow}
      templateCardClassName={styles.card}
      titleClassName={styles.title}
    />
  );
};

export { SeeAllTemplates };
