/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { object, string } from 'prop-types';
import { getTime } from 'date-fns';

import ShadowIcon from 'src/assets/icons/shadow.svg';
import { updateElement } from 'src/actions/projectActions';
import { useProjectSelector, useClickOutside } from 'src/hooks';
import {
  SHAPE_DISABLED_COLOR,
  SHADOW_COLOR_EYEDROPPER_SOURCE,
} from 'src/constants/general';
import { ToggleSwitch } from 'src/common/toggle-switch';
import { ColorPickerCanvas } from 'src/pages/project/canvas/canvas-helper-elements';
import { InputNumber } from 'src/common/input-number';
import { ButtonTooltip } from 'src/common/button-tooltip';
import styles from './ShadowOptions.module.scss';

const DEFAULT_SHADOW_X = 2;
const DEFAULT_SHADOW_Y = 2;
const DEFAULT_SHADOW_BLUR = 5;
const DEFAULT_SHADOW_COLOR = '#000000';
const DEFAULT_SHADOW_ALPHA = 25;

const WRITING_VALUE = null;

const ShadowOptions = ({
  className = '',
  optionsStyle,
  containerId,
  tooltipStyle,
  tooltipClassName,
}) => {
  const dispatch = useDispatch();

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const [isOpen, setIsOpen] = useState(false);

  const elementSelected = useMemo(() => {
    const elem = elements.find(e => e.uuid === selectedElementsList[0]);
    if (!elem) return {};
    const shadowAttrs = {
      shadowOffsetX: elem.shadowOffsetX !== undefined ? elem.shadowOffsetX : DEFAULT_SHADOW_X,
      shadowOffsetY: elem.shadowOffsetY !== undefined ? elem.shadowOffsetY : DEFAULT_SHADOW_Y,
      shadowBlur: elem.shadowBlur >= 0 ? elem.shadowBlur : DEFAULT_SHADOW_BLUR,
      shadowColor: elem.shadowColor ||
          { hex: DEFAULT_SHADOW_COLOR, alpha: DEFAULT_SHADOW_ALPHA },
      shadowEnabled: elem.shadowEnabled,
    };
    return { ...elem, ...shadowAttrs };
  }, [elements, selectedElementsList]);

  const changeShadowElementRequest = useCallback((prop, event) => {
    selectedElementsList.forEach(elementId => {
      const newAttrs = {
        uuid: elementId,
        ...prop,
      };
      dispatch(updateElement(newAttrs, event.timeStamp));
    });
  }, [dispatch, selectedElementsList]);

  const toggleShadow = useCallback(() => {
    const attrs = {
      shadowOffsetX: elementSelected.shadowOffsetX,
      shadowOffsetY: elementSelected.shadowOffsetY,
      shadowBlur: elementSelected.shadowBlur,
      shadowColor: elementSelected.shadowColor,
      shadowEnabled: !elementSelected.shadowEnabled,
    };
    const timeStamp = getTime(new Date());
    changeShadowElementRequest(attrs, { timeStamp });
  }, [changeShadowElementRequest, elementSelected]);

  const disable = !elementSelected;

  const clickeableId = 'clickeableDropShadow';
  const ref = useClickOutside(
    () => setIsOpen(false),
    isOpen,
    [clickeableId],
  );

  return (
    <div className={cn(styles.container, className)} id={containerId}>
      <ButtonTooltip
        text="Drop Shadow"
        onClick={() => setIsOpen(o => !o)}
        icon={ShadowIcon}
        alt="Drop Shadow"
        disabled={disable}
        ignoreTooltip={isOpen}
        iconId={clickeableId}
        tooltipStyle={tooltipStyle}
        className={tooltipClassName}
      />
      {isOpen && (
        <div className={styles.popup} ref={ref} style={optionsStyle}>
          <div className={styles.header}>
            <span className={styles.headerText}>Drop Shadow</span>
            <ToggleSwitch
              checked={elementSelected?.shadowEnabled}
              onChange={toggleShadow}
            />
          </div>
          <div className={styles.properties}>
            <div className={styles.property}>
              <label htmlFor="propX" className={styles.label}>
                X:
                <InputNumber
                  name="propX"
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  onChange={(value, e) => (
                    changeShadowElementRequest({
                      shadowOffsetX: value ? Number(value) : WRITING_VALUE,
                    }, e)
                  )}
                  value={elementSelected.shadowOffsetX === WRITING_VALUE ?
                    undefined :
                    elementSelected.shadowOffsetX}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      changeShadowElementRequest({
                        shadowOffsetY: DEFAULT_SHADOW_X,
                      }, e);
                    }
                  }}
                />
              </label>
            </div>
            <div className={styles.property}>
              <label htmlFor="propY" className={styles.label}>
                Y:
                <InputNumber
                  name="propY"
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  onChange={(value, e) => (
                    changeShadowElementRequest({
                      shadowOffsetY: value ? Number(value) : WRITING_VALUE,
                    }, e)
                  )}
                  value={elementSelected.shadowOffsetY === WRITING_VALUE ?
                    undefined :
                    elementSelected.shadowOffsetY}
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      changeShadowElementRequest({
                        shadowOffsetY: DEFAULT_SHADOW_Y,
                      }, e);
                    }
                  }}
                />
              </label>
            </div>
            <div className={styles.property}>
              <label htmlFor="propBlur" className={styles.label}>
                Blur:
                <InputNumber
                  name="propBlur"
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  onChange={(value, e) => (
                    changeShadowElementRequest({ shadowBlur: +value }, e)
                  )}
                  value={elementSelected.shadowBlur >= 0 ?
                    elementSelected.shadowBlur : DEFAULT_SHADOW_BLUR}
                  min={0}
                />
              </label>
            </div>
          </div>
          <div className={styles.colorContainer}>
            <ColorPickerCanvas
              color={elementSelected?.shadowColor ||
                { hex: DEFAULT_SHADOW_COLOR, alpha: DEFAULT_SHADOW_ALPHA }}
              onColorChange={(shadowColor, e) => changeShadowElementRequest({ shadowColor }, e)}
              className={styles.colorPicker}
              disabled={disable}
              disabledColor={SHAPE_DISABLED_COLOR}
              eyedropperSource={SHADOW_COLOR_EYEDROPPER_SOURCE}
            />
            <span className={styles.color}>
              {(disable || !elementSelected?.shadowColor?.hex) ?
                DEFAULT_SHADOW_COLOR :
                elementSelected?.shadowColor?.hex}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

ShadowOptions.propTypes = {
  className: string,
  optionsStyle: object,
  containerId: string,
  tooltipStyle: object,
  tooltipClassName: string,
};

export { ShadowOptions };
