import React from 'react';
import { func } from 'prop-types';
import { Link } from 'react-router-dom';

import DownloadIcon from 'src/assets/icons/download-longpress.svg';
import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { routesPaths } from 'src/routes/routesPaths';
import { routeWithProps } from 'src/utils/helpers';
import { useProjectSelector } from 'src/hooks';
import styles from './NewTabModal.module.scss';

const NewTabModal = ({ hide }) => {
  const { id } = useProjectSelector();

  return (
    <Modal
      isShowing
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Save Images
        </span>
        <div className={styles.imageContainer}>
          <img src={DownloadIcon} alt="Download long press" />
        </div>
        <span className={styles.subtitle}>
          A new window will open.
          To save your images, long press on each image to save to your device.
        </span>
        <Link
          to={routeWithProps(routesPaths.previewBreakDown, { id })}
          target="_blank"
          className={styles.link}
        >
          <Button
            className={styles.button}
            alternativeCTA
            onClick={hide}
          >
            Got it!
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

NewTabModal.propTypes = {
  hide: func.isRequired,
};

export { NewTabModal };
