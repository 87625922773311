import { CategoryService } from 'src/services/categoryService';
import { KeywordService } from 'src/services/keywordsService';
import { TemplateService } from 'src/services/templateService';
import createAction from './createAction';

export const GET_CATEGORIES_WITH_TEMPLATES = 'GET_CATEGORIES_WITH_TEMPLATES';
export const GET_CATEGORIES_WITH_TEMPLATES_SUCCESS = 'GET_CATEGORIES_WITH_TEMPLATES_SUCCESS';
export const GET_CATEGORIES_WITH_TEMPLATES_REQUEST = 'GET_CATEGORIES_WITH_TEMPLATES_REQUEST';
export const GET_CATEGORIES_WITH_TEMPLATES_ERROR = 'GET_CATEGORIES_WITH_TEMPLATES_ERROR';

export const GET_CATEGORY_TEMPLATES = 'GET_CATEGORY_TEMPLATES';
export const GET_CATEGORY_TEMPLATES_SUCCESS = 'GET_CATEGORY_TEMPLATES_SUCCESS';
export const GET_CATEGORY_TEMPLATES_REQUEST = 'GET_CATEGORY_TEMPLATES_REQUEST';
export const GET_CATEGORY_TEMPLATES_ERROR = 'GET_CATEGORY_TEMPLATES_ERROR';

export const GET_CATEGORY_TEMPLATES_RESET = 'GET_CATEGORY_TEMPLATES_RESET';

export const SEE_ALL = 'SEE_ALL';
export const SEE_ALL_SUCCESS = 'SEE_ALL_SUCCESS';
export const SEE_ALL_REQUEST = 'SEE_ALL_REQUEST';
export const SEE_ALL_ERROR = 'SEE_ALL_ERROR';

export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';

export const SET_LOADING_PAGINATION_CATEGORY_ID = 'SET_LOADING_PAGINATION_CATEGORY_ID';

export const SEARCH_TEMPLATES = 'SEARCH_TEMPLATES';
export const SEARCH_TEMPLATES_SUCCESS = 'SEARCH_TEMPLATES_SUCCESS';
export const SEARCH_TEMPLATES_REQUEST = 'SEARCH_TEMPLATES_REQUEST';
export const SEARCH_TEMPLATES_ERROR = 'SEARCH_TEMPLATES_ERROR';

export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';

export const RESET_SEARCH_TEMPLATES = 'RESET_SEARCH_TEMPLATES';

export const SAVE_SELECTED_TEMPLATE_PREVIEW = 'SAVE_SELECTED_TEMPLATE_PREVIEW';

export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';

export const getCategoriesWithTemplatesRequest =
  createAction(GET_CATEGORIES_WITH_TEMPLATES_REQUEST);
export const getCategoriesWithTemplatesSuccess =
  createAction(GET_CATEGORIES_WITH_TEMPLATES_SUCCESS);
export const getCategoriesWithTemplatesError =
  createAction(GET_CATEGORIES_WITH_TEMPLATES_ERROR);

export const getCategoryTemplatesRequest = createAction(GET_CATEGORY_TEMPLATES_REQUEST);
export const getCategoryTemplatesSuccess = createAction(GET_CATEGORY_TEMPLATES_SUCCESS);
export const getCategoryTemplatesError = createAction(GET_CATEGORY_TEMPLATES_ERROR);

export const saveCategoryTemplatesReset = createAction(GET_CATEGORY_TEMPLATES_RESET);

export const seeAllRequest = createAction(SEE_ALL_REQUEST);
export const seeAllSuccess = createAction(SEE_ALL_SUCCESS);
export const seeAllError = createAction(SEE_ALL_ERROR);

export const setSelectedCategoryId = createAction(SET_SELECTED_CATEGORY);

export const setLoadingPaginationCategoryId = createAction(SET_LOADING_PAGINATION_CATEGORY_ID);

export const searchTemplatesRequest = createAction(SEARCH_TEMPLATES_REQUEST);
export const searchTemplatesSuccess = createAction(SEARCH_TEMPLATES_SUCCESS);
export const searchTemplatesError = createAction(SEARCH_TEMPLATES_ERROR);

export const setSearchKeyword = createAction(SET_SEARCH_KEYWORD);

export const resetSearchTemplates = createAction(RESET_SEARCH_TEMPLATES);

export const saveSelectedTemplatePreview = createAction(SAVE_SELECTED_TEMPLATE_PREVIEW);

export const getTemplateRequest = createAction(GET_TEMPLATE_REQUEST);
export const getTemplateSuccess = createAction(GET_TEMPLATE_SUCCESS);
export const getTemplateError = createAction(GET_TEMPLATE_ERROR);

export const getCategoriesWithTemplates = (
  accountId,
  page,
  typeOfProjects,
  perPage = 4,
) => async dispatch => {
  try {
    dispatch(getCategoriesWithTemplatesRequest());
    const { data: { categories } } = await CategoryService
      .getCategoriesWithTemplates(accountId, page, perPage, typeOfProjects);
    dispatch(getCategoriesWithTemplatesSuccess({ categories, page, perPage }));
  } catch (error) {
    dispatch(getCategoriesWithTemplatesError());
  }
};

export const getCategoryTemplates = (accountId, id, page, perPage = 4) => async dispatch => {
  try {
    dispatch(getCategoryTemplatesRequest({ categoryId: id }));
    const { data } =
      await TemplateService.fetchAllTemplatesFromCategory(accountId, id, page, perPage);
    dispatch(getCategoryTemplatesSuccess({ ...data, categoryId: id, page, perPage }));
  } catch (error) {
    dispatch(getCategoryTemplatesError());
  }
};

export const getTemplatesSeeAll = (accountId, id, page, perPage = 12) => async dispatch => {
  try {
    dispatch(seeAllRequest({ categoryId: id }));
    const { data } =
      await TemplateService.fetchAllTemplatesFromCategory(accountId, id, page, perPage);
    dispatch(seeAllSuccess({ ...data, categoryId: id, page, perPage }));
  } catch (error) {
    dispatch(seeAllError());
  }
};

export const getSearchTemplates = (
  accountId,
  keyword,
  projectType,
  page,
  perPage = 12,
) => async (dispatch) => {
  try {
    dispatch(searchTemplatesRequest());
    dispatch(setSearchKeyword(keyword));
    const { data } = await KeywordService.searchTemplatesByKeywords(
      keyword,
      page,
      perPage,
      accountId,
      projectType,
    );
    dispatch(searchTemplatesSuccess({ ...data, page, perPage }));
  } catch (error) {
    dispatch(searchTemplatesError());
  }
};

export const getTemplate = (id) => async dispatch => {
  try {
    dispatch(getTemplateRequest());
    const { data } = await TemplateService.getTemplate(id);
    dispatch(saveSelectedTemplatePreview(data.template));
    dispatch(getTemplateSuccess());
  } catch (error) {
    dispatch(getTemplateError());
  }
};
