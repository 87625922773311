import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { setSelectingMultipleMobile } from 'src/actions/canvasActions';
import { useProjectSelector } from 'src/hooks';
import styles from './MultipleSelectControl.module.scss';

const MultipleSelectControl = () => {
  const dispatch = useDispatch();

  const { selectedElements } = useProjectSelector();

  const selectedElementsAmount = Object.values(selectedElements).length;

  const done = () => {
    dispatch(setSelectingMultipleMobile({ selectingMultipleMobile: false }));
  };

  return (
    <div className={styles.container}>
      <span className={styles.text}>
        Selected {`(${selectedElementsAmount})`}
      </span>
      <Button
        className={styles.done}
        onClick={done}
        alternativeCTA
        short
      >
        Done
      </Button>
    </div>
  );
};

export { MultipleSelectControl };
