import mixpanel from 'mixpanel-browser';
import * as FullStory from '@fullstory/browser';
import ReactGA from 'react-ga4';

import { getStringCapitalized, getUserNameToShow } from 'src/utils/helpers';
import { PLAN } from 'src/constants/memberships';

export const trackLogin = user => {
  FullStory.identify(user.id, {
    displayName: getUserNameToShow(user),
    email: user.email,
  });
  mixpanel.identify(user.email);
  mixpanel.people.set_once({
    $name: getUserNameToShow(user),
    $email: user.email,
  });
};

export const trackSignup = user => {
  FullStory.identify(user.id, {
    displayName: getUserNameToShow(user),
    email: user.email,
  });
  const freePlanCapitalized = getStringCapitalized(PLAN.FREE);
  mixpanel.identify(user.email);
  mixpanel.track('Signup', { CreatedOnCampaign: user.createdOnCampaign });
  mixpanel.people.set({
    $name: getUserNameToShow(user),
    $email: user.email,
    'Subscribed to plan': freePlanCapitalized,
  });
};

export const trackUserPersona = user => {
  mixpanel.people.set({
    Persona: user.persona,
  });
};

export const trackGeneratePreview = id => {
  mixpanel.track('Generate preview of project', { ProjectId: id });
  mixpanel.analytics.track('StaggerPublished', {
    project: { id },
    trigger_ts: Date.now ? Date.now() : +new Date(),
  });
};

export const trackDownload = (id, layoutSource) => {
  mixpanel.track('Download project', { ProjectId: id, LayoutSource: layoutSource });
};

export const trackCopyEmbedCode = (id, layoutSource) => {
  mixpanel.track('Copy embed code', { ProjectId: id, LayoutSource: layoutSource });
};

export const trackAddVideoToCanvas = (id, duration) => {
  mixpanel.track('Add video to canvas', { ProjectId: id, VideoDuration: duration.toFixed(2) });
};

export const trackAddImageToCanvas = (id, uploadType) => {
  mixpanel.track('Add image to canvas', { ProjectId: id, ImageUploadType: uploadType });
};

export const trackReplaceImage = (id, uploadType) => {
  mixpanel.track('Replace image', { ProjectId: id, ImageUploadType: uploadType });
};

export const trackApplyTemplate = (id, category, layoutSource) => {
  mixpanel.track('Apply template', {
    ProjectId: id,
    TemplateCategory: category,
    TemplateLayoutSource: layoutSource,
  });
};

export const resetIdentity = (user) => {
  mixpanel.people.set({ $name: getUserNameToShow(user) });
  mixpanel.identify(user.email);
};

export const setEmailSubscription = (user, subscribed) => {
  mixpanel.people.set('Subscribed to email', subscribed);
  mixpanel.identify(user.email);
};

export const trackAddLogoToBrandLibrary = () => {
  mixpanel.track('Brand Library - Add logo');
};

export const trackDeleteLogoToBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete logo');
};

export const trackAddAssetToBrandLibrary = () => {
  mixpanel.track('Brand Library - Add asset');
};

export const trackDeleteAssetToBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete asset');
};

export const trackCreateAssetCategoryOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Create asset category');
};

export const trackCreateLogoCategoryOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Create logo category');
};

export const trackDeleteAssetCategoryOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete asset category');
};

export const trackDeleteLogoCategoryOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete logo category');
};

export const trackCreateColorPaletteOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Create color palette');
};

export const trackDeleteColorPaletteOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete color palette');
};

export const trackAddColorToPaletteOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Add color to color palette');
};

export const trackDeleteColorToPaletteOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete color from color palette');
};

export const trackAddFontStyleToBrandLibrary = (uploadType) => {
  mixpanel.track('Brand Library - Create font style', { FontUploadType: uploadType });
};

export const trackUpdateFontStyleOfBrandLibrary = (uploadType) => {
  mixpanel.track('Brand Library - Update font style', { FontUploadType: uploadType });
};

export const trackDeleteFontStyleOfBrandLibrary = (uploadType) => {
  mixpanel.track('Brand Library - Delete font style', { FontUploadType: uploadType });
};

export const trackUploadFontToBrandLibrary = () => {
  mixpanel.track('Brand Library - Upload font');
};

export const trackDeleteUploadedFontOfBrandLibrary = () => {
  mixpanel.track('Brand Library - Delete uploaded font');
};

export const trackSuggestionUsed = () => {
  mixpanel.track('Suggestion used');
};

export const trackWebsiteScrapeRequested = () => {
  mixpanel.track('Website scrape requested');
};

export const trackWebsiteScraped = (status) => {
  mixpanel.track('Website scraped', { Status: status });
};

export const trackQRScanned = (accountId) => {
  mixpanel.track('Public - QR scanned', { AccountId: accountId });
};

export const trackUserPosition = (lat, lng, accuracy) => {
  mixpanel.track('Public - User position', { Lat: lat, Lng: lng, Accuracy: accuracy });
};

export const trackProjectsInRadius = (projectIDs, radius) => {
  mixpanel.track('Public - Projects in radius', { ProjectIDs: projectIDs, Radius: radius });
};

export const trackProjectViewed = (projectId) => {
  mixpanel.track('Public - Project viewed', { ProjectId: projectId });
};

export const trackProjectViewedFromQR = (projectId) => {
  mixpanel.track('Public - Project viewed from QR', { ProjectId: projectId });
};

export const trackRemoveBackground = (uploadType) => {
  mixpanel.track('Remove background', { ImageUploadType: uploadType });
};

export const trackPageView = (customPath) => {
  ReactGA.send({ hitType: 'pageview', page: customPath });
};

export const trackCollageShowMe = (projectId) => {
  mixpanel.track('Collage - Show me', { ProjectId: projectId });
};

export const trackCollageGenerate = (projectId, userCreatedOnCampaign) => {
  mixpanel.track(
    'Collage - Generate',
    { ProjectId: projectId, UserCreatedOnCampaign: userCreatedOnCampaign },
  );
};

export const trackCollageReGenerate = (projectId) => {
  mixpanel.track('Collage - Re-generate', { ProjectId: projectId });
};

export const trackCollageKeep = (projectId) => {
  mixpanel.track('Collage - Keep', { ProjectId: projectId });
};

export const trackCollageTriggerKeep = (projectId) => {
  mixpanel.track('Collage - Automatically keep', { ProjectId: projectId });
};

export const trackFullscreenViewOfSquadEvent = (origin) => {
  mixpanel.track('Fullscreen view of squad event media', { Origin: origin });
};

export const trackFocusViewOfSquadEvent = () => {
  mixpanel.track('Focused view of squad event media');
};
