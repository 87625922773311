import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT,
  EDITABLE_TITLE_FRAME_TYPE,
  TOUCH_LONG_PRESS,
} from 'src/constants/general';
import { setEditableFrameTitle } from 'src/actions/canvasActions';
import EditIcon from 'src/assets/icons/edit.svg';
import styles from './CanvasRightClickOptionsFrameCard.module.scss';

const CanvasRightClickOptionsFrameCard = () => {
  const dispatch = useDispatch();

  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  const renameFrame = useCallback(() => {
    if (rightClickOptions.action === TOUCH_LONG_PRESS) {
      dispatch(setEditableFrameTitle({
        frame: rightClickOptions.frame,
        type: EDITABLE_TITLE_FRAME_TYPE.MODAL,
      }));
    } else {
      dispatch(setEditableFrameTitle({
        frame: rightClickOptions.frame,
        type: EDITABLE_TITLE_FRAME_TYPE.FRAME_CARD_INPUT,
      }));
    }
  }, [dispatch, rightClickOptions]);

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: rightClickOptions.y,
        left: rightClickOptions.x,
        height: `${CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT}rem`,
        width: `${CANVAS_RIGHT_CLICK_OPTIONS_WIDTH}rem`,
      }}
    >
      <button
        className={styles.option}
        onClick={renameFrame}
      >
        <img src={EditIcon} alt="Rename" />
        Rename
      </button>
    </div>,
    document.getElementById('root'),
  );
};

export { CanvasRightClickOptionsFrameCard };
