import React from 'react';
import { arrayOf, string, shape, func } from 'prop-types';
import cn from 'classnames';

import { CollapsibleOptions } from 'src/common/collapsible-options';
import { HamburgerMenu } from 'src/common/hamburger-menu';
import { useSession } from 'src/hooks';
import ArrowUp from 'src/assets/icons/arrow-up-white.svg';
import styles from './SmallScreenMenu.module.scss';

const SmallScreenMenu = ({ options }) => {
  const { account } = useSession();

  return (
    <HamburgerMenu>
      {options.map(({ action, text, href, group }, index) => {
        if (group) {
          const filteredList = group.filter(g => g.text !== account.brandName);
          if (filteredList?.length < 1) {
            return null;
          }
          return (
            <CollapsibleOptions
              options={filteredList}
              closeOnClickOption
              iconOpened={ArrowUp}
              className={styles.collapsibleContainer}
              buttonClassName={styles.collapsibleButton}
              optionsClassName={styles.collapsibleOptions}
              optionClassName={styles.collapsibleOption}
            >
              {account.brandName}
            </CollapsibleOptions>
          );
        }
        if (action) {
          return (
            <button
              key={text}
              onClick={action}
              className={cn(styles.option, { [styles.lastOption]: index === options.length - 1 })}
            >
              {text}
            </button>
          );
        } if (href) {
          return (
            <a
              key={text}
              href={href}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.linkOption}
            >
              {text}
            </a>
          );
        }
        return null;
      })}
    </HamburgerMenu>
  );
};

SmallScreenMenu.propTypes = {
  options: arrayOf(shape({
    text: string,
    action: func,
    href: string,
  })).isRequired,
};

export { SmallScreenMenu };
