/* eslint-disable default-case */
import Konva from 'konva';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  CANVAS_DRAWING_CONTENT,
  CANVAS_DRAWING_LAYER,
  CANVAS_HELPERS_PREFIXES,
  TRANSFORMER_ID,
} from 'src/constants/general';
import { useProjectSelector } from 'src/hooks';
import { getTransformerAnchorsByType } from 'src/utils/canvasHelpers';
import {
  ALIGNMENT_GUIDE_NAME,
  getObjectSnappingEdges,
  getObjectSnapsAllowed,
  getGuides,
  START,
  CENTER,
  END,
} from 'src/utils/alignmentGuidesHelpers';

const useAlignmentGuides = (refDrawingLayer) => {
  const { layout, size, selectedElements } = useProjectSelector();

  const { scale, stageOffset, draggableOffset } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
    draggableOffset: canvas.draggableOffset,
  }));

  const multipleElementsSelected = Object.keys(selectedElements).length > 1;

  // where can we snap our objects?
  const getLineGuideStops = useCallback((skipShapes) => {
    const vertical = [];
    const horizontal = [];

    if (!refDrawingLayer.current) {
      return { vertical, horizontal };
    }

    // we snap to the begining of the canvas
    vertical.push(stageOffset.x * scale + draggableOffset.x * scale);
    horizontal.push(stageOffset.y * scale + draggableOffset.y * scale);

    // we snap to the center vertical of each frame of the canvas
    for (let index = 1; index <= size; index++) {
      const verticalCenter = (layout.width * scale) / 2;
      const prevFrames = layout.width * (index - 1) * scale;
      const offsets = stageOffset.x * scale + draggableOffset.x * scale;
      vertical.push(verticalCenter + prevFrames + offsets);
    }
    // we snap to the center horizontal
    const horizontalCenter = (layout.height * scale) / 2;
    const offsets = stageOffset.y * scale + draggableOffset.y * scale;
    horizontal.push(horizontalCenter + offsets);

    // we snap to the end of the canvas
    vertical.push(layout.width * size * scale + stageOffset.x * scale + draggableOffset.x * scale);
    horizontal.push(layout.height * scale + stageOffset.y * scale + draggableOffset.y * scale);

    const elementNodes = [];
    refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`)
      .children.forEach((elementNode) => {
        if (elementNode.attrs.id &&
          !CANVAS_HELPERS_PREFIXES.some(prefix => elementNode.attrs.id.startsWith(prefix))) {
          elementNodes.push(elementNode);
        }
      });

    // we snap over edges and center of each object on the canvas
    elementNodes.forEach((guideItem) => {
      if (skipShapes.find(skip => skip.attrs.id === guideItem.attrs.id)) {
        return;
      }
      const box = guideItem.getClientRect();
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }, [
    draggableOffset,
    layout.height,
    layout.width,
    refDrawingLayer,
    scale,
    size,
    stageOffset,
  ]);

  const drawGuides = useCallback((guides) => {
    if (!refDrawingLayer?.current) {
      return;
    }
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 2,
          name: ALIGNMENT_GUIDE_NAME,
          dash: [4, 6],
          orientation: 'H',
          snap: lg.snap,
        });
        refDrawingLayer.current.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
        refDrawingLayer.current.batchDraw();
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 2,
          name: ALIGNMENT_GUIDE_NAME,
          dash: [4, 6],
          orientation: 'V',
          snap: lg.snap,
        });
        refDrawingLayer.current.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
        refDrawingLayer.current.batchDraw();
      }
    });
  }, [refDrawingLayer]);

  const onDragMoveElements = useCallback((e) => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const singleSelectedElement = e.target.attrs.id !== TRANSFORMER_ID &&
      e.target.attrs.id !== CANVAS_DRAWING_LAYER && !multipleElementsSelected;
    const multipleSelectedTransformer = e.target.attrs.id === TRANSFORMER_ID &&
      multipleElementsSelected;
    if (!multipleSelectedTransformer && !singleSelectedElement) {
      return;
    }
    let transformer = e.target;
    if (singleSelectedElement) {
      const transformers = refDrawingLayer.current.getChildren(
        node => node.id() === TRANSFORMER_ID,
      );
      [transformer] = transformers;
    }
    if (!transformer) {
      return;
    }

    // clear all previous lines on the screen
    const prevGuides = refDrawingLayer.current.find(`.${ALIGNMENT_GUIDE_NAME}`);
    prevGuides.forEach(guide => {
      guide.destroy();
    });

    // find possible snapping lines
    const lineGuideStops = getLineGuideStops(transformer.nodes());
    // find snapping points of current object
    const itemBounds = getObjectSnappingEdges(transformer);

    // now find where can we snap current object
    const guides = getGuides(lineGuideStops, itemBounds);

    // do nothing if no snapping
    if (!guides.length) {
      return;
    }

    drawGuides(guides);

    let elements = transformer.nodes().map(node => ({
      absPos: node.getAbsolutePosition(),
      box: node.getClientRect(),
      node,
    }));
    const transformerClientRect = transformer.getClientRect();
    // now force object position
    guides.forEach((lg) => {
      switch (lg.snap) {
        case START: {
          switch (lg.orientation) {
            case 'V': {
              const diff = lg.lineGuide - transformerClientRect.x;
              elements = elements.map(element => {
                element.absPos.x += diff;
                return element;
              });
              break;
            }
            case 'H': {
              const diff = lg.lineGuide - transformerClientRect.y;
              elements = elements.map(element => {
                element.absPos.y += diff;
                return element;
              });
              break;
            }
          }
          break;
        }
        case CENTER: {
          switch (lg.orientation) {
            case 'V': {
              const diff = lg.lineGuide -
                (transformerClientRect.x + transformerClientRect.width / 2);
              elements = elements.map(element => {
                element.absPos.x += diff;
                return element;
              });
              break;
            }
            case 'H': {
              const diff = lg.lineGuide -
                (transformerClientRect.y + transformerClientRect.height / 2);
              elements = elements.map(element => {
                element.absPos.y += diff;
                return element;
              });
              break;
            }
          }
          break;
        }
        case END: {
          switch (lg.orientation) {
            case 'V': {
              const diff = lg.lineGuide - (transformerClientRect.x + transformerClientRect.width);
              elements = elements.map(element => {
                element.absPos.x += diff;
                return element;
              });
              break;
            }
            case 'H': {
              const diff = lg.lineGuide - (transformerClientRect.y + transformerClientRect.height);
              elements = elements.map(element => {
                element.absPos.y += diff;
                return element;
              });
              break;
            }
          }
          break;
        }
      }
    });
    elements.forEach(element => {
      element.node.absolutePosition(element.absPos);
    });
  }, [drawGuides, getLineGuideStops, multipleElementsSelected, refDrawingLayer]);

  const onResizeElement = useCallback((e) => {
    if (!refDrawingLayer?.current || !e.target.attrs.id ||
      e.target.attrs.rotation !== 0 || multipleElementsSelected) {
      return;
    }
    // clear all previous lines on the screen
    const prevGuides = refDrawingLayer.current.find(`.${ALIGNMENT_GUIDE_NAME}`);
    prevGuides.forEach(guide => {
      guide.destroy();
    });

    // find possible snapping lines
    const lineGuideStops = getLineGuideStops([e.target]);

    // find snapping points of current object
    const transformers = refDrawingLayer.current
      .getChildren(node => node.id() === TRANSFORMER_ID);
    const activeAnchor = transformers[0].getActiveAnchor();
    const allowedSnappingEdges = getObjectSnapsAllowed(activeAnchor);
    const itemBounds = getObjectSnappingEdges(e.target, allowedSnappingEdges);

    // now find where can we snap current object
    const guides = getGuides(lineGuideStops, itemBounds);

    // do nothing of no snapping
    if (!guides.length) {
      return;
    }

    drawGuides(guides);

    // the force object position is in the boundBoxFunc of the Transformer
  }, [drawGuides, getLineGuideStops, refDrawingLayer, multipleElementsSelected]);

  const hideTransformerAnchors = () => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const transformers = refDrawingLayer.current.getChildren(node => node.id() === TRANSFORMER_ID);
    const transformer = transformers[0];
    transformer.enabledAnchors([]);
    transformer.rotateEnabled(false);
  };

  const showTransformerAnchors = () => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const transformers = refDrawingLayer.current.getChildren(node => node.id() === TRANSFORMER_ID);
    const transformer = transformers[0];
    let props;
    if (transformer.nodes().length === 1) {
      const element = transformer.nodes()[0];
      const attrs = {
        ...element.attrs,
        type: element.attrs.elementType,
        uuid: element.attrs.id,
      };
      props = getTransformerAnchorsByType(attrs);
    } else {
      props = getTransformerAnchorsByType({});
    }
    transformer.enabledAnchors(props.enabledAnchors);
    transformer.rotateEnabled(props.rotateEnabled);
  };

  return {
    onDragMoveElements,
    onResizeElement,
    hideTransformerAnchors,
    showTransformerAnchors,
  };
};

export { useAlignmentGuides };
