import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from 'src/assets/icons/logo.svg';
import { Button } from 'src/common/button';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './NavbarSignin.module.scss';

const NavbarSignin = () => {
  const history = useHistory();

  const goToLogin = useCallback(() => {
    history.push(routesPaths.signin);
  }, [history]);

  const goToSignup = useCallback(() => {
    history.push(routesPaths.signup);
  }, [history]);

  return (
    <div className={styles.container}>
      <a
        className={styles.logoNav}
        href="https://www.stagger.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Logo} alt="Stagger logo" className={styles.logo} />
      </a>
      <div className={styles.buttons}>
        <Button onClick={goToLogin} secondary className={styles.button}>
          Log in
        </Button>
        <Button onClick={goToSignup} alternativeCTA className={styles.button}>
          Sign up
        </Button>
      </div>
    </div>
  );
};

export { NavbarSignin };
