import createAction from './createAction';

const SET_IMAGE_SOURCE_IN_MEMORY = 'SET_IMAGE_SOURCE_IN_MEMORY';

const SET_FONT_IN_MEMORY = 'SET_FONT_IN_MEMORY';

const ADD_COLOR_TO_MEMORY = 'ADD_COLOR_TO_MEMORY';

export const setImageSourceInMemory = createAction(SET_IMAGE_SOURCE_IN_MEMORY);

export const setFontInMemory = createAction(SET_FONT_IN_MEMORY);

export const addColorToMemory = createAction(ADD_COLOR_TO_MEMORY);
