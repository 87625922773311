import React, { useRef, memo, useEffect, useMemo } from 'react';
import { Group, Image as ImageKonva, Rect } from 'react-konva';
import useImage from 'use-image';
import { string, number, bool, func, object, shape } from 'prop-types';
import { connect } from 'react-redux';
import 'gifler';

import { LOCAL_UPLOAD, UNSPLASH_UPLOAD } from 'src/constants/uploadFile';
import { IMAGE_ELEMENT } from 'src/constants/canvasElements';
import {
  setUnsplashImageDimensions,
} from 'src/actions/projectActions';
import {
  setEditableImage,
} from 'src/actions/canvasActions';
import { getImageDimensions, isGif } from 'src/utils/helpers';
import {
  getPosDragBoundFunc,
} from 'src/utils/canvasHelpers';
import NotFoundImage from 'src/assets/images/missing-image.svg';
import {
  useMoveTransformElement,
  useKeyboardListener,
  useOnClickElement,
  useDraggableElement,
  useDecreaseCanvasSize,
  useElementColor,
} from 'src/hooks';
import { PROCESSING_OVERLAY_ID } from 'src/constants/general';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { imageDragBound } from 'src/utils/imageCropperHelper';
import { optimizeCloudinaryCanvasImage } from 'src/utils/cloudinaryHelpers';
import { CanvasProcessingElement } from '../../canvas-helper-elements';

const FAILED_STATUS = 'failed';

const CanvasImageComponent = memo(({
  src,
  x,
  y,
  uuid,
  scaleX,
  scaleY,
  borderColor: { hex: borderHex } = {},
  strokeWidth = 0,
  rotation,
  width,
  height,
  unlocked,
  crop,
  selectedElements,
  isInGroup,
  setEditableImageDispatch,
  editableImage,
  scale,
  uploadType,
  setUnsplashImageDimensionsDispatch,
  refCropper,
  selectedRef,
  canvasAttrs,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  shadowEnabled = false,
  shadowOffsetX = 0,
  shadowOffsetY = 0,
  shadowBlur = 0,
  shadowColor = { alpha: 0 },
  opacity = 100,
  pinchZoomGesture,
  saved,
}) => {
  const refGroup = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const refBorder = useRef();

  const selectedElementId = selectedElements[uuid];

  const imageIsGif = isGif(src);

  const imageSrc = uploadType === LOCAL_UPLOAD && !imageIsGif ?
    optimizeCloudinaryCanvasImage(src) :
    src;

  const [image, status] = useImage(imageSrc, 'Anonymous');
  const [fallbackImage] = useImage(NotFoundImage);

  const {
    moveElementRequest,
    transformElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refGroup, selectedRef);

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const singleElementSelected = Object.keys(selectedElements).length === 1;

  const onDragStart = (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && selectElementRequest(e);
      hideTransformerAnchors();
    }
  };

  const onDragEnd = (e) => {
    if (!isInGroup && e.evt) {
      showTransformerAnchors();
      onDragEndScroll();
      moveElementRequest(e);
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const onDoubleClick = () => {
    if (unlocked && !isInGroup && singleElementSelected) {
      setEditableImageDispatch({ uuid });
    }
  };

  useEffect(() => {
    const updateDimensions = (realWidth, realHeight) => {
      if (uploadType === UNSPLASH_UPLOAD && (realWidth !== width || realHeight !== height)) {
        const newAttrs = {
          uuid,
          scaleX: (width * scaleX) / realWidth,
          scaleY: (height * scaleY) / realHeight,
          width: realWidth,
          height: realHeight,
        };
        setUnsplashImageDimensionsDispatch(newAttrs);
      }
    };

    getImageDimensions(src, updateDimensions);
  }, [height, scaleX, scaleY, setUnsplashImageDimensionsDispatch, src, uploadType, uuid, width]);

  const dragBoundFunc = (pos) => {
    if (editableImage === uuid && refCropper?.current) {
      const { current: { attrs } } = refCropper;
      const imageDimensions = {
        width: width * scaleX,
        height: height * scaleY,
      };
      return imageDragBound(pos, attrs, imageDimensions, scale, canvasAttrs);
    }
    return getPosDragBoundFunc(
      refPositions.current,
      pos,
      1 / scale,
      refGroup.current?.absolutePosition(),
      shiftShortcutPressed,
    );
  };

  const isImage = true;
  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    selectElementRequest,
    isImage,
  );

  const { draggableElement } = useDraggableElement(isInGroup);

  const { borderColor, dropShadow } = useElementColor(
    selectedElementId === uuid,
    eyeDropper,
    IMAGE_ELEMENT,
    { border: borderHex, shadow: shadowColor },
  );

  const imageWithAnimation = useMemo(() => {
    if (imageIsGif) {
      return document.createElement('canvas');
    }
    return image;
  }, [image, imageIsGif]);

  useEffect(() => {
    if (imageIsGif) {
      // save animation instance to stop it on unmount
      let anim;
      window.gifler(src).get(a => {
        anim = a;
        anim.animateInCanvas(imageWithAnimation);
        anim.onDrawFrame = (ctx, frame) => {
          ctx.drawImage(frame.buffer, frame.x, frame.y);
          if (refGroup?.current) {
            refGroup.current.getLayer().draw();
          }
        };
      });
      return () => anim?.stop();
    }
    return () => {};
  }, [imageWithAnimation, imageIsGif, src]);

  useDecreaseCanvasSize(refGroup, isInGroup);

  const onClickImage = (e) => {
    if (e.target.attrs.elementType === IMAGE_ELEMENT) {
      onClick(e);
    } else {
      const target = { ...e.target.parent };
      if (!target.attrs.id.includes(PROCESSING_OVERLAY_ID)) {
        onClick({ ...e, target: { ...e.target.parent } });
      }
    }
  };

  const onTransformEnd = (e) => {
    if (!isInGroup) {
      transformElementRequest(e);
    }
  };

  const transformImage = (e) => {
    if (!refGroup?.current) {
      return;
    }
    if (refBorder?.current) {
      refBorder.current.setAttrs({
        width: width * refGroup.current.attrs.scaleX + strokeWidth,
        scaleX: 1 / refGroup.current.attrs.scaleX,
        height: height * refGroup.current.attrs.scaleY + strokeWidth,
        scaleY: 1 / refGroup.current.attrs.scaleY,
      });
    }
    onTransformElement(e);
  };

  const calculatedWidth = editableImage !== uuid ? width + strokeWidth : width;
  const calculatedHeight = editableImage !== uuid ? height + strokeWidth : height;

  return (
    <Group
      id={uuid}
      x={x}
      y={y}
      scaleX={scaleX}
      scaleY={scaleY}
      rotation={rotation}
      ref={refGroup}
      draggable={draggableElement}
      onClick={onClickImage}
      onTap={onClickImage}
      onDblClick={onDoubleClick}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onTransformEnd={onTransformEnd}
      onTransform={transformImage}
      opacity={opacity / 100}
      unlocked={unlocked}
      listening={!pinchZoomGesture && !eyeDropper.active}
      elementType={IMAGE_ELEMENT}
      uploadType={uploadType}
      width={calculatedWidth}
      height={calculatedHeight}
      offsetX={width / 2}
      offsetY={height / 2}
      dragBoundFunc={dragBoundFunc}
    >
      {editableImage !== uuid && strokeWidth > 0 && (
        <Rect
          ref={refBorder}
          scaleX={1 / scaleX}
          scaleY={1 / scaleY}
          offsetX={strokeWidth / 2}
          offsetY={strokeWidth / 2}
          width={width * scaleX + strokeWidth}
          height={height * scaleY + strokeWidth}
          fill={borderColor}
          shadowOpacity={dropShadow.alpha / 100}
          shadowColor={dropShadow.hex}
          shadowBlur={shadowBlur * Math.min(scaleX, scaleY)}
          shadowOffset={{ x: shadowOffsetX * scaleX, y: shadowOffsetY * scaleY }}
          shadowEnabled={shadowEnabled && strokeWidth}
        />
      )}
      <ImageKonva
        image={status === FAILED_STATUS ? fallbackImage : imageWithAnimation}
        width={width}
        height={height}
        crop={crop || { x: 0, y: 0, width: undefined, height: undefined }}
        src={imageSrc}
        shadowOpacity={dropShadow.alpha / 100}
        shadowColor={dropShadow.hex}
        shadowBlur={shadowBlur}
        shadowOffset={{ x: shadowOffsetX, y: shadowOffsetY }}
        shadowEnabled={shadowEnabled && !strokeWidth}
      />
      {!saved && (
        <CanvasProcessingElement
          element={{
            width: width * scaleX + strokeWidth,
            height: height * scaleY + strokeWidth,
            offsetX: strokeWidth / 2,
            offsetY: strokeWidth / 2,
            scaleX: 1 / scaleX,
            scaleY: 1 / scaleY,
          }}
        />
      )}
    </Group>
  );
});

CanvasImageComponent.propTypes = {
  src: string.isRequired,
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  scaleX: number.isRequired,
  scaleY: number.isRequired,
  strokeWidth: number,
  borderColor: shape({
    hex: string,
    alpha: number,
  }),
  rotation: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  unlocked: bool.isRequired,
  selectedElements: object,
  isInGroup: bool,
  setEditableImageDispatch: func.isRequired,
  editableImage: string,
  crop: object,
  scale: number.isRequired,
  uploadType: string.isRequired,
  setUnsplashImageDimensionsDispatch: func.isRequired,
  refCropper: object,
  selectedRef: object,
  canvasAttrs: shape({
    x: number,
    y: number,
  }),
  eyeDropper: shape({ active: bool }),
  onTransformElement: func,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  shadowOffsetX: number,
  shadowOffsetY: number,
  shadowBlur: number,
  shadowColor: shape({
    hex: string,
    alpha: number,
  }),
  shadowEnabled: bool,
  opacity: number,
  pinchZoomGesture: bool,
  saved: bool.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElements: store.project.present.selectedElements,
  scale: store.canvas.scale,
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const mapDispatchToProps = dispatch => ({
  setEditableImageDispatch: v => dispatch(setEditableImage(v)),
  setUnsplashImageDimensionsDispatch: v => dispatch(setUnsplashImageDimensions(v)),
});

const CanvasImage = connect(mapStateToProps, mapDispatchToProps)(CanvasImageComponent);

export { CanvasImage };
