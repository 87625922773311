import { useCallback, useEffect, useRef, useState } from 'react';

const useTooltip = (followMouse = false) => {
  const ref = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const [topLeftPosition, setTopLeftPosition] = useState({ x: 0, y: 0 });

  const getTooltipPosition = ({ clientX, clientY }) => {
    setTopLeftPosition({ y: clientY, x: clientX });
  };

  const mouseEnter = useCallback((e) => {
    setShowTooltip(true);
    if (followMouse) {
      getTooltipPosition(e);
    }
  }, [followMouse]);

  const mouseLeave = () => setShowTooltip(false);

  const onClick = () => setShowTooltip(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', mouseEnter);
      node.addEventListener('mouseleave', mouseLeave);
      node.addEventListener('click', onClick);
      if (followMouse) {
        node.addEventListener('mousemove', getTooltipPosition);
      }
      return () => {
        node.removeEventListener('mouseenter', mouseEnter);
        node.removeEventListener('mouseleave', mouseLeave);
        node.removeEventListener('click', onClick);
        if (followMouse) {
          node.removeEventListener('mousemove', getTooltipPosition);
        }
      };
    }
  }, [ref, followMouse, mouseEnter]);

  return { ref, showTooltip, setShowTooltip, topLeftPosition };
};

export { useTooltip };
