import React, { useEffect, useRef, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams, useHistory } from 'react-router-dom';
import { ActionCreators } from 'redux-undo';
import { parse } from 'query-string';
import { toast } from 'react-toastify';

import { toastSuccessConfig } from 'src/constants/toastConfig';
import { reset } from 'src/actions/statusActions';
import { getTourProductName } from 'src/pages/project/bottom-options/product-tour/tourHelper';
import {
  useStatus,
  useAskBeforeUnload,
  useProjectFonts,
  useSession,
  useProjectSelector,
  useCanvasFrameActions,
} from 'src/hooks';
import {
  BRAND_ASSETS_STATUS,
  CANVAS_DRAWING_CONTENT,
  TOAST_ID_PRINT_ORDER_SUCCESS,
  USER_PERSONA,
  ENABLE_DESIGN_TIPS,
} from 'src/constants/general';
import { ERROR, SUCCESS, LOADING, NOT_STARTED } from 'src/constants/status';
import {
  TEMPLATES_CONTROL,
  SEE_ALL_TEMPLATES_CONTROL,
} from 'src/constants/canvasControls';
import { Spinner } from 'src/common/spinner';
import {
  EditTemplateDetails,
} from 'src/pages/project/controls-options/templates-control/edit-template-details';
import { ScrapperAlert } from 'src/common/scrapper-alert';
import {
  getProject,
  GET_PROJECT,
} from 'src/actions/projectActions';
import { showGeneratedCollage, triggerKeepGeneratedCollage } from 'src/actions/collageActions';
import {
  getUserWithBrandLibrary,
  GET_USER_WITH_BRAND_LIBRARY,
} from 'src/actions/brandLibraryActions';
import {
  getRules,
  GET_RULES,
  updateProjectFacts,
  loadRulesByType,
  LOAD_RULES_BY_TYPE,
  TRIGGER_EVENT,
} from 'src/actions/rulesEngineActions';
import {
  PreviewTemplate,
} from 'src/pages/project/controls-options/templates-control/preview-template';
import { UserWebsiteModal } from 'src/common/user-website-modal';
import { Toolbar } from './toolbar';
import { ControlsOptions } from './controls-options';
import { Canvas } from './canvas';
import { BottomOptions } from './bottom-options';
import { DesignTip } from './design-tip';
import { BottomMenu } from './bottom-menu';
import { Controls } from './controls';
import { ElementControl } from './controls-options/element-control';
import { WelcomeModal } from './welcome-modal';
import styles from './Project.module.scss';

const Project = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const refDrawingLayer = useRef();

  const { id: projectId } = useParams();

  useEffect(() => {
    dispatch(ActionCreators.clearHistory());
    dispatch(getProject(projectId));

    return () => {
      dispatch(reset(GET_PROJECT));
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    const { location: { search, pathname } } = history;
    if (search) {
      const { showToast } = parse(search, { parseBooleans: true });
      if (showToast === TOAST_ID_PRINT_ORDER_SUCCESS) {
        toast.success('We got your order, more details soon! 📪', toastSuccessConfig);
        history.replace(pathname);
      }
    }
  }, [history]);

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = 'auto';
    };
  }, []);

  const { status: statusGetProject } = useStatus(GET_PROJECT);
  const { status: statusGetBrandLibrary } = useStatus(GET_USER_WITH_BRAND_LIBRARY);
  const { status: statusLoadRulesByType = NOT_STARTED } = useStatus(LOAD_RULES_BY_TYPE);
  const { status: statusGetRules } = useStatus(GET_RULES);

  const { user, accountId, account } = useSession();
  const { brandAssetStatus } = account || {};
  const project = useProjectSelector();
  const { type } = project;

  useEffect(() => {
    if (statusGetProject === SUCCESS) {
      dispatch(getUserWithBrandLibrary(accountId));
    }

    return () => {
      if (statusGetProject === SUCCESS) {
        dispatch(reset(GET_USER_WITH_BRAND_LIBRARY));
      }
    };
  }, [accountId, dispatch, statusGetProject]);

  const {
    controlOpen,
    selectedTemplatePreview,
    changesSaved,
    isProjectFactsDirty,
    readyToShowDesignTip,
    generatedCollageToShow,
    isGeneratedCollageVisible,
  } = useSelector(({ canvas, templates, projectState, rulesEngine, collage }) => ({
    controlOpen: canvas.controlOpen,
    selectedTemplatePreview: templates.selectedTemplatePreview,
    changesSaved: projectState.changesSaved,
    isProjectFactsDirty: rulesEngine.projectFacts.dirty,
    readyToShowDesignTip: rulesEngine.readyToShowDesignTip,
    generatedCollageToShow: collage.generatedCollageToShow,
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
  }));

  const saveCollageBeforeUnload = () => {
    if (isGeneratedCollageVisible) {
      dispatch(triggerKeepGeneratedCollage(project.id));
    }
  };

  useAskBeforeUnload(!changesSaved, saveCollageBeforeUnload);

  const { fontStatus } = useProjectFonts();

  const tourName = useMemo(() => getTourProductName(type), [type]);

  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);

  useLayoutEffect(() => {
    if (statusGetBrandLibrary === SUCCESS && user && !user.settings[tourName]) {
      setIsOpenWelcomeModal(true);
    }
  }, [dispatch, statusGetBrandLibrary, tourName, user]);

  const [visibleBottomMenu, setVisibleBottomMenu] = useState(false);

  const error = statusGetProject === ERROR || statusGetBrandLibrary === ERROR;

  useEffect(() => {
    // This is to draw regenerated collages
    if (generatedCollageToShow && isGeneratedCollageVisible) {
      dispatch(showGeneratedCollage(Number(projectId), generatedCollageToShow, false));
    }
  }, [dispatch, generatedCollageToShow, isGeneratedCollageVisible, projectId]);

  const renderControl = () => (
    <>
      {(controlOpen === TEMPLATES_CONTROL || controlOpen === SEE_ALL_TEMPLATES_CONTROL) &&
        selectedTemplatePreview && <PreviewTemplate />}
      <div className={styles.canvasContainer}>
        <ElementControl />
        {error && (
          <div className={styles.error}>
            Oops! This project couldn&apos;t be loaded, please try again.
          </div>
        )}
        {statusGetProject === SUCCESS && statusGetBrandLibrary === SUCCESS &&
          fontStatus === SUCCESS && (
          <Canvas ref={refDrawingLayer} />
        )}
        {(statusGetProject === LOADING || statusGetBrandLibrary === LOADING ||
          fontStatus === LOADING) && (
          <div className={styles.loading}>
            <Spinner iconClassName={styles.spinner} />
          </div>
        )}
        <BottomOptions />
      </div>
      <EditTemplateDetails />
    </>
  );

  useEffect(() => {
    dispatch(getRules());

    return () => {
      dispatch(reset(LOAD_RULES_BY_TYPE));
      dispatch(reset(TRIGGER_EVENT));
    };
  }, [dispatch]);

  const readyToLoadRules = useMemo(() => (
    statusGetBrandLibrary === SUCCESS &&
    user.settings.showDesignTips &&
    statusLoadRulesByType === NOT_STARTED &&
    statusGetRules === SUCCESS &&
    statusGetProject === SUCCESS
  ), [
    statusGetBrandLibrary,
    statusGetProject,
    statusGetRules,
    statusLoadRulesByType,
    user,
  ]);

  useEffect(() => {
    if (readyToLoadRules) {
      dispatch(loadRulesByType(project.type));
    }
  }, [dispatch, project, readyToLoadRules]);

  useLayoutEffect(() => {
    if (ENABLE_DESIGN_TIPS && isProjectFactsDirty) {
      dispatch(updateProjectFacts({ project }));
    }
  }, [dispatch, project, isProjectFactsDirty]);

  const { moveFrameRequest } = useCanvasFrameActions();

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || source.index === destination.index) {
      return;
    }
    if (source.droppableId === 'bottomMenu') {
      const canvasDrawingContent = refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`);
      const indexes = {
        source: source.index,
        destination: destination.index,
      };
      moveFrameRequest(indexes, canvasDrawingContent);
    }
  };

  const showWebsiteModal = statusGetBrandLibrary === SUCCESS &&
    user.persona === USER_PERSONA.BUSINESS_OWNER &&
    (brandAssetStatus === BRAND_ASSETS_STATUS.ASKING_FOR_A_WEBSITE ||
    brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY ||
    brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.page}>
        {statusGetBrandLibrary === SUCCESS && <ScrapperAlert showAnimation={false} />}
        <Toolbar ref={refDrawingLayer} error={error} />
        <div className={styles.content}>
          <Controls error={error} />
          <ControlsOptions />
          {renderControl()}
        </div>
        <BottomMenu
          visible={visibleBottomMenu}
          setVisible={setVisibleBottomMenu}
          ref={refDrawingLayer}
        />
        {readyToShowDesignTip && <DesignTip />}
        {showWebsiteModal && <UserWebsiteModal />}
        {isOpenWelcomeModal && <WelcomeModal setIsOpenWelcomeModal={setIsOpenWelcomeModal} />}
      </div>
    </DragDropContext>
  );
};

export { Project };
