import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import chunk from 'lodash.chunk';
import { func } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import DeleteIcon from 'src/assets/icons/delete-tool-dark.svg';
import {
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import { Button } from 'src/common/button';
import {
  addAssetsToProject,
  ADD_ASSETS_TO_PROJECT,
  replaceAssetFromRightClick,
  REPLACE_ASSET_FROM_RIGHT_CLICK,
} from 'src/actions/brandLibraryActions';
import { useStageCenteredPosition, useStatus, useWindowSize } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import { LoadingCard } from 'src/common/loading-card';
import { deleteMedia, DELETE_MEDIA } from 'src/actions/canvasActions';
import { MOBILE_THRESHOLD_WIDTH, SMALL_HEIGHT } from 'src/constants/breakpoints';
import { getAssetFilenameFromUrl } from 'src/utils/helpers';
import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { isVideo } from 'src/utils/videoHelpers';
import { UploadedMediaCard } from '../uploaded-media-card';
import { FullscreenButton } from '../fullscreen-button';
import styles from './UploadedMedia.module.scss';

const SCROLLABLE_CONTAINER_ID = 'uploadedScrollableContainer';

const UploadedMedia = ({ saveSourceInMemory }) => {
  const dispatch = useDispatch();

  const position = useStageCenteredPosition();

  const {
    uploadedMedia,
    loadingUploadedMedia,
    replaceableImageRightClick,
  } = useSelector(({ canvas }) => ({
    uploadedMedia: canvas.uploadedMedia,
    loadingUploadedMedia: canvas.loadingUploadedMedia,
    replaceableImageRightClick: canvas.replaceableImageRightClick,
  }));

  const isReplacingImage = !!replaceableImageRightClick;

  const [selected, setSelected] = useState([]);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);

  const onClickAsset = image => {
    if (selected.find(elem => elem.id === image.id)) {
      const filteredList = selected.filter(elem => elem.id !== image.id);
      setSelected(filteredList);
    } else if (isReplacingImage) {
      setSelected([image]);
    } else {
      setSelected([...selected, image]);
    }
  };

  const importAssets = () => {
    dispatch(addAssetsToProject(selected, position.x, position.y));
    setSelected([]);
    saveSourceInMemory();
  };

  const replaceAssets = () => {
    dispatch(replaceAssetFromRightClick(selected[0], replaceableImageRightClick));
    setSelected([]);
    saveSourceInMemory();
  };

  const deleteAssets = () => {
    dispatch(deleteMedia(selected));
    setSelected([]);
  };

  const { status: statusAddToProject } = useStatus(ADD_ASSETS_TO_PROJECT);
  const { status: statusReplace } = useStatus(REPLACE_ASSET_FROM_RIGHT_CLICK);
  const { status: statusDelete } = useStatus(DELETE_MEDIA);

  const { windowSize } = useWindowSize();

  const perPage = useMemo(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH) {
      return 6;
    }
    return windowSize.height > SMALL_HEIGHT ? 12 : 9;
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    const loadingList = Array.from({ length: loadingUploadedMedia },
      (_, i) => ({ loading: true, key: i }));
    const elemList = uploadedMedia.map(media => ({ loading: false, url: media }));
    setPages(chunk([...loadingList, ...elemList], perPage));
  }, [perPage, uploadedMedia, loadingUploadedMedia]);

  const pagesToShow = useMemo(() => {
    let result = [];
    for (let index = 0; index <= page; index++) {
      result = [...result, ...(pages[index] || [])];
    }
    return result;
  }, [page, pages]);

  const nextPage = useCallback(() => {
    setPage(p => p + 1);
  }, []);

  useEffect(() => {
    const totalOfElements = pages?.flat()?.length || 0;
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (pagesToShow.length < totalOfElements &&
        containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [nextPage, pages, pagesToShow]);

  return (
    <div className={styles.container}>
      <div className={styles.results} id={SCROLLABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={pagesToShow.length || 0}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {pagesToShow.map((elem) => {
            if (elem.loading) {
              return (
                <LoadingCard key={elem.key} className={styles.loadingCard} />
              );
            }
            const id = getAssetFilenameFromUrl(elem.url);
            const name = isVideo(elem.url) ? id : getCloudinaryFilenameWithoutTimestamp(id);
            return (
              <UploadedMediaCard
                key={elem.url}
                preview={elem.url}
                id={id}
                name={name}
                addImage={onClickAsset}
                isSelected={!!selected.find(e => e.id === id)}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      {!!pages.length && (
        <div className={styles.bottom}>
          <FullscreenButton
            title="Uploaded media"
            pagesToShow={pagesToShow}
            nextPage={nextPage}
            cardComponent={(elem) => {
              if (elem.loading) {
                return null;
              }
              const id = getAssetFilenameFromUrl(elem.url);
              const name = isVideo(elem) ? id : getCloudinaryFilenameWithoutTimestamp(id);
              return (
                <UploadedMediaCard
                  key={elem.url}
                  preview={elem.url}
                  id={id}
                  name={name}
                  showName={false}
                  addImage={onClickAsset}
                  isSelected={!!selected.find(e => e.id === id)}
                  className={styles.fullscreenImageContainer}
                  imageClassName={styles.fullscreenImage}
                  imageButtonClassName={styles.fullscreenImageButton}
                  optimizeImage={false}
                />
              );
            }}
            selected={selected}
            setSelected={setSelected}
            actionButton={isReplacingImage ? (
              {
                disabled: !selected.length,
                onClick: replaceAssets,
                className: styles.replace,
                loading: statusReplace === LOADING,
                text: 'Replace',
              }
            ) : (
              {
                disabled: !selected.length || statusDelete === LOADING,
                onClick: importAssets,
                className: styles.import,
                loading: statusAddToProject === LOADING,
                text: `Place in canvas ${selected.length ? `(${selected.length})` : ''}`,
              }
            )}
            source={LOCAL_UPLOAD}
          />
          <div className={styles.bottomActions}>
            {isReplacingImage ? (
              <Button
                disabled={!selected.length}
                onClick={replaceAssets}
                className={styles.replace}
                loading={statusReplace === LOADING}
              >
                Replace
              </Button>
            ) : (
              <>
                <Button
                  disabled={!selected.length}
                  onClick={deleteAssets}
                  className={styles.delete}
                  loading={statusDelete === LOADING}
                  secondary
                >
                  <img src={DeleteIcon} alt="Delete" />
                </Button>
                <Button
                  disabled={!selected.length || statusDelete === LOADING}
                  onClick={importAssets}
                  className={styles.import}
                  loading={statusAddToProject === LOADING}
                >
                  Place in canvas {selected.length ? `(${selected.length})` : ''}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

UploadedMedia.propTypes = {
  saveSourceInMemory: func.isRequired,
};

export { UploadedMedia };
