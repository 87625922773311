import { useEffect, useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_ZOOM_FIT,
  CANVAS_SCROLL_ID,
  CANVAS_TITLE_SPACE,
} from 'src/constants/general';
import { calculateAspectRatioFit } from 'src/utils/canvasHelpers';
import {
  saveCanvasSize,
  resetDraggableOffset,
  saveZoom,
  setPinchZoomGesture,
} from 'src/actions/canvasActions';
import { useProjectSelector, usePrevious, useWindowSize, useSession } from 'src/hooks';

const useCanvasSize = (refContainer, refDrawingLayer) => {
  const dispatch = useDispatch();

  const { layout, size } = useProjectSelector();

  const { account } = useSession();
  const { brandAssetStatus } = account || {};

  const { windowSize } = useWindowSize();

  const {
    zoom,
    draggableOffset,
    scale,
    stageOffset,
    pinchZoomGesture,
    controlOpen,
  } = useSelector(({ canvas }) => ({
    zoom: canvas.zoom,
    draggableOffset: canvas.draggableOffset,
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
    pinchZoomGesture: canvas.pinchZoomGesture,
    controlOpen: canvas.controlOpen,
  }));

  const prevZoom = usePrevious(zoom);
  const prevSize = usePrevious(size);

  const adjustForZoom = useCallback((canvasSize, canvasWidth) => {
    const canvasElem = document.getElementById(CANVAS_SCROLL_ID);
    const center = {
      y: (canvasElem.offsetHeight / 2) / scale - draggableOffset.y - stageOffset.y,
      x: (canvasElem.offsetWidth / 2) / scale - draggableOffset.x - stageOffset.x,
    };
    let offsetX = -center.x + (canvasElem.offsetWidth / 2) / canvasSize.scale;
    let offsetY = -center.y + (canvasElem.offsetHeight / 2) / canvasSize.scale;

    offsetX = Math.min(
      Math.max(offsetX, (canvasElem.offsetWidth - canvasWidth) / canvasSize.scale),
      0,
    );
    offsetY = Math.min(
      Math.max(
        offsetY,
        (refContainer.current.offsetHeight - canvasSize.baseCanvasHeight) / canvasSize.scale,
      ),
      0,
    );

    canvasElem.dispatchEvent(new CustomEvent('scroll', {
      detail: {
        offsetX,
        offsetY,
        scale: canvasSize.scale,
        stageOffset: canvasSize.stageOffset,
      },
    }));
  }, [draggableOffset, refContainer, scale, stageOffset]);

  useEffect(() => {
    if (!zoom) {
      dispatch(saveZoom({ zoom: CANVAS_ZOOM_FIT }));
    }
  }, [dispatch, zoom]);

  useLayoutEffect(() => {
    if (!zoom) {
      return;
    }
    const canvasSize = {};
    const canvasElem = document.getElementById(CANVAS_SCROLL_ID);
    const { offsetWidth, offsetHeight } = canvasElem;
    if (zoom === CANVAS_ZOOM_FIT) {
      const { ratio, width, height } = calculateAspectRatioFit(
        layout.width,
        layout.height,
        offsetWidth,
        offsetHeight - CANVAS_TITLE_SPACE,
      );
      canvasSize.scale = ratio;
      canvasSize.baseCanvasWidth = width;
      canvasSize.baseCanvasHeight = height + CANVAS_TITLE_SPACE;
    } else {
      canvasSize.scale = zoom / 100;
      canvasSize.baseCanvasWidth = layout.width * canvasSize.scale;
      canvasSize.baseCanvasHeight = layout.height * canvasSize.scale + CANVAS_TITLE_SPACE;
    }

    const canvasWidth = canvasSize.baseCanvasWidth * size;
    let stageX = ((offsetWidth - canvasWidth) / 2) / canvasSize.scale;
    if (!stageX || stageX < 0 || !Number.isFinite(stageX)) {
      stageX = 0;
    }
    let stageY = ((offsetHeight - canvasSize.baseCanvasHeight) / 2)
      / canvasSize.scale;
    if (!stageY || stageY < 0 || !Number.isFinite(stageY)) {
      stageY = 0;
    }

    // Adds space for title
    stageY += CANVAS_TITLE_SPACE * (1 / canvasSize.scale);

    canvasSize.stageOffset = { x: stageX, y: stageY };

    if (prevSize && prevSize > size) {
      canvasSize.draggableOffset = { x: 0, y: 0 };
    }

    dispatch(saveCanvasSize(canvasSize));

    if (pinchZoomGesture) {
      dispatch(setPinchZoomGesture(false));
      return;
    }

    if (prevZoom && prevZoom !== zoom) {
      adjustForZoom(canvasSize, canvasWidth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    refDrawingLayer,
    windowSize.width,
    layout,
    refContainer,
    zoom,
    size,
    brandAssetStatus,
    controlOpen,
  ]);

  useEffect(() => {
    dispatch(resetDraggableOffset());
  }, [dispatch, layout]);
};

export { useCanvasSize };
