import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { bool, func } from 'prop-types';

import { useSession } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import check from 'src/assets/icons/check-black.svg';
import { createCustomerPortalSession } from 'src/actions/userActions';
import { dismissModal } from 'src/actions/userSettingsActions';
import { UPGRADE_DASHBOARD } from 'src/constants/dismissibleModals';
import styles from './UpgradeModal.module.scss';

const UpgradeModal = ({ showAnyway, setShowAnyway }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user } = useSession();
  const { customerId, membership: { subscription } } = user;

  const [modalOpen, setModalOpen] = useState(user.settings.showDashboardModal);
  const onClose = useCallback(() => setModalOpen(false), []);

  const upgradeRequest = useCallback(() => {
    dispatch(dismissModal(UPGRADE_DASHBOARD));
    if (subscription && customerId) {
      dispatch(createCustomerPortalSession(customerId, pathname));
    } else {
      history.push(routesPaths.choosePlan, { pathname });
    }
  }, [dispatch, subscription, customerId, pathname, history]);

  const dismissRequest = useCallback(() => {
    dispatch(dismissModal(UPGRADE_DASHBOARD));
    setModalOpen(false);
    setShowAnyway(false);
  }, [dispatch, setShowAnyway]);

  return (
    <Modal
      isShowing={showAnyway || modalOpen}
      hide={onClose}
      isEscEnabled={false}
      closeOnClickOutside={false}
      curvedContainer
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.headerFirstLine}>WELCOME TO YOUR DASHBOARD</div>
            <div className={styles.headerTitle}>Upgrade to unlock powerful brand features</div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.secondTitle}>
            Get realtime data on the performance of your Stagger so you
            can adjust your creative to make the most impact
          </div>
          <div className={styles.featureGrid}>
            <div className={cn(styles.row, styles.bottomLine)}>
              <div className={styles.featureAvailable}>
                <img src={check} alt="" />
              </div>
              <div className={styles.featureName}>Embed Codes</div>
              <div className={styles.featureDesc}>
                Track the performance of your Staggers wherever you embed them on the web
              </div>
            </div>
            <div className={cn(styles.row, styles.bottomLine)}>
              <div className={styles.featureAvailable}>
                <img src={check} alt="" />
              </div>
              <div className={styles.featureName}>Instagram Analytics</div>
              <div className={styles.featureDesc}>
                Connect your Instagram feed to see your post analytics and how your Staggers perform
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.featureAvailable}>
                <span className={styles.soon}>Soon</span>
              </div>
              <div className={styles.featureName}>Insights</div>
              <div className={styles.featureDesc}>
                Get actionable insights to help you tell your story
                with the right creative and messaging
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.actionButton}
              onClick={upgradeRequest}
            >
              Upgrade
            </Button>
            <Button
              className={cn(styles.actionButton, styles.dismissButton)}
              secondary
              onClick={dismissRequest}
            >
              Dismiss
            </Button>
          </div>

        </div>

      </div>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  showAnyway: bool,
  setShowAnyway: func,
};

export { UpgradeModal };
