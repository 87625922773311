import React, { useRef, useLayoutEffect, useState, useCallback, useMemo } from 'react';
import { bool } from 'prop-types';

import { useProjectSelector, useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { showPreviews } from 'src/utils/helpers';
import { calculateAspectRatioFit } from 'src/utils/canvasHelpers';
import { Slider } from 'src/common/slider';
import LoadingAnimation from './loading-animation.svg';
import styles from './ExportSlider.module.scss';

const ExportSlider = ({ loading }) => {
  const ref = useRef();

  const [sliderStyles, setSliderStyles] = useState({});

  const { layout, previews, videoUrls } = useProjectSelector();

  const { windowSize } = useWindowSize();
  const isSmallScreen = useMemo(() => windowSize.width <= SMALL_WIDTH, [windowSize.width]);

  const getStyles = useCallback(() => {
    if (ref.current) {
      const { current: { clientWidth, clientHeight } } = ref;
      const { width, height } = calculateAspectRatioFit(
        layout.width, layout.height, clientWidth, clientHeight,
      );
      let widthStyle = width;
      if (previews.length > 1) {
        const arrowsWidth = isSmallScreen ? 42 * 2 : 64 * 2;
        widthStyle += arrowsWidth;
      }
      return { width: widthStyle, height };
    }
    return {};
  }, [layout.height, layout.width, previews.length, isSmallScreen]);

  useLayoutEffect(() => {
    setSliderStyles(getStyles());
  }, [getStyles]);

  if (!previews.length) {
    return (
      <div className={styles.error}>
        Oops! This project has nothing exported yet. Go back and export.
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref}>
      <div style={sliderStyles}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <img src={LoadingAnimation} alt="Loading" className={styles.loadingIcon} />
            <span className={styles.loadingText}>Generating video...</span>
          </div>
        ) :
          <Slider slides={showPreviews(previews, videoUrls)} />}
      </div>
    </div>
  );
};

ExportSlider.propTypes = {
  loading: bool.isRequired,
};

export { ExportSlider };
