import React from 'react';
import { useDispatch } from 'react-redux';

import { InputSearch } from 'src/common/input-search';
import {
  saveSearchTermBrandLibrary,
  SEARCH_IN_BRAND_LIBRARY,
  clearSearchInBrandLibrary,
} from 'src/actions/brandLibraryActions';
import { useStatus } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import { CLEAR_BRAND_LIBRARY_SEARCH_BUTTON_ID, ENABLE_ASSETS_SEARCH } from 'src/constants/general';

const SearchBox = () => {
  const dispatch = useDispatch();

  const { status } = useStatus(SEARCH_IN_BRAND_LIBRARY);

  const clearSearch = () => {
    dispatch(clearSearchInBrandLibrary());
  };

  const search = (searchTerm) => {
    if (!searchTerm) {
      clearSearch();
    } else {
      dispatch(saveSearchTermBrandLibrary({ searchTerm }));
    }
  };

  return ENABLE_ASSETS_SEARCH ? (
    <InputSearch
      searchAction={search}
      clearAllAction={clearSearch}
      isLoading={status === LOADING}
      enableClearAll
      buttonId={CLEAR_BRAND_LIBRARY_SEARCH_BUTTON_ID}
      placeholder="Search"
    />
  ) : null;
};

export { SearchBox };
