import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, string, shape, number, func } from 'prop-types';

import { addColorToMemory } from 'src/actions/projectMemoryActions';
import { ColorPicker } from 'src/common/color-picker';
import { useBrandLibraryColors } from 'src/hooks';
import {
  CanvasEyedropper,
} from 'src/pages/project/canvas/canvas-helper-elements/color-picker-canvas/canvas-eyedropper';
import {
  CanvasColorSwatches,
} from 'src/pages/project/canvas/canvas-helper-elements/color-picker-canvas/canvas-color-swatches';

const ColorPickerCanvas = ({
  eyedropperSource,
  enableEyedropper = true,
  color,
  onColorChange,
  ...props
}) => {
  const dispatch = useDispatch();

  const { brandLibraryColors } = useBrandLibraryColors();

  const { eyeDropper, colors } = useSelector(({ canvas, projectMemory }) => ({
    eyeDropper: canvas.eyeDropper,
    colors: projectMemory.colors,
  }));

  const [hasChangedColor, setHasChangedColor] = useState(false);

  const changeColor = (newColor, event) => {
    onColorChange(newColor, event);
    setHasChangedColor(true);
  };

  const onClose = () => {
    if (hasChangedColor) {
      dispatch(addColorToMemory({ color }));
    }
  };

  return (
    <ColorPicker
      {...props}
      onClose={onClose}
      onColorChange={changeColor}
      color={eyeDropper.active && eyeDropper.source === eyedropperSource ?
        { hex: eyeDropper.color, alpha: 100 } : color}
      addedChildren={(
        <>
          <CanvasColorSwatches
            colors={brandLibraryColors}
            onColorChange={changeColor}
            title="Brand colors"
          />
          <CanvasColorSwatches
            colors={colors}
            onColorChange={changeColor}
            title="Recently used"
          />
          {enableEyedropper && <CanvasEyedropper source={eyedropperSource} />}
        </>
      )}
    />
  );
};

ColorPickerCanvas.propTypes = {
  eyedropperSource: string,
  enableEyedropper: bool,
  color: shape({
    hex: string,
    alpha: number,
  }),
  onColorChange: func.isRequired,
};

export { ColorPickerCanvas };
