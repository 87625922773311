import React, { useState, useEffect, useRef, useCallback } from 'react';
import { string, shape, number, bool, func } from 'prop-types';
import cn from 'classnames';

import { useTooltip } from 'src/hooks';
import { Tooltip } from 'src/common/tooltip';
import { LayoutConfirmationModal } from '../layout-confirmation-modal';
import styles from './LayoutControlOption.module.scss';

const LayoutControlOption = ({
  icon = '',
  iconSelected = '',
  layout,
  text,
  buttonStyles,
  isSelected,
  needsConfirmationModal,
  modalCondition,
  modalTitle,
  modalText,
  onChange,
}) => {
  const node = useRef();

  const [image, setImage] = useState(isSelected ? iconSelected : icon);

  const { ref, showTooltip } = useTooltip();

  useEffect(() => {
    if (!isSelected && icon) {
      setImage(icon);
    }
  }, [icon, isSelected, layout]);

  const [showModal, setShowModal] = useState(false);

  const onClick = useCallback(() => {
    if (needsConfirmationModal && modalCondition) {
      setShowModal(true);
    } else {
      onChange();
    }
  }, [modalCondition, needsConfirmationModal, onChange]);

  return (
    <div className={styles.option} ref={node}>
      <span className={cn(styles.name, { [styles.selected]: isSelected })}>{text}</span>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <button
        onClick={onClick}
        style={buttonStyles}
        className={cn(styles.button, { [styles.selected]: isSelected })}
        onMouseEnter={() => !isSelected && setImage(iconSelected)}
        onMouseLeave={() => !isSelected && setImage(icon)}
        ref={ref}
      >
        {icon && iconSelected && (
          <img
            src={image}
            alt="Layout option"
          />
        )}
        {showTooltip && <Tooltip>{layout.width}x{layout.height}</Tooltip>}
      </button>
      <LayoutConfirmationModal
        title={modalTitle}
        text={modalText}
        isShowing={showModal}
        onClose={() => setShowModal(false)}
        onAccept={onChange}
      />
    </div>
  );
};

LayoutControlOption.propTypes = {
  icon: string,
  iconSelected: string,
  text: string.isRequired,
  layout: shape({
    width: number,
    height: number,
    source: string,
  }).isRequired,
  buttonStyles: shape({
    width: string,
    height: string,
  }).isRequired,
  isSelected: bool.isRequired,
  needsConfirmationModal: bool.isRequired,
  modalCondition: bool,
  modalTitle: string,
  modalText: string,
  onChange: func.isRequired,
};

export { LayoutControlOption };
