import { TEXT_ELEMENT, GROUP_ELEMENT } from 'src/constants/canvasElements';
import {
  UNDO,
  UPDATE_ELEMENT,
  ADD_TEXT_ELEMENT,
  DELETE_ELEMENT,
  CHANGE_SIZE,
} from 'src/actions/projectActions';
import {
  ADD_IMAGE_SCORE_FACT,
  DISCARD_TIP,
} from 'src/actions/rulesEngineActions';

const theActionTypeIsSpecialOrInTheList = (actionType, actionsList) => {
  const specialActions = [UNDO];
  return specialActions.includes(actionType) || actionsList.includes(actionType);
};

export const CanvasCountFactHandler = () => {
  const FACT_NAME = 'canvasCount';
  const canvasCountActionNames = [CHANGE_SIZE];

  return {
    factFieldName: FACT_NAME,
    updateFact: (project) => {
      const updatedFact = {
        [FACT_NAME]: project.size,
      };

      return updatedFact;
    },
    // eslint-disable-next-line no-unused-vars
    discardFact: (facts, _uuid) => (facts),
    isFactAffectedByAction: (action) => theActionTypeIsSpecialOrInTheList(
      action.type,
      canvasCountActionNames,
    ),
  };
};

export const FontsCountFactHandler = () => {
  const FACT_NAME = 'fontsCount';
  const fontsCountActionNames = [UPDATE_ELEMENT, ADD_TEXT_ELEMENT, DELETE_ELEMENT];

  const countFontsInAProject = (project) => {
    const fontsCount = {};

    project.elements.forEach(element => {
      if (element.type === TEXT_ELEMENT) {
        const font = element.fontFamily;
        if (fontsCount[font]) {
          fontsCount[font].push(element.uuid);
        } else {
          fontsCount[font] = [element.uuid];
        }
      }
    });

    Object.values(project.groupsOfElementsById).forEach(groupOfElements => {
      groupOfElements.forEach(element => {
        if (element.type === TEXT_ELEMENT) {
          const font = element.fontFamily;
          if (fontsCount[font]) {
            fontsCount[font].push(element.uuid);
          } else {
            fontsCount[font] = [element.uuid];
          }
        }
      });
    });
    return fontsCount;
  };

  return {
    factFieldName: FACT_NAME,
    updateFact: (project, facts) => {
      const updatedFact = {
        [FACT_NAME]: facts[FACT_NAME],
      };

      if (theActionTypeIsSpecialOrInTheList(facts.dirtyingAction.type, fontsCountActionNames)) {
        const { dirtyElements } = facts;

        if (dirtyElements) {
          for (let i = 0; i < dirtyElements.length; i++) {
            const element = dirtyElements[i];

            if ([TEXT_ELEMENT, GROUP_ELEMENT].includes(element?.type)) {
              updatedFact[FACT_NAME] = countFontsInAProject(project);
              break;
            }
          }
        }
      }

      return updatedFact;
    },
    // eslint-disable-next-line no-unused-vars
    discardFact: (facts, _uuid) => (facts),
    isFactAffectedByAction: (action) => action.payload?.attrs?.fontFamily &&
      theActionTypeIsSpecialOrInTheList(
        action.type,
        fontsCountActionNames,
      ),
  };
};

export const ImagesScoresFactHandler = () => {
  const FACT_NAME = 'imagesScores';
  const imagesScoresActionNames = [ADD_IMAGE_SCORE_FACT, DISCARD_TIP];

  return {
    factFieldName: FACT_NAME,
    updateFact: (_, facts) => {
      const updatedFact = {
        [FACT_NAME]: facts[FACT_NAME],
      };
      return updatedFact;
    },
    discardFact: (facts, uuid) => {
      facts[FACT_NAME] = facts[FACT_NAME].filter(
        imageScore => imageScore.imageId !== uuid,
      );
      return facts;
    },
    isFactAffectedByAction: (action) => theActionTypeIsSpecialOrInTheList(
      action.type,
      imagesScoresActionNames,
    ),
  };
};
