import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import chunk from 'lodash.chunk';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TRIALING } from 'src/constants/subscriptionStatuses';
import { SMALL_HEIGHT, MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { BRAND_LIBRARY_PLANS } from 'src/constants/memberships';
import { reset } from 'src/actions/statusActions';
import { LOADING, SUCCESS } from 'src/constants/status';
import { useStatus, useWindowSize, useSession, useStageCenteredPosition } from 'src/hooks';
import { UpgradeAlert } from 'src/common/upgrade-alert';
import {
  addAssetsToProject,
  ADD_ASSETS_TO_PROJECT,
  replaceAssetFromRightClick,
  REPLACE_ASSET_FROM_RIGHT_CLICK,
} from 'src/actions/brandLibraryActions';
import {
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import { getAssetFilenameFromUrl } from 'src/utils/helpers';
import { Button } from 'src/common/button';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import ArrowUp from 'src/assets/icons/arrow-up.svg';
import { isVideo } from 'src/utils/videoHelpers';
import { BRAND_LIBRARY_UPLOAD } from 'src/constants/uploadFile';
import { FullscreenButton } from '../fullscreen-button';
import { BrandLibraryCard } from '../brand-library-card';
import styles from './BrandLibraryUpload.module.scss';

const SCROLLABLE_CONTAINER_ID = 'blScrollableContainer';
const LOGOS_ID = 1;
const IMAGERY_ID = 2;

const BrandLibraryUpload = ({ saveSourceInMemory }) => {
  const dispatch = useDispatch();
  const { user, account } = useSession();

  const { membership } = user;

  const position = useStageCenteredPosition();

  const {
    disabled,
    assetCategories,
    logoCategories,
    replaceableImageRightClick,
    imageSourceMemory,
  } = useSelector(({ brandLibrary, canvas, projectMemory }) => ({
    disabled: brandLibrary.disabled,
    assetCategories: brandLibrary.assetCategories,
    logoCategories: brandLibrary.logoCategories,
    replaceableImageRightClick: canvas.replaceableImageRightClick,
    imageSourceMemory: projectMemory.imageSource,
  }));

  const isReplacingImage = !!replaceableImageRightClick;

  const [selected, setSelected] = useState([]);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [categoryTabs, setCategoryTabs] = useState([]);

  const [
    librarySource,
    setLibrarySource,
  ] = useState(imageSourceMemory?.librarySource || IMAGERY_ID);
  const [activeCategoryTab, setActiveCategoryTab] = useState(imageSourceMemory?.category);

  const onClickAsset = image => {
    if (selected.find(elem => elem.id === image.id)) {
      const filteredList = selected.filter(elem => elem.id !== image.id);
      setSelected(filteredList);
    } else if (isReplacingImage) {
      setSelected([image]);
    } else {
      setSelected([...selected, image]);
    }
  };

  const importAssets = useCallback(() => {
    if (isReplacingImage) {
      dispatch(replaceAssetFromRightClick(selected[0], replaceableImageRightClick));
    } else {
      dispatch(addAssetsToProject(selected, position.x, position.y));
    }
  }, [dispatch, isReplacingImage, position, replaceableImageRightClick, selected]);

  const { status: statusAddToProject } = useStatus(ADD_ASSETS_TO_PROJECT);
  const { status: statusReplace } = useStatus(REPLACE_ASSET_FROM_RIGHT_CLICK);

  const { windowSize } = useWindowSize();

  const perPage = useMemo(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH) {
      return 6;
    }
    return windowSize.height > SMALL_HEIGHT ? 12 : 9;
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    if (statusAddToProject === SUCCESS || statusReplace === SUCCESS) {
      setSelected([]);
      saveSourceInMemory({ librarySource, category: activeCategoryTab });
      if (statusAddToProject === SUCCESS) {
        dispatch(reset(ADD_ASSETS_TO_PROJECT));
      }
      if (statusReplace === SUCCESS) {
        dispatch(reset(REPLACE_ASSET_FROM_RIGHT_CLICK));
      }
    }
  }, [
    dispatch,
    saveSourceInMemory,
    statusAddToProject,
    statusReplace,
    librarySource,
    activeCategoryTab,
  ]);

  useEffect(() => {
    if (categoryTabs?.length) {
      const category = categoryTabs.find((aCat) => (
        aCat.id === activeCategoryTab
      )) || categoryTabs[0];

      if (activeCategoryTab !== category.id) {
        setActiveCategoryTab(category.id);
      }

      const assets = category.assets || category.logos;
      setPages(chunk(assets.map((asset) => (
        asset.url
      )), perPage));
    }
  }, [activeCategoryTab, categoryTabs, perPage, imageSourceMemory]);

  const changeCategories = useCallback((source) => {
    let categories;
    if (source === LOGOS_ID) {
      categories = logoCategories;
    } else if (source === IMAGERY_ID) {
      categories = assetCategories;
    }
    if (categories.length === 0) {
      setCategoryTabs([]);
      setPages([]);
      setPage(0);
    } else {
      setCategoryTabs(categories);
    }
  }, [assetCategories, logoCategories]);

  useEffect(() => {
    changeCategories(librarySource);
  }, [changeCategories, librarySource]);

  const changeSource = useCallback((source) => {
    setLibrarySource(source);
    changeCategories(source);
    setActiveCategoryTab(-1);
  }, [changeCategories]);

  const sourceOptions = [
    {
      text: 'Logos',
      value: LOGOS_ID,
      action: () => changeSource(LOGOS_ID),
    },
    {
      text: 'Assets',
      value: IMAGERY_ID,
      action: () => changeSource(IMAGERY_ID),
    },
  ];

  const onCategoryClick = (id) => {
    if (id === activeCategoryTab) {
      return;
    }
    setPages([]);
    setPage(0);
    setActiveCategoryTab(id);
  };

  const pagesToShow = useMemo(() => {
    let result = [];
    for (let index = 0; index <= page; index++) {
      result = [...result, ...(pages[index] || [])];
    }
    return result;
  }, [page, pages]);

  const nextPage = useCallback(() => {
    setPage(p => p + 1);
  }, []);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    const totalOfElements = pages?.flat()?.length || 0;
    if (pagesToShow.length < totalOfElements &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [pages, pagesToShow, nextPage]);

  const isTrial = membership?.subscription && membership.subscription.active &&
    membership.subscription.status === TRIALING;

  const brandLibraryIsEmpty = (assetCategories.length + logoCategories.length) === 0;
  const noAssetToShow = categoryTabs.length === 0 &&
    assetCategories.length > 0 && pages.length === 0;
  const noLogoToShow = categoryTabs.length === 0 &&
    logoCategories.length > 0 && pages.length === 0;
  const categoryIsEmpty = categoryTabs.length >= 1 && pages.length === 0;

  return (
    <div className={styles.container}>
      <span className={styles.title}>Brand Library</span>
      <span className={styles.subtitle}>
        Add images from your Stagger Brand Library
      </span>
      {disabled && (
        <UpgradeAlert
          plans={BRAND_LIBRARY_PLANS}
          upgradeChildren={(
            <span>
              Upgrade your account to use your Brand Library assets
            </span>
          )}
          upgradeButtonText={isTrial ?
            'Upgrade now' :
            'Start Your Free Trial'}
          containerClassName={styles.upgrade}
          buttonClassName={styles.upgradeButton}
        />
      )}
      <CollapsibleOptions
        options={sourceOptions}
        className={styles.collapsibleContainer}
        optionsClassName={styles.options}
        buttonClassName={styles.collapsibleButton}
        optionClassName={styles.option}
        iconOpened={ArrowUp}
        closeOnClickOption
      >
        <span className={styles.text}>
          {sourceOptions.find(elem => elem.value === librarySource)?.text}
        </span>
      </CollapsibleOptions>
      {categoryTabs?.length > 1 && (
        <CollapsibleOptions
          options={
            categoryTabs.map(cat => (
              { value: cat.id, text: cat.name, action: () => onCategoryClick(cat.id) }
            ))
          }
          className={styles.collapsibleContainer}
          optionsClassName={styles.options}
          buttonClassName={styles.collapsibleButton}
          optionClassName={styles.option}
          iconOpened={ArrowUp}
          closeOnClickOption
        >
          <span className={styles.text}>
            {categoryTabs.find(elem => elem.id === activeCategoryTab)?.name}
          </span>
        </CollapsibleOptions>
      )}
      {pages.length > 0 ? (
        <div className={styles.results} id={SCROLLABLE_CONTAINER_ID}>
          <InfiniteScroll
            dataLength={pagesToShow.length || 0}
            next={nextPage}
            hasMore
            scrollableTarget={SCROLLABLE_CONTAINER_ID}
            style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
          >
            {pagesToShow.map(asset => {
              const id = getAssetFilenameFromUrl(asset);
              const name = isVideo(asset) ? id : getCloudinaryFilenameWithoutTimestamp(id);
              return (
                <BrandLibraryCard
                  key={asset}
                  preview={asset}
                  id={id}
                  name={name}
                  addImage={onClickAsset}
                  isSelected={!!selected.find(elem => elem.id === id)}
                  isQrImage={asset === account.qrCodeUrl}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.empty}>
          {brandLibraryIsEmpty && (
            <>
              <span className={styles.emptyTitle}>
                Looks like your Brand Library is empty.
              </span>
              <span className={styles.emptyText}>
                Add your brand&apos;s {librarySource === LOGOS_ID ? 'logos' : 'assets'} to
                your library for quick access when creating a project.
              </span>
            </>
          )}
          {(noAssetToShow || noLogoToShow) && (
            <>
              <span className={styles.emptyTitle}>
                Looks like you don&apos;t have
                any {librarySource === LOGOS_ID ? 'logo' : 'asset'} to show.
              </span>
              <span className={styles.emptyText}>
                Add your brand&apos;s {librarySource === LOGOS_ID ? 'logos' : 'assets'} to
                your library for quick access when creating a project.
              </span>
            </>
          )}
          {categoryIsEmpty && (
            <>
              <span className={styles.emptyTitle}>
                Looks like that category is empty.
              </span>
              <span className={styles.emptyText}>
                Add your brand&apos;s assets to your library
                for quick access when creating a project.
              </span>
            </>
          )}
        </div>
      )}
      {!!pages.length && (
        <div className={styles.bottom}>
          <FullscreenButton
            title={categoryTabs.find(elem => elem.id === activeCategoryTab)?.name}
            pagesToShow={pagesToShow.map(p => ({ url: p }))}
            nextPage={nextPage}
            cardComponent={(asset) => {
              const id = getAssetFilenameFromUrl(asset.url);
              const name = isVideo(asset) ? id : getCloudinaryFilenameWithoutTimestamp(id);
              return (
                <BrandLibraryCard
                  key={asset.url}
                  preview={asset.url}
                  id={id}
                  name={name}
                  showName={false}
                  addImage={onClickAsset}
                  isSelected={!!selected.find(elem => elem.id === id)}
                  isQrImage={asset.url === account.qrCodeUrl}
                  className={styles.fullscreenImageContainer}
                  imageClassName={styles.fullscreenImage}
                  imageButtonClassName={styles.fullscreenImageButton}
                  optimizeImage={false}
                />
              );
            }}
            selected={selected}
            setSelected={setSelected}
            selectDisabled={disabled}
            actionButton={{
              disabled: !selected.length,
              onClick: importAssets,
              className: styles.import,
              loading: statusAddToProject === LOADING || statusReplace === LOADING,
              text: isReplacingImage ?
                'Replace' :
                `Place in canvas ${selected.length ? `(${selected.length})` : ''}`,
            }}
            source={BRAND_LIBRARY_UPLOAD}
          />
          <Button
            disabled={!selected.length}
            onClick={importAssets}
            className={styles.import}
            loading={statusAddToProject === LOADING || statusReplace === LOADING}
          >
            {isReplacingImage ?
              'Replace' :
              `Place in canvas ${selected.length ? `(${selected.length})` : ''}`}
          </Button>
        </div>
      )}
    </div>
  );
};

BrandLibraryUpload.propTypes = {
  saveSourceInMemory: func.isRequired,
};

export { BrandLibraryUpload };
