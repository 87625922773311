import React, { useRef, useLayoutEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GET_POST_DATA } from 'src/actions/instagramAnalyticsActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { useStatus } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import { Slider } from 'src/common/slider';
import { showPreviews, getImageDimensions } from 'src/utils/helpers';
import { calculateAspectRatioFit } from 'src/utils/canvasHelpers';
import styles from './PostPreview.module.scss';

const getSliderStyles = (previews, w, h, clientWidth, clientHeight) => {
  const { width, height } = calculateAspectRatioFit(
    w, h, clientWidth, clientHeight,
  );
  let widthStyle = width;
  if (previews.length > 1) {
    const arrowsWidth = 64 * 2;
    widthStyle += arrowsWidth;
  }
  return { width: widthStyle, height };
};

const PostPreview = () => {
  const ref = useRef();

  const { previewUrl, postChildren } = useSelector(({ instagramAnalytics }) => ({
    previewUrl: instagramAnalytics.previewUrl,
    postChildren: instagramAnalytics.selectedPostChildren,
  }));

  const { status } = useStatus(GET_POST_DATA);

  const [sliderStyles, setSliderStyles] = useState({});

  const previews = useMemo(
    () => (postChildren ? postChildren.map((child) => child.mediaUrl) : [previewUrl]),
    [postChildren, previewUrl],
  );

  useLayoutEffect(() => {
    if (ref.current) {
      const { current: { clientWidth, clientHeight } } = ref;
      getImageDimensions(previews[0], (w, h) => {
        setSliderStyles(getSliderStyles(previews, w, h, clientWidth, clientHeight));
      });
    }
  }, [previews]);

  return (
    <div className={styles.container} ref={ref}>
      {status === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {status === SUCCESS && previews && (
        <div className={styles.preview} style={sliderStyles}>
          <Slider
            slides={showPreviews(previews)}
          />
        </div>
      )}
      {status === ERROR && (
        <div className={styles.error}>No preview found</div>
      )}
    </div>
  );
};

export { PostPreview };
