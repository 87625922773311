import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Font } from '../font';
import styles from './FontsDrawer.module.scss';

const FontsDrawer = () => {
  const { fonts } = useSelector(({ brandLibrary }) => ({
    fonts: brandLibrary.fonts,
  }));

  const [editOpen, setEditOpen] = useState();

  return (
    <div className={styles.drawer}>
      {fonts?.map(font => (
        <Font
          {...font}
          key={font.id}
          isEditing={editOpen === font.id}
          setEditOpen={setEditOpen}
        />
      ))}
    </div>
  );
};

export { FontsDrawer };
