import { logout } from 'src/actions/userActions';
import { getUserForDashboardSuccess } from 'src/actions/embedAnalyticsActions';
import {
  setSelectedPost,
  getInstagramPostsSuccess,
  getPostDataSuccess,
  getPostInsightsSuccess,
  isLoggedInToFBSuccess,
  logoutFromFBSuccess,
  isStaggerPostSuccess,
  setIsStaggerPostSuccess,
} from 'src/actions/instagramAnalyticsActions';
import createReducer from './createReducer';

const initialState = {
  disabled: true,
  selectedPost: undefined,
  posts: [],
  likeCount: undefined,
  commentsCount: undefined,
  permalink: undefined,
  postedDate: undefined,
  previewUrl: undefined,
  impressions: undefined,
  reach: undefined,
  igBusinessAccountId: undefined,
  loggedIn: false,
  isStaggerPost: false,
  selectedPostChildren: [],
};

const actionHandlers = {
  [getUserForDashboardSuccess]: (state, { payload: { disabled } }) => {
    state.disabled = disabled;
  },
  [setSelectedPost]: (state, { payload: { post } }) => {
    state.selectedPost = post;
  },
  [getInstagramPostsSuccess]: (state, { payload: { posts } }) => {
    state.posts = posts;
    if (posts.length) {
      [state.selectedPost] = posts;
    }
  },
  [getPostDataSuccess]: (state, { payload: {
    postId,
    permalink,
    likeCount,
    commentsCount,
    time,
    mediaUrl,
    username,
    children,
  } }) => {
    if (state.selectedPost?.id === postId) {
      state.likeCount = likeCount;
      state.commentsCount = commentsCount;
      state.permalink = permalink;
      state.postedDate = time;
      state.previewUrl = mediaUrl;
      state.username = username;
      state.selectedPostChildren = children;
    }
  },
  [getPostInsightsSuccess]: (state, { payload: { impressions, reach, postId } }) => {
    if (state.selectedPost?.id === postId) {
      state.impressions = impressions;
      state.reach = reach;
    }
  },
  [isLoggedInToFBSuccess]: (state, { payload: { loggedIn, igBusinessAccountId } }) => {
    state.igBusinessAccountId = igBusinessAccountId;
    state.loggedIn = loggedIn;
  },
  [logoutFromFBSuccess]: (state) => {
    state.igBusinessAccountId = initialState.igBusinessAccountId;
    state.loggedIn = initialState.loggedIn;
  },
  [isStaggerPostSuccess]: (state, { payload }) => {
    state.isStaggerPost = payload;
  },
  [setIsStaggerPostSuccess]: (state, { payload }) => {
    state.isStaggerPost = payload;
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
