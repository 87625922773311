import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';

import { useSession } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { routeWithProps } from 'src/utils/helpers';
import { reset } from 'src/actions/statusActions';
import { Button } from 'src/common/button';
import { StepsMenu } from './steps-menu';
import { Step0Welcome } from './step0-welcome';
import { Step1BusinessInfo } from './step1-business-info';
import { Step2TargetDemographic } from './step2-target-demographic';
import { Step3MainCTA } from './step3-main-cta';
import { Step4AllSet } from './step4-all-set';
import {
  finishOnboarding,
} from '../../actions/userActions';
import {
  getUserWithAccount,
  GET_USER_WITH_ACCOUNT,
} from '../../actions/accountActions';
import styles from './Onboarding.module.scss';

const FIRST_PAGE_IDX = 0;
const LAST_PAGE_IDX = 4;

const Onboarding = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(getUserWithAccount());

    return () => {
      dispatch(reset(GET_USER_WITH_ACCOUNT));
    };
  }, [dispatch]);

  const { user } = useSession();

  const queryParams = useMemo(() => {
    const { location: { search } } = history;
    if (search) {
      const { pjId } = parse(search, { parseBooleans: true });
      return { pjId };
    }
    return {};
  }, [history]);

  const closeOnboarding = () => {
    dispatch(finishOnboarding(user));
    if (queryParams.pjId) {
      history.replace(routeWithProps(routesPaths.project, { id: queryParams.pjId }));
    } else {
      history.replace(routesPaths.projects);
    }
  };

  if (user.didOnboarding) {
    closeOnboarding();
  }

  const [currStep, setCurrStep] = useState(0);

  const formRef = useRef();

  const handleToNextStep = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
    setCurrStep(c => c + 1);
  };

  const handleToPrevStep = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
    setCurrStep(c => c - 1);
  };

  return (
    <div className={styles.page}>
      <div className={styles.leftContainer}>
        <StepsMenu currentStep={currStep} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.mainContainer}>
          {currStep === 0 && <Step0Welcome />}
          {currStep === 1 && <Step1BusinessInfo ref={formRef} />}
          {currStep === 2 && <Step2TargetDemographic ref={formRef} />}
          {currStep === 3 && <Step3MainCTA ref={formRef} />}
          {currStep === LAST_PAGE_IDX && <Step4AllSet closeOnboarding={closeOnboarding} />}
        </div>
        {currStep < LAST_PAGE_IDX && (
          <div className={styles.actionsContainer}>
            <Button className={styles.buttonSkip} onClick={closeOnboarding}>
              Skip for now
            </Button>

            <div>
              {currStep > FIRST_PAGE_IDX && (
                <Button className={styles.buttonBack} onClick={handleToPrevStep} secondary>
                  Back
                </Button>
              )}
              <Button className={styles.buttonNext} onClick={handleToNextStep}>
                {currStep === FIRST_PAGE_IDX ? 'Get Started' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { Onboarding };
