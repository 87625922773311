import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  SHIFT_CHARACTER_CODE,
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
  KEY_G_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import { useKeyboardListener, useProjectSelector } from 'src/hooks';
import { addGroupElement, ungroupElements } from 'src/actions/projectActions';
import { isHtmlTagFocused } from 'src/utils/helpers';
import { VIDEO_ELEMENT, GROUP_ELEMENT } from 'src/constants/canvasElements';

const useGroupElementsShortcut = () => {
  const dispatch = useDispatch();

  const { elements, selectedElements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);
  const moreThanOneSelected = selectedElementsList.length > 1;

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);

  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const keyGPressed = useKeyboardListener(KEY_G_CHARACTER_CODE);

  const isGroupDisabled = useMemo(() => {
    const hasVideo = selectedElementsList.reduce((res, selectedElemId) => {
      const selectedElement = elements.find(elem => elem.uuid === selectedElemId);
      return res || selectedElement?.type === VIDEO_ELEMENT;
    }, false);
    return !moreThanOneSelected || (moreThanOneSelected && hasVideo);
  }, [elements, moreThanOneSelected, selectedElementsList]);

  const groupElementsRequest = useCallback(() => {
    if (!isGroupDisabled) {
      dispatch(addGroupElement());
    }
  }, [dispatch, isGroupDisabled]);

  const ungroupElementsRequest = useCallback(() => {
    if (!moreThanOneSelected) {
      const selectedElement = elements.find(elem => elem.uuid === selectedElementsList[0]);
      const { uuid, type } = selectedElement;
      if (type === GROUP_ELEMENT) {
        dispatch(ungroupElements(uuid));
      }
    }
  }, [dispatch, elements, moreThanOneSelected, selectedElementsList]);

  useEffect(() => {
    if (!isHtmlTagFocused() && keyGPressed && (commandPressed || controlPressed)) {
      const areElementsSelected = selectedElementsList.length;
      if (areElementsSelected) {
        if (shiftShortcutPressed) {
          ungroupElementsRequest();
        } else {
          groupElementsRequest();
        }
      }
    }
  }, [
    keyGPressed,
    groupElementsRequest,
    selectedElementsList,
    shiftShortcutPressed,
    ungroupElementsRequest,
    commandPressed,
    controlPressed,
  ]);
};

export { useGroupElementsShortcut };
