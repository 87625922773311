import React, { useCallback, useState } from 'react';
import { func, bool, number, string, arrayOf, object } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { useLoading } from 'src/hooks';
import {
  deleteAccountsMovingProjects,
  DELETE_ACCOUNTS_MOVING_PROJECTS,
} from 'src/actions/accountActions';
import { DeleteAccountConfirmation } from '../delete-account-confirmation';
import { MoveProjects } from '../move-projects';
import styles from './DeleteAccount.module.scss';

const CHOOSE_STEP = 'chooseStep';
const DELETE_CONFIRMATION_STEP = 'deleteConfirmationStep';
const MOVE_PROJECTS_STEP = 'moveProjectsStep';

const DeleteAccount = ({ isShowing, hide, id, brandName, projects }) => {
  const dispatch = useDispatch();

  const defaultStep = projects?.length ? CHOOSE_STEP : DELETE_CONFIRMATION_STEP;
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const onMoveToAccount = useCallback(() => {
    setCurrentStep(MOVE_PROJECTS_STEP);
  }, []);

  const onDelete = useCallback(() => {
    setCurrentStep(DELETE_CONFIRMATION_STEP);
  }, []);

  const loadingDeleteMovingProjs = useLoading(DELETE_ACCOUNTS_MOVING_PROJECTS);

  const onMoveProjects = useCallback((selectedOption) => {
    dispatch(deleteAccountsMovingProjects([id], selectedOption));
  }, [dispatch, id]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      curvedContainer
      classNameContent={styles.modal}
      closeOnClickOutside={false}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete your account?
        </span>
        {currentStep === CHOOSE_STEP && (
          <>
            <span className={styles.subtitle}>
              You have projects in your account.
              Do you want to permanently delete them or move them to another account?
            </span>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={onMoveToAccount}
                alternativeCTA
              >
                Move to Another Account
              </Button>
              <Button
                className={styles.buttonDelete}
                onClick={onDelete}
              >
                Delete Them Forever
              </Button>
            </div>
          </>
        )}
        {currentStep === DELETE_CONFIRMATION_STEP && (
          <DeleteAccountConfirmation id={id} brandName={brandName} onCancel={hide} />
        )}
        {currentStep === MOVE_PROJECTS_STEP && (
          <MoveProjects
            ids={[id]}
            onCancel={hide}
            loading={loadingDeleteMovingProjs}
            onMoveProjects={onMoveProjects}
          />
        )}
      </div>
    </Modal>
  );
};

DeleteAccount.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  id: number.isRequired,
  brandName: string.isRequired,
  projects: arrayOf(object),
};

export { DeleteAccount };
