import React, { useRef } from 'react';
import { number, string } from 'prop-types';
import { Group, Text } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRightOptionsPosition,
  setCursorToDefault,
  setCursorToPointer,
} from 'src/utils/canvasHelpers';
import {
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
  CANVAS_TITLE_MARGIN_TOP,
  EDITABLE_TITLE_FRAME_TYPE,
} from 'src/constants/general';
import { useLongPress } from 'src/hooks';
import {
  deselectFrame,
  openRightClickOptions,
  selectFrame,
  setEditableFrameTitle,
} from 'src/actions/canvasActions';
import { LEFT_CLICK_CODE } from 'src/constants/keyboardCodes';
import { isRightClick } from 'src/utils/helpers';

const CanvasFrameTitle = ({ prefix, x, y, title, frameIndex }) => {
  const dispatch = useDispatch();

  const { selectedFrame } = useSelector(({ canvas }) => ({
    selectedFrame: canvas.selectedFrame,
  }));

  const ref = useRef();

  const prefixRightMargin = 5;
  const prefixWidth = (ref?.current?.textWidth || 0) + prefixRightMargin;

  const onLeftClickText = () => {
    if (selectedFrame !== frameIndex) {
      dispatch(selectFrame({ frameIndex }));
    } else {
      dispatch(deselectFrame());
    }
  };

  const onRightClickText = (e) => {
    const { evt } = e;
    const pos = getRightOptionsPosition(
      evt,
      CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT,
      CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
    );
    dispatch(openRightClickOptions({
      ...pos,
      type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.TITLE,
      frame: {
        x,
        y: y - CANVAS_TITLE_MARGIN_TOP,
        index: frameIndex,
        prefixWidth,
      },
    }));
  };

  const onLongTouch = () => {
    dispatch(setEditableFrameTitle({
      frame: {
        x,
        y: y - CANVAS_TITLE_MARGIN_TOP,
        index: frameIndex,
        prefixWidth,
      },
      type: EDITABLE_TITLE_FRAME_TYPE.MODAL,
    }));
  };

  const onClickText = (e) => {
    const { evt } = e;
    if (evt?.button === LEFT_CLICK_CODE && !evt?.ctrlKey) {
      onLeftClickText();
    }
    if (isRightClick(e.evt)) {
      onRightClickText(e);
    }
  };

  const { handlers } = useLongPress(onLongTouch, onLeftClickText);

  return (
    <Group
      onMouseEnter={setCursorToPointer}
      onMouseLeave={setCursorToDefault}
      onClick={onClickText}
      onTouchStart={handlers.onTouchStart}
      onTouchEnd={handlers.onTouchEnd}
    >
      <Text
        text={`${prefix} -`}
        x={x}
        y={y}
        fill={selectedFrame === frameIndex ? '#276DF1' : '#CCCCCC'}
        fontSize={15}
        ref={ref}
      />
      <Text
        text={`${title || 'Add a title'}`}
        x={x + prefixWidth}
        y={y}
        fill={selectedFrame === frameIndex ? '#276DF1' : '#CCCCCC'}
        fontSize={15}
      />
    </Group>
  );
};

CanvasFrameTitle.propTypes = {
  prefix: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  title: string,
  frameIndex: number.isRequired,
};

export { CanvasFrameTitle };
