import React, { useCallback, useState, useEffect } from 'react';
import { number, string, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useLoading, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { CUSTOM } from 'src/constants/general';
import CloseIcon from 'src/assets/icons/close-white.svg';
import {
  updatePaletteColor,
  deletePaletteColor,
  DELETE_PALETTE_COLOR,
} from 'src/actions/brandLibraryActions';
import { ColorPicker } from 'src/common/color-picker';
import styles from './Color.module.scss';

const Color = ({ paletteId, color }) => {
  const dispatch = useDispatch();

  const [showDelete, setShowDelete] = useState(false);

  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH && !showDelete) {
      setShowDelete(true);
    }
  }, [showDelete, windowSize.width]);

  const updateColorRequest = useCallback(value => {
    dispatch(updatePaletteColor(paletteId, color.id, value));
  }, [color.id, dispatch, paletteId]);

  const deleteColorRequest = useCallback(() => {
    dispatch(deletePaletteColor(paletteId, color.id));
  }, [color.id, dispatch, paletteId]);

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const deleteLoading = useLoading(DELETE_PALETTE_COLOR);

  return (
    <div className={styles.color}>
      <ColorPicker
        color={color}
        onColorChange={updateColorRequest}
        className={styles.colorPickerContainer}
        swatch={onClick => (
          <div
            className={styles.swatchContainer}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            <button
              className={styles.swatch}
              onClick={onClick}
              disabled={disabled}
            >
              <div
                style={{
                  backgroundColor: color.hex,
                  opacity: color.alpha / 100,
                }}
                className={styles.swatchColor}
              />
              <span className={styles.swatchName}>{color.hex}</span>
            </button>
            {showDelete && (
              <button
                className={styles.delete}
                onClick={deleteColorRequest}
                disabled={deleteLoading || disabled}
              >
                <img src={CloseIcon} alt="Delete" />
              </button>
            )}
          </div>
        )}
        openInModal
        position={CUSTOM}
        customPosition={styles.customPosition}
      />
    </div>
  );
};

Color.propTypes = {
  color: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  paletteId: number.isRequired,
};

export { Color };
