import React, { useCallback, useMemo } from 'react';
import { func, number, shape } from 'prop-types';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { AngleCirclePicker } from '../angle-circle-picker';
import styles from './AnglePicker.module.scss';

const MIN_ANGLE = 0;
const MAX_ANGLE = 359;

const AnglePicker = ({ gradientStartPoint, gradientEndPoint, onChange }) => {
  const moveAngleToShow = (angleToMove) => {
    let movedAnlge = angleToMove + 90;
    if (movedAnlge > MAX_ANGLE) {
      movedAnlge -= MAX_ANGLE + 1;
    }
    return movedAnlge;
  };

  const resetAngleToShow = (angleToMove) => {
    let movedAngle = angleToMove - 90;
    if (movedAngle < MIN_ANGLE) {
      movedAngle += MAX_ANGLE + 1;
    }
    return movedAngle;
  };

  const angle = useMemo(() => {
    const Vx = gradientEndPoint.x - gradientStartPoint.x;
    const Vy = gradientEndPoint.y - gradientStartPoint.y;
    let radians;
    if (Vx || Vy) {
      radians = Math.atan2(Vy, Vx);
    } else {
      radians = 0;
    }
    if (radians < 0) {
      radians += 2 * Math.PI;
    }
    const degrees = radians * (180 / Math.PI);
    const movedAngle = moveAngleToShow(degrees);
    return movedAngle;
  }, [gradientEndPoint, gradientStartPoint]);

  const lessAngle = useCallback(() => {
    const movedAngle = resetAngleToShow(angle);
    if (movedAngle === MIN_ANGLE) {
      onChange(MAX_ANGLE);
    } else {
      onChange(movedAngle - 1);
    }
  }, [angle, onChange]);

  const moreAngle = useCallback(() => {
    const movedAngle = resetAngleToShow(angle);
    if (movedAngle === MAX_ANGLE) {
      onChange(MIN_ANGLE);
    } else {
      onChange(movedAngle + 1);
    }
  }, [angle, onChange]);

  const onChangeInput = ({ target: { value } }) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      return;
    }
    const valueNumber = +(Number(value).toFixed(2));
    if (valueNumber < MIN_ANGLE || valueNumber > MAX_ANGLE + 1) {
      return;
    }
    const movedAngle = resetAngleToShow(valueNumber);
    onChange(movedAngle);
  };

  const onKeyDown = useCallback(({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  }, []);

  return (
    <div className={styles.container}>
      <AngleCirclePicker angle={angle} />
      <div className={styles.inputContainer}>
        <button onClick={lessAngle} className={styles.button}>
          -
        </button>
        <input
          value={+(Number(angle).toFixed(2))}
          className={styles.input}
          onChange={onChangeInput}
          onKeyDown={onKeyDown}
        />
        <button onClick={moreAngle} className={styles.button}>
          +
        </button>
      </div>
    </div>
  );
};

AnglePicker.propTypes = {
  onChange: func.isRequired,
  gradientStartPoint: shape({ x: number, y: number }).isRequired,
  gradientEndPoint: shape({ x: number, y: number }).isRequired,
};

export { AnglePicker };
