import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import CloseIcon from 'src/assets/icons/close-white.svg';
import { useProjectSelector, useWindowSize } from 'src/hooks';
import { changeLayout, changeSize } from 'src/actions/projectActions';
import { PROJECT_TYPE } from 'src/constants/general';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { LayoutControlOption } from './layout-control-option';
import { CustomLayoutControlOption } from './custom-layout-control-option';
import {
  socialMediaLayouts,
  presentationLayouts,
  webLayouts,
  emailLayouts,
  layoutAllowsOnlyOneFrame,
  flyerLayouts,
  signLayouts,
} from './layoutControlOptions';
import styles from './LayoutControl.module.scss';

const LayoutControl = ({ onCloseMobile }) => {
  const dispatch = useDispatch();

  const { layout: projectLayout, size, type } = useProjectSelector();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const elements = useMemo(() => {
    if (type === PROJECT_TYPE.FLYER) {
      return [flyerLayouts];
    }
    if (type === PROJECT_TYPE.SIGN) {
      return [signLayouts];
    }
    return (
      [
        socialMediaLayouts,
        presentationLayouts,
        webLayouts,
        emailLayouts,
      ]
    );
  }, [type]);

  const changeLayoutRequest = useCallback((layout) => {
    dispatch(changeLayout({ layout }));
  }, [dispatch]);

  const onChangeToEmailSource = useCallback((layout) => {
    const timestamp = new Date();
    dispatch(changeLayout({ layout, timestamp }));
    dispatch(changeSize({ size: 1, timestamp }));
  }, [dispatch]);

  const getModalConfirmationData = (option) => {
    if (layoutAllowsOnlyOneFrame(option.layout)) {
      return {
        needsConfirmationModal: true,
        modalCondition: size !== 1,
        modalTitle: 'Heads up',
        modalText: `This ${option.layout.source} layout uses one canvas.
        Are you sure you want to apply this layout and
        lose the work on your additional canvases?`,
        onChange: () => onChangeToEmailSource(option.layout),
      };
    }
    return { needsConfirmationModal: false };
  };

  const getLayoutControlOption = (option) => (
    <LayoutControlOption
      key={`${option.layout.source}_${option.layout.width}x${option.layout.height}`}
      icon={option.icon}
      iconSelected={option.iconSelected}
      layout={option.layout}
      text={option.text}
      buttonStyles={option.buttonStyles}
      isSelected={projectLayout.width === option.layout.width &&
        projectLayout.height === option.layout.height &&
        projectLayout.source === option.layout.source}
      onChange={() => changeLayoutRequest(option.layout)}
      {...getModalConfirmationData(option)}
    />
  );

  const showCustomOption = type !== PROJECT_TYPE.SIGN;

  return (
    <div className={styles.container}>
      {isMobile && (
        <div className={styles.header}>
          <span className={styles.title}>Canvas Layout</span>
          <button className={styles.closeButton} onClick={onCloseMobile}>
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
      )}
      {showCustomOption && <CustomLayoutControlOption />}
      <div className={styles.layouts}>
        {elements.map(elem => (
          <div className={styles.layout} key={elem.title}>
            {elem.title && <span className={styles.optionTitle}>{elem.title}</span>}
            <div className={styles.options}>
              {elem.options.map((option, index) => getLayoutControlOption(option, index))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LayoutControl.propTypes = {
  onCloseMobile: func,
};

export { LayoutControl };
