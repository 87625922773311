import React, { useState, useEffect } from 'react';
import { func, number, bool, string } from 'prop-types';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { toastErrorConfig } from 'src/constants/toastConfig';
import LineHeightWhiteIcon from 'src/assets/icons/line-height-white.svg';
import styles from './LineHeightInput.module.scss';

const MAX_LINE_HEIGHT = 10;

const LineHeightInput = ({
  disabled = false,
  onBlur,
  onChange,
  lineHeight,
  inputClassName = '',
  icon = LineHeightWhiteIcon,
}) => {
  const [value, setValue] = useState(lineHeight);

  useEffect(() => {
    setValue(lineHeight);
  }, [lineHeight]);

  const changeLineHeight = ({ target }) => {
    let valueNumber = +target.value;
    valueNumber = Math.round(valueNumber * 100) / 100;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > MAX_LINE_HEIGHT) {
      toast.error(`Line height must be less than ${MAX_LINE_HEIGHT}`, toastErrorConfig);
    } else if (onChange) {
      onChange(valueNumber);
    } else {
      onBlur(valueNumber);
    }
  };

  const onKeyDown = ({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  };

  return (
    <div className={styles.container}>
      <img src={icon} alt="Line height" />
      <input
        className={cn(styles.input, inputClassName)}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        disabled={disabled}
        onBlur={changeLineHeight}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

LineHeightInput.propTypes = {
  disabled: bool,
  onBlur: func.isRequired,
  onChange: func,
  lineHeight: number.isRequired,
  inputClassName: string,
  icon: string,
};

export { LineHeightInput };
