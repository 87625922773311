export const IMAGE_ELEMENT = 'IMAGE_ELEMENT';

export const VIDEO_ELEMENT = 'VIDEO_ELEMENT';

export const TEXT_ELEMENT = 'TEXT_ELEMENT';

export const LINE_ELEMENT = 'LINE_ELEMENT';

export const CIRCLE_ELEMENT = 'CIRCLE_ELEMENT';

export const SQUARE_ELEMENT = 'SQUARE_ELEMENT';

export const TRIANGLE_ELEMENT = 'TRIANGLE_ELEMENT';

export const ROUNDED_RECT_ELEMENT = 'ROUNDED_RECT_ELEMENT';

export const GROUP_ELEMENT = 'GROUP_ELEMENT';

export const SHAPES_ELEMENTS = [
  LINE_ELEMENT,
  CIRCLE_ELEMENT,
  SQUARE_ELEMENT,
  ROUNDED_RECT_ELEMENT,
  TRIANGLE_ELEMENT,
];

export const MEDIA_ELEMENTS = [
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
];
