import React, { useEffect, useMemo, useRef } from 'react';
import { func, bool, number, arrayOf, shape, string } from 'prop-types';
import cn from 'classnames';

import { ERROR, SUCCESS } from 'src/constants/status';
import ArrowLeftDisabled from 'src/assets/icons/arrow-left.svg';
import ArrowRightDisabled from 'src/assets/icons/arrow-right.svg';
import ArrowLeft from 'src/assets/icons/arrow-left-black.svg';
import ArrowRight from 'src/assets/icons/arrow-right-black.svg';
import { TemplateCard } from 'src/pages/templates/template-card';
import {
  SuggestionCard,
} from 'src/pages/project/controls-options/templates-control/categories-list/suggestion-card';
import { Spinner } from 'src/common/spinner';
import styles from './TemplateGrid.module.scss';

const TemplateGrid = ({
  isMainHeader,
  name,
  goToTemplates,
  showLoading,
  templates,
  suggestions,
  status,
  page,
  isNextPageDisabled,
  prevPage,
  nextPage,
  categoryId,
  amountOfTemplatesInARow,
  templateCardClassName,
  titleClassName,
}) => {
  const hidePagination = (isNextPageDisabled && page === 1) || status === ERROR;

  const refResults = useRef();

  useEffect(() => {
    if (refResults?.current && status === SUCCESS) {
      refResults.current.scrollTo(0, 0);
    }
  }, [status]);

  const rows = useMemo(() => (
    (templates || suggestions).map((itemsPerRow, rowIndex) => {
      const list = itemsPerRow.map(
        (item, index) => {
          if (templates) {
            return (
              <TemplateCard
                key={item.id}
                thumbnail={item.thumbnail}
                templateIndex={index + (rowIndex * amountOfTemplatesInARow)}
                categoryId={categoryId}
                className={templateCardClassName}
                {...item}
              />
            );
          }
          if (suggestions) {
            return (
              <SuggestionCard key={item.id} suggestion={item} />
            );
          }
          return null;
        },
      );
      return (
        <div
          className={styles.row}
          // eslint-disable-next-line react/no-array-index-key
          key={rowIndex}
        >
          {list}
          {Array.from({ length: amountOfTemplatesInARow - list.length }, (v, i) => i).map(elem => (
            <div className={cn(styles.blankcard, templateCardClassName)} key={elem} />
          ))}
        </div>
      );
    })), [amountOfTemplatesInARow, categoryId, suggestions, templateCardClassName, templates]);

  return (
    <div className={styles.content} ref={refResults}>
      {isMainHeader && (
        <div className={styles.header}>
          <button className={styles.button} onClick={goToTemplates}>
            <img
              className={styles.imageIcon}
              src={ArrowLeft}
              alt="Go back"
            />
            <div className={cn(styles.title, titleClassName)}>{name}</div>
          </button>
        </div>
      )}
      {showLoading ? (
        <Spinner
          containerClassName={styles.spinnerContainer}
          iconClassName={styles.spinnerIcon}
        />
      ) : (
        <>
          <div className={styles.grid}>
            {rows}
          </div>
          {status === ERROR && (
            <span className={styles.error}>
              Oops! The templates for this category are not available right now, try again later.
            </span>
          )}
          {!hidePagination && (
            <div className={styles.pagination}>
              <button className={styles.button} disabled={page === 1} onClick={prevPage}>
                <img
                  className={styles.imageIcon}
                  src={page === 1 ? ArrowLeftDisabled : ArrowLeft}
                  alt="Arrow Left"
                />
                <span
                  className={page === 1 ? styles.labelDisabled : styles.label}
                >
                  Back
                </span>
              </button>
              <button className={styles.button} disabled={isNextPageDisabled} onClick={nextPage}>
                <span
                  className={isNextPageDisabled ?
                    styles.labelDisabled :
                    styles.label}
                >
                  Next
                </span>
                <img
                  src={isNextPageDisabled ? ArrowRightDisabled : ArrowRight}
                  className={styles.imageIcon}
                  alt="Arrow Right"
                />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const itemsRow = arrayOf(shape({
  id: number,
  thumbnail: string,
}));

TemplateGrid.propTypes = {
  isMainHeader: bool.isRequired,
  name: string,
  goToTemplates: func,
  showLoading: bool.isRequired,
  templates: arrayOf(itemsRow),
  suggestions: arrayOf(itemsRow),
  status: string,
  page: number.isRequired,
  isNextPageDisabled: bool.isRequired,
  prevPage: func.isRequired,
  nextPage: func.isRequired,
  categoryId: number,
  amountOfTemplatesInARow: number,
  templateCardClassName: string,
  titleClassName: string,
};

export { TemplateGrid };
