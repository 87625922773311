import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, number } from 'prop-types';

import LoadingAnimation from 'src/assets/images/dots-loading.gif';
import { generateCollage } from 'src/actions/collageActions';
import { useProjectSelector } from 'src/hooks';
import styles from './LoadingCollage.module.scss';

const LoadingCollage = ({ sendCollage = false, amountCanvases }) => {
  const dispatch = useDispatch();

  const { chatMedia } = useSelector(({ collage }) => ({
    chatMedia: collage.chatMedia,
  }));

  const { id } = useProjectSelector();

  useEffect(() => {
    if (sendCollage) {
      const media = Object.values(chatMedia).flatMap(m => m.media);
      dispatch(generateCollage(id, media, amountCanvases));
    }
  }, [chatMedia, dispatch, id, sendCollage, amountCanvases]);

  return (
    <div className={styles.container}>
      <img src={LoadingAnimation} alt="Loading" className={styles.loading} />
    </div>
  );
};

LoadingCollage.propTypes = {
  sendCollage: bool,
  amountCanvases: number,
};

export { LoadingCollage };
