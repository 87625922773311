import { HttpClient } from 'src/clients';

class AccountInviteService {
  static inviteNeedsNewUser(token) {
    return HttpClient.get(`/account-invites/invite-needs-new-user/${token}`);
  }

  static accept(token) {
    return HttpClient.post('/account-invites/accept', { accountInviteToken: token });
  }

  static getAccountsInvites(accountIds) {
    return HttpClient.get('/account-invites', {
      params: { accountIds: JSON.stringify(accountIds) },
    });
  }

  static createAccountsInvite(accountInviteParams, accountIds) {
    return HttpClient.post('/account-invites', {
      accountInviteParams,
      accountIds,
    });
  }

  static resendInviteToAccountsSuccess(inviteId) {
    return HttpClient.post(`/account-invites/resend-invite/${inviteId}`);
  }
}

export { AccountInviteService };
