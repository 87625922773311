import React from 'react';

import styles from './LoadingCard.module.scss';

const LoadingCard = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.lines}>
        <div className={styles.firstLine} />
        <div className={styles.secondLine} />
      </div>
    </div>
  </div>
);

export { LoadingCard };
