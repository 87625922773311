import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bool } from 'prop-types';
import cn from 'classnames';
import * as Sentry from '@sentry/browser';

import CloseWhiteIcon from 'src/assets/icons/close-white.svg';
import { Alert } from 'src/common/alert';
import { Button } from 'src/common/button';
import { useSession, useInterval, useDelayUnmount, useStatus } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import {
  fillBrandLibraryFromWebsite,
  setCurrentBrandAssetStatus,
  FILL_BRAND_LIBRARY_FROM_WEBSITE,
} from 'src/actions/accountActions';
import {
  BRAND_ASSETS_STATUS,
  USER_WEBSITE_MODAL_ID,
  FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS,
} from 'src/constants/general';
import { SUCCESS, LOADING } from 'src/constants/status';
import styles from './ScrapperAlert.module.scss';

const ANIMATION_DURATION = 130;
const MAX_AMOUNT_OF_REQUESTS = 25;

const ScrapperAlert = ({
  showAnimation = true,
  showSuccessAlert = true,
  showErrorButton = true,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { accountId, account } = useSession();
  const { brandAssetStatus } = account || {};

  const defaultIntervalDelay = 15000;
  const [intervalDelay, setIntervalDelay] = useState(defaultIntervalDelay);
  const [amountOfRequests, setAmountOfRequests] = useState(0);

  const fillFromWebsite = useCallback(() => {
    if (brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY) {
      dispatch(fillBrandLibraryFromWebsite(accountId, amountOfRequests));
      setAmountOfRequests(a => a + 1);
      !intervalDelay && setIntervalDelay(defaultIntervalDelay);
    } else {
      setIntervalDelay(null);
      setAmountOfRequests(0);
    }
  }, [brandAssetStatus, amountOfRequests, dispatch, accountId, intervalDelay]);

  useEffect(() => {
    if (!intervalDelay &&
      brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY) {
      setIntervalDelay(defaultIntervalDelay);
    }
  }, [intervalDelay, brandAssetStatus, amountOfRequests]);

  useEffect(() => {
    if (amountOfRequests >= MAX_AMOUNT_OF_REQUESTS) {
      setIntervalDelay(null);
      setAmountOfRequests(0);
      const params = {
        brandAssetStatus: BRAND_ASSETS_STATUS.API_ERROR,
        id: accountId,
      };
      dispatch(setCurrentBrandAssetStatus(params));
      Sentry.captureMessage(
        `fillBrandLibraryFromWebsite reached limit of ${MAX_AMOUNT_OF_REQUESTS}
         requests for user ${accountId}`,
      );
    }
  }, [intervalDelay, brandAssetStatus, amountOfRequests, accountId, dispatch]);

  const {
    status: fillBLFromWebsiteStatus,
    statusInfo: fillBLFromWebsiteStatusInfo,
  } = useStatus(FILL_BRAND_LIBRARY_FROM_WEBSITE);

  useInterval(() => {
    if (fillBLFromWebsiteStatus !== LOADING) {
      fillFromWebsite();
    }
  }, intervalDelay);

  const userWebsiteModal = document.getElementById(USER_WEBSITE_MODAL_ID);

  const isSuccess = !userWebsiteModal &&
    brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY;

  const shouldRenderSuccessAlert = useDelayUnmount(isSuccess, ANIMATION_DURATION);

  const renderSuccessAlert = showAnimation ? shouldRenderSuccessAlert : isSuccess;

  const isAlreadyFilling = fillBLFromWebsiteStatus === SUCCESS &&
  fillBLFromWebsiteStatusInfo.status === FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.ALREADY_FILLING;

  const isError = brandAssetStatus === BRAND_ASSETS_STATUS.FAILED ||
    brandAssetStatus === BRAND_ASSETS_STATUS.API_ERROR ||
    isAlreadyFilling;

  showErrorButton = showErrorButton && !isAlreadyFilling;

  const shouldRenderErrorAlert = useDelayUnmount(isError, ANIMATION_DURATION);

  const renderErrorAlert = showAnimation ? shouldRenderErrorAlert : isError;

  const onClickSuccess = useCallback(async () => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.DONE,
      id: accountId,
    };
    await dispatch(setCurrentBrandAssetStatus(params));
    history.push(routesPaths.brandLibrary);
  }, [dispatch, history, accountId]);

  const onClickError = useCallback(async () => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.ASKING_FOR_A_WEBSITE,
      id: accountId,
    };
    await dispatch(setCurrentBrandAssetStatus(params));
    if (pathname !== routesPaths.projects) {
      history.push(routesPaths.projects);
    }
  }, [dispatch, history, pathname, accountId]);

  const onCloseSuccess = useCallback(() => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.DONE,
      id: accountId,
    };
    dispatch(setCurrentBrandAssetStatus(params));
  }, [dispatch, accountId]);

  const onCloseError = useCallback(() => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.NOT_APPLICABLE,
      id: accountId,
    };
    dispatch(setCurrentBrandAssetStatus(params));
  }, [dispatch, accountId]);

  const animationSuccessStyle = isSuccess ? styles.mount : styles.unmount;
  const animationErrorStyle = isError ? styles.mount : styles.unmount;

  if (renderErrorAlert) {
    return (
      <Alert containerClassName={cn(styles.container, animationErrorStyle, styles.error)}>
        <span className={styles.text}>
          {brandAssetStatus === BRAND_ASSETS_STATUS.FAILED &&
            'The URL you gave us is invalid. Want to try again?'}
          {brandAssetStatus === BRAND_ASSETS_STATUS.API_ERROR &&
            (isAlreadyFilling ?
              'We may found a problem with the website. Do you mind checking the Brand Library?' :
              'Oh man, we ran into a problem with that website. Do you mind trying again?')}
        </span>
        {showErrorButton && (
          <Button className={styles.actionButton} onClick={onClickError} secondary>
            Update my website
          </Button>
        )}
        <button className={styles.close} onClick={onCloseError}>
          <img src={CloseWhiteIcon} alt="Close" />
        </button>
      </Alert>
    );
  }
  if (!showSuccessAlert) {
    return null;
  }
  if (renderSuccessAlert) {
    return (
      <Alert containerClassName={cn(styles.container, animationSuccessStyle)}>
        <span className={styles.text}>
          Your Brand Library is ready to be previewed.
        </span>
        <Button className={styles.actionButton} onClick={onClickSuccess} secondary>
          Check it out
        </Button>
        <button className={styles.close} onClick={onCloseSuccess}>
          <img src={CloseWhiteIcon} alt="Close" />
        </button>
      </Alert>
    );
  }
  return null;
};

ScrapperAlert.propTypes = {
  showAnimation: bool,
  showSuccessAlert: bool,
  showErrorButton: bool,
};

export { ScrapperAlert };
