import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStatus } from 'src/hooks';
import { SUCCESS } from 'src/constants/status';

import {
  fbSDKInit,
  FB_SDK_INIT,
  isLoggedInToFB,
  LOGIN_TO_FB,
} from 'src/actions/instagramAnalyticsActions';

const useCheckFBLoginStatus = () => {
  const dispatch = useDispatch();

  const {
    loggedIn,
  } = useSelector(({ instagramAnalytics }) => ({
    loggedIn: instagramAnalytics.loggedIn,
  }));

  useEffect(() => {
    if (!window.FB) {
      dispatch(fbSDKInit());
    }
  }, [dispatch]);

  const { status: loginToFb } = useStatus(LOGIN_TO_FB);
  const { status: fbSDKInitStatus } = useStatus(FB_SDK_INIT);

  useEffect(() => {
    if (fbSDKInitStatus === SUCCESS) {
      dispatch(isLoggedInToFB());
    }
  }, [dispatch, loginToFb, fbSDKInitStatus]);

  return loggedIn;
};

export { useCheckFBLoginStatus };
