import { useSelector, shallowEqual } from 'react-redux';

import {
  getMembershipAccount,
  getTeamAccount,
  isSubscriptionActive,
  getMembershipPlan,
} from 'src/utils/sessionHelpers';

const useSession = () => useSelector(
  ({
    session: {
      authenticated,
      user,
      hasPassword,
      accountId,
      onboarding,
      billingInformation,
    },
  }) => {
    const membershipAccount = getMembershipAccount(user, accountId);
    const teamAccount = getTeamAccount(user?.teams, accountId);

    return {
      authenticated,
      user,
      hasPassword,
      accountId,
      onboarding,
      billingInformation,
      account: membershipAccount || teamAccount?.account,
      isSubscriptionActive: isSubscriptionActive(user, accountId),
      membershipPlan: getMembershipPlan(user, accountId),
    };
  },
  shallowEqual,
);

export { useSession };
