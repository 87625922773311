import React, { forwardRef, memo } from 'react';
import { object, number, shape } from 'prop-types';
import * as Sentry from '@sentry/browser';

import { useProjectSelector } from 'src/hooks';
import {
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
  TEXT_ELEMENT,
} from 'src/constants/canvasElements';
import { areSelectedElementsUnlocked, getTransformerAnchorsByType } from 'src/utils/canvasHelpers';
import { CanvasTransformer } from 'src/pages/project/canvas/canvas-helper-elements';
import { imageTransformerBound } from 'src/utils/imageCropperHelper';

const Transformers = memo(forwardRef(({
  selectedElements,
  selectedRefs,
  scale,
  canvasAttrs,
}, refCropper) => {
  const { elements, editableImage, editableText, groupsOfElementsById, id } = useProjectSelector();

  const getPropsByType = (element) => {
    const props = getTransformerAnchorsByType(element, editableImage);
    switch (element.type) {
      case IMAGE_ELEMENT:
        return {
          ...props,
          keepRatio: true,
          boundBoxFunc: (oldBox, newBox) => {
            if (editableImage === element.uuid) {
              const { current: { attrs } } = refCropper;
              return imageTransformerBound(oldBox, newBox, scale, attrs, canvasAttrs);
            }
            return newBox;
          },
          isCropping: editableImage === element.uuid,
          temporaryCropAttrs: element.temporaryCropAttrs,
        };
      case VIDEO_ELEMENT:
        return {
          ...props,
          keepRatio: true,
        };
      case TEXT_ELEMENT:
        return {
          ...props,
          boundBoxFunc: (_, newBox, refTransform) => {
            newBox.width = Math.max(30, newBox.width);
            refTransform.current.nextHeight = newBox.height;
            return newBox;
          },
          show: editableText !== element.uuid,
        };
      default:
        return props;
    }
  };

  const singleElementSelected = Object.keys(selectedElements).length === 1;

  const multipleElementsUnlocked = areSelectedElementsUnlocked(
    selectedElements,
    elements,
    groupsOfElementsById,
  );

  if (singleElementSelected) {
    return (
      Object.keys(selectedElements).map(selectedElemId => {
        const selectedElement = elements.find(elem => elem.uuid === selectedElemId);
        if (!selectedElement) {
          Sentry.captureMessage(
            'Tried to show a transformer for a selected element that doesn\'t exist',
            { extra: { selectedElemId, projectId: id } },
          );
          // eslint-disable-next-line no-console
          console.error('Tried to show a transformer for a selected element that doesn\'t exist');
          return null;
        }
        const transformerProps = getPropsByType(selectedElement);
        return (
          <CanvasTransformer
            key={selectedElement.uuid}
            uuid={selectedElement.uuid}
            selectedRefs={selectedRefs}
            rotateEnabled={selectedElement.unlocked}
            resizeEnabled={selectedElement.unlocked}
            ref={refCropper}
            canvasAttrs={canvasAttrs}
            {...transformerProps}
          />
        );
      })
    );
  }
  return (
    <CanvasTransformer
      uuid="multipleSelected"
      selectedRefs={selectedRefs}
      ref={refCropper}
      rotateEnabled={multipleElementsUnlocked}
      resizeEnabled={multipleElementsUnlocked}
    />
  );
}));

Transformers.propTypes = {
  selectedElements: object,
  selectedRefs: object,
  scale: number.isRequired,
  canvasAttrs: shape({
    x: number,
    y: number,
  }),
};

export { Transformers };
