import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { reset } from 'src/actions/statusActions';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { useStatus } from 'src/hooks';
import {
  getInstagramPosts,
  isLoggedInToFB,
  GET_INSTAGRAM_POSTS,
  LOGIN_TO_FB,
} from 'src/actions/instagramAnalyticsActions';
import { DisabledContent } from '../disabled-content';
import { PostCard } from './post-card';
import { NoPosts } from './no-posts';
import { PostData } from './post-data';
import { FBLogin } from './fb-login';
import styles from './InstagramDashboard.module.scss';

const InstagramDashboard = () => {
  const dispatch = useDispatch();

  const {
    selectedPost,
    disabled,
    posts,
    loggedIn,
    igBusinessAccountId,
  } = useSelector(({ instagramAnalytics }) => ({
    selectedPost: instagramAnalytics.selectedPost,
    posts: instagramAnalytics.posts,
    disabled: instagramAnalytics.disabled,
    loggedIn: instagramAnalytics.loggedIn,
    igBusinessAccountId: instagramAnalytics.igBusinessAccountId,
  }));

  const { status: loginToFb } = useStatus(LOGIN_TO_FB);

  useEffect(() => {
    dispatch(isLoggedInToFB());
  }, [dispatch, loginToFb]);

  useEffect(() => {
    if (loggedIn && !disabled) {
      dispatch(getInstagramPosts(igBusinessAccountId));
    }
    return () => {
      dispatch(reset(GET_INSTAGRAM_POSTS));
    };
  }, [disabled, dispatch, loggedIn, igBusinessAccountId]);

  const { status: getPostsStatus } = useStatus(GET_INSTAGRAM_POSTS);

  const showNoPostsList = disabled || !loggedIn ||
    (getPostsStatus === SUCCESS && !posts.length) || getPostsStatus === ERROR;

  return (
    <div className={styles.content}>
      <div className={styles.list}>
        <span className={styles.listTitle}>
          Feed
        </span>
        <div className={styles.listPosts}>
          {getPostsStatus === LOADING && (
            <Spinner
              iconClassName={styles.spinnerIcon}
              containerClassName={styles.spinnerContainer}
            />
          )}
          {getPostsStatus === SUCCESS && posts.map(post => (
            <PostCard
              post={post}
              selectedProject={selectedPost}
              key={post.id}
            />
          ))}
          {showNoPostsList && <div className={styles.noPosts}>No posts.</div>}
        </div>
      </div>
      <div className={styles.analytics}>
        {!loggedIn && !disabled && (
          <div className={styles.loginContainer}>
            <span className={styles.loginTitle}>Not connected.</span>
            <span className={styles.loginSubtitle}>
              We aren&apos;t connected to any of your social network accounts.
              Connect an account to start retrieving your post&apos;s data.
            </span>
            <FBLogin loggedIn={loggedIn} />
          </div>
        )}
        {disabled && (
          <DisabledContent
            text="When you upgrade to a Business plan, your Instagram data will be displayed."
          />
        )}
        {getPostsStatus === SUCCESS && selectedPost && <PostData />}
        {getPostsStatus === SUCCESS && !posts.length && <NoPosts />}
        {/* TODO: conditional to show <LowFollowers /> */}
      </div>
    </div>
  );
};

export { InstagramDashboard };
