import React from 'react';
import { oneOfType, node, object, string } from 'prop-types';

import styles from './Alert.module.scss';

const Alert = ({ children, containerClassName = '' }) => (
  <div className={`${styles.alert} ${containerClassName}`}>
    {children}
  </div>
);

Alert.propTypes = {
  children: oneOfType([node, object]).isRequired,
  containerClassName: string,
};

export { Alert };
