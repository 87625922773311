import React from 'react';

import { PROJECT_TYPE } from 'src/constants/general';
import { MEDIUM_WIDTH, MOBILE_THRESHOLD_WIDTH, SMALL_WIDTH } from 'src/constants/breakpoints';
import QrCodeImage from 'src/assets/images/qr-code-tour.svg';
import QrCodeExample from 'src/assets/images/qr-code-example.svg';
import PublishFlyerExample from 'src/assets/images/publish-flyer-example.svg';
import ImageTiles from 'src/assets/images/canvas-tiles.png';
import ImageExport from 'src/assets/images/canvas-export.png';
import ExportExample from 'src/assets/images/export-example.svg';
import TextIconDark from 'src/assets/icons/text-icon-dark.svg';
import ShapesIconDark from 'src/assets/icons/shapes-dark.svg';
import ImageIconDark from 'src/assets/icons/choose-image-dark.svg';
import TemplatesIconDark from 'src/assets/icons/templates-dark.svg';
import EditCanvasIconDark from 'src/assets/icons/edit-canvas-dark.svg';
import CollagesIconDark from 'src/assets/icons/wand-dark.svg';
import CanvasCollage from 'src/assets/images/canvas-collage.png';
import {
  TEMPLATES_CONTROL,
  CHOOSE_IMAGE_CONTROL,
  EDIT_CANVAS_CONTROL,
  ADD_TEXT_CONTROL,
  ADD_OBJECT_CONTROL,
  COLLAGE_CONTROL,
} from 'src/constants/canvasControls';
import styles from './ProductTour.module.scss';

export const getTourProductName = (type) => {
  let tourName = 'didProductTourProject';
  if (type === PROJECT_TYPE.SIGN) {
    tourName = 'didProductTourSign';
  } else if (type === PROJECT_TYPE.FLYER) {
    tourName = 'didProductTourFlyer';
  }
  return tourName;
};

export const getSteps = (windowWidth, type, onClickToolbarStep) => {
  if (type === PROJECT_TYPE.PROJECT) {
    return (
      [
        {
          title: 'Main Toolbar',
          subtitle: 'Use your toolbar to manage the design and layout of your Stagger project.',
          body: () => (
            <div className={styles.listContainer}>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(COLLAGE_CONTROL)}
              >
                <img src={CollagesIconDark} className={styles.collageIcon} alt="Collages" />
                <span className={styles.listItem}>Collages</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(TEMPLATES_CONTROL)}
              >
                <img src={TemplatesIconDark} alt="Templates" />
                <span className={styles.listItem}>Template Library</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(CHOOSE_IMAGE_CONTROL)}
              >
                <img src={ImageIconDark} alt="Choose asset" />
                <span className={styles.listItem}>Add Media</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(EDIT_CANVAS_CONTROL)}
              >
                <img src={EditCanvasIconDark} alt="Edit canvas" />
                <span className={styles.listItem}>Edit Canvas</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_TEXT_CONTROL)}
              >
                <img src={TextIconDark} alt="Text" />
                <span className={styles.listItem}>Text</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_OBJECT_CONTROL)}
              >
                <img src={ShapesIconDark} alt="Objects" />
                <span className={styles.listItem}>Objects</span>
              </button>
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'left-top' : 'top-left',
        },
        {
          title: 'Canvas Tiles',
          subtitle: `Tell a continuous story with canvases.
          The divider line shows your where the design breaks.`,
          body: () => (
            <div className={styles.singleImageContainer}>
              <img src={ImageTiles} alt="" className={styles.singleImage} />
            </div>
          ),
          align: windowWidth > MEDIUM_WIDTH ? 'left-top' : 'top-center',
        },
        {
          title: 'Creator Collages',
          subtitle: `Upload two more photos and we'll automagically make a
          Creator Collage for you to post to social media.`,
          body: () => (
            <div className={styles.collageContainer}>
              <img src={CanvasCollage} alt="" />
            </div>
          ),
          align: windowWidth > MOBILE_THRESHOLD_WIDTH ? 'left-top' : 'top-right',
        },
        {
          title: 'Export Your Stagger',
          subtitle: `When you've finished your Stagger, preview the final
          design and choose to export individual files or copy an embed code for your website.`,
          body: () => (
            <div className={styles.exportContainer}>
              <img src={ImageExport} alt="" />
              <img src={ExportExample} alt="" />
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'right-top' : 'top-right',
        },
      ]
    );
  }
  if (type === PROJECT_TYPE.SIGN) {
    return (
      [
        {
          title: 'Main Toolbar',
          subtitle: 'Use your toolbar to design your own customized printed sign for your brand.',
          body: () => (
            <div className={styles.listContainer}>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(TEMPLATES_CONTROL)}
              >
                <img src={TemplatesIconDark} alt="Templates" />
                <span className={styles.listItem}>Template Library</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(CHOOSE_IMAGE_CONTROL)}
              >
                <img src={ImageIconDark} alt="Choose asset" />
                <span className={styles.listItem}>Add Media</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(EDIT_CANVAS_CONTROL)}
              >
                <img src={EditCanvasIconDark} alt="Edit canvas" />
                <span className={styles.listItem}>Edit Canvas</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_TEXT_CONTROL)}
              >
                <img src={TextIconDark} alt="Text" />
                <span className={styles.listItem}>Text</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_OBJECT_CONTROL)}
              >
                <img src={ShapesIconDark} alt="Objects" />
                <span className={styles.listItem}>Objects</span>
              </button>
            </div>
          ),
          align: 'left-top',
        },
        {
          title: 'Custom QR Code',
          subtitle: `We generated your own QR code that anyone can scan
          to view your digital flyers closest to them.`,
          body: () => (
            <div className={styles.singleImageContainer}>
              <img src={QrCodeImage} alt="" className={styles.singleImage} />
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'left-top' : 'top-center',
        },
        {
          title: 'Order Your Sign',
          subtitle: `We’ll print and send your sign to you so you
          can start promoting your Flyers in real life.`,
          body: () => (
            <div className={styles.singleImageContainer}>
              <img src={QrCodeExample} alt="" className={styles.singleImage} />
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'right-top' : 'top-right',
        },
      ]
    );
  }
  if (type === PROJECT_TYPE.FLYER) {
    return (
      [
        {
          title: 'Main Toolbar',
          subtitle: 'Use your toolbar to manage the design and layout of your digital flyer.',
          body: () => (
            <div className={styles.listContainer}>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(TEMPLATES_CONTROL)}
              >
                <img src={TemplatesIconDark} alt="Templates" />
                <span className={styles.listItem}>Template Library</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(CHOOSE_IMAGE_CONTROL)}
              >
                <img src={ImageIconDark} alt="Choose asset" />
                <span className={styles.listItem}>Add Media</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(EDIT_CANVAS_CONTROL)}
              >
                <img src={EditCanvasIconDark} alt="Edit canvas" />
                <span className={styles.listItem}>Edit Canvas</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_TEXT_CONTROL)}
              >
                <img src={TextIconDark} alt="Text" />
                <span className={styles.listItem}>Text</span>
              </button>
              <button
                className={styles.listToolbar}
                onClick={() => onClickToolbarStep(ADD_OBJECT_CONTROL)}
              >
                <img src={ShapesIconDark} alt="Objects" />
                <span className={styles.listItem}>Objects</span>
              </button>
            </div>
          ),
          align: 'left-top',
        },
        {
          title: 'Canvas Tiles',
          subtitle: `Tell a continuous story with canvases.
          The divider line shows your where the design breaks.`,
          body: () => (
            <div className={styles.singleImageContainer}>
              <img src={ImageTiles} alt="" className={styles.singleImage} />
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'left-top' : 'top-center',
        },
        {
          title: 'Publish Your Flyer',
          subtitle: `When you’ve finished your design, publish your Flyer to
          your public profile and give it an address for your dynamic QR code.`,
          body: () => (
            <div className={styles.singleImageContainer}>
              <img src={PublishFlyerExample} alt="" className={styles.singleImage} />
            </div>
          ),
          align: windowWidth > SMALL_WIDTH ? 'right-top' : 'top-right',
        },
      ]
    );
  }
  return [{ title: '', subtitle: '', body: () => {} }];
};
