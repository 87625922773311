import { useDragging } from './useDragging';

const limitPos = (offset, min, max) => Math.max(Math.min(offset, max), min);

const getColorStopRefTop = (ref) => {
  if (!ref.current) return 0;
  return ref.current.getBoundingClientRect().top;
};

// NOTE: From https://github.com/odedglas/react-linear-gradient-picker
const useStopDragging = ({
  limits,
  stop,
  colorStopRef,
  onPosChange,
  onDragStart,
  onDragEnd,
  onDeleteColor,
  left,
}) => {
  const handleDrag = ({ clientX, clientY }) => {
    const { id } = stop;
    const { min, max } = limits;

    // Removing if out of drop limit on Y axis
    const top = getColorStopRefTop(colorStopRef);
    if (Math.abs(clientY - top) > limits.drop) {
      onDeleteColor(id);
      return;
    }

    if (!left) {
      return;
    }
    const dragOffset = clientX - left;
    const limitedPos = limitPos(dragOffset, min, max);
    onPosChange({ id, offset: limitedPos });
  };

  const [drag] = useDragging({
    onDragStart: () => onDragStart(stop.id),
    onDrag: handleDrag,
    onDragEnd: () => onDragEnd(stop.id),
  });

  return [drag];
};

export { useStopDragging };
