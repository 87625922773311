import React, { useRef, useEffect, forwardRef, memo } from 'react';
import { Transformer } from 'react-konva';
import { arrayOf, string, func, bool, number, object, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { boundBoxFuncTrasformerAlignmentGuides } from 'src/utils/alignmentGuidesHelpers';
import { CANVAS_RIGHT_CLICK_OPTIONS_TYPE, TRANSFORMER_ID } from 'src/constants/general';
import { ALT_CHARACTER_CODE, SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { useKeyboardListener } from 'src/hooks';
import { cloneElements } from 'src/actions/projectActions';
import {
  CanvasElementOptionsButton,
} from 'src/pages/project/canvas/canvas-helper-elements';
import { drawCanvasElementoptionsButton } from 'src/utils/canvasHelpers';
import { closeRightClickOptions } from 'src/actions/canvasActions';
import { CanvasImageCropper } from '../canvas-image-cropper';

const CanvasTransformer = memo(forwardRef(({
  enabledAnchors,
  boundBoxFunc,
  rotateEnabled = true,
  resizeEnabled = true,
  padding,
  keepRatio = false,
  uuid,
  selectedRefs,
  show = true,
  isCropping = false,
  temporaryCropAttrs,
  canvasAttrs,
  ignoreStroke = false,
}, refCropper) => {
  const refTransform = useRef();
  const refMenu = useRef();

  const dispatch = useDispatch();

  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  useEffect(() => {
    const refs = Object.values(selectedRefs).map(ref => ref?.current);
    if (refTransform?.current && !refs?.includes(undefined) && !refs?.includes(null)) {
      if (isCropping) {
        selectedRefs[uuid].current.setAttrs({ rotation: 0 });
      }
      refTransform.current.nodes(refs);
      refTransform.current.getLayer().batchDraw();
      drawCanvasElementoptionsButton(refMenu, refTransform);
    }
  }, [refTransform, uuid, selectedRefs, isCropping]);

  const altShortcutPressed = useKeyboardListener(ALT_CHARACTER_CODE);
  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const hideMenu = () => {
    if (refMenu?.current) {
      refMenu.current.hide();
    }
    if (rightClickOptions?.type === CANVAS_RIGHT_CLICK_OPTIONS_TYPE.ELEMENT_FLOATING_MOBILE) {
      dispatch(closeRightClickOptions());
    }
  };

  const onDragStart = ({ target }) => {
    if (altShortcutPressed) {
      const uuids = target.nodes().map(node => node.attrs.id);
      dispatch(cloneElements({ uuids }));
    }
    hideMenu();
  };

  const showMenu = () => {
    if (refMenu?.current) {
      refMenu.current.show();
    }
  };

  return (
    <>
      {show && (
        <Transformer
          ref={refTransform}
          anchorStroke="#979797"
          borderStroke="#979797"
          borderStrokeWidth={1.5}
          borderDash={[4]}
          rotateAnchorOffset={40}
          padding={padding}
          rotationSnaps={[0, 90, 180, 270]}
          enabledAnchors={enabledAnchors}
          boundBoxFunc={(oldBox, newBox) => {
            const box = boundBoxFuncTrasformerAlignmentGuides(
              oldBox,
              newBox,
              refTransform,
              shiftShortcutPressed,
            );
            if (boundBoxFunc) {
              return boundBoxFunc(oldBox, box, refTransform);
            }
            return box;
          }}
          rotateEnabled={rotateEnabled}
          resizeEnabled={resizeEnabled}
          keepRatio={keepRatio}
          id={TRANSFORMER_ID}
          onDragStart={onDragStart}
          onDragEnd={showMenu}
          onTransformStart={hideMenu}
          onTransformEnd={showMenu}
          ignoreStroke={ignoreStroke}
          flipEnabled={false}
        />
      )}
      {isCropping && selectedRefs[uuid]?.current && (
        <CanvasImageCropper
          refImage={selectedRefs[uuid]}
          ref={refCropper}
          temporaryCropAttrs={temporaryCropAttrs}
          canvasAttrs={canvasAttrs}
        />
      )}
      <CanvasElementOptionsButton refTransform={refTransform} ref={refMenu} />
    </>
  );
}));

CanvasTransformer.propTypes = {
  enabledAnchors: arrayOf(string),
  boundBoxFunc: func,
  rotateEnabled: bool,
  resizeEnabled: bool,
  keepRatio: bool,
  padding: number,
  uuid: string.isRequired,
  selectedRefs: object,
  show: bool,
  isCropping: bool,
  temporaryCropAttrs: object,
  canvasAttrs: shape({
    x: number,
    y: number,
  }),
  ignoreStroke: bool,
};

export { CanvasTransformer };
