import React, { useState, useEffect } from 'react';
import { string, number, bool, arrayOf, shape, oneOfType, func } from 'prop-types';
import cn from 'classnames';

import { EditEditorForm } from 'src/pages/account-settings/editors-manager/edit-editor-form';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { Tooltip } from 'src/common/tooltip';
import { useTooltip, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { DeleteEditor } from 'src/pages/account-settings/editors-manager/delete-editor';
import styles from './EditorCard.module.scss';

const EditorCard = ({ id, name, lastName, email, teams, openFormId, setOpenFormId, disabled }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { windowSize } = useWindowSize();

  const onClickEdit = () => {
    setShowOptions(false);
    setOpenFormId(id);
  };

  const onCancelEdit = () => {
    setOpenFormId();
  };

  const editorAccounts = teams.map(team => team.account);

  const accountNames = editorAccounts.map(account => account.brandName).join(', ');
  const { ref, showTooltip } = useTooltip();

  const onKeyDown = ({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      setOpenFormId(id);
      target.blur();
    }
  };

  useEffect(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH && !showOptions && openFormId !== id) {
      setShowOptions(true);
    }
  }, [showOptions, windowSize.width, openFormId, id]);

  const onMouseEnter = () => {
    if (openFormId !== id) {
      setShowOptions(true);
    }
  };

  const onClickDelete = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div
        className={styles.container}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setShowOptions(false)}
      >
        <div className={styles.card}>
          <span className={styles.name}>{name} {lastName}</span>
          <span className={styles.email}>{email}</span>
          <span
            className={styles.accounts}
            onClick={onClickEdit}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            ref={ref}
          >
            {teams.length > 1 && (`(${teams.length})`)} {accountNames}
            {showTooltip && (
              <Tooltip className={styles.tooltip}>
                {accountNames}
              </Tooltip>
            )}
          </span>
          {showOptions && (
            <div className={cn(styles.buttonsContainer, styles.filler)}>
              <button
                className={styles.delete}
                onClick={onClickDelete}
                disabled={disabled}
              >
                Delete
              </button>
            </div>
          )}
          {!showOptions && <span className={styles.filler}>&nbsp;</span>}
        </div>
        {openFormId === id &&
          (
          <EditEditorForm
            id={id}
            accounts={editorAccounts}
            onCancel={onCancelEdit}
            disabled={disabled}
          />
          )}
      </div>
      {modalOpen && (
        <DeleteEditor
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          editorId={id}
        />
      )}
    </>
  );
};

EditorCard.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  teams: arrayOf(shape({
    account: shape({
      id: number,
      brandName: string,
    }),
  })),
  openFormId: oneOfType([string, number]),
  setOpenFormId: func.isRequired,
  disabled: bool.isRequired,
};

export { EditorCard };
