import React, { useRef, useCallback, useMemo } from 'react';
import { func, shape, string, number } from 'prop-types';
import WebFont from 'webfontloader';
import { useDispatch, useSelector } from 'react-redux';

import { addUploadedFontToStylesheet, getFontsRegisteredInStylesheet } from 'src/utils/fontHelpers';
import { useBrandLibraryFonts, useTooltip } from 'src/hooks';
import { GOOGLE_UPLOAD, LOCAL_UPLOAD, SYSTEM_UPLOAD } from 'src/constants/uploadFile';
import { Tooltip } from 'src/common/tooltip';
import {
  FONT_FAMILY_OPTIONS,
  REGULAR,
} from 'src/constants/fonts';
import { setFontInMemory } from 'src/actions/projectMemoryActions';
import { Select } from 'src/common/select';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import styles from './FontFamilySelectControl.module.scss';

const FontFamilySelectControl = ({
  element,
  updateElementRequest,
  groupClassName,
  scrollPosition,
}) => {
  const dispatch = useDispatch();

  const { uploadedFonts } = useSelector(({ brandLibrary }) => ({
    uploadedFonts: brandLibrary.uploadedFonts,
  }));

  const { availableFonts } = useBrandLibraryFonts();

  const { ref: containerRef, showTooltip } = useTooltip();
  const fontFamilyRef = useRef();

  const actualFontFamily = useMemo(() => {
    const { uploadType, fontFamily } = element;
    if (!uploadType || uploadType === GOOGLE_UPLOAD || uploadType === SYSTEM_UPLOAD) {
      return FONT_FAMILY_OPTIONS.find(elem => elem.value === fontFamily);
    }
    return uploadedFonts.find(elem => elem.value === fontFamily) || { value: '', styles: [] };
  }, [element, uploadedFonts]);

  const onChangeFontFamily = ({ value, font, uploadType, name, url }) => {
    let newFont;
    if (uploadType === GOOGLE_UPLOAD) {
      WebFont.load({
        google: { families: [font] },
        active: () => {
          newFont = {
            fontFamily: value,
            fontStyle: REGULAR.value,
            uploadType,
            fontFamilyUrl: '',
          };
          updateElementRequest(newFont);
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    } else if (uploadType === LOCAL_UPLOAD) {
      const uploadedFont = { name, url };
      addUploadedFontToStylesheet(uploadedFont);
      const fontsRegistered = getFontsRegisteredInStylesheet();
      WebFont.load({
        custom: { families: fontsRegistered },
        active: () => {
          newFont = {
            fontFamily: value,
            fontStyle: REGULAR.value,
            uploadType,
            fontFamilyUrl: url,
          };
          updateElementRequest(newFont);
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    } else if (uploadType === SYSTEM_UPLOAD) {
      const systemFont = { name: value, url: font };
      addUploadedFontToStylesheet(systemFont);
      const fontsRegistered = getFontsRegisteredInStylesheet();
      WebFont.load({
        custom: { families: fontsRegistered },
        active: () => {
          newFont = {
            fontFamily: value,
            fontStyle: REGULAR.value,
            uploadType,
            fontFamilyUrl: font,
          };
          updateElementRequest(newFont);
          dispatch(setFontInMemory({ font: newFont }));
        },
      });
    }
  };

  const onChangeFontStyles = ({ value }) => {
    updateElementRequest({ fontStyle: value });
    const newFont = {
      fontFamily: element.fontFamily,
      fontStyle: value,
      uploadType: element.uploadType,
      fontFamilyUrl: element.fontFamilyUrl,
    };
    dispatch(setFontInMemory({ font: newFont }));
  };

  const containerId = 'fontFamilySelect';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  const selectStyles = useCallback((leftOffset = 0) => ({
    control: base => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 0,
    }),
    singleValue: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    input: base => ({ ...base, color: 'white', fontSize: '1.4rem' }),
    menu: base => (
      {
        ...base,
        color: '#555555',
        fontSize: '1.4rem',
        width: '16rem',
        left: optionsSize.left + leftOffset,
      }),
    option: base => ({
      ...base,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    group: (base, state) => {
      if (!state.label) {
        return base;
      }
      return { ...base, borderBottom: '1px solid #c2c2c2' };
    },
  }), [optionsSize.left]);

  const tooltipStyles = useCallback(() => {
    const paddingLeft = 45;
    return (
      {
        left: optionsSize.left + paddingLeft,
        right: 'unset',
        transform: 'unset',
      }
    );
  }, [optionsSize.left]);

  return (
    <>
      <div className={groupClassName} ref={containerRef} id={containerId}>
        <Select
          options={availableFonts}
          value={actualFontFamily}
          onChange={onChangeFontFamily}
          className={styles.fontFamily}
          selectStyles={selectStyles()}
          ref={fontFamilyRef}
        />
        {showTooltip && fontFamilyRef?.current?.state.isFocused === false && (
          <Tooltip style={tooltipStyles()}>Fonts</Tooltip>
        )}
      </div>
      <div className={groupClassName}>
        <Select
          options={actualFontFamily?.styles}
          value={actualFontFamily?.styles.find(st => st.value === element.fontStyle) ||
            { value: '' }}
          onChange={onChangeFontStyles}
          className={styles.fontStyle}
          selectStyles={selectStyles(160)}
        />
      </div>
    </>
  );
};

FontFamilySelectControl.propTypes = {
  updateElementRequest: func.isRequired,
  element: shape({ fontStyle: string, fontFamily: string, uploadType: string }),
  groupClassName: string.isRequired,
  scrollPosition: shape({
    x: number,
  }),
};

export { FontFamilySelectControl };
