/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './Terms.module.scss';

const Terms = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>Stagger Terms of Service</h1>
    <h2 className={styles.subtitle}>Last Updated: July 7, 2020 – source <Link to={routesPaths.terms}> https://beta.stagger.com/terms-of-service</Link></h2>
    <h2 className={styles.header}> STAGGER INC. TERMS OF USE </h2>
    <h2 className={styles.header}> PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES OFFERED BY Stagger Inc., A DELAWARE CORPORATION. THESE TERMS OF USE SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE WEBSITE AT https://beta.stagger.com (THE &quot;SITE&quot;) AND THE SERVICES, FEATURES, CONTENT, APPLICATIONS OR WIDGETS OFFERED BY Stagger (COLLECTIVELY WITH THE SITE, THE &quot;SERVICE&quot;).</h2>
    <p className={styles.content} />
    <h2 className={styles.header}>Acceptance of Terms.</h2>
    <p className={styles.content}>By registering for and/or using the Service in any manner, including but not limited to visiting or browsing the Site, you agree to all of the terms and conditions contained herein (&quot;Terms of Use&quot;), which also incorporate Stagger&apos;s Privacy Policy (available at https://stagger.com/legal/privacy-policy.html) and all other operating rules, policies and procedures that may be published from time to time on the Site by Stagger, each of which is incorporated by reference and each of which may be updated by Stagger from time to time without notice to you in accordance with the terms set out under the &quot;Modification of Terms of Use&quot; section below. In addition, some services offered through the Service may be subject to additional terms and conditions specified by Stagger from time to time; your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms of Use by this reference. These Terms of Use apply to all users of the Service, including, without limitation, users who are contributors of content, information, and other materials or services on the Site, individual users of the Service, venues that access the Service, and users that have a page on the Service.</p>
    <h2 className={styles.header}>Website Operation and Use Within the United States Only</h2>
    <p className={styles.content}>By accessing and using the Website and the Service, you acknowledge and agree that Stagger controls and operates all parts of the Website and the Service from its offices in the United States and that the Website and the Service, and the information contained on the Website and elsewhere in the Service, are intended for use by End Users located in the United States.  Stagger makes no representation that the Website and the Service, or the information contained on the Website or elsewhere in the Service, are appropriate or will be available for use in other locations.  All material and content found on or accessible through the Website is solely directed to individuals, companies, or other entities located in the United States.  BY ACCESSING THE WEBSITE AND/OR THE SERVICE AND/OR BY SUBMITTING INFORMATION TO US VIA THE WEBSITE AND/OR ELSEWHERE IN THE SERVICE, YOU SPECIFICALLY WARRANT THAT YOU ARE NOT LOCATED OR OPERATING OUTSIDE OF THE UNITED STATES.  IF YOU ARE OPERATING OUTSIDE OF THE UNITED STATES, INCLUDING, WITHOUT LIMITATION, OPERATING IN, OR LOCATED WITHIN, THE EUROPEAN UNION OR ANY OTHER NON-U.S. TERRITORY, THEN YOU SHOULD NOT USE THE WEBSITE OR THE SERVICE.</p>
    <h2 className={styles.header}>Access.</h2>
    <p className={styles.content}>Subject to these Terms of Use, Stagger may offer to provide the Services, as described more fully on the Site, and which are selected by you, solely for your own use, and not for the use or benefit of any third party. Services shall include, but not be limited to, any services Stagger performs for you, any applications or widgets offered by Stagger that you download from the Site or, subject to the terms set out under the &quot;Third party Sites and Services&quot; section below, from third party application stores (e.g., iTunes® store, Blackberry App World™, or Google Apps Marketplace) authorized by Stagger, as well as the offering of any materials displayed or performed on or through the Services (including Content (as defined below)).</p>
    <h2 className={styles.header}>Registration and Eligibility.</h2>
    <p className={styles.content}>You may browse the Site and view Content without registering, but as a condition to using certain aspects of the Service, you are required to register with Stagger and represent, warrant and covenant that you provide Stagger with accurate and complete registration information (including, but not limited to a user name (&quot;User Name&quot;), e-mail address and/or mobile telephone number and a password you will use to access the Service) and to keep your registration information accurate and up-to-date. Failure to do so shall constitute a breach of these Terms of Use, which may result in immediate termination of your Stagger account. We recommend, but do not require, that you use your own name as your User Name so your friends can recognize you more easily.</p>
    <p className={styles.content}>If you use a mobile device, please be aware that your carrier&apos;s normal rates and fees, such as text messaging and data charges, will still apply. In the event you change or deactivate your mobile telephone number, you agree that you will update your account information on the Services within 48 hours to ensure that your messages are not sent to the person who acquires your old number.</p>
    <h2 className={styles.header}>THE INFORMATION WE COLLECT</h2>
    <p className={styles.content}>When you visit the website or use one of our mobile applications, we collect your IP address, and standard web log information, such as your browser type and the pages you accessed on our website. We also may collect certain geo-location information. If you do not agree to our collection of this information, you may not be able to use our Service.</p>
    <p className={styles.content}>If you open a Stagger account, we collect the following information from you:</p>
    <ul>
      <li className={styles.content}>Account Information - text-enabled cellular/wireless telephone number, machine or mobile device ID and other similar information.</li>
      <li className={styles.content}>Device Information - information about you  from your mobile device such as your device type, machine or mobile device identification number, geo-location, time zone, language setting, browser type, and IP address, You may manage certain contact preferences and notifications in your account settings.</li>
      <li className={styles.content}>We are committed to providing a safe, secure and all around great Service. Therefore, before permitting you to use our Service, we may require additional information from you we can use to verify your identity, address or other information or to manage risk and compliance throughout our relationship. We may also obtain information about you from third parties such as identity verification, fraud prevention and similar services.</li>
      <li className={styles.content}>When you are using the Stagger Services, we collect information about your account transactions and we may collect information about your computer or other access device for fraud prevention and other similar purposes.</li>
      <li className={styles.content}>Finally, we may collect additional information from or about you in other ways not specifically described here. For example, we may collect information related to your contact with our customer support team or store results when you respond to a survey.</li>
    </ul>
    <h2 className={styles.header}>INFORMATION FROM CHILDREN</h2>
    <p className={styles.content}>The website and the Stagger Services are not directed to children under the age of 13. If we obtain actual knowledge that we have collected personal information from a child under the age of 13, we will promptly delete it, unless we are legally obligated to retain such data. Contact us if you believe that we have mistakenly or unintentionally collected information from a child under the age of 13.</p>
    <h2 className={styles.header}>Content.</h2>
    <p className={styles.content}>All Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content. Stagger cannot guarantee the authenticity of any Content or data which users may provide about themselves. You acknowledge that all Content accessed by you using the Service is at your own risk and you will be solely responsible and liable for any damage or loss to you or any other party resulting therefrom. For purposes of these Terms of Use, the term &quot;Content&quot; includes, without limitation, any location information, &quot;shouts&quot;, videos, audio clips, comments, information, data, text, photographs, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible by Stagger on or through the Service. Content added, created, uploaded, submitted, distributed, posted or otherwise obtained through the Service by users, including Content that is added to the Service in connection with users linking their accounts to third party websites and services, is collectively referred to as, &quot;User Submissions.&quot;</p>
    <p className={styles.content}>Stagger Content.</p>
    <p className={styles.content}>The Service contains Content specifically provided by Stagger (“Stagger Content”) or its partners and such Content is protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights and laws, as applicable. You shall abide by and maintain all copyright notices, information, and restrictions contained in any Content accessed through the Service.</p>
    <p className={styles.content}>Subject to these Terms of Use, Stagger grants each user of the Site and/or Service a worldwide, non-exclusive, non-sublicensable and non-transferable license to use, modify and reproduce the Stagger Content, solely for personal, non-commercial use. Use, reproduction, modification, distribution or storage of any Content for other than personal, non-commercial use is expressly prohibited without prior written permission from Stagger, or from the copyright holder identified in such Content&apos;s copyright notice, as applicable. You shall not sell, license, rent, or otherwise use or exploit any Content for commercial (whether or not for profit) use or in any way that violates any third party right.</p>
    <h2 className={styles.header}>HOW WE USE COOKIES</h2>
    <p className={styles.content}>When you visit our website, use our mobile application or the Stagger Services, or visit a third-party website for which we provide online services, we and certain business partners and vendors may use cookies and other tracking technologies (collectively, &quot;Cookies&quot;). We use Cookies to recognize you as a customer; customize Stagger Services, other content and advertising; measure the effectiveness of promotions; perform a wide range of analytics; mitigate risk and prevent potential fraud; and to promote trust and safety across our sites and Stagger Services.</p>
    <p className={styles.content}>Certain aspects and features of the Stagger Services and our website are only available through the use of Cookies, so if you choose to disable or decline Cookies, your use of the websites and Stagger Services may be limited or not possible.</p>
    <p className={styles.content}>Do Not Track: Do Not Track (&quot;DNT&quot;) is an optional browser setting that allows you to express your preferences regarding tracking by advertisers and other third-parties. We do not respond to DNT signals.</p>
    <h2 className={styles.header}>HOW WE PROTECT & STORE PERSONAL INFORMATION</h2>
    <p className={styles.content}>We store and process your personal information using third party servers located in data centers in the United States. This information is protected by physical, electronic and procedural safeguards in compliance with applicable US federal and state regulations. We also use computer safeguards such as firewalls and data encryption, we enforce physical access controls to our office and files, and we authorize access to personal information only for those employees who require it to fulfill their job responsibilities.</p>
    <p className={styles.content}>We strive to ensure security on our systems. Despite our efforts, we cannot guarantee that personal information may not be accessed, disclosed, altered or destroyed by breach of our administrative, managerial and technical safeguards. Therefore, we urge you to take adequate precautions to protect your personal data as well, including never sharing your Stagger password with anyone.</p>
    <p className={styles.content}>If Stagger learns of a systems security breach, we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Stagger Services, you agree that Stagger may communicate with you electronically. Stagger may post a notice on the website or mobile application if a security breach occurs. We may also send an email to you at the email address you have provided to us. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice of a security breach), please email us at app@stagger.it</p>
    <h2 className={styles.header}>HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h2>
    <p className={styles.content}>Our primary purpose in collecting personal information is to provide you with a safe, smooth, efficient, fun and customized experience. We may use your personal information to:</p>
    <ul>
      <li className={styles.content}>provide the services and customer support you request;</li>
      <li className={styles.content}>process transactions and send notices about your transactions or your network activity;</li>
      <li className={styles.content}>resolve disputes, collect fees, and troubleshoot problems;</li>
      <li className={styles.content}>prevent potentially fraudulent, prohibited or illegal activities, and enforce our User Agreement;</li>
      <li className={styles.content}>create an account connection between your Stagger account and a third-party account or platform;</li>
      <li className={styles.content}>customize, personalize, measure, and improve our services and the content and layout of our website;</li>
      <li className={styles.content}>send you updates about new products and services that we are offering to customers;</li>
      <li className={styles.content}>compare information for accuracy and verify it with third parties; and</li>
      <li className={styles.content}>perform other duties as required by law.</li>
    </ul>
    <h2 className={styles.header}>HOW WE SHARE PERSONAL INFORMATION WITH OTHER PARTIES</h2>
    <p className={styles.content}>Stagger does not share your personal information with third parties for their promotional or marketing purposes.</p>
    <p className={styles.content}>Some personal information is public information (including your Stagger username, Stagger profile photo, Stagger profile first and last name, month, and year of Stagger account creation), and may be seen by anyone on the internet, whether or not they have a Stagger account. Public information may also be seen, accessed, reshared or downloaded through Stagger&apos;s APIs or third-party services that integrate with our products. We may share your personal information with:</p>
    <p className={styles.content}>Companies that Stagger plans to merge with or be acquired by or, in the event of any bankruptcy, a bankruptcy estate. Should such a combination occur, we will require that the new combined entity follow this privacy policy with respect to your personal information. If your personal information could be used contrary to this policy, you will receive prior notice and the opportunity to communicate preferences you may have, if applicable.</p>
    <p className={styles.content}>Law enforcement, government officials, or other third parties if Stagger is compelled to do so by a subpoena, court order or similar legal procedure, when it is necessary to do so to comply with law, or where the disclosure of personal information is reasonably necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of the Stagger User Agreement, or as otherwise required by law.</p>
    <p className={styles.content}>Third party service providers who assist us in providing services to you or who provide fraud detection or similar services on our or any vendor’s behalf.</p>
    <p className={styles.content}>The other Stagger user participating in the transaction and, depending on the privacy setting of each Stagger account transaction, your Stagger friends and the Stagger friends of the other user participating in the transaction, or the public, through the Stagger feed on our website and mobile application and elsewhere on the internet.</p>
    <p className={styles.content}>Service providers under contract who help with parts of our business operations (for example, fraud prevention, payment processing, or technology services). Our contracts dictate that these service providers only use your information in connection with the services they perform for us and not for their own benefit.</p>
    <p className={styles.content}>Other third parties with your consent or at your direction to do so, including if you authorize an account connection with a third-party account or platform.</p>
    <p className={styles.content}>For the purposes of this privacy policy, an &quot;account connection&quot; with such a third party is a connection you authorize or enable between your Stagger account and a non-Stagger account, payment instrument, or platform that you lawfully control or own. When you authorize such a connection, Stagger and the third-party will exchange your personal information and other information directly.</p>
    <p className={styles.content}>If you choose to use Siri or iMessage to send payments via Stagger or to otherwise use the Stagger Services, such use is subject to Apple’s terms and conditions for use of iMessage and/or Siri, as applicable, and the terms of the Stagger User Agreement. By using Siri or iMessage, you authorize us to share some of your Stagger account data (including your friends list, list of persons you have transacted with most recently and most frequently, transaction instructions, and transaction notes) with Apple to allow it to facilitate transaction requests made through Siri or iMessage on iOS. Data shared with Apple will be used pursuant to Apple’s then-current user agreements and privacy policies. You can grant or revoke Apple’s access to Stagger on iOS at any time under the “Siri” or “iMessage” settings on your iPhone.</p>
    <p className={styles.content}>Stagger does not send your personal information to third-party social networks unless you have specifically requested or authorized us to do so. When you broadcast information to such third-party social networks, such information is no longer under the control of Stagger and is subject to the terms of use and privacy policies of such third parties.</p>
    <h2 className={styles.header}>User Submissions.</h2>
    <p className={styles.content}>We may use your User Submissions in a number of different ways in connection with the Site, Service and Stagger’s business as Stagger may determine in its sole discretion, including but not limited to, publicly displaying it, reformatting it, incorporating it into marketing materials, advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing other users to do the same in connection with their own websites, media platforms, and applications (“Third Party Media”). By submitting User Submissions on the Site or otherwise through the Service, you hereby do and shall grant Stagger a worldwide, non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use, copy, edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with the Site, the Service and Stagger&apos;s (and its successors and assigns&apos;) business, including without limitation for promoting and redistributing part or all of the Site (and derivative works thereof) or the Service in any media formats and through any media channels (including, without limitation, third party websites and feeds). You also hereby do and shall grant each user of the Site and/or the Service, including Third Party Media, a non-exclusive license to access your User Submissions through the Site and the Service, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions in connection with their use of the Site, Service and Third Party Media. For clarity, the foregoing license grant to Stagger does not affect your other ownership or license rights in your User Submission(s), including the right to grant additional licenses to the material in your User Submission(s), unless otherwise agreed in writing with Stagger.</p>
    <p className={styles.content}>You represent and warrant that you have all rights to grant such license to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights.</p>
    <p className={styles.content}>You understand that all information publicly posted or privately transmitted through the Site is the sole responsibility of the person from which such Content originated; that Stagger will not be liable for any errors or omissions in any Content; and that Stagger cannot guarantee the identity of any other users with whom you may interact in the course of using the Service.</p>
    <p className={styles.content}>When you delete your User Submissions, they will be removed from the Service. However, you understand that any removed User Submissions may persist in backup copies for a reasonable period of time (but following removal will not be shared with others) or may remain with users who have previously accessed or downloaded your User Submissions.</p>
    <h2 className={styles.header}>Rules and Conduct.</h2>
    <p className={styles.content}>As a condition of use, you promise not to use the Service for any purpose that is prohibited by these Terms of Use. You are responsible for all of your activity in connection with the Service. Additionally, you shall abide by all applicable local, state, national and international laws and regulations and, if you represent a business, any advertising, marketing, privacy, or other self-regulatory code(s) applicable to your industry.</p>
    <p className={styles.content}>By way of example, and not as a limitation, you shall not (and shall not permit any third party to) either (a)take any action or (b)upload, download, post, submit or otherwise distribute or facilitate distribution of any Content on or through the Service, including without limitation any User Submission, that:</p>
    <ul>
      <li className={styles.content}>infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity or violates any law or contractual duty;</li>
      <li className={styles.content}>you know is false, misleading, untruthful or inaccurate;</li>
      <li className={styles.content}>is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another&apos;s privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts sexual activity, promotes bigotry, discrimination or violence, or is otherwise inappropriate as determined by Stagger in its sole discretion;</li>
      <li className={styles.content}>constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (&quot;spamming&quot;);</li>
      <li className={styles.content}>other than appropriate use of an Add-to Link, involves commercial activities (whether or not for profit) and/or sales without Stagger&apos;s prior written consent such as contests, sweepstakes, barter, advertising, or pyramid schemes;</li>
      <li className={styles.content}>contains software viruses or any other computer codes, files, worms, logic bombs or programs that are designed or intended to disrupt, disable, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password or other information of Stagger or any third party;</li>
      <li className={styles.content}>impersonates any person or entity, including any employee or representative of Stagger;</li>
      <li className={styles.content}>includes anyone&apos;s identification documents or sensitive financial information; or</li>
      <li className={styles.content}>breaches the Privacy Policy and/or any of the other policies and rules incorporated herein.</li>
    </ul>
    <p className={styles.content}>If the User Submission includes a photograph, Stagger has a zero-tolerance policy against child pornography, and will terminate and report to the appropriate authorities any user who publishes or distributes child pornography.</p>
    <p className={styles.content}>You shall not (directly or indirectly):</p>
    <ul>
      <li className={styles.content}>take any action that imposes or may impose (as determined by Stagger in its sole discretion) an unreasonable or disproportionately large load on Stagger&apos;s (or its third party providers&apos;) infrastructure;</li>
      <li className={styles.content}>interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service;</li>
      <li className={styles.content}>bypass any measures Stagger may use to prevent or restrict access to the Service (or other accounts, computer systems or networks connected to the Service);</li>
      <li className={styles.content}>run any form of auto-responder or &quot;spam&quot; on the Service;</li>
      <li className={styles.content}>use manual or automated software, devices, or other processes to &quot;crawl&quot; or &quot;spider&quot; any page of the Site;</li>
      <li className={styles.content}>harvest or scrape any Content from the Service;</li>
      <li className={styles.content}>modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit the Service or Site Content (other than your User Submissions), except as expressly authorized by Stagger;</li>
      <li className={styles.content}>decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service (including without limitation any application or widget), except to the limited extent applicable laws specifically prohibit such restriction;</li>
      <li className={styles.content}>copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder;</li>
      <li className={styles.content}>otherwise take any action in violation of Stagger&apos;s guidelines and policies.</li>
    </ul>
    <p className={styles.content}>Stagger does not guarantee that any Content or User Submissions (as defined above) will be made available on the Site or through the Service. Stagger has no obligation to monitor the Site, Service, Content, or User Submissions. However, Stagger reserves the right to (i) remove, suspend, edit or modify any Content in its sole discretion, including without limitation any User Submissions at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content or if Stagger is concerned that you may have violated these Terms of Use), or for no reason at all and (ii) to remove, suspend or block any User Submissions from the Service. Stagger also reserves the right to access, read, preserve, and disclose any information as Stagger reasonably believes is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce these Terms of Use, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of Stagger, its users and the public.</p>
    <h2 className={styles.header}>Card Services</h2>
    <p className={styles.content}>Certain features of the Service and/or certain services and products made available to you through the Service may allow you to purchase services or to redeem an offer or special using your credit card (together with “Card Services”). Stagger uses a third-party payment processor (the “Payment Processor”) to link your credit card account to the Services (your “Card Account”) for use of the Card Services. The processing of payments or credits, as applicable, in connection with the Card Services will be subject to the terms, conditions and privacy policies of the Payment Processor and your credit card issuer in addition to this Agreement. Stagger is not responsible for any error that occurs during any offer or special redemption process, including any error by the Payment Processor. In connection with your use of the Card Services, Stagger will obtain certain transaction details, which Stagger will use solely in accordance with our Privacy Policy.</p>
    <h2 className={styles.header}>Feedback and Unsolicited Idea Submission Policy</h2>
    <p className={styles.content}>For information on Stagger’s Feedback and Unsolicited Idea Submission Policy, please go here.</p>
    <h2 className={styles.header}>Special Terms for &apos;Add-to-Stagger&apos; Link</h2>
    <p className={styles.content}>If you include Stagger&apos;s &apos;Add-to-Stagger&apos; Link (&quot;Add-to Link&quot;) on your website, the following additional terms apply to you:</p>
    <p className={styles.content}>Subject to your continuous compliance with these Terms of Use, Stagger hereby grants you a non-exclusive, non-transferable, non-sublicensable, revocable license to use and display the Add-to Link on a website for which you have proper authorization (&quot;your website&quot;) so that users can post links or content from your website on the Service. You grant us permission to use and allow others to use such links and content on the Service.</p>
    <p className={styles.content}>Your license is subject to the following restrictions on use: (a) you may not use the Add-to Link to facilitate your sale of access to the Service or any information therein without Stagger&apos;s prior written approval; (b) you may not obscure or disable any element of the Add-to Link; (c) your website title and other trademarks and logos must appear at least as prominent as Stagger&apos;s trademarks and logos in the Add-to Link; (d) you may not display the Add-to Link in any manner that implies partnership with, affiliation with, sponsorship, or endorsement by Stagger; (e) you may not display the Add-to Link on any site that disparages Stagger or its products or services, infringes any Stagger&apos;s intellectual property or other rights, or violates any applicable law; (f) you may not place the Add-to Link on sites that include content that would violate these Terms of Use if posted on the Service; (g) you may not display the Add-to Link in a manner that does not permit successful linking to, redirection to the Service; and (h) you may not insert any intermediate page, splash page or other content between the Add-to Link and the Service.</p>
    <h2 className={styles.header}>Special Terms for Pages.</h2>
    <p className={styles.content}>If you have a &quot;Page&quot; on the Service, you also agree to the following:</p>
    <ul>
      <li className={styles.content}>Pages are special profiles that may only be used to promote a business or other commercial, political, or charitable organization or endeavor (including non-profit organizations, political campaigns, bands, and celebrities).</li>
      <li className={styles.content}>You may only administer a Page if you are an authorized representative of the subject of the Page.</li>
      <li className={styles.content}>All Content posted on the Pages will be made available to all users of the Services.</li>
      <li className={styles.content}>You may not display any Content on your Page that is an ad or could be construed as an ad.</li>
      <li className={styles.content}>You may not establish terms beyond those set forth in these Terms of Use to govern the use of the Page you administer.</li>
      <li className={styles.content}>Your Page will not be used primarily or substantially to promote or advertise alcohol or tobacco products, firearms, or other products or services that may not be lawfully purchased or used by minors.</li>
      <li className={styles.content}>You will not direct your Page, or any Content on your Page, to children under the age of 13.</li>
      <li className={styles.content}>If you collect information about users, you will only use such information for your internal business purposes in connection with your use of the Service and in accordance with Stagger&apos;s Privacy Policy.</li>
      <li className={styles.content}>You will not use deceptive practices to get users to &quot;like&quot; your Page.</li>
      <li className={styles.content}>You are responsible for ensuring that your Page, including any Content you post on your Page, shall comply with all applicable laws, rules and regulations and these Terms of Use, and any advertising, marketing, privacy, or other self-regulatory code(s) applicable to your industry.</li>
    </ul>
    <h2 className={styles.header}>Third Party Sites and Services.</h2>
    <p className={styles.content}>The Service may permit you to link to other websites, services or resources on the Internet, and other websites, services or resources may contain links to the Site. When you access third party websites, you do so at your own risk. These other websites are not under Stagger&apos;s control, and you acknowledge that Stagger is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply endorsement by Stagger or any association with its operators. You further acknowledge and agree that Stagger shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such Content, goods or services available on or through any such website or resource.</p>
    <h2 className={styles.header}>Termination.</h2>
    <p className={styles.content}>Stagger may terminate your access to all or any part of the Service and/or Add-to Link at any time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of all information associated with your membership. If you wish to terminate your account, you may do so by following the instructions on the Site. Any fees paid hereunder are non-refundable. All provisions of these Terms of Use which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
    <p className={styles.content}>Warranty Disclaimer.</p>
    <p className={styles.content}>Save to the extent required by law, Stagger has no special relationship with or fiduciary duty to you. You acknowledge that Stagger has no control over, and no duty to take any action regarding: which users gain access to the Service; what Content you access via the Service; what effects the Content may have on you; how you may interpret or use the Content; or what actions you may take as a result of having been exposed to the Content.</p>
    <p className={styles.content}>You release Stagger from all liability for you having acquired or not acquired Content through the Service. The Service may contain, or direct you to websites containing, information that some people may find offensive or inappropriate. Stagger makes no representations concerning any Content contained in or accessed through the Service, and Stagger will not be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Service.</p>
    <p className={styles.content}>You release us from all liability relating to your connections and relationships with other users. You understand that we do not, in any way, screen users, nor do we inquire into the backgrounds of users or attempt to verify their backgrounds or statements. We make no representations or warranties as to the conduct of users or the veracity of any information users provide. In no event shall we be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the Services, including, without limitation, bodily injury, emotional distress, and any damages resulting in any way from communications or meetings with users or persons you may otherwise meet through the Services. As such, you agree to take reasonable precautions and exercise the utmost personal care in all interactions with any individual you come into contact with through the Services, particularly if you decide to meet such individuals in person. For example, you should not, under any circumstances, provide your financial information (e.g., credit card or bank account numbers) to other individuals.</p>
    <p className={styles.content}>THE SITE, SERVICE, CONTENT AND ADD-TO LINK ARE PROVIDED &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND ARE PROVIDED WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE EXTENT REQUIRED BY LAW.</p>
    <p className={styles.content}>Stagger, AND ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (A) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE WEBSITE, SERVICE, CONTENT AND ADD-TO LINK IS SOLELY AT YOUR OWN RISK. SOME STATES / COUNTRIES DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
    <h2 className={styles.header}>Indemnification.</h2>
    <p className={styles.content}>You shall defend, indemnify, and hold harmless Stagger, its affiliates and each of its and its affiliates&apos; employees, contractors, directors, suppliers and representatives from all losses, costs, actions, claims, damages, expenses (including reasonable legal costs) or liabilities, that arise from or relate to your use or misuse of, or access to, the Site, Service, Content, Add-to Link or otherwise from your User Submissions, violation of these Terms of Use, or infringement by you, or any third party using the your account, of any intellectual property or other right of any person or entity (save to the extent that a court of competent jurisdiction holds that such claim arose due to an act or omission of Stagger). Stagger reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Stagger in asserting any available defenses.</p>
    <h2 className={styles.header}>Limitation of Liability.</h2>
    <p className={styles.content}>ALL LIABILITY OF Stagger, ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS HOWSOEVER ARISING FOR ANY LOSS SUFFERED AS A RESULT OF YOUR USE THE SITE, SERVICE, CONTENT, USER SUBMISSIONS OR ADD-TO LINK IS EXPRESSLY EXCLUDED TO THE FULLEST EXTENT PERMITTED BY LAW, SAVE THAT, IF A COURT OF COMPETENT JURISDICTION DETERMINES THAT LIABILITY OF Stagger, ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS (AS APPLICABLE) HAS ARISEN, THE TOTAL OF SUCH LIABILITY SHALL BE LIMITED IN AGGREGATE TO ONE HUNDRED US DOLLARS ($100).</p>
    <p className={styles.content}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Stagger, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY OR OTHERWISE (AND WHETHER OR NOT Stagger, ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS HAD PRIOR KNOWLEDGE OF THE CIRCUMSTANCES GIVING RISE TO SUCH LOSS OR DAMAGE) WITH RESPECT TO THE SITE, SERVICE, CONTENT, USER SUBMISSIONS OR ADD-TO LINK FOR:</p>
    <ul>
      <li className={styles.content}>INDIRECT OR CONSEQUENTIAL LOSSES OR DAMAGES;</li>
      <li className={styles.content}>LOSS OF REVENUE;</li>
      <li className={styles.content}>LOSS OF GOODWILL;</li>
      <li className={styles.content}>LOSS OF DATA;</li>
      <li className={styles.content}>LOSS OF ANTICIPATED SAVINGS;</li>
      <li className={styles.content}>WASTED EXPENDITURE; OR</li>
      <li className={styles.content}>COST OF PROCUREMENT OF SUBSTITUE GOODS OR SERVICES.</li>
      <li className={styles.content}>NOTHING IN THESE TERMS OF USE SHALL BE DEEMED TO EXCLUDE OR LIMIT YOUR LIABILITY IN RESPECT OF ANY INDEMNITY GIVEN BY YOU UNDER THESE TERMS OF USE. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, Stagger’S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</li>
    </ul>
    <h2 className={styles.header}>Governing Law.</h2>
    <p className={styles.content}>A printed version of these Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. You and Stagger agree that any cause of action arising out of or related to the Service must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.</p>
    <p className={styles.content}>These Terms of Use shall be governed by and construed in accordance with the laws of the State of Texas, excluding its conflicts of law rules, and the United States of America. For all purposes of these Terms of Use, the parties consent to exclusive jurisdiction and venue in the United States Federal Courts or state courts located in the Western District of Texas. Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of these Terms of Use, including without limitation, this section. If you are accepting these Terms of Use on behalf of a United States federal government entity that is legally unable to accept the controlling law, jurisdiction or venue clauses above, then those clauses do not apply to you but instead these Terms and any action related thereto will be will be governed by the laws of the United States of America (without reference to conflict of laws) and, in the absence of federal law and to the extent permitted under federal law, the laws of the State of Texas (excluding choice of law).</p>
    <h2 className={styles.header}>Government Use.</h2>
    <p className={styles.content}>The use, duplication, reproduction, release, modification, disclosure or transfer of the software, application or widget is restricted in accordance with the Federal Acquisition Regulations as applied to civilian agencies and the Defense Federal Acquisition Regulation Supplement as applied to military agencies. Any such software, application or widget is a &quot;commercial item,&quot; &quot;commercial computer software&quot; and &quot;commercial computer software documentation.&quot; In accordance with such provisions, any use of any such software, application or widget shall be governed solely by these Terms of Use.</p>
    <h2 className={styles.header}>Integration and Severability.</h2>
    <p className={styles.content}>These Terms of Use are the entire agreement between you and Stagger with respect to the Service and use of the Site, Service, Content, User Submissions or Add-to Link, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and Stagger with respect to the Site. If any provision of these Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms of Use will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future. In order for any waiver of compliance with these Terms of Use to be binding, Stagger must provide you with written notice of such waiver through one of its authorized representatives.</p>
    <h2 className={styles.header}>Modification of Terms of Use.</h2>
    <p className={styles.content}>Stagger reserves the right, at its sole discretion, to modify or replace any of these Terms of Use, or change, suspend, or discontinue the Service (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Site or by sending you notice through the Service or via email. Stagger may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Service following the posting of any changes to these Terms of Use constitutes acceptance of those changes.</p>
    <h2 className={styles.header}>Miscellaneous.</h2>
    <p className={styles.content}>Stagger shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Stagger&apos;s reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation (including &quot;line-noise&quot; interference). These Terms of Use are personal to you, and are not assignable, transferable or sublicensable by you except with Stagger&apos;s prior written consent. Stagger may assign, transfer or delegate any of its rights and obligations hereunder without consent. No agency, partnership, joint venture, or employment relationship is created as a result of these Terms of Use and neither party has any authority of any kind to bind the other in any respect.</p>
    <p className={styles.content}>Unless otherwise specified in these Term of Use, all notices under these Terms of Use will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service.</p>
    <h2 className={styles.header}>Language.</h2>
    <p className={styles.content}>Where Stagger has provided you with a translation of the English language version of these Terms of Use, then you agree that the translation is provided for your convenience only and that the English language versions of the Terms will govern your relationship with Stagger.</p>
    <p className={styles.content}>If there is any contradiction between what the English language version of these Terms of Use says and what a translation says, then the English language version shall take precedence.</p>
    <p className={styles.content}>Effective Date: July 7th, 2020`;</p>

    <Link to={routesPaths.projects} className={styles.goBack}>
      Back to projects
    </Link>
  </div>
);

export { Terms };
