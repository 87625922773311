import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Input } from 'src/common/input';
import { Button } from 'src/common/button';
import { reset } from 'src/actions/statusActions';
import {
  editPersonalInformation,
  EDIT_PERSONAL_INFORMATION,
  CHECK_USERNAME,
} from 'src/actions/userActions';
import { useForm, useTextInputProps, useStatus, useSession } from 'src/hooks';
import { validateEditProfileInformation, validateEditable } from 'src/utils/constrains';
import { LOADING } from 'src/constants/status';
import { UsernameInput } from 'src/common/username-input';
import { ChangeUsernameModal } from 'src/pages/profile/personal-information/change-username-modal';
import styles from './PersonalInformation.module.scss';

const PersonalInformation = () => {
  const dispatch = useDispatch();

  const { status: statusEdit } = useStatus(EDIT_PERSONAL_INFORMATION);

  useEffect(() => () => {
    dispatch(reset(EDIT_PERSONAL_INFORMATION));
    dispatch(reset(CHECK_USERNAME));
  }, [dispatch]);

  const { user, hasPassword } = useSession();

  const initialValues = useMemo(() => {
    const result = {
      email: user?.email,
    };
    if (user?.name) {
      result.name = user.name;
    }
    if (user?.lastName) {
      result.lastName = user.lastName;
    }
    if (user?.username) {
      result.username = user.username;
    }
    return result;
  }, [user]);

  const [modalOpen, setModalOpen] = useState(false);

  const editPersonalInformationRequest = useCallback(async (values) => {
    const data = {
      id: user.id,
      ...values,
    };

    if (user.username === values.username) {
      delete data.username;
    }
    await dispatch(editPersonalInformation(data));
  }, [dispatch, user]);

  const updatePersonalInformationOrAsk = useCallback(async (values) => {
    if (user.username === values.username) {
      editPersonalInformationRequest(values);
    } else {
      setModalOpen(true);
    }
  }, [editPersonalInformationRequest, user.username]);

  const [usernameStatus, setUsernameStatus] = useState();

  const validation = useCallback((values) => (
    validateEditProfileInformation(values, usernameStatus)
  ), [usernameStatus]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
    setValues,
  } = useForm(updatePersonalInformationOrAsk, {
    initialValues,
    validate: validation,
  });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const handleCancelEdit = () => {
    setValues(initialValues);
  };

  const enableSaveButton = validateEditable(initialValues, values);

  return (
    <div className={cn(styles.container, {
      [styles.enableEditForm]: enableSaveButton,
    })}
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.headerInfo}>Personal Information</div>
        <div className={styles.inputNameContainer}>
          <Input
            name="name"
            label="First Name"
            placeholder={user.name}
            {...inputProps('name')}
            containerClassName={styles.inputSmallContainer}
          />
          <Input
            name="lastName"
            label="Last Name"
            placeholder={user.lastName}
            {...inputProps('lastName')}
            containerClassName={styles.inputSmallContainer}
          />
        </div>
        <Input
          name="email"
          label="Email"
          {...inputProps('email')}
          disabled
          containerClassName={styles.inputLargeContainer}
        />
        <UsernameInput
          inputProps={inputProps}
          usernameStatus={usernameStatus}
          setUsernameStatus={setUsernameStatus}
          className={styles.inputLargeContainer}
          initialValue={initialValues.username}
        />
        {hasPassword && (
          <>
            <div className={styles.divider} />
            <div className={styles.subtitleInfo}>Password</div>
            <Input
              name="oldPassword"
              type="password"
              label="Old Password"
              {...inputProps('oldPassword')}
              containerClassName={styles.inputSmallContainer}
              // The autoComplete is for the browser to avoid filling the fields here
              autoComplete="new-password"
            />
            <Input
              name="newPassword"
              type="password"
              label="New Password"
              {...inputProps('newPassword')}
              containerClassName={styles.inputSmallContainer}
            />
            <Input
              name="confirmPassword"
              type="password"
              {...inputProps('confirmPassword')}
              label="Confirm Password"
              containerClassName={styles.inputSmallContainer}
            />
          </>
        )}
        <div className={styles.containerSubmit}>
          <Button className={styles.buttonCancel} onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!enableSaveButton}
            loading={statusEdit === LOADING}
          >
            Save
          </Button>
        </div>
      </form>
      <ChangeUsernameModal
        hide={() => setModalOpen(false)}
        isShowing={modalOpen}
        update={() => editPersonalInformationRequest(values)}
      />
    </div>
  );
};

export { PersonalInformation };
