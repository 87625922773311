import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { UsernameInput } from 'src/common/username-input';
import { validateSignup } from 'src/utils/constrains';
import { signup, SIGNUP, CHECK_USERNAME } from 'src/actions/userActions';
import { reset } from 'src/actions/statusActions';
import { ERROR, LOADING } from 'src/constants/status';
import styles from './SignupForm.module.scss';

const SignupForm = ({ newUserInfo }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { status, error } = useStatus(SIGNUP);

  const signupRequest = useCallback(async (values) => {
    await dispatch(signup(values, history));
  }, [dispatch, history]);

  useEffect(() => () => {
    dispatch(reset(SIGNUP));
    dispatch(reset(CHECK_USERNAME));
  }, [dispatch]);

  const [usernameStatus, setUsernameStatus] = useState();

  const validation = useCallback((values) => (
    validateSignup(values, usernameStatus)
  ), [usernameStatus]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(signupRequest, { validate: validation });

  if (newUserInfo) {
    values.email = newUserInfo.email;
    values.name = newUserInfo.name;
    values.lastName = newUserInfo.lastName;
  }

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="email"
        type="email"
        label="Email"
        disabled={!!newUserInfo?.email}
        {...inputProps('email')}
      />
      <UsernameInput
        inputProps={inputProps}
        usernameStatus={usernameStatus}
        setUsernameStatus={setUsernameStatus}
      />
      <Input
        name="password"
        type="password"
        label="Password"
        {...inputProps('password')}
      />
      {status === ERROR && <span className={styles.error}>{error}</span>}
      <Button
        type="submit"
        className={styles.button}
        loading={status === LOADING}
      >
        Sign Up
      </Button>
    </form>
  );
};

SignupForm.propTypes = {
  newUserInfo: shape({
    name: string,
    lastName: string,
    email: string,
  }),
};

export { SignupForm };
