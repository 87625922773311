import { format } from 'date-fns';

import { DASHBOARD_PLANS } from 'src/constants/memberships';
import { EmbedAnalyticsService } from 'src/services/embedAnalyticsService';
import { UserService } from 'src/services/userService';
import { serializeUserAccounts } from 'src/utils/helpers';
import { isSubscriptionActive, getMembershipPlan } from 'src/utils/sessionHelpers';
import createAction from './createAction';

export const GET_USER_FOR_DASHBOARD = 'GET_USER_FOR_DASHBOARD';
export const GET_USER_FOR_DASHBOARD_SUCCESS = 'GET_USER_FOR_DASHBOARD_SUCCESS';
export const GET_USER_FOR_DASHBOARD_REQUEST = 'GET_USER_FOR_DASHBOARD_REQUEST';
export const GET_USER_FOR_DASHBOARD_ERROR = 'GET_USER_FOR_DASHBOARD_ERROR';

export const GET_TIME_SPENT_IN_FRAMES = 'GET_TIME_SPENT_IN_FRAMES';
export const GET_TIME_SPENT_IN_FRAMES_SUCCESS = 'GET_TIME_SPENT_IN_FRAMES_SUCCESS';
export const GET_TIME_SPENT_IN_FRAMES_REQUEST = 'GET_TIME_SPENT_IN_FRAMES_REQUEST';
export const GET_TIME_SPENT_IN_FRAMES_ERROR = 'GET_TIME_SPENT_IN_FRAMES_ERROR';

export const GET_EXPORT_DATE = 'GET_EXPORT_DATE';
export const GET_EXPORT_DATE_SUCCESS = 'GET_EXPORT_DATE_SUCCESS';
export const GET_EXPORT_DATE_REQUEST = 'GET_EXPORT_DATE_REQUEST';
export const GET_EXPORT_DATE_ERROR = 'GET_EXPORT_DATE_ERROR';

export const GET_REFERRERS = 'GET_REFERRERS';
export const GET_REFERRERS_SUCCESS = 'GET_REFERRERS_SUCCESS';
export const GET_REFERRERS_REQUEST = 'GET_REFERRERS_REQUEST';
export const GET_REFERRERS_ERROR = 'GET_REFERRERS_ERROR';

export const GET_LINKS_CLICKED = 'GET_LINKS_CLICKED';
export const GET_LINKS_CLICKED_SUCCESS = 'GET_LINKS_CLICKED_SUCCESS';
export const GET_LINKS_CLICKED_REQUEST = 'GET_LINKS_CLICKED_REQUEST';
export const GET_LINKS_CLICKED_ERROR = 'GET_LINKS_CLICKED_ERROR';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';

export const SET_SELECTED_TIME_RANGE = 'SET_SELECTED_TIME_RANGE';

export const GET_IMPRESSIONS = 'GET_IMPRESSIONS';
export const GET_IMPRESSIONS_SUCCESS = 'GET_IMPRESSIONS_SUCCESS';
export const GET_IMPRESSIONS_REQUEST = 'GET_IMPRESSIONS_REQUEST';
export const GET_IMPRESSIONS_ERROR = 'GET_IMPRESSIONS_ERROR';

export const GET_TIME_IN_STAGGER = 'GET_TIME_IN_STAGGER';
export const GET_TIME_IN_STAGGER_SUCCESS = 'GET_TIME_IN_STAGGER_SUCCESS';
export const GET_TIME_IN_STAGGER_REQUEST = 'GET_TIME_IN_STAGGER_REQUEST';
export const GET_TIME_IN_STAGGER_ERROR = 'GET_TIME_IN_STAGGER_ERROR';

export const getUserForDashboardRequest = createAction(GET_USER_FOR_DASHBOARD_REQUEST);
export const getUserForDashboardSuccess = createAction(GET_USER_FOR_DASHBOARD_SUCCESS);
export const getUserForDashboardError = createAction(GET_USER_FOR_DASHBOARD_ERROR);

export const getTimeSpentInFramesRequest = createAction(GET_TIME_SPENT_IN_FRAMES_REQUEST);
export const getTimeSpentInFramesSuccess = createAction(GET_TIME_SPENT_IN_FRAMES_SUCCESS);
export const getTimeSpentInFramesError = createAction(GET_TIME_SPENT_IN_FRAMES_ERROR);

export const getExportDateRequest = createAction(GET_EXPORT_DATE_REQUEST);
export const getExportDateSuccess = createAction(GET_EXPORT_DATE_SUCCESS);
export const getExportDateError = createAction(GET_EXPORT_DATE_ERROR);

export const getReferrersRequest = createAction(GET_REFERRERS_REQUEST);
export const getReferrersSuccess = createAction(GET_REFERRERS_SUCCESS);
export const getReferrersError = createAction(GET_REFERRERS_ERROR);

export const getLinksClickedRequest = createAction(GET_LINKS_CLICKED_REQUEST);
export const getLinksClickedSuccess = createAction(GET_LINKS_CLICKED_SUCCESS);
export const getLinksClickedError = createAction(GET_LINKS_CLICKED_ERROR);

export const setSelectedProject = createAction(SET_SELECTED_PROJECT);

export const setSelectedTimeRange = createAction(SET_SELECTED_TIME_RANGE);

export const getImpressionsRequest = createAction(GET_IMPRESSIONS_REQUEST);
export const getImpressionsSuccess = createAction(GET_IMPRESSIONS_SUCCESS);
export const getImpressionsError = createAction(GET_IMPRESSIONS_ERROR);

export const getTimeInStaggerRequest = createAction(GET_TIME_IN_STAGGER_REQUEST);
export const getTimeInStaggerSuccess = createAction(GET_TIME_IN_STAGGER_SUCCESS);
export const getTimeInStaggerError = createAction(GET_TIME_IN_STAGGER_ERROR);

export const getUserForDashboard = (accountId) => async dispatch => {
  try {
    dispatch(getUserForDashboardRequest());
    const { data } = await UserService.getUser('projects');
    const user = serializeUserAccounts(data.user);
    const currentAccountId = accountId || user.defaultAccountId;

    const disabled = !DASHBOARD_PLANS.includes(getMembershipPlan(user, currentAccountId)) ||
    !isSubscriptionActive(user, currentAccountId);
    dispatch(getUserForDashboardSuccess({ user, disabled }));
  } catch (error) {
    dispatch(getUserForDashboardError(error?.data?.message));
  }
};

export const getTimeSpentInFrames = (projectId, from, to) => async dispatch => {
  try {
    dispatch(getTimeSpentInFramesRequest());
    const { data: { result } } = await EmbedAnalyticsService.getTimeSpentInFrames(
      projectId,
      from,
      to,
    );
    const roundedResult = result.map(elem => ({ ...elem, avg: Math.round(elem.avg * 100) / 100 }));
    dispatch(getTimeSpentInFramesSuccess({ result: roundedResult, projectId }));
  } catch (error) {
    dispatch(getTimeSpentInFramesError(error?.data?.message));
  }
};

export const getExportDate = (projectId, to) => async dispatch => {
  try {
    dispatch(getExportDateRequest());
    const { data: { result } } = await EmbedAnalyticsService.getExportDate(projectId, to);
    const time = result.length ? format(result[0].time, 'LLLL dd, yyyy') : 'No date found';
    dispatch(getExportDateSuccess({ time, projectId }));
  } catch (error) {
    dispatch(getExportDateError(error?.data?.message));
  }
};

export const getReferrers = (projectId, to) => async dispatch => {
  try {
    dispatch(getReferrersRequest());
    const { data: { result } } = await EmbedAnalyticsService.getReferrers(projectId, to);
    const referrers = result.length ? result : [{ referrer: '' }];
    dispatch(getReferrersSuccess({ referrers, projectId }));
  } catch (error) {
    dispatch(getReferrersError(error?.data?.message));
  }
};

export const getLinksClicked = (projectId, from, to) => async dispatch => {
  try {
    dispatch(getLinksClickedRequest());
    const { data: { result } } = await EmbedAnalyticsService.getLinksClicked(projectId, from, to);
    dispatch(getLinksClickedSuccess({ result, projectId }));
  } catch (error) {
    dispatch(getLinksClickedError(error?.data?.message));
  }
};

export const getImpressions = (projectId, from, to) => async dispatch => {
  try {
    dispatch(getImpressionsRequest());
    const { data: { result } } = await EmbedAnalyticsService.getImpressions(projectId, from, to);
    const impressions = result.length ? result[0].count : '-';
    dispatch(getImpressionsSuccess({ impressions, projectId }));
  } catch (error) {
    dispatch(getImpressionsError(error?.data?.message));
  }
};

export const getTimeInStagger = (projectId, from, to) => async dispatch => {
  try {
    dispatch(getTimeInStaggerRequest());
    const { data: { result } } = await EmbedAnalyticsService.getTimeInStagger(projectId, from, to);
    const time = result.length ? result[0].time : undefined;
    dispatch(getTimeInStaggerSuccess({ time, projectId }));
  } catch (error) {
    dispatch(getTimeInStaggerError(error?.data?.message));
  }
};
