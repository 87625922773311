import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { changeSize } from 'src/actions/projectActions';
import { useProjectSelector } from 'src/hooks';
import { layoutAllowsOnlyOneFrame } from '../layout-control/layoutControlOptions';
import styles from './SizeControl.module.scss';

const MIN_SIZE = 1;

const SizeControl = () => {
  const dispatch = useDispatch();

  const { size, layout } = useProjectSelector();

  const changeSizeRequest = useCallback(value => {
    dispatch(changeSize({ size: value }));
  }, [dispatch]);

  const incrementSize = () => {
    if (!layoutAllowsOnlyOneFrame(layout)) {
      changeSizeRequest(size + 1);
    }
  };

  const decrementSize = () => {
    if (size > MIN_SIZE) changeSizeRequest(size - 1);
  };

  return (
    <div className={styles.container}>
      <span className={styles.countText}>Canvas count</span>
      <div className={styles.buttonsContainer}>
        <button
          className={styles.button}
          onClick={decrementSize}
          disabled={size === MIN_SIZE}
        >
          -
        </button>
        <span className={styles.size}>{size}</span>
        <button
          className={styles.button}
          onClick={incrementSize}
          disabled={layoutAllowsOnlyOneFrame(layout)}
        >
          +
        </button>
      </div>
    </div>
  );
};

export { SizeControl };
