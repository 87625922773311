import { useState, useEffect, useCallback } from 'react';

const useDelayUnmount = (isMounted, delayTime, callback) => {
  const [shouldRender, setShouldRender] = useState(false);

  const onClose = useCallback(() => {
    setShouldRender(false);
    callback && callback();
  }, [callback]);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      if (delayTime) {
        timeoutId = setTimeout(() => {
          onClose();
        }, delayTime);
      } else {
        onClose();
      }
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender, onClose]);

  return shouldRender;
};

export { useDelayUnmount };
