import React from 'react';
import { useDispatch } from 'react-redux';

import { cleanSelectedElement } from 'src/actions/projectActions';
import { Button } from 'src/common/button';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import styles from './MaskControl.module.scss';

const MaskControl = () => {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(cleanSelectedElement());
  };

  const onMask = () => {
    const event = new KeyboardEvent('keydown', {
      bubbles: true,
      code: ENTER_CHARACTER_CODE,
      type: 'keydown',
    });
    window.dispatchEvent(event);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.cancel}
        onClick={onCancel}
        short
      >
        Cancel
      </Button>
      <Button
        className={styles.done}
        onClick={onMask}
        alternativeCTA
        short
      >
        Done
      </Button>
    </div>
  );
};

export { MaskControl };
