import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthenticatedRoute } from './AuthenticatedRoute';
import ScrollToTop from './ScrollToTop';
import routes from './routes';

const renderRoutes = () => {
  const enabledRoutes = routes.filter(route => !route.disabled);
  return enabledRoutes.map(({ path, authenticated, ...route }) => (
    authenticated ?
      <AuthenticatedRoute key={path} path={path} {...route} /> :
      <Route key={path} path={path} {...route} />
  ));
};

const Base = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {renderRoutes()}
      </Switch>
    </ScrollToTop>
  </Router>
);
export default Base;
