import React, { useRef, useEffect, useCallback } from 'react';
import { string, func } from 'prop-types';
import { useDispatch } from 'react-redux';

import { updateProjectFrameTitles, UPDATE_PROJECT_TITLES } from 'src/actions/projectActions';
import { setEditableFrameTitle } from 'src/actions/canvasActions';
import { Input } from 'src/common/input';
import { useForm, useTextInputProps, useProjectSelector, useLoading } from 'src/hooks';
import { validateFrameTitle } from 'src/utils/constrains';
import { Button } from 'src/common/button';
import styles from './RenameFrameTitleForm.module.scss';

const RenameFrameTitleForm = ({ defaultTitle, onCancel }) => {
  const dispatch = useDispatch();

  const { id, frameTitles, editableFrameTitle } = useProjectSelector();

  const updateTitle = async (values) => {
    if (values.title === defaultTitle) {
      dispatch(setEditableFrameTitle());
    } else {
      const { index } = editableFrameTitle.frame;
      const newFrameTitles = [...frameTitles];
      newFrameTitles[index] = values.title;
      await dispatch(updateProjectFrameTitles(id, { frameTitles: newFrameTitles }));
      dispatch(setEditableFrameTitle());
    }
  };

  const validation = useCallback((values) => {
    const { index } = editableFrameTitle.frame;
    return validateFrameTitle(values, frameTitles, index);
  }, [frameTitles, editableFrameTitle]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(
    updateTitle,
    {
      validate: validation,
      initialValues: defaultTitle ? { title: defaultTitle } : {},
    },
  );

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  const isLoading = useLoading(UPDATE_PROJECT_TITLES);

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Input
        name="title"
        disabled={false}
        placeholder="Add a title"
        {...inputProps('title')}
        ref={inputRef}
      />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.cancelButton}
          onClick={onCancel}
          secondary
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={styles.saveButton}
          loading={isLoading}
          alternativeCTA
        >
          Save
        </Button>
      </div>
    </form>
  );
};

RenameFrameTitleForm.propTypes = {
  defaultTitle: string,
  onCancel: func.isRequired,
};

export { RenameFrameTitleForm };
