import React from 'react';
import cn from 'classnames';
import { ScrollMenu as ScrollMenuReact } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { string, oneOfType, node, object } from 'prop-types';

import { RightArrow } from './right-arrow';
import { LeftArrow } from './left-arrow';
import styles from './ScrollMenu.module.scss';

const ScrollMenu = ({
  wrapperClassName,
  children,
  rightArrowClassName,
  leftArrowClassName,
}) => (
  <ScrollMenuReact
    LeftArrow={() => <LeftArrow className={leftArrowClassName} />}
    RightArrow={() => <RightArrow className={rightArrowClassName} />}
    wrapperClassName={cn(styles.wrapper, wrapperClassName)}
  >
    {children}
  </ScrollMenuReact>
);

ScrollMenu.propTypes = {
  wrapperClassName: string,
  children: oneOfType([node, object]).isRequired,
  rightArrowClassName: string,
  leftArrowClassName: string,
};

export { ScrollMenu };
