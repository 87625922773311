import { HttpClient } from 'src/clients';

class OnboardingService {
  static getOnboardingInfo(accountId) {
    return HttpClient.get(`/onboarding/account/${accountId}`);
  }

  static saveBusinessInfo(businessInfo, accountId) {
    return HttpClient.post('/onboarding/business-info', { businessInfo, accountId });
  }

  static saveTargetDemographic(targetDemographic, accountId) {
    return HttpClient.post('/onboarding/target-demographic', { targetDemographic, accountId });
  }

  static saveMainCTA(mainCTA, accountId) {
    return HttpClient.post('/onboarding/main-cta', { mainCTA, accountId });
  }
}

export { OnboardingService };
