import React, { useMemo } from 'react';
import { func, object, oneOf, string } from 'prop-types';
import cn from 'classnames';

import TextAlignLeftWhiteIcon from 'src/assets/icons/text-align-left-white.svg';
import TextAlignLeftIcon from 'src/assets/icons/text-align-left.svg';
import TextAlignRightWhiteIcon from 'src/assets/icons/text-align-right-white.svg';
import TextAlignRightIcon from 'src/assets/icons/text-align-right.svg';
import TextAlignCenterWhiteIcon from 'src/assets/icons/text-align-center-white.svg';
import TextAlignCenterIcon from 'src/assets/icons/text-align-center.svg';
import { TEXT_ALIGN } from 'src/constants/general';
import { CollapsibleOptions } from '../collapsible-options';
import styles from './TextAlignment.module.scss';

const TextAlignment = ({
  changeAlignment,
  alignment,
  iconLeftActive = TextAlignLeftWhiteIcon,
  iconCenterActive = TextAlignCenterWhiteIcon,
  iconRightActive = TextAlignRightWhiteIcon,
  className,
  optionsStyle,
}) => {
  const options = useMemo(() => (
    [
      {
        text: '',
        prefix: (
          <img
            src={alignment === TEXT_ALIGN.LEFT ? TextAlignLeftWhiteIcon : TextAlignLeftIcon}
            alt="Text align left"
          />
        ),
        action: () => changeAlignment(TEXT_ALIGN.LEFT),
        value: TEXT_ALIGN.LEFT,
      },
      {
        text: '',
        prefix: (
          <img
            src={alignment === TEXT_ALIGN.CENTER ? TextAlignCenterWhiteIcon : TextAlignCenterIcon}
            alt="Text align center"
          />
        ),
        action: () => changeAlignment(TEXT_ALIGN.CENTER),
        value: TEXT_ALIGN.CENTER,
      },
      {
        text: '',
        prefix: (
          <img
            src={alignment === TEXT_ALIGN.RIGHT ? TextAlignRightWhiteIcon : TextAlignRightIcon}
            alt="Text align right"
          />
        ),
        action: () => changeAlignment(TEXT_ALIGN.RIGHT),
        value: TEXT_ALIGN.RIGHT,
      },
    ]
  ), [alignment, changeAlignment]);

  const optionSelected = useMemo(() => {
    if (alignment === TEXT_ALIGN.LEFT) {
      return <img src={iconLeftActive} alt="Text align left" />;
    }
    if (alignment === TEXT_ALIGN.CENTER) {
      return <img src={iconCenterActive} alt="Text align center" />;
    }
    if (alignment === TEXT_ALIGN.RIGHT) {
      return <img src={iconRightActive} alt="Text align right" />;
    }
    return null;
  }, [alignment, iconCenterActive, iconLeftActive, iconRightActive]);

  return (
    <CollapsibleOptions
      options={options}
      className={cn(styles.container, className)}
      selectedValue={alignment}
      closeOnClickOption
      optionsStyle={optionsStyle}
    >
      {optionSelected}
    </CollapsibleOptions>
  );
};

TextAlignment.propTypes = {
  changeAlignment: func.isRequired,
  alignment: oneOf(Object.values(TEXT_ALIGN)),
  iconLeftActive: string,
  iconCenterActive: string,
  iconRightActive: string,
  className: string,
  optionsStyle: object,
};

export { TextAlignment };
