import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import TagIcon from 'src/assets/icons/tag.svg';
import { useProjectSelector } from 'src/hooks';
import { Button } from 'src/common/button';
import { Select } from 'src/common/select';
import { MEDIA_ELEMENTS, TEXT_ELEMENT } from 'src/constants/canvasElements';
import { addTagToElement, removeTagFromElement } from 'src/actions/projectActions';
import { setEditableTag } from 'src/actions/canvasActions';
import { Modal } from 'src/common/modal';
import styles from './CanvasEditableTag.module.scss';

const ASSET_OPTION = { label: 'Asset', value: 'asset' };
const LOGO_OPTION = { label: 'Logo', value: 'logo' };
const COLOR_OPTION = { label: 'Color', value: 'color' };
const FONT_OPTION = { label: 'Font', value: 'font' };

const CATEGORY_SEPARATOR = ',';

const CanvasEditableTag = () => {
  const dispatch = useDispatch();

  const { editableTag, elements } = useProjectSelector();

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categories, setCategories] = useState();

  const element = elements.find(elem => elem.uuid === editableTag.uuid);

  useEffect(() => {
    let initialCategories = [ASSET_OPTION];
    let optionsAvailable = [ASSET_OPTION, LOGO_OPTION];
    if (!MEDIA_ELEMENTS.includes(element.type)) {
      initialCategories = [COLOR_OPTION];
      optionsAvailable = [COLOR_OPTION];
    }
    if (element.type === TEXT_ELEMENT) {
      optionsAvailable.push(FONT_OPTION);
    }
    if (element.tag) {
      const parsedCategories = element.tag.category.split(CATEGORY_SEPARATOR);
      initialCategories = optionsAvailable.reduce((res, actual) => {
        if (parsedCategories.includes(actual.value)) {
          res.push(actual);
        }
        return res;
      }, []);
    }
    setCategoryOptions(optionsAvailable);
    setCategories(initialCategories);
  }, [editableTag.uuid, element.tag, element.type, elements]);

  const onSave = () => {
    const categoryToSend = categories.map(cat => cat.value).join(CATEGORY_SEPARATOR);
    dispatch(addTagToElement({ category: categoryToSend }));
  };

  const onRemove = () => {
    dispatch(removeTagFromElement());
  };

  const onClose = () => {
    dispatch(setEditableTag());
  };

  const onChange = (value) => {
    if (Array.isArray(value)) {
      setCategories(value);
    } else {
      setCategories([value]);
    }
  };

  const disabledSaveButton = useMemo(() => {
    if (!categories?.length) {
      return true;
    }
    const categoryToSend = categories.map(cat => cat.value).join(CATEGORY_SEPARATOR);
    if (categoryToSend === element.tag?.category) {
      return true;
    }
    return false;
  }, [categories, element.tag]);

  return (
    <Modal
      isShowing
      hide={onClose}
      noBackground
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={TagIcon} className={styles.icon} alt="" />
          <span className={styles.title}>Tag</span>
        </div>
        <div className={styles.content}>
          <span className={styles.category}>CATEGORY</span>
          <Select
            options={categoryOptions}
            value={categories}
            onChange={onChange}
            closeMenuOnSelect
            isMulti={element.type === TEXT_ELEMENT}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={onSave}
            className={styles.saveButton}
            alternativeCTA
            disabled={disabledSaveButton}
          >
            Save Tag
          </Button>
          {element.tag && (
            <Button
              className={styles.removeButton}
              secondary
              onClick={onRemove}
            >
              Remove tag
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { CanvasEditableTag };
