import React, { useEffect, useReducer, useContext } from 'react';
import { oneOfType, node, object } from 'prop-types';

import {
  BOTTOM_ARROW_CODE,
  COMMAND_CHARACTER_CODE,
  COMMAND_CHARACTER_CODE_RIGHT,
  CONTROL_CHARACTER_CODE,
  KEY_A_CHARACTER_CODE,
  KEY_G_CHARACTER_CODE,
  KEY_L_CHARACTER_CODE,
  KEY_Z_CHARACTER_CODE,
  LEFT_ARROW_CODE,
  RIGHT_ARROW_CODE,
  SPACE_CHARACTER_CODE,
  TOP_ARROW_CODE,
} from 'src/constants/keyboardCodes';
import { isHtmlTagFocused } from 'src/utils/helpers';

const KeyboardListenerContext = React.createContext({ });

const reducer = (state, action) => {
  switch (action.type) {
    case 'down':
      if (state[action.code]) {
        return state;
      }
      return { ...state, [action.code]: true };
    case 'up':
      if (!state[action.code]) {
        return state;
      }
      return { ...state, [action.code]: false };
    case 'reset':
      return { };
    default:
      return state;
  }
};

const KeyboardListener = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const resetKey = (code) => dispatch({ type: 'up', code });

  useEffect(() => {
    const handleKeyDown = (e) => {
      const arrowsPressed = e.code === TOP_ARROW_CODE || e.code === BOTTOM_ARROW_CODE ||
        e.code === LEFT_ARROW_CODE || e.code === RIGHT_ARROW_CODE;
      const canvasShortcutsPressed = (e.code === KEY_G_CHARACTER_CODE ||
        e.code === KEY_L_CHARACTER_CODE || e.code === KEY_A_CHARACTER_CODE) &&
        (e.ctrlKey || e.metaKey);
      const undoShortcutsPressed = e.code === KEY_Z_CHARACTER_CODE &&
        (e.ctrlKey || e.metaKey);
      const dragCanvasShortcutPressed = e.code === SPACE_CHARACTER_CODE;
      if ((canvasShortcutsPressed || arrowsPressed || undoShortcutsPressed
        || dragCanvasShortcutPressed) &&
        !isHtmlTagFocused()) {
        e.preventDefault();
      }
      dispatch({ type: 'down', code: e.code });
    };

    const handleKeyUp = (e) => {
      if (e.code === COMMAND_CHARACTER_CODE || e.code === CONTROL_CHARACTER_CODE ||
          e.code === COMMAND_CHARACTER_CODE_RIGHT) {
        dispatch({ type: 'reset' });
      } else {
        dispatch({ type: 'up', code: e.code });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [dispatch]);

  return (
    <KeyboardListenerContext.Provider value={{ state, resetKey }}>
      {children}
    </KeyboardListenerContext.Provider>
  );
};

KeyboardListener.propTypes = {
  children: oneOfType([node, object]).isRequired,
};

const useKeyboardListener = (code) => {
  const { state } = useContext(KeyboardListenerContext);
  return state[code];
};

const useKeyboardReset = () => {
  const { resetKey } = useContext(KeyboardListenerContext);
  return resetKey;
};

export { KeyboardListener, useKeyboardListener, useKeyboardReset };
