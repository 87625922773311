import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chunk from 'lodash.chunk';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';

import {
  getTemplatesSeeAll,
  SEE_ALL,
} from 'src/actions/templatesActions';
import { LOADING, SUCCESS } from 'src/constants/status';
import { useStatus, usePagination } from 'src/hooks';
import { reset } from 'src/actions/statusActions';
import { TemplateGrid } from 'src/pages/templates/template-grid';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './SeeAllTemplates.module.scss';

const SeeAllTemplates = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const goToTemplates = () => {
    history.replace(routesPaths.templates);
  };

  const {
    categories,
    loadingPaginationCategoryId,
    templatesInSeeAllByPages,
  } = useSelector(({ templates }) => ({
    categories: templates.categories,
    loadingPaginationCategoryId: templates.loadingPaginationCategoryId,
    templatesInSeeAllByPages: templates.templatesInSeeAllByPages,
  }));

  const categoryId = useMemo(() => {
    const { seeAll } = parse(history.location.search, { parseBooleans: true });
    return Number(seeAll);
  }, [history]);

  const handleFetchAll = useCallback(async (page) => {
    const accountId = -1;
    await dispatch(getTemplatesSeeAll(accountId, categoryId, page));
  }, [categoryId, dispatch]);

  const { status } = useStatus(SEE_ALL);
  let showLoading = false;

  if (status === LOADING) {
    showLoading = loadingPaginationCategoryId === categoryId;
  }

  const { page, prevPage, nextPage } = usePagination(handleFetchAll);

  useEffect(() => {
    const initialPage = 1;
    const accountId = -1;
    dispatch(getTemplatesSeeAll(accountId, categoryId, initialPage));

    return () => dispatch(reset(SEE_ALL));
  }, [categoryId, dispatch]);

  const rows = chunk(categories[categoryId]?.templates, amountOfTemplatesInARow);

  let isNextPageDisabled = false;
  if (templatesInSeeAllByPages[categoryId]?.total >= 0) {
    const { total, current } = templatesInSeeAllByPages[categoryId];
    isNextPageDisabled = current === total;
  }

  if (status === SUCCESS && templatesInSeeAllByPages[categoryId]?.total === 0) {
    return (
      <div className={styles.emptyContainer}>
        <span className={styles.emptyTitle}>No templates</span>
        <span className={styles.emptySubtitle}>
          We couldn&apos;t find any templates for this categroy
        </span>
      </div>
    );
  }

  return (
    <TemplateGrid
      isMainHeader
      name={categories[categoryId]?.name}
      goToTemplates={goToTemplates}
      showLoading={showLoading}
      templates={rows}
      status={status}
      page={page}
      isNextPageDisabled={isNextPageDisabled}
      prevPage={prevPage}
      nextPage={nextPage}
      categoryId={categoryId}
      amountOfTemplatesInARow={amountOfTemplatesInARow}
    />
  );
};

export { SeeAllTemplates };
