import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useSession, useStatus } from 'src/hooks';
import {
  getEditorsAndInvites,
  GET_EDITORS_AND_INVITES,
  INVITE_TO_ACCOUNTS,
  EDIT_EDITOR_ACCOUNTS,
  DELETE_EDITOR,
} from 'src/actions/accountActions';
import { SUCCESS } from 'src/constants/status';
import { Button } from 'src/common/button';
import { AddEditor } from './add-editor';
import { EditorCard } from './editor-card';
import { InviteCard } from './invite-card';
import styles from './EditorsManager.module.scss';

const EditorsManager = () => {
  const dispatch = useDispatch();
  const { user } = useSession();
  const [editorsAndInvites, setEditorsAndInvites] = useState([]);
  const [openFormId, setOpenFormId] = useState();

  const loadEditorsAndInvites = useCallback(() => {
    if (user?.membership?.accounts) {
      const accountIds = user.membership.accounts.map(account => account.id);

      dispatch(getEditorsAndInvites(accountIds));
    } else {
      setEditorsAndInvites([]);
    }
  }, [dispatch, user]);

  useEffect(() => {
    loadEditorsAndInvites();
  }, [loadEditorsAndInvites]);

  const {
    status: getEditorsAndInvitesStatus,
    statusInfo: getEditorsAndInvitesStatusInfo,
  } = useStatus(GET_EDITORS_AND_INVITES);

  useEffect(() => {
    if (getEditorsAndInvitesStatus === SUCCESS && getEditorsAndInvitesStatusInfo) {
      setEditorsAndInvites(getEditorsAndInvitesStatusInfo.editorsAndInvites);
    }
  }, [getEditorsAndInvitesStatus, getEditorsAndInvitesStatusInfo]);

  const [addingForTheFirstTime, setAddingForTheFirstTime] = useState(false);

  const { status: statusInviteToAccounts } = useStatus(INVITE_TO_ACCOUNTS);
  const { status: statusEditEditorAccounts } = useStatus(EDIT_EDITOR_ACCOUNTS);
  const { status: statusDeleteEditor } = useStatus(DELETE_EDITOR);

  const refreshStatus = statusInviteToAccounts === SUCCESS ||
    statusEditEditorAccounts === SUCCESS ||
    statusDeleteEditor === SUCCESS;

  useEffect(() => {
    if (refreshStatus) {
      loadEditorsAndInvites();
      setAddingForTheFirstTime(false);
    }
  }, [refreshStatus, loadEditorsAndInvites]);

  const onAddAccount = () => {
    setAddingForTheFirstTime(true);
  };

  const managerEnabled = user.membership.subscription?.active;

  return (
    <>
      <div className={styles.container}>
        <span className={styles.header}>Editors ({editorsAndInvites.length})</span>
        {editorsAndInvites.map(editorOrInvite => (
          editorOrInvite.expirationDate ? (
            <InviteCard
              key={editorOrInvite.id}
              disabled={!managerEnabled}
              {...editorOrInvite}
            />
          ) : (
            <EditorCard
              key={editorOrInvite.id}
              disabled={!managerEnabled}
              {...editorOrInvite}
              openFormId={openFormId}
              setOpenFormId={setOpenFormId}
            />
          )
        ))}
        {(addingForTheFirstTime || editorsAndInvites.length > 0) ? (
          <AddEditor
            addingForTheFirstTime={addingForTheFirstTime}
            setAddingForTheFirstTime={setAddingForTheFirstTime}
            openFormId={openFormId}
            setOpenFormId={setOpenFormId}
            disabled={!managerEnabled}
          />
        ) : (
          <div className={styles.editorSection}>
            <span className={styles.noEditorTitle}>No editors yet.</span>
            <span className={styles.noEditorContent}>
              Assigning an editor role allows a user to review a Stagger project.
              They do not have permission to other accounts or projects.
            </span>
            <Button
              onClick={onAddAccount}
              secondary
              disabled={!managerEnabled}
            >
              Add an Editor
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export { EditorsManager };
