import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { MOBILE_THRESHOLD_WIDTH, SMALL_HEIGHT } from 'src/constants/breakpoints';
import {
  useStatus,
  usePagination,
  useProjectSelector,
  useWindowSize,
  useStageCenteredPosition,
} from 'src/hooks';
import {
  unsplashSearch,
  unsplashReset,
  UNSPLASH_SEARCH,
  unsplashDownload,
  replaceImageFromRightClick,
  addMediaElements,
  UNSPLASH_NEXT_PAGE,
  unsplashNextPage,
} from 'src/actions/projectActions';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { reset } from 'src/actions/statusActions';
import { Spinner } from 'src/common/spinner';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { PRODUCTION } from 'src/constants/environments';
import SearchIcon from 'src/assets/icons/search.svg';
import { DEFAULT_SEARCH_TERM } from 'src/constants/general';
import { UNSPLASH_UPLOAD } from 'src/constants/uploadFile';
import { FullscreenButton } from '../fullscreen-button';
import { UnsplashCard } from '../unsplash-card';
import styles from './UnsplashUpload.module.scss';

const SCROLLABLE_CONTAINER_ID = 'unsplashScrollableContainer';

const UnsplashUpload = ({ saveSourceInMemory }) => {
  const dispatch = useDispatch();

  const { replaceableImageRightClick } = useSelector(({ canvas }) => ({
    replaceableImageRightClick: canvas.replaceableImageRightClick,
  }));

  const isReplacingImage = !!replaceableImageRightClick;

  const { unsplashImages } = useProjectSelector();

  const position = useStageCenteredPosition();

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);

  const { windowSize } = useWindowSize();

  const perPage = useMemo(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH) {
      return 6;
    }
    return windowSize.height > SMALL_HEIGHT ? 12 : 9;
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    dispatch(unsplashSearch(DEFAULT_SEARCH_TERM, 1, perPage));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const searchRequest = useCallback(page => {
    dispatch(unsplashSearch(
      search,
      page,
      perPage,
    ));
  },
  [dispatch, search, perPage]);

  const nextPageRequest = useCallback(async page => {
    await dispatch(unsplashNextPage(search, page, perPage));
  }, [dispatch, search, perPage]);

  useEffect(() => () => {
    dispatch(reset(UNSPLASH_SEARCH));
    dispatch(unsplashReset());
  }, [dispatch]);

  const handleChange = ({ target: { value } }) => setSearch(value);

  const { page, nextPage, setPage } = usePagination(nextPageRequest);

  const handleSubmit = () => {
    if (search) {
      setPage(1);
      searchRequest(1);
    }
  };

  const handleSubmitByKeyboard = ({ key }) => {
    if (key === ENTER_CHARACTER_CODE) {
      handleSubmit();
    }
  };

  const onClickImage = image => {
    if (selected.find(elem => elem.id === image.id)) {
      const filteredList = selected.filter(elem => elem.id !== image.id);
      setSelected(filteredList);
    } else {
      image.atPage = page;
      image.sourceId = image.id;
      if (isReplacingImage) {
        setSelected([image]);
      } else {
        setSelected([...selected, image]);
      }
    }
  };

  const importImages = useCallback(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === PRODUCTION) {
    // Only send analytics to Unsplash in production
      selected.forEach(elem => {
        unsplashDownload(elem.downloadLocation);
      });
    }
    const images = selected.map(elem => {
      delete elem.downloadLocation;
      return elem;
    });
    if (isReplacingImage) {
      dispatch(replaceImageFromRightClick({
        media: selected[0],
        replaceableImage: replaceableImageRightClick,
      }));
    } else {
      dispatch(addMediaElements({ media: images, x: position.x, y: position.y }));
    }
    setSelected([]);
    saveSourceInMemory();
  }, [
    dispatch,
    isReplacingImage,
    position,
    replaceableImageRightClick,
    saveSourceInMemory,
    selected,
  ]);

  const { status: statusSearch } = useStatus(UNSPLASH_SEARCH);
  const { status: statusNextPage } = useStatus(UNSPLASH_NEXT_PAGE);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (statusSearch !== LOADING && statusNextPage !== LOADING &&
      page < unsplashImages.totalPages && containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [nextPage, page, statusNextPage, statusSearch, unsplashImages]);

  const refResults = useRef();

  useEffect(() => {
    if (refResults?.current && statusSearch === LOADING) {
      refResults.current.scrollTo(0, 0);
    }
  }, [statusSearch]);

  return (
    <div className={styles.container}>
      <span className={styles.title}>Unsplash Library</span>
      <span className={styles.subtitle}>
        Search for royalty-free images
      </span>
      <Input
        name="search"
        value={search}
        onChange={handleChange}
        containerClassName={styles.inputContainer}
        inputClassName={styles.input}
        onKeyDown={handleSubmitByKeyboard}
        placeholder="Search free high-resolution photos"
        sufix={(
          <button
            onClick={handleSubmit}
            className={styles.button}
            disabled={statusSearch === LOADING}
          >
            {statusSearch === LOADING ? (
              <Spinner
                iconClassName={styles.spinnerIcon}
                containerClassName={styles.spinnerContainer}
              />
            ) :
              <img src={SearchIcon} alt="search" />}
          </button>
        )}
      />
      <div className={styles.results} id={SCROLLABLE_CONTAINER_ID} ref={refResults}>
        <InfiniteScroll
          dataLength={unsplashImages.images.length}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {statusSearch === ERROR && (
            <span className={styles.error}>
              Oops! The search resulted in error. Please try again.
            </span>
          )}
          {unsplashImages?.images.map(image => (
            <UnsplashCard
              key={image.id}
              {...image}
              addImage={onClickImage}
              isSelected={!!selected.find(elem => elem.id === image.id)}
            />
          ))}
          {statusSearch === SUCCESS && unsplashImages.totalPages === 0 &&
            unsplashImages.images?.length === 0 && (
            <span className={styles.noResults}>No results found</span>
          )}
        </InfiniteScroll>
      </div>
      {!!unsplashImages.images.length && (
        <div className={styles.bottom}>
          <FullscreenButton
            title="Unsplash"
            pagesToShow={unsplashImages.images}
            nextPage={nextPage}
            cardComponent={(image) => (
              <UnsplashCard
                key={image.id}
                {...image}
                addImage={onClickImage}
                isSelected={!!selected.find(elem => elem.id === image.id)}
                showAuthor={false}
                className={styles.fullscreenImageContainer}
                imageClassName={styles.fullscreenImage}
                imageButtonClassName={styles.fullscreenImageButton}
              />
            )}
            selected={selected}
            setSelected={setSelected}
            actionButton={{
              disabled: selected.length === 0,
              onClick: importImages,
              className: styles.import,
              text: isReplacingImage ?
                'Replace' :
                `Place in canvas ${selected.length ? `(${selected.length})` : ''}`,
            }}
            source={UNSPLASH_UPLOAD}
          />
          <Button
            disabled={selected.length === 0}
            onClick={importImages}
            className={styles.import}
          >
            {isReplacingImage ?
              'Replace' :
              `Place in canvas ${selected.length ? `(${selected.length})` : ''}`}
          </Button>
        </div>
      )}
    </div>
  );
};

UnsplashUpload.propTypes = {
  saveSourceInMemory: func.isRequired,
};

export { UnsplashUpload };
