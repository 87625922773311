import React from 'react';

import EmptyProjects from 'src/assets/icons/empty-projects.svg';
import styles from './NoPublicProjects.module.scss';

const NoPublicProjects = () => (
  <div className={styles.noProjects}>
    <img
      src={EmptyProjects}
      alt="No public flyers"
      className={styles.noProjectsIcon}
    />
    <h3 className={styles.noProjectsText}>
        No flyers to show yet
    </h3>
  </div>
);

export { NoPublicProjects };
