import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { writeTextElement } from 'src/actions/projectActions';
import { TEXT_ELEMENT } from 'src/constants/canvasElements';
import { CANVAS_TEXT_PADDING, TEXT_DEFAULT_LINE_HEIGHT } from 'src/constants/general';
import { parseFontStyle } from 'src/utils/helpers';
import { useProjectSelector } from 'src/hooks';
import styles from './CanvasEditableText.module.scss';

const CanvasEditableText = () => {
  const dispatch = useDispatch();

  const {
    scale,
    stageOffset,
  } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
  }));

  const { elements, editableText, selectedRefs } = useProjectSelector();

  const element = useMemo(() => (
    elements.find(elem => elem.uuid === editableText)
  ), [elements, editableText]);

  const writeTextElementRequest = useCallback(text => {
    const elem = selectedRefs[editableText];
    const lineHeight = element.lineHeight || TEXT_DEFAULT_LINE_HEIGHT;
    const heightAttr = elem?.current?.textArr ?
      {
        height: (element.fontSize * lineHeight) * elem.current.textArr.length +
          CANVAS_TEXT_PADDING * 2,
      } :
      {};
    const newAttrs = {
      uuid: editableText,
      text,
      ...heightAttr,
    };
    dispatch(writeTextElement(newAttrs));
  }, [dispatch, editableText, selectedRefs, element]);

  const top = useMemo(() => {
    const scrollY = stageOffset.y * scale;
    return element.y * scale + scrollY - CANVAS_TEXT_PADDING * scale;
  }, [element.y, scale, stageOffset.y]);

  const left = useMemo(() => {
    const scrollX = stageOffset.x * scale;
    return element.x * scale + scrollX;
  }, [stageOffset.x, scale, element.x]);

  if (!element || element.type !== TEXT_ELEMENT) {
    return null;
  }

  const { fontWeight, fontStyle } = parseFontStyle(element.fontStyle);

  return (
    <TextareaAutosize
      className={styles.container}
      style={{
        top,
        left,
        width: element.width * scale,
        fontSize: element.fontSize * scale,
        padding: CANVAS_TEXT_PADDING * scale,
        fontFamily: element.fontFamily,
        textAlign: element.align,
        color: element.color.hex,
        opacity: element.color.alpha / 100,
        fontStyle,
        fontWeight,
        lineHeight: element.lineHeight || TEXT_DEFAULT_LINE_HEIGHT,
        letterSpacing: element.letterSpacing * scale || 0,
        '--placeholder-color': element.color.hex,
      }}
      value={element?.text || ''}
      placeholder="Add text"
      onChange={({ target }) => writeTextElementRequest(target.value)}
      autoFocus
      onFocus={(e) => {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
      }}
    />
  );
};

export { CanvasEditableText };
