import {
  getProjectSuccess,
  updateProjectSuccess,
  updateProjectAutomaticallySuccess,
  getProjectReset,
} from 'src/actions/projectActions';
import { actionsToSave, actionsToForceSaveMedia } from 'src/constants/actionsToSave';
import createReducer from './createReducer';

const initialState = {
  projectId: undefined,
  changesSaved: true,
  forceMediaAutoSave: false,
  actionsUnsaved: 0,
};

const createActionHandlers = () => {
  const actionHandlers = {
    [getProjectReset]: () => initialState,
    [getProjectSuccess]: (state, { payload: { project } }) => {
      state.changesSaved = true;
      state.forceMediaAutoSave = false;
      state.projectId = project.id;
      state.actionsUnsaved = 0;
    },
    [updateProjectSuccess]: (state, { payload: { project } }) => {
      if (state.projectId === project.id) {
        state.changesSaved = true;
        state.forceMediaAutoSave = false;
        state.actionsUnsaved = 0;
      }
    },
    [updateProjectAutomaticallySuccess]: (state, { payload: { project, amountUnsaved } }) => {
      if (state.projectId === project.id) {
        if (state.actionsUnsaved === amountUnsaved) {
          state.changesSaved = true;
        }
        state.forceMediaAutoSave = false;
        state.actionsUnsaved -= amountUnsaved;
      }
    },
  };
  return actionsToSave.reduce((res, actual) => ({
    ...res,
    [actual]: state => {
      state.changesSaved = false;
      state.actionsUnsaved += 1;
      if (actionsToForceSaveMedia.includes(actual)) {
        state.forceMediaAutoSave = true;
      }
    },
  }), actionHandlers);
};

export default createReducer(initialState, createActionHandlers());
