import React, { useRef, useEffect, useCallback } from 'react';
import { string, object } from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { updateProjectFrameTitles } from 'src/actions/projectActions';
import { setEditableFrameTitle } from 'src/actions/canvasActions';
import { Input } from 'src/common/input';
import { useForm, useTextInputProps, useProjectSelector, useClickOutside } from 'src/hooks';
import { validateFrameTitle } from 'src/utils/constrains';
import styles from './CanvasFrameTitleInput.module.scss';

const CanvasFrameTitleInput = ({
  defaultTitle,
  style,
  inputClassName,
}) => {
  const dispatch = useDispatch();

  const { id, frameTitles, editableFrameTitle } = useProjectSelector();

  const updateTitle = async (values) => {
    if (values.title === defaultTitle) {
      dispatch(setEditableFrameTitle());
    } else {
      const { index } = editableFrameTitle.frame;
      const newFrameTitles = [...frameTitles];
      newFrameTitles[index] = values.title;
      await dispatch(updateProjectFrameTitles(id, { frameTitles: newFrameTitles }));
      dispatch(setEditableFrameTitle());
    }
  };

  const validation = useCallback((values) => {
    const { index } = editableFrameTitle.frame;
    return validateFrameTitle(values, frameTitles, index);
  }, [frameTitles, editableFrameTitle]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(
    updateTitle,
    {
      validate: validation,
      initialValues: defaultTitle ? { title: defaultTitle } : {},
    },
  );

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  const refContainer = useClickOutside(handleSubmit);

  return (
    <form
      onSubmit={handleSubmit}
      style={style}
      ref={refContainer}
    >
      <Input
        name="title"
        containerClassName={styles.inputContainer}
        inputClassName={cn(styles.input, inputClassName)}
        disabled={false}
        placeholder="Add a title"
        {...inputProps('title')}
        ref={inputRef}
      />
    </form>
  );
};

CanvasFrameTitleInput.propTypes = {
  defaultTitle: string,
  style: object,
  inputClassName: string,
};

export { CanvasFrameTitleInput };
