import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { isHtmlTagFocused } from 'src/utils/helpers';
import { BACKSPACE_CHARACTER_CODE, DELETE_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { setGradientActiveColorId } from 'src/actions/canvasActions';
import { GradientPalette } from '../gradient-palette';
import { ColorStopsHolder } from '../color-stops-holder';
import styles from './GradientPicker.module.scss';

const STOP_WIDTH = 10;
const HALF_STOP_WIDTH = STOP_WIDTH / 2;
const DEFAULT_WIDTH = 200;
const DEFAULT_HEIGHT = 32;
const STOP_REMOVAL_DROP = 50;
const MAX_STOPS = 5;
const MIN_STOPS = 2;

const nextColorId = (palette) => Math.max(...palette.map(({ id }) => id)) + 1;

const mapPaletteToStops = ({ palette, activeId, width }) => (
  palette.map((color) => ({
    ...color,
    id: color.id,
    offset: width * color.offset - HALF_STOP_WIDTH,
    isActive: color.id === activeId,
  }))
);

const getPaletteColor = (palette, id) => {
  const color = palette.find(c => c.id === id);
  return { ...color, offset: Number(color.offset) };
};

const GradientPicker = ({ colorStops: gradient, onColorStopsChange }) => {
  const dispatch = useDispatch();

  const { activeColorId } = useSelector(({ canvas }) => ({
    activeColorId: canvas.gradientActiveColorId,
  }));

  const [colorStops, setColorStops] = useState(gradient);

  useEffect(() => {
    const [defaultActiveColor] = colorStops;
    dispatch(setGradientActiveColorId(defaultActiveColor.id));

    return () => {
      dispatch(setGradientActiveColorId());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const sortPalette = pal => (
    pal.sort(({ offset: offset1 }, { offset: offset2 }) => offset1 - offset2)
  );

  const handlePaletteChange = useCallback((pal, triggerOnChange = false) => {
    const sortedPalette = sortPalette(pal)
      .map(({ offset, ...rest }) => ({ offset: Number(offset).toFixed(3), ...rest }));
    setColorStops(sortedPalette);
    if (triggerOnChange) {
      onColorStopsChange(sortedPalette);
    }
  }, [onColorStopsChange]);

  const handleColorAdd = useCallback(({ offset }) => {
    if (colorStops.length >= MAX_STOPS) return;
    const { color } = getPaletteColor(colorStops, activeColorId);
    const entry = { id: nextColorId(colorStops), offset: offset / DEFAULT_WIDTH, color };

    const updatedPalette = [...colorStops, entry];

    dispatch(setGradientActiveColorId(entry.id));
    const triggerOnChange = true;
    handlePaletteChange(updatedPalette, triggerOnChange);
  }, [activeColorId, colorStops, dispatch, handlePaletteChange]);

  const handleColorDelete = useCallback((id) => {
    if (colorStops.length <= MIN_STOPS) return;

    const updatedPalette = colorStops.filter(color => color.id !== id);
    const activeId = updatedPalette.reduce((res, actual) => (
      actual.offset < res.offset ? actual : res
    ), updatedPalette[0]).id;

    dispatch(setGradientActiveColorId(activeId));
    const triggerOnChange = true;
    handlePaletteChange(updatedPalette, triggerOnChange);
  }, [colorStops, dispatch, handlePaletteChange]);

  useEffect(() => {
    const handleKeydown = ({ code }) => {
      if ((code === BACKSPACE_CHARACTER_CODE || code === DELETE_CHARACTER_CODE) &&
        activeColorId && !isHtmlTagFocused()) {
        handleColorDelete(activeColorId);
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [activeColorId, handleColorDelete]);

  const onStopDragStart = (id) => {
    if (id !== activeColorId) {
      dispatch(setGradientActiveColorId(id));
    }
  };

  const onStopDragEnd = () => {
    onColorStopsChange(colorStops);
  };

  const handleStopPosChange = ({ id, offset }) => {
    const updatedPalette = colorStops.map(color => {
      if (id === color.id) {
        return {
          ...color,
          offset: (offset + HALF_STOP_WIDTH) / DEFAULT_WIDTH,
        };
      }
      return color;
    });
    handlePaletteChange(updatedPalette);
  };

  const paletteWidth = DEFAULT_WIDTH - HALF_STOP_WIDTH;
  const stopsHolderDisabled = colorStops.length >= MAX_STOPS;

  return (
    <div className={styles.container}>
      <GradientPalette width={DEFAULT_WIDTH} height={DEFAULT_HEIGHT} palette={colorStops} />
      <ColorStopsHolder
        width={paletteWidth}
        disabled={stopsHolderDisabled}
        stops={mapPaletteToStops({
          palette: colorStops,
          width: paletteWidth,
          activeId: activeColorId,
        })}
        limits={{
          min: -HALF_STOP_WIDTH,
          max: DEFAULT_WIDTH - HALF_STOP_WIDTH,
          drop: STOP_REMOVAL_DROP,
        }}
        onPosChange={handleStopPosChange}
        onAddColor={handleColorAdd}
        onDeleteColor={handleColorDelete}
        onDragStart={onStopDragStart}
        onDragEnd={onStopDragEnd}
      />
    </div>
  );
};

GradientPicker.propTypes = {
  colorStops: arrayOf(shape({
    color: string,
    offset: string,
    id: number,
  })).isRequired,
  onColorStopsChange: func.isRequired,
};

export { GradientPicker };
