import React, { forwardRef } from 'react';
import { string, number, func, arrayOf, shape, oneOfType, object, bool } from 'prop-types';
import ReactSelect from 'react-select';
import cn from 'classnames';

import ArrowUp from 'src/assets/icons/arrow-up.svg';
import { SELECT_THEME, SELECT_STYLES } from 'src/constants/general';
import { ArrowSelect } from './arrow-select';
import styles from './Select.module.scss';

const Select = forwardRef(({
  value,
  onChange,
  options,
  className = '',
  theme = SELECT_THEME,
  selectStyles = SELECT_STYLES,
  disabled = false,
  closeMenuOnSelect = false,
  label,
  name,
  placeholder,
  isMulti = false,
  error = '',
  touched = false,
  icon = ArrowUp,
}, ref) => {
  const hasError = error && touched;
  return (
    <>
      {label && <label htmlFor={name} className={styles.selectLabel}>{label}</label>}
      <ReactSelect
        value={value}
        onChange={onChange}
        options={options}
        className={cn(className, { [styles.selectError]: hasError })}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: (props) => <ArrowSelect {...props} icon={icon} />,
        }}
        theme={theme}
        styles={selectStyles}
        isDisabled={disabled}
        placeholder={placeholder}
        ref={ref}
        isMulti={isMulti}
        name={name}
      />
      {hasError && <span className={styles.error}>{error}</span>}
    </>
  );
});

const valueType = shape({
  value: oneOfType([string, number]).isRequired,
  label: string,
});

Select.propTypes = {
  value: oneOfType([valueType, arrayOf(valueType)]),
  onChange: func.isRequired,
  options: oneOfType([
    arrayOf(valueType),
    arrayOf(shape({ label: string, options: arrayOf(valueType) })),
  ]).isRequired,
  className: string,
  theme: func,
  selectStyles: object,
  disabled: bool,
  closeMenuOnSelect: bool,
  label: string,
  name: string,
  placeholder: string,
  isMulti: bool,
  error: string,
  touched: bool,
  icon: string,
};

export { Select };
