import React, { memo } from 'react';
import Toggle from 'react-toggle';
import { func, bool } from 'prop-types';
import 'react-toggle/style.css';

import styles from './ToggleSwitch.module.scss';

const ToggleSwitch = memo(({ checked = false, onChange, disabled = false }) => (
  <Toggle
    icons={false}
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    className={styles.toggle}
  />
));

ToggleSwitch.propTypes = {
  onChange: func.isRequired,
  checked: bool,
  disabled: bool,
};

export { ToggleSwitch };
