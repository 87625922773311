import React from 'react';
import { useDispatch } from 'react-redux';

import { FtuTip } from 'src/pages/project/ftu-tip';
import { closeProductTip } from 'src/actions/userSettingsActions';
import styles from './CollageTip.module.scss';

const CollageTip = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeProductTip('showSquadEventCollageTip'));
  };

  return (
    <FtuTip
      title="Make a Creator Collage"
      subtitle="We can automatically design a collage with your images to help inspire creativity!
        Try it out."
      onClose={onClose}
      className={styles.container}
      closeOnClickOutside={false}
    />
  );
};

export { CollageTip };
