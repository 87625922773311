import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
  KEY_D_CHARACTER_CODE,
  KEY_L_CHARACTER_CODE,
  KEY_O_CHARACTER_CODE,
  KEY_R_CHARACTER_CODE,
  KEY_T_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import { addTextToCanvas } from 'src/utils/fontHelpers';
import { useKeyboardListener, useStageCenteredPosition } from 'src/hooks';
import {
  addLineElement,
  addCircleElement,
  addSquareElement,
  addRoundedRectElement,
  addTextElement,
} from 'src/actions/projectActions';
import { isHtmlTagFocused } from 'src/utils/helpers';
import { FONT_SIZE_OPTIONS } from 'src/constants/fonts';
import { CANVAS_TEXT_PADDING, TEXT_DEFAULT_LINE_HEIGHT } from 'src/constants/general';

const useAddElementShortcut = () => {
  const dispatch = useDispatch();

  const { fontMemory } = useSelector(({ projectMemory }) => ({
    fontMemory: projectMemory.font,
  }));

  const keyDPressed = useKeyboardListener(KEY_D_CHARACTER_CODE);
  const keyLPressed = useKeyboardListener(KEY_L_CHARACTER_CODE);
  const keyOPressed = useKeyboardListener(KEY_O_CHARACTER_CODE);
  const keyRPressed = useKeyboardListener(KEY_R_CHARACTER_CODE);
  const keyTPressed = useKeyboardListener(KEY_T_CHARACTER_CODE);

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);
  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const position = useStageCenteredPosition();

  const addRoundedRect = useCallback(() => {
    dispatch(addRoundedRectElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addLine = useCallback(() => {
    dispatch(addLineElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addCircle = useCallback(() => {
    dispatch(addCircleElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addSquare = useCallback(() => {
    dispatch(addSquareElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addText = useCallback(() => {
    addTextToCanvas(fontMemory, () => {
      const sizeProps = { width: 500, fontSize: FONT_SIZE_OPTIONS[10].value };
      dispatch(addTextElement({
        x: position.x - sizeProps.width / 2,
        y: position.y - (sizeProps.fontSize * TEXT_DEFAULT_LINE_HEIGHT) / 2,
        height: sizeProps.fontSize * TEXT_DEFAULT_LINE_HEIGHT + CANVAS_TEXT_PADDING * 2,
        fontMemory,
        ...sizeProps,
      }));
    });
  }, [dispatch, fontMemory, position.x, position.y]);

  useEffect(() => {
    if (!isHtmlTagFocused() && !commandPressed && !controlPressed) {
      if (keyDPressed) {
        addRoundedRect();
      } else if (keyLPressed) {
        addLine();
      } else if (keyOPressed) {
        addCircle();
      } else if (keyRPressed) {
        addSquare();
      } else if (keyTPressed) {
        addText();
      }
    }
  }, [
    addCircle,
    addLine,
    addRoundedRect,
    addSquare,
    addText,
    dispatch,
    keyDPressed,
    keyLPressed,
    keyOPressed,
    keyRPressed,
    keyTPressed,
    commandPressed,
    controlPressed,
  ]);
};

export { useAddElementShortcut };
