import React from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';

import { useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { AddAccountForm } from 'src/pages/account-settings/accounts-manager/add-account-form';

const ADD_ACCOUNT_FORM_ID = 'addAccount';

const AddAccount = ({ disabled, openFormId, setOpenFormId }) => {
  const { user } = useSession();

  const onAddAccount = () => {
    setOpenFormId(ADD_ACCOUNT_FORM_ID);
  };

  const onCancel = () => {
    setOpenFormId();
  };

  const maxAmountOfAccounts = user.membership.subscription.quantity;

  return (
    <>
      {openFormId === ADD_ACCOUNT_FORM_ID ? (
        <AddAccountForm onCancel={onCancel} />
      ) : (
        <Button
          onClick={onAddAccount}
          disabled={disabled || user.membership.accounts.length >= maxAmountOfAccounts}
          secondary
        >
          Add Account
        </Button>
      )}
    </>
  );
};

AddAccount.propTypes = {
  disabled: bool.isRequired,
  openFormId: oneOfType([string, number]),
  setOpenFormId: func.isRequired,
};

export { AddAccount };
