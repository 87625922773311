import React, { useCallback, useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { Button } from 'src/common/button';
import { routesPaths } from 'src/routes/routesPaths';
import { Input } from 'src/common/input';
import { validateChangePassword } from 'src/utils/constrains';
import { changePassword, CHANGE_PASSWORD } from 'src/actions/userActions';
import { reset } from 'src/actions/statusActions';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import styles from './ChangePasswordForm.module.scss';

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { status, error } = useStatus(CHANGE_PASSWORD);

  const changePasswordRequest = useCallback(async (values) => {
    const validateValues = async () => {
      const data = {
        token,
        newPassword: values?.newPassword,
      };
      await dispatch(changePassword(data));
    };
    validateValues();
  }, [dispatch, token]);

  useEffect(() => () => dispatch(reset(CHANGE_PASSWORD)), [dispatch]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(changePasswordRequest, { validate: validateChangePassword });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  if (status === SUCCESS) {
    return <Redirect to={{ pathname: routesPaths.signin, state: { isReset: true } }} />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="newPassword"
        type="password"
        label="New Password"
        {...inputProps('newPassword')}
      />
      <Input
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        {...inputProps('confirmPassword')}
      />
      {status === ERROR && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
          <Link to={routesPaths.forgotPassword} className={styles.link}>Resend</Link>
        </div>
      )}
      <Button type="submit" className={styles.button} loading={status === LOADING}>
        Reset Password
      </Button>
    </form>
  );
};

export { ChangePasswordForm };
