import React, { memo, forwardRef } from 'react';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';

import styles from './TextArea.module.scss';

const TextArea = memo(forwardRef(({
  label,
  name,
  onChange,
  value = '',
  error = '',
  touched = false,
  placeholder = '',
  containerClassName = '',
  inputClassName = '',
}, ref) => {
  const hasError = error && touched;
  return (
    <div className={cn(styles.container, containerClassName)}>
      {label && <label htmlFor={name} className={styles.label}>{label}</label>}
      <textarea
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={cn(styles.input, inputClassName, {
          [styles.inputError]: hasError,
        })}
        ref={ref}
      />
      {hasError && <span className={styles.error}>{error}</span>}
    </div>
  );
}));

TextArea.propTypes = {
  name: string.isRequired,
  label: string,
  error: string,
  onChange: func.isRequired,
  value: string,
  touched: bool,
  placeholder: string,
  containerClassName: string,
  inputClassName: string,
};

export { TextArea };
