import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';

import EditIcon from 'src/assets/icons/edit.svg';
import DeleteIcon from 'src/assets/icons/delete-tool-dark.svg';
import { CollapsibleOptions } from 'src/common/collapsible-options';
import { CategoryNameInput } from './category-name-input';
import styles from './CategoryName.module.scss';

const CategoryName = ({ name, onClickDelete, updateCategory }) => {
  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const [writeMode, setWriteMode] = useState();

  const onClickRename = () => {
    setWriteMode(true);
  };

  const onUpdatedSuccess = () => {
    setWriteMode(false);
  };

  const onCancel = () => {
    setWriteMode(false);
  };

  const options = [
    {
      text: 'Rename',
      action: onClickRename,
      posfix: <img src={EditIcon} alt="Rename" className={styles.optionIcon} />,
    },
    {
      text: 'Delete',
      action: onClickDelete,
      posfix: <img src={DeleteIcon} alt="Delete" className={styles.optionIcon} />,
      className: styles.lastOption,
    },
  ];

  return (
    writeMode ? (
      <CategoryNameInput
        defaultName={name}
        updateCategory={updateCategory}
        onUpdatedSuccess={onUpdatedSuccess}
        onCancel={onCancel}
      />
    ) : (
      <CollapsibleOptions
        options={options}
        optionsClassName={styles.options}
        optionClassName={styles.option}
        className={styles.optionsContainer}
        buttonClassName={styles.optionsButton}
        closeOnClickOption
        disabled={disabled}
      >
        <span className={styles.text}>
          {name}
        </span>
      </CollapsibleOptions>
    )
  );
};

CategoryName.propTypes = {
  name: string.isRequired,
  onClickDelete: func.isRequired,
  updateCategory: func.isRequired,
};

export { CategoryName };
