import { useEffect } from 'react';

import {
  PLUS_NUMPAD_CODE,
  MINUS_NUMPAD_CODE,
  MINUS_CODE,
  EQUAL_CODE,
} from 'src/constants/keyboardCodes';
import { isHtmlTagFocused } from 'src/utils/helpers';
import { useKeyboardListener, useKeyboardReset } from './useKeyboardListener';
import { useZoomOptions } from './useZoomOptions';

const useZoomShortcut = () => {
  const plusNumpadPressed = useKeyboardListener(PLUS_NUMPAD_CODE);
  const plusPressed = useKeyboardListener(EQUAL_CODE); // Equal and Plus are in the same key

  const minusNumpadPressed = useKeyboardListener(MINUS_NUMPAD_CODE);
  const minusPressed = useKeyboardListener(MINUS_CODE);

  const resetKey = useKeyboardReset();

  const { onClickMinus, onClickPlus } = useZoomOptions();

  useEffect(() => {
    if (!isHtmlTagFocused()) {
      if (plusNumpadPressed) {
        onClickPlus();
        resetKey(PLUS_NUMPAD_CODE);
      }
      if (plusPressed) {
        onClickPlus();
        resetKey(EQUAL_CODE);
      }
      if (minusNumpadPressed) {
        onClickMinus();
        resetKey(MINUS_NUMPAD_CODE);
      }
      if (minusPressed) {
        onClickMinus();
        resetKey(MINUS_CODE);
      }
    }
  }, [
    resetKey,
    onClickPlus,
    onClickMinus,
    plusNumpadPressed,
    minusNumpadPressed,
    plusPressed,
    minusPressed,
  ]);
};

export { useZoomShortcut };
