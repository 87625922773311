import React, { useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { changeControlOpen } from 'src/actions/canvasActions';
import { useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { cleanSelectedElement } from 'src/actions/projectActions';
import styles from './Control.module.scss';

const Control = ({ icon, control, text = '', id, iconClassName, disabled, onClickCallback }) => {
  const dispatch = useDispatch();

  const { controlOpen } = useSelector(({ canvas }) => ({ controlOpen: canvas.controlOpen }));

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const onClick = useCallback(() => {
    if (controlOpen === control) {
      dispatch(changeControlOpen(''));
    } else {
      dispatch(changeControlOpen(control));
      if (isMobile) {
        dispatch(cleanSelectedElement());
      }
    }
    if (onClickCallback) {
      onClickCallback();
    }
  }, [control, controlOpen, dispatch, isMobile, onClickCallback]);

  return (
    <button
      className={cn(styles.container, { [styles.open]: controlOpen === control })}
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      <img src={icon} alt="" className={cn(styles.icon, iconClassName)} />
      {!isMobile && <span className={styles.text}>{text}</span>}
    </button>
  );
};

Control.propTypes = {
  icon: string.isRequired,
  control: string.isRequired,
  text: string,
  id: string,
  iconClassName: string,
  disabled: bool,
  onClickCallback: func,
};

export { Control };
