import { TEXT_ELEMENT } from 'src/constants/canvasElements';
import {
  FILL_COLOR_EYEDROPPER_SOURCE,
  BORDER_COLOR_EYEDROPPER_SOURCE,
  SHADOW_COLOR_EYEDROPPER_SOURCE,
  TEXT_COLOR_EYEDROPPER_SOURCE,
} from 'src/constants/general';

const useElementColor = (isSelected, eyeDropper, elementType, { fill, border, shadow }) => {
  const fillEyedropperSource = elementType === TEXT_ELEMENT ?
    TEXT_COLOR_EYEDROPPER_SOURCE :
    FILL_COLOR_EYEDROPPER_SOURCE;

  const fillColor = isSelected && eyeDropper.active &&
    eyeDropper.source === fillEyedropperSource ?
    eyeDropper.color :
    fill;

  const borderColor = isSelected && eyeDropper.active &&
    eyeDropper.source === BORDER_COLOR_EYEDROPPER_SOURCE ?
    eyeDropper.color :
    border;

  const dropShadow = isSelected && eyeDropper.active &&
    eyeDropper.source === SHADOW_COLOR_EYEDROPPER_SOURCE ?
    { hex: eyeDropper.color, alpha: 100 } :
    shadow;

  return { fillColor, borderColor, dropShadow };
};

export { useElementColor };
