import React from 'react';
import { useDispatch } from 'react-redux';

import { setEditableFrameTitle } from 'src/actions/canvasActions';
import { Modal } from 'src/common/modal';
import { useProjectSelector } from 'src/hooks';
import { RenameFrameTitleForm } from './rename-frame-title-form';
import styles from './RenameFrameTitleModal.module.scss';

const RenameFrameTitleModal = () => {
  const dispatch = useDispatch();

  const { frameTitles, editableFrameTitle } = useProjectSelector();

  const hide = () => {
    dispatch(setEditableFrameTitle());
  };

  return (
    <Modal
      isShowing
      hide={hide}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Canvas {editableFrameTitle.frame.index + 1}
        </span>
        <RenameFrameTitleForm
          defaultTitle={frameTitles[editableFrameTitle.frame.index]}
          onCancel={hide}
        />
      </div>
    </Modal>
  );
};

export { RenameFrameTitleModal };
