import React, { useRef, useCallback } from 'react';
import { Rect } from 'react-konva';
import { string, number, shape, bool, object, func, arrayOf } from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { updateElement } from 'src/actions/projectActions';
import {
  useMoveTransformElement,
  useKeyboardListener,
  useOnClickElement,
  useDraggableElement,
  useElementColor,
  useDecreaseCanvasSize,
} from 'src/hooks';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { getRGBAColor } from 'src/utils/helpers';
import {
  getPosDragBoundFunc,
  getStrokeDash,
  getShadowOffset,
  getFillLinearGradientColorStops,
} from 'src/utils/canvasHelpers';
import { ROUNDED_RECT_ELEMENT } from 'src/constants/canvasElements';
import { ALIGNMENT_GUIDE_NAME } from 'src/utils/alignmentGuidesHelpers';
import { CANVAS_DRAWING_LAYER } from 'src/constants/general';

const CanvasRoundedRectComponent = ({
  uuid,
  x,
  y,
  width,
  height,
  color,
  borderColor: { hex: borderHex },
  strokeWidth,
  selectedElement: selectedElementId,
  isInGroup,
  rotation,
  selectedRef,
  scale,
  eyeDropper,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  strokeDash = [],
  shadowEnabled = false,
  shadowOffsetX,
  shadowOffsetY,
  shadowBlur,
  shadowColor = { alpha: 0 },
  fillLinearGradientEnabled = false,
  fillLinearGradientColorStops,
  fillLinearGradientStartPoint = {},
  fillLinearGradientEndPoint = {},
  pinchZoomGesture,
}) => {
  const dispatch = useDispatch();
  const refRoundedRect = useRef();
  const refPositions = useRef({ positions: [], posIndex: 0 });

  const {
    moveElementRequest,
    selectElementRequest,
    onDragMoveScroll,
    onDragEndScroll,
    allowDraggingElement,
  } = useMoveTransformElement(selectedElementId, uuid, refRoundedRect, selectedRef);

  const transformRoundedRectRequest = useCallback(({ target, evt }) => {
    if (!refRoundedRect?.current) {
      return;
    }
    const { attrs } = target;
    const attrsScaleX = attrs.scaleX;
    const attrsScaleY = attrs.scaleY;
    refRoundedRect.current.setAttrs({
      scaleX: 1,
      scaleY: 1,
    });
    const newAttrs = {
      uuid: attrs.id,
      x: attrs.x,
      y: attrs.y,
      rotation: attrs.rotation,
      width: attrs.width * attrsScaleX,
      height: Math.abs(attrs.height * attrsScaleY),
    };
    dispatch(updateElement(newAttrs, evt.timeStamp));
    const drawingLayer = refRoundedRect.current.findAncestor(`#${CANVAS_DRAWING_LAYER}`);
    const guides = drawingLayer.find(`.${ALIGNMENT_GUIDE_NAME}`);
    guides.forEach(guide => {
      guide.destroy();
    });
  }, [dispatch]);

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const onDragStart = (e) => {
    if (!isInGroup) {
      const canDrag = allowDraggingElement(e);
      if (!canDrag) {
        return;
      }
      const isSelected = selectedElementId === uuid;
      !isSelected && selectElementRequest(e);
      hideTransformerAnchors();
    }
  };

  const onDragEnd = (e) => {
    if (!isInGroup && e.evt) {
      moveElementRequest(e);
      showTransformerAnchors();
      onDragEndScroll();
    }
  };

  const onDragMove = (e) => {
    if (!isInGroup) {
      onDragMoveScroll(e);
    }
  };

  const { onClick } = useOnClickElement(
    isInGroup,
    selectedElementId !== uuid,
    selectElementRequest,
  );

  const { draggableElement } = useDraggableElement(isInGroup);

  const colorRGB = getRGBAColor(color);
  const { fillColor, borderColor, dropShadow } = useElementColor(
    selectedElementId === uuid,
    eyeDropper,
    ROUNDED_RECT_ELEMENT,
    { fill: colorRGB, border: borderHex, shadow: shadowColor },
  );

  useDecreaseCanvasSize(refRoundedRect, isInGroup);

  return (
    <Rect
      id={uuid}
      cornerRadius={25}
      ref={refRoundedRect}
      x={x}
      y={y}
      fill={!fillLinearGradientEnabled ? fillColor : undefined}
      stroke={borderColor}
      strokeWidth={strokeWidth}
      strokeScaleEnabled={false}
      dash={getStrokeDash(strokeDash, strokeWidth, scale)}
      width={width}
      height={height}
      offsetX={width / 2}
      offsetY={height / 2}
      draggable={draggableElement}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onClick={onClick}
      onTap={onClick}
      onTransformEnd={!isInGroup && transformRoundedRectRequest}
      onTransform={onTransformElement}
      rotation={rotation}
      dragBoundFunc={(pos) => getPosDragBoundFunc(
        refPositions.current,
        pos,
        1 / scale,
        refRoundedRect.current?.absolutePosition(),
        shiftShortcutPressed,
      )}
      shadowOpacity={dropShadow.alpha / 100}
      shadowColor={dropShadow.hex}
      shadowBlur={shadowBlur}
      shadowOffset={getShadowOffset(shadowOffsetX, shadowOffsetY, strokeWidth)}
      shadowEnabled={shadowEnabled}
      elementType={ROUNDED_RECT_ELEMENT}
      listening={!pinchZoomGesture && !eyeDropper.active}
      perfectDrawEnabled={false}
      shadowForStrokeEnabled={false}
      fillLinearGradientStartPoint={fillLinearGradientStartPoint}
      fillLinearGradientEndPoint={fillLinearGradientEndPoint}
      fillLinearGradientColorStops={getFillLinearGradientColorStops(fillLinearGradientColorStops)}
    />
  );
};

CanvasRoundedRectComponent.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  strokeWidth: number.isRequired,
  color: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  borderColor: shape({
    hex: string,
    alpha: number,
  }).isRequired,
  selectedElement: string,
  isInGroup: bool,
  rotation: number.isRequired,
  selectedRef: object,
  scale: number,
  eyeDropper: shape({ active: bool }),
  onTransformElement: func,
  hideTransformerAnchors: func,
  showTransformerAnchors: func,
  strokeDash: arrayOf(number),
  shadowOffsetX: number,
  shadowOffsetY: number,
  shadowBlur: number,
  shadowColor: shape({
    hex: string,
    alpha: number,
  }),
  shadowEnabled: bool,
  fillLinearGradientEnabled: bool,
  fillLinearGradientColorStops: arrayOf(shape({
    color: string,
    offset: string,
  })),
  fillLinearGradientStartPoint: shape({ x: number, y: number }),
  fillLinearGradientEndPoint: shape({ x: number, y: number }),
  pinchZoomGesture: bool,
};

const mapStateToProps = (store, ownProps) => ({
  selectedElement: store.project.present.selectedElements[ownProps.uuid],
  selectedRef: store.project.present.selectedRefs[ownProps.uuid],
  scale: store.canvas.scale,
  eyeDropper: store.canvas.eyeDropper,
  pinchZoomGesture: store.canvas.pinchZoomGesture,
});

const CanvasRoundedRect = connect(mapStateToProps)(CanvasRoundedRectComponent);

export { CanvasRoundedRect };
