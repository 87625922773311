import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import BannerImage2x from 'src/assets/images/template-banner@2x.png';
import BannerImageSmall from 'src/assets/images/template-banner-small@2x.png';
import { useWindowSize } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './Banner.module.scss';

const Banner = () => {
  const [img, setImg] = useState();

  const { windowSize: { width } } = useWindowSize();

  useEffect(() => {
    if (width <= MOBILE_THRESHOLD_WIDTH && img !== BannerImageSmall) {
      setImg(BannerImageSmall);
    } else if (width > MOBILE_THRESHOLD_WIDTH && img !== BannerImage2x) {
      setImg(BannerImage2x);
    }
  }, [img, width]);

  return (
    <div className={styles.container}>
      <Link
        to={`${routesPaths.templates}?seeAll=${process.env.REACT_APP_TEMPLATE_BANNER_CATEGORY_ID}`}
        className={styles.link}
      >
        <img
          src={img}
          alt=""
          className={styles.image}
        />
      </Link>
    </div>
  );
};

export { Banner };
