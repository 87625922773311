import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { removeElementOnFrameDeletion } from 'src/actions/projectActions';
import { usePrevious } from 'src/hooks';
import { useProjectSelector } from './useProjectSelector';

const useDecreaseCanvasSize = (ref, isInGroup) => {
  const dispatch = useDispatch();

  const { scale, stageOffset, draggableOffset } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
    draggableOffset: canvas.draggableOffset,
  }));

  const { size, layout } = useProjectSelector();

  const prevSize = usePrevious(size);

  useEffect(() => {
    if (prevSize && prevSize > size && !isInGroup && ref?.current) {
      const box = ref.current.getClientRect();
      if (box.x / scale - stageOffset.x - draggableOffset.x > layout.width * size) {
        const uuid = ref.current.attrs.id;
        dispatch(removeElementOnFrameDeletion({ id: uuid }));
      }
    }
  }, [
    prevSize,
    size,
    isInGroup,
    scale,
    stageOffset.x,
    ref,
    layout,
    draggableOffset.x,
    dispatch,
  ]);
};

export { useDecreaseCanvasSize };
