import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { validateEmailForgotPassword } from 'src/utils/constrains';
import { sendEmailResetPassword, SEND_EMAIL_PASSWORD } from 'src/actions/userActions';
import { LOADING } from 'src/constants/status';
import styles from './ForgotPasswordForm.module.scss';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { status } = useStatus(SEND_EMAIL_PASSWORD);

  const sendEmailResetPasswordRequest = useCallback(async (values) => {
    await dispatch(sendEmailResetPassword(values));
  }, [dispatch]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(sendEmailResetPasswordRequest, { validate: validateEmailForgotPassword });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="email"
        type="email"
        label="Email"
        {...inputProps('email')}
      />
      <Button type="submit" className={styles.button} loading={status === LOADING}>
        Send Reset Instructions
      </Button>
    </form>
  );
};

export { ForgotPasswordForm };
