import { useDispatch, useSelector } from 'react-redux';

import { changeControlOpen } from 'src/actions/canvasActions';
import { addSelectedElement, removeSelectedElement } from 'src/actions/projectActions';
import { showProductTip } from 'src/actions/userSettingsActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { SHOWING_STATUS } from 'src/constants/general';
import { SHIFT_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { useKeyboardListener, useProjectSelector, useWindowSize, useSession } from 'src/hooks';

const useShiftAddElements = (refElement) => {
  const dispatch = useDispatch();

  const { controlOpen, selectingMultipleMobile } = useSelector(({ canvas }) => ({
    controlOpen: canvas.controlOpen,
    selectingMultipleMobile: canvas.selectingMultipleMobile,
  }));

  const { selectedElements } = useProjectSelector();

  const { user } = useSession();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const addElementRequest = (id, keepEditableText = false, wasFiredInCode = false) => {
    if (shiftShortcutPressed || selectingMultipleMobile) {
      if (selectedElements[id]) {
        dispatch(removeSelectedElement(id));
      } else {
        dispatch(addSelectedElement({ id, refElement }));
      }
    } else if (wasFiredInCode) {
      dispatch(addSelectedElement({ id, refElement }));
    } else {
      dispatch(addSelectedElement({ id, clean: true, refElement, keepEditableText }));
    }
    if (isMobile) {
      if (controlOpen) {
        dispatch(changeControlOpen(''));
      }
      if (user.settings.showElementResizeMobileTip === SHOWING_STATUS.DONT_SHOW) {
        dispatch(showProductTip('showElementResizeMobileTip'));
      }
    }
  };

  return {
    addElementRequest,
  };
};

export { useShiftAddElements };
