import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chunk from 'lodash.chunk';

import { LOADING } from 'src/constants/status';
import { GET_SUGGESTIONS, getSuggestions } from 'src/actions/insightActions';
import { useStatus, usePagination, useSession } from 'src/hooks';
import { changeControlOpen } from 'src/actions/canvasActions';
import { TEMPLATES_CONTROL } from 'src/constants/canvasControls';
import { TemplateGrid } from 'src/pages/templates/template-grid';
import {
  useAmountOfTemplatesInARow,
} from 'src/pages/project/controls-options/templates-control/useAmountOfTemplates';
import styles from './SeeAllSuggestions.module.scss';

const PER_PAGE = 12;

const SeeAllSuggestions = () => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const { amountOfTemplatesInARow } = useAmountOfTemplatesInARow();

  const goToTemplates = () => {
    dispatch(changeControlOpen(TEMPLATES_CONTROL));
  };

  const { suggestions, suggestionsPages } = useSelector(({ insights }) => ({
    suggestions: insights.suggestions,
    suggestionsPages: insights.suggestionsPages,
  }));

  const { status: getSuggestionsStatus } = useStatus(GET_SUGGESTIONS);

  const handleFetch = useCallback(async (page) => {
    await dispatch(getSuggestions(accountId, page, PER_PAGE));
  }, [dispatch, accountId]);

  const { page, nextPage, prevPage } = usePagination(handleFetch);

  let isNextPageDisabled = false;
  if (suggestionsPages?.total) {
    const { total, current } = suggestionsPages;
    isNextPageDisabled = current === total;
  }

  useEffect(() => {
    dispatch(getSuggestions(accountId, 1, PER_PAGE));
  }, [dispatch, accountId]);

  const rows = chunk(suggestions, amountOfTemplatesInARow);

  return (
    <TemplateGrid
      isMainHeader
      name="Recommended for me"
      goToTemplates={goToTemplates}
      showLoading={getSuggestionsStatus === LOADING}
      suggestions={rows}
      status={getSuggestionsStatus}
      page={page}
      isNextPageDisabled={isNextPageDisabled}
      prevPage={prevPage}
      nextPage={nextPage}
      amountOfTemplatesInARow={amountOfTemplatesInARow}
      templateCardClassName={styles.card}
      titleClassName={styles.title}
    />
  );
};

export { SeeAllSuggestions };
