import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { arrayOf, node, object, oneOfType, string } from 'prop-types';
import cn from 'classnames';

import { Alert } from 'src/common/alert';
import { Button } from 'src/common/button';
import { useSession } from 'src/hooks';
import { createCustomerPortalSession } from 'src/actions/userActions';
import { routesPaths } from 'src/routes/routesPaths';
import { TRIALING } from 'src/constants/subscriptionStatuses';
import { getStringCapitalized } from 'src/utils/helpers';
import { getMembershipAccount } from 'src/utils/sessionHelpers';
import styles from './UpgradeAlert.module.scss';

const UpgradeAlert = ({
  upgradeChildren,
  plans,
  containerClassName,
  buttonClassName,
  upgradeButtonText = 'Upgrade now',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { user, accountId, isSubscriptionActive, membershipPlan } = useSession();

  const { customerId, membership } = user;

  const upgradeRequest = useCallback(() => {
    if (membership.subscription && customerId) {
      dispatch(createCustomerPortalSession(customerId, pathname));
    } else {
      history.push(routesPaths.choosePlan, { pathname });
    }
  }, [customerId, membership.subscription, dispatch, history, pathname]);

  const notActiveSubscription = !isSubscriptionActive &&
    plans.includes(membershipPlan);
  const isTrial = membership?.subscription?.status === TRIALING;
  const showActionButton = useMemo(() => getMembershipAccount(user, accountId), [user, accountId]);
  return (
    <Alert containerClassName={containerClassName}>
      {notActiveSubscription ? (
        <>
          <span className={styles.text}>
            {isTrial ?
              `Your ${getStringCapitalized(user.membership?.plan)}
              Trial has ended. Don’t let this be the end of us.` :
              'Your subscription is paused. You need to update your payment method.'}
          </span>
          {showActionButton && (
          <Button
            className={cn(styles.button, buttonClassName)}
            onClick={upgradeRequest}
            secondary
            short
          >
            {isTrial ? 'Upgrade now' : 'Update'}
          </Button>
          )}
        </>
      ) : (
        <>
          {upgradeChildren}
          {showActionButton && (
          <Button
            className={cn(styles.button, buttonClassName)}
            onClick={upgradeRequest}
            secondary
            short
          >
            {upgradeButtonText}
          </Button>
          )}
        </>
      )}

    </Alert>
  );
};

UpgradeAlert.propTypes = {
  upgradeChildren: oneOfType([node, object]).isRequired,
  plans: arrayOf(string).isRequired,
  containerClassName: string,
  buttonClassName: string,
  upgradeButtonText: string,
};

export { UpgradeAlert };
