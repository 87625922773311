import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useProjectMedia } from 'src/hooks';
import { Button } from 'src/common/button';
import { applyTemplateToProject } from 'src/actions/productTemplateActions';
import { saveSelectedTemplatePreview } from 'src/actions/templatesActions';
import { changeControlOpen } from 'src/actions/canvasActions';
import { PreviewModal } from 'src/pages/templates/preview-template/preview-modal';
import { TemplateMediaModal } from './template-media-modal';
import styles from './PreviewTemplate.module.scss';

const USE_TEMPLATE_BUTTON_ID = 'useTemplateButton';

const PreviewTemplate = () => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { media } = useProjectMedia();

  const useTemplate = () => {
    if (!media.length) {
      dispatch(applyTemplateToProject());
      dispatch(saveSelectedTemplatePreview(undefined));
      dispatch(changeControlOpen(''));
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <PreviewModal
        disableClickOutside={modalOpen}
        actionButton={(
          <Button
            onClick={useTemplate}
            alternativeCTA
            className={styles.useTemplate}
            id={USE_TEMPLATE_BUTTON_ID}
          >
            Use Template
          </Button>
        )}
        actionButtonId={USE_TEMPLATE_BUTTON_ID}
      />
      <TemplateMediaModal
        isShowing={modalOpen}
        hide={() => setModalOpen(false)}
        media={media}
      />
    </>
  );
};

export { PreviewTemplate };
