import React from 'react';

import { useSession } from 'src/hooks';
import styles from './AccountsAsEditor.module.scss';

const AccountsAsEditor = () => {
  const { user } = useSession();

  const accountsAsEditors = user.teams?.map(team => team.account);
  if (accountsAsEditors.length === 0) {
    return null;
  }
  return (
    <>
      <div className={styles.container}>
        <span className={styles.header}>
          Other accounts I&apos;m a part of ({accountsAsEditors.length})
        </span>
        {accountsAsEditors.map(account => (
          <div key={account.id} className={styles.cardContainer}>
            <div className={styles.card}>
              <span className={styles.name}>{account.brandName}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { AccountsAsEditor };
