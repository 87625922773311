import { useEffect, useCallback, useRef } from 'react';
import Konva from 'konva';
import { useSelector, useDispatch } from 'react-redux';

import { useWindowSize, useProjectSelector } from 'src/hooks';
import { saveZoom, setPinchZoomGesture } from 'src/actions/canvasActions';
import { SMALL_WIDTH } from 'src/constants/breakpoints';

const useTouchEventsCanvas = (refDrawingLayer) => {
  const dispatch = useDispatch();

  const { windowSize } = useWindowSize();

  const { selectedElements } = useProjectSelector();

  useEffect(() => {
    if (windowSize.width <= SMALL_WIDTH) {
      Konva.hitOnDragEnabled = true;
    }
  }, [windowSize.width]);

  const { pinchZoomGesture } = useSelector(({ canvas }) => ({
    pinchZoomGesture: canvas.pinchZoomGesture,
  }));

  const setPointerPositionWithTouch = useCallback((e) => {
    const stage = refDrawingLayer?.current?.getStage();
    if (stage) {
      stage.setPointersPositions(e);
    }
  }, [refDrawingLayer]);

  useEffect(() => {
    window.addEventListener('touchend', setPointerPositionWithTouch);
    return () => {
      window.removeEventListener('touchend', setPointerPositionWithTouch);
    };
  }, [refDrawingLayer, setPointerPositionWithTouch]);

  const lastDist = useRef();

  const getDistance = (p1, p2) => Math.sqrt(((p2.x - p1.x) ** 2) + ((p2.y - p1.y) ** 2));

  const onTouchMoveDrawingLayer = (e) => {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    const areElementsSelected = Object.keys(selectedElements).length;

    if (touch1 && touch2 && !areElementsSelected) {
      if (!refDrawingLayer?.current || !lastDist) {
        return;
      }
      // If the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (refDrawingLayer.current.isDragging()) {
        refDrawingLayer.current.stopDrag();
      }
      if (!pinchZoomGesture) {
        dispatch(setPinchZoomGesture(true));
      }

      const p1 = { x: touch1.clientX, y: touch1.clientY };
      const p2 = { x: touch2.clientX, y: touch2.clientY };

      const dist = getDistance(p1, p2);

      if (!lastDist.current) {
        lastDist.current = dist;
      }

      const stage = refDrawingLayer.current.getStage();

      const newScale = stage.scaleX() * (dist / lastDist.current);

      stage.scaleX(newScale);
      stage.scaleY(newScale);

      lastDist.current = dist;
    }
    // eslint-disable-next-line consistent-return
    return true;
  };

  const onTouchEndStage = () => {
    if (!refDrawingLayer?.current || !lastDist) {
      return;
    }
    if (lastDist.current) {
      lastDist.current = 0;
      const stage = refDrawingLayer.current.getStage();
      dispatch(saveZoom({ zoom: stage.scaleX() * 100 }));
    }
  };

  return { onTouchMoveDrawingLayer, onTouchEndStage };
};

export { useTouchEventsCanvas };
