import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { string, bool, object } from 'prop-types';
import cn from 'classnames';

import { LOADING } from 'src/constants/status';
import { getStringCapitalized } from 'src/utils/helpers';
import {
  createCheckoutSessionSubscription,
  createFreeTrial,
  CREATE_CHECKOUT_SESSION,
  CREATE_FREE_TRIAL,
} from 'src/actions/userActions';
import CheckIcon from 'src/assets/icons/check-grey.svg';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { useStatus } from 'src/hooks';
import styles from './PlanCard.module.scss';

const PlanCard = ({
  planMembership,
  className = '',
  isMainContainer = false,
  disabled = false,
  buttonText = 'Select Plan',
  pathname = routesPaths.projects,
  type,
  freeTrial = false,
}) => {
  const dispatch = useDispatch();

  const { plan, price, promo, features, comingSoonFeatureIndex } = planMembership;

  const planCapitalized = getStringCapitalized(plan);

  const {
    status: freeTrialStatus,
    statusInfo: freeTrialStatusInfo,
  } = useStatus(CREATE_FREE_TRIAL);
  const {
    status: checkoutSessionStatus,
    statusInfo: checkoutSessionStatusInfo,
  } = useStatus(CREATE_CHECKOUT_SESSION);

  const createCheckoutSessionRequest = useCallback(() => {
    if (freeTrial) {
      dispatch(createFreeTrial(plan, type));
    } else {
      dispatch(createCheckoutSessionSubscription(pathname, plan, type));
    }
  }, [dispatch, pathname, plan, type, freeTrial]);

  const loading = (freeTrialStatus === LOADING && freeTrialStatusInfo.plan === planCapitalized) ||
    (checkoutSessionStatus === LOADING && checkoutSessionStatusInfo.plan === planCapitalized);

  return (
    <div className={cn(styles.planCard, { [styles.mainPlanCard]: isMainContainer })}>
      <div className={cn(styles.card, { [styles.mainCard]: isMainContainer })}>
        <div className={cn(styles.top, className)} />
        <div className={cn(styles.container, { [styles.mainContainer]: isMainContainer })}>
          <div className={styles.body}>
            <div className={styles.header}>
              <span className={styles.plan}>{planCapitalized}</span>
              <div className={styles.prices}>
                <span className={styles.price}>{price}</span>
                <span className={styles.month}>mo</span>
              </div>
              <span className={styles.promo}>{promo}</span>
            </div>
            {features.map((item, index) => (
              <div className={styles.item} key={item}>
                <img src={CheckIcon} alt="Check" />
                <span>{item}</span>
                {comingSoonFeatureIndex === index && (
                  <div className={styles.comingSoon}>COMING SOON</div>
                )}
              </div>
            ))}
          </div>
          <Button
            className={styles.selectPlan}
            onClick={createCheckoutSessionRequest}
            alternativeCTA
            disabled={disabled}
            loading={loading}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};


PlanCard.propTypes = {
  planMembership: object.isRequired,
  className: string,
  pathname: string,
  isMainContainer: bool,
  disabled: bool,
  buttonText: string,
  type: string.isRequired,
  freeTrial: bool,
};

export { PlanCard };
