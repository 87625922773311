import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useForm, useTextInputProps, useStatus } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { Link } from 'react-router-dom';
import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { validateLogin } from 'src/utils/constrains';
import { login, LOGIN } from 'src/actions/userActions';
import { reset } from 'src/actions/statusActions';
import { ERROR, LOADING } from 'src/constants/status';
import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const dispatch = useDispatch();

  const { status, error } = useStatus(LOGIN);

  const loginRequest = useCallback(async (values) => {
    await dispatch(login(values));
  }, [dispatch]);

  useEffect(() => () => dispatch(reset(LOGIN)), [dispatch]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(loginRequest, { validate: validateLogin });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name="email"
        type="email"
        label="Email"
        {...inputProps('email')}
      />
      <Input
        name="password"
        type="password"
        label="Password"
        {...inputProps('password')}
      />
      {status === ERROR && <span className={styles.error}>{error}</span>}

      <div className={styles.buttons}>
        <Link to={routesPaths.forgotPassword} className={styles.link}>Forgot Password?</Link>
        <Button type="submit" className={styles.button} loading={status === LOADING}>
          Login
        </Button>
      </div>
    </form>
  );
};

export { LoginForm };
