import { useEffect } from 'react';

import { LEFT_CLICK_CODE } from 'src/constants/keyboardCodes';

const useLeftClickOnScreen = (callback) => {
  useEffect(() => {
    const onClick = e => {
      if (e.button === LEFT_CLICK_CODE) {
        callback(e);
      }
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [callback]);
};

export { useLeftClickOnScreen };
