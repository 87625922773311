import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { showPreviews } from 'src/utils/helpers';
import { useProjectSelector } from 'src/hooks';
import { Slider } from 'src/common/slider';
import styles from './PreviewTemplateSlider.module.scss';

const PreviewTemplateSlider = forwardRef((_, ref) => {
  const { selectedTemplatePreview } = useSelector(({ templates }) => ({
    selectedTemplatePreview: templates.selectedTemplatePreview,
  }));

  const { layout } = useProjectSelector();

  if (!selectedTemplatePreview) {
    return null;
  }

  const getStyles = () => {
    if (ref.current) {
      return { width: '100%' };
    }
    return { width: `${layout.width}px` };
  };

  if (!selectedTemplatePreview?.previews.length) {
    return (
      <div className={styles.error}>
        Oops! This template&rsquo;s previews are not available. Go back and export.
      </div>
    );
  }

  return (
    <div className={styles.container} ref={ref}>
      <div style={getStyles()}>
        <Slider
          slides={showPreviews(
            selectedTemplatePreview.previews,
            selectedTemplatePreview.videoUrls,
          )}
        />
      </div>
    </div>
  );
});

export { PreviewTemplateSlider };
