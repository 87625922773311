import React, { useState, useEffect, useCallback } from 'react';
import { number, string, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { useLoading, useTooltip, useWindowSize } from 'src/hooks';
import {
  ADD_LOGOS_TO_BRAND_LIBRARY,
  showLogoDetails,
  SHOW_LOGO_DETAILS,
} from 'src/actions/brandLibraryActions';
import { snapImageOfVideo, isVideo } from 'src/utils/videoHelpers';
import CloseIcon from 'src/assets/icons/close-white.svg';
import {
  truncateFilename,
} from 'src/utils/helpers';
import {
  getCloudinaryFilenameFromUrl,
  getCloudinaryFilenameWithoutTimestamp,
  optimizeCloudinaryThumbnail,
} from 'src/utils/cloudinaryHelpers';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { Tooltip } from 'src/common/tooltip';
import { DeleteLogoModal } from '../delete-logo-modal';
import styles from './Logo.module.scss';

const MAX_FILENAME_LENGTH = 10;

const Logo = ({ logo, logoCategoryId }) => {
  const [thumbnail, setThumbnail] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (windowSize.width <= MOBILE_THRESHOLD_WIDTH && !showDelete) {
      setShowDelete(true);
    }
  }, [showDelete, windowSize.width]);

  const addLogoLoading = useLoading(ADD_LOGOS_TO_BRAND_LIBRARY);
  const getLogoDetailsLoading = useLoading(SHOW_LOGO_DETAILS);

  useEffect(() => {
    let mounted = true;
    if (isVideo(logo.url)) {
      snapImageOfVideo(logo.url, (thumbnailSrc) => {
        mounted && setThumbnail(thumbnailSrc);
      });
    } else {
      const imageThumbnail = optimizeCloudinaryThumbnail(logo.url);
      setThumbnail(imageThumbnail);
    }
    return () => { mounted = false; };
  }, [logo]);

  const filename = getCloudinaryFilenameWithoutTimestamp(getCloudinaryFilenameFromUrl(logo.url));

  const followMouse = true;
  const { ref, showTooltip, setShowTooltip, topLeftPosition } = useTooltip(followMouse);

  const showDetails = useCallback(() => {
    dispatch(showLogoDetails({
      ...logo,
      filename,
    }));
  }, [dispatch, logo, filename]);

  const onMouseLeave = () => {
    if (showDelete) {
      setShowDelete(false);
      setShowTooltip(false);
    }
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <button
        className={styles.logoButton}
        onClick={showDetails}
        disabled={getLogoDetailsLoading}
      >
        <img src={thumbnail} alt="" className={styles.image} disabled={addLogoLoading} />
      </button>
      <span className={styles.name}>
        {truncateFilename(filename, MAX_FILENAME_LENGTH)}
      </span>
      {showDelete && logoCategoryId && (
        <button
          className={styles.delete}
          onClick={() => setModalOpen(true)}
          disabled={addLogoLoading || disabled}
        >
          <img src={CloseIcon} alt="Delete" />
        </button>
      )}
      {modalOpen && (
        <DeleteLogoModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          logoCategoryId={logoCategoryId}
          logoId={logo.id}
        />
      )}
      {showTooltip && (
        <Tooltip followMouse topLeftPosition={topLeftPosition}>
          {filename}
        </Tooltip>
      )}
    </div>
  );
};

Logo.propTypes = {
  logo: shape({
    id: number,
    url: string,
  }),
  logoCategoryId: number,
};

export { Logo };
