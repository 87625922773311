import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { useStatus, useWindowSize } from 'src/hooks';
import { ERROR } from 'src/constants/status';
import {
  findAccountByNames,
  FIND_ACCOUNT_BY_NAMES,
  setSelectedProject,
  getAllPublicProjects,
} from 'src/actions/publicPagesActions';
import { reset } from 'src/actions/statusActions';
import { NotFound } from 'src/pages/not-found/NotFound';
import {
  SMALL_WIDTH,
  MOBILE_THRESHOLD_WIDTH,
  MEDIUM_WIDTH,
  EXTRA_LARGE_WIDTH,
} from 'src/constants/breakpoints';
import { ProjectDetailsCard } from 'src/pages/public-pages/account-profile/project-details-card';
import { AccountHeader } from 'src/pages/public-pages/account-profile/account-header';
import { Navbar } from 'src/pages/public-pages/common/navbar';
import { Modal } from 'src/common/modal';
import { ProjectList } from 'src/pages/public-pages/account-profile/project-list';
import { ProjectCard } from 'src/pages/public-pages/account-profile/project-card';
import { NoPublicProjects } from 'src/pages/public-pages/account-profile/no-public-projects';
import styles from './AccountProfile.module.scss';

const AccountProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { username: encodedUsername, brandName: encodedBrandName } = useParams();

  const { windowSize: { width } } = useWindowSize();

  const {
    account,
    selectedProject,
    allPublicProjects,
  } = useSelector(({ publicPages }) => ({
    account: publicPages.account,
    selectedProject: publicPages.selectedProject,
    allPublicProjects: publicPages.allPublicProjects,
  }));

  useEffect(() => {
    if (!account) {
      const username = decodeURIComponent(encodedUsername);
      const brandName = encodedBrandName && decodeURIComponent(encodedBrandName);

      dispatch(findAccountByNames(username, brandName));
    }

    return () => {
      dispatch(reset(FIND_ACCOUNT_BY_NAMES));
    };
  }, [dispatch, account, encodedUsername, encodedBrandName]);

  useEffect(() => {
    if (!allPublicProjects && account) {
      dispatch(getAllPublicProjects(account.id));
    }
  }, [dispatch, account, allPublicProjects]);

  const queryParams = useMemo(() => {
    if (history.location.search) {
      const { pjid } = parse(history.location.search, { parseBooleans: true });
      return { pjid };
    }
    return {};
  }, [history.location.search]);

  useEffect(() => {
    if (!queryParams.pjid || !allPublicProjects) return;

    const project = allPublicProjects.find(({ id }) => id === Number(queryParams.pjid));
    dispatch(setSelectedProject(project));
    window.scrollTo(0, 0);
  }, [dispatch, queryParams.pjid, allPublicProjects]);

  const isSmallWidth = width <= SMALL_WIDTH;

  const onClose = () => {
    dispatch(setSelectedProject(null));
    history.push(account.publicRelUrl);
  };

  const { status: findAccountStatus } = useStatus(FIND_ACCOUNT_BY_NAMES);

  if (findAccountStatus === ERROR) {
    return <NotFound />;
  }

  return (
    <div className={styles.container}>
      <Navbar />
      {!selectedProject && <AccountHeader />}
      {allPublicProjects?.length === 0 && <NoPublicProjects />}

      {isSmallWidth && (
        <>
            {selectedProject && (<ProjectDetailsCard />)}
            {!selectedProject && <ProjectList projects={allPublicProjects} />}
        </>
      )}

      {!isSmallWidth && (
        <>
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              [MOBILE_THRESHOLD_WIDTH]: 1,
              [SMALL_WIDTH]: 2,
              [MEDIUM_WIDTH]: 3,
              [EXTRA_LARGE_WIDTH]: 4,
            }}
            className={styles.masonryContainer}
          >
            <Masonry gutter="2rem">
              {allPublicProjects?.map(project => (
                <div className={styles.gridItem} key={project.id}>
                  <ProjectCard {...project} />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
          {selectedProject && (
            <Modal
              isShowing
              hide={onClose}
              curvedContainer={false}
              classNameContent={styles.modal}
              closeOnClickOutside={false}
              hideClassName={styles.closeModal}
              icon
            >
              <ProjectDetailsCard />
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export { AccountProfile };
