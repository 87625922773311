import { useDispatch } from 'react-redux';

import {
  CANVAS_DRAWING_LAYER,
  CANVAS_BLEED_PREFIX,
  CANVAS_DRAWING_CONTENT,
  PROJECT_TYPE,
  CANVAS_HELPERS_PREFIXES,
  CANVAS_TOP_LEVEL_HELPERS_PREFIXES,
} from 'src/constants/general';
import { dataURItoBlob, isGif, promiseInListWithLimit } from 'src/utils/helpers';
import { useProjectSelector } from 'src/hooks';
import { cleanSelectedElement } from 'src/actions/projectActions';
import { IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { removeOptimizationCloudinaryCanvasImage } from 'src/utils/cloudinaryHelpers';
import { isVideo } from 'src/utils/videoHelpers';

const useSaveProjectPreviews = (refDrawingLayer) => {
  const dispatch = useDispatch();

  const { size, layout, editableImage, editableText, type } = useProjectSelector();

  const searchHelpersInsideGroups = (element, callback) => {
    const helpers = element.getChildren(node => (
      CANVAS_HELPERS_PREFIXES.some(prefix => node.getId().startsWith(prefix))
    ));
    helpers.forEach(elem => {
      callback(elem);
    });
    const groups = element
      .getChildren(node => node.getClassName() === 'Group');
    groups.forEach(group => {
      searchHelpersInsideGroups(group, callback);
    });
  };

  const loadImage = async (newImage, imageInCanvas) => new Promise((resolve) => {
    newImage.onload = async () => {
      imageInCanvas.image(newImage);
      resolve(true);
    };
  });

  const restoreOriginalImagesInCanvas = async (element) => {
    const images = element.getChildren(node => node.attrs.elementType === IMAGE_ELEMENT &&
      node.attrs.uploadType === LOCAL_UPLOAD);
    // eslint-disable-next-line no-restricted-syntax
    for await (const elem of images) {
      const [imageInCanvas] = elem.getChildren(node => node.getClassName() === 'Image');
      if (!isGif(imageInCanvas.attrs.src) && !isVideo(imageInCanvas.attrs.src)) {
        const newImage = new Image();
        newImage.crossOrigin = 'Anonymous';
        newImage.src = removeOptimizationCloudinaryCanvasImage(imageInCanvas.attrs.src);
        await loadImage(newImage, imageInCanvas);
      }
    }
    const groups = element.getChildren(node => node.getClassName() === 'Group');
    // eslint-disable-next-line no-restricted-syntax
    for await (const group of groups) {
      await restoreOriginalImagesInCanvas(group);
    }
  };

  const prepareCanvas = () => {
    if (editableImage || editableText) {
      dispatch(cleanSelectedElement());
    }
    if (!refDrawingLayer?.current) {
      return;
    }
    const helpers = refDrawingLayer.current
      .getChildren(node => (
        CANVAS_TOP_LEVEL_HELPERS_PREFIXES.some(prefix => node.getId().startsWith(prefix))
      ));
    helpers.forEach(elem => {
      elem.hide();
    });
    const transformers = refDrawingLayer.current
      .getChildren(node => node.getClassName() === 'Transformer');
    transformers.forEach(transformer => {
      transformer.hide();
    });

    const canvasDrawingContent = refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`);
    searchHelpersInsideGroups(canvasDrawingContent, (elem) => elem.hide());

    if (type === PROJECT_TYPE.SIGN) {
      const signHelpers = refDrawingLayer.current
        .getChildren(node => node.getId().startsWith(CANVAS_BLEED_PREFIX));
      signHelpers.forEach(border => {
        border.hide();
      });
    }
  };

  const undoPrepareCanvas = () => {
    if (!refDrawingLayer?.current) {
      return;
    }
    const helpers = refDrawingLayer.current
      .getChildren(node => (
        CANVAS_TOP_LEVEL_HELPERS_PREFIXES.some(prefix => node.getId().startsWith(prefix))
      ));
    helpers.forEach(elem => {
      elem.show();
    });
    const transformers = refDrawingLayer.current
      .getChildren(node => node.getClassName() === 'Transformer');
    transformers.forEach(transformer => {
      transformer.show();
    });

    const canvasDrawingContent = refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`);
    searchHelpersInsideGroups(canvasDrawingContent, (elem) => elem.show());

    if (type === PROJECT_TYPE.SIGN) {
      const signHelpers = refDrawingLayer.current
        .getChildren(node => node.getId().startsWith(CANVAS_BLEED_PREFIX));
      signHelpers.forEach(border => {
        border.show();
      });
    }
  };

  const generatePreviews = async ({
    nameFile,
    isThumbnail = false,
    isTemplate = false,
  }) => {
    if (!refDrawingLayer?.current) {
      return [];
    }
    const amountOfPreviews = isThumbnail ? 1 : size;

    const pixelRatio = isThumbnail ? 0.5 : 1.5;
    const clonedStage = refDrawingLayer.current.getStage().clone({
      width: layout.width * size * pixelRatio,
      height: layout.height * pixelRatio,
    });
    clonedStage.scale({ x: pixelRatio, y: pixelRatio });
    const [clonedLayer] = clonedStage.getChildren(node => node.getId() === CANVAS_DRAWING_LAYER);
    let x = clonedLayer.attrs.x * pixelRatio;

    if (!isThumbnail) {
      const canvasClonedContent = clonedLayer.findOne(`#${CANVAS_DRAWING_CONTENT}`);
      await restoreOriginalImagesInCanvas(canvasClonedContent);
    }

    const files = await promiseInListWithLimit(Array.from({ length: amountOfPreviews }),
      (_, index) => {
        const exportWidth = layout.width * pixelRatio;
        const exportHeight = layout.height * pixelRatio;
        const previewName = `${isTemplate ? 'Template_' : ''}${nameFile}_${index + 1}.jpg`;
        const dataURL = clonedStage.toDataURL({
          x,
          width: exportWidth,
          y: clonedLayer.attrs.y * pixelRatio,
          height: exportHeight,
        });
        const blob = dataURItoBlob(dataURL);
        x += exportWidth;
        return new File([blob], previewName);
      });
    clonedStage.destroy();
    return files;
  };

  return { generatePreviews, prepareCanvas, undoPrepareCanvas };
};

export { useSaveProjectPreviews };
