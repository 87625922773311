import React from 'react';

import EmptyProjectsIcon from 'src/assets/icons/empty-projects.svg';
import styles from './EmptyProjects.module.scss';

const EmptyProjects = () => (
  <div className={styles.container}>
    <img src={EmptyProjectsIcon} alt="" />
    <span className={styles.title}>No projects to show ... yet.</span>
    <span className={styles.text}>Tap that button in the top right to start designing.</span>
  </div>
);

export { EmptyProjects };
