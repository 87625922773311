import React, { useCallback, useEffect, useMemo } from 'react';
import { object, bool, string, func } from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useSession, useWindowSize } from 'src/hooks';
import { setSmallScreen } from 'src/actions/userActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import {
  MOBILE_SCREEN_SEEING_MODAL,
  MOBILE_SCREEN_NOT_SEEN_MODAL,
  MOBILE_SCREEN_CLOSED_MODAL,
} from 'src/constants/general';
import { routesPaths } from './routesPaths';

const AuthenticatedRoute = ({ path, component, ...rest }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { authenticated, user } = useSession();

  const { windowSize: { width } } = useWindowSize();

  const { fromSmallScreen } = useSelector(({ session }) => ({
    fromSmallScreen: session.fromSmallScreen,
  }));

  const isInPreviewBreakDown = useMemo(() => {
    const pathnameSplitted = pathname.split('/');
    const pathnameSufix = pathnameSplitted[pathnameSplitted.length - 1];
    const routesSplitted = routesPaths.previewBreakDown.split('/');
    const routesSufix = routesSplitted[routesSplitted.length - 1];
    return pathnameSufix === routesSufix;
  }, [pathname]);

  useEffect(() => {
    const smallScreen = width <= MOBILE_THRESHOLD_WIDTH;
    if (isInPreviewBreakDown) {
      dispatch(setSmallScreen(MOBILE_SCREEN_CLOSED_MODAL));
    } else if (smallScreen && fromSmallScreen === MOBILE_SCREEN_NOT_SEEN_MODAL) {
      dispatch(setSmallScreen(MOBILE_SCREEN_SEEING_MODAL));
    }
  }, [dispatch, width, fromSmallScreen, isInPreviewBreakDown]);

  const renderComponent = useCallback((props) => {
    if (!authenticated) {
      return (
        // Redirect to page explaining we're closing
        <Route
          component={() => {
            window.location.href = 'https://www.stagger.com';
            return null;
          }}
        />
      );
    }
    if (user?.settings?.mustRedirectToUrl && user.settings.mustRedirectToUrl !== pathname) {
      return (
        <Redirect
          to={{
            pathname: user.settings.mustRedirectToUrl,
            state: { from: props.location },
          }}
        />
      );
    }
    return React.createElement(component, props);
  }, [authenticated, component, user, pathname]);

  return (
    <Route
      path={path}
      {...rest}
      render={props => renderComponent(props)}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: func.isRequired,
  path: string.isRequired,
  exact: bool,
  location: object,
};

export { AuthenticatedRoute };
