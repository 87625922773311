import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTime } from 'date-fns';
import { number, shape } from 'prop-types';

import BucketPaint from 'src/assets/icons/paint-bucket.svg';
import BucketPaintWhite from 'src/assets/icons/paint-bucket-white.svg';
import { updateElement } from 'src/actions/projectActions';
import { getRGBAColor } from 'src/utils/helpers';
import { useProjectSelector } from 'src/hooks';
import {
  COLOR_TYPE_GRADIENT_LINEAR,
  COLOR_TYPE_SOLID,
  SHAPE_DISABLED_COLOR,
  FILL_COLOR_EYEDROPPER_SOURCE,
} from 'src/constants/general';
import {
  SHAPES_ELEMENTS,
  LINE_ELEMENT,
  CIRCLE_ELEMENT,
  TRIANGLE_ELEMENT,
} from 'src/constants/canvasElements';
import { ColorPickerCanvas } from 'src/pages/project/canvas/canvas-helper-elements';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import styles from './ShapeFillOptions.module.scss';

const ShapeFillOptions = ({ scrollPosition }) => {
  const dispatch = useDispatch();

  const { eyeDropper, gradientActiveColorId } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
    gradientActiveColorId: canvas.gradientActiveColorId,
  }));

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const elementSelected = useMemo(() => (
    elements.find(elem => elem.uuid === selectedElementsList[0])
  ), [elements, selectedElementsList]);

  const changeColorElementRequest = useCallback((color, event) => {
    selectedElementsList.forEach(elementId => {
      const newAttrs = {
        uuid: elementId,
        color,
      };
      dispatch(updateElement(newAttrs, event.timeStamp));
    });
  }, [dispatch, selectedElementsList]);

  const disable = !elementSelected || !SHAPES_ELEMENTS.includes(elementSelected.type);
  const disableFillPicker = disable || elementSelected.type === LINE_ELEMENT;

  const showEyedropperFillColor = eyeDropper.active &&
    eyeDropper.source === FILL_COLOR_EYEDROPPER_SOURCE;

  const getStartPointGradientHeight = (element, angle) => {
    if (element.type === CIRCLE_ELEMENT || element.type === TRIANGLE_ELEMENT) {
      if (angle <= 180) {
        return -element.height / 3;
      }
      return element.height / 3;
    }
    if (angle <= 180) {
      return 0;
    }
    return element.height;
  };

  const getStartPointGradientWidth = (element, angle) => {
    if (element.type === CIRCLE_ELEMENT || element.type === TRIANGLE_ELEMENT) {
      if (angle > 270 || angle <= 90) {
        return -element.width / 3;
      }
      return element.width / 3;
    }
    if (angle > 270 || angle <= 90) {
      return 0;
    }
    return element.width;
  };

  const degreesToRadians = (degrees) => {
    const pi = Math.PI;
    return degrees * (pi / 180);
  };

  const getGradientPositionsFromAngle = (element, angle) => {
    const startWidth = getStartPointGradientWidth(element, angle);
    const startHeight = getStartPointGradientHeight(element, angle);
    const attrs = {};
    attrs.fillLinearGradientStartPoint = { x: startWidth, y: startHeight };
    const magnitud = element.type === CIRCLE_ELEMENT ?
      element.height + Math.abs(attrs.fillLinearGradientStartPoint.y) :
      Math.max(element.width, element.height);

    const x2 = (magnitud * Math.cos(degreesToRadians(angle))) +
      attrs.fillLinearGradientStartPoint.x;
    const y2 = (magnitud * Math.sin(degreesToRadians(angle))) +
      attrs.fillLinearGradientStartPoint.y;
    attrs.fillLinearGradientEndPoint = { x: x2, y: y2 };
    return attrs;
  };

  const onChangeColorType = (colorType) => {
    let fillLinearGradientEnabled = false;
    if (colorType === COLOR_TYPE_GRADIENT_LINEAR) {
      fillLinearGradientEnabled = true;
    }
    const timeStamp = getTime(new Date());
    selectedElementsList.forEach(elementId => {
      const attrs = {};
      if (fillLinearGradientEnabled) {
        const element = elements.find(elem => elem.uuid === elementId);
        if (!element.fillLinearGradientColorStops) {
          attrs.fillLinearGradientColorStops = [
            { offset: '0.00', color: '#000000', id: 1 },
            { offset: '1.00', color: element.color.hex, id: 2 },
          ];
          const angle = 90;
          const positions = getGradientPositionsFromAngle(element, angle);
          attrs.fillLinearGradientStartPoint = positions.fillLinearGradientStartPoint;
          attrs.fillLinearGradientEndPoint = positions.fillLinearGradientEndPoint;
        }
      }
      const newAttrs = {
        uuid: elementId,
        fillLinearGradientEnabled,
        ...attrs,
      };
      dispatch(updateElement(newAttrs, timeStamp));
    });
  };

  const changeGradientElementRequest = useCallback((gradient) => {
    const timeStamp = getTime(new Date());
    selectedElementsList.forEach(elementId => {
      const element = elements.find(elem => elem.uuid === elementId);
      if (element.fillLinearGradientEnabled) {
        const newAttrs = {
          uuid: elementId,
          fillLinearGradientColorStops: gradient,
        };
        dispatch(updateElement(newAttrs, timeStamp));
      }
    });
  }, [dispatch, elements, selectedElementsList]);

  const defaultColorType = useMemo(() => (
    elementSelected?.fillLinearGradientEnabled ?
      COLOR_TYPE_GRADIENT_LINEAR :
      COLOR_TYPE_SOLID
  ), [elementSelected]);

  const gradientColorToShow = useMemo(() => {
    if (!elementSelected?.fillLinearGradientColorStops) return null;
    if (gradientActiveColorId) {
      const activeStop = elementSelected.fillLinearGradientColorStops.find(stop => (
        stop.id === gradientActiveColorId
      ));
      return { hex: activeStop.color, alpha: 100 };
    }
    const defaultStop = elementSelected.fillLinearGradientColorStops[0];
    return { hex: defaultStop.color, alpha: 100 };
  }, [elementSelected, gradientActiveColorId]);

  const color = useMemo(() => {
    if (elementSelected?.fillLinearGradientEnabled) {
      return gradientColorToShow;
    }
    return elementSelected?.color;
  }, [elementSelected, gradientColorToShow]);

  const fillColorForBucket = useMemo(() => {
    if (disable) {
      return { backgroundColor: SHAPE_DISABLED_COLOR };
    }
    if (showEyedropperFillColor) {
      return { backgroundColor: eyeDropper.color };
    }
    if (elementSelected?.fillLinearGradientEnabled) {
      return { backgroundColor: gradientColorToShow.hex };
    }
    return { backgroundColor: getRGBAColor(elementSelected?.color) };
  }, [
    disable,
    elementSelected,
    eyeDropper.color,
    gradientColorToShow,
    showEyedropperFillColor,
  ]);

  const onColorChange = ({ hex, alpha }, event) => {
    if (elementSelected?.fillLinearGradientEnabled && gradientActiveColorId) {
      const gradient = elementSelected.fillLinearGradientColorStops.map(stop => {
        if (stop.id === gradientActiveColorId) {
          stop.color = hex;
        }
        return stop;
      });
      changeGradientElementRequest(gradient);
    } else {
      changeColorElementRequest({ hex, alpha }, event);
    }
  };

  const onGradientAngleChange = (angle) => {
    const timeStamp = getTime(new Date());
    selectedElementsList.forEach(elementId => {
      const element = elements.find(elem => elem.uuid === elementId);
      const positions = getGradientPositionsFromAngle(element, angle);
      const newAttrs = {
        uuid: elementId,
        ...positions,
      };
      dispatch(updateElement(newAttrs, timeStamp));
    });
  };

  const containerId = 'shapeFillOptions';
  const { optionsSize } = useDropdownWithScroll(scrollPosition, containerId);

  return (
    <div className={styles.container} id={containerId}>
      <div className={styles.fill}>
        <div className={styles.paintBucket}>
          <img
            src={disableFillPicker ? BucketPaint : BucketPaintWhite}
            alt="Fill shape color"
          />
          <div className={styles.paintBucketLine}>
            <div style={fillColorForBucket} />
          </div>
        </div>
        <ColorPickerCanvas
          color={color}
          onColorChange={onColorChange}
          className={styles.colorPicker}
          disabled={disableFillPicker}
          disabledColor={SHAPE_DISABLED_COLOR}
          eyedropperSource={FILL_COLOR_EYEDROPPER_SOURCE}
          enableEyedropper={!elementSelected?.fillLinearGradientEnabled}
          enableGradient
          onChangeColorType={onChangeColorType}
          defaultColorType={defaultColorType}
          gradientColorStops={elementSelected?.fillLinearGradientColorStops}
          setGradientColorStops={changeGradientElementRequest}
          enableAlpha={!elementSelected?.fillLinearGradientEnabled}
          onGradientAngleChange={onGradientAngleChange}
          gradientStartPoint={elementSelected?.fillLinearGradientStartPoint}
          gradientEndPoint={elementSelected?.fillLinearGradientEndPoint}
          style={{ left: optionsSize.left, top: '118%' }}
        />
      </div>
    </div>
  );
};

ShapeFillOptions.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
};

export { ShapeFillOptions };
