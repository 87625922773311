import React from 'react';
import Storytelling1 from 'src/assets/images/storytelling/storytelling-1@2x.png';
import Storytelling2 from 'src/assets/images/storytelling/storytelling-2@2x.png';
import Storytelling3 from 'src/assets/images/storytelling/storytelling-3@2x.jpg';

export const STORYTELLING_PREVIEWS = [Storytelling1, Storytelling2, Storytelling3];

export const STORYTELLING_TEXT = [
  {
    title: 'Define the topic',
    subtitle: () => (
      <span>
        Use 2-3 words to produce your mission
        statement - the <b>what</b> and <b>why</b>. Introduce your
        character or product.
      </span>
    ),
  },
  {
    title: 'Portray your brand',
    subtitle: () => (
      <span>
        Let the persona, voice/tone and brand
        attributes shine through. Use impactful
        imagery, fonts and colors that represent your
        message.
      </span>
    ),
  },
  {
    title: 'Evoke emotion and mission',
    subtitle: () => (
      <span>
        Leave your audience with an emotion. Brave.
        Confident. Inspired. And tell them what you
        want. Vist your website. Follow your brand.
        Send you an email.
      </span>
    ),
  },
];
