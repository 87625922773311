import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import {
  EDIT_CANVAS_CONTROL,
  CHOOSE_IMAGE_CONTROL,
  TEMPLATE_RELATED_CONTROLS,
  ADD_TEXT_CONTROL,
  ADD_OBJECT_CONTROL,
  COLLAGE_CONTROL,
} from 'src/constants/canvasControls';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { useWindowSize } from 'src/hooks';
import { TemplatesControl } from './templates-control';
import { EditCanvasControl } from './edit-canvas-control';
import { AddMediaControl } from './add-media-control';
import { VideoModal } from './video-modal';
import { AddTextControl } from './add-text-control';
import { AddObjectControl } from './add-object-control';
import { CollageControl } from './collage-control';
import styles from './ControlsOptions.module.scss';

const ControlsOptions = () => {
  const { controlOpen, isGeneratedCollageVisible } = useSelector(({ canvas, collage }) => ({
    controlOpen: canvas.controlOpen,
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
  }));

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const getControls = () => {
    if (controlOpen === EDIT_CANVAS_CONTROL) {
      return <EditCanvasControl />;
    }
    if (controlOpen === ADD_TEXT_CONTROL) {
      return <AddTextControl />;
    }
    if (controlOpen === ADD_OBJECT_CONTROL) {
      return <AddObjectControl />;
    }
    if (controlOpen === CHOOSE_IMAGE_CONTROL) {
      return (
        <AddMediaControl
          setVideoModalOpen={setVideoModalOpen}
        />
      );
    }
    if (TEMPLATE_RELATED_CONTROLS.find(t => t === controlOpen)) {
      return <TemplatesControl />;
    }
    if (controlOpen === COLLAGE_CONTROL) {
      return <CollageControl />;
    }
    return null;
  };

  const unsetHeight = controlOpen === ADD_TEXT_CONTROL || controlOpen === EDIT_CANVAS_CONTROL
  || (isGeneratedCollageVisible && isMobile);

  return (
    <div className={cn(styles.container, { [styles.unsetHeight]: unsetHeight })}>
      {getControls()}
      <VideoModal isShowing={videoModalOpen} setVideoModalOpen={setVideoModalOpen} />
    </div>
  );
};

export { ControlsOptions };
