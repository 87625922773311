import React from 'react';
import { number, string } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { getRightOptionsPosition } from 'src/utils/canvasHelpers';
import { openRightClickOptions } from 'src/actions/canvasActions';
import { useProjectSelector, useLongPress } from 'src/hooks';
import {
  CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  EDITABLE_TITLE_FRAME_TYPE,
  TOUCH_LONG_PRESS,
} from 'src/constants/general';
import { CanvasFrameTitleInput } from 'src/pages/project/canvas/canvas-helper-elements';
import styles from './FrameCard.module.scss';

const FrameCard = ({ index, frameTitle }) => {
  const dispatch = useDispatch();

  const { frameTitles, editableFrameTitle } = useProjectSelector();

  const onRightClickFrame = (e) => {
    e.preventDefault();
    const pos = getRightOptionsPosition(
      e,
      CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT,
      CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
    );
    dispatch(openRightClickOptions({
      ...pos,
      type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.FRAME_CARD,
      frame: {
        index,
      },
    }));
  };

  const onLongTouch = (e) => {
    const touch = e.touches[0] || e.changedTouches[0];
    e.clientX = touch.clientX;
    e.clientY = touch.clientY;
    const pos = getRightOptionsPosition(
      e,
      CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT,
      CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
    );
    dispatch(openRightClickOptions({
      ...pos,
      type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.FRAME_CARD,
      frame: {
        index,
      },
      action: TOUCH_LONG_PRESS,
    }));
  };

  const { handlers } = useLongPress(onLongTouch, () => {});

  const isEditing = editableFrameTitle?.type === EDITABLE_TITLE_FRAME_TYPE.FRAME_CARD_INPUT &&
    editableFrameTitle?.frame?.index === index;

  return (
    <Draggable draggableId={frameTitle} index={index} type="frameCard">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={styles.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onContextMenu={onRightClickFrame}
          onTouchStart={!snapshot.isDragging ? handlers.onTouchStart : undefined}
          onTouchEnd={!snapshot.isDragging ? handlers.onTouchEnd : undefined}
        >
          {isEditing ? (
            <CanvasFrameTitleInput
              defaultTitle={frameTitles[editableFrameTitle.frame.index]}
              inputClassName={styles.input}
            />
          ) : (
            <span className={styles.name}>
              {frameTitle}
            </span>
          )}
        </div>
      )}
    </Draggable>
  );
};

FrameCard.propTypes = {
  index: number.isRequired,
  frameTitle: string.isRequired,
};

export { FrameCard };
