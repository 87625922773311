import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  BOTTOM_ARROW_CODE,
  LEFT_ARROW_CODE,
  RIGHT_ARROW_CODE,
  TOP_ARROW_CODE,
  SHIFT_CHARACTER_CODE,
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import { isHtmlTagFocused } from 'src/utils/helpers';
import {
  moveElementWithKeyboard,
  moveElementBackward,
  moveElementForward,
} from 'src/actions/projectActions';
import { areSelectedElementsUnlocked } from 'src/utils/canvasHelpers';
import { useProjectSelector, useKeyboardListener } from 'src/hooks';
import { useKeyboardReset } from 'src/hooks/useKeyboardListener';

const SMALL_DELTA = 1;
const LARGE_DELTA = 10;

const useDirectionalKeysOnCanvas = () => {
  const dispatch = useDispatch();

  const { selectedElements, elements, groupsOfElementsById } = useProjectSelector();

  const resetKey = useKeyboardReset();

  const shiftShortcutPressed = useKeyboardListener(SHIFT_CHARACTER_CODE);

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);

  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const leftPressed = useKeyboardListener(LEFT_ARROW_CODE);
  const rightPressed = useKeyboardListener(RIGHT_ARROW_CODE);
  const topPressed = useKeyboardListener(TOP_ARROW_CODE);
  const bottomPressed = useKeyboardListener(BOTTOM_ARROW_CODE);

  const moveElementPosition = useCallback((code) => {
    let delta = shiftShortcutPressed ? LARGE_DELTA : SMALL_DELTA;
    if (code === LEFT_ARROW_CODE || code === TOP_ARROW_CODE) {
      delta = -delta;
    }
    dispatch(moveElementWithKeyboard({ code, delta }));
  }, [dispatch, shiftShortcutPressed]);

  const moveElementZIndex = useCallback((code) => {
    if (code === TOP_ARROW_CODE) {
      dispatch(moveElementForward());
    } else {
      dispatch(moveElementBackward());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isHtmlTagFocused() && (leftPressed || rightPressed || topPressed || bottomPressed)) {
      const areElementsSelected = Object.keys(selectedElements).length;
      if (areElementsSelected) {
        const selectedElementsUnlocked = areSelectedElementsUnlocked(
          selectedElements,
          elements,
          groupsOfElementsById,
        );
        if (selectedElementsUnlocked) {
          let code = LEFT_ARROW_CODE;
          if (rightPressed) {
            code = RIGHT_ARROW_CODE;
          } else if (topPressed) {
            code = TOP_ARROW_CODE;
          } else if (bottomPressed) {
            code = BOTTOM_ARROW_CODE;
          }
          resetKey(code);
          if ((commandPressed || controlPressed) && shiftShortcutPressed &&
            (topPressed || bottomPressed)) {
            moveElementZIndex(code);
          } else {
            moveElementPosition(code);
          }
        }
      }
    }
  }, [
    dispatch,
    leftPressed,
    rightPressed,
    topPressed,
    bottomPressed,
    selectedElements,
    elements,
    groupsOfElementsById,
    shiftShortcutPressed,
    resetKey,
    commandPressed,
    controlPressed,
    moveElementPosition,
    moveElementZIndex,
  ]);
};

export { useDirectionalKeysOnCanvas };
