import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { Button } from 'src/common/button';
import { useSession } from 'src/hooks';
import { setCurrentBrandAssetStatus } from 'src/actions/accountActions';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import styles from './UpdateWebsiteWarning.module.scss';

const UpdateWebsiteWarning = ({ closeEditWebsiteModal, setCurrentStep }) => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const onUpdate = useCallback(() => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.ASKING_FOR_A_WEBSITE,
      id: accountId,
    };
    dispatch(setCurrentBrandAssetStatus(params));
    setCurrentStep(1);
  }, [dispatch, setCurrentStep, accountId]);

  return (
    <div className={styles.content}>
      <span role="img" aria-label="" className={styles.emoji}>⚠️</span>
      <span className={styles.text}>
        We&apos;re going to start from scratch and build a new Brand Library with your new URL!
      </span>
      <span className={styles.text}>
        Ready to erase your old assets?
      </span>
      <Button
        className={styles.actionButton}
        onClick={onUpdate}
      >
        Yes
      </Button>
      <Button
        className={styles.dismissButton}
        secondary
        onClick={closeEditWebsiteModal}
      >
        No
      </Button>
    </div>
  );
};

UpdateWebsiteWarning.propTypes = {
  closeEditWebsiteModal: func.isRequired,
  setCurrentStep: func.isRequired,
};

export { UpdateWebsiteWarning };
