import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { DOCUMENTATION_LINK } from 'src/constants/general';
import { useClickOutside } from 'src/hooks';
import { userDoingProductTour } from 'src/actions/userSettingsActions';
import HelpIcon from 'src/assets/icons/help.svg';
import { ButtonTooltip } from 'src/common/button-tooltip';
import { StorytellingModal } from 'src/pages/project/bottom-options/storytelling';
import { ProductTour } from 'src/pages/project/bottom-options/product-tour';
import styles from './HelpOption.module.scss';

const HelpOption = () => {
  const dispatch = useDispatch();

  const [helpRequested, setHelpRequested] = useState(false);
  const [modalStorytellingOpen, setModalStorytellingOpen] = useState(false);

  const ref = useClickOutside(() => setHelpRequested(false), helpRequested);

  const beginProductTour = useCallback(() => {
    dispatch(userDoingProductTour(true));
    setHelpRequested(false);
  }, [dispatch]);

  const openOptions = useCallback(() => {
    setHelpRequested(!helpRequested);
  }, [helpRequested]);

  const openStorytelling = useCallback(() => {
    setModalStorytellingOpen(true);
    setHelpRequested(false);
  }, []);

  const onCloseStorytelling = useCallback(() => {
    setModalStorytellingOpen(false);
  }, []);

  const helpOptions = useMemo(() => (
    [
      { text: 'Storytelling Tips', action: openStorytelling },
      { text: 'Product Tour', action: beginProductTour },
    ]
  ), [beginProductTour, openStorytelling]);

  return (
    <>
      <div className={styles.helpContainer}>
        <ButtonTooltip
          text="Help"
          icon={HelpIcon}
          onClick={openOptions}
          className={cn(styles.button, { [styles.draggable]: helpRequested })}
          tooltipClassName={styles.tooltip}
          alt="Help"
        />
        {helpRequested && (
          <div className={styles.helpOptions} ref={ref}>
            {helpOptions.map(help => (
              <button
                key={help.text}
                className={styles.buttonHelp}
                onClick={help.action}
              >
                {help.text}
              </button>
            ))}
            <a
              href={DOCUMENTATION_LINK}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.linkHelp}
            >
              Documentation
            </a>
          </div>
        )}
      </div>
      <StorytellingModal
        isShowing={modalStorytellingOpen}
        onClose={onCloseStorytelling}
      />
      <ProductTour setModalStorytellingOpen={setModalStorytellingOpen} />
    </>
  );
};

export { HelpOption };
