import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from 'src/common/modal';
import { Button } from 'src/common/button';
import { SHOW_LOGO_DETAILS } from 'src/actions/brandLibraryActions';
import { reset } from 'src/actions/statusActions';
import NoDetailsYetIcon from 'src/assets/icons/brand-library-assets-empty.svg';
import styles from './LogoDetailsModal.module.scss';

const LogoDetailsModal = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);
  const [tags, setTags] = useState(null);
  const [colors, setColors] = useState(null);

  const { selectedLogo, selectedLogoDetails } = useSelector(({ brandLibrary }) => ({
    selectedLogo: brandLibrary.selectedLogo,
    selectedLogoDetails: brandLibrary.selectedLogoDetails,
  }));

  const onClose = () => {
    setModalOpen(false);
    dispatch(reset(SHOW_LOGO_DETAILS));
  };

  useEffect(() => {
    if (selectedLogoDetails) {
      const labels = selectedLogoDetails.labels.map(lbl => lbl.tag);
      setTags(labels);
      setColors(selectedLogoDetails.colors);
    }
  }, [selectedLogo, selectedLogoDetails]);

  return (
    <Modal
      isShowing={modalOpen}
      hide={onClose}
      curvedContainer
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>{selectedLogo.filename}</span>
        </div>
        <div className={styles.details}>
          <div className={styles.imgContainer}>
            <img src={selectedLogo.url} alt="" />
          </div>
          {selectedLogoDetails && (
            <div className={styles.features}>
              <div className={styles.subtitle}>TAGS</div>
              {tags && tags.length > 0 ? (
                <span className={styles.tags}>
                  {tags.join(', ')}
                </span>
              ) : (
                <span className={styles.noDetailsText}>
                  Whoops. We couldn&apos;t come up with any tags
                  for this image, but it sure is pretty!
                </span>
              )}
              <div className={styles.subtitle}>PRIMARY COLORS</div>
              <div className={styles.swatches}>
                {colors && colors.length > 0 ? (
                  colors.map(color => (
                    <div className={styles.swatch} key={color}>
                      <div
                        className={styles.color}
                        style={{ backgroundColor: `${color}` }}
                      />
                      <span>{color}</span>
                    </div>
                  ))
                ) : (
                  <span className={styles.noDetailsText}>
                    Whoops. We couldn&apos;t come up with any color
                    for this image, but it sure is pretty!
                  </span>
                )}
              </div>
            </div>
          )}
          {!selectedLogoDetails && (
            <div className={styles.noDetailsContainer}>
              <img src={NoDetailsYetIcon} alt="No details yet" />
              <span className={styles.noDetailsTitle}>Wow, nice image!</span>
              <span className={styles.noDetailsText}>
                Give us a few more minutes while we gather your image tags and color profiles.
              </span>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <Button className={styles.close} onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { LogoDetailsModal };
