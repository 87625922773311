import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string } from 'prop-types';

import {
  setSelectedCategoryId,
  getCategoryTemplates,
  setLoadingPaginationCategoryId,
} from 'src/actions/templatesActions';
import { SeeAllNavigation } from 'src/pages/templates/categories-list/see-all-navigation';
import { useSession, usePagination } from 'src/hooks';
import { SEE_ALL_TEMPLATES_CONTROL } from 'src/constants/canvasControls';
import { changeControlOpen } from 'src/actions/canvasActions';
import { TemplateList } from 'src/pages/templates/categories-list/template-list';
import styles from './TemplateCategory.module.scss';

const TemplateCategory = ({ name, categoryId, amountOfTemplatesInARow }) => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const { templatesByCategoryPages } = useSelector(({ templates }) => ({
    templatesByCategoryPages: templates.templatesByCategoryPages[categoryId],
  }));

  const handleFetch = useCallback(async (page) => {
    await dispatch(getCategoryTemplates(accountId, categoryId, page));
  }, [categoryId, dispatch, accountId]);

  const optimized = true;
  const { page, nextPage, prevPage } = usePagination(handleFetch, optimized);

  const requestNextPage = () => {
    dispatch(setLoadingPaginationCategoryId(categoryId));
    nextPage();
  };

  let isNextPageDisabled = false;
  if (templatesByCategoryPages?.total) {
    const { total } = templatesByCategoryPages;
    isNextPageDisabled = page === Math.ceil(total / amountOfTemplatesInARow);
  }

  const goToSeeAll = () => {
    dispatch(setSelectedCategoryId(categoryId));
    dispatch(changeControlOpen(SEE_ALL_TEMPLATES_CONTROL));
  };

  return (
    <>
      <SeeAllNavigation
        name={name}
        goToSeeAll={goToSeeAll}
        next={requestNextPage}
        prev={prevPage}
        isNextPageDisabled={isNextPageDisabled}
        isPrevDisabled={page === 1}
        templateTextClassName={styles.templateTitle}
        seeAllButtonClassName={styles.seeAllButton}
      />
      {amountOfTemplatesInARow && (
        <TemplateList
          categoryId={categoryId}
          amountOfTemplatesInARow={amountOfTemplatesInARow}
          page={page}
          templateCardClassName={styles.card}
          spinnerContainerClassName={styles.spinnerContainer}
        />
      )}
    </>
  );
};

TemplateCategory.propTypes = {
  name: string.isRequired,
  categoryId: number.isRequired,
  amountOfTemplatesInARow: number,
};

export { TemplateCategory };
