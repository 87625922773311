import { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CANVAS_SCROLL_ID } from 'src/constants/general';
import { saveDraggableOffset } from 'src/actions/canvasActions';

const useScrollEventsCanvas = (refDrawingLayer, refScroll) => {
  const dispatch = useDispatch();

  const { scale, stageOffset } = useSelector(({ canvas }) => ({
    scale: canvas.scale,
    stageOffset: canvas.stageOffset,
  }));

  // Setup isScrolling variable for detecting scroll end
  const isScrolling = useRef();

  const moveStageFromZoom = useRef();

  const canvasProps = useRef();

  useEffect(() => {
    // We need to save it in a ref so we always have the last one in the
    // event listener
    canvasProps.current = { scale, stageOffset };
  }, [scale, stageOffset]);

  const moveStageFromScroll = useCallback((e) => {
    const scrollContainer = document.getElementById(CANVAS_SCROLL_ID);
    if (e.detail) {
      moveStageFromZoom.current = {
        active: true,
        scrollLeft: -e.detail.offsetX * e.detail.scale,
        scrollTop: -e.detail.offsetY * e.detail.scale,
        scale: e.detail.scale,
        stageOffset: e.detail.stageOffset,
      };
      scrollContainer.scrollTo(
        moveStageFromZoom.current.scrollLeft,
        moveStageFromZoom.current.scrollTop,
      );
    } else {
      const canvasLayer = refDrawingLayer?.current;
      if (scrollContainer && canvasLayer) {
        const scaleToUse = moveStageFromZoom.current?.scale || canvasProps.current?.scale;
        const dx = moveStageFromZoom.current?.scrollLeft || scrollContainer.scrollLeft;
        const dy = moveStageFromZoom.current?.scrollTop || scrollContainer.scrollTop;
        const stageOffsetXToUse = moveStageFromZoom.current?.stageOffset ?
          moveStageFromZoom.current.stageOffset.x :
          canvasProps.current?.stageOffset.x;
        let transformX = Math.abs(dx);
        if (!stageOffsetXToUse) {
          canvasLayer.x(-dx / scaleToUse);
        } else {
          canvasLayer.x(stageOffsetXToUse);
          transformX = 0;
        }
        const stageOffsetYToUse = moveStageFromZoom.current?.stageOffset ?
          moveStageFromZoom.current.stageOffset.y :
          canvasProps.current?.stageOffset.y;
        const transformY = Math.abs(dy);
        // This is because of the title. We now always have a stageOffset
        canvasLayer.y(-dy / scaleToUse + stageOffsetYToUse);
        canvasLayer.getStage().container()
          .style.transform = `translate(${transformX}px, ${transformY}px)`;
        canvasLayer.batchDraw();

        if (moveStageFromZoom.current) {
          setTimeout(() => {
            if (!stageOffsetXToUse && moveStageFromZoom.current) {
            // We do +1 to enforce drawing in the canvas
              scrollContainer.scrollLeft += 1;
            }
            if (!stageOffsetYToUse && moveStageFromZoom.current) {
            // We do +1 to enforce drawing in the canvas
              scrollContainer.scrollTop += 1;
            }
            if (moveStageFromZoom.current) {
              moveStageFromZoom.current = undefined;
            }
          }, 0);
        }

        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling.current);

        // Set a timeout to run after scrolling ends
        isScrolling.current = setTimeout(() => {
          // Scrolling has stopped
          dispatch(saveDraggableOffset({ x: -dx / scaleToUse, y: -dy / scaleToUse }));
        }, 66);
      }
    }
  }, [refDrawingLayer, dispatch]);

  useEffect(() => {
    const scrollContainer = refScroll?.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', moveStageFromScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', moveStageFromScroll);
      }
    };
  }, [moveStageFromScroll, refScroll]);
};

export { useScrollEventsCanvas };
