import axios from 'axios';

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Same API, with longer response timeout for heavy-loaded requests
const HttpHeavyClient = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE_HEAVY_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export { HttpClient, HttpHeavyClient };
