import React, { useCallback } from 'react';
import { func, bool, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import {
  DELETE_LOGO_CATEGORY,
  deleteLogoCategory,
} from 'src/actions/brandLibraryActions';
import { useLoading } from 'src/hooks';
import styles from './DeleteLogoCategoryModal.module.scss';

const DeleteLogoCategoryModal = ({ isShowing, hide, id }) => {
  const dispatch = useDispatch();

  const { id: libraryId } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const deleteCategory = useCallback(() => {
    dispatch(deleteLogoCategory(libraryId, id));
  }, [dispatch, id, libraryId]);

  const loading = useLoading(DELETE_LOGO_CATEGORY);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Delete the whole category?
        </span>
        <span className={styles.subtitle}>
          If you delete the entire selected category, it will delete all of your logos inside of it
          – permanently. Are you sure you want to delete it?
        </span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={deleteCategory}
            loading={loading}
            alternativeCTA
          >
            Yes, delete the category
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteLogoCategoryModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  id: number.isRequired,
};

export { DeleteLogoCategoryModal };
