import { useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { toastSuccessConfig, toastErrorConfig } from 'src/constants/toastConfig';
import { useProjectSelector, useSession } from 'src/hooks';
import { shareAsEmbed } from 'src/utils/embed';
import { updateProjectEmbedCodeGenerated } from 'src/actions/projectActions';
import { trackCopyEmbedCode } from 'src/utils/analytics';

const useCopyEmbedCode = () => {
  const dispatch = useDispatch();

  const { id, elements, embedCodeGenerated, layout } = useProjectSelector();

  const linksOvelapping = useMemo(() => (
    elements.filter(element => element.link && element.isOverlapping)
  ), [elements]);

  const { user } = useSession();

  const copyEmbedInClipboard = useCallback(async () => {
    const code = shareAsEmbed(id, user.settings.showMadeWithStaggerBadge);
    await navigator.clipboard.writeText(code);
  }, [id, user]);

  const generateEmbedCode = useCallback(async (copyInClipboard = true) => {
    if (linksOvelapping?.length) {
      toast.error('Linked element cannot overlap two canvases', toastErrorConfig);
      return;
    }
    if (!embedCodeGenerated) {
      dispatch(updateProjectEmbedCodeGenerated(id, { embedCodeGenerated: true }));
    } else {
      if (copyInClipboard) {
        await copyEmbedInClipboard();
      }
      trackCopyEmbedCode(id, layout.source);
      toast.success('Embed code copied, now go paste that thing! 💻', toastSuccessConfig);
    }
  }, [
    copyEmbedInClipboard,
    dispatch,
    embedCodeGenerated,
    id,
    layout.source,
    linksOvelapping?.length,
  ]);

  return { generateEmbedCode, linksOvelapping, copyEmbedInClipboard };
};

export { useCopyEmbedCode };
