import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';

import DataIcon from 'src/assets/icons/data-icon.svg';
import { useSession } from 'src/hooks';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { createCustomerPortalSession } from 'src/actions/userActions';
import styles from './DisabledContent.module.scss';

const DisabledContent = ({ text }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user } = useSession();
  const { customerId, membership: { subscription } } = user;

  const upgradeRequest = useCallback(() => {
    if (subscription && customerId) {
      dispatch(createCustomerPortalSession(customerId, pathname));
    } else {
      history.push(routesPaths.choosePlan, { pathname });
    }
  }, [customerId, subscription, dispatch, history, pathname]);

  return (
    <div className={styles.container}>
      <img
        src={DataIcon}
        alt=""
      />
      <span className={styles.title}>
        Let&apos;s get you upgraded!
      </span>
      <span className={styles.text}>
        {text}
      </span>
      <Button
        className={styles.button}
        onClick={upgradeRequest}
        secondary
      >
        Upgrade now
      </Button>
    </div>
  );
};

DisabledContent.propTypes = {
  text: string.isRequired,
};

export { DisabledContent };
