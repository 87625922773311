import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  searchInBrandLibrary,
  SEARCH_IN_BRAND_LIBRARY,
  SHOW_ASSET_DETAILS,
  SHOW_LOGO_DETAILS,
} from 'src/actions/brandLibraryActions';
import { useStatus, usePagination, useSession } from 'src/hooks';
import { LOADING, SUCCESS } from 'src/constants/status';
import { Logo } from '../logo-category/logo';
import { Asset } from '../asset-category/asset';
import { AssetDetailsModal } from '../asset-category/asset-details-modal';
import { LogoDetailsModal } from '../logo-category/logo-details-modal';
import styles from './SearchResults.module.scss';

const SCROLLABLE_CONTAINER_ID = 'searchScrollableContainer';

const SearchResults = () => {
  const dispatch = useDispatch();

  const { searchResults, searchTerm } = useSelector(({ brandLibrary }) => ({
    searchResults: brandLibrary.searchResults,
    searchTerm: brandLibrary.searchTerm,
  }));

  const { accountId } = useSession();

  const { status: showAssetDetailsStatus } = useStatus(SHOW_ASSET_DETAILS);
  const { status: showLogoDetailsStatus } = useStatus(SHOW_LOGO_DETAILS);

  const searchRequest = useCallback(async page => {
    const perPage = 12;
    await dispatch(searchInBrandLibrary(accountId, searchTerm, page, perPage));
  }, [dispatch, accountId, searchTerm]);

  const { nextPage } = usePagination(searchRequest);

  const {
    status: searchStatus,
    statusInfo: searchStatusInfo,
  } = useStatus(SEARCH_IN_BRAND_LIBRARY);

  useEffect(() => {
    if (!searchResults) {
      searchRequest(1);
    }
  }, [searchRequest, searchResults]);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (searchStatus !== LOADING && searchStatusInfo?.hasNextPage &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [searchStatus, nextPage, searchStatusInfo]);

  return (
    <>
      <div className={styles.header}>
        <span className={styles.title}>Search results for &quot;{searchTerm}&quot;</span>
      </div>
      {searchStatus === SUCCESS && !searchResults?.length ? (
        <>
          <span className={styles.emptyTitle}>No results.</span>
          <span className={styles.emptySubtitle}>
            We couldn&apos;t find any results matching &quot;{searchTerm}&quot;
          </span>
        </>
      ) : (
        <div className={styles.results} id={SCROLLABLE_CONTAINER_ID}>
          <InfiniteScroll
            dataLength={searchResults?.length || 0}
            next={nextPage}
            hasMore
            scrollableTarget={SCROLLABLE_CONTAINER_ID}
            style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
          >
            {searchResults?.map(elem => {
              if (elem.assetOrLogo === 'asset') {
                return <Asset key={elem.url} asset={elem} />;
              }
              if (elem.assetOrLogo === 'logo') {
                return <Logo key={elem.url} logo={elem} />;
              }
              return null;
            })}
          </InfiniteScroll>
        </div>
      )}
      {showAssetDetailsStatus === SUCCESS && <AssetDetailsModal />}
      {showLogoDetailsStatus === SUCCESS && <LogoDetailsModal />}
    </>
  );
};

export { SearchResults };
