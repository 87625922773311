import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';

import LockIcon from 'src/assets/icons/lock-tool.svg';
import LockWhiteIcon from 'src/assets/icons/lock-tool-white.svg';
import UnlockIcon from 'src/assets/icons/unlock-tool.svg';
import UnlockWhiteIcon from 'src/assets/icons/unlock-tool-white.svg';
import LinkIcon from 'src/assets/icons/link-tool.svg';
import LinkIconWhite from 'src/assets/icons/link-tool-white.svg';
import MoveBackwardIcon from 'src/assets/icons/move-backward-tool.svg';
import MoveBackwardWhiteIcon from 'src/assets/icons/move-backward-tool-white.svg';
import MoveForwardIcon from 'src/assets/icons/move-forward-tool.svg';
import MoveForwardWhiteIcon from 'src/assets/icons/move-forward-tool-white.svg';
import DeleteIcon from 'src/assets/icons/delete-tool.svg';
import DeleteIconWhite from 'src/assets/icons/delete-tool-white.svg';
import CloneIconWhite from 'src/assets/icons/clone-tool-white.svg';
import MoveIconWhite from 'src/assets/icons/move-tool-white.svg';
import MultipleSelectIconWhite from 'src/assets/icons/multiple-select-tool-white.svg';
import { useProjectSelector } from 'src/hooks';
import {
  cloneElements,
  deleteElement,
  lockElement,
  moveElementBackward,
  moveElementForward,
  unlockElement,
} from 'src/actions/projectActions';
import { VIDEO_ELEMENT } from 'src/constants/canvasElements';
import {
  closeRightClickOptions,
  setEditableHyperlink,
  setSelectingMultipleMobile,
} from 'src/actions/canvasActions';
import { areSelectedElementsDeleteable } from 'src/utils/canvasHelpers';

const ElementGeneralOptions = ({ optionClassName, showMovementOptions }) => {
  const dispatch = useDispatch();

  const {
    selectedElements,
    elements,
    groupsOfElementsById,
    type,
  } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);
  const moreThanOneSelected = selectedElementsList.length > 1;
  let selectedElementStore;
  let selectedElementType;
  let selectedElementIsUnlocked = false;

  if (selectedElementsList.length === 1) {
    selectedElementStore = elements.find(element => element?.uuid === selectedElementsList[0]);
    selectedElementType = selectedElementStore?.type;
    selectedElementIsUnlocked = selectedElementStore?.unlocked;
  }

  const moveElementBackwardRequest = useCallback(() => {
    dispatch(moveElementBackward());
  }, [dispatch]);

  const moveElementForwardRequest = useCallback(() => {
    dispatch(moveElementForward());
  }, [dispatch]);

  const linkRequest = useCallback(() => {
    dispatch(setEditableHyperlink(selectedElementStore?.uuid));
    dispatch(closeRightClickOptions());
  }, [dispatch, selectedElementStore]);

  const lockElementRequest = useCallback(() => {
    dispatch(lockElement());
  }, [dispatch]);

  const unlockElementRequest = useCallback(() => {
    dispatch(unlockElement());
  }, [dispatch]);

  const deleteElementRequest = useCallback(() => {
    dispatch(deleteElement());
    dispatch(closeRightClickOptions());
  }, [dispatch]);

  const cloneElementRequest = useCallback(() => {
    dispatch(cloneElements({ uuids: Object.keys(selectedElements), newElementDistance: 30 }));
    dispatch(closeRightClickOptions());
  }, [dispatch, selectedElements]);

  const selectMultipleRequest = useCallback(() => {
    dispatch(setSelectingMultipleMobile({ selectingMultipleMobile: true }));
    dispatch(closeRightClickOptions());
  }, [dispatch]);

  const isLinkDisabled = moreThanOneSelected || selectedElementType === VIDEO_ELEMENT;

  const multipleElementsDeleteable = areSelectedElementsDeleteable(
    selectedElements,
    elements,
    groupsOfElementsById,
    type,
  );

  return (
    <>
      <button
        className={optionClassName}
        onClick={moveElementForwardRequest}
        disabled={moreThanOneSelected}
      >
        <img
          src={moreThanOneSelected ? MoveForwardIcon : MoveForwardWhiteIcon}
          alt="Move Forward"
        />
      </button>
      <button
        className={optionClassName}
        onClick={moveElementBackwardRequest}
        disabled={moreThanOneSelected}
      >
        <img
          src={moreThanOneSelected ? MoveBackwardIcon : MoveBackwardWhiteIcon}
          alt="Move Backward"
        />
      </button>
      <button
        className={optionClassName}
        onClick={cloneElementRequest}
      >
        <img
          src={CloneIconWhite}
          alt="Clone"
        />
      </button>
      <button
        className={optionClassName}
        onClick={linkRequest}
        disabled={isLinkDisabled}
      >
        <img
          src={isLinkDisabled ? LinkIcon : LinkIconWhite}
          alt="Link"
        />
      </button>
      {selectedElementIsUnlocked ? (
        <button
          className={optionClassName}
          onClick={lockElementRequest}
          disabled={moreThanOneSelected}
        >
          <img
            src={moreThanOneSelected ? LockIcon : LockWhiteIcon}
            alt="Lock"
          />
        </button>
      )
        : (
          <button
            className={optionClassName}
            onClick={unlockElementRequest}
            disabled={moreThanOneSelected}
          >
            <img
              src={moreThanOneSelected ? UnlockIcon : UnlockWhiteIcon}
              alt="Unlock"
            />
          </button>
        )}
      <button
        className={optionClassName}
        onClick={deleteElementRequest}
        disabled={!multipleElementsDeleteable}
      >
        <img
          src={!multipleElementsDeleteable ? DeleteIcon : DeleteIconWhite}
          alt="Delete"
        />
      </button>
      <button
        className={optionClassName}
        onClick={showMovementOptions}
      >
        <img
          src={MoveIconWhite}
          alt="Move"
        />
      </button>
      <button
        className={optionClassName}
        onClick={selectMultipleRequest}
      >
        <img
          src={MultipleSelectIconWhite}
          alt="Multiple Select"
        />
      </button>
    </>
  );
};

ElementGeneralOptions.propTypes = {
  optionClassName: string,
  showMovementOptions: func.isRequired,
};

export { ElementGeneralOptions };
