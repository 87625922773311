import { useState, useEffect, useCallback } from 'react';

const DRAG_HANDLERS = {
  coordinates: ({ clientX, clientY }) => ({ clientX, clientY }),
  dragEvent: { name: 'mousemove' },
  dragEndEvent: { name: 'mouseup' },
};

// NOTE: From https://github.com/odedglas/react-linear-gradient-picker
const useDragging = ({ onDragStart = () => {}, onDrag, onDragEnd = () => {} }) => {
  const [context, setContext] = useState({});
  const [dragging, setDragging] = useState(false);

  const activate = (e, handler) => {
    setDragging(true);
    context.handler = handler;

    onDragStart(handler.coordinates(e));
  };

  const dragHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.button) {
      activate(e, DRAG_HANDLERS);
    }
  };

  const deactivate = useCallback(() => {
    setDragging(false);

    onDragEnd(context.change);
    setContext({});
  }, [context.change, onDragEnd]);

  const handleDrag = useCallback((e) => {
    const { handler } = context;
    if (!dragging) return;

    context.change = onDrag(handler.coordinates(e));
  }, [context, dragging, onDrag]);

  useEffect(() => {
    const { handler } = context;
    if (!handler) return;

    const { dragEvent, dragEndEvent } = handler;

    if (dragging) {
      document.addEventListener(dragEvent.name, handleDrag, dragEndEvent.options);
      document.addEventListener(dragEndEvent.name, deactivate);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener(dragEvent.name, handleDrag, dragEndEvent.options);
      document.removeEventListener(dragEndEvent.name, deactivate);
    };
  }, [context, deactivate, dragging, handleDrag]);

  return [
    dragHandler,
    activate,
    deactivate,
  ];
};

export { useDragging };
