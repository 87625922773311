import React, { useState, useCallback } from 'react';
import { number, arrayOf, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteColorPalette,
  updatePaletteName,
} from 'src/actions/brandLibraryActions';
import { Color } from './color';
import { CategoryName } from '../category-name';
import { DeleteColorPaletteModal } from './delete-color-palette-modal';
import styles from './ColorPalette.module.scss';
import { AddColor } from './add-color';

const ColorPalette = ({ id, name, colors }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { id: libraryId } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
  }));

  const deletePalette = useCallback(() => {
    dispatch(deleteColorPalette(libraryId, id));
  }, [dispatch, id, libraryId]);

  const onClickDelete = useCallback(() => {
    if (colors?.length) {
      setModalOpen(true);
    } else {
      deletePalette();
    }
  }, [colors, deletePalette]);

  const updateCategory = async (params) => {
    await dispatch(updatePaletteName(libraryId, id, params));
  };

  return (
    <>
      <div className={styles.header}>
        <CategoryName
          name={name}
          onClickDelete={onClickDelete}
          updateCategory={updateCategory}
        />
      </div>
      <div className={styles.colors}>
        <AddColor paletteId={id} />
        {colors?.map(color => <Color color={color} key={color.id} paletteId={id} />)}
      </div>
      {modalOpen && (
        <DeleteColorPaletteModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          id={id}
        />
      )}
    </>
  );
};

ColorPalette.propTypes = {
  colors: arrayOf(shape({
    hex: string,
    alpha: number,
  })).isRequired,
  id: number.isRequired,
  name: string.isRequired,
};

export { ColorPalette };
