import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { arrayOf, func, object } from 'prop-types';

import {
  moveElementToFront,
  moveElementToBack,
  deleteElement,
  addGroupElement,
  ungroupElements,
  lockElement,
  unlockElement,
  replaceImageFromRightClick,
} from 'src/actions/projectActions';
import { setEditableHyperlink, setReplaceableImageRightClick } from 'src/actions/canvasActions';
import { areSelectedElementsDeleteable } from 'src/utils/canvasHelpers';
import {
  CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT_FOR_IMAGES,
} from 'src/constants/general';
import { useProjectSelector } from 'src/hooks';
import {
  GROUP_ELEMENT,
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
} from 'src/constants/canvasElements';
import styles from './CanvasRightClickOptions.module.scss';

const CanvasRightClickOptions = ({ onCopy, onPaste, copiedElements }) => {
  const dispatch = useDispatch();

  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  const {
    selectedElements,
    groupsOfElementsById,
    elements,
    type,
  } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const multipleSelectedElements = selectedElementsList.length > 1;

  let selectedElementStore;
  let selectedElementType;
  let selectedElementIsUnlocked = false;

  if (selectedElementsList.length === 1) {
    selectedElementStore = elements.find(
      element => element?.uuid === selectedElementsList[0],
    );
    selectedElementType = selectedElementStore?.type;
    selectedElementIsUnlocked = selectedElementStore?.unlocked;
  }

  const onPasteAndReplace = useCallback(() => {
    if (copiedElements?.length) {
      const copiedElement = copiedElements.find(elem => elem.type === IMAGE_ELEMENT);
      if (copiedElement) {
        dispatch(replaceImageFromRightClick({
          media: copiedElement,
          replaceableImage: selectedElementStore,
        }));
      }
    }
  }, [copiedElements, dispatch, selectedElementStore]);

  const moveElementToFrontRequest = useCallback(() => {
    dispatch(moveElementToFront());
  }, [dispatch]);

  const moveElementToBackRequest = useCallback(() => {
    dispatch(moveElementToBack());
  }, [dispatch]);

  const deleteElementRequest = useCallback(() => {
    dispatch(deleteElement());
  }, [dispatch]);

  const groupElementsRequest = useCallback(() => {
    dispatch(addGroupElement());
  }, [dispatch]);

  const ungroupElementsRequest = useCallback(() => {
    dispatch(ungroupElements(selectedElementStore?.uuid));
  }, [dispatch, selectedElementStore]);

  const lockElementRequest = useCallback(() => {
    dispatch(lockElement());
  }, [dispatch]);

  const unlockElementRequest = useCallback(() => {
    dispatch(unlockElement());
  }, [dispatch]);

  const linkRequest = useCallback(() => {
    dispatch(setEditableHyperlink(selectedElementStore?.uuid));
  }, [dispatch, selectedElementStore]);

  const changeImage = () => {
    dispatch(setReplaceableImageRightClick({
      attrs: {
        ...selectedElementStore,
      },
    }));
  };

  const multipleElementsDeleteable = areSelectedElementsDeleteable(
    selectedElements,
    elements,
    groupsOfElementsById,
    type,
  );

  const isGroupDisabled = useMemo(() => {
    const hasVideo = selectedElementsList.reduce((res, selectedElemId) => {
      const selectedElement = elements.find(elem => elem.uuid === selectedElemId);
      return res || selectedElement?.type === VIDEO_ELEMENT;
    }, false);
    return !multipleSelectedElements || (multipleSelectedElements && hasVideo);
  }, [elements, multipleSelectedElements, selectedElementsList]);

  const isLinkDisabled = multipleSelectedElements || selectedElementType === VIDEO_ELEMENT;

  const heightContainer = useMemo(() => {
    if (selectedElementType === IMAGE_ELEMENT) {
      return `${CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT_FOR_IMAGES}rem`;
    }
    return `${CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT}rem`;
  }, [selectedElementType]);

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: rightClickOptions.y,
        left: rightClickOptions.x,
        height: heightContainer,
        width: `${CANVAS_RIGHT_CLICK_OPTIONS_WIDTH}rem`,
      }}
    >
      <div className={styles.group}>
        <button
          className={styles.option}
          onClick={onCopy}
        >
          Copy
        </button>
        <button
          className={styles.option}
          onClick={onPaste}
        >
          Paste
        </button>
        {selectedElementType === IMAGE_ELEMENT && (
          <button
            className={styles.option}
            onClick={onPasteAndReplace}
            disabled={multipleSelectedElements || !selectedElementIsUnlocked}
          >
            Paste and Replace
          </button>
        )}
      </div>
      <div className={styles.group}>
        <button
          className={styles.option}
          onClick={moveElementToFrontRequest}
          disabled={multipleSelectedElements}
        >
          Move to Front
        </button>
        <button
          className={styles.option}
          onClick={moveElementToBackRequest}
          disabled={multipleSelectedElements}
        >
          Move to Back
        </button>
        {selectedElementType === IMAGE_ELEMENT && (
          <button
            className={styles.option}
            onClick={changeImage}
            disabled={!selectedElementIsUnlocked}
          >
            Change Image
          </button>
        )}
      </div>
      <div className={styles.group}>
        <button
          className={styles.option}
          onClick={linkRequest}
          disabled={isLinkDisabled}
        >
          Hyperlink
        </button>
        {selectedElementIsUnlocked ? (
          <button
            className={styles.option}
            onClick={lockElementRequest}
            disabled={multipleSelectedElements}
          >
            Lock
          </button>
        ) : (
          <button
            className={styles.option}
            onClick={unlockElementRequest}
            disabled={multipleSelectedElements}
          >
            Unlock
          </button>
        )}
        {(selectedElementType !== GROUP_ELEMENT || multipleSelectedElements) && (
        <button
          className={styles.option}
          onClick={groupElementsRequest}
          disabled={isGroupDisabled}
        >
          Group
        </button>
        )}
        {selectedElementType === GROUP_ELEMENT && !multipleSelectedElements && (
        <button
          className={styles.option}
          onClick={ungroupElementsRequest}
          disabled={multipleSelectedElements}
        >
          Ungroup
        </button>
        )}
      </div>
      <div className={styles.group}>
        <button
          className={styles.option}
          onClick={deleteElementRequest}
          disabled={!multipleElementsDeleteable}
        >
          Delete
        </button>
      </div>
    </div>,
    document.getElementById('root'),
  );
};

CanvasRightClickOptions.propTypes = {
  onCopy: func.isRequired,
  onPaste: func.isRequired,
  copiedElements: arrayOf(object),
};

export { CanvasRightClickOptions };
