import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Dropzone } from 'src/common/dropzone';
import { uploadMedia } from 'src/actions/canvasActions';
import { IMAGE_TYPE } from 'src/constants/uploadFile';
import {
  addMessagesToChat,
  askForAmountFromChat,
  removeLastMessageOfType,
} from 'src/actions/collageActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import { AnswerToChat } from '../answer-to-chat';
import { MediaInChat } from '../media-in-chat';
import styles from './UploadMediaToChat.module.scss';

const loadingId = `chat_${uuidv4()}`;

const UploadMediaToChat = () => {
  const dispatch = useDispatch();

  const { chatMedia } = useSelector(({ collage }) => ({
    chatMedia: collage.chatMedia,
  }));

  const addMedia = useCallback(images => {
    dispatch(uploadMedia(images, loadingId));
  }, [dispatch]);

  const addFiles = files => {
    const formattedFiles = files.map(file => {
      const img = { file };
      img.fileType = IMAGE_TYPE;
      return img;
    });
    addMedia(formattedFiles);
  };

  const finishUploading = async () => {
    await dispatch(removeLastMessageOfType({ type: COLLAGE_CHAT_TYPE.UPLOAD_MEDIA }));
    await dispatch(addMessagesToChat({
      messages: [
        { type: COLLAGE_CHAT_TYPE.MEDIA_IN_CHAT, loadingId, id: uuidv4() },
      ],
    }));
    dispatch(askForAmountFromChat());
  };

  const media = Object.values(chatMedia).flatMap(m => m.media);

  return (
    <>
      <Dropzone
        onAcceptCallback={addFiles}
        acceptType={{ 'image/*': ['.heic'] }}
        buttonFile
        noClick
        className={styles.container}
      />
      {chatMedia[loadingId] && (
        <MediaInChat
          loadingId={loadingId}
        />
      )}
      <AnswerToChat
        text="I'm ready for the next step"
        onClick={finishUploading}
        disabled={media.length < 2}
      />
    </>
  );
};

export { UploadMediaToChat };
