import {
  getUserWithProjectsSuccess,
  loginSuccess,
  logout,
  signupSuccess,
  socialLogin,
  editPersonalInformationSuccess,
  getPersonalInformationSuccess,
  getBillingInformationSuccess,
  userExportedProjectSuccess,
  setSmallScreen,
  finishOnboardingSuccess,
  createFreeTrialSuccess,
  changeDefaultAccountIdSuccess,
  setUserPersonaSuccess,
  signupOnlyEmailSuccess,
} from 'src/actions/userActions';
import {
  dismissModalSuccess,
  changeEmailSubscriptionSuccess,
  closeSuggestionUpgradeInfoSuccess,
  closeFirstSignModalSuccess,
  userFinishesProductTourSuccess,
  userDoingProductTour,
  closeProductTipSuccess,
  showProductTipSuccess,
  setDesignTipsStateSuccess,
} from 'src/actions/userSettingsActions';
import {
  setCurrentBrandAssetStatusSuccess,
  processWebsiteSuccess,
  fillBrandLibraryFromWebsiteSuccess,
  getUserWithAccountSuccess,
  getOnboardingInformationSuccess,
  saveOnboardingStepSuccess,
  reProcessWebsiteSuccess,
  getAccountProjectsSuccess,
  updateAndProcessWebsiteSuccess,
  addAccountSuccess,
  updateAccountNameSuccess,
  setAccountId,
  deleteAccountSuccess,
  deleteAccountsMovingProjectsSuccess,
  deleteAccountsAgencyDowngradeSuccess,
  moveProjectsAgencyDowngradeSuccess,
  downgradePlanFromAgencySuccess,
  getUserForAgencyDowngradeSuccess,
} from 'src/actions/accountActions';
import {
  deleteProjectSuccess,
  updateProjectSuccess,
  updateProjectAutomaticallySuccess,
  duplicateProjectSuccess,
  getProjectSuccess,
} from 'src/actions/projectActions';
import { getUserWithBrandLibrarySuccess } from 'src/actions/brandLibraryActions';
import { getUserForDashboardSuccess } from 'src/actions/embedAnalyticsActions';
import { trackLogin, trackSignup } from 'src/utils/analytics';
import {
  MOBILE_SCREEN_NOT_SEEN_MODAL,
  BRAND_ASSETS_STATUS,
  FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS,
} from 'src/constants/general';
import { UPGRADE_DASHBOARD } from 'src/constants/dismissibleModals';
import createReducer from './createReducer';

const initialState = {
  authenticated: false,
  user: null,
  token: '',
  productTour: false,
  hasPassword: true,
  fromSmallScreen: MOBILE_SCREEN_NOT_SEEN_MODAL,
  onboarding: {
    businessInfo: {},
    targetDemographic: {},
    mainCTA: {},
  },
  billingInformation: undefined,
  accountId: undefined,
};

const getAccount = (user, accountId) => (
  user.membership.accounts.find(a => a.id === accountId) ||
    user.teams?.find(t => t.account.id === accountId)?.account
);

const actionHandlers = {
  [loginSuccess]: (state, { payload: { user, token } }) => {
    state.user = { ...user, socialSignin: false, alreadyExisted: true };
    state.token = token;
    state.authenticated = true;
    state.accountId = user.defaultAccountId;
  },
  [socialLogin]: (state, { payload: { token, alreadyExisted } }) => {
    state.token = token;
    state.authenticated = true;
    state.user = { socialSignin: true, alreadyExisted };
  },
  [signupSuccess]: (state, { payload: { user, token } }) => {
    state.user = { ...user, socialSignin: false, alreadyExisted: false };
    state.token = token;
    state.authenticated = true;
    state.accountId = user.defaultAccountId;
  },
  [signupOnlyEmailSuccess]: (state, { payload: { user, token } }) => {
    state.user = { ...user, socialSignin: false, alreadyExisted: false };
    state.token = token;
    state.authenticated = true;
    state.accountId = user.defaultAccountId;
  },
  [getUserWithProjectsSuccess]: (state, { payload: { user } }) => {
    if (state.user.socialSignin) {
      if (state.user.alreadyExisted) {
        trackLogin(user);
      } else {
        trackSignup(user);
      }
    }
    state.user = user;
    if (!state.accountId) {
      state.accountId = user.defaultAccountId;
    }
  },
  [getProjectSuccess]: (state, { payload: { account } }) => {
    state.accountId = account.id;
  },
  [changeDefaultAccountIdSuccess]: (state, { payload: { user } }) => {
    state.user.defaultAccountId = user.defaultAccountId;
    state.accountId = user.defaultAccountId;
  },
  [getAccountProjectsSuccess]: (state, { payload: { projects } }) => {
    const account = getAccount(state.user, state.accountId);
    account.projects = [
      ...(account.projects || []),
      ...projects,
    ];
  },
  [getUserWithBrandLibrarySuccess]: (state, { payload: { user } }) => {
    if (state.user.socialSignin) {
      if (state.user.alreadyExisted) {
        trackLogin(user);
      } else {
        trackSignup(user);
      }
    }
    state.user = user;
    if (!state.accountId) {
      state.accountId = user.defaultAccountId;
    }
  },
  [getUserForDashboardSuccess]: (state, { payload: { user } }) => {
    state.user = user;
    if (!state.accountId) {
      state.accountId = user.defaultAccountId;
    }
  },
  [duplicateProjectSuccess]: (state, { payload: { project } }) => {
    const account = getAccount(state.user, state.accountId);
    account.projects = [
      project,
      ...account.projects.filter(proj => project.id !== proj.id),
    ];
  },
  [deleteProjectSuccess]: (state, { payload: { projectId } }) => {
    const account = getAccount(state.user, state.accountId);
    account.projects = account.projects.filter(project => project.id !== projectId);
  },
  [updateProjectSuccess]: (state, { payload: { project } }) => {
    const account = getAccount(state.user, state.accountId);
    if (account.projects) {
      account.projects = [
        project,
        ...account.projects.filter(proj => project.id !== proj.id),
      ];
    }
  },
  [updateProjectAutomaticallySuccess]: (state, { payload: { project } }) => {
    const account = getAccount(state.user, state.accountId);
    if (account.projects) {
      account.projects = [
        project,
        ...account.projects.filter(proj => project.id !== proj.id),
      ];
    }
  },
  [setDesignTipsStateSuccess]: (state, { payload: { user } }) => {
    state.user.settings = user.settings;
  },
  [userFinishesProductTourSuccess]: (state, { payload: { user } }) => {
    state.user.settings = user.settings;
    state.productTour = false;
  },
  [closeProductTipSuccess]: (state, { payload: { user } }) => {
    state.user.settings = user.settings;
  },
  [showProductTipSuccess]: (state, { payload: { user } }) => {
    state.user.settings = user.settings;
  },
  [userDoingProductTour]: (state, { payload }) => {
    state.productTour = payload;
  },
  [editPersonalInformationSuccess]: (state, { payload: { user } }) => {
    state.user = user;
  },
  [userExportedProjectSuccess]: (state, { payload: { user } }) => {
    state.user.exportedProjects = user.exportedProjects;
  },
  [getPersonalInformationSuccess]: (state, { payload: { user, hasPassword } }) => {
    state.user = user;
    state.hasPassword = hasPassword;
    if (!state.accountId) {
      state.accountId = user.defaultAccountId;
    }
  },
  [changeEmailSubscriptionSuccess]: (state, { payload: { user } }) => {
    state.user = user;
  },
  [getBillingInformationSuccess]: (state, { payload }) => {
    state.billingInformation = payload;
  },
  [setSmallScreen]: (state, { payload }) => {
    state.fromSmallScreen = payload;
  },
  [finishOnboardingSuccess]: (state, { payload }) => {
    state.user.didOnboarding = payload.user.didOnboarding;
  },
  [saveOnboardingStepSuccess]: (state, { payload }) => {
    state.onboarding = {
      ...state.onboarding,
      ...payload,
    };
  },
  [getUserWithAccountSuccess]: (state, { payload: { user } }) => {
    state.user = user;
    if (!state.accountId) {
      state.accountId = user.defaultAccountId;
    }
  },
  [getOnboardingInformationSuccess]: (state, { payload }) => {
    state.onboarding = {
      ...state.onboarding,
      ...payload,
    };
  },
  [dismissModalSuccess]: (state, { payload: { user, dismissedModal } }) => {
    if (dismissedModal === UPGRADE_DASHBOARD) {
      state.user.settings.showDashboardModal = user.settings.showDashboardModal;
    }
  },
  [createFreeTrialSuccess]: (state, { payload: { user } }) => {
    state.user = user;
  },
  [setCurrentBrandAssetStatusSuccess]: (state, { payload: { account: updatedAcount } }) => {
    const account = getAccount(state.user, state.accountId);
    account.brandAssetStatus = updatedAcount.brandAssetStatus;
  },
  [processWebsiteSuccess]: state => {
    const account = getAccount(state.user, state.accountId);
    account.brandAssetStatus = BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY;
  },
  [fillBrandLibraryFromWebsiteSuccess]: (state, { payload: { status } }) => {
    const account = getAccount(state.user, state.accountId);
    if (status === FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.DONE) {
      account.brandAssetStatus = BRAND_ASSETS_STATUS.READY_TO_NOTIFY;
    } else if (status === FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.FAILED) {
      account.brandAssetStatus = BRAND_ASSETS_STATUS.FAILED;
    } else if (status === FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS.ALREADY_FILLING) {
      account.brandAssetStatus = BRAND_ASSETS_STATUS.API_ERROR;
    }
  },
  [reProcessWebsiteSuccess]: state => {
    const account = getAccount(state.user, state.accountId);
    account.brandAssetStatus = BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY;
  },
  [updateAndProcessWebsiteSuccess]: state => {
    const account = getAccount(state.user, state.accountId);
    account.brandAssetStatus = BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY;
  },
  [closeSuggestionUpgradeInfoSuccess]: (state, { payload: { user } }) => {
    state.user.settings.closedSuggestionUpgradeInfo = user.settings.closedSuggestionUpgradeInfo;
  },
  [closeFirstSignModalSuccess]: (state, { payload: { user } }) => {
    state.user.settings.closedFirstSignModal = user.settings.closedFirstSignModal;
  },
  [addAccountSuccess]: (state, { payload: { account } }) => {
    state.user.membership.accounts.push(account);
  },
  [updateAccountNameSuccess]: (state, { payload: { account: updatedAccount } }) => {
    const account = getAccount(state.user, updatedAccount.id);
    account.brandName = updatedAccount.brandName;
  },
  [setAccountId]: (state, { payload: { accountId } }) => {
    state.accountId = accountId;
  },
  [deleteAccountSuccess]: (state, { payload: { accountId } }) => {
    state.user.membership.accounts = (
      state.user.membership.accounts.filter(acc => acc.id !== accountId)
    );
    if (state.accountId === accountId) {
      state.accountId = state.user.defaultAccountId;
    }
  },
  [deleteAccountsMovingProjectsSuccess]: (state, { payload: { accountIdsFrom } }) => {
    state.user.membership.accounts = (
      state.user.membership.accounts.filter(acc => !accountIdsFrom.includes(acc.id))
    );
    if (accountIdsFrom.includes(state.accountId)) {
      state.accountId = state.user.defaultAccountId;
    }
  },
  [deleteAccountsAgencyDowngradeSuccess]: (state, { payload: { user, ids } }) => {
    state.user.membership.accounts = (
      state.user.membership.accounts.filter(acc => !ids.includes(acc.id))
    );
    if (ids.includes(state.accountId)) {
      state.accountId = state.user.defaultAccountId;
    }
    state.user.settings = user.settings;
  },
  [moveProjectsAgencyDowngradeSuccess]: (state, { payload: { accountIdsFrom, user } }) => {
    state.user.membership.accounts = (
      state.user.membership.accounts.filter(acc => !accountIdsFrom.includes(acc.id))
    );
    if (accountIdsFrom.includes(state.accountId)) {
      state.accountId = state.user.defaultAccountId;
    }
    state.user.settings = user.settings;
  },
  [downgradePlanFromAgencySuccess]: (state, { payload: { ids, user } }) => {
    state.user.membership.accounts = (
      state.user.membership.accounts.filter(acc => !ids.includes(acc.id))
    );
    if (ids.includes(state.accountId)) {
      state.accountId = state.user.defaultAccountId;
    }
    state.user.settings = user.settings;
  },
  [getUserForAgencyDowngradeSuccess]: (state, { payload: { user } }) => {
    state.user = user;
  },
  [setUserPersonaSuccess]: (state, { payload: { user } }) => {
    state.user.persona = user.persona;
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
