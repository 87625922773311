import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';

import { useSession } from 'src/hooks';
import { Modal } from 'src/common/modal';
import { setCurrentBrandAssetStatus } from 'src/actions/accountActions';
import { BRAND_ASSETS_STATUS, USER_WEBSITE_MODAL_ID } from 'src/constants/general';
import { UpdateWebsiteWarning } from 'src/common/user-website-modal/update-website-warning';
import { EnterWebsite } from 'src/common/user-website-modal/enter-website';
import { AnalysingWebsite } from 'src/common/user-website-modal/analysing-website';
import { WebsiteScrapped } from 'src/common/user-website-modal/website-scrapped';
import styles from './UserWebsiteModal.module.scss';

const UserWebsiteModal = ({ showEditWebsiteModal = false, closeEditWebsiteModal }) => {
  const dispatch = useDispatch();
  const { user, accountId, account } = useSession();
  const { brandAssetStatus } = account || {};

  const defaultOpen = brandAssetStatus === BRAND_ASSETS_STATUS.ASKING_FOR_A_WEBSITE ||
    showEditWebsiteModal;
  const [modalOpen, setModalOpen] = useState(defaultOpen);

  const onClose = useCallback(() => {
    const params = {
      brandAssetStatus: BRAND_ASSETS_STATUS.DONE,
      id: accountId,
    };
    dispatch(setCurrentBrandAssetStatus(params));
    setModalOpen(false);
    closeEditWebsiteModal && closeEditWebsiteModal();
  }, [closeEditWebsiteModal, dispatch, accountId]);

  const inferredWebsite = useMemo(() => {
    const domain = user.email.split('@')[1];
    if (!/(gmail|yahoo|hotmail|msn|aol|live|outlook|icloud)/.test(domain)) {
      return `www.${domain}`;
    }
    return '';
  }, [user.email]);

  const defaultStep = useMemo(() => {
    if (showEditWebsiteModal) {
      return 0;
    }
    return 1;
  }, [showEditWebsiteModal]);

  const [currentStep, setCurrentStep] = useState(defaultStep);

  const steps = [
    UpdateWebsiteWarning,
    EnterWebsite,
    AnalysingWebsite,
    WebsiteScrapped,
  ];

  const Content = steps[currentStep];

  useEffect(() => {
    if (modalOpen && brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY) {
      setCurrentStep(3);
    }
  }, [modalOpen, brandAssetStatus]);

  return (
    <Modal
      isShowing={modalOpen}
      hide={onClose}
      isEscEnabled={false}
      closeOnClickOutside={currentStep === 3}
      curvedContainer
      className={styles.modal}
    >
      <div className={styles.content} id={USER_WEBSITE_MODAL_ID}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.headerTitle}>
              Let&apos;s get to know your brand a little better.
            </div>
          </div>
        </div>
        <Content
          setCurrentStep={setCurrentStep}
          setModalOpen={setModalOpen}
          inferredWebsite={inferredWebsite}
          editMode={showEditWebsiteModal}
          closeEditWebsiteModal={closeEditWebsiteModal}
        />
      </div>
    </Modal>
  );
};

UserWebsiteModal.propTypes = {
  showEditWebsiteModal: bool,
  closeEditWebsiteModal: func,
};

export { UserWebsiteModal };
