import { HttpClient } from '../clients';

class InstagramPostService {
  static exists(postId) {
    return HttpClient.get(`/instagram-post/exists/${postId}`);
  }

  static create(data) {
    return HttpClient.post('/instagram-post/', data);
  }

  static delete(postId) {
    return HttpClient.delete(`/instagram-post/${postId}`);
  }
}

export { InstagramPostService };
