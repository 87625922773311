import React, { useState, useCallback } from 'react';
import { string, number, bool, arrayOf, shape } from 'prop-types';
import { isBefore } from 'date-fns';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { Tooltip } from 'src/common/tooltip';
import { useTooltip } from 'src/hooks';
import { resendInviteToAccounts } from 'src/actions/accountActions';
import styles from './InviteCard.module.scss';

const InviteCard = ({ id, name, lastName, email, accounts, expirationDate, disabled }) => {
  const dispatch = useDispatch();
  const accountNames = accounts?.map(acc => acc.brandName).join(', ');

  const [showOptions, setShowOptions] = useState(false);
  const [expiredInvite, setExpiredInvite] =
    useState(isBefore(new Date(expirationDate), new Date()));
  const { ref, showTooltip } = useTooltip();

  const onMouseEnter = useCallback(() => {
    if (expiredInvite) {
      setShowOptions(true);
    }
  }, [expiredInvite]);

  const showPill = useCallback(() => (
    <div className={cn(styles.pillContainer, styles.filler)}>
      { expiredInvite ? (
        <div className={cn(styles.pill, styles.expired)}>
          <span onMouseEnter={onMouseEnter}>Expired</span>
        </div>
      ) : (
        <div className={cn(styles.pill, styles.invite)}>
          <span>Invite</span>
        </div>
      )}
    </div>
  ), [expiredInvite, onMouseEnter]);

  const onClickResend = useCallback(() => {
    dispatch(resendInviteToAccounts(id));
    setShowOptions(false);
    setExpiredInvite(false);
  }, [dispatch, id]);

  return (
    <div
      className={styles.container}
      onMouseLeave={() => setShowOptions(false)}
    >
      <div className={styles.card}>
        <span className={styles.name}>{name} {lastName}</span>
        <span className={styles.email}>{email}</span>
        <span
          className={styles.accounts}
          ref={ref}
        >
          {accounts.length > 1 && (`(${accounts.length})`)} {accountNames}
          {showTooltip && (
            <Tooltip className={styles.tooltip}>
              {accountNames}
            </Tooltip>
          )}
        </span>
        { showOptions ? (
          <div className={cn(styles.buttonsContainer, styles.filler)}>
            <button
              className={styles.resend}
              onClick={onClickResend}
              disabled={disabled}
            >
              Resend
            </button>
          </div>
        ) : showPill() }
      </div>
    </div>
  );
};

InviteCard.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  accounts: arrayOf(shape({
    id: number,
    brandName: string,
  })),
  disabled: bool.isRequired,
  expirationDate: string,
};

export { InviteCard };
