import React, { useMemo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { TEXT_DEFAULT_LINE_HEIGHT, CANVAS_TEXT_PADDING } from 'src/constants/general';
import { updateElement } from 'src/actions/projectActions';
import { LineHeightInput } from 'src/common/line-height-input';
import { LetterSpacingInput } from 'src/common/letter-spacing-input';
import { useProjectSelector, useScrollPosition } from 'src/hooks';
import { FontSizeSelectControl } from './font-size-select-control';
import { FontFamilySelectControl } from './font-family-select-control';
import { BrandLibraryFontSelectControl } from './brand-library-font-select-control';
import { FontColorControl } from './font-color-control';
import { TextAlignmentControl } from './text-alignment-control';
import { ElementShadow } from '../element-control/element-shadow';
import { BorderOptions } from '../element-control/border-options';
import styles from './TextControl.module.scss';

const TextControl = () => {
  const dispatch = useDispatch();

  const containerRef = useRef();

  const { selectedElements, elements, selectedRefs } = useProjectSelector();

  const element = useMemo(() => {
    const selectedElementsList = Object.keys(selectedElements);
    const selectedElementId = selectedElementsList[0];
    return elements.find(elem => elem.uuid === selectedElementId);
  }, [elements, selectedElements]);

  const updateElementRequest = useCallback(attrs => {
    Object.entries(selectedRefs).forEach(([elementId, ref]) => {
      const { current: { attrs: refAttrs, textArr } } = ref;
      const lineHeight = attrs.lineHeight || refAttrs.lineHeight;
      const fontSize = attrs.fontSize || refAttrs.fontSize;
      const newAttrs = {
        height: (fontSize * lineHeight) * textArr.length + CANVAS_TEXT_PADDING * 2,
        uuid: elementId,
        brandLibraryStyleId: undefined,
        ...attrs,
      };
      dispatch(updateElement(newAttrs));
    });
  }, [dispatch, selectedRefs]);

  const { position } = useScrollPosition(containerRef);

  return (
    <div className={styles.container} ref={containerRef}>
      <TextAlignmentControl
        alignment={element?.align}
        changeAlignment={value => updateElementRequest({ align: value })}
        groupClassName={styles.group}
        scrollPosition={position}
      />
      <BrandLibraryFontSelectControl
        element={element}
        updateElementRequest={updateElementRequest}
        groupClassName={styles.group}
        scrollPosition={position}
      />
      <FontFamilySelectControl
        element={element}
        updateElementRequest={updateElementRequest}
        groupClassName={styles.group}
        scrollPosition={position}
      />
      <FontSizeSelectControl
        onChange={fontSize => updateElementRequest({ fontSize })}
        fontSize={element?.fontSize}
        groupClassName={styles.group}
        scrollPosition={position}
      />
      <FontColorControl
        element={element}
        updateElementRequest={value => updateElementRequest({ color: value })}
        groupClassName={styles.group}
        scrollPosition={position}
      />
      <div className={styles.group}>
        <LetterSpacingInput
          letterSpacing={element?.letterSpacing}
          onBlur={letterSpacing => updateElementRequest({ letterSpacing })}
        />
      </div>
      <div className={styles.group}>
        <LineHeightInput
          lineHeight={element?.lineHeight || TEXT_DEFAULT_LINE_HEIGHT}
          onBlur={lineHeight => updateElementRequest({ lineHeight })}
        />
      </div>
      <div className={styles.group}>
        <BorderOptions scrollPosition={position} disableDash paddingLeftOptions={140} />
      </div>
      <div className={styles.group}>
        <ElementShadow scrollPosition={position} paddingLeftOptions={226} />
      </div>
    </div>
  );
};

export { TextControl };
