import React from 'react';
import { useSelector } from 'react-redux';
import { Rect } from 'react-konva';

import { useProjectSelector } from 'src/hooks';
import { getRGBAColor } from 'src/utils/helpers';
import { BACKGROUND_COLOR_EYEDROPPER_SOURCE, CANVAS_BACKGROUND } from 'src/constants/general';

const CanvasBackground = () => {
  const { layout, size, color } = useProjectSelector();

  const { eyeDropper } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
  }));

  const backgroundColor = eyeDropper.active &&
    eyeDropper.source === BACKGROUND_COLOR_EYEDROPPER_SOURCE ?
    eyeDropper.color :
    getRGBAColor(color);

  return (
    <>
      <Rect
        x={0}
        y={0}
        width={layout.width * size}
        height={layout.height}
        fill="white"
      />
      <Rect
        x={0}
        y={0}
        width={layout.width * size}
        height={layout.height}
        fill={backgroundColor}
        id={CANVAS_BACKGROUND}
      />
    </>
  );
};

export { CanvasBackground };
