import React from 'react';
import { toast } from 'react-toastify';

import { ToastMarkup } from 'src/common/toast-markup';
import {
  toastErrorConfig,
  toastSuccessMarkupConfig,
} from 'src/constants/toastConfig';
import { isVideo } from 'src/utils/videoHelpers';
import { isGif } from 'src/utils/helpers';
import {
  trackCollageShowMe,
  trackCollageReGenerate,
  trackCollageKeep,
  trackCollageGenerate,
  trackCollageTriggerKeep,
} from 'src/utils/analytics';
import { ProjectService } from 'src/services/projectService';
import createAction from './createAction';

export const GENERATE_COLLAGE_REQUEST = 'GENERATE_COLLAGE_REQUEST';
export const GENERATE_COLLAGE_SUCCESS = 'GENERATE_COLLAGE_SUCCESS';
export const GENERATE_COLLAGE_ERROR = 'GENERATE_COLLAGE_ERROR';

export const RE_GENERATE_COLLAGE = 'RE_GENERATE_COLLAGE';
export const RE_GENERATE_COLLAGE_REQUEST = 'RE_GENERATE_COLLAGE_REQUEST';
export const RE_GENERATE_COLLAGE_SUCCESS = 'RE_GENERATE_COLLAGE_SUCCESS';
export const RE_GENERATE_COLLAGE_ERROR = 'RE_GENERATE_COLLAGE_ERROR';

const RECEIVE_GENERATED_COLLAGE = 'RECEIVE_GENERATED_COLLAGE';
const RECEIVE_RE_GENERATED_COLLAGE = 'RECEIVE_RE_GENERATED_COLLAGE';

export const SHOW_GENERATED_COLLAGE = 'SHOW_GENERATED_COLLAGE';
export const SHOW_GENERATED_COLLAGE_SUCCESS = 'SHOW_GENERATED_COLLAGE_SUCCESS';

export const KEEP_GENERATED_COLLAGE = 'KEEP_GENERATED_COLLAGE';

export const DISCARD_GENERATED_COLLAGE_DATA = 'DISCARD_GENERATED_COLLAGE_DATA';
export const DISCARD_GENERATED_COLLAGE_DATA_REQUEST = 'DISCARD_GENERATED_COLLAGE_DATA_REQUEST';
export const DISCARD_GENERATED_COLLAGE_DATA_SUCCESS = 'DISCARD_GENERATED_COLLAGE_DATA_SUCCESS';
export const DISCARD_GENERATED_COLLAGE_DATA_ERROR = 'DISCARD_GENERATED_COLLAGE_DATA_ERROR';

const KEEP_CURRENT_PROJECT_FOR_UNDO = 'KEEP_CURRENT_PROJECT_FOR_UNDO';
const UNDO_COLLAGE = 'UNDO_COLLAGE';

const ADD_MESSAGES_TO_CHAT = 'ADD_MESSAGES_TO_CHAT';
const ANSWER_TO_CHAT = 'ANSWER_TO_CHAT';
const START_OVER_CHAT = 'START_OVER_CHAT';
const ADD_PROJECT_MEDIA_TO_CHAT = 'ADD_PROJECT_MEDIA_TO_CHAT';
const REMOVE_LAST_MESSAGE_OF_TYPE = 'REMOVE_LAST_MESSAGE_OF_TYPE';
const ASK_FOR_COLLAGE_FROM_CHAT = 'ASK_FOR_COLLAGE_FROM_CHAT';
const ASK_FOR_AMOUNT_FROM_CHAT = 'ASK_FOR_AMOUNT_FROM_CHAT';
const DELETE_IMAGE_FROM_CHAT = 'DELETE_IMAGE_FROM_CHAT';

export const generateCollageRequest = createAction(GENERATE_COLLAGE_REQUEST);
export const generateCollageSuccess = createAction(GENERATE_COLLAGE_SUCCESS);
export const generateCollageError = createAction(GENERATE_COLLAGE_ERROR);

export const reGenerateCollageRequest = createAction(RE_GENERATE_COLLAGE_REQUEST);
export const reGenerateCollageSuccess = createAction(RE_GENERATE_COLLAGE_SUCCESS);
export const reGenerateCollageError = createAction(RE_GENERATE_COLLAGE_ERROR);

export const receiveGeneratedCollage = createAction(RECEIVE_GENERATED_COLLAGE);
export const receiveReGeneratedCollage = createAction(RECEIVE_RE_GENERATED_COLLAGE);

export const showGeneratedCollageSuccess = createAction(SHOW_GENERATED_COLLAGE_SUCCESS);

export const discardGeneratedCollageDataRequest =
  createAction(DISCARD_GENERATED_COLLAGE_DATA_REQUEST);
export const discardGeneratedCollageDataSuccess =
  createAction(DISCARD_GENERATED_COLLAGE_DATA_SUCCESS);
export const discardGeneratedCollageDataError =
  createAction(DISCARD_GENERATED_COLLAGE_DATA_ERROR);

export const keepCurrentProjectForUndo = createAction(KEEP_CURRENT_PROJECT_FOR_UNDO);
export const undoCollage = createAction(UNDO_COLLAGE);

export const addMessagesToChat = createAction(ADD_MESSAGES_TO_CHAT);
export const answerToChat = createAction(ANSWER_TO_CHAT);
export const startOverChat = createAction(START_OVER_CHAT);
export const addProjectMediaToChat = createAction(ADD_PROJECT_MEDIA_TO_CHAT);
export const removeLastMessageOfType = createAction(REMOVE_LAST_MESSAGE_OF_TYPE);
export const askForCollageFromChat = createAction(ASK_FOR_COLLAGE_FROM_CHAT);
export const askForAmountFromChat = createAction(ASK_FOR_AMOUNT_FROM_CHAT);
export const deleteImageFromChat = createAction(DELETE_IMAGE_FROM_CHAT);

export const generateCollage =
  (projectId, uploadedMedia, amountOfFrames) => async (dispatch, getState) => {
    try {
      const imageUrls = uploadedMedia.filter(media => !isVideo(media) && !isGif(media));
      if (imageUrls.length) {
        dispatch(generateCollageRequest());
        const {
          project: { present: currProject },
          session: { user },
        } = getState();
        const collageParameters = {
          maxAmountOfFrames: amountOfFrames || 100,
          project: {
            layoutWidth: currProject.layout.width,
            layoutHeight: currProject.layout.height,
            layoutSource: currProject.layout.source,
          },
        };
        await ProjectService.generateCollage(projectId, imageUrls, collageParameters);

        dispatch(generateCollageSuccess({ projectId }));
        dispatch(keepCurrentProjectForUndo({ project: currProject }));
        trackCollageGenerate(projectId, user.createdOnCampaign);
      }
    } catch (error) {
      dispatch(generateCollageError());
    }
  };

export const reGenerateCollage = (projectId) => async (dispatch, getState) => {
  try {
    dispatch(reGenerateCollageRequest());
    const { project: { present: currProject } } = getState();

    const collageParameters = {
      project: {
        layoutWidth: currProject.layout.width,
        layoutHeight: currProject.layout.height,
        layoutSource: currProject.layout.source,
      },
    };

    await ProjectService.reGenerateCollage(projectId, collageParameters);
    dispatch(reGenerateCollageSuccess());
    trackCollageReGenerate(projectId);
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(reGenerateCollageError());
  }
};

export const showGeneratedCollage = (
  projectId,
  generatedCollage,
  isNewCollage = true,
) => async dispatch => {
  dispatch(showGeneratedCollageSuccess({ projectId, generatedCollage, isNewCollage }));
  if (isNewCollage) {
    trackCollageShowMe(projectId);
  }
};

export const discardGeneratedCollageData = (projectId) => async dispatch => {
  try {
    dispatch(discardGeneratedCollageDataRequest());
    await ProjectService.discardGeneratedCollage(projectId);
    dispatch(discardGeneratedCollageDataSuccess(projectId));
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(discardGeneratedCollageDataError());
  }
};

export const keepGeneratedCollage = (projectId) => async (dispatch, getState) => {
  const { collage: { projectForUndo } } = getState();
  const action = {
    label: 'Undo',
    onClick: () => dispatch(undoCollage({ projectId, projectForUndo })),
  };
  toast(
    <ToastMarkup emoji="👍" action={action}>
      Enjoy your new design
    </ToastMarkup>, toastSuccessMarkupConfig,
  );
  dispatch(discardGeneratedCollageData(projectId));
  trackCollageKeep(projectId);
};

export const triggerKeepGeneratedCollage = (projectId) => async dispatch => {
  dispatch(discardGeneratedCollageData(projectId));
  trackCollageTriggerKeep(projectId);
};
