import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string, arrayOf, shape } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LOADING, SUCCESS } from 'src/constants/status';
import {
  updateAssetCategoryName,
  deleteAssetCategory,
  ADD_ASSETS_TO_BRAND_LIBRARY,
  SHOW_ASSET_DETAILS,
  addAssetsToBrandLibrary,
  getAssetsOfCategory,
  GET_ASSETS_OF_CATEGORY,
} from 'src/actions/brandLibraryActions';
import { useStatus, usePagination } from 'src/hooks';
import { LoadingCard } from 'src/common/loading-card';
import { UploadFilesButton } from '../upload-files-button';
import { Asset } from './asset';
import { DeleteAssetCategoryModal } from './delete-asset-category-modal';
import { AssetDetailsModal } from './asset-details-modal';
import { CategoryName } from '../category-name';
import { EmptyFilesDropzone } from '../empty-files-dropzone';
import styles from './AssetCategory.module.scss';

const SCROLLABLE_CONTAINER_ID = 'assetsScrollableContainer';

const AssetCategory = ({ id, name, assets }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { status: addAssetsStatus } = useStatus(ADD_ASSETS_TO_BRAND_LIBRARY);

  const { status: showDetailsStatus } = useStatus(SHOW_ASSET_DETAILS);

  const { id: libraryId, loadingAssets } = useSelector(({ brandLibrary }) => ({
    id: brandLibrary.id,
    loadingAssets: brandLibrary.loadingAssets,
  }));

  const deleteCategory = useCallback(() => {
    dispatch(deleteAssetCategory(libraryId, id));
  }, [dispatch, id, libraryId]);

  const onClickDelete = useCallback(() => {
    if (assets?.length) {
      setModalOpen(true);
    } else {
      deleteCategory();
    }
  }, [assets, deleteCategory]);

  const uploadFiles = (filesToSave) => {
    dispatch(addAssetsToBrandLibrary(id, filesToSave));
  };

  const updateCategory = async (params) => {
    await dispatch(updateAssetCategoryName(libraryId, id, params));
  };

  const getAssetsRequest = useCallback(async page => {
    const perPage = 12;
    await dispatch(getAssetsOfCategory(id, page, perPage));
  }, [dispatch, id]);

  const { nextPage } = usePagination(getAssetsRequest);

  const {
    status: getAssetsStatus,
    statusInfo: getAssetsStatusInfo,
  } = useStatus(GET_ASSETS_OF_CATEGORY);

  useEffect(() => {
    if (!assets) {
      getAssetsRequest(1);
    }
  }, [getAssetsRequest, assets]);

  useEffect(() => {
    const containerDiv = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (getAssetsStatus !== LOADING && getAssetsStatusInfo?.hasNextPage &&
      containerDiv.scrollHeight <= containerDiv.clientHeight) {
      nextPage();
    }
  }, [getAssetsStatus, nextPage, getAssetsStatusInfo]);

  return (
    <>
      <div className={styles.header}>
        <CategoryName
          name={name}
          onClickDelete={onClickDelete}
          updateCategory={updateCategory}
        />
        <UploadFilesButton uploadFiles={uploadFiles} />
      </div>
      <div className={styles.assets} id={SCROLLABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={assets?.length || 0}
          next={nextPage}
          hasMore
          scrollableTarget={SCROLLABLE_CONTAINER_ID}
          style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}
        >
          {assets?.map(asset => <Asset key={asset.url} asset={asset} assetCategoryId={id} />)}
          {addAssetsStatus === LOADING && loadingAssets[id] &&
            Array.from({ length: loadingAssets[id] }, (v, i) => i).map(
              (elem) => <LoadingCard key={elem} />,
            )}
        </InfiniteScroll>
        {!assets?.length && getAssetsStatus === SUCCESS &&
          (addAssetsStatus !== LOADING || (addAssetsStatus === LOADING && !loadingAssets[id])) &&
            <EmptyFilesDropzone addFiles={uploadFiles} />}
      </div>
      {modalOpen && (
        <DeleteAssetCategoryModal
          isShowing={modalOpen}
          hide={() => setModalOpen(false)}
          id={id}
        />
      )}
      {showDetailsStatus === SUCCESS && <AssetDetailsModal />}
    </>
  );
};

AssetCategory.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  assets: arrayOf(shape({
    id: number,
    url: string,
  })),
};

export { AssetCategory };
