import {
  getSuggestionsSuccess,
  setShowSuggestions,
} from 'src/actions/insightActions';
import { logout } from 'src/actions/userActions';
import createReducer from './createReducer';

const initialState = {
  suggestions: [],
  showSuggestions: true,
  suggestionsPages: null,
};

const actionHandlers = {
  [getSuggestionsSuccess]: (
    state,
    { payload: { suggestions, totalSuggestions, page, perPage } },
  ) => {
    state.suggestions = suggestions;
    state.suggestionsPages = {
      current: suggestions.length + ((page - 1) * perPage),
      total: totalSuggestions,
    };
  },
  [setShowSuggestions]: (state, { payload }) => {
    state.showSuggestions = payload;
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
