import { HttpClient } from 'src/clients';

class UserService {
  static signup(user) {
    return HttpClient.post('/users', { user });
  }

  static signupOnlyEmail(email, createdOnCampaign) {
    return HttpClient.post('/users/signup/email', { email, createdOnCampaign });
  }

  static login(credentials) {
    return HttpClient.post('/users/login', credentials);
  }

  static getUser(include, perPage) {
    return HttpClient.get('/users/profile', { params: { include, perPage } });
  }

  static update(user) {
    return HttpClient.put('/users', { user });
  }

  static updateSettings(userSettings) {
    return HttpClient.put('/users/settings', { userSettings });
  }

  static getPersonalInformation() {
    return HttpClient.get('/users/personal');
  }

  static sendResetPasswordEmail(email) {
    return HttpClient.get('/users/change-password', { params: email });
  }

  static resetPassword(data) {
    return HttpClient.put('users/change-password', { user: data });
  }

  static cancelEmailSubscription(token) {
    return HttpClient.put('users/cancel-email-subscription', { token });
  }

  static getBillingInformation() {
    return HttpClient.get('/users/billing-information');
  }

  static createCustomerPortalSession(session) {
    return HttpClient.post('/users/create-customer-portal-session', { session });
  }

  static createCheckoutSessionSubscription(pathname, plan, type) {
    return HttpClient.post('users/checkout-session-subscription', { pathname, plan, type });
  }

  static createCheckoutSessionOrderSign(pathnameSuccess, pathnameError, projectId) {
    return HttpClient.post('users/checkout-session-order-sign', {
      pathnameSuccess,
      pathnameError,
      projectId,
    });
  }

  static createFreeTrial(plan, type) {
    return HttpClient.post('users/create-free-trial', { plan, type });
  }

  static checkUsername(username) {
    return HttpClient.get(`users/username/${username}`);
  }

  static deleteUser() {
    return HttpClient.delete('users');
  }
}

export { UserService };
