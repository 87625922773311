import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';

import { Navbar } from 'src/common/navbar';
import { useSession } from 'src/hooks';
import { SearchTemplatesInput } from 'src/pages/templates/search-templates-input';
import { SearchTemplates } from 'src/pages/templates/search-templates';
import { CategoriesList } from 'src/pages/templates/categories-list';
import { SeeAllTemplates } from 'src/pages/templates/see-all-templates';
import { PreviewTemplate } from 'src/pages/templates/preview-template';
import {
  EditTemplateDetails,
} from 'src/pages/project/controls-options/templates-control/edit-template-details';
import { NavbarSignin } from 'src/pages/templates/navbar-sigin';
import { Banner } from 'src/pages/templates/banner';
import styles from './Templates.module.scss';

const Templates = () => {
  const history = useHistory();

  const { searchKeyword, searchTemplates } = useSelector(({ templates }) => ({
    searchKeyword: templates.searchKeyword,
    searchTemplates: templates.searchTemplates,
  }));

  const { authenticated } = useSession();

  const contentToShow = useMemo(() => {
    if (searchKeyword) {
      return (
        <SearchTemplates
          textClassName={styles.searchText}
          spinnerIconClassName={styles.spinnerIcon}
          gridClassName={styles.searchGrid}
        />
      );
    }
    const categoriesList = (
      <>
        <Banner />
        <CategoriesList />
      </>
    );
    if (history.location.search) {
      const { seeAll } = parse(history.location.search, { parseBooleans: true });
      if (seeAll) {
        return <SeeAllTemplates />;
      }
      return categoriesList;
    }
    return categoriesList;
  }, [history.location.search, searchKeyword]);

  const showPreview = useMemo(() => {
    if (history.location.search) {
      const { preview } = parse(history.location.search, { parseBooleans: true });
      if (preview) {
        return <PreviewTemplate />;
      }
      return null;
    }
    return null;
  }, [history.location.search]);

  return (
    <div className={styles.page}>
      {authenticated ? <Navbar /> : <NavbarSignin />}
      <div className={styles.subnav}>
        <h1 className={styles.title}>
          {searchTemplates.length ? `Results for "${searchKeyword}"` : 'Templates'}
        </h1>
        <SearchTemplatesInput
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
        />
      </div>
      <div className={styles.content}>
        {contentToShow}
      </div>
      {showPreview}
      {authenticated && <EditTemplateDetails />}
    </div>
  );
};

export { Templates };
