import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { bool, func } from 'prop-types';

import WandIcon from 'src/assets/icons/wand.svg';
import { Button } from 'src/common/button';
import { SpinnerControlled } from 'src/common/spinner-controlled';
import { useLoading } from 'src/hooks';
import { UPDATE_AND_PROCESS_WEBSITE } from 'src/actions/accountActions';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './AnalysingWebsite.module.scss';

const AnalysingWebsite = ({ setModalOpen, editMode }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const close = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const [step, setStep] = useState(1);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStep(2);
    }, 2100);
    return () => clearTimeout(timeoutId);
  }, []);

  const loadingWebsite = useLoading(UPDATE_AND_PROCESS_WEBSITE);

  const isBrandLibraryPage = useMemo(() => (pathname === routesPaths.brandLibrary), [pathname]);

  const onClickActionButton = useCallback(() => {
    if (isBrandLibraryPage) {
      history.push(routesPaths.projects);
    } else {
      setModalOpen(false);
    }
  }, [isBrandLibraryPage, history, setModalOpen]);

  return (
    <div className={styles.content}>
      {step === 1 && (
        <>
          <span className={styles.title}>
            Got it! We&apos;re building your Brand Library.
          </span>
          <div className={styles.spinner}>
            <SpinnerControlled>
              <image href={WandIcon} alt="" width="16px" height="16px" x="10px" y="10px" />
            </SpinnerControlled>
          </div>
          <div className={styles.space} />
        </>
      )}
      {step === 2 && (
        <>
          <span className={styles.title}>
            Building Your Brand Library
          </span>
          <span className={styles.text}>
            We&apos;re helping you save time by importing
            important assets like your logos, images, colors and fonts from your website.
          </span>
          <div className={styles.actions}>
            <Button
              className={styles.actionButton}
              onClick={onClickActionButton}
              disabled={editMode && loadingWebsite}
            >
              {isBrandLibraryPage ? 'See Your Projects' : 'Got it'}
            </Button>
            <Button
              className={styles.dismissButton}
              secondary
              onClick={close}
            >
              Close
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

AnalysingWebsite.propTypes = {
  setModalOpen: func.isRequired,
  editMode: bool.isRequired,
};

export { AnalysingWebsite };
