import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SaveTemplateModal } from 'src/pages/project/toolbar/save-template-modal';
import {
  updateMetadataTemplate,
  setSelectedTemplate,
} from 'src/actions/productTemplateActions';
import { useHistory } from 'react-router';

const EditTemplateDetails = () => {
  const { selectedTemplate } = useSelector(({ productTemplate }) => ({
    selectedTemplate: productTemplate.selectedTemplate,
  }));

  const history = useHistory();

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setSelectedTemplate(undefined));
  }, [dispatch]);

  useEffect(() => () => onClose(), [onClose]);

  const onSaveOrEditTemplate = useCallback(data => {
    const templateParams = {
      name: data.name,
      description: data.description,
      keywords: data.keywords,
      tagBackground: data.tagBackground,
      visibility: data.visibility,
    };
    if (selectedTemplate.category.name !== data.categoryName) {
      templateParams.categoryName = data.categoryName;
    }
    dispatch(updateMetadataTemplate(selectedTemplate.id, templateParams, history));
    onClose();
  }, [dispatch, onClose, selectedTemplate, history]);

  return (
    <SaveTemplateModal
      isShowing={!!selectedTemplate}
      onClose={onClose}
      onSaveOrEditTemplate={onSaveOrEditTemplate}
      isEdit
      selectedTemplate={selectedTemplate}
    />
  );
};

export { EditTemplateDetails };
