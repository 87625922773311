import React, { useCallback, useMemo } from 'react';
import { Line } from 'react-konva';

import { useProjectSelector } from 'src/hooks';
import { CANVAS_SEPARATOR } from 'src/constants/general';

const Separators = () => {
  const { layout, size } = useProjectSelector();

  const canvasLines = useMemo(() => {
    const lines = [];
    for (let index = 1; index < size; index++) {
      const x = layout.width * index;
      const y2 = layout.height;
      const points = [x, 0, x, y2];
      lines.push(points);
    }
    return lines;
  }, [layout, size]);

  const drawSeparators = useCallback(() => (
    canvasLines.map((points, index) => (
      <Line key={points} points={points} stroke="black" id={`${CANVAS_SEPARATOR}_${index}`} />
    ))
  ), [canvasLines]);

  return drawSeparators();
};

export { Separators };
