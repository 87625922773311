import React, { memo, useRef, Fragment } from 'react';
import { object, number, shape, func } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Group } from 'react-konva';

import {
  useProjectSelector,
  useUndoShortcut,
  useDirectionalKeysOnCanvas,
  useDeleteElementShortcut,
  useGroupElementsShortcut,
  useLockElementShortcut,
  useAddElementShortcut,
  useSelectAllShortcut,
  useSession,
  useZoomShortcut,
} from 'src/hooks';
import {
  CanvasImage,
  CanvasVideo,
  CanvasText,
  CanvasLine,
  CanvasCircle,
  CanvasSquare,
  CanvasRoundedRect,
  CanvasTriangle,
  CanvasGroup,
} from 'src/pages/project/canvas/canvas-elements';
import {
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
  TEXT_ELEMENT,
  LINE_ELEMENT,
  CIRCLE_ELEMENT,
  SQUARE_ELEMENT,
  ROUNDED_RECT_ELEMENT,
  TRIANGLE_ELEMENT,
  GROUP_ELEMENT,
} from 'src/constants/canvasElements';
import { CANVAS_DRAWING_CONTENT, ROLE_ADMIN } from 'src/constants/general';
import {
  CanvasTagMarker,
  CanvasTipLightbulb,
  CanvasProcessingElement,
} from 'src/pages/project/canvas/canvas-helper-elements';
import { Transformers } from '../../transformers';

const DrawingComponent = memo(({
  selectedElements,
  selectedRefs,
  scale,
  canvasWidth,
  canvasHeight,
  canvasAttrs,
  onTransformElement,
  hideTransformerAnchors,
  showTransformerAnchors,
  refDrawingLayer,
}) => {
  const { elements, editableImage, imageUUIDToRemoveBackground } = useProjectSelector();

  const { tipsObjects } = useSelector(({ rulesEngine }) => ({
    tipsObjects: rulesEngine.tipsObjects,
  }));

  const { user } = useSession();

  useUndoShortcut();
  useDirectionalKeysOnCanvas();
  useDeleteElementShortcut();
  useGroupElementsShortcut();
  useLockElementShortcut();
  useAddElementShortcut();
  useSelectAllShortcut(refDrawingLayer);
  useZoomShortcut();

  const refCropper = useRef();

  const commonProps = {
    onTransformElement,
    hideTransformerAnchors,
    showTransformerAnchors,
  };

  const parseElements = () => (
    elements.map(element => {
      let canvasElem;
      switch (element.type) {
        case IMAGE_ELEMENT:
          canvasElem = (
            <CanvasImage
              {...element}
              editableImage={editableImage}
              refCropper={refCropper}
              canvasAttrs={canvasAttrs}
              {...commonProps}
            />
          );
          break;
        case VIDEO_ELEMENT:
          canvasElem = (
            <CanvasVideo
              {...element}
              {...commonProps}
            />
          );
          break;
        case TEXT_ELEMENT:
          canvasElem = (
            <CanvasText
              {...element}
              {...commonProps}
            />
          );
          break;
        case LINE_ELEMENT:
          canvasElem = (
            <CanvasLine
              {...element}
              {...commonProps}
            />
          );
          break;
        case CIRCLE_ELEMENT:
          canvasElem = (
            <CanvasCircle
              {...element}
              {...commonProps}
            />
          );
          break;
        case SQUARE_ELEMENT:
          canvasElem = (
            <CanvasSquare
              {...element}
              {...commonProps}
            />
          );
          break;
        case ROUNDED_RECT_ELEMENT:
          canvasElem = (
            <CanvasRoundedRect
              {...element}
              {...commonProps}
            />
          );
          break;
        case TRIANGLE_ELEMENT:
          canvasElem = (
            <CanvasTriangle
              {...element}
              {...commonProps}
            />
          );
          break;
        case GROUP_ELEMENT:
          canvasElem = (
            <CanvasGroup
              {...element}
              {...commonProps}
            />
          );
          break;
        default:
          return null;
      }
      return (
        <Fragment key={element.uuid}>
          {canvasElem}
          {tipsObjects[element.uuid] && (
            <CanvasTipLightbulb {...element} {...commonProps} />
          )}
          {user.role.id === ROLE_ADMIN && element.tag && (
            <CanvasTagMarker {...element} {...commonProps} />
          )}
          {imageUUIDToRemoveBackground && element.uuid === imageUUIDToRemoveBackground && (
            <CanvasProcessingElement element={element} />
          )}
        </Fragment>
      );
    })
  );

  return (
    <>
      <Group
        clip={{ x: 0, y: 0, width: canvasWidth / scale, height: canvasHeight / scale }}
        id={CANVAS_DRAWING_CONTENT}
      >
        {parseElements()}
      </Group>
      <Transformers
        ref={refCropper}
        selectedElements={selectedElements}
        selectedRefs={selectedRefs}
        scale={scale}
        canvasAttrs={canvasAttrs}
      />
    </>
  );
});

DrawingComponent.propTypes = {
  selectedElements: object,
  selectedRefs: object,
  scale: number.isRequired,
  canvasWidth: number,
  canvasHeight: number,
  canvasAttrs: shape({
    x: number,
    y: number,
  }),
  onTransformElement: func.isRequired,
  hideTransformerAnchors: func.isRequired,
  showTransformerAnchors: func.isRequired,
  refDrawingLayer: object,
};

const mapStateToProps = store => ({
  selectedElements: store.project.present.selectedElements,
  selectedRefs: store.project.present.selectedRefs,
  scale: store.canvas.scale,
});

const DrawingContent = connect(mapStateToProps)(DrawingComponent);

export { DrawingContent };
