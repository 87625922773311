import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { generateProjectHash } from 'src/utils/embed';
import { Button } from 'src/common/button';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { useWindowSize } from 'src/hooks';
import { ProjectList } from 'src/pages/public-pages/account-profile/project-list';
import { toastSuccessConfig } from 'src/constants/toastConfig';
import { setSelectedProject } from 'src/actions/publicPagesActions';
import { ScrollMenu } from 'src/common/scroll-menu';
import { ProjectCard } from 'src/pages/public-pages/account-profile/project-card';
import styles from './ProjectDetailsCard.module.scss';

const ProjectDetailsCard = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { windowSize: { width } } = useWindowSize();
  const isSmallWidth = width <= SMALL_WIDTH;

  const {
    account,
    selectedProject,
    allPublicProjects,
  } = useSelector(({ publicPages }) => ({
    account: publicPages.account,
    selectedProject: publicPages.selectedProject,
    allPublicProjects: publicPages.allPublicProjects,
  }));

  useEffect(() => {
    if (ref?.current && selectedProject) {
      const pjHash = generateProjectHash(selectedProject.id);
      const staggerEmbedDiv = ref.current;

      ref.current.id = `st-${pjHash}-ph`;
      const script = document.createElement('script');

      script.src = `${process.env.REACT_APP_URL_SHARE_API}/embed/${pjHash}?noFooter=true`;
      script.async = true;

      const embedDiv = staggerEmbedDiv.querySelector('div');
      embedDiv.style.height = null;
      embedDiv.style.width = null;

      if (isSmallWidth) {
        embedDiv.style.width = '100vw';
      } else {
        embedDiv.style.height = '62vh';
      }

      staggerEmbedDiv.appendChild(script);

      return () => {
        staggerEmbedDiv.removeChild(script);
      };
    }

    return null;
  }, [isSmallWidth, selectedProject]);

  const onShareProjectClick = useCallback(async () => {
    const projectUrl = `${account.publicUrl}?pjid=${selectedProject.id}`;
    await window.navigator.clipboard.writeText(projectUrl);
    toast.success('Flyer link copied and ready to paste!', toastSuccessConfig);
  }, [account, selectedProject.id]);

  const publicBusinessName = useCallback(() => {
    const businessName = account?.businessInfo?.name;
    if (businessName && businessName.length > 0) {
      return businessName;
    }
    return account.brandName;
  }, [account]);

  const resetSelectedProject = useCallback(() => {
    dispatch(setSelectedProject(null));
  }, [dispatch]);

  const selectedProjectLocation = useMemo(() => {
    if (
      selectedProject &&
      selectedProject.flyerSettings.locationBasedSharing &&
      selectedProject.flyerSettings.location
    ) {
      const [longitude, latitude] = selectedProject.flyerSettings.location.coordinates;
      return { latitude, longitude };
    }
    return null;
  }, [selectedProject]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{`${selectedProject.name} | Stagger`}</title>
        {selectedProjectLocation && (
          <meta
            name="geo.position"
            content={`${selectedProjectLocation.latitude};${selectedProjectLocation.longitude}`}
          />
        )}
        {selectedProjectLocation && (
          <meta
            name="ICBM"
            content={`${selectedProjectLocation.latitude}, ${selectedProjectLocation.longitude}`}
          />
        )}
      </Helmet>
      <div
        className={styles.projectDetailsCard}
      >
        <div
          className={styles.projectDetailsHeader}
        >
          <h2 className={styles.name}>{selectedProject.name}</h2>
          <div className={styles.subnav}>
            <Link to={account.publicRelUrl} onClick={resetSelectedProject}>
              <span className={styles.brandName}>{publicBusinessName()}</span>
            </Link>
            <Button
              onClick={onShareProjectClick}
              className={styles.shareButton}
              secondary
              short
            >
              Share
            </Button>
          </div>
        </div>
        <div className={cn(styles.staggerEmbed, 'stagger-embed')} ref={ref}>
          <div className={styles.embed} />
        </div>
      </div>
      <div className={styles.moreProjects}>
        {isSmallWidth
          ? (
            <>
              <h2 className={styles.moreProjectsTitle}>More flyers</h2>
              {allPublicProjects.length > 1 && <ProjectList projects={allPublicProjects} />}
              {allPublicProjects.length <= 1 &&
              <span className={styles.noMoreProjects}>No more flyers to see</span>}
            </>
          ) : (
            <>
              {allPublicProjects.length > 1 && (
                <ScrollMenu
                  wrapperClassName={styles.slider}
                  rightArrowClassName={styles.rightArrow}
                  leftArrowClassName={styles.leftArrow}
                >
                  {allPublicProjects.map((project) => (
                    <ProjectCardContainer project={project} itemId={project.id} key={project.id} />
                  ))}
                </ScrollMenu>
              )}
            </>
          )}
      </div>
    </div>
  );
};

const ProjectCardContainer = ({ project }) => (
  <div className={styles.projectCardContainer} key={project.id}>
    <ProjectCard {...project} showOnlyThumbnail />
  </div>
);

ProjectCardContainer.propTypes = {
  project: object.isRequired,
};

export { ProjectDetailsCard };
