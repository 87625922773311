import React, { useState, useEffect } from 'react';

import { useSession } from 'src/hooks';
import styles from './GeneralInformation.module.scss';

const GeneralInformation = () => {
  const { user, account } = useSession();
  const [accountProfilePath, setAccountProfilePath] = useState(null);

  useEffect(() => {
    if (user && account && account.publicUrl) {
      if (user.defaultAccountId === account.id) {
        const urlWithoutBrandName = account.publicUrl.substring(
          0,
          account.publicUrl.lastIndexOf('/'),
        );
        setAccountProfilePath(urlWithoutBrandName);
      } else {
        setAccountProfilePath(account.publicUrl);
      }
    }
  }, [account, user]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          General information
        </div>
        <div className={styles.content}>
          <span className={styles.title}>Public URL:&nbsp;</span>
          <span className={styles.value}>{
            accountProfilePath ?
              (
                <a
                  className={styles.url}
                  href={accountProfilePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {accountProfilePath}
                </a>
              ) :
              'No username selected'
          }
          </span>
        </div>
      </div>
    </div>
  );
};

export { GeneralInformation };
