import io from 'socket.io-client';

import {
  LOGIN_RESET,
  GET_USER_WITH_PROJECTS_SUCCESS,
  LOGOUT,
} from 'src/actions/userActions';

import {
  GET_PROJECT_SUCCESS,
} from 'src/actions/projectActions';

import { GET_USER_WITH_BRAND_LIBRARY_REQUEST } from 'src/actions/brandLibraryActions';

const CLIENT_ACTION_EVENT = 'CLIENT_ACTION_EVENT';

const createSocket = (token) => {
  const newSocket = io(process.env.REACT_APP_URL_WEBSOCKET, {
    auth: {
      token,
    },
    autoConnect: false,
  });
  return newSocket;
};

export const createWebsocketMiddleware = () => storeAPI => {
  let socket;

  return next => action => {
    switch (action.type) {
      case LOGIN_RESET:
      case GET_USER_WITH_PROJECTS_SUCCESS:
      case GET_PROJECT_SUCCESS:
      case GET_USER_WITH_BRAND_LIBRARY_REQUEST: {
        if (!socket) {
          const { session: { token } } = storeAPI.getState();
          if (token === '') return;

          socket = createSocket(token);
          socket.connect();

          socket.on('connect', () => {
            socket.emit('testFromClient');
          });

          socket.on('disconnect', () => { });

          socket.on(CLIENT_ACTION_EVENT, ({ actionName, payload }, callback) => {
            storeAPI.dispatch({
              type: actionName,
              payload,
            });
            callback('ack');
          });

          socket.on('error', () => {
            socket.reconnect();
          });
        }

        break;
      }
      case LOGOUT: {
        // eslint-disable-next-line no-unused-expressions
        socket?.close();
        socket = null;

        break;
      }
      case 'TEST_SEND_WEBSOCKET_MESSAGE': {
        socket.send(action.payload);
        return;
      }
      default: {
        break;
      }
    }

    // eslint-disable-next-line consistent-return
    return next(action);
  };
};
