import { useEffect } from 'react';
import { DEVELOPMENT } from 'src/constants/environments';

const useAskBeforeUnload = (condition = true, actionBeforeUnload) => {
  useEffect(() => {
    const beforeUnload = e => {
      actionBeforeUnload();
      if (condition) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    const environment = process.env.REACT_APP_ENVIRONMENT;
    environment !== DEVELOPMENT && window.addEventListener('beforeunload', beforeUnload);

    return () => {
      environment !== DEVELOPMENT && window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [condition, actionBeforeUnload]);
};

export { useAskBeforeUnload };
