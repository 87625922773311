import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CloseIcon from 'src/assets/icons/close-white.svg';
import { addTextElement } from 'src/actions/projectActions';
import { useStageCenteredPosition, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { addTextToCanvas } from 'src/utils/fontHelpers';
import { Button } from 'src/common/button';
import { changeControlOpen } from 'src/actions/canvasActions';
import {
  CANVAS_TEXT_PADDING,
  TEXT_DEFAULT_LINE_HEIGHT,
} from 'src/constants/general';
import { FONT_SIZE_OPTIONS } from 'src/constants/fonts';
import { TextCombinations } from './text-combinations';
import styles from './AddTextControl.module.scss';

const AddTextControl = () => {
  const dispatch = useDispatch();

  const { fontMemory } = useSelector(({ projectMemory }) => ({
    fontMemory: projectMemory.font,
  }));

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const position = useStageCenteredPosition();

  const onClose = useCallback(() => {
    dispatch(changeControlOpen(''));
  }, [dispatch]);

  const onClick = (sizeProps) => {
    addTextToCanvas(fontMemory, () => {
      dispatch(addTextElement({
        x: position.x - sizeProps.width / 2,
        y: position.y - (sizeProps.fontSize * TEXT_DEFAULT_LINE_HEIGHT) / 2,
        height: sizeProps.fontSize * TEXT_DEFAULT_LINE_HEIGHT + CANVAS_TEXT_PADDING * 2,
        fontMemory,
        ...sizeProps,
      }));
      if (isMobile) {
        onClose();
      }
    });
  };

  return (
    <div className={styles.container}>
      {!isMobile && (
        <>
          <button className={styles.closeButton} onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </button>
          <span className={styles.title}>Text</span>
        </>
      )}
      <Button
        className={styles.headingButton}
        onClick={() => onClick({ width: 500, fontSize: FONT_SIZE_OPTIONS[10].value })}
        secondary
      >
        Add a Title
      </Button>
      <Button
        className={styles.textButton}
        onClick={() => onClick({ width: 300, fontSize: FONT_SIZE_OPTIONS[7].value })}
        secondary
      >
        Add Subhead text
      </Button>
      <Button
        className={styles.smallTextButton}
        onClick={() => onClick({ width: 250, fontSize: FONT_SIZE_OPTIONS[5].value })}
        secondary
      >
        Add Body text
      </Button>
      {!isMobile && (
        <div className={styles.combinations}>
          <span className={styles.title}>Text combinations</span>
          <TextCombinations />
        </div>
      )}
    </div>
  );
};

export { AddTextControl };
