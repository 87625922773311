import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';

import { toastSuccessConfig, toastErrorConfig } from 'src/constants/toastConfig';
import { UserService } from 'src/services/userService';
import {
  trackLogin,
  trackSignup,
  resetIdentity,
  trackUserPersona,
} from 'src/utils/analytics';
import { routeWithProps, serializeUserAccounts } from 'src/utils/helpers';
import { getRules } from 'src/actions/rulesEngineActions';
import { routesPaths } from 'src/routes/routesPaths';
import createAction from './createAction';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const SIGNUP_ONLY_EMAIL = 'SIGNUP_ONLY_EMAIL';
export const SIGNUP_ONLY_EMAIL_SUCCESS = 'SIGNUP_ONLY_EMAIL_SUCCESS';
export const SIGNUP_ONLY_EMAIL_REQUEST = 'SIGNUP_ONLY_EMAIL_REQUEST';
export const SIGNUP_ONLY_EMAIL_ERROR = 'SIGNUP_ONLY_EMAIL_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

export const LOGOUT = 'LOGOUT';

export const GET_USER_WITH_PROJECTS = 'GET_USER_WITH_PROJECTS';
export const GET_USER_WITH_PROJECTS_SUCCESS = 'GET_USER_WITH_PROJECTS_SUCCESS';
export const GET_USER_WITH_PROJECTS_REQUEST = 'GET_USER_WITH_PROJECTS_REQUEST';
export const GET_USER_WITH_PROJECTS_ERROR = 'GET_USER_WITH_PROJECTS_ERROR';

export const GET_PERSONAL_INFORMATION = 'GET_PERSONAL_INFORMATION';
export const GET_PERSONAL_INFORMATION_SUCCESS = 'GET_PERSONAL_INFORMATION_SUCCESS';
export const GET_PERSONAL_INFORMATION_REQUEST = 'GET_PERSONAL_INFORMATION_REQUEST';
export const GET_PERSONAL_INFORMATION_ERROR = 'GET_PERSONAL_INFORMATION_ERROR';

export const EDIT_PERSONAL_INFORMATION = 'EDIT_PERSONAL_INFORMATION';
export const EDIT_PERSONAL_INFORMATION_REQUEST = 'EDIT_PERSONAL_INFORMATION_REQUEST';
export const EDIT_PERSONAL_INFORMATION_SUCCESS = 'EDIT_PERSONAL_INFORMATION_SUCCESS';
export const EDIT_PERSONAL_INFORMATION_ERROR = 'EDIT_PERSONAL_INFORMATION_ERROR';

export const USER_EXPORTED_PROJECT = 'USER_EXPORTED_PROJECT';
export const USER_EXPORTED_PROJECT_REQUEST = 'USER_EXPORTED_PROJECT_REQUEST';
export const USER_EXPORTED_PROJECT_SUCCESS = 'USER_EXPORTED_PROJECT_SUCCESS';
export const USER_EXPORTED_PROJECT_ERROR = 'USER_EXPORTED_PROJECT_ERROR';

export const SEND_EMAIL_PASSWORD = 'SEND_EMAIL_PASSWORD';
export const SEND_EMAIL_PASSWORD_REQUEST = 'SEND_EMAIL_PASSWORD_REQUEST';
export const SEND_EMAIL_PASSWORD_SUCCESS = 'SEND_EMAIL_PASSWORD_SUCCESS';
export const SEND_EMAIL_PASSWORD_ERROR = 'SEND_EMAIL_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const GET_BILLING_INFORMATION = 'GET_BILLING_INFORMATION';
export const GET_BILLING_INFORMATION_SUCCESS = 'GET_BILLING_INFORMATION_SUCCESS';
export const GET_BILLING_INFORMATION_REQUEST = 'GET_BILLING_INFORMATION_REQUEST';
export const GET_BILLING_INFORMATION_ERROR = 'GET_BILLING_INFORMATION_ERROR';

export const CREATE_CUSTOMER_PORTAL_SESSION = 'CREATE_CUSTOMER_PORTAL_SESSION';
export const CREATE_CUSTOMER_PORTAL_SESSION_SUCCESS = 'CREATE_CUSTOMER_PORTAL_SESSION_SUCCESS';
export const CREATE_CUSTOMER_PORTAL_SESSION_REQUEST = 'CREATE_CUSTOMER_PORTAL_SESSION_REQUEST';
export const CREATE_CUSTOMER_PORTAL_SESSION_ERROR = 'CREATE_CUSTOMER_PORTAL_SESSION_ERROR';

export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const CREATE_CHECKOUT_SESSION_REQUEST = 'CREATE_CHECKOUT_SESSION_REQUEST';
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_ERROR = 'CREATE_CHECKOUT_SESSION_ERROR';

export const CREATE_CHECKOUT_SESSION_ORDER_SIGN = 'CREATE_CHECKOUT_SESSION_ORDER_SIGN';
export const CREATE_CHECKOUT_SESSION_ORDER_SIGN_REQUEST =
  'CREATE_CHECKOUT_SESSION_ORDER_SIGN_REQUEST';
export const CREATE_CHECKOUT_SESSION_ORDER_SIGN_SUCCESS =
  'CREATE_CHECKOUT_SESSION_ORDER_SIGN_SUCCESS';
export const CREATE_CHECKOUT_SESSION_ORDER_SIGN_ERROR = 'CREATE_CHECKOUT_SESSION_ORDER_SIGN_ERROR';

export const CREATE_FREE_TRIAL = 'CREATE_FREE_TRIAL';
export const CREATE_FREE_TRIAL_REQUEST = 'CREATE_FREE_TRIAL_REQUEST';
export const CREATE_FREE_TRIAL_SUCCESS = 'CREATE_FREE_TRIAL_SUCCESS';
export const CREATE_FREE_TRIAL_ERROR = 'CREATE_FREE_TRIAL_ERROR';

export const SET_SMALL_SCREEN = 'SET_SMALL_SCREEN';

export const FINISH_ONBOARDING = 'FINISH_ONBOARDING';
export const FINISH_ONBOARDING_REQUEST = 'FINISH_ONBOARDING_REQUEST';
export const FINISH_ONBOARDING_SUCCESS = 'FINISH_ONBOARDING_SUCCESS';
export const FINISH_ONBOARDING_ERROR = 'FINISH_ONBOARDING_ERROR';

export const CHANGE_DEFAULT_ACCOUNT_ID = 'CHANGE_DEFAULT_ACCOUNT_ID';
export const CHANGE_DEFAULT_ACCOUNT_ID_SUCCESS = 'CHANGE_DEFAULT_ACCOUNT_ID_SUCCESS';
export const CHANGE_DEFAULT_ACCOUNT_ID_REQUEST = 'CHANGE_DEFAULT_ACCOUNT_ID_REQUEST';
export const CHANGE_DEFAULT_ACCOUNT_ID_ERROR = 'CHANGE_DEFAULT_ACCOUNT_ID_ERROR';

export const CHECK_USERNAME = 'CHECK_USERNAME';
export const CHECK_USERNAME_REQUEST = 'CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_ERROR = 'CHECK_USERNAME_ERROR';

export const SET_USER_PERSONA = 'SET_USER_PERSONA';
export const SET_USER_PERSONA_REQUEST = 'SET_USER_PERSONA_REQUEST';
export const SET_USER_PERSONA_SUCCESS = 'SET_USER_PERSONA_SUCCESS';
export const SET_USER_PERSONA_ERROR = 'SET_USER_PERSONA_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const signupRequest = createAction(SIGNUP_REQUEST);
export const signupSuccess = createAction(SIGNUP_SUCCESS);
export const signupError = createAction(SIGNUP_ERROR);

export const signupOnlyEmailRequest = createAction(SIGNUP_ONLY_EMAIL_REQUEST);
export const signupOnlyEmailSuccess = createAction(SIGNUP_ONLY_EMAIL_SUCCESS);
export const signupOnlyEmailError = createAction(SIGNUP_ONLY_EMAIL_ERROR);

export const loginRequest = createAction(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginError = createAction(LOGIN_ERROR);

export const socialLogin = createAction(SOCIAL_LOGIN);

export const logout = createAction(LOGOUT);

export const getUserWithProjectsRequest = createAction(GET_USER_WITH_PROJECTS_REQUEST);
export const getUserWithProjectsSuccess = createAction(GET_USER_WITH_PROJECTS_SUCCESS);
export const getUserWithProjectsError = createAction(GET_USER_WITH_PROJECTS_ERROR);

export const getPersonalInformationRequest = createAction(GET_PERSONAL_INFORMATION_REQUEST);
export const getPersonalInformationSuccess = createAction(GET_PERSONAL_INFORMATION_SUCCESS);
export const getPersonalInformationError = createAction(GET_PERSONAL_INFORMATION_ERROR);

export const editPersonalInformationRequest = createAction(EDIT_PERSONAL_INFORMATION_REQUEST);
export const editPersonalInformationSuccess = createAction(EDIT_PERSONAL_INFORMATION_SUCCESS);
export const editPersonalInformationError = createAction(EDIT_PERSONAL_INFORMATION_ERROR);

export const userExportedProjectRequest = createAction(USER_EXPORTED_PROJECT_REQUEST);
export const userExportedProjectSuccess = createAction(USER_EXPORTED_PROJECT_SUCCESS);
export const userExportedProjectError = createAction(USER_EXPORTED_PROJECT_ERROR);

export const sendEmailResetPasswordRequest = createAction(SEND_EMAIL_PASSWORD_REQUEST);
export const sendEmailResetPasswordSuccess = createAction(SEND_EMAIL_PASSWORD_SUCCESS);
export const sendEmailResetPasswordError = createAction(SEND_EMAIL_PASSWORD_ERROR);

export const changePasswordRequest = createAction(CHANGE_PASSWORD_REQUEST);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordError = createAction(CHANGE_PASSWORD_ERROR);

export const getBillingInformationRequest = createAction(GET_BILLING_INFORMATION_REQUEST);
export const getBillingInformationSuccess = createAction(GET_BILLING_INFORMATION_SUCCESS);
export const getBillingInformationError = createAction(GET_BILLING_INFORMATION_ERROR);

export const createCustomerPortalSessionRequest =
  createAction(CREATE_CUSTOMER_PORTAL_SESSION_REQUEST);
export const createCustomerPortalSessionSuccess =
  createAction(CREATE_CUSTOMER_PORTAL_SESSION_SUCCESS);
export const createCustomerPortalSessionError =
  createAction(CREATE_CUSTOMER_PORTAL_SESSION_ERROR);

export const createCheckoutSessionRequest = createAction(CREATE_CHECKOUT_SESSION_REQUEST);
export const createCheckoutSessionSuccess = createAction(CREATE_CHECKOUT_SESSION_SUCCESS);
export const createCheckoutSessionError = createAction(CREATE_CHECKOUT_SESSION_ERROR);

export const createCheckoutSessionOrderSignRequest =
  createAction(CREATE_CHECKOUT_SESSION_ORDER_SIGN_REQUEST);
export const createCheckoutSessionOrderSignSuccess =
  createAction(CREATE_CHECKOUT_SESSION_ORDER_SIGN_SUCCESS);
export const createCheckoutSessionOrderSignError =
  createAction(CREATE_CHECKOUT_SESSION_ORDER_SIGN_ERROR);

export const createFreeTrialRequest = createAction(CREATE_FREE_TRIAL_REQUEST);
export const createFreeTrialSuccess = createAction(CREATE_FREE_TRIAL_SUCCESS);
export const createFreeTrialError = createAction(CREATE_FREE_TRIAL_ERROR);

export const setSmallScreen = createAction(SET_SMALL_SCREEN);

export const finishOnboardingRequest = createAction(FINISH_ONBOARDING_REQUEST);
export const finishOnboardingSuccess = createAction(FINISH_ONBOARDING_SUCCESS);
export const finishOnboardingError = createAction(FINISH_ONBOARDING_ERROR);

export const changeDefaultAccountIdRequest = createAction(CHANGE_DEFAULT_ACCOUNT_ID_REQUEST);
export const changeDefaultAccountIdSuccess = createAction(CHANGE_DEFAULT_ACCOUNT_ID_SUCCESS);
export const changeDefaultAccountIdError = createAction(CHANGE_DEFAULT_ACCOUNT_ID_ERROR);

export const checkUsernameRequest = createAction(CHECK_USERNAME_REQUEST);
export const checkUsernameSuccess = createAction(CHECK_USERNAME_SUCCESS);
export const checkUsernameError = createAction(CHECK_USERNAME_ERROR);

export const setUserPersonaRequest = createAction(SET_USER_PERSONA_REQUEST);
export const setUserPersonaSuccess = createAction(SET_USER_PERSONA_SUCCESS);
export const setUserPersonaError = createAction(SET_USER_PERSONA_ERROR);

export const deleteUserRequest = createAction(DELETE_USER_REQUEST);
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS);
export const deleteUserError = createAction(DELETE_USER_ERROR);

export const signup = (user, history) => async dispatch => {
  try {
    dispatch(signupRequest());
    const { data } = await UserService.signup(user);
    dispatch(signupSuccess(data));
    trackSignup(data.user);
    if (history && data.projectId) {
      history.push(routeWithProps(routesPaths.project, { id: data.projectId }));
    }
  } catch ({ data }) {
    if (!data.details) {
      dispatch(signupError(data?.message));
    } else {
      dispatch(signupError());
    }
    throw data.details || {};
  }
};

export const signupOnlyEmail = (email, createdOnCampaign, history) => async dispatch => {
  try {
    dispatch(signupOnlyEmailRequest());
    const { data } = await UserService.signupOnlyEmail(email, createdOnCampaign);
    dispatch(signupOnlyEmailSuccess(data));
    trackSignup(data.user);
    if (history && data.projectId) {
      history.push(routeWithProps(routesPaths.project, { id: data.projectId }));
    }
  } catch ({ data }) {
    if (!data.details) {
      dispatch(signupOnlyEmailError(data?.message));
    } else {
      dispatch(signupOnlyEmailError());
    }
    throw data.details || {};
  }
};

export const login = credentials => async dispatch => {
  try {
    dispatch(loginRequest());
    const { data } = await UserService.login(credentials);
    dispatch(loginSuccess(data));
    trackLogin(data.user);
  } catch ({ data }) {
    dispatch(loginError(data?.message));
    throw data.details || {};
  }
};

export const getUserWithProjects = () => async dispatch => {
  try {
    dispatch(getUserWithProjectsRequest());
    const amountOfProjects = 12;
    const { data } = await UserService.getUser('projects', amountOfProjects);
    const user = serializeUserAccounts(data.user);
    dispatch(getUserWithProjectsSuccess({ user, ...data }));
    dispatch(getRules());
  } catch (error) {
    dispatch(getUserWithProjectsError(error?.data?.message));
  }
};

export const getPersonalInformation = () => async dispatch => {
  try {
    dispatch(getPersonalInformationRequest());
    const { data } = await UserService.getPersonalInformation();
    const user = serializeUserAccounts(data.user);
    dispatch(getPersonalInformationSuccess({ user, ...data }));
  } catch (error) {
    dispatch(getPersonalInformationError(error?.data?.message));
  }
};

export const editPersonalInformation = (userParams) => async dispatch => {
  try {
    dispatch(editPersonalInformationRequest());
    const { data } = await UserService.update(userParams);
    dispatch(editPersonalInformationSuccess(data));
    resetIdentity(data.user);
    toast.success('Personal information updated', toastSuccessConfig);
  } catch (error) {
    if (!error?.data.details) {
      toast.error('Error while editing personal information', toastErrorConfig);
      dispatch(editPersonalInformationError(error?.data?.message));
    } else {
      dispatch(editPersonalInformationError());
    }
    throw error.data.details || {};
  }
};

export const userExportedProject = (params) => async dispatch => {
  try {
    dispatch(userExportedProjectRequest());
    const { data } = await UserService.update(params);
    dispatch(userExportedProjectSuccess(data));
  } catch (error) {
    dispatch(userExportedProjectError(error?.data?.message));
  }
};

export const sendEmailResetPassword = (email) => async dispatch => {
  try {
    dispatch(sendEmailResetPasswordRequest());
    const { data } = await UserService.sendResetPasswordEmail(email);
    dispatch(sendEmailResetPasswordSuccess(data));
  } catch (error) {
    dispatch(sendEmailResetPasswordError('Error while sending the instructions.'));
  }
};

export const changePassword = params => async dispatch => {
  try {
    dispatch(changePasswordRequest());
    const { data } = await UserService.resetPassword(params);
    dispatch(changePasswordSuccess(data));
  } catch (error) {
    if (error?.data?.message === 'jwt expired') {
      dispatch(changePasswordError('Your reset token has expired.'));
    } else {
      dispatch(changePasswordError('Error while changing password'));
    }
  }
};

export const getBillingInformation = () => async dispatch => {
  try {
    dispatch(getBillingInformationRequest());
    const { data } = await UserService.getBillingInformation();
    dispatch(getBillingInformationSuccess(data));
  } catch (error) {
    dispatch(getBillingInformationError(error?.data?.message));
  }
};

export const createCustomerPortalSession = (customerId, returnURL) => async dispatch => {
  try {
    dispatch(createCustomerPortalSessionRequest());
    const session = { customerId, returnURL };
    const { data } = await UserService.createCustomerPortalSession(session);
    window.location.href = data;
    dispatch(createCustomerPortalSessionSuccess());
  } catch (error) {
    dispatch(createCustomerPortalSessionError(error?.data?.message));
  }
};

export const createCheckoutSessionSubscription = (pathname, plan, type) => async dispatch => {
  try {
    dispatch(createCheckoutSessionRequest({ plan }));
    const { data } = await UserService.createCheckoutSessionSubscription(pathname, plan, type);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const { error } = await stripe.redirectToCheckout({ sessionId: data?.id });
    dispatch(createCheckoutSessionSuccess(data));
    const errorStripe = {
      message: 'Error while trying to redirect to checkout.',
      error,
    };
    error && dispatch(createCheckoutSessionError(errorStripe));
  } catch (error) {
    toast.error('Something went wrong with your checkout.', toastErrorConfig);
    dispatch(createCheckoutSessionError(error));
  }
};

export const createCheckoutSessionOrderSign = (
  pathnameSuccess,
  pathnameError,
  projectId,
) => async dispatch => {
  try {
    dispatch(createCheckoutSessionOrderSignRequest());
    const { data } = await UserService.createCheckoutSessionOrderSign(
      pathnameSuccess,
      pathnameError,
      projectId,
    );
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const { error } = await stripe.redirectToCheckout({ sessionId: data?.id });
    dispatch(createCheckoutSessionOrderSignSuccess(data));
    const errorStripe = {
      message: 'Error while trying to redirect to checkout.',
      error,
    };
    error && dispatch(createCheckoutSessionOrderSignError(errorStripe));
  } catch (error) {
    toast.error('Something went wrong with your checkout.', toastErrorConfig);
    dispatch(createCheckoutSessionOrderSignError(error));
  }
};

export const createFreeTrial = (plan, type) => async dispatch => {
  try {
    dispatch(createFreeTrialRequest({ plan }));
    const { data } = await UserService.createFreeTrial(plan, type);
    dispatch(createFreeTrialSuccess(data));
    toast.success('The 30-day timer starts ... NOW! 🙌', toastSuccessConfig);
  } catch (error) {
    dispatch(createFreeTrialError(error));
  }
};

export const finishOnboarding = (user) => async dispatch => {
  try {
    dispatch(finishOnboardingRequest());
    const { data } = await UserService.update({ id: user.id, didOnboarding: true });
    dispatch(finishOnboardingSuccess(data));
  } catch (err) {
    dispatch(finishOnboardingError(err));
  }
};

export const changeDefaultAccountId = (user, defaultAccountId) => async dispatch => {
  try {
    dispatch(changeDefaultAccountIdRequest());
    const { data } = await UserService.update({ id: user.id, defaultAccountId });
    dispatch(changeDefaultAccountIdSuccess(data));
  } catch (error) {
    dispatch(changeDefaultAccountIdError(error?.data?.message));
  }
};

export const checkUsername = (username) => async dispatch => {
  try {
    dispatch(checkUsernameRequest());
    const { data } = await UserService.checkUsername(username);
    dispatch(checkUsernameSuccess(data));
  } catch (error) {
    dispatch(checkUsernameError(error?.data?.message));
  }
};

export const setUserPersona = (user, persona) => async dispatch => {
  try {
    dispatch(setUserPersonaRequest());
    const { data } = await UserService.update({ id: user.id, persona });
    trackUserPersona(data.user);
    dispatch(setUserPersonaSuccess(data));
  } catch (error) {
    dispatch(setUserPersonaError(error?.data?.message));
  }
};

export const deleteUser = () => async dispatch => {
  try {
    dispatch(deleteUserRequest());
    const { data } = await UserService.deleteUser();
    dispatch(deleteUserSuccess(data));
    dispatch(logout());
  } catch (error) {
    dispatch(deleteUserError(error?.data?.message));
  }
};
