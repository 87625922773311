import React from 'react';
import { shape, string } from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { setSelectedPost } from 'src/actions/instagramAnalyticsActions';
import styles from './PostCard.module.scss';

const PostCard = ({ post, selectedPost }) => {
  const dispatch = useDispatch();

  return (
    <button
      className={cn(styles.card, { [styles.active]: post.id === selectedPost?.id })}
      onClick={() => dispatch(setSelectedPost({ post }))}
    >
      <img
        src={post.thumbnailUrl || post.mediaUrl}
        alt=""
        title={post.caption}
        className={styles.image}
      />
    </button>
  );
};

PostCard.propTypes = {
  post: shape({
    id: string,
  }).isRequired,
  selectedPost: shape({
    id: string,
  }),
};

export { PostCard };
