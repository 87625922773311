import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';

import { toastSuccessConfig } from 'src/constants/toastConfig';
import { TRIALING } from 'src/constants/subscriptionStatuses';
import { LOADING, NOT_STARTED, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { BRAND_LIBRARY_PLANS } from 'src/constants/memberships';
import { Navbar } from 'src/common/navbar';
import { useStatus, useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import {
  getUserWithBrandLibrary,
  GET_USER_WITH_BRAND_LIBRARY,
  refetchBrandLibrary,
} from 'src/actions/brandLibraryActions';
import { UserWebsiteModal } from 'src/common/user-website-modal';
import { ScrapperAlert } from 'src/common/scrapper-alert';
import { BRAND_ASSETS_STATUS } from 'src/constants/general';
import { UpgradeAlert } from 'src/common/upgrade-alert';
import { reset } from 'src/actions/statusActions';
import { UpgradeModal } from './upgrade-modal';
import { UpdateUrl } from './update-url';
import { SideMenu } from './side-menu';
import { BrandLibraryContent } from './brand-library-content';
import styles from './BrandLibrary.module.scss';
import { SearchBox } from './brand-library-content/search-box';

const BrandLibrary = () => {
  const dispatch = useDispatch();
  const { user, accountId, account } = useSession();
  const { brandAssetStatus } = account || {};

  const { membership } = user;

  const { status } = useStatus(GET_USER_WITH_BRAND_LIBRARY);

  useEffect(() => {
    if (!status || status === NOT_STARTED) {
      const includeRelations = ['colors'];
      dispatch(getUserWithBrandLibrary(accountId, includeRelations));
    }
  }, [dispatch, accountId, status]);

  useEffect(() => () => {
    dispatch(reset(GET_USER_WITH_BRAND_LIBRARY));
  }, [dispatch]);

  const { disabled, searchTerm } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
    searchTerm: brandLibrary.searchTerm,
  }));

  const [showAnyway, setShowAnyway] = useState(false);

  const [showEditWebsiteModal, setShowEditWebsiteModal] = useState(false);

  const [navigationOpen, setNavigationOpen] = useState({});
  const [selectedNavigation, setSelectedNavigation] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const updateUrl = () => {
    setShowEditWebsiteModal(true);
  };

  const isTrial = membership?.subscription && membership.subscription.active &&
    membership.subscription.status === TRIALING;

  const isTrialEndingSoon = useMemo(() => {
    const dateEnds = new Date(membership?.subscription?.ends);
    const now = new Date();
    return isTrial && differenceInDays(dateEnds, now) < 3;
  }, [isTrial, membership]);

  const previewContent = useMemo(() => (
    !user.customerId && !membership?.subscription &&
      (brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY ||
      brandAssetStatus === BRAND_ASSETS_STATUS.DONE)
  ), [membership, brandAssetStatus, user.customerId]);

  const upgradeText = useMemo(() => {
    if (previewContent) {
      return 'Upgrade your account and start using your Brand Library assets in your designs.';
    }
    if (isTrialEndingSoon) {
      return 'Your free trial of the Brand Library expires soon.';
    }
    return 'Keep all of your brand assets in one location for easy workflow.';
  }, [isTrialEndingSoon, previewContent]);

  const showWebsiteModal = useMemo(() => (
    status === SUCCESS &&
    (brandAssetStatus === BRAND_ASSETS_STATUS.FILLING_BRAND_LIBRARY ||
    showEditWebsiteModal)
  ), [showEditWebsiteModal, status, brandAssetStatus]);

  useEffect(() => {
    if (brandAssetStatus === BRAND_ASSETS_STATUS.READY_TO_NOTIFY) {
      setShowEditWebsiteModal(false);
      toast.success('Brand Library updated, look at those assets! 😍', toastSuccessConfig);
      const params = {
        brandAssetStatus: BRAND_ASSETS_STATUS.DONE,
        id: accountId,
      };
      dispatch(refetchBrandLibrary(params, accountId));
    }
  }, [dispatch, brandAssetStatus, accountId]);

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.subnav}>
        <h1 className={styles.title}>Brand Library</h1>
        <div className={styles.searchContainer}>
          <UpdateUrl onClick={updateUrl} />
          <SearchBox />
        </div>
      </div>
      {status === SUCCESS && (
        <ScrapperAlert showSuccessAlert={false} showErrorButton={false} />
      )}
      {status === SUCCESS && (disabled || isTrialEndingSoon) && (
        <>
          <UpgradeAlert
            plans={BRAND_LIBRARY_PLANS}
            upgradeChildren={(
              <>
                <span className={styles.upgradeAlertText}>{upgradeText}</span>
                <Button
                  className={cn(styles.alertButton, { [styles.preview]: previewContent })}
                  onClick={() => setShowAnyway(true)}
                  short
                >
                  More info
                </Button>
              </>
            )}
            upgradeButtonText={isTrial ?
              'Upgrade now' :
              'Start Your Free Trial'}
            containerClassName={previewContent ? styles.preview : ''}
          />
          <UpgradeModal showAnyway={showAnyway} setShowAnyway={setShowAnyway} />
        </>
      )}
      <div className={styles.content}>
        {status === LOADING && (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        )}
        {status === SUCCESS && (
          <div className={styles.containerInfo}>
            <SideMenu
              setNavigationOpen={setNavigationOpen}
              navigationOpen={navigationOpen}
              setSelectedNavigation={setSelectedNavigation}
              selectedNavigation={selectedNavigation}
              setSelectedCategoryId={setSelectedCategoryId}
              selectedCategoryId={selectedCategoryId}
              isSearching={!!searchTerm}
            />
            <BrandLibraryContent
              selectedNavigation={selectedNavigation}
              selectedCategoryId={selectedCategoryId}
              isSearching={!!searchTerm}
            />
          </div>
        )}
        {showWebsiteModal && (
          <UserWebsiteModal
            showEditWebsiteModal={showEditWebsiteModal}
            closeEditWebsiteModal={() => setShowEditWebsiteModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export { BrandLibrary };
