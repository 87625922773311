import {
  changeControlOpen,
  saveDraggableOffset,
  saveCanvasSize,
  saveZoom,
  resetDraggableOffset,
  setReplaceableImageRightClick,
  openRightClickOptions,
  closeRightClickOptions,
  setEyeDropperActive,
  changeEyeDropperColor,
  chooseEyeDropperColor,
  resetEyeDropperColor,
  setEyeDropperInactive,
  setGradientActiveColorId,
  setPinchZoomGesture,
  selectFrame,
  deselectFrame,
  setEditableHyperlink,
  getTextCombinationsSuccess,
  uploadMediaSuccess,
  uploadSingleAssetSuccess,
  uploadMediaRequest,
  uploadMediaError,
  deleteMediaSuccess,
  getObjectsCombinationsSuccess,
  setSelectingMultipleMobile,
  getSquadEventAssetsSuccess,
  getSquadEventAssetsReset,
  addAssetsToMediaDrawerSuccess,
} from 'src/actions/canvasActions';
import {
  cleanSelectedElement,
  addSelectedElement,
  getProjectReset,
  getProjectError,
  getProjectSuccess,
  replaceImageFromRightClick,
} from 'src/actions/projectActions';
import { logout } from 'src/actions/userActions';
import { CHOOSE_IMAGE_CONTROL } from 'src/constants/canvasControls';
import createReducer from './createReducer';

const DEFAULT_EYEDROPPER = {
  active: false,
  color: '#000000',
  source: '',
};

const initialState = {
  controlOpen: '',
  draggableOffset: { x: 0, y: 0 },
  stageOffset: { x: 0, y: 0 },
  baseCanvasWidth: 0,
  baseCanvasHeight: 0,
  scale: 0,
  replaceableImageRightClick: false,
  rightClickOptions: null,
  eyeDropper: DEFAULT_EYEDROPPER,
  zoom: undefined,
  gradientActiveColorId: undefined,
  pinchZoomGesture: false,
  selectedFrame: null,
  editableHyperlink: null,
  textCombinations: [],
  objectCombinations: [],
  uploadedMedia: [],
  loadingUploadedMedia: 0,
  selectingMultipleMobile: false,
  squadEventAssets: { assets: [], totalAssets: 0 },
};

const actionHandlers = {
  [changeControlOpen]: (state, { payload }) => {
    state.controlOpen = payload;
  },
  [saveCanvasSize]: (state, { payload: {
    baseCanvasWidth,
    baseCanvasHeight,
    scale,
    stageOffset,
    draggableOffset,
  } }) => {
    state.baseCanvasWidth = baseCanvasWidth;
    state.baseCanvasHeight = baseCanvasHeight;
    state.scale = scale;
    state.stageOffset = stageOffset;
    if (draggableOffset) {
      state.draggableOffset = draggableOffset;
    }
  },
  [saveZoom]: (state, { payload: { zoom } }) => {
    state.zoom = zoom;
  },
  [saveDraggableOffset]: (state, { payload: { x, y } }) => {
    state.draggableOffset = { x, y };
  },
  [setPinchZoomGesture]: (state, { payload }) => {
    state.pinchZoomGesture = payload;
  },
  [resetDraggableOffset]: state => {
    state.draggableOffset = { x: 0, y: 0 };
  },
  [setReplaceableImageRightClick]: (state, { payload: { attrs } }) => {
    state.replaceableImageRightClick = attrs;
    state.rightClickOptions = null;
    if (attrs) {
      state.controlOpen = CHOOSE_IMAGE_CONTROL;
    } else {
      state.controlOpen = '';
    }
  },
  [replaceImageFromRightClick]: (state) => {
    state.replaceableImageRightClick = false;
  },
  [openRightClickOptions]: (state, { payload }) => {
    state.rightClickOptions = payload;
  },
  [closeRightClickOptions]: state => {
    state.rightClickOptions = null;
  },
  [cleanSelectedElement]: state => {
    state.selectedFrame = null;
    state.selectingMultipleMobile = false;
  },
  [addSelectedElement]: state => {
    state.selectedFrame = null;
  },
  [setEyeDropperActive]: (state, { payload: { source } }) => {
    state.eyeDropper = {
      active: true,
      color: DEFAULT_EYEDROPPER.color,
      source,
    };
  },
  [changeEyeDropperColor]: (state, { payload: { hex } }) => {
    state.eyeDropper.color = hex;
  },
  [chooseEyeDropperColor]: state => {
    state.eyeDropper = DEFAULT_EYEDROPPER;
  },
  [resetEyeDropperColor]: state => {
    state.eyeDropper.color = DEFAULT_EYEDROPPER.color;
  },
  [setEyeDropperInactive]: state => {
    state.eyeDropper = DEFAULT_EYEDROPPER;
  },
  [setGradientActiveColorId]: (state, { payload }) => {
    state.gradientActiveColorId = payload;
  },
  [selectFrame]: (state, { payload: { frameIndex } }) => {
    state.selectedFrame = frameIndex;
  },
  [deselectFrame]: (state) => {
    state.selectedFrame = null;
  },
  [setEditableHyperlink]: (state, { payload }) => {
    state.editableHyperlink = payload;
  },
  [getTextCombinationsSuccess]: (state, { payload: { textCombinations } }) => {
    state.textCombinations = textCombinations;
  },
  [getObjectsCombinationsSuccess]: (state, { payload: { objectCombinations } }) => {
    state.objectCombinations = objectCombinations;
  },
  [uploadSingleAssetSuccess]: (state, { payload: { url } }) => {
    state.uploadedMedia = [url, ...state.uploadedMedia];
    state.loadingUploadedMedia -= 1;
  },
  [uploadMediaRequest]: (state, { payload: { amount } }) => {
    state.loadingUploadedMedia += amount;
  },
  [uploadMediaSuccess]: (state, { payload: { uploadedMedia } }) => {
    state.uploadedMedia = uploadedMedia;
  },
  [deleteMediaSuccess]: (state, { payload: { project: { uploadedMedia } } }) => {
    state.uploadedMedia = uploadedMedia;
  },
  [uploadMediaError]: state => {
    state.loadingUploadedMedia = 0;
  },
  [addAssetsToMediaDrawerSuccess]: (state, { payload: { uploadedMedia } }) => {
    state.uploadedMedia = uploadedMedia;
  },
  [setSelectingMultipleMobile]: (state, { payload: { selectingMultipleMobile } }) => {
    state.selectingMultipleMobile = selectingMultipleMobile;
  },
  [getProjectSuccess]: (state, { payload: { project } }) => {
    state.uploadedMedia = project.uploadedMedia;
  },
  [getSquadEventAssetsSuccess]: (state, { payload: { assets, totalAssets } }) => {
    state.squadEventAssets = {
      assets: [...state.squadEventAssets.assets, ...assets],
      totalAssets,
    };
  },
  [getSquadEventAssetsReset]: state => {
    state.squadEventAssets = { assets: [], totalAssets: 0 };
  },
  [getProjectReset]: () => initialState,
  [getProjectError]: () => initialState,
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
