import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { bool, string } from 'prop-types';

import {
  getSearchTemplates,
  SEARCH_TEMPLATES,
  setSearchKeyword,
} from 'src/actions/templatesActions';
import { Spinner } from 'src/common/spinner';
import SearchIcon from 'src/assets/icons/search.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import { useStatus, usePagination, useProjectSelector } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import styles from './SearchTemplatesInput.module.scss';

const SearchTemplatesInput = ({ inputClassName, className, searchPrivateTemplates }) => {
  const dispatch = useDispatch();

  const { searchKeyword, searchTemplates, accountId } = useSelector(({ templates, session }) => ({
    searchKeyword: templates.searchKeyword,
    searchTemplates: templates.searchTemplates,
    accountId: session.accountId,
  }));

  const { type } = useProjectSelector();

  const [search, setSearch] = useState(searchKeyword);

  useEffect(() => {
    setSearch(searchKeyword);
  }, [searchKeyword]);

  const { status } = useStatus(SEARCH_TEMPLATES);

  const searchRequest = useCallback(async (page) => {
    const accountIdToSend = searchPrivateTemplates ? accountId : -1;
    await dispatch(getSearchTemplates(accountIdToSend, search, type, page));
  }, [searchPrivateTemplates, accountId, dispatch, search, type]);

  const closeSearch = () => {
    dispatch(setSearchKeyword(''));
  };

  const handleChange = ({ target: { value } }) => setSearch(value);

  const { page } = usePagination(searchRequest);

  const handleSubmit = ({ key }) => {
    if (key === ENTER_CHARACTER_CODE && search) {
      searchRequest(page);
    }
  };

  return (
    <div className={cn(styles.search, className)}>
      <input
        type="text"
        value={search}
        placeholder="Search"
        onChange={handleChange}
        className={cn(styles.input, inputClassName)}
        onKeyDown={handleSubmit}
      />
      {status === LOADING && (
        <Spinner iconClassName={styles.spinnerIcon} />
      )}
      {!searchKeyword.length && status !== LOADING && (
        <button
          onClick={() => searchRequest(page)}
          className={styles.button}
          disabled={status === LOADING || searchKeyword}
        >
          <img className={styles.searchIcon} src={SearchIcon} alt="search" />
        </button>
      )}
      {searchKeyword && searchTemplates.length >= 0 && status !== LOADING && (
        <button
          onClick={closeSearch}
          className={styles.button}
        >
          <img className={styles.searchIcon} src={CloseIcon} alt="close" />
        </button>
      )}
    </div>
  );
};

SearchTemplatesInput.propTypes = {
  inputClassName: string,
  className: string,
  searchPrivateTemplates: bool,
};

export { SearchTemplatesInput };
