import React from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { routesPaths } from 'src/routes/routesPaths';
import styles from './NavbarTabs.module.scss';

const NavbarTabs = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <div className={cn(styles.tab, { [styles.active]: pathname === routesPaths.projects })}>
        <Link to={routesPaths.projects} className={styles.link}>
          Projects
        </Link>
      </div>
      <div className={cn(styles.tab, { [styles.active]: pathname === routesPaths.templates })}>
        <Link to={routesPaths.templates} className={styles.link}>
          Templates
        </Link>
      </div>
      <div className={cn(styles.tab, { [styles.active]: pathname === routesPaths.brandLibrary })}>
        <Link to={routesPaths.brandLibrary} className={styles.link}>
          Brand Library
        </Link>
      </div>
      <div className={cn(styles.tab, { [styles.active]: pathname === routesPaths.dashboard })}>
        <Link to={routesPaths.dashboard} className={styles.link}>
          Dashboard
        </Link>
      </div>
    </div>
  );
};

export { NavbarTabs };
