import React, { memo, forwardRef } from 'react';
import { string, func, bool, object, node, oneOfType } from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.scss';

const Input = memo(forwardRef(({
  label,
  name,
  onChange,
  onKeyDown,
  value = '',
  error = '',
  touched = false,
  type = 'text',
  placeholder = '',
  textAlignRight = false,
  containerClassName = '',
  inputClassName = '',
  disabled = false,
  sufix,
  onBlur,
  autoComplete = 'off',
}, ref) => {
  const hasError = error && touched;
  return (
    <div className={cn(styles.container, containerClassName)}>
      {label && <label htmlFor={name} className={styles.inputLabel}>{label}</label>}
      <div className={styles.inputContainer}>
        <input
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          className={cn(styles.input, inputClassName, {
            [styles.inputError]: hasError,
            [styles.textAlignRight]: textAlignRight,
          })}
          type={type}
          disabled={disabled}
          ref={ref}
          autoComplete={autoComplete}
        />
        {!!sufix && sufix}
        {hasError && <span className={styles.error}>{error}</span>}
      </div>
    </div>
  );
}));

Input.propTypes = {
  name: string.isRequired,
  label: string,
  error: string,
  onChange: func.isRequired,
  onKeyDown: func,
  onBlur: func,
  value: string,
  touched: bool,
  type: string,
  placeholder: string,
  textAlignRight: bool,
  containerClassName: string,
  inputClassName: string,
  disabled: bool,
  sufix: oneOfType([object, node]),
  autoComplete: string,
};

export { Input };
