import { useEffect } from 'react';

import {
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
  KEY_A_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import {
  CANVAS_DRAWING_CONTENT,
  CANVAS_SELECT_ALL_ID,
} from 'src/constants/general';
import { useKeyboardListener } from 'src/hooks';
import { useKeyboardReset } from 'src/hooks/useKeyboardListener';
import { isHtmlTagFocused } from 'src/utils/helpers';

const useSelectAllShortcut = (refDrawingLayer) => {
  const keyAPressed = useKeyboardListener(KEY_A_CHARACTER_CODE);

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);
  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const resetKey = useKeyboardReset();

  useEffect(() => {
    if (refDrawingLayer?.current && !isHtmlTagFocused() &&
      (commandPressed || controlPressed) && keyAPressed) {
      refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`)
        .children.forEach((elementNode) => {
          if (elementNode.attrs.id) {
            elementNode.fire('click', { origin: CANVAS_SELECT_ALL_ID });
          }
        });
      resetKey(KEY_A_CHARACTER_CODE);
    }
  }, [commandPressed, controlPressed, keyAPressed, refDrawingLayer, resetKey]);
};

export { useSelectAllShortcut };
