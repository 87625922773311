import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSession } from 'src/hooks';
import { DEFAULT_PROJECT } from 'src/constants/general';
import { Button } from 'src/common/button';
import { createProject } from 'src/actions/projectActions';
import styles from './NoPosts.module.scss';

const NoPosts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { account } = useSession();

  const onClick = () => {
    const project = {
      size: DEFAULT_PROJECT.SIZE,
      color: DEFAULT_PROJECT.COLOR,
      alpha: DEFAULT_PROJECT.ALPHA,
      layoutWidth: DEFAULT_PROJECT.LAYOUT.width,
      layoutHeight: DEFAULT_PROJECT.LAYOUT.height,
      layoutSource: DEFAULT_PROJECT.LAYOUT.source,
    };
    dispatch(createProject(project, account, history));
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>Your feed is empty.</span>
      <span className={styles.subtitle}>
        We need a post on your Instagram to start gathering some data from.
        Get started with a Stagger template and post your first project!
      </span>
      <Button
        className={styles.button}
        onClick={onClick}
        secondary
      >
        Create a new project
      </Button>
    </div>
  );
};

export { NoPosts };
