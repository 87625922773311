import React, { useRef } from 'react';
import cn from 'classnames';
import { func, shape, bool, number, object } from 'prop-types';

import { useStopDragging } from './useStopDragging';
import styles from './ColorStop.module.scss';

const getLeftCoordinate = (ref) => {
  if (!ref.current) return 0;
  return ref.current.getBoundingClientRect().left;
};

const ColorStop = ({
  stop,
  limits,
  onPosChange,
  onDeleteColor,
  onDragStart = () => {},
  onDragEnd = () => {},
  handlerRef,
}) => {
  const left = getLeftCoordinate(handlerRef);

  const colorStopRef = useRef();
  const [drag] = useStopDragging({
    stop,
    limits,
    onPosChange,
    onDragStart,
    onDragEnd,
    onDeleteColor,
    colorStopRef,
    left,
  });

  const { offset, color, isActive } = stop;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cn(styles.container, { [styles.active]: isActive })}
      ref={colorStopRef}
      style={{ left: offset, backgroundColor: color }}
      onMouseDown={drag}
    />
  );
};

ColorStop.propTypes = {
  stop: shape({
    id: number,
    offset: number,
    isActive: bool,
  }),
  limits: shape({
    min: number,
    max: number,
    drop: number,
  }),
  onPosChange: func,
  onDeleteColor: func,
  onDragStart: func,
  onDragEnd: func,
  handlerRef: object,
};

export { ColorStop };
