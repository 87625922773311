import { INSTAGRAM_LAYOUTS } from 'src/constants/layout';

export const CANVAS_BACKGROUND = 'canvasBackground';

export const CANVAS_DRAWING_LAYER = 'canvasDrawingLayer';

export const CANVAS_DRAWING_CONTENT = 'canvasDrawingContent';

export const CANVAS_SEPARATOR = 'canvasSeparator';

export const CANVAS_BLEED_PREFIX = 'canvasBleed';

export const CANVAS_SELECTED_FRAME_PREFIX = 'canvasSelectedFrame';

export const CANVAS_DROPPABLE_ID = 'canvas';

export const CANVAS_SCROLL_ID = 'canvasScroll';

export const STAGE_ID = 'stage';

export const TRANSFORMER_ID = 'transformer';

export const CANVAS_ELEMENT_MENU = 'elementMenu';

export const TAG_MARKER_PREFIX_ID = 'tagMarker';
export const TIP_LIGHTBULB_PREFIX_ID = 'tipLightbulb';
export const PROCESSING_OVERLAY_ID = 'processingOverlay';
export const VIDEO_BUTTON_PREFIX_ID = 'videoPlayPause';

export const CANVAS_HELPERS_PREFIXES = [
  TAG_MARKER_PREFIX_ID,
  TIP_LIGHTBULB_PREFIX_ID,
  PROCESSING_OVERLAY_ID,
  VIDEO_BUTTON_PREFIX_ID,
];

export const CANVAS_TOP_LEVEL_HELPERS_PREFIXES = [
  CANVAS_SEPARATOR,
  CANVAS_SELECTED_FRAME_PREFIX,
  CANVAS_ELEMENT_MENU,
];

export const CANVAS_SELECTION_ID = 'canvasSelection';
export const CANVAS_SELECT_ALL_ID = 'canvasSelectAll';
export const CANVAS_ADDING_ELEMENT = 'canvasAddingElement';

export const USER_WEBSITE_MODAL_ID = 'userWebsiteModal';

export const FONT_STYLES_FILE_NAME = 'fontStyles';

export const TEXT_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

export const PROJECT_TYPE = {
  PROJECT: 'project',
  FLYER: 'flyer',
  SIGN: 'sign',
};

export const USER_PERSONA = {
  INFLUENCER: 'influencer',
  CONTENT_CREATOR: 'contentCreator',
  BUSINESS_OWNER: 'businessOwner',
};

export const LEFT = 'left';
export const RIGHT = 'right';
export const CUSTOM = 'custom';

export const CANVAS_TEXT_PADDING = 12;

export const TEXT_DEFAULT_LINE_HEIGHT = 1.5;

export const TEXT_DISABLED_COLOR = '#000000';

export const SHAPE_DISABLED_COLOR = '#EEEEEE';

export const DEFAULT_BORDER_COLOR = { hex: '#EEEEEE', alpha: 100 };

export const SELECT_THEME = base => ({
  ...base,
  colors: {
    ...base.colors,
    primary25: '#f7f7f7',
    primary75: '#f7f7f7',
    primary50: '#f7f7f7',
    primary: '#555555',
  },
});

export const SELECT_STYLES = {
  control: base => ({
    ...base,
    border: 'solid 1px #CCCCCC',
    boxShadow: 0,
    fontSize: '1.6rem',
    color: '#555555',
    minHeight: '5.6rem',
    overflowY: 'auto',
    '&:hover': {
      borderColor: '#201C1C',
    },
  }),
  placeholder: base => ({ ...base, color: '#9A9A9A' }),
  option: base => ({ ...base, padding: '.8rem 1.5rem' }),
  singleValue: base => ({ ...base,
    color: '#555555',
    fontSize: '1.6rem' }),
  input: base => ({ ...base, color: '#555555', fontSize: '1.6rem' }),
  valueContainer: base => ({ ...base, padding: '.8rem 1.5rem' }),
  menu: base => ({ ...base,
    color: '#555555',
    fontSize: '1.6rem',
    border: 'solid 1px #201C1C',
    boxShadow: 'none' }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#F7F7F7',
    border: 'solid 1px #CCCCCC',
    fontSize: '1.6rem',
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#555555',
    fontSize: '1.6rem',
  }),
};

export const NO_BORDER_SIZE = 0;
export const DEFAULT_BORDER_SIZE = NO_BORDER_SIZE;
export const BORDER_DASH_OPTIONS = [[], [1, 5], [5, 5], [5, 2, 1, 2]];
export const DEFAULT_BORDER_DASH = BORDER_DASH_OPTIONS[0];

export const DEFAULT_PROJECT = {
  LAYOUT: INSTAGRAM_LAYOUTS.PORTRAIT_LAYOUT,
  SIZE: 1,
  COLOR: '#ffffff',
  ALPHA: 100,
};

export const ROLE_ADMIN = 1;

export const SHOTSTACK_VIDEO_READY = 'done';

export const CREATE_FONT_SIZE_PREFIX = 'Font size';

export const DOCUMENTATION_LINK =
  'https://www.notion.so/Documentation-f2830e3f847b4eea9f3c7663e9f0b0e5';

export const LANDING_PAGE_LINK = 'https://www.stagger.com';

export const ACTIONS_TO_AUTO_SAVE_PROJECT = 10;

export const MAX_FREE_PROJECT_EXPORTS = 3;

export const MOBILE_SCREEN_CLOSED_MODAL = 'closed';
export const MOBILE_SCREEN_NOT_SEEN_MODAL = 'notSeen';
export const MOBILE_SCREEN_SEEING_MODAL = 'seeing';

export const BACKGROUND_COLOR_EYEDROPPER_SOURCE = 'backgroundColor';
export const FILL_COLOR_EYEDROPPER_SOURCE = 'fillColor';
export const BORDER_COLOR_EYEDROPPER_SOURCE = 'borderColor';
export const TEXT_COLOR_EYEDROPPER_SOURCE = 'textColor';
export const SHADOW_COLOR_EYEDROPPER_SOURCE = 'shadowColor';

export const CANVAS_ZOOM_FIT = 'Fit';
export const ZOOM_OPTIONS = [
  { text: '200%', value: 200 },
  { text: '150%', value: 150 },
  { text: '100%', value: 100 },
  { text: '75%', value: 75 },
  { text: '50%', value: 50 },
  { text: '25%', value: 25 },
  { text: '10%', value: 10 },
  { text: 'Fit', value: CANVAS_ZOOM_FIT },
];

export const CANVAS_TITLE_SPACE = 30;
export const CANVAS_TITLE_MARGIN_TOP = 10;

export const CANVAS_RIGHT_CLICK_OPTIONS_WIDTH = 14;
export const CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT = 23.1;
export const CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT_FOR_IMAGES = (
  CANVAS_RIGHT_CLICK_OPTIONS_HEIGHT + 4.5
);
export const CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT = 4.8;
export const CANVAS_RIGHT_CLICK_OPTIONS_TITLE_HEIGHT = 4.8;

export const CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT = 33.6;
export const CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT_MOVEMENT = 21.6;
export const CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_WIDTH = 4;

export const CANVAS_RIGHT_CLICK_OPTIONS_TYPE = {
  BACKGROUND: 'canvasBackground',
  ELEMENT: 'canvasElement',
  ELEMENT_FLOATING_MOBILE: 'canvasElementFloatingMobile',
  TITLE: 'canvasTitle',
  FRAME_CARD: 'frameCard',
};

export const EDITABLE_TITLE_FRAME_TYPE = {
  TITLE_INPUT: 'canvasTitleInput',
  FRAME_CARD_INPUT: 'frameCardInput',
  MODAL: 'modal',
};

export const CANVAS_OFFSET_FOR_ADDING_IMAGES = 25;

export const DEFAULT_SEARCH_TERM = 'photo';

export const COLOR_TYPE_SOLID = 'solid';
export const COLOR_TYPE_GRADIENT_LINEAR = 'linear';

export const BRAND_ASSETS_STATUS = {
  ASKING_FOR_A_WEBSITE: 'AskingForAWebsite',
  NOT_APPLICABLE: 'NotApplicable',
  FILLING_BRAND_LIBRARY: 'FillingBrandLibrary',
  READY_TO_NOTIFY: 'ReadyToNotify',
  DONE: 'Done',
  FAILED: 'Failed',
  API_ERROR: 'ApiError',
};

export const FILL_BRAND_LIBRARY_FROM_SCRAPER_STATUS = {
  REQUESTED: 'requested',
  DONE: 'done',
  FAILED: 'failed',
  FILLING: 'filling',
  // Backend status for when two requests try to fill the same brand library at the same time
  ALREADY_FILLING: 'already_filling',
};

export const SHOWING_STATUS = {
  DONT_SHOW: 'dontShow',
  SHOW: 'show',
  ALREADY_CLOSED: 'alreadyClosed',
};

export const HTTP_STATUS = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
};

export const KEEP_PROJECT_IN_DOWNGRADE = 'keep';
export const DELETE_PROJECT_IN_DOWNGRADE = 'delete';

export const UTM_SOURCE = 'utm_source=https%3A%2F%2Fwww.stagger.com&utm_medium=referral';

export const PRODUCT_TOUR_PREFIX_ID = 'productTourStep';

export const VISIBILITY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const CUSTOM_TEMPLATE_CATEGORY = 'Custom';

export const BROWSERS = {
  CHROME: 'chrome',
  SAFARI: 'safari',
  OTHERS: 'others',
};

export const TOUCH_LONG_PRESS = 'touchLongPress';

export const TOAST_ID_PRINT_ORDER_SUCCESS = 'print-order-success';

export const CLEAR_BRAND_LIBRARY_SEARCH_BUTTON_ID = 'clearBrandLibraryId';

export const EXPORT_CALLBACK = {
  DOWNLOAD_PREVIEWS: 'downloadPreviews',
  DOWNLOAD_PDF: 'downloadPdf',
  GENERATE_EMBED_CODE: 'generateEmbedCode',
  GO_TO_PREVIEW: 'goToPreview',
  PUBLISH_TOAST: 'publishToast',
  ORDER_SIGN: 'orderSign',
};

export const DESIGN_COMPONENT_TYPE = {
  TAPE: 'tape',
  TEXTS: 'texts',
  FRAMES: 'photoFrame',
};

export const COLLAGE_CHAT_TYPE = {
  QUESTION: 'question',
  ANSWER: 'answer',
  UPLOAD_MEDIA: 'uploadMedia',
  MEDIA_IN_CHAT: 'mediaInChat',
  ADD_PROJECT_MEDIA: 'addProjectMedia',
  LOADING: 'loading',
  COLLAGE_THUMBNAIL: 'collageThumbnail',
  ANSWER_REGENERATE: 'answerRegenerate',
  FINISH_ANSWER: 'answerFinish',
  AMOUNT_CANVASES_ANSWER: 'answerAmountCanvases',
};

export const SQUAD_EVENT_CAMPAIGN = 'squadEvent';

export const OPEN_FULLSCREEN_ACTION = 'openFullscreen';

export const ENABLE_ASSETS_SEARCH = false;

export const ENABLE_SUGGESTION_UPGRADE_BANNER = false;

export const ENABLE_PRIVATE_TEMPLATES = true;

const staggerTeamEmails = [
  'dario@stagger.com',
  'constanza@howdy.com',
  'chrissy@stagger.it',
];

const isStaggerTeam = (email) => staggerTeamEmails.includes(email);

const isStaggerEmail = (email) => email.endsWith('@stagger.com') || email.endsWith('@stagger.it');

export const ENABLE_FLYERS = (email) => isStaggerTeam(email) || isStaggerEmail(email);

export const ENABLE_SIGNS = (email) => isStaggerTeam(email) || isStaggerEmail(email);

export const ENABLE_REMOVE_BG = true;

export const ENABLE_DESIGN_TIPS = false;

export const ENABLE_SQUAD_EVENT_ASSETS = (createdOnCampaign) => (
  createdOnCampaign === SQUAD_EVENT_CAMPAIGN
);
