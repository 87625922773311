import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { Button } from 'src/common/button';
import { isUrlAbsolute } from 'src/utils/helpers';
import styles from './AccountHeader.module.scss';

const AccountHeader = () => {
  const {
    account,
    allPublicProjects,
  } = useSelector(({ publicPages }) => ({
    account: publicPages.account,
    allPublicProjects: publicPages.allPublicProjects,
  }));

  const numberOfProjectsText = useCallback(() => {
    if (allPublicProjects) {
      const projectsCount = allPublicProjects.length;
      if (projectsCount === 0) {
        return 'No flyers';
      }
      return `${projectsCount} flyer${projectsCount === 1 ? '' : 's'}`;
    }
    return '';
  }, [allPublicProjects]);

  const visitWebsite = useCallback(() => {
    if (account) {
      const url = account.businessInfo.website;
      window.location.href = isUrlAbsolute(url) ? url : `https://${url}`;
    }
  }, [account]);

  const publicBusinessName = useCallback(() => {
    const businessName = account?.businessInfo?.name;
    if (businessName && businessName.length > 0) {
      return businessName;
    }
    return account.brandName;
  }, [account]);

  return account && (
    <div className={styles.container}>
      <Helmet>
        <title>{`${publicBusinessName()} | Stagger`}</title>
        {account.businessInfo?.description && (
          <meta name="description" content={account.businessInfo?.description} />
        )}
      </Helmet>
      <div className={styles.header}>
        <div className={styles.name}>{publicBusinessName()}</div>
        <div className={styles.projectsCounter}>
          {numberOfProjectsText()}
        </div>
      </div>
      <div className={styles.headerActions}>
        <Button
          onClick={visitWebsite}
          className={styles.websiteButton}
          secondary
          disabled={!account.businessInfo?.website}
          short
        >
          Visit Website
        </Button>
      </div>
    </div>
  );
};

export { AccountHeader };
