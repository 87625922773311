import React, { useCallback, forwardRef, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool } from 'prop-types';

import { useForm, useSession } from 'src/hooks';
import { saveOnboardingStep } from 'src/actions/accountActions';
import { checkForChanges } from 'src/utils/formHelpers';
import { StepsSelect } from '../steps-select';
import styles from './Step2TargetDemographic.module.scss';

const Step2TargetDemographic = forwardRef(({
  setFormIsDirty = () => {},
  fromProfile = false,
}, ref) => {
  const dispatch = useDispatch();

  const { onboarding: { targetDemographic }, accountId } = useSession();

  const initialValues = useMemo(() => ({
    category: targetDemographic?.category,
    industry: targetDemographic?.industry,
    age: targetDemographic?.age,
    gender: targetDemographic?.gender,
    race: targetDemographic?.race,
    income: targetDemographic?.income,
    geographic: targetDemographic?.geographic,
  }), [targetDemographic]);

  const saveOnboardingStepRequest = useCallback(async (values) => {
    await dispatch(saveOnboardingStep(2, values, accountId));
  }, [dispatch, accountId]);

  const {
    setValues,
    values,
    handleInputChange,
    handleSubmit,
  } = useForm(saveOnboardingStepRequest, { initialValues });

  useEffect(() => {
    setValues(initialValues);
  }, [setValues, initialValues]);

  const selectOnChange = useCallback((name, value) => {
    handleInputChange(name, value);
  }, [handleInputChange]);

  useEffect(() => {
    const haveChanges = checkForChanges(initialValues, values);
    setFormIsDirty(haveChanges);
  }, [setFormIsDirty, initialValues, values]);

  return (
    <div className={styles.step2container}>
      {!fromProfile && <div className={styles.headerInfo}>Who is your main target audience?</div>}
      <form onSubmit={handleSubmit} ref={ref}>
        <div className={styles.row}>
          <div className={styles.inputCategoryContainer}>
            <StepsSelect
              name="category"
              label="What category is your business in?"
              options={['B2B', 'B2C', 'C2C', 'C2B']}
              placeholder="Choose a category"
              onChange={selectOnChange}
              value={values.category}
            />
          </div>
          <div className={styles.inputIndustryContainer}>
            <StepsSelect
              name="industry"
              label="Which best describes your industry?"
              options={[
                'Bags and accessories',
                'Books',
                'Clothing',
                'Consumer electronics',
                'Design',
                'DIY',
                'Food and drink',
                'Furniture and household',
                'Music, entertainment, movies',
                'Non-profit',
                'Pets',
                'Real estate',
                'Restaurant',
                'Sports and outdoor activities',
                'Stationery/hobby',
                'Toys and baby products',
              ]}
              placeholder="Choose an industry"
              onChange={selectOnChange}
              value={values.industry}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputAgeContainer}>
            <StepsSelect
              name="age"
              label="Age"
              options={[
                'All',
                '18-24 years old',
                '25-34 years old',
                '35-44 years old',
                '45-54 years old',
                '55-64 years old',
                '65-74 years old',
                '75+ years old',
              ]}
              placeholder="Choose an age range"
              onChange={selectOnChange}
              value={values.age}
            />
          </div>
          <div className={styles.inputGenderContainer}>
            <StepsSelect
              name="gender"
              label="Gender"
              options={[
                'All',
                'Female',
                'Male',
                'Non-binary',
                'Prefer not to answer',
              ]}
              placeholder="Choose a gender"
              onChange={selectOnChange}
              value={values.gender}
            />
          </div>
          <div className={styles.inputRaceContainer}>
            <StepsSelect
              name="race"
              label="Race"
              options={[
                'All',
                'American Indian or Alaska Native',
                'Asian',
                'Black or African American',
                'Hispanic',
                'Native Hawaiian or Other Pacific Islander',
                'White',
              ]}
              placeholder="Choose a race"
              onChange={selectOnChange}
              value={values.race}
            />
          </div>
        </div>
        <div className={styles.inputIncomeContainer}>
          <StepsSelect
            name="income"
            label="Income Level"
            options={[
              'All',
              '<$24k',
              '$25-40k',
              '$40k-75k',
              '$75-150k',
              '150k+',
            ]}
            placeholder="Choose an income range"
            onChange={selectOnChange}
            value={values.income}
          />
        </div>
        <div className={styles.inputGeographicContainer}>
          <StepsSelect
            name="geographic"
            label="Geographic Area"
            options={[
              'All',
              'Midwest - IA, IL, IN, KS, MI, MN, MO, ND, NE, OH, SD, WI',
              'Northeast - CT, DC, DE, MA, MD, ME, NH, NJ, NY, PA, RI, VT',
              'Southeast - AL, AR, FL, GA, KY, LA, MS, NC, SC, TN, VA, WV',
              'Southwest - AZ, NM, OK, TX',
              'West - AK, CA, CO, HI, ID, MT, NV, OR, UT, WA, WY',
            ]}
            placeholder="Choose a geographic area"
            onChange={selectOnChange}
            value={values.geographic}
          />
        </div>
      </form>
    </div>
  );
});

Step2TargetDemographic.propTypes = {
  setFormIsDirty: func,
  fromProfile: bool,
};

export { Step2TargetDemographic };
