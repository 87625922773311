import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { number, string } from 'prop-types';
import cn from 'classnames';

import { LOADING, SUCCESS } from 'src/constants/status';
import { GET_SUGGESTIONS, getSuggestions } from 'src/actions/insightActions';
import { useStatus, usePagination, useSession } from 'src/hooks';
import { Spinner } from 'src/common/spinner';
import {
  SuggestionCard,
} from 'src/pages/project/controls-options/templates-control/categories-list/suggestion-card';
import { SeeAllNavigation } from 'src/pages/templates/categories-list/see-all-navigation';
import { SEE_ALL_SUGGESTIONS_CONTROL } from 'src/constants/canvasControls';
import { changeControlOpen } from 'src/actions/canvasActions';
import styles from './SuggestionList.module.scss';

const SuggestionList = ({
  amountOfTemplatesInARow,
  suggestionCardClassName,
  spinnerContainerClassName,
}) => {
  const dispatch = useDispatch();
  const { accountId } = useSession();

  const { suggestions, suggestionsPages } = useSelector(({ insights }) => ({
    suggestions: insights.suggestions,
    suggestionsPages: insights.suggestionsPages,
  }));

  const { status: getSuggestionsStatus } = useStatus(GET_SUGGESTIONS);

  const handleFetch = useCallback(async (page) => {
    await dispatch(getSuggestions(accountId, page, amountOfTemplatesInARow));
  }, [amountOfTemplatesInARow, dispatch, accountId]);

  const { page, nextPage, prevPage } = usePagination(handleFetch);

  const goToSeeAll = () => {
    dispatch(changeControlOpen(SEE_ALL_SUGGESTIONS_CONTROL));
  };

  let isNextPageDisabled = false;
  if (suggestionsPages?.total) {
    const { total, current } = suggestionsPages;
    isNextPageDisabled = current === total;
  }

  return (
    <>
      <SeeAllNavigation
        name="Recommended for me"
        goToSeeAll={goToSeeAll}
        next={nextPage}
        prev={prevPage}
        isNextPageDisabled={isNextPageDisabled}
        isPrevDisabled={page === 1}
        templateTextClassName={styles.templateTitle}
        seeAllButtonClassName={styles.seeAllButton}
      />
      {getSuggestionsStatus === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={cn(styles.spinnerContainer, spinnerContainerClassName)}
          spinnerClassName={styles.spinner}
        />
      )}
      {getSuggestionsStatus === SUCCESS && (
        <div className={styles.suggestions}>
          {suggestions.map(suggestion => (
            <SuggestionCard
              key={suggestion.id}
              suggestion={suggestion}
              className={suggestionCardClassName}
            />
          ))}
          {Array.from({ length: amountOfTemplatesInARow - suggestions.length }, (v, i) => i)
            .map(elem => (
              <div className={cn(styles.blankcard, suggestionCardClassName)} key={elem} />
            ))}
        </div>
      )}
    </>
  );
};

SuggestionList.propTypes = {
  amountOfTemplatesInARow: number.isRequired,
  suggestionCardClassName: string,
  spinnerContainerClassName: string,
};

export { SuggestionList };
