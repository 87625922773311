import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useBrandLibraryColors = () => {
  const { colorPalettes, disabled } = useSelector(({ brandLibrary }) => ({
    colorPalettes: brandLibrary.colorPalettes,
    disabled: brandLibrary.disabled,
  }));

  const brandLibraryColors = useMemo(() => {
    if (disabled) {
      return [];
    }
    return colorPalettes.reduce((res, palette) => {
      palette.colors.forEach(paletteColor => {
        if (!res.find(resColor => resColor.hex === paletteColor.hex &&
          resColor.alpha === paletteColor.alpha)) {
          const newColor = {
            hex: paletteColor.hex,
            alpha: paletteColor.alpha,
          };
          res.push(newColor);
        }
      });
      return res;
    }, []);
  }, [colorPalettes, disabled]);

  return { brandLibraryColors };
};

export { useBrandLibraryColors };
