import React, { memo, useState } from 'react';
import { number, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { useProjectMedia, useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { optimizeCloudinaryThumbnail } from 'src/utils/cloudinaryHelpers';
import { changeControlOpen } from 'src/actions/canvasActions';
import { applySuggestionToProject } from 'src/actions/insightActions';
import { Button } from 'src/common/button';
import { TemplateMediaModal } from '../../preview-template/template-media-modal';
import styles from './SuggestionCard.module.scss';

const SuggestionCard = memo(({ suggestion }) => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const { media } = useProjectMedia();

  const { windowSize } = useWindowSize();

  const useSuggestion = () => {
    if (!media.length) {
      dispatch(applySuggestionToProject(suggestion));
      dispatch(changeControlOpen(''));
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      {windowSize.width <= SMALL_WIDTH ? (
        <div className={styles.card}>
          <button onClick={useSuggestion} className={styles.smallCard}>
            <img
              className={styles.image}
              src={optimizeCloudinaryThumbnail(suggestion.thumbnail)}
              alt=""
            />
          </button>
        </div>
      ) : (
        <div className={styles.card}>
          <img
            className={styles.image}
            src={optimizeCloudinaryThumbnail(suggestion.thumbnail)}
            alt=""
          />
          <div className={styles.overlayButtons}>
            <Button
              className={styles.buttonSelect}
              onClick={useSuggestion}
              secondary
            >
              Select
            </Button>
          </div>
        </div>
      )}
      <TemplateMediaModal
        isShowing={modalOpen}
        hide={() => setModalOpen(false)}
        media={media}
        suggestion={suggestion}
      />
    </>
  );
});

SuggestionCard.propTypes = {
  thumbnail: string.isRequired,
  suggestion: shape({
    id: number,
    thumbnail: string,
    layoutHeight: number,
    layoutWidth: number,
  }).isRequired,
};

export { SuggestionCard };
