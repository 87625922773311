import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCategoriesWithTemplates,
  GET_CATEGORIES_WITH_TEMPLATES,
} from 'src/actions/templatesActions';
import { useStatus } from 'src/hooks';
import { ERROR, LOADING, SUCCESS } from 'src/constants/status';
import { Spinner } from 'src/common/spinner';
import { PROJECT_TYPE } from 'src/constants/general';
import { reset } from 'src/actions/statusActions';
import styles from './CategoriesList.module.scss';
import { TemplateCategory } from './template-category';

const CategoriesList = () => {
  const dispatch = useDispatch();

  const { status: getTemplatesStatus } = useStatus(GET_CATEGORIES_WITH_TEMPLATES);

  const { categories } = useSelector(({ templates }) => ({
    categories: templates.categories,
  }));

  useEffect(() => {
    const page = 1;
    dispatch(getCategoriesWithTemplates(-1, page, PROJECT_TYPE.PROJECT));

    return () => dispatch(reset(GET_CATEGORIES_WITH_TEMPLATES));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {getTemplatesStatus === SUCCESS && (
        Object.values(categories).map(({ name, id, templates }) => (
          !!templates.length && (
            <TemplateCategory
              key={id}
              name={name}
              categoryId={id}
            />
          )
        ))
      )}
      {getTemplatesStatus === LOADING && (
        <Spinner
          iconClassName={styles.spinnerIcon}
          containerClassName={styles.spinnerContainer}
        />
      )}
      {getTemplatesStatus === ERROR && (
        <span className={styles.error}>
          Oops! The search for the templates resulted in error. Please try again.
        </span>
      )}
    </div>
  );
};

export { CategoriesList };
