import React, { useRef } from 'react';

import { useScrollPosition, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { ShapeFillOptions } from './shape-fill-options';
import { BorderOptions } from '../element-control/border-options';
import { ElementShadow } from '../element-control/element-shadow';
import styles from './ShapeControl.module.scss';

const ShapeControl = () => {
  const ref = useRef();

  const { position } = useScrollPosition(ref);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  return (
    <div className={styles.container} ref={ref}>
      <ShapeFillOptions scrollPosition={position} />
      <BorderOptions scrollPosition={position} />
      <ElementShadow
        scrollPosition={position}
        className={styles.borderLeft}
        paddingLeftOptions={isMobile ? 140 : 0}
      />
    </div>
  );
};

export { ShapeControl };
