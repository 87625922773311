import React, { useCallback, useMemo } from 'react';
import { func, bool, number, object } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { deleteProject, DELETE_PROJECT } from 'src/actions/projectActions';
import { useLoading } from 'src/hooks';
import { VISIBILITY } from 'src/constants/general';
import styles from './DeleteProjectModal.module.scss';

const DeleteProjectModal = ({ isShowing, hide, projectId, template }) => {
  const dispatch = useDispatch();

  const deleteProjectRequest = useCallback(() => {
    dispatch(deleteProject(projectId));
  }, [dispatch, projectId]);

  const loading = useLoading(DELETE_PROJECT);

  const hasTemplate = !!template;
  const hasPrivateTemplate = hasTemplate && template.visibility === VISIBILITY.PRIVATE;

  const info = useMemo(() => {
    const result = {};
    if (hasPrivateTemplate) {
      result.title = 'Are you sure?';
      result.subtitle = `This project is associated with one of your custom templates.
      If you delete it, your template will also be deleted.`;
      result.ctaButtonText = 'I\'m sure';
      result.ctaButtonAction = deleteProjectRequest;
    } else if (hasTemplate) {
      result.title = 'Template warning';
      result.subtitle = `This project is linked to a public template.
      Please delete the template first from the library before deleting the project.`;
      result.ctaButtonText = 'Ok';
      result.ctaButtonAction = hide;
    } else {
      result.title = 'Delete your Stagger?';
      result.subtitle = `Are you sure you want to delete your Stagger?
      This cannot be undone and the layout will be deleted from your projects.`;
      result.ctaButtonText = 'I\'m sure';
      result.ctaButtonAction = deleteProjectRequest;
    }
    return result;
  }, [hasPrivateTemplate, hasTemplate, deleteProjectRequest, hide]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>{info.title}</span>
        <span className={styles.subtitle}>{info.subtitle}</span>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={info.ctaButtonAction}
            loading={loading}
            alternativeCTA
          >
            {info.ctaButtonText}
          </Button>
          <Button
            className={styles.buttonCancel}
            onClick={hide}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteProjectModal.propTypes = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  projectId: number.isRequired,
  template: object,
};

export { DeleteProjectModal };
