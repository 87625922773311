/* eslint-disable default-case */
import { CANVAS_DRAWING_LAYER } from 'src/constants/general';

export const GUIDELINE_OFFSET = 5;

export const CENTER = 'center';
export const START = 'start';
export const END = 'end';

export const ALIGNMENT_GUIDE_NAME = 'guid-line';

// find all snapping possibilities
export const getGuides = (lineGuideStops, itemBounds) => {
  const resultV = [];
  const resultH = [];

  lineGuideStops.vertical.forEach((lineGuide) => {
    itemBounds.vertical.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      // if the distance between guild line and object snap point
      // is close we can consider this for snapping
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide,
          diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide) => {
    itemBounds.horizontal.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide,
          diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
        });
      }
    });
  });

  const guides = [];

  // find closest snap
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: 'V',
      snap: minV.snap,
      diff: minV.diff,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: 'H',
      snap: minH.snap,
      diff: minH.diff,
    });
  }
  return guides;
};

// what points of the object will trigger to snapping?
// we will enable all edges and center
export const getObjectSnappingEdges = (node, allowedSnappingEdges) => {
  const box = node.getClientRect();
  const absPos = node.getAbsolutePosition();

  const result = {
    vertical: [
      {
        guide: Math.round(box.x),
        offset: Math.round(absPos.x - box.x),
        snap: START,
      },
      {
        guide: Math.round(box.x + box.width / 2),
        offset: Math.round(absPos.x - box.x - box.width / 2),
        snap: CENTER,
      },
      {
        guide: Math.round(box.x + box.width),
        offset: Math.round(absPos.x - box.x - box.width),
        snap: END,
      },
    ],
    horizontal: [
      {
        guide: Math.round(box.y),
        offset: Math.round(absPos.y - box.y),
        snap: START,
      },
      {
        guide: Math.round(box.y + box.height / 2),
        offset: Math.round(absPos.y - box.y - box.height / 2),
        snap: CENTER,
      },
      {
        guide: Math.round(box.y + box.height),
        offset: Math.round(absPos.y - box.y - box.height),
        snap: END,
      },
    ],
  };
  if (!allowedSnappingEdges) {
    return result;
  }
  result.vertical = result.vertical.filter(
    elem => allowedSnappingEdges.vertical.find(allowed => allowed === elem.snap),
  );
  result.horizontal = result.horizontal.filter(
    elem => allowedSnappingEdges.horizontal.find(allowed => allowed === elem.snap),
  );
  return result;
};

export const getObjectSnapsAllowed = (activeAnchor) => {
  const anchors = activeAnchor.split('-');
  const vertical = [];
  const horizontal = [];
  anchors.forEach(anchor => {
    if (anchor === 'top') {
      horizontal.push(START);
    } else if (anchor === 'bottom') {
      horizontal.push(END);
    } else if (anchor === 'left') {
      vertical.push(START);
    } else if (anchor === 'right') {
      vertical.push(END);
    }
  });
  return { vertical, horizontal };
};

export const boundBoxFuncTrasformerAlignmentGuides = (
  oldBox,
  newBox,
  refTransform,
  shiftPressed,
) => {
  if (!refTransform?.current || shiftPressed) {
    return newBox;
  }
  const drawingLayer = refTransform.current.findAncestor(`#${CANVAS_DRAWING_LAYER}`);
  const guides = drawingLayer.find(`.${ALIGNMENT_GUIDE_NAME}`);
  guides.forEach((lg) => {
    switch (lg.attrs.snap) {
      case START: {
        switch (lg.attrs.orientation) {
          case 'V': {
            const diff = Math.abs(lg.absolutePosition().x - newBox.x);
            if (diff < GUIDELINE_OFFSET) {
              newBox.x = lg.absolutePosition().x;
              newBox.width = oldBox.width - (lg.absolutePosition().x - oldBox.x);
            }
            break;
          }
          case 'H': {
            const diff = Math.abs(lg.absolutePosition().y - newBox.y);
            if (diff < GUIDELINE_OFFSET) {
              newBox.y = lg.absolutePosition().y;
              newBox.height = oldBox.height - (lg.absolutePosition().y - oldBox.y);
            }
            break;
          }
        }
        break;
      }
      case END: {
        switch (lg.attrs.orientation) {
          case 'V': {
            const diff = Math.abs(lg.absolutePosition().x - newBox.x - newBox.width);
            if (diff < GUIDELINE_OFFSET) {
              newBox.width = lg.absolutePosition().x - newBox.x;
            }
            break;
          }
          case 'H': {
            const diff = Math.abs(lg.absolutePosition().y - newBox.y - newBox.height);
            if (diff < GUIDELINE_OFFSET) {
              newBox.height = lg.absolutePosition().y - newBox.y;
            }
            break;
          }
        }
        break;
      }
    }
  });
  return newBox;
};
