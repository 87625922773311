import React from 'react';
import cn from 'classnames';
import { string } from 'prop-types';

import { Spinner } from 'src/common/spinner';
import styles from './LoadingCard.module.scss';

const LoadingCard = ({ className }) => (
  <div className={cn(styles.loading, className)}>
    <Spinner iconClassName={styles.spinnerIcon} />
  </div>
);

LoadingCard.propTypes = {
  className: string,
};

export { LoadingCard };
