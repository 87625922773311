import axios from 'axios';

const ShareClient = axios.create({
  baseURL: `${process.env.REACT_APP_URL_SHARE_API}/public-api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export { ShareClient };
