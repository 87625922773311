import React from 'react';
import { bool } from 'prop-types';
import cn from 'classnames';

import ArrowUp from 'src/assets/icons/arrow-up.svg';
import styles from './ChevronArrow.module.scss';

const ChevronArrow = ({ isOpen }) => (
  <img
    src={ArrowUp}
    alt=""
    className={cn(styles.icon, { [styles.closedIcon]: !isOpen })}
  />
);

ChevronArrow.propTypes = {
  isOpen: bool.isRequired,
};

export { ChevronArrow };
