import { format, parseISO } from 'date-fns';

import { InstagramAnalyticsService } from 'src/services/instagramAnalyticsService';
import { InstagramPostService } from 'src/services/instagramPostService';

import createAction from './createAction';

export const SET_SELECTED_POST = 'SET_SELECTED_POST';

export const GET_INSTAGRAM_POSTS = 'GET_INSTAGRAM_POSTS';
export const GET_INSTAGRAM_POSTS_SUCCESS = 'GET_INSTAGRAM_POSTS_SUCCESS';
export const GET_INSTAGRAM_POSTS_REQUEST = 'GET_INSTAGRAM_POSTS_REQUEST';
export const GET_INSTAGRAM_POSTS_ERROR = 'GET_INSTAGRAM_POSTS_ERROR';

export const GET_POST_DATA = 'GET_POST_DATA';
export const GET_POST_DATA_SUCCESS = 'GET_POST_DATA_SUCCESS';
export const GET_POST_DATA_REQUEST = 'GET_POST_DATA_REQUEST';
export const GET_POST_DATA_ERROR = 'GET_POST_DATA_ERROR';

export const GET_POST_INSIGHTS = 'GET_POST_INSIGHTS';
export const GET_POST_INSIGHTS_SUCCESS = 'GET_POST_INSIGHTS_SUCCESS';
export const GET_POST_INSIGHTS_REQUEST = 'GET_POST_INSIGHTS_REQUEST';
export const GET_POST_INSIGHTS_ERROR = 'GET_POST_INSIGHTS_ERROR';

export const FB_SDK_INIT = 'FB_SDK_INIT';
export const FB_SDK_INIT_SUCCESS = 'FB_SDK_INIT_SUCCESS';
export const FB_SDK_INIT_REQUEST = 'FB_SDK_INIT_REQUEST';
export const FB_SDK_INIT_ERROR = 'FB_SDK_INIT_ERROR';

export const LOGIN_TO_FB = 'LOGIN_TO_FB';
export const LOGIN_TO_FB_SUCCESS = 'LOGIN_TO_FB_SUCCESS';
export const LOGIN_TO_FB_REQUEST = 'LOGIN_TO_FB_REQUEST';
export const LOGIN_TO_FB_ERROR = 'LOGIN_TO_FB_ERROR';

export const IS_LOGGED_IN_TO_FB = 'IS_LOGGED_IN_TO_FB';
export const IS_LOGGED_IN_TO_FB_SUCCESS = 'IS_LOGGED_IN_TO_FB_SUCCESS';
export const IS_LOGGED_IN_TO_FB_REQUEST = 'IS_LOGGED_IN_TO_FB_REQUEST';
export const IS_LOGGED_IN_TO_FB_ERROR = 'IS_LOGGED_IN_TO_FB_ERROR';

export const LOGOUT_FROM_FB = 'LOGOUT_FROM_FB';
export const LOGOUT_FROM_FB_SUCCESS = 'LOGOUT_FROM_FB_SUCCESS';
export const LOGOUT_FROM_FB_REQUEST = 'LOGOUT_FROM_FB_REQUEST';
export const LOGOUT_FROM_FB_ERROR = 'LOGOUT_FROM_FB_ERROR';

export const IS_STAGGER_POST = 'IS_STAGGER_POST';
export const IS_STAGGER_POST_SUCCESS = 'IS_STAGGER_POST_SUCCESS';
export const IS_STAGGER_POST_REQUEST = 'IS_STAGGER_POST_REQUEST';
export const IS_STAGGER_POST_ERROR = 'IS_STAGGER_POST_ERROR';

export const SET_IS_STAGGER_POST = 'SET_IS_STAGGER_POST';
export const SET_IS_STAGGER_POST_SUCCESS = 'SET_IS_STAGGER_POST_SUCCESS';
export const SET_IS_STAGGER_POST_REQUEST = 'SET_IS_STAGGER_POST_REQUEST';
export const SET_IS_STAGGER_POST_ERROR = 'SET_IS_STAGGER_POST_ERROR';

export const setSelectedPost = createAction(SET_SELECTED_POST);

export const getInstagramPostsRequest = createAction(GET_INSTAGRAM_POSTS_REQUEST);
export const getInstagramPostsSuccess = createAction(GET_INSTAGRAM_POSTS_SUCCESS);
export const getInstagramPostsError = createAction(GET_INSTAGRAM_POSTS_ERROR);

export const getPostDataRequest = createAction(GET_POST_DATA_REQUEST);
export const getPostDataSuccess = createAction(GET_POST_DATA_SUCCESS);
export const getPostDataError = createAction(GET_POST_DATA_ERROR);

export const getPostInsightsRequest = createAction(GET_POST_INSIGHTS_REQUEST);
export const getPostInsightsSuccess = createAction(GET_POST_INSIGHTS_SUCCESS);
export const getPostInsightsError = createAction(GET_POST_INSIGHTS_ERROR);

export const fbSDKInitRequest = createAction(FB_SDK_INIT_REQUEST);
export const fbSDKInitSuccess = createAction(FB_SDK_INIT_SUCCESS);
export const fbSDKInitError = createAction(FB_SDK_INIT_ERROR);

export const loginToFBRequest = createAction(LOGIN_TO_FB_REQUEST);
export const loginToFBSuccess = createAction(LOGIN_TO_FB_SUCCESS);
export const loginToFBError = createAction(LOGIN_TO_FB_ERROR);

export const isLoggedInToFBRequest = createAction(IS_LOGGED_IN_TO_FB_REQUEST);
export const isLoggedInToFBSuccess = createAction(IS_LOGGED_IN_TO_FB_SUCCESS);
export const isLoggedInToFBError = createAction(IS_LOGGED_IN_TO_FB_ERROR);

export const logoutFromFBRequest = createAction(LOGOUT_FROM_FB_REQUEST);
export const logoutFromFBSuccess = createAction(LOGOUT_FROM_FB_SUCCESS);
export const logoutFromFBError = createAction(LOGOUT_FROM_FB_ERROR);

export const isStaggerPostRequest = createAction(IS_STAGGER_POST_REQUEST);
export const isStaggerPostSuccess = createAction(IS_STAGGER_POST_SUCCESS);
export const isStaggerPostError = createAction(IS_STAGGER_POST_ERROR);

export const setIsStaggerPostRequest = createAction(SET_IS_STAGGER_POST_REQUEST);
export const setIsStaggerPostSuccess = createAction(SET_IS_STAGGER_POST_SUCCESS);
export const setIsStaggerPostError = createAction(SET_IS_STAGGER_POST_ERROR);

export const fbSDKInit = () => async dispatch => {
  try {
    dispatch(fbSDKInitRequest());
    await InstagramAnalyticsService.initFacebookSDK();
    dispatch(fbSDKInitSuccess());
  } catch (err) {
    dispatch(fbSDKInitError(err));
  }
};

export const loginToFB = () => async dispatch => {
  try {
    dispatch(loginToFBRequest());
    await InstagramAnalyticsService.loginToFB();
    dispatch(loginToFBSuccess());
  } catch (err) {
    dispatch(loginToFBError(err));
  }
};

export const isLoggedInToFB = () => async dispatch => {
  try {
    dispatch(isLoggedInToFBRequest());
    const payload = await InstagramAnalyticsService.isLoggedInToFB();
    dispatch(isLoggedInToFBSuccess(payload));
  } catch (err) {
    dispatch(isLoggedInToFBError(err));
  }
};

export const logoutFromFB = () => async dispatch => {
  try {
    dispatch(logoutFromFBRequest());
    await InstagramAnalyticsService.logoutFromFB();
    dispatch(logoutFromFBSuccess());
  } catch (err) {
    dispatch(logoutFromFBError(err));
  }
};

export const getInstagramPosts = (igBusinessAccountId) => async dispatch => {
  try {
    dispatch(getInstagramPostsRequest());
    const payload = await InstagramAnalyticsService.getPosts(igBusinessAccountId);
    dispatch(getInstagramPostsSuccess(payload));
  } catch (err) {
    dispatch(getInstagramPostsError(err));
  }
};

export const getPostData = (postId) => async dispatch => {
  try {
    dispatch(getPostDataRequest());
    const payload = await InstagramAnalyticsService.getPostData(postId);
    payload.time = format(parseISO(payload.timestamp), 'LLLL dd, yyyy');
    dispatch(getPostDataSuccess(payload));
  } catch (err) {
    dispatch(getPostDataError(err));
  }
};

export const getPostInsights = (postId) => async dispatch => {
  try {
    dispatch(getPostInsightsRequest());
    const payload = await InstagramAnalyticsService.getPostInsights(postId);
    dispatch(getPostInsightsSuccess(payload));
  } catch (err) {
    dispatch(getPostInsightsError(err));
  }
};

export const isStaggerPost = (postId) => async dispatch => {
  try {
    dispatch(isStaggerPostRequest());
    const { data: postExists } = await InstagramPostService.exists(postId);
    dispatch(isStaggerPostSuccess(postExists));
  } catch (err) {
    dispatch(isStaggerPostError(err));
  }
};

const buildIPPPayloadFromState = (username, selectedPost, children) => {
  const images = [];
  if (!children) {
    children = [];
    children.push({
      id: selectedPost.id,
      timestamp: selectedPost.timestamp,
      mediaUrl: selectedPost.mediaUrl,
      shortcode: selectedPost.shortcode,
    });
  }

  children.forEach((c) => {
    images.push({
      createdAt: c.timestamp,
      description: selectedPost.caption,
      fullUrl: c.mediaUrl,
      height: 0,
      smallUrl: `http://instagram.com/p/${c.shortcode}/media?size=m`,
      sourceId: c.id,
      sourcePostId: selectedPost.id,
      thumbUrl: `http://instagram.com/p/${c.shortcode}/media?size=t`,
      updatedAt: c.timestamp,
      width: 0,
    });
  });

  return {
    images,
    sourceUserName: username,
  };
};

export const setIsStaggerPost = (
  accountId,
  post,
  state,
  {
    username,
    children: postChilds,
  },
) => async dispatch => {
  try {
    dispatch(setIsStaggerPostRequest());
    if (state) {
      await InstagramPostService.create({
        accountId,
        post: {
          postId: post.id,
        },
        payload: buildIPPPayloadFromState(username, post, postChilds),
      });
    } else {
      await InstagramPostService.delete(post.id);
    }
    dispatch(setIsStaggerPostSuccess(state));
  } catch (err) {
    dispatch(setIsStaggerPostError(err));
  }
};
