/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => (
  <div className={styles.container}>
    <h1>Stagger Privacy Policy</h1>

    <h2>Last Updated: July 7, 2020</h2>

    <h2>Stagger Inc. Privacy Policy</h2>

    <p>Stagger knows that you take your privacy seriously. We do, too. We intend to be transparent with you about our data practices and to give you control over information we collect about you (your &ldquo;Data&rdquo;), some of which may identify you or from which you may be identified (&ldquo;PII&rdquo;).</p>

    <p>This is the Privacy Policy for Stagger Inc. (&quot;Stagger&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). This Privacy Policy also incorporates Stagger&rsquo;s Terms of Service (available at <Link to={routesPaths.terms}>https://beta.stagger.com/terms-of-service)</Link>.</p>

    <p>Stagger offers the following services:</p>

    <p>Consumer Services, which include our website (www.staggerit.com), our mobile applications (collectively, the &ldquo;Apps&rdquo;), and our features (i.e. attributes that Stagger makes available, such as the ability for users to create lists), content (e.g. tips, reviews, photos that users may provide), and widgets (i.e. applications that allow users to access the Apps) (all, collectively, the &ldquo;Consumer Services&rdquo;); and</p>

    <p>Enterprise Services, which include our media, attribution, analytics, Application Programming Interface (&ldquo;API&rdquo;) and Software Development Kit (&ldquo;SDK&rdquo;) products (collectively, the &ldquo;Enterprise Services&rdquo;).</p>

    <p>This Privacy Policy is divided into four parts:</p>

    <ul>
      <li>Part I applies to Data about users of our Consumer Services (&ldquo;Stagger Users&rdquo;).</li>
      <li>Part II applies to Data provided by customers of our Enterprise Services (&ldquo;Enterprise Customers&rdquo;) about users of Enterprise Customers&rsquo; products (&ldquo;Partner End-users&rdquo;).</li>
      <li>Part III applies to Data about individuals other than Stagger Users and Partner End-users, such as individuals acting in their professional capacity as representatives of a third party developer, vendor, partner or enterprise customer.</li>
      <li>Part IV applies to all Data that we may collect and further process in the circumstances described in all of Parts I, II and III.</li>
    </ul>

    <h3>Part I &ndash; Stagger Users</h3>

    <p>Below are the types of Data we collect about Stagger Users and the ways in which we may collect, use and share that Data.</p>

    <p>What Data Does Stagger Collect</p>

    <p>Data You Provide To Us:</p>

    <p>We receive and store Data that you provide to us when you create an account and use our Consumer Services. When you create an account, we may collect your full name, email address, phone number, birthday, gender, the city in which you created your account, and where applicable, your Twitter, Facebook or Google username and other Data if you create an account using your third party log-in credentials (please see &ldquo;Data Received From Third Parties&rdquo; below). As you interact with us and use our Consumer Services, we may also collect other Data that you directly provide to us, which may include pictures that you choose to upload to your account, any restaurant or other venue searches you make, your likes, who you follow, check-ins, comments or tips you make, whether you have created or edited venues, and any other Data that you voluntarily provide to us.</p>

    <p>Data Collected When You Use Our Consumer Services:</p>

    <p>If you opt-in, we automatically receive Data about your location and/or IP address whenever you interact with, or use, the Consumer Services (e.g. open the App, visit our website, etc.). We use various technologies to determine your location, such as the location services of your operating system or browser, sensor data from your device (e.g. magnetometer or barometer, Bluetooth data, beacon data, Wi-Fi access points, GPS data, and cell tower data), and other Data that may help us to understand your precise location. We may also collect additional Data about you when you use the Consumer Services. For example, we receive Data:</p>

    <p>relating to how you interact with the Consumer Services, such as your search terms, what pages you view, your access times, the time you spend on each page, your IP address, the redirected pages, or any messages you write to other users or post to the Consumer Services;</p>

    <p>relating to your device, such as your hardware model, operating system, unique ID, browser type, language, and wireless network; and</p>

    <p>collected by cookies and other technologies, such as cookie ID, advertising ID or other unique advertising ID, web beacons, local storage and pixels. You can control certain types of cookies through your browser settings or other tools. You may review our Cookie Policy for further information.</p>

    <p>If you use our Apps and have enabled background location services in our Apps, you have permitted us to collect the above Data about you in the background even when you do not have the Apps open (as permitted by your device settings). You may turn off location services at any time by going to your device settings and toggling off background location. For more information about location, please see &ldquo;Determining and Sharing Location&rdquo; below.</p>

    <p>Data Received From Third Parties</p>

    <p>We may receive Data about you from third parties, including Enterprise Customers (e.g. customers of our Media Products) and/or third parties with services that interact with the Consumer Services or that provide services to us, such as unaffiliated apps and websites, e.g., websites and applications offered by third party developers that integrate with our API, advertising exchanges, ad platforms, ad networks or from our partners. This Data may include Data about your activity when using the Consumer Services or on unaffiliated websites and apps, or Data received from web browsers or operating systems such as browser types and settings, the operating systems, device models, carriers, location information, IP address, mobile device and advertising identifiers (e.g. Apple IDFA or Google Advertising ID), as well as cookie information, pixel or other similar technology. If you created your account using your Google, Twitter or Facebook account profile, we may receive certain information from these companies, such as your first name, last name, gender, birthday, email address and your Google, Twitter or Facebook ID.</p>

    <p>THE INFORMATION WE COLLECT</p>

    <p>When you visit the website or use one of our mobile applications, we collect your IP address, and standard web log information, such as your browser type and the pages you accessed on our website. We also may collect certain geo-location information. If you do not agree to our collection of this information, you may not be able to use our Service.</p>

    <p>If you open a Stagger account, we collect the following information from you:</p>

    <p>Account Information - text-enabled cellular/wireless telephone number, machine or mobile device ID and other similar information.</p>

    <p>Identification Information - your name, street address, email address, date of birth, and SSN (or other governmental issued verification numbers).</p>

    <p>Device Information - information about you: (a) from your mobile device such as your device type, machine or mobile device identification number, geo-location, time zone, language setting, browser type, and IP address, and (b) from third parties for purposes of transaction processing, identity verification, fraud detection or prevention and other similar purposes. For fraud prevention purposes, we also may link your machine ID with the machines of others who use your same payment cards.</p>

    <p>We are committed to providing a safe, secure and all around great Service. Therefore, before permitting you to use our Service, we may require additional information from you we can use to verify your identity, address or other information or to manage risk and compliance throughout our relationship. We may also obtain information about you from third parties such as identity verification, fraud prevention and similar services.</p>

    <p>When you are using the Stagger Services, we collect information about your account transactions and we may collect information about your computer or other access device for fraud prevention and other similar purposes.</p>

    <p>Finally, we may collect additional information from or about you in other ways not specifically described here. For example, we may collect information related to your contact with our customer support team or store results when you respond to a survey.</p>

    <p>APPLICABILITY OF PRIVACY POLICY</p>

    <p>This privacy policy applies to all information we collect through our web and mobile applications from current and former Stagger users, including you. When you are no longer our customer, we continue to share your information as described in this policy.</p>

    <p>Throughout this policy, we use the term &quot;personal information&quot; to describe information that can be associated with a specific person and can be used to identify that person. We do not consider personal information to include information that has been aggregated and/or anonymized so that it does not identify a specific user.</p>

    <p>How Is My Data Used?</p>

    <p>Below are the ways that we may use Data about Stagger Users.</p>

    <p>To Operate, Maintain And Improve The Consumer Services</p>

    <p>We may use your Data to operate and maintain the Consumer Services, and to understand you better and tailor the Consumer Services to you. If you download more than one App, we may combine your Data provided in each App. If you use the Consumer Services when you are not logged into your Stagger account, when you subsequently log into your Stagger account, we may combine Data collected while you were not logged in with your Stagger account.</p>

    <p>We may also use your Data for research and development, to improve the Consumer Services, or to offer new products, features and tools, but only to the extent that this is in compliance with applicable law. For example, we may use your Data to customize our Apps and Sites to provide you with your own special experience. We do this by inferring your interests and preferences, looking at your search history or allowing you to find and add friends who are also using the Apps and Sites from your device&rsquo;s contact list or if applicable, your Facebook, Google or Twitter account if you have opted to connect these accounts to your Stagger account. In these situations, we do not store your contact lists.</p>

    <p>We also may use your Data to help us map Locations (as defined below). For example, we may use device signals to determine that a particular device is within a particular venue. Additionally, if you consent to sharing crash data (when the Apps crash) with us, we may use that data to help us analyze the cause of the crash.</p>

    <p>Communicate With You</p>

    <p>We may use your Data to communicate with you, including by email, text (only if you opt-in), or in our Apps, about new product offerings, updates to the Consumer Services, or anything else we think may be of interest to you. You can opt out of receiving marketing and promotional communications here.</p>

    <p>Protection Of Our Rights</p>

    <p>We may use your Data to protect the rights, property or safety of Stagger, the Consumer Services, our users and employees, or any third party. For example, we may use your Data if we need to enforce or apply our Terms of Service or Policies.</p>

    <p>Enterprise Services</p>

    <p>We may use your Data in our Enterprise Services and in other services that we may provide to businesses. For example, when partners use our SDK, we may receive certain Partner End User Data back (e.g., wifi end points, Visit Data, and other Data), including Data about Stagger Users, if the Partner End-Users are also users of our Consumer Services. We may use this Data for our business purposes or to improve the Enterprise Services. As always, you can always opt-out of this type of sharing by disabling your background location services for the Apps. See also &ldquo;Media Products&rdquo; section of Part III below.</p>

    <p>When Is My Data Shared?</p>

    <p>We neither rent nor sell Data about users without their consent unless anonymized, aggregated or pseudonymized. Though, as described more fully in this Privacy Policy, we may license or otherwise provide certain other Data to third parties, provided we have received your consent to do so.</p>

    <p>Data That May Be Shared With Other Users</p>

    <p>When you use the Consumer Services, your authorized friends may see some of your PII, such as your name, email address, phone number associated with your account, profile photo, hometown, mayorships and badges/stickers, and links to your Twitter and Facebook accounts (if you have connected those accounts to your Stagger account), a list of your friends, a map of your check-in history, any content or comments that you post, and which Apps you use. When you communicate on or through the Consumer Services, such as by direct messaging to your friends or checking-in to a business, an establishment, or a location (each a &ldquo;Location&rdquo;), such as a gym or a restaurant, your friends may see this Data, too. You can always control what you want visible to others, including your friends, at any time by clicking on &ldquo;Settings&rdquo; in your account and making your choices.</p>

    <p>Publicly available Data such as the Data available in your user public profile, tips, likes, saves, public photos, lists that you create and follow, tastes, badges/stickers, mayorships, and lists of friends and/or followers may be searchable by search engines and be shared with or disclosed to third parties, including through our Enterprise Services to our partners and customers.</p>

    <p>HOW WE SHARE PERSONAL INFORMATION WITH OTHER PARTIES</p>

    <p>Stagger does not share your personal information with third parties for their promotional or marketing purposes.</p>

    <p>Some personal information is public information (including your Stagger username, Stagger profile photo, Stagger profile first and last name, month, and year of Stagger account creation, and public transactions in which you&apos;ve been involved), and may be seen by anyone on the internet, whether or not they have a Stagger account. Public information may also be seen, accessed, reshared or downloaded through Stagger&apos;s APIs or third-party services that integrate with our products. In addition to any public information, your Stagger friends list may be seen by any logged-in Stagger user.</p>

    <p>HOW YOU CAN ACCESS OR CHANGE YOUR PERSONAL INFORMATION</p>

    <p>You can review and update your personal information in your account settings at any time by logging in to your account.</p>

    <p>How to Remove Background Location Sharing?</p>

    <p>If you use our Apps and have opted-in to sharing your background location with us, you may remove your permission at any time by going into your operating device settings and toggling off background sharing.</p>

    <p>Data That May Be Shared With Third Party Partners And Service Providers</p>

    <p>A key purpose of the Consumer Services is to help users discover and learn about Locations, upcoming events, and brands that are relevant to them, and for these organizations to provide information about themselves, their Locations and events to users, as well as to reward users when they visit their Location or event through offers or specials provided to you in compliance with applicable law. When you visit a Location, your visit may be shared with that Location so they can provide superior products and services to you. Sharing your visit means providing the Location with your user name and profile photo\You can always control what Data you choose to share with Locations by adjusting your preferences in &ldquo;Settings.&rdquo;</p>

    <p>We may also use service providers, vendors and contractors to provide or perform services on our behalf. In these cases, we may need to share your PII with them. Our service providers, vendors and contractors will be subject to binding contractual obligations to: (i) only process the PII in accordance with our prior written instructions; and not use the PII for their own purposes; and (ii) use appropriate measures to protect the confidentiality and security of the PII; together with any additional requirements under applicable law.</p>

    <p>When we partner with a business, we may share Data with them but only in aggregate or in some cases, we may share a non-identifying ID such as an advertising ID or a hashed identifier (&ldquo;pseudonymized&rdquo;). When we share Data in a pseudonymized form, a unique identifier is shared so that our partner can measure conversion as to whether an individual has seen its ad, visited its website, or you went to its brick and mortar location. We may also share or disclose aggregated, pseudonymized, and/or anonymous Data to other third parties, including Stagger Users you may follow, and developers who use our API or SDK, or our other Enterprise Customers, for analytical or other purposes. See also &ldquo;Media Products&rdquo; section of Part III below.</p>

    <p>When You Consent</p>

    <p>Except as permitted under this Privacy Policy, we will only share your PII with your consent. For example:</p>

    <p>If you consent or authorize a third party app to access your Stagger account, we may share your Data with such third party app. You can revoke your consent at any time in your Stagger account profile settings.</p>

    <p>We sometimes enter into relationships with partners to offer contests and promotions through the Consumer Services. For example, we may partner with a brand to have a contest to win a prize and if you enter the contest, we may share your email address and/or other necessary PII with such brand, with your consent. In these cases, we will only share Data that is necessary with our partner.</p>

    <p>Other times</p>

    <p>We may share your Data when we believe in good faith that such release is necessary to comply with law or a legal process, including laws outside your country of residence; to protect our rights, property, or safety of Stagger, our employees, the Consumer Services and others.</p>

    <p>We may share your Data as part or in anticipation of a business sale, merger, consolidation, investment, change in control, transfer of substantial corporate assets, reorganization, liquidation, or similar business transaction or corporate event.</p>

    <p>Determining and Sharing Location</p>

    <p>Some features and functionality in our Apps may require that you provide your location. If you have your location services turned on, whenever you open and use or interact with our Apps (e.g. write a tip or check in to a restaurant) on your mobile device, we collect and use your location data from your mobile device (e.g., latitude and longitude) to tailor the Consumer Services to your current location (i.e. we&rsquo;ll show you a list of nearby locations, and tips). We will only process your location data with your permission. If you have background location data turned on by granting us permission through your device&rsquo;s location settings and in your privacy settings, we will, from time to time, obtain your device&rsquo;s location even if you are not directly interacting with the Apps. Other than in an anonymized, pseudonymized or aggregated form, your location is never shared with others, except with your consent or as permitted under this Privacy Policy.</p>

    <p>Each of your check-ins using the Consumer Services is saved to your &quot;Check-in History,&quot; which allows you and your friends to look back at the locations you have checked in since you created your account. You can delete any of your check-ins from the check-in page in our Apps. If for any reason you ever decide to delete your Data, your account and your Data will be permanently deleted from the Consumer Services.</p>

    <p>What About Cookies and Similar Technologies?</p>

    <p>We may use technologies like cookies, pixels, web beacons, local storage and other identifiers on our Sites, in our emails, within our Apps and sometimes in third party apps that use our API or SDK to provide you with a range of products and services. We use these technologies for a number of purposes, such as for authentication, security, preference settings, advertising and analytics. We sometimes work with advertisers or other partners, and advertising service providers to serve ads to you on the Consumer Services, or a third party website or app, that may be relevant to you based on your interaction with the Consumer Services, including places you have &ldquo;checked in&rdquo;.</p>

    <p>You can control cookies through your browser settings and other tools. For more information, including the types of cookies and similar technologies found on Stagger, please read our Cookie Policy.</p>

    <p>How do I Access or Delete My Data?</p>

    <p>You may access and update your Data at any time through the &ldquo;Settings&rdquo; tab in your Stagger account.</p>

    <p>You may also delete your Data and account at any time on our Sites by clicking on the Delete Your Account link in your Privacy Settings page through your web account. If you delete your Data, your profile, including your check-in history, tips and photos, badges and stickers received will be removed from the Consumer Services, and you will no longer be able to access such Data. We will not be able to restore your account once it has been deleted. Deletion of your account may not be immediate. Residual copies of your PII or posts may remain on backup media for a short period of time thereafter. Additionally, we may store certain Data that is not PII, i.e. does not directly identify you, in our internal systems, which we may use in our Enterprise Services. We may also retain Data as required by applicable law and we will not delete any Data that is not PII, i.e., has already been anonymized.</p>

    <p>If you have questions about accessing, updating, or deleting your Data, please email privacy@staggerit.com</p>

    <h3>Part II &ndash; Partner End-Users</h3>

    <p>Below are the ways in which we may collect, use and share Data about Partner End-Users.</p>

    <p>API or Data Files</p>

    <p>We share Data with third party developers via our API, which may also involve those third party developers agreeing to share Data about Partner End-Users with us. In some cases, they have committed not to share Partner End-User Data with us if it comprises PII or without the Partner End-User consenting to the sharing. We may also share Data with third party developers via flat data files, and may receive a flat data file from developers in return.</p>

    <p>SDK Products</p>

    <p>App developers may partner with us to use our SDK, which they integrate into their own apps to help them identify when Partner End-Users are near interesting places of relevance to their service (e.g. a nearby store with respect to which they can offer a coupon), identify accurate venue data (such as the geo-coordinates of a restaurant or store) or to identify when their end users have visited a specific venue (&ldquo;Visit Data&rdquo;). When partners use our SDK, we may receive certain Partner End-User Data back (e.g. wifi end points, Visit Data, and other Data). We may incorporate such Partner End-User Data into our Enterprise Services, and if contractually permitted, license such Partner End-User Data to business partners. In some cases, if contractually permitted, we may share non-identifying IDs such as an advertising IDs or other pseudonymized data.</p>

    <p>In addition, we may acquire data from third parties and append such information to Partner End-User Data. We will delete Partner End-User Data if requested by our partners.</p>

    <p>In the event that a Partner End-User is also a Stagger User, we may combine all data about the user into a single profile.</p>

    <h3>Part III &ndash; Individuals Who Are Not Stagger Users or Partner End-Users</h3>

    <p>Below are the ways in which we may collect, use and share Data about individuals who are not Stagger Users or Partner End-Users.</p>

    <p>Media Products</p>

    <p>We, our partners, and advertising service providers may receive Data from unaffiliated apps and websites, advertising exchanges, ad platforms, ad networks or from our partners, which we, our partners and advertising service providers may use to send interest-based advertising. The Data collected for this purpose may include Data such as activity when using the Consumer Services or on unaffiliated websites and apps, or Data received from web browsers or operating systems such as browser types and settings, the operating systems, device models, carriers, location information, IP address, mobile device and advertising identifiers (e.g. Apple IDFA or Google Advertising ID), as well as cookie information, pixel or other similar technology.</p>

    <p>We may combine the Data described above with demographic, location and other interest-based segment Data from third parties to provide the Enterprise Services.</p>

    <p>When we partner with a business, PII is not shared with them. We will only share Data in aggregate or in some cases, pseudonymized form. When we share Data in a pseudonymized form, a unique identifier, such as an Advertising ID, is shared so that our partner can measure conversion as to whether an individual has seen its ad, visited its website, or visited their brick and mortar location.</p>

    <p>Other</p>

    <p>We may collect Data about individuals acting in their professional capacity as a representative of a third party developer, vendor, partner or enterprise customer. We may collect their names, job titles, phone numbers, email addresses and/or billing addresses (of their organization), so that we can contact and interact with these organizations in relation to the contracting process, billing, and/or promotions. We do not combine Data from these individuals with any Data about users of our Consumer Services.</p>

    <p>In the case of developers who use our API or SDK, we also collect Data that developers provide on their developer account profiles, as well as certain Data that we process when a developer uses his or her account (such as time of use and device information).</p>

    <p>We may share Data we collect about these individuals with service providers, vendors and contractors to provide or perform services on our behalf, such as providers that help us manage customer relationships, sales, and marketing for Stagger or may, in some cases, share Data about these individuals with our Enterprise Customers. Our service providers, vendors and contractors will be subject to binding contractual obligations to: (i) only process this Data in accordance with our prior written instructions, and not use the Data for their own purposes; (ii) use measures to protect the confidentiality and security of the Data; (iii) comply with additional requirements under applicable law. We may also share this Data when we believe in good faith that such release is necessary to comply with law or a legal process, including laws outside the individual&rsquo;s country of residence; to protect our rights, property, or safety of Stagger, our employees, the Consumer Services and others. We may also share this Data as part or in anticipation of a business sale, merger, consolidation, investment, change in control, transfer of substantial corporate assets, reorganization, liquidation, or similar business transaction or corporate event.</p>

    <h3>Part IV &ndash; Individuals Described In All of Parts I, II and III.</h3>

    <p>This Part IV applies to Data about individuals we may collect in the circumstances described in all of Parts I, II and III.</p>

    <p>Your account is protected by a password that you have chosen. We recommend using a password that is unique to your Stagger account. If you use the Consumer Services on public or shared networks (e.g. public wifi), we cannot ensure the security of your account.</p>

    <p>Children under 13</p>

    <p>Neither the Consumer Services nor the Enterprise Services are directed to individuals who are under 13 years of age. We do not knowingly collect any Data from children under 13. If you notice or suspect that a user is under 13, please contact us immediately at privacy@staggerit.com.</p>

    <p>PII Retention</p>

    <p>The criteria for determining the duration for which we will keep your PII are as follows: we will retain copies of your PII only for as long as is necessary in connection with the purposes set out in this Privacy Policy, unless applicable law requires a longer retention period. In addition, we may retain your PII for the duration of any period necessary to establish, exercise or defend any legal rights.</p>

    <p>You have the right to access your PII. In compliance with the Principles, Stagger commits to resolve complaints about our collection or use of your PII.</p>

    <p>Changes to this Privacy Policy?</p>

    <p>Stagger may amend this Privacy Policy from time to time. We encourage you to regularly check this page to review any changes we might make in accordance with this Privacy Policy. If we make material changes in the way we use your Data, we will notify you by posting an announcement on our Sites or Apps, or by sending you an email (if we have your email). If permitted by law, users are bound by any changes to this Privacy Policy when they use the Consumer Services and Enterprise Services after such changes have been first posted.</p>

    <p>LINKS TO OTHER SITES</p>

    <p>The website or mobile application may contain links to other third-party websites. Stagger does not control the information collection of third-party websites that can be reached through links from the website. We encourage our users to be aware when they are leaving the website and to read the privacy statements of any third-party website that collects personally identifiable information.</p>

    <p>HOW WE USE COOKIES</p>

    <p>When you visit our website, use our mobile application or the Stagger Services, or visit a third-party website for which we provide online services, we and certain business partners and vendors may use cookies and other tracking technologies (collectively, &quot;Cookies&quot;). We use Cookies to recognize you as a customer; customize Stagger Services, other content and advertising; measure the effectiveness of promotions; perform a wide range of analytics; mitigate risk and prevent potential fraud; and to promote trust and safety across our sites and Stagger Services.</p>

    <p>Certain aspects and features of the Stagger Services and our website are only available through the use of Cookies, so if you choose to disable or decline Cookies, your use of the websites and Stagger Services may be limited or not possible.</p>

    <p>Do Not Track: Do Not Track (&quot;DNT&quot;) is an optional browser setting that allows you to express your preferences regarding tracking by advertisers and other third-parties. We do not respond to DNT signals.</p>

    <p>HOW WE PROTECT &amp; STORE PERSONAL INFORMATION</p>

    <p>We store and process your personal information using third party servers located in data centers in the United States. This information is protected by physical, electronic and procedural safeguards in compliance with applicable US federal and state regulations. We also use computer safeguards such as firewalls and data encryption, we enforce physical access controls to our office and files, and we authorize access to personal information only for those employees who require it to fulfill their job responsibilities.</p>

    <p>We strive to ensure security on our systems. Despite our efforts, we cannot guarantee that personal information may not be accessed, disclosed, altered or destroyed by breach of our administrative, managerial and technical safeguards. Therefore, we urge you to take adequate precautions to protect your personal data as well, including never sharing your Stagger password with anyone.</p>

    <p>If Stagger learns of a systems security breach, we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Stagger Services, you agree that Stagger may communicate with you electronically. Stagger may post a notice on the website or mobile application if a security breach occurs. We may also send an email to you at the email address you have provided to us. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice of a security breach), please email us at privacy@staggerit.com</p>

    <p>HOW WE USE THE PERSONAL INFORMATION WE COLLECT</p>

    <p>Our primary purpose in collecting personal information is to provide you with a safe, smooth, efficient, fun and customized experience. We may use your personal information to:</p>

    <p>provide the services and customer support you request;</p>

    <p>process transactions and send notices about your transactions or your network activity;</p>

    <p>resolve disputes, collect fees, and troubleshoot problems;</p>

    <p>prevent potentially fraudulent, prohibited or illegal activities, and enforce our User Agreement;</p>

    <p>create an account connection between your Stagger account and a third-party account or platform;</p>

    <p>customize, personalize, measure, and improve our services and the content and layout of our website;</p>

    <p>send you updates about new products and services that we are offering to customers;</p>

    <p>compare information for accuracy and verify it with third parties; and</p>

    <p>perform other duties as required by law.</p>

    <p>HOW WE SHARE PERSONAL INFORMATION WITHIN THE Stagger NETWORK</p>

    <p>To process payments on Stagger, we need to share some of your personal information with the person or company that you are paying or is paying you. Your contact information, date of sign-up, the number of payments you have received and other verification metrics like social graph activity may be provided to users or companies when you transact with, on, or through Stagger.</p>

    <p>We work with vendors to enable them to accept payments from you using Stagger. In doing so, a vendor may share information about you with us, such as your mobile phone number or Stagger username, when you attempt to pay that vendor. We use this information to confirm to that vendor that you are a Stagger customer and that the vendor should enable Stagger as a form of payment for your purchase.</p>

    <p>Regardless, we will not disclose your credit card number or bank account number to anyone you have paid or who has paid you through Stagger, except with your express permission or if we are required to do so to comply with a subpoena or other legal process.</p>

    <h3>CHANGES TO OUR PRIVACY POLICY</h3>

    <p>Stagger is always improving. As the Stagger Services evolve we may occasionally update this privacy policy. If we modify this privacy policy, we will post the revised privacy policy to the website, and we will also revise the &quot;last updated date&quot; stated above. If we make material changes in the way we use personal information, we will notify you by posting an announcement on our mobile application or website or by sending you an e-mail. It is your responsibility to periodically review this privacy policy; users are bound by any changes to the privacy policy by using the Stagger Services after such changes have been first posted.</p>

    <h3>HOW TO CONTACT US</h3>

    <p>If you have questions or concerns regarding this privacy policy, or any feedback pertaining to your privacy and the Stagger Services that you would like us to consider, please email us at privacy@staggerit.com.</p>

    <p>Effective Date: July 7, 2020</p>

    <Link to={routesPaths.projects} className={styles.goBack}>
      Back to projects
    </Link>
  </div>
);

export { PrivacyPolicy };
