import React from 'react';
import { Redirect } from 'react-router';

import { routesPaths } from 'src/routes/routesPaths';
import { useSession } from 'src/hooks';

const Home = () => {
  const { authenticated } = useSession();

  if (authenticated) {
    return <Redirect to={routesPaths.projects} />;
  }
  window.location.href = 'https://www.stagger.com';
  return null;
};

export { Home };
