import { HttpClient } from '../clients';

class TemplateService {
  static fetchAllTemplatesFromCategory(accountId, id, page, perPage) {
    return HttpClient.get(`/templates/categories/${id}`, { params: { accountId, page, perPage } });
  }

  static createTemplate(data, projectId, userId) {
    return HttpClient.post('/templates', { ...data, projectId, userId });
  }

  static updateTemplate(id, template, projectId, userId) {
    return HttpClient.put(`/templates/${id}`, { template, projectId, userId });
  }

  static updateDataTemplate(id, template) {
    return HttpClient.put(`/templates/${id}`, { template });
  }

  static getTemplate(id) {
    return HttpClient.get(`/templates/${id}`);
  }
}

export { TemplateService };
