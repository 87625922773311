import React, { useCallback } from 'react';
import { func, oneOf, shape, string, number } from 'prop-types';

import { TEXT_ALIGN } from 'src/constants/general';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import { TextAlignment } from 'src/common/text-alignment';
import styles from './TextAlignmentControl.module.scss';

const TextAlignmentControl = ({
  alignment,
  changeAlignment,
  groupClassName,
  scrollPosition,
}) => {
  const containerId = 'textAlignmentSelect';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  const selectStyles = useCallback(() => ({
    left: optionsSize.left,
    right: 'unset',
  }), [optionsSize]);

  return (
    <div className={groupClassName} id={containerId}>
      <TextAlignment
        alignment={alignment}
        changeAlignment={changeAlignment}
        className={styles.container}
        optionsStyle={selectStyles()}
      />
    </div>
  );
};

TextAlignmentControl.propTypes = {
  changeAlignment: func.isRequired,
  groupClassName: string,
  alignment: oneOf(Object.values(TEXT_ALIGN)),
  scrollPosition: shape({
    x: number,
  }),
};

export { TextAlignmentControl };
