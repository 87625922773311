import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { IMAGE_ELEMENT } from 'src/constants/canvasElements';
import { useStageCenteredPosition } from 'src/hooks';
import { addMediaElements } from 'src/actions/projectActions';

const usePasteImageInCanvas = () => {
  const dispatch = useDispatch();

  const position = useStageCenteredPosition();

  const addImageInCanvas = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        const src = URL.createObjectURL(file);
        const uuid = uuidv4();
        const img = {
          src,
          id: uuid,
          uploadType: LOCAL_UPLOAD,
          width: image.width,
          height: image.height,
          file,
          type: IMAGE_ELEMENT,
        };
        dispatch(addMediaElements({ media: [img], x: position.x, y: position.y }));
      };
    };
    reader.readAsDataURL(file);
  };

  return { addImageInCanvas };
};

export { usePasteImageInCanvas };
