import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useStageCenteredPosition } from 'src/hooks';
import { getTextCombinations } from 'src/actions/canvasActions';
import { AddTextCombination } from './add-text-combination';
import styles from './TextCombinations.module.scss';

const TextCombinations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTextCombinations());
  }, [dispatch]);

  const { textCombinations } = useSelector(({ canvas }) => ({
    textCombinations: canvas.textCombinations,
  }));

  const position = useStageCenteredPosition();

  return (
    <div className={styles.content}>
      {textCombinations.map(text => (
        <AddTextCombination {...text} position={position} key={text.id} />
      ))}
    </div>
  );
};

export { TextCombinations };
