import React from 'react';
import { func, string, bool, object } from 'prop-types';
import cn from 'classnames';

import { Tooltip } from 'src/common/tooltip';
import { useTooltip } from 'src/hooks';
import styles from './ButtonTooltip.module.scss';

const ButtonTooltip = ({
  text,
  onClick,
  icon = '',
  className = '',
  alt = '',
  disabled = false,
  buttonText = '',
  tooltipClassName,
  tooltipStyle,
  ignoreTooltip = false,
  iconId,
}) => {
  const { ref, showTooltip } = useTooltip();

  return (
    <button
      className={cn(styles.button, className)}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      {buttonText || <img src={icon} alt={alt} id={iconId} />}
      {showTooltip && !ignoreTooltip && (
        <Tooltip className={tooltipClassName} style={tooltipStyle}>{text}</Tooltip>
      )}
    </button>
  );
};

ButtonTooltip.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  icon: string,
  className: string,
  tooltipStyle: object,
  alt: string,
  disabled: bool,
  buttonText: string,
  tooltipClassName: string,
  ignoreTooltip: bool,
  iconId: string,
};

export { ButtonTooltip };
