import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';

import { GOOGLE_UPLOAD } from 'src/constants/uploadFile';
import EditIcon from 'src/assets/icons/edit.svg';
import { parseFontStyle } from 'src/utils/helpers';
import { EditFont } from '../edit-font';
import { DeleteFont } from '../delete-font';
import styles from './Font.module.scss';

const Font = ({
  id,
  name,
  fontFamily,
  fontStyle: fontStyleComplete,
  fontSize,
  lineHeight,
  letterSpacing,
  hex,
  alpha,
  uploadType,
  align,
  isEditing,
  setEditOpen,
}) => {
  const { fontWeight, fontStyle } = parseFontStyle(fontStyleComplete);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={styles.font}
        onMouseEnter={() => !isEditing && setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        <div className={styles.content}>
          <span
            className={styles.name}
            style={{ color: hex, opacity: alpha / 100, fontFamily, fontWeight, fontStyle }}
          >
            {name}
          </span>
          {isEditing ? (
            <EditFont
              defaultValues={{
                name,
                fontFamily,
                fontStyle: fontStyleComplete,
                fontSize,
                color: { hex, alpha },
                uploadType: uploadType || GOOGLE_UPLOAD,
                align,
                lineHeight,
                letterSpacing,
              }}
              fontId={id}
              onClose={() => setEditOpen()}
            />
          ) : (
            <span className={styles.characteristics}>
              {fontFamily}, {fontSize}px, {hex}
            </span>
          )}
        </div>
        {showDelete && (
          <button
            className={styles.delete}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </button>
        )}
      </div>
      {!isEditing && (
        <button onClick={() => setEditOpen(id)} className={styles.editButton}>
          <img src={EditIcon} alt="Edit" />
        </button>
      )}
      {deleteModalOpen && (
        <DeleteFont
          isShowing={deleteModalOpen}
          hide={() => setDeleteModalOpen(false)}
          fontId={id}
          uploadType={uploadType || GOOGLE_UPLOAD}
        />
      )}
    </div>
  );
};

Font.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  hex: string.isRequired,
  alpha: number.isRequired,
  fontFamily: string.isRequired,
  fontStyle: string.isRequired,
  fontSize: number.isRequired,
  lineHeight: number.isRequired,
  letterSpacing: number,
  isEditing: bool.isRequired,
  setEditOpen: func.isRequired,
  uploadType: string,
  align: string.isRequired,
};

export { Font };
