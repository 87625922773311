import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

import { AddNewCategoryInput } from './add-new-category-input';
import styles from './AddNewCategory.module.scss';

const AddNewCategory = ({ addCategory }) => {
  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const [writeMode, setWriteMode] = useState();

  const onClickAdd = () => {
    setWriteMode(true);
  };

  const onCreatedSuccess = () => {
    setWriteMode(false);
  };

  return (
    writeMode ? (
      <AddNewCategoryInput
        defaultName=""
        addCategory={addCategory}
        onCreatedSuccess={onCreatedSuccess}
        disabled={disabled}
        placeholder="New Category"
      />
    ) : (
      <button className={styles.addButton} disabled={disabled} onClick={onClickAdd}>
        + Add New Category
      </button>
    )
  );
};

AddNewCategory.propTypes = {
  addCategory: func.isRequired,
};

export { AddNewCategory };
