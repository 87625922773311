import React, { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ToggleSwitch } from 'src/common/toggle-switch';
import { Navbar } from 'src/common/navbar';
import { Spinner } from 'src/common/spinner';
import { routesPaths } from 'src/routes/routesPaths';
import {
  getPersonalInformation,
  GET_PERSONAL_INFORMATION,
  CREATE_FREE_TRIAL,
} from 'src/actions/userActions';
import { reset } from 'src/actions/statusActions';
import { LOADING, SUCCESS } from 'src/constants/status';
import { useSession, useStatus } from 'src/hooks';
import { PlanCard } from './plan-card';
import {
  PLAN_CASUAL_CREATOR_YEARLY,
  PLAN_CASUAL_CREATOR_MONTHLY,
  PLAN_CORE_CREATOR_YEARLY,
  PLAN_CORE_CREATOR_MONTHLY,
  PLAN_PERFORMANCE_CREATOR_YEARLY,
  PLAN_PERFORMANCE_CREATOR_MONTHLY,
} from './PlansAvailable';
import styles from './ChoosePlan.module.scss';

const ChoosePlan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location: { state } } = history;

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    dispatch(getPersonalInformation());

    return () => {
      dispatch(reset(GET_PERSONAL_INFORMATION));
    };
  }, [dispatch]);

  const { user: { membership, customerId } } = useSession();

  const { status } = useStatus(GET_PERSONAL_INFORMATION);

  const pathname = state?.pathname || routesPaths.projects;

  const handleSwitch = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const planType = checked ? 'year' : 'month';

  const { status: freeTrialStatus } = useStatus(CREATE_FREE_TRIAL);

  useEffect(() => {
    if (freeTrialStatus === SUCCESS) {
      dispatch(reset(CREATE_FREE_TRIAL));
      history.push(pathname);
    }
  }, [dispatch, freeTrialStatus, history, pathname]);

  if (status === SUCCESS && membership.subscription && customerId) {
    return <Redirect to={routesPaths.projects} />;
  }

  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.content}>
        {status === LOADING ? (
          <Spinner
            iconClassName={styles.spinnerIcon}
            containerClassName={styles.spinnerContainer}
          />
        ) : (
          <>
            <div className={styles.header}>
              <h1 className={styles.title}>Plans</h1>
              <div className={styles.planSwitch}>
                <span className={cn({ [styles.opacity]: checked })}>
                  Monthly
                </span>
                <ToggleSwitch checked={checked} onChange={handleSwitch} />
                <span className={cn({ [styles.opacity]: !checked })}>
                  Yearly
                </span>
              </div>
            </div>
            <div className={styles.plans}>
              <PlanCard
                className={styles.casualTop}
                pathname={pathname}
                planMembership={checked ? PLAN_CASUAL_CREATOR_YEARLY : PLAN_CASUAL_CREATOR_MONTHLY}
                type={planType}
              />
              <PlanCard
                isMainContainer
                pathname={pathname}
                className={styles.coreTop}
                planMembership={checked ? PLAN_CORE_CREATOR_YEARLY : PLAN_CORE_CREATOR_MONTHLY}
                type={planType}
                buttonText={!membership.subscription ?
                  'Start 30-day Free Trial' :
                  undefined}
                freeTrial={!membership.subscription}
              />
              <PlanCard
                className={styles.performanceTop}
                pathname={pathname}
                planMembership={checked ?
                  PLAN_PERFORMANCE_CREATOR_YEARLY :
                  PLAN_PERFORMANCE_CREATOR_MONTHLY}
                type={planType}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { ChoosePlan };
