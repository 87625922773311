import React, { useState, useCallback, forwardRef, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func, bool } from 'prop-types';

import { useForm, useTextInputProps, useSession } from 'src/hooks';
import { TextArea } from 'src/common/text-area';
import { saveOnboardingStep } from 'src/actions/accountActions';
import { checkForChanges } from 'src/utils/formHelpers';
import { StepsSelect } from '../steps-select';
import styles from './Step3MainCTA.module.scss';

const Step3MainCTA = forwardRef(({ setFormIsDirty = () => {}, fromProfile = false }, ref) => {
  const dispatch = useDispatch();

  const { onboarding: { mainCTA }, accountId } = useSession();

  const initialValues = useMemo(() => ({
    wantAudienceTo: mainCTA?.wantAudienceTo,
    wantAudienceToOther: mainCTA?.wantAudienceToOther,
  }), [mainCTA]);

  const [showOthers, setShowOthers] = useState(false);

  const saveOnboardingStepRequest = useCallback(async (values) => {
    await dispatch(saveOnboardingStep(3, values, accountId));
  }, [dispatch, accountId]);

  const {
    setValues,
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(saveOnboardingStepRequest, { initialValues });

  useEffect(() => {
    setValues(initialValues);
  }, [setValues, initialValues]);

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const selectOnChange = useCallback((name, value) => {
    if (value === 'Other [fill in]') {
      setShowOthers(true);
    } else {
      setShowOthers(false);
      handleInputChange('wantAudienceToOther', null);
    }
    handleInputChange(name, value);
  }, [handleInputChange]);

  useEffect(() => {
    const haveChanges = checkForChanges(initialValues, values);
    setFormIsDirty(haveChanges);
  }, [setFormIsDirty, initialValues, values]);

  return (
    <div className={styles.step3container}>
      {!fromProfile &&
        <div className={styles.headerInfo}>What action do you want your audience to take?</div>}
      <form onSubmit={handleSubmit} ref={ref}>
        <div className={styles.inputWantContainer}>
          <StepsSelect
            name="wantAudienceTo"
            label="I want them to"
            options={[
              'Convert to website',
              'Follow social link',
              'Share story',
              'Attend an event',
              'Other [fill in]',
            ]}
            placeholder="Choose an action"
            onChange={selectOnChange}
            value={values.wantAudienceTo}
          />
        </div>
        <div className={styles.otherPlaceHolder}>
          {(values.wantAudienceToOther || showOthers) && (
          <TextArea
            name="wantAudienceToOther"
            placeholder=""
            inputBox
            {...inputProps('wantAudienceToOther')}
            containerClassName={styles.inputOtherContainer}
          />
          )}
        </div>
      </form>
    </div>
  );
});

Step3MainCTA.propTypes = {
  setFormIsDirty: func,
  fromProfile: bool,
};

export { Step3MainCTA };
