import {
  getProjectReset,
  getProjectSuccess,
} from 'src/actions/projectActions';
import {
  setImageSourceInMemory,
  setFontInMemory,
  addColorToMemory,
} from 'src/actions/projectMemoryActions';
import { logout } from 'src/actions/userActions';
import createReducer from './createReducer';

const MAX_COLORS_LENGTH = 16;

const initialState = {
  imageSource: null,
  font: undefined, // fontFamily, fontStyle, uploadType, fontFamilyUrl

  // Saved in backend
  colors: [],
};

const actionHandlers = {
  [getProjectSuccess]: (state, { payload: { project } }) => {
    state.colors = project.memory?.colors || [];
  },
  [setImageSourceInMemory]: (state, { payload: { imageSource } }) => {
    state.imageSource = imageSource;
  },
  [setFontInMemory]: (state, { payload: { font } }) => {
    state.font = font;
  },
  [addColorToMemory]: (state, { payload: { color } }) => {
    const colorIndex = state.colors.findIndex(resColor => resColor.hex === color.hex &&
      resColor.alpha === color.alpha);
    if (colorIndex >= 0) {
      const element = state.colors[colorIndex];
      state.colors.splice(colorIndex, 1);
      state.colors.splice(0, 0, element);
    } else if (state.colors.length < MAX_COLORS_LENGTH) {
      state.colors = [color, ...state.colors];
    } else {
      state.colors.splice(MAX_COLORS_LENGTH - 1, 1);
      state.colors = [color, ...state.colors];
    }
  },
  [getProjectReset]: () => initialState,
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
