export const getMembershipAccount =
(user, accountId) => user?.membership?.accounts?.find(a => a.id === accountId);

export const getTeamAccount = (teams, accountId) => teams?.find(t => t.account.id === accountId);

export const isSubscriptionActive = (user, accountId) => {
  const membershipAccount = getMembershipAccount(user, accountId);
  const teamAccount = getTeamAccount(user?.teams, accountId);

  return !!(membershipAccount
    ? user.membership.subscription?.active
    : teamAccount?.ownerSuscriptionActive);
};

export const getMembershipPlan = (user, accountId) => {
  const membershipAccount = getMembershipAccount(user, accountId);
  const teamAccount = getTeamAccount(user?.teams, accountId);

  return membershipAccount
    ? user.membership.plan
    : teamAccount?.ownerMembershipPlan;
};
