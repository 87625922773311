import React, { useCallback, useState } from 'react';
import { number, string, func } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/button';
import { Input } from 'src/common/input';
import { deleteAccount, DELETE_ACCOUNT } from 'src/actions/accountActions';
import { useStatus } from 'src/hooks';
import { LOADING } from 'src/constants/status';
import styles from './DeleteAccountConfirmation.module.scss';

const DeleteAccountConfirmation = ({ id, brandName, onCancel }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState();

  const { status: statusDelete } = useStatus(DELETE_ACCOUNT);

  const onDelete = useCallback(() => {
    dispatch(deleteAccount(id));
  }, [dispatch, id]);

  return (
    <>
      <span className={styles.subtitle}>
        To confirm, type the name of the account you want to delete.
      </span>
      <Input
        name="name"
        label="Account name"
        containerClassName={styles.inputContainer}
        inputClassName={styles.input}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          onClick={onDelete}
          alternativeCTA
          disabled={name !== brandName}
          loading={statusDelete === LOADING}
        >
          Delete
        </Button>
        <Button
          className={styles.buttonDelete}
          onClick={onCancel}
          disabled={statusDelete === LOADING}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

DeleteAccountConfirmation.propTypes = {
  id: number.isRequired,
  brandName: string.isRequired,
  onCancel: func.isRequired,
};

export { DeleteAccountConfirmation };
