import React, { useEffect, useState, useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { string } from 'prop-types';

import { LEFT } from 'src/constants/general';
import { MenuArrow } from '../menu-arrow';

const LeftArrow = ({ className }) => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isFirstItemVisible,
  );

  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <MenuArrow
      disabled={disabled}
      onClick={() => scrollPrev()}
      direction={LEFT}
      className={className}
    />
  );
};

LeftArrow.propTypes = {
  className: string,
};

export { LeftArrow };
