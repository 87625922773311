import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useStatus } from 'src/hooks';
import {
  getCurrentLocation,
  GET_CURRENT_LOCATION,
  getProjectsWithin,
  GET_PROJECTS_WITHIN,
  findAccountByIds,
  FIND_ACCOUNT_BY_IDS,
  getAllPublicProjects,
  GET_ALL_PUBLIC_PROJECTS,
} from 'src/actions/publicPagesActions';
import { SUCCESS, ERROR } from 'src/constants/status';
import { trackProjectViewedFromQR } from 'src/utils/analytics';
import { Button } from 'src/common/button';
import LocationIcon from 'src/assets/icons/public-location.svg';
import { getBrowserName } from 'src/utils/helpers';
import { BROWSERS } from 'src/constants/general';
import { NotFound } from 'src/pages/not-found/NotFound';
import styles from './Closest.module.scss';

const GeoLocationPermissions = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
};

const WAIT_UNTIL_ASK_FOR_LOCATION = 2000;

const Closest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, accountId } = useParams();

  const { onRadiusProjects, account } = useSelector(({ publicPages }) => ({
    onRadiusProjects: publicPages.onRadiusProjects,
    account: publicPages.account,
  }));

  useEffect(() => {
    if (!account) {
      dispatch(findAccountByIds(userId, accountId));
    }
  }, [dispatch, userId, accountId, account]);

  const [browser, setBrowser] = useState(BROWSERS.OTHERS);
  useEffect(() => {
    setBrowser(getBrowserName());
  }, []);

  const enableLocation = useCallback(() => {
    dispatch(getCurrentLocation());
  }, [dispatch]);

  const { status: findAccountStatus } = useStatus(FIND_ACCOUNT_BY_IDS);
  const [showingInstructions, setShowingInstructions] = useState(false);

  useEffect(() => {
    let timeOutId = null;
    if (findAccountStatus === SUCCESS) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === GeoLocationPermissions.GRANTED) {
            enableLocation();
          } else if (result.state === GeoLocationPermissions.PROMPT) {
            timeOutId = setTimeout(enableLocation, WAIT_UNTIL_ASK_FOR_LOCATION);
          } else if (result.state === GeoLocationPermissions.DENIED) {
            setShowingInstructions(true);
          }
          result.onchange = () => {
            if (result.state === GeoLocationPermissions.DENIED) {
              history.push(account.publicRelUrl);
            }
          };
        });
      } else {
        timeOutId = setTimeout(enableLocation, WAIT_UNTIL_ASK_FOR_LOCATION);
      }
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, [account, enableLocation, findAccountStatus, history]);

  const { status: locationStatus, statusInfo: locationInfo } = useStatus(GET_CURRENT_LOCATION);

  useEffect(() => {
    if (locationStatus === SUCCESS) {
      const { latitude, longitude } = locationInfo;
      const radius = 100;
      dispatch(getProjectsWithin(accountId, latitude, longitude, radius));
      dispatch(getAllPublicProjects(accountId, latitude, longitude));
    }
  }, [dispatch, locationStatus, locationInfo, accountId]);

  const { status: withinProjectsStatus } = useStatus(GET_PROJECTS_WITHIN);
  const { status: allProjectsStatus } = useStatus(GET_ALL_PUBLIC_PROJECTS);

  useEffect(() => {
    if (withinProjectsStatus === SUCCESS && allProjectsStatus === SUCCESS) {
      const selectedProject = onRadiusProjects[0];
      if (selectedProject) {
        trackProjectViewedFromQR(selectedProject.id);

        history.push(`${account.publicRelUrl}?pjid=${selectedProject.id}`);
      } else {
        history.push(`${account.publicRelUrl}`);
      }
    }
  }, [dispatch, withinProjectsStatus, onRadiusProjects, history, allProjectsStatus, account]);

  useEffect(() => {
    if (locationStatus === ERROR) {
      if (locationInfo.code === 1) { // User denied GeoLocation
        setShowingInstructions(true);
      } else if (account) {
        history.push(account.publicRelUrl);
      }
    }
  }, [locationStatus, locationInfo, account, history]);

  if (findAccountStatus === ERROR) {
    return <NotFound />;
  }

  const gotoPublicProfile = () => {
    if (account) {
      history.push(account.publicRelUrl);
    }
  };

  return (
    <div className={styles.container}>
      {!showingInstructions && (
      <Button
        onClick={enableLocation}
        className={styles.enableButton}
        alternativeCTA
      >
        Enable Location
      </Button>
      )}
      <p className={styles.locationText}>
        Stagger will show you the flyer closest to your location.
      </p>
      {showingInstructions
        ? (
          <>
            <div className={styles.instructions}>
              <hr />
              {browser === BROWSERS.SAFARI && (
                <ul>
                  <li>Launch the Phone Settings app on your device.</li>
                  <li>Open the <b>Privacy</b> menu and choose the Location Services sub-menu.</li>
                  <li>Enable the <b>Location Services</b> toggle.</li>
                  <li>Scroll down on the <b>Location Services</b> page,
                  and select the <b>Safari</b> app from the list.
                  </li>
                  <li>Choose the appropriate desired option from &ndash;
                  Never, Ask Next Time, or While Using the App.
                  </li>
                </ul>
              )}
              {browser === BROWSERS.CHROME && (
                <ul>
                  <li>Launch the <b>Chrome</b> browser on your device.</li>
                  <li>
                    To the left of the address bar, tap Lock&nbsp;
                    <span role="img" aria-label="lock">&#128274;</span> &rsaquo; <b>Permissions</b>.
                  </li>
                  <ul>
                    <li>To change a setting, select it.</li>
                    <li>To clear the site&apos;s settings, tap Reset permissions.</li>
                  </ul>
                </ul>
              )}
              {browser === BROWSERS.OTHERS && (
                <>
                  <p>We couldn&apos;t detect your browser.</p>
                  <ul>
                    <li>
                      Open your browser <b>documentation</b> or Google it.
                    </li>
                    <li>
                      Look up how to allow or block&nbsp;
                      <b>Geolocation permission</b> for a specific site.
                    </li>
                    <li>
                      Follow the steps provided by your browser&apos;s vendor.
                    </li>
                  </ul>
                </>
              )}
            </div>
            <Button
              onClick={gotoPublicProfile}
              className={styles.viewProfileButton}
              alternativeCTA
            >
              View profile
            </Button>
          </>
        )
        : <img src={LocationIcon} alt="Enable location" className={styles.locationIcon} />}
    </div>
  );
};

export { Closest };
