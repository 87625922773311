import React from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import styles from './CategoryOption.module.scss';

const CategoryOption = ({ name, selected, onClickCategory }) => (
  <button
    className={cn(styles.option, { [styles.active]: selected })}
    onClick={onClickCategory}
  >
    {name}
  </button>
);

CategoryOption.propTypes = {
  name: string.isRequired,
  selected: bool.isRequired,
  onClickCategory: func.isRequired,
};

export { CategoryOption };
