import React, { useCallback, useState } from 'react';

import { useSession } from 'src/hooks';
import { Button } from 'src/common/button';
import { PLAN } from 'src/constants/memberships';
import { RestrictionModal } from 'src/pages/profile/delete-user/restriction-modal';
import { ConfirmationModal } from 'src/pages/profile/delete-user/confirmation-modal';
import styles from './DeleteUser.module.scss';

const DeleteUser = () => {
  const { user } = useSession();

  const [restrictionModalOpen, setRestrictionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onDelete = useCallback(() => {
    if (user.membership.plan === PLAN.PERFORMANCE) {
      setRestrictionModalOpen(true);
    } else {
      setConfirmationModalOpen(true);
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <div className={styles.headerInfo}>Delete Full Account</div>
      <div className={styles.content}>
        <span className={styles.text}>
          Deleting your account deletes all of your data and existing projects.
        </span>
        <Button
          className={styles.button}
          onClick={onDelete}
        >
          Delete Account
        </Button>
      </div>
      {restrictionModalOpen && (
        <RestrictionModal
          isShowing={restrictionModalOpen}
          hide={() => setRestrictionModalOpen(false)}
        />
      )}
      {confirmationModalOpen && (
        <ConfirmationModal
          isShowing={confirmationModalOpen}
          hide={() => setConfirmationModalOpen(false)}
        />
      )}
    </div>
  );
};

export { DeleteUser };
