import React from 'react';
import { string, number, arrayOf, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { ProjectCard } from 'src/pages/public-pages/account-profile/project-card';
import styles from './ProjectList.module.scss';

const ProjectList = ({ projects }) => {
  const {
    selectedProject,
  } = useSelector(({ publicPages }) => ({
    selectedProject: publicPages.selectedProject,
  }));

  return (
    <div className={styles.projectList}>
      {projects?.map((project) => (
        selectedProject?.id !== project.id && (
        <div className={styles.projectCardContainer} key={project.id}>
          <ProjectCard {...project} />
        </div>
        )
      ))}
    </div>
  );
};

ProjectList.propTypes = {
  projects: arrayOf(
    shape({
      id: number,
      name: string,
      thumbnail: string,
    }),
  ),
};

export { ProjectList };
