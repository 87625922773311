import {
  getKeywordsSuccess,
  getCategoriesSuccess,
  setSelectedTemplate,
} from 'src/actions/productTemplateActions';
import { logout } from 'src/actions/userActions';
import createReducer from './createReducer';

const initialState = {
  categoryNames: {},
  keywordsTags: {},
  selectedTemplate: undefined,
};

const actionHandlers = {
  [getKeywordsSuccess]: (state, { payload }) => {
    state.keywordsTags = {};
    payload.forEach(keyword => {
      state.keywordsTags[keyword.id] = keyword;
    });
  },
  [getCategoriesSuccess]: (state, { payload }) => {
    state.categoryNames = {};
    payload.categories.forEach(category => {
      state.categoryNames[category.id] = category;
    });
  },
  [setSelectedTemplate]: (state, { payload }) => {
    state.selectedTemplate = payload;
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
