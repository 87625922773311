import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSession } from 'src/hooks';
import { ToggleSwitch } from 'src/common/toggle-switch';
import { setShowMadeWithStaggerBadgeState } from 'src/actions/userSettingsActions';
import { SET_SHOW_MADE_WITH_STAGGER_BADGE_PLANS } from 'src/constants/memberships';
import styles from './EmbedSettings.module.scss';

const EmbedSettings = () => {
  const dispatch = useDispatch();
  const { user, isSubscriptionActive, membershipPlan } = useSession();
  const [checked, setChecked] = useState(user.settings.showMadeWithStaggerBadge);

  const handleSubmit = useCallback(() => {
    dispatch(setShowMadeWithStaggerBadgeState(!checked));
    setChecked(!checked);
  }, [checked, dispatch]);

  const setShowMadeWithStaggerBadgeEnabled =
    isSubscriptionActive && SET_SHOW_MADE_WITH_STAGGER_BADGE_PLANS.includes(membershipPlan);

  return (
    <div className={styles.container}>
      <div className={styles.headerInfo}>Embed Settings</div>
      <div className={styles.enableSwitch}>
        <span className={styles.text}>&quot;Made with Stagger&quot; Badge</span>
        <ToggleSwitch
          checked={checked}
          onChange={handleSubmit}
          disabled={!setShowMadeWithStaggerBadgeEnabled}
        />
      </div>
    </div>
  );
};

export { EmbedSettings };
