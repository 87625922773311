import React, { forwardRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactSlider from 'react-slick';
import { arrayOf, node } from 'prop-types';
import cn from 'classnames';

import styles from './ModalSlider.module.scss';

const ModalSlider = forwardRef(({ slides }, refSlider) => (
  <ReactSlider
    ref={refSlider}
    className={cn(styles.slider, 'center')}
    slidesToShow={1}
    slidesToScroll={1}
    arrows={false}
    infinite={false}
    centerPadding="50px"
    centerMode
    draggable={false}
  >
    {slides}
  </ReactSlider>
));

ModalSlider.propTypes = {
  slides: arrayOf(node).isRequired,
};

export { ModalSlider };
