const CROPPER_MIN_SIZE = 20;
const STROKE_WIDTH = 4;

export const cropperTransformerBound = (oldBox, newBox, scale, imageAttrs, canvasAttrs) => {
  if (newBox.width < CROPPER_MIN_SIZE || newBox.height < CROPPER_MIN_SIZE) {
    return oldBox;
  }
  const newValues = newBox;
  const imageDimensions = {
    width: imageAttrs.width * imageAttrs.scaleX,
    height: imageAttrs.height * imageAttrs.scaleY,
  };
  const imageTopLeftCoordinateX = imageAttrs.x - (imageDimensions.width / 2);
  if (newBox.width > oldBox.width) {
    const isLeftRestricted = (newBox.x / scale) - canvasAttrs.x <
      imageTopLeftCoordinateX - STROKE_WIDTH;
    const isRightRestricted = (newBox.x / scale) - canvasAttrs.x - imageTopLeftCoordinateX +
      (newBox.width / scale) > imageDimensions.width + STROKE_WIDTH;
    if (isLeftRestricted || isRightRestricted) {
      newValues.x = oldBox.x;
      newValues.width = oldBox.width;
    }
  }
  const imageTopLeftCoordinateY = imageAttrs.y - (imageDimensions.height / 2);
  if (newBox.height > oldBox.height) {
    const isTopRestricted = (newBox.y / scale) - canvasAttrs.y <
      imageTopLeftCoordinateY - STROKE_WIDTH;
    const isBottomRestricted = (newBox.y / scale) - canvasAttrs.y - imageTopLeftCoordinateY +
      (newBox.height / scale) > imageDimensions.height + STROKE_WIDTH;
    if (isTopRestricted || isBottomRestricted) {
      newValues.y = oldBox.y;
      newValues.height = oldBox.height;
    }
  }
  return newValues;
};

export const imageDragBound = (pos, cropperAttrs, imageDimensions, scale, canvasAttrs) => {
  const newValues = pos;
  const imagePos = {
    x: (pos.x / scale) - canvasAttrs.x,
    y: (pos.y / scale) - canvasAttrs.y,
  };
  const cropperDimensions = {
    width: cropperAttrs.width * cropperAttrs.scaleX,
    height: cropperAttrs.height * cropperAttrs.scaleY,
  };
  const isRightRestricted = Math.floor(imagePos.x + (imageDimensions.width / 2)) <
    Math.floor(cropperAttrs.x + (cropperDimensions.width / 2));
  if (isRightRestricted) {
    newValues.x = Math.floor((cropperAttrs.x + (cropperDimensions.width / 2) -
      imageDimensions.width / 2) * scale + canvasAttrs.x * scale);
  }
  const isLeftRestricted = Math.floor(imagePos.x - (imageDimensions.width / 2)) >=
    Math.floor(cropperAttrs.x - (cropperDimensions.width / 2));
  if (isLeftRestricted) {
    newValues.x = Math.floor((cropperAttrs.x - (cropperDimensions.width / 2) +
      imageDimensions.width / 2) * scale + canvasAttrs.x * scale);
  }
  const isBottomRestricted = Math.floor(imagePos.y + (imageDimensions.height / 2)) <
    Math.floor(cropperAttrs.y + (cropperDimensions.height / 2));
  if (isBottomRestricted) {
    newValues.y = Math.floor((cropperAttrs.y + (cropperDimensions.height / 2) -
      imageDimensions.height / 2) * scale + canvasAttrs.y * scale);
  }
  const isTopRestricted = Math.floor(imagePos.y - (imageDimensions.height / 2)) >=
    Math.floor(cropperAttrs.y - (cropperDimensions.height / 2));
  if (isTopRestricted) {
    newValues.y = Math.floor((cropperAttrs.y - (cropperDimensions.height / 2) +
      imageDimensions.height / 2) * scale + canvasAttrs.y * scale);
  }
  return newValues;
};

export const imageTransformerBound = (oldBox, newBox, scale, cropperAttrs, canvasAttrs) => {
  const newValues = newBox;
  const cropperDimensions = {
    width: cropperAttrs.width * cropperAttrs.scaleX,
    height: cropperAttrs.height * cropperAttrs.scaleY,
  };
  const cropperTopLeftCoordinateX = cropperAttrs.x - (cropperDimensions.width / 2);
  if (newBox.width < oldBox.width) {
    const isLeftRestricted = (newBox.x / scale) - canvasAttrs.x >
      cropperTopLeftCoordinateX + STROKE_WIDTH / 2;
    const isRightRestricted = (newBox.x / scale) - canvasAttrs.x - cropperTopLeftCoordinateX +
      (newBox.width / scale) < cropperDimensions.width - STROKE_WIDTH / 2;
    if (isLeftRestricted || isRightRestricted) {
      newValues.x = oldBox.x;
      newValues.width = oldBox.width;
    }
  }
  const cropperTopLeftCoordinateY = cropperAttrs.y - (cropperDimensions.height / 2);
  if (newBox.height < oldBox.height) {
    const isTopRestricted = (newBox.y / scale) - canvasAttrs.y >
      cropperTopLeftCoordinateY + STROKE_WIDTH / 2;
    const isBottomRestricted = (newBox.y / scale) - canvasAttrs.y - cropperTopLeftCoordinateY +
      (newBox.height / scale) < cropperDimensions.height - STROKE_WIDTH / 2;
    if (isTopRestricted || isBottomRestricted) {
      newValues.y = oldBox.y;
      newValues.height = oldBox.height;
    }
  }
  return newValues;
};

export const resetCroppedAttributes = (crop, attrs) => {
  const { width, height, scaleX, scaleY, x, y } = attrs;
  if (!crop) {
    return {
      scaleX,
      scaleY,
      x,
      y,
      crop: undefined,
    };
  }
  const realScaleX = (width * scaleX) / crop.width;
  const realScaleY = (height * scaleY) / crop.height;
  const realLeftX = x - (crop.x * realScaleX + ((crop.width * realScaleX) / 2));
  const realX = realLeftX + ((width * realScaleX) / 2);
  const realLeftY = y - (crop.y * realScaleY + ((crop.height * realScaleY) / 2));
  const realY = realLeftY + ((height * realScaleY) / 2);
  return {
    scaleX: realScaleX,
    scaleY: realScaleY,
    x: realX,
    y: realY,
    crop: undefined,
  };
};
