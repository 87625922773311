import React from 'react';
import { number, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { addObjectCombination } from 'src/actions/projectActions';
import styles from './AddObjectCombination.module.scss';

const AddObjectCombination = ({ thumbnail, elements, layoutWidth, layoutHeight, position }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    const parsedElements = JSON.parse(elements) || [];
    dispatch(addObjectCombination({
      elements: parsedElements,
      layoutWidth,
      layoutHeight,
      position,
    }));
  };

  return (
    <button className={styles.content} onClick={onClick}>
      <img src={thumbnail} alt="Add Frame Combination" />
    </button>
  );
};

AddObjectCombination.propTypes = {
  thumbnail: string.isRequired,
  elements: string.isRequired,
  layoutWidth: number.isRequired,
  layoutHeight: number.isRequired,
  position: shape({
    x: number,
    y: number,
  }),
};

export { AddObjectCombination };
