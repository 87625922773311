import { useLayoutEffect, useState } from 'react';

const useDropdownWithScroll = (scrollPosition, elemId) => {
  const [optionsSize, setOptionsSize] = useState({ left: 0, right: 0 });

  useLayoutEffect(() => {
    const elem = document.getElementById(elemId);
    const scrollX = scrollPosition?.x || 0;
    const left = elem ? elem.offsetLeft - scrollX : 0;
    setOptionsSize({ left });
  }, [elemId, scrollPosition]);

  return {
    optionsSize,
  };
};

export { useDropdownWithScroll };
