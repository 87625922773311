import React, { useMemo } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';

const generateGradientId = () => `${Math.random().toString(36).substr(2, 9)}`;

const GradientPalette = ({ palette, width, height }) => {
  const sortPalette = pal => (
    pal.sort(({ offset: offset1 }, { offset: offset2 }) => offset1 - offset2)
  );

  const sortedPalette = sortPalette(palette);
  const gradientId = useMemo(generateGradientId, [palette.length]);

  return (
    <div style={{ width, height }}>
      <svg width="100%" height="100%">
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0.5" x2="1" y2="0.5">
            {sortedPalette.map(({ id, offset, color, opacity = 1 }) => (
              <stop key={id} offset={offset} style={{ stopColor: color, stopOpacity: opacity }} />
            ))}
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill={`url(#${gradientId})`} />
      </svg>
    </div>
  );
};

GradientPalette.propTypes = {
  width: number,
  height: number,
  palette: arrayOf(shape({
    offset: string,
    color: string,
  })),
};

export { GradientPalette };
