import Hashids from 'hashids';

const PJ_HASH_MIN_LENGTH = 7;
const pjHashids = new Hashids('', PJ_HASH_MIN_LENGTH);

export const generateProjectHash = (projectId) => {
  const projectHash = pjHashids.encode(projectId);
  return projectHash;
};

export const shareAsEmbed = (projectId, showMadeWithStaggerBadge) => {
  const projectHash = generateProjectHash(projectId);

  const embedFooter = showMadeWithStaggerBadge ? '' : '?noFooter=true';

  return `
    <div id="st-${projectHash}-ph" class="stagger-embed">

    <!-- You can change your embed code width and height values here -->
    <!-- You can add percentages or pixels values -->
    <div style="
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;"
      >
    </div>
    <script
      src="${process.env.REACT_APP_URL_SHARE_API}/embed/${projectHash}${embedFooter}"
    ></script>
  </div>
  `;
};
