import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { func, string, arrayOf, shape, bool } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import ArrowLeft from 'src/assets/icons/arrow-left.svg';
import ExpandIcon from 'src/assets/icons/expand-content-dark.svg';
import ExpandIconWhite from 'src/assets/icons/expand-content-white.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import { Button } from 'src/common/button';
import {
  MEDIUM_WIDTH,
  EXTRA_LARGE_WIDTH,
  MOBILE_THRESHOLD_WIDTH,
} from 'src/constants/breakpoints';
import { useWindowSize } from 'src/hooks';
import { isVideo } from 'src/utils/videoHelpers';
import { trackFocusViewOfSquadEvent } from 'src/utils/analytics';
import { CAMPAIGN_UPLOAD } from 'src/constants/uploadFile';
import styles from './FullscreenModal.module.scss';

const SCROLLABLE_CONTAINER_ID = 'fullscreenScrollableContainer';

const FullscreenModal = ({
  title,
  onClose,
  pagesToShow,
  nextPage,
  cardComponent,
  selected,
  setSelected,
  actionButton,
  selectDisabled,
  source,
}) => {
  const { windowSize: { width } } = useWindowSize();

  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const ref = useRef();

  const [showExpand, setShowExpand] = useState();
  const [focusedView, setFocusedView] = useState();

  const onActionButtonClick = () => {
    actionButton.onClick();
    onClose();
  };

  const deselectAllAction = () => {
    setSelected([]);
  };

  const selectFocused = () => {
    if (ref?.current) {
      const [cardButton] = ref.current.querySelectorAll(`[id='${focusedView.divId}'] button`);
      if (cardButton) {
        cardButton.click();
      }
    }
  };

  const onExpand = (elem) => {
    const focused = { ...elem };
    if (elem.author) {
      focused.name = `${elem.author.name} via Unsplash`;
      focused.url = elem.preview;
    }
    setFocusedView(focused);
    if (source === CAMPAIGN_UPLOAD) {
      trackFocusViewOfSquadEvent();
    }
  };

  const closeFocusedView = () => {
    setFocusedView();
  };

  const gridColumnsNumber = useMemo(() => {
    if (width >= EXTRA_LARGE_WIDTH) {
      return 'auto auto auto auto';
    } if (width >= MEDIUM_WIDTH) {
      return 'auto auto auto';
    }
    return 'auto auto';
  }, [width]);

  return (
    ReactDOM.createPortal(
      <div className={styles.container}>
        <div className={styles.modalContent}>
          <div className={styles.header}>
            <div className={styles.title}>
              <span>{title}</span>
            </div>
            <button className={styles.hide} onClick={onClose}>
              <img src={CloseIcon} alt="Close modal" />
            </button>
          </div>
          {focusedView && (
            <div className={styles.focusedContent}>
              <div className={styles.header}>
                <div className={styles.title}>
                  <button className={styles.hide} onClick={closeFocusedView}>
                    <img src={ArrowLeft} alt="Close focused view" />
                  </button>
                  <span>{focusedView.name}</span>
                </div>
                <button className={styles.hide} onClick={onClose}>
                  <img src={CloseIcon} alt="Close modal" />
                </button>
              </div>
              <div className={styles.focusedCard}>
                {isVideo(focusedView.url) ? (
                  <video className={styles.focusedImage} controls muted>
                    <source src={focusedView.url} type="video/mp4" />
                  </video>
                ) : (
                  <img src={focusedView.url} alt="" className={styles.focusedImage} />
                )}
              </div>
              <div className={styles.cardBottomFocused}>
                {selected.find(e => e.id === focusedView.id) ? (
                  <>
                    <Button onClick={selectFocused} className={styles.deselectFocused}>
                      Undo
                    </Button>
                    <Button disabled>
                      Added
                    </Button>
                  </>
                ) : (
                  <Button onClick={selectFocused} disabled={selectDisabled}>
                    Select Image to Add
                  </Button>
                )}
              </div>
            </div>
          )}
          <div className={styles.scrollable} id={SCROLLABLE_CONTAINER_ID} ref={ref}>
            <InfiniteScroll
              dataLength={pagesToShow?.length || 0}
              next={nextPage}
              hasMore
              scrollableTarget={SCROLLABLE_CONTAINER_ID}
              style={{
                display: 'grid',
                gridTemplateColumns: gridColumnsNumber,
                flex: 1,
                columnGap: '1rem',
                rowGap: '1rem',
              }}
            >
              {pagesToShow?.map(elem => {
                const card = cardComponent(elem);
                if (!card) {
                  return null;
                }
                const divId = card.props.id.split('.')[0];
                return (
                  <div
                    id={divId}
                    key={card.props.id}
                    className={styles.card}
                    onMouseEnter={() => setShowExpand(card.props.id)}
                    onMouseLeave={() => setShowExpand()}
                  >
                    {cardComponent(elem)}
                    {(isMobile || showExpand === card.props.id) && (
                      <div className={styles.cardBottom}>
                        <button
                          className={styles.buttonExpand}
                          onClick={() => onExpand({ ...elem, ...card.props, divId })}
                        >
                          <img src={isMobile ? ExpandIcon : ExpandIconWhite} alt="Expand" />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
          <div className={styles.bottomOptions}>
            {!!selected.length && (
              <Button className={styles.deselect} secondary onClick={deselectAllAction}>
                Deselect all
              </Button>
            )}
            <Button
              className={actionButton.className}
              onClick={onActionButtonClick}
              loading={actionButton.loading}
              disabled={actionButton.disabled}
            >
              {actionButton.text}
            </Button>
          </div>
        </div>
      </div>,
      document.getElementById('root'),
    )
  );
};

FullscreenModal.propTypes = {
  onClose: func.isRequired,
  pagesToShow: arrayOf(shape({ url: string })),
  nextPage: func.isRequired,
  cardComponent: func.isRequired,
  selected: arrayOf(shape({ url: string })),
  setSelected: func.isRequired,
  actionButton: shape({
    disabled: bool,
    onClick: func.isRequired,
    className: string,
    loading: bool,
    text: string.isRequired,
  }),
  selectDisabled: bool.isRequired,
  source: string.isRequired,
};

export { FullscreenModal };
