import {
  HttpClient,
} from '../clients';

class TipRuleService {
  static getAllRules() {
    return HttpClient.get('/tip-rules');
  }
}

export { TipRuleService };
