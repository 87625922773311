import React, { useCallback } from 'react';
import { Circle } from 'react-konva';
import { string, number, bool, shape } from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { TAG_MARKER_PREFIX_ID } from 'src/constants/general';
import { setEditableTag } from 'src/actions/canvasActions';

const CanvasTagMarkerComponent = ({
  uuid,
  x,
  y,
  eyeDropper,
  isInGroup = false,
}) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(setEditableTag({ uuid }));
  }, [dispatch, uuid]);

  return (
    <Circle
      id={`${TAG_MARKER_PREFIX_ID}_${uuid}`}
      x={x}
      y={y}
      radius={250}
      fill="#2DAF92"
      width={20}
      height={20}
      shadowOpacity={0.9}
      shadowColor="#2DAF92"
      shadowBlur={10}
      shadowEnabled
      onClick={onClick}
      onTap={onClick}
      listening={!eyeDropper.active && !isInGroup}
    />
  );
};

CanvasTagMarkerComponent.propTypes = {
  uuid: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  eyeDropper: shape({ active: bool }),
  isInGroup: bool,
};

const mapStateToProps = (store) => ({
  eyeDropper: store.canvas.eyeDropper,
});

const CanvasTagMarker = connect(mapStateToProps)(CanvasTagMarkerComponent);

export { CanvasTagMarker };
