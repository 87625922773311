import React, { useEffect, useCallback } from 'react';
import { func, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import CheckGreen from 'src/assets/icons/check-green.svg';
import WarningRed from 'src/assets/icons/warning-red.svg';
import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { Input } from 'src/common/input';
import { useStatus } from 'src/hooks';
import { checkUsername, CHECK_USERNAME } from 'src/actions/userActions';
import { SUCCESS, ERROR, LOADING } from 'src/constants/status';
import styles from './UsernameInput.module.scss';

const UsernameInput = ({
  inputProps,
  usernameStatus,
  setUsernameStatus,
  className = '',
  initialValue,
}) => {
  const dispatch = useDispatch();

  const { status: statusCheckUsername, statusInfo } = useStatus(CHECK_USERNAME);

  const { value, error, onChange } = inputProps('username');

  const onBlur = useCallback(() => {
    if (!error && value && value !== initialValue) {
      dispatch(checkUsername(value));
    }
  }, [error, value, initialValue, dispatch]);

  const onInputChange = useCallback((e) => {
    if (usernameStatus) {
      setUsernameStatus();
    }
    onChange(e);
  }, [onChange, setUsernameStatus, usernameStatus]);

  const onKeyDown = useCallback(({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  }, []);

  useEffect(() => {
    if (statusCheckUsername === SUCCESS) {
      if (statusInfo.exist) {
        setUsernameStatus(ERROR);
      } else {
        setUsernameStatus(SUCCESS);
      }
    } else if (statusCheckUsername === LOADING || statusCheckUsername === ERROR) {
      setUsernameStatus(statusCheckUsername);
    }
  }, [setUsernameStatus, statusCheckUsername, statusInfo]);

  return (
    <div className={cn(styles.container, className)}>
      <Input
        name="username"
        label="Username"
        {...inputProps('username')}
        onBlur={onBlur}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        inputClassName={styles.input}
      />
      {usernameStatus === SUCCESS && !error && (
        <img className={styles.icon} src={CheckGreen} alt="success" />
      )}
      {usernameStatus === ERROR && (
        <img className={styles.icon} src={WarningRed} alt="warning" />
      )}
    </div>
  );
};
UsernameInput.propTypes = {
  inputProps: func.isRequired,
  setUsernameStatus: func.isRequired,
  usernameStatus: string,
  className: string,
  initialValue: string,
};

export { UsernameInput };
