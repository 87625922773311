import React from 'react';
import { func } from 'prop-types';

import DownloadIcon from 'src/assets/icons/download-longpress.svg';
import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import styles from './ModalDownloadLongpress.module.scss';

const ModalDownloadLongpress = ({ hide }) => (
  <Modal
    isShowing
    hide={hide}
    isEscEnabled={false}
    curvedContainer
    classNameContent={styles.modal}
  >
    <div className={styles.content}>
      <span className={styles.title}>
        Save Images
      </span>
      <div className={styles.imageContainer}>
        <img src={DownloadIcon} alt="Download long press" />
      </div>
      <span className={styles.subtitle}>
        To save your images, long press on each image to save to your device.
      </span>
      <Button
        className={styles.button}
        onClick={hide}
        alternativeCTA
      >
        Got it!
      </Button>
    </div>
  </Modal>
);

ModalDownloadLongpress.propTypes = {
  hide: func.isRequired,
};

export { ModalDownloadLongpress };
