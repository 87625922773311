import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import SquareIconWhite from 'src/assets/icons/square-white.svg';
import RoundedRectIconWhite from 'src/assets/icons/rounded-rect-white.svg';
import TriangleIconWhite from 'src/assets/icons/triangle-white.svg';
import CircleIconWhite from 'src/assets/icons/circle-white.svg';
import LineIconWhite from 'src/assets/icons/line-white.svg';
import { useStageCenteredPosition, useWindowSize } from 'src/hooks';
import {
  addLineElement,
  addCircleElement,
  addSquareElement,
  addRoundedRectElement,
  addTriangleElement,
} from 'src/actions/projectActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { ShapeSelectOption } from './shape-select-option';
import styles from './AddShapeControl.module.scss';

const AddShapeControl = ({ onClose }) => {
  const dispatch = useDispatch();

  const position = useStageCenteredPosition();

  const addLine = useCallback(() => {
    dispatch(addLineElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addCircle = useCallback(() => {
    dispatch(addCircleElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addSquare = useCallback(() => {
    dispatch(addSquareElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addRoundedRect = useCallback(() => {
    dispatch(addRoundedRectElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const addTriangle = useCallback(() => {
    dispatch(addTriangleElement({ x: position.x, y: position.y }));
  }, [dispatch, position.x, position.y]);

  const shapeOptions = useMemo(() => [
    {
      icon: SquareIconWhite,
      alt: 'square',
      onClick: addSquare,
    },
    {
      icon: RoundedRectIconWhite,
      alt: 'rounded rect',
      onClick: addRoundedRect,
    },
    {
      icon: TriangleIconWhite,
      alt: 'triangle',
      onClick: addTriangle,
    },
    {
      icon: CircleIconWhite,
      alt: 'circle',
      onClick: addCircle,
    },
    {
      icon: LineIconWhite,
      alt: 'line',
      onClick: addLine,
    },
  ], [addCircle, addLine, addRoundedRect, addSquare, addTriangle]);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const onAddShape = (onClick) => {
    onClick();
    if (isMobile) {
      onClose();
    }
  };

  return (
    <div className={styles.shapes}>
      {shapeOptions.map(({ icon, alt, onClick }) => (
        <ShapeSelectOption
          key={alt}
          icon={icon}
          alt={alt}
          onClick={() => onAddShape(onClick)}
        />
      ))}
    </div>
  );
};

AddShapeControl.propTypes = {
  onClose: func.isRequired,
};

export { AddShapeControl };
