import React from 'react';
import { func, bool, string } from 'prop-types';

import { Modal } from 'src/common/modal';
import { Button } from 'src/common/button';
import styles from './LayoutConfirmationModal.module.scss';

const LayoutConfirmationModal = ({ isShowing, onAccept, onClose, title, text }) => (
  <Modal
    isShowing={isShowing}
    hide={onClose}
    isEscEnabled={false}
    curvedContainer
    classNameContent={styles.modal}
  >
    <div className={styles.content}>
      <span className={styles.title}>{title}</span>
      <span className={styles.text}>
        {text}
      </span>
      <div className={styles.buttonsContainer}>
        <Button
          onClick={onAccept}
          alternativeCTA
          className={styles.buttonAccept}
        >
          Yes, I&apos;m sure
        </Button>
        <Button
          className={styles.buttonCancel}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

LayoutConfirmationModal.propTypes = {
  isShowing: bool.isRequired,
  onAccept: func.isRequired,
  onClose: func.isRequired,
  title: string,
  text: string,
};

export { LayoutConfirmationModal };
