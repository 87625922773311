import React, { useState } from 'react';
import { arrayOf, string, shape, func } from 'prop-types';

import { useClickOutside } from 'src/hooks';
import MenuIcon from 'src/assets/icons/menu.svg';
import styles from './TemplateOptions.module.scss';

const TemplateOptions = ({
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false), isOpen);

  const optionClick = action => {
    setIsOpen(false);
    action();
  };

  return (
    <div
      className={styles.container}
      ref={ref}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button className={styles.button} onClick={() => setIsOpen(open => !open)}>
        <img src={MenuIcon} alt="Menu" />
      </button>
      {isOpen && (
        <div className={styles.options}>
          {options.map(({ text, action }) => (
            <button
              key={text}
              onClick={() => optionClick(action)}
              className={styles.option}
            >
              {text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};


TemplateOptions.propTypes = {
  options: arrayOf(shape({
    text: string,
    action: func,
  })).isRequired,
};

export { TemplateOptions };
