import {
  getUserWithBrandLibrarySuccess,
  deleteAssetFromBrandLibrarySuccess,
  deleteLogoFromBrandLibrarySuccess,
  createAssetCategorySuccess,
  updateAssetCategoryNameSuccess,
  deleteAssetCategorySuccess,
  createLogoCategorySuccess,
  updateLogoCategoryNameSuccess,
  deleteLogoCategorySuccess,
  createColorPaletteSuccess,
  updatePaletteNameSuccess,
  deleteColorPaletteSuccess,
  createPaletteColorSuccess,
  addSingleAssetToCategorySuccess,
  addSingleLogoToCategorySuccess,
  updatePaletteColorSuccess,
  deletePaletteColorSuccess,
  addFontSuccess,
  uploadFontsSuccess,
  updateFontSuccess,
  deleteUploadedFontSuccess,
  deleteFontSuccess,
  searchInBrandLibrarySuccess,
  showAssetDetailsSuccess,
  showAssetDetailsReset,
  showLogoDetailsSuccess,
  showLogoDetailsReset,
  getLogosOfCategorySuccess,
  getAssetsOfCategorySuccess,
  addLogosToBrandLibraryRequest,
  addLogosToBrandLibrarySuccess,
  addLogosToBrandLibraryError,
  addAssetsToBrandLibraryRequest,
  addAssetsToBrandLibrarySuccess,
  addAssetsToBrandLibraryError,
  clearSearchInBrandLibrary,
  saveSearchTermBrandLibrary,
  receiveAssetMetadata,
  receiveLogoMetadata,
} from 'src/actions/brandLibraryActions';
import { logout } from 'src/actions/userActions';

import createReducer from './createReducer';

const initialState = {
  disabled: true,
  id: 0,
  colorPalettes: [],
  fonts: [],
  uploadedFonts: [],
  logoCategories: [],
  assetCategories: [],
  searchResults: null,
  searchTerm: undefined,
  selectedAsset: null,
  selectedAssetDetails: null,
  selectedLogo: null,
  selectedLogoDetails: null,
  loadingLogos: {},
  loadingAssets: {},
};

const actionHandlers = {
  [getUserWithBrandLibrarySuccess]: (state, { payload: { library, disabled } }) => {
    if (library) {
      state.id = library.id;
      state.logoCategories = library.logoCategories;
      state.assetCategories = library.assetCategories;
      state.colorPalettes = library.colorPalettes;
      state.fonts = library.fonts;
      state.uploadedFonts = library.uploadedFonts;
    } else {
      state = initialState;
    }
    state.disabled = disabled;
  },
  [getLogosOfCategorySuccess]: (state, { payload: { categoryId, logos } }) => {
    state.logoCategories = state.logoCategories.map((logoCat) => {
      if (logoCat.id === categoryId) {
        logoCat.logos = [
          ...(logoCat.logos || []),
          ...logos,
        ];
      }
      return logoCat;
    });
  },
  [getAssetsOfCategorySuccess]: (state, { payload: { categoryId, assets } }) => {
    state.assetCategories = state.assetCategories.map((assetCat) => {
      if (assetCat.id === categoryId) {
        assetCat.assets = [
          ...(assetCat.assets || []),
          ...assets,
        ];
      }
      return assetCat;
    });
  },
  [deleteLogoFromBrandLibrarySuccess]: (state, { payload: { logoCategoryId, logoId } }) => {
    state.logoCategories = state.logoCategories.map((logoCat) => {
      if (logoCat.id === logoCategoryId) {
        logoCat.logos = logoCat.logos.filter((logo) => logo.id !== logoId);
      }
      return logoCat;
    });
  },
  [deleteAssetFromBrandLibrarySuccess]: (state, { payload: { assetCategoryId, assetId } }) => {
    state.assetCategories = state.assetCategories.map((assetCat) => {
      if (assetCat.id === assetCategoryId) {
        assetCat.assets = assetCat.assets.filter((asset) => asset.id !== assetId);
      }
      return assetCat;
    });
  },
  [createAssetCategorySuccess]: (state, { payload: { assetCategory } }) => {
    state.assetCategories = [assetCategory, ...state.assetCategories];
  },
  [updateAssetCategoryNameSuccess]: (state, { payload: { id, name } }) => {
    state.assetCategories = state.assetCategories.map((assetCat) => {
      if (assetCat.id === id) {
        assetCat.name = name;
      }
      return assetCat;
    });
  },
  [deleteAssetCategorySuccess]: (state, { payload: { assetCategoryId } }) => {
    state.assetCategories = state.assetCategories.filter(elem => elem.id !== assetCategoryId);
  },
  [createLogoCategorySuccess]: (state, { payload: { logoCategory } }) => {
    state.logoCategories = [logoCategory, ...state.logoCategories];
  },
  [updateLogoCategoryNameSuccess]: (state, { payload: { id, name } }) => {
    state.logoCategories = state.logoCategories.map((logoCat) => {
      if (logoCat.id === id) {
        logoCat.name = name;
      }
      return logoCat;
    });
  },
  [deleteLogoCategorySuccess]: (state, { payload: { logoCategoryId } }) => {
    state.logoCategories = state.logoCategories.filter(elem => elem.id !== logoCategoryId);
  },
  [createColorPaletteSuccess]: (state, { payload: { colorPalette } }) => {
    state.colorPalettes = [colorPalette, ...state.colorPalettes];
  },
  [updatePaletteNameSuccess]: (state, { payload: { id, name } }) => {
    state.colorPalettes = state.colorPalettes.map(palette => {
      if (palette.id === id) {
        palette.name = name;
      }
      return palette;
    });
  },
  [deleteColorPaletteSuccess]: (state, { payload: { paletteId } }) => {
    state.colorPalettes = state.colorPalettes.filter(elem => elem.id !== paletteId);
  },
  [createPaletteColorSuccess]: (state, { payload: { paletteId, color } }) => {
    state.colorPalettes = state.colorPalettes.map(palette => {
      if (palette.id === paletteId) {
        palette.colors = [...palette.colors, color];
      }
      return palette;
    });
  },
  [addSingleAssetToCategorySuccess]: (state, { payload: { assetCategoryId, asset } }) => {
    state.assetCategories = state.assetCategories.map(assetCat => {
      if (assetCat.id === assetCategoryId) {
        assetCat.assets = assetCat.assets || [];
        assetCat.assets.push(asset);
      }
      return assetCat;
    });
    state.loadingAssets[assetCategoryId] -= 1;
  },
  [addSingleLogoToCategorySuccess]: (state, { payload: { logoCategoryId, logo } }) => {
    state.logoCategories = state.logoCategories.map(logoCat => {
      if (logoCat.id === logoCategoryId) {
        logoCat.logos = logoCat.logos || [];
        logoCat.logos.push(logo);
      }
      return logoCat;
    });
    state.loadingLogos[logoCategoryId] -= 1;
  },
  [updatePaletteColorSuccess]: (state, { payload: { paletteId, color } }) => {
    state.colorPalettes = state.colorPalettes.map(palette => {
      if (palette.id === paletteId) {
        palette.colors = palette.colors.map(elem => {
          if (elem.id === color.id) {
            elem = color;
          }
          return elem;
        });
      }
      return palette;
    });
  },
  [deletePaletteColorSuccess]: (state, { payload: { paletteId, colorId } }) => {
    state.colorPalettes = state.colorPalettes.map(palette => {
      if (palette.id === paletteId) {
        palette.colors = palette.colors.filter(elem => elem.id !== colorId);
      }
      return palette;
    });
  },
  [addFontSuccess]: (state, { payload: { font } }) => {
    state.fonts = [...state.fonts, font];
  },
  [uploadFontsSuccess]: (state, { payload: { uploadedFonts } }) => {
    state.uploadedFonts = [...state.uploadedFonts, ...uploadedFonts];
  },
  [updateFontSuccess]: (state, { payload: { font } }) => {
    state.fonts = state.fonts.map(elem => {
      if (elem.id === font.id) {
        elem = font;
      }
      return elem;
    });
  },
  [deleteUploadedFontSuccess]: (state, { payload: { uploadedFontId, updatedFonts } }) => {
    state.uploadedFonts = state.uploadedFonts.filter(elem => elem.id !== uploadedFontId);
    if (updatedFonts.length) {
      state.fonts = state.fonts.map(elem => {
        const fontChanged = updatedFonts.find(updated => updated.id === elem.id);
        if (fontChanged) {
          elem = fontChanged;
        }
        return elem;
      });
    }
  },
  [deleteFontSuccess]: (state, { payload: { fontId } }) => {
    state.fonts = state.fonts.filter(elem => elem.id !== fontId);
  },
  [saveSearchTermBrandLibrary]: (state, { payload: { searchTerm } }) => {
    state.searchTerm = searchTerm;
    state.searchResults = null;
  },
  [searchInBrandLibrarySuccess]: (state, { payload: { searchMatches } }) => {
    state.searchResults = [
      ...(state.searchResults || []),
      ...searchMatches,
    ];
  },
  [clearSearchInBrandLibrary]: state => {
    state.searchResults = null;
    state.searchTerm = undefined;
  },
  [showAssetDetailsSuccess]: (state, { payload: { asset } }) => {
    state.selectedAsset = asset;
  },
  [showAssetDetailsReset]: state => {
    state.selectedAsset = null;
    state.selectedAssetDetails = null;
  },
  [receiveAssetMetadata]: (state, { payload: { assetMetadata } }) => {
    state.selectedAssetDetails = assetMetadata;
  },
  [showLogoDetailsSuccess]: (state, { payload: { logo } }) => {
    state.selectedLogo = logo;
  },
  [showLogoDetailsReset]: state => {
    state.selectedLogo = null;
    state.selectedLogoDetails = null;
  },
  [receiveLogoMetadata]: (state, { payload: { logoMetadata } }) => {
    state.selectedLogoDetails = logoMetadata;
  },
  [addLogosToBrandLibraryRequest]: (state, { payload: { logoCategoryId, files } }) => {
    state.loadingLogos[logoCategoryId] = files.length;
  },
  [addLogosToBrandLibrarySuccess]: (state, { payload: { logoCategoryId } }) => {
    delete state.loadingLogos[logoCategoryId];
  },
  [addLogosToBrandLibraryError]: (state, { payload: { logoCategoryId } }) => {
    delete state.loadingLogos[logoCategoryId];
  },
  [addAssetsToBrandLibraryRequest]: (state, { payload: { assetCategoryId, files } }) => {
    state.loadingAssets[assetCategoryId] = files.length;
  },
  [addAssetsToBrandLibrarySuccess]: (state, { payload: { assetCategoryId } }) => {
    delete state.loadingAssets[assetCategoryId];
  },
  [addAssetsToBrandLibraryError]: (state, { payload: { assetCategoryId } }) => {
    delete state.loadingAssets[assetCategoryId];
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
