import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
  KEY_Z_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import { useKeyboardListener } from 'src/hooks';
import { useKeyboardReset } from 'src/hooks/useKeyboardListener';
import { undo } from 'src/actions/projectActions';

const useUndoShortcut = () => {
  const dispatch = useDispatch();

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);

  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const keyZPressed = useKeyboardListener(KEY_Z_CHARACTER_CODE);

  const resetKey = useKeyboardReset();

  const { past } = useSelector(({ project }) => project);

  useEffect(() => {
    if ((commandPressed || controlPressed) && keyZPressed) {
      if (past.length) {
        dispatch(undo());
      }
      resetKey(KEY_Z_CHARACTER_CODE);
    }
  }, [dispatch, commandPressed, keyZPressed, past.length, controlPressed, resetKey]);
};

export { useUndoShortcut };
