import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT_MOVEMENT,
  CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_WIDTH,
} from 'src/constants/general';
import { useClickOutside } from 'src/hooks';
import { closeRightClickOptions } from 'src/actions/canvasActions';
import { ElementGeneralOptions } from './element-general-options';
import { ElementMovementOptions } from './element-movement-options';
import styles from './CanvasElementOptions.module.scss';

const GENERAL_OPTIONS = 'generalOptions';
const MOVEMENT_OPTIONS = 'movementOptions';

const CanvasElementOptions = () => {
  const dispatch = useDispatch();

  const { rightClickOptions } = useSelector(({ canvas }) => ({
    rightClickOptions: canvas.rightClickOptions,
  }));

  const [optionsShowing, setOptionsShowing] = useState(GENERAL_OPTIONS);

  const showMovementOptions = () => {
    setOptionsShowing(MOVEMENT_OPTIONS);
  };

  const showGeneralOptions = () => {
    setOptionsShowing(GENERAL_OPTIONS);
  };

  const height = useMemo(() => {
    if (optionsShowing === MOVEMENT_OPTIONS) {
      return CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT_MOVEMENT;
    }
    return CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_HEIGHT;
  }, [optionsShowing]);

  const ref = useClickOutside(() => dispatch(closeRightClickOptions()), rightClickOptions);

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: rightClickOptions.y,
        left: rightClickOptions.x,
        height: `${height}rem`,
        width: `${CANVAS_RIGHT_CLICK_OPTIONS_ELEMENT_MOBILE_WIDTH}rem`,
      }}
      ref={ref}
    >
      {optionsShowing === GENERAL_OPTIONS && (
        <ElementGeneralOptions
          optionClassName={styles.option}
          showMovementOptions={showMovementOptions}
        />
      )}
      {optionsShowing === MOVEMENT_OPTIONS && (
        <ElementMovementOptions
          optionClassName={styles.option}
          showGeneralOptions={showGeneralOptions}
        />
      )}
    </div>,
    document.getElementById('root'),
  );
};

export { CanvasElementOptions };
