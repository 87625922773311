import React, { useMemo, forwardRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { bool } from 'prop-types';

import {
  ACTIONS_TO_AUTO_SAVE_PROJECT,
  PRODUCT_TOUR_PREFIX_ID,
  PROJECT_TYPE,
} from 'src/constants/general';
import { Pulse } from 'src/common/pulse';
import {
  UPDATE_PROJECT,
  updateProjectAutomaticallyRequest,
  updateProjectAutomatically,
  UPDATE_PROJECT_AUTOMATICALLY,
} from 'src/actions/projectActions';
import { routesPaths } from 'src/routes/routesPaths';
import ArrowLeft from 'src/assets/icons/arrow-left-white.svg';
import {
  useWindowSize,
  useLoading,
  useProjectSelector,
  useSaveProjectPreviews,
} from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { triggerKeepGeneratedCollage } from 'src/actions/collageActions';
import { ProjectName } from './project-name';
import { UndoRedoButtons } from './undo-redo-buttons';
import { SaveOrPublish } from './save-or-publish';
import styles from './Toolbar.module.scss';

const Toolbar = forwardRef(({ error }, refDrawingLayer) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { windowSize } = useWindowSize();

  const isSmallScreen = useMemo(() => windowSize.width <= SMALL_WIDTH, [windowSize.width]);

  const loadingSaveDraft = useLoading(UPDATE_PROJECT);
  const loadingSaveAutomatically = useLoading(UPDATE_PROJECT_AUTOMATICALLY);

  const {
    changesSaved,
    actionsUnsaved,
    productTour,
    forceMediaAutoSave,
    isGeneratedCollageVisible,
  } = useSelector(({ projectState, session, collage }) => ({
    changesSaved: projectState.changesSaved,
    actionsUnsaved: projectState.actionsUnsaved,
    productTour: session.productTour,
    forceMediaAutoSave: projectState.forceMediaAutoSave,
    isGeneratedCollageVisible: collage.isGeneratedCollageVisible,
  }));

  const {
    id,
    size,
    layout,
    name,
    elements,
    groupsOfElementsById,
    videoIdsByPosition,
    gifIdsByPosition,
    color,
    frameTitles,
    type,
  } = useProjectSelector();

  const {
    generatePreviews,
    prepareCanvas,
    undoPrepareCanvas,
  } = useSaveProjectPreviews(refDrawingLayer);

  const saveAsDraftAutomatically = useCallback(async (generateThumbnail = false) => {
    dispatch(updateProjectAutomaticallyRequest());
    let fileThumbnail;
    if (generateThumbnail) {
      [fileThumbnail] = await generatePreviews({ nameFile: name, isThumbnail: true });
    }
    const newParams = {
      size,
      color: color.hex,
      alpha: color.alpha,
      layoutWidth: layout.width,
      layoutHeight: layout.height,
      layoutSource: layout.source,
      elements,
      groupsOfElementsById,
      videoIdsByPosition,
      gifIdsByPosition,
      frameTitles,
    };
    dispatch(updateProjectAutomatically(id, newParams, fileThumbnail));
  }, [
    color.alpha,
    color.hex,
    dispatch,
    elements,
    generatePreviews,
    groupsOfElementsById,
    id,
    layout.height,
    layout.width,
    layout.source,
    name,
    size,
    videoIdsByPosition,
    gifIdsByPosition,
    frameTitles,
  ]);

  const goToProjects = async () => {
    if (!changesSaved) {
      await saveAsDraftAutomatically(true);
    }
    if (isGeneratedCollageVisible) {
      dispatch(triggerKeepGeneratedCollage(id));
    }
    history.push(routesPaths.projects);
  };

  useEffect(() => {
    if (!changesSaved && !loadingSaveAutomatically &&
      (actionsUnsaved >= ACTIONS_TO_AUTO_SAVE_PROJECT || forceMediaAutoSave)) {
      saveAsDraftAutomatically();
    }
  }, [
    actionsUnsaved,
    changesSaved,
    saveAsDraftAutomatically,
    loadingSaveAutomatically,
    forceMediaAutoSave,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <button className={styles.back} onClick={goToProjects}>
          <img src={ArrowLeft} alt="back" />
        </button>
        <ProjectName />
      </div>
      <div className={styles.content}>
        <UndoRedoButtons />
        {!isSmallScreen && (
          <span
            className={cn(styles.changesSaved, {
              [styles.show]: changesSaved || loadingSaveAutomatically || loadingSaveDraft,
            })}
          >
            {changesSaved && !(loadingSaveAutomatically || loadingSaveDraft) && 'Changes saved'}
            {(loadingSaveAutomatically || loadingSaveDraft) && 'Saving changes...'}
          </span>
        )}
        <SaveOrPublish
          generatePreviews={generatePreviews}
          prepareCanvas={prepareCanvas}
          undoPrepareCanvas={undoPrepareCanvas}
          disabled={error}
        />
        <div
          id={`${PRODUCT_TOUR_PREFIX_ID}-${type === PROJECT_TYPE.PROJECT ? 4 : 3}`}
          className={styles.productTour}
        >
          {productTour && <Pulse />}
        </div>
      </div>
    </div>
  );
});

Toolbar.propTypes = {
  error: bool,
};

export { Toolbar };
