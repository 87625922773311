import React from 'react';
import { string, func, bool, oneOfType, node, object } from 'prop-types';
import cn from 'classnames';

import styles from './SimpleOption.module.scss';

const SimpleOption = ({
  action,
  text,
  href,
  optionClick,
  isActive,
  optionClassName,
  opClassName,
  closeOnClickOption,
  prefix,
  posfix,
}) => (
  <>
    {action && (
      <button
        onClick={() => optionClick(action, closeOnClickOption)}
        className={cn(
          styles.option,
          optionClassName,
          opClassName || '',
          { [styles.active]: isActive },
        )}
      >
        {prefix}
        <span>{text}</span>
        {posfix}
      </button>
    )}
    {href && (
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className={cn(styles.linkOption, optionClassName)}
      >
        {text}
      </a>
    )}
  </>
);

SimpleOption.propTypes = {
  optionClassName: string,
  closeOnClickOption: bool,
  text: string,
  action: func,
  href: string,
  opClassName: string,
  isActive: bool,
  optionClick: func.isRequired,
  prefix: oneOfType([node, object]),
  posfix: oneOfType([node, object]),
};

export { SimpleOption };
