import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bool, number, shape } from 'prop-types';

import { updateElement } from 'src/actions/projectActions';
import { useProjectSelector } from 'src/hooks';
import {
  SHAPE_DISABLED_COLOR,
  NO_BORDER_SIZE,
  BORDER_COLOR_EYEDROPPER_SOURCE,
} from 'src/constants/general';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import { ColorPickerCanvas } from 'src/pages/project/canvas/canvas-helper-elements';
import { BorderWidth } from '../border-width';
import { BorderDash } from '../../shape-control/border-dash';
import styles from './BorderOptions.module.scss';

const BorderOptions = ({ scrollPosition, disableDash = false, paddingLeftOptions = 0 }) => {
  const dispatch = useDispatch();

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const elementSelected = useMemo(() => (
    elements.find(elem => elem.uuid === selectedElementsList[0])
  ), [elements, selectedElementsList]);

  const changeBorderColorElementRequest = useCallback(borderColor => {
    selectedElementsList.forEach(elementId => {
      const newAttrs = {
        uuid: elementId,
        borderColor,
      };
      dispatch(updateElement(newAttrs));
    });
  }, [dispatch, selectedElementsList]);

  const disabledBorderPicker = !elementSelected?.strokeWidth ||
    elementSelected.strokeWidth === NO_BORDER_SIZE;

  const containerId = 'borderColor';
  const { optionsSize } = useDropdownWithScroll(scrollPosition, containerId);

  return (
    <div className={styles.container} id={containerId}>
      <div className={styles.border}>
        <BorderWidth scrollPosition={scrollPosition} />
        {!disabledBorderPicker && (
          <>
            {!disableDash && (
              <BorderDash disabled={disabledBorderPicker} scrollPosition={scrollPosition} />
            )}
            <ColorPickerCanvas
              color={elementSelected?.borderColor}
              onColorChange={changeBorderColorElementRequest}
              className={styles.colorPicker}
              disabled={disabledBorderPicker}
              disabledColor={SHAPE_DISABLED_COLOR}
              enableAlpha={false}
              eyedropperSource={BORDER_COLOR_EYEDROPPER_SOURCE}
              style={{ left: optionsSize.left - paddingLeftOptions, top: '118%' }}
            />
          </>
        )}
      </div>
    </div>
  );
};

BorderOptions.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
  disableDash: bool,
  paddingLeftOptions: number,
};

export { BorderOptions };
