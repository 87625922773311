import { toast } from 'react-toastify';
import React from 'react';
import produce from 'immer';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { ActionCreators } from 'redux-undo';

import {
  toastSuccessConfig,
  toastErrorConfig,
  toastInfoConfig,
} from 'src/constants/toastConfig';
import { ToastLoading } from 'src/common/toast-loading';
import {
  TEXT_ELEMENT,
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
  MEDIA_ELEMENTS,
} from 'src/constants/canvasElements';
import {
  PROJECT_TYPE,
  ENABLE_DESIGN_TIPS,
} from 'src/constants/general';
import {
  routeWithProps,
  promiseInListWithLimit,
  isGif,
} from 'src/utils/helpers';
import {
  getCloudinaryFilenameFromUrl,
  getCloudinaryFilenameWithoutTimestamp,
} from 'src/utils/cloudinaryHelpers';
import {
  isElementOverlapping,
  calculateAspectRatioFit,
  duplicateAssetFiles,
} from 'src/utils/canvasHelpers';
import {
  calculatePositionVideoExport,
  calculateScaleVideo,
  saveGifCanvasPosition,
  saveVideoCanvasPosition,
} from 'src/utils/videoHelpers';
import {
  trackApplyTemplate,
  trackRemoveBackground,
} from 'src/utils/analytics';
import { routesPaths } from 'src/routes/routesPaths';
import { LOCAL_UPLOAD, INVALID_LOCAL_UPLOAD } from 'src/constants/uploadFile';
import { ProjectService } from 'src/services/projectService';
import { setDirtyFact, triggerEventReset } from 'src/actions/rulesEngineActions';
import createAction from './createAction';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR';
export const GET_PROJECT_RESET = 'GET_PROJECT_RESET';

export const CREATE_PROJECT_FROM_TEMPLATE = 'CREATE_PROJECT_FROM_TEMPLATE';
export const CREATE_PROJECT_FROM_TEMPLATE_SUCCESS = 'CREATE_PROJECT_FROM_TEMPLATE_SUCCESS';
export const CREATE_PROJECT_FROM_TEMPLATE_REQUEST = 'CREATE_PROJECT_FROM_TEMPLATE_REQUEST';
export const CREATE_PROJECT_FROM_TEMPLATE_ERROR = 'CREATE_PROJECT_FROM_TEMPLATE_ERROR';

export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';
export const DUPLICATE_PROJECT_SUCCESS = 'DUPLICATE_PROJECT_SUCCESS';
export const DUPLICATE_PROJECT_REQUEST = 'DUPLICATE_PROJECT_REQUEST';
export const DUPLICATE_PROJECT_ERROR = 'DUPLICATE_PROJECT_ERROR';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const UPDATE_PROJECT_AUTOMATICALLY = 'UPDATE_PROJECT_AUTOMATICALLY';
export const UPDATE_PROJECT_AUTOMATICALLY_SUCCESS = 'UPDATE_PROJECT_AUTOMATICALLY_SUCCESS';
export const UPDATE_PROJECT_AUTOMATICALLY_REQUEST = 'UPDATE_PROJECT_AUTOMATICALLY_REQUEST';
export const UPDATE_PROJECT_AUTOMATICALLY_ERROR = 'UPDATE_PROJECT_AUTOMATICALLY_ERROR';

export const UPDATE_PROJECT_NAME_SUCCESS = 'UPDATE_PROJECT_NAME_SUCCESS';
export const UPDATE_PROJECT_NAME_REQUEST = 'UPDATE_PROJECT_NAME_REQUEST';
export const UPDATE_PROJECT_NAME_ERROR = 'UPDATE_PROJECT_NAME_ERROR';

export const UPDATE_PROJECT_TO_EXPORT = 'UPDATE_PROJECT_TO_EXPORT';
export const UPDATE_PROJECT_TO_EXPORT_SUCCESS = 'UPDATE_PROJECT_TO_EXPORT_SUCCESS';
export const UPDATE_PROJECT_TO_EXPORT_REQUEST = 'UPDATE_PROJECT_TO_EXPORT_REQUEST';
export const UPDATE_PROJECT_TO_EXPORT_ERROR = 'UPDATE_PROJECT_TO_EXPORT_ERROR';

export const UPDATE_PROJECT_TITLES = 'UPDATE_PROJECT_TITLES';
export const UPDATE_PROJECT_TITLES_SUCCESS = 'UPDATE_PROJECT_TITLES_SUCCESS';
export const UPDATE_PROJECT_TITLES_REQUEST = 'UPDATE_PROJECT_TITLES_REQUEST';
export const UPDATE_PROJECT_TITLES_ERROR = 'UPDATE_PROJECT_TITLES_ERROR';

export const UPDATE_PROJECT_VIDEO_INFORMATION_SUCCESS = 'UPDATE_PROJECT_VIDEO_INFORMATION_SUCCESS';
export const UPDATE_PROJECT_VIDEO_INFORMATION_REQUEST = 'UPDATE_PROJECT_VIDEO_INFORMATION_REQUEST';
export const UPDATE_PROJECT_VIDEO_INFORMATION_ERROR = 'UPDATE_PROJECT_VIDEO_INFORMATION_ERROR';

export const UPDATE_PROJECT_EMBED_CODE_GENERATED_SUCCESS =
  'UPDATE_PROJECT_EMBED_CODE_GENERATED_SUCCESS';
export const UPDATE_PROJECT_EMBED_CODE_GENERATED_REQUEST =
  'UPDATE_PROJECT_EMBED_CODE_GENERATED_REQUEST';
export const UPDATE_PROJECT_EMBED_CODE_GENERATED_ERROR =
  'UPDATE_PROJECT_EMBED_CODE_GENERATED_ERROR';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';

export const CHANGE_SIZE = 'CHANGE_SIZE';

export const CHANGE_COLOR = 'CHANGE_COLOR';

export const ADD_TO_MEDIA_BAR = 'ADD_TO_MEDIA_BAR';
export const ADD_TO_MEDIA_BAR_SUCCESS = 'ADD_TO_MEDIA_BAR_SUCCESS';
export const ADD_TO_MEDIA_BAR_REQUEST = 'ADD_TO_MEDIA_BAR_REQUEST';
export const ADD_TO_MEDIA_BAR_ERROR = 'ADD_TO_MEDIA_BAR_ERROR';

export const DELETE_FROM_MEDIA_BAR = 'DELETE_FROM_MEDIA_BAR';

export const HIDE_FROM_MEDIA_BAR = 'HIDE_FROM_MEDIA_BAR';

export const UNSPLASH_SEARCH = 'UNSPLASH_SEARCH';
export const UNSPLASH_SEARCH_SUCCESS = 'UNSPLASH_SEARCH_SUCCESS';
export const UNSPLASH_SEARCH_REQUEST = 'UNSPLASH_SEARCH_REQUEST';
export const UNSPLASH_SEARCH_ERROR = 'UNSPLASH_SEARCH_ERROR';

export const UNSPLASH_NEXT_PAGE = 'UNSPLASH_NEXT_PAGE';
export const UNSPLASH_NEXT_PAGE_SUCCESS = 'UNSPLASH_NEXT_PAGE_SUCCESS';
export const UNSPLASH_NEXT_PAGE_REQUEST = 'UNSPLASH_NEXT_PAGE_REQUEST';
export const UNSPLASH_NEXT_PAGE_ERROR = 'UNSPLASH_NEXT_PAGE_ERROR';

export const UNSPLASH_RESET = 'UNSPLASH_RESET';

export const SET_UNSPLASH_IMAGE_DIMENSIONS = 'SET_UNSPLASH_IMAGE_DIMENSIONS';

export const CLONE_ELEMENTS = 'CLONE_ELEMENTS';

export const COPY_PASTE_ELEMENTS = 'COPY_PASTE_ELEMENTS';

export const ADD_MEDIA_ELEMENTS = 'ADD_MEDIA_ELEMENTS';

export const REPLACE_IMAGE_FROM_RIGHT_CLICK = 'REPLACE_IMAGE_FROM_RIGHT_CLICK';

export const SAVE_MEDIA_ELEMENTS = 'SAVE_MEDIA_ELEMENTS';

export const ADD_TEXT_ELEMENT = 'ADD_TEXT_ELEMENT';
export const ADD_TEXT_ELEMENT_SUCCESS = 'ADD_TEXT_ELEMENT_SUCCESS';

export const WRITE_TEXT_ELEMENT = 'WRITE_TEXT_ELEMENT';

export const ADD_LINE_ELEMENT = 'ADD_LINE_ELEMENT';

export const ADD_CIRCLE_ELEMENT = 'ADD_CIRCLE_ELEMENT';

export const ADD_SQUARE_ELEMENT = 'ADD_SQUARE_ELEMENT';

export const ADD_ROUNDED_RECT_ELEMENT = 'ADD_ROUNDED_RECT_ELEMENT';

export const ADD_TRIANGLE_ELEMENT = 'ADD_TRIANGLE_ELEMENT';

export const ADD_GROUP_ELEMENT = 'ADD_GROUP_ELEMENT';

export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';

export const MOVE_ELEMENT_BACKWARD = 'MOVE_ELEMENT_BACKWARD';

export const MOVE_ELEMENT_TO_BACK = 'MOVE_ELEMENT_TO_BACK';

export const MOVE_ELEMENT_FORWARD = 'MOVE_ELEMENT_FORWARD';

export const MOVE_ELEMENT_TO_FRONT = 'MOVE_ELEMENT_TO_FRONT';

export const LOCK_ELEMENT = 'LOCK_ELEMENT';

export const UNLOCK_ELEMENT = 'UNLOCK_ELEMENT';

export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';

export const DELETE_ELEMENT_BY_ID = 'DELETE_ELEMENT_BY_ID';

export const ADD_TAG_TO_ELEMENT = 'ADD_TAG_TO_ELEMENT';

export const REMOVE_TAG_FROM_ELEMENT = 'REMOVE_TAG_FROM_ELEMENT';

export const ADD_SELECTED_ELEMENT = 'ADD_SELECTED_ELEMENT';

export const CLEAN_SELECTED_ELEMENT = 'CLEAN_SELECTED_ELEMENT';

export const REMOVE_SELECTED_ELEMENT = 'REMOVE_SELECTED_ELEMENT';

export const UNGROUP_ELEMENTS = 'UNGROUP_ELEMENTS';

export const ADD_LINK_TO_ELEMENT = 'ADD_LINK_TO_ELEMENT';

export const SAVE_VIDEOS_POSITION = 'SAVE_VIDEOS_POSITION';

export const SAVE_GIFS_POSITION = 'SAVE_GIFS_POSITION';

export const CROP_IMAGE = 'CROP_IMAGE';

export const MOVE_ELEMENT_WITH_KEYBOARD = 'MOVE_ELEMENT_WITH_KEYBOARD';

export const UNDO = 'UNDO';

export const MOVE_FRAME = 'MOVE_FRAME';

export const ADD_FRAME = 'ADD_FRAME';

export const REMOVE_IMAGE_BACKGROUND = 'REMOVE_IMAGE_BACKGROUND';
export const REMOVE_IMAGE_BACKGROUND_REQUEST = 'REMOVE_IMAGE_BACKGROUND_REQUEST';
export const REMOVE_IMAGE_BACKGROUND_SUCCESS = 'REMOVE_IMAGE_BACKGROUND_SUCCESS';
export const REMOVE_IMAGE_BACKGROUND_ERROR = 'REMOVE_IMAGE_BACKGROUND_ERROR';

export const REPLACE_BG_REMOVED_IMAGE = 'REPLACE_BG_REMOVED_IMAGE';

export const ADD_TEXT_COMBINATION = 'ADD_TEXT_COMBINATION';

export const ADD_OBJECT_COMBINATION = 'ADD_OBJECT_COMBINATION';

export const RECEIVE_SHOTSTACK_VIDEO = 'RECEIVE_SHOTSTACK_VIDEO';

export const TRANSFER_PROJECT = 'TRANSFER_PROJECT';
export const TRANSFER_PROJECT_REQUEST = 'TRANSFER_PROJECT_REQUEST';
export const TRANSFER_PROJECT_SUCCESS = 'TRANSFER_PROJECT_SUCCESS';
export const TRANSFER_PROJECT_ERROR = 'TRANSFER_PROJECT_ERROR';

export const createProjectRequest = createAction(CREATE_PROJECT_REQUEST);
export const createProjectSuccess = createAction(CREATE_PROJECT_SUCCESS);
export const createProjectError = createAction(CREATE_PROJECT_ERROR);

export const getProjectRequest = createAction(GET_PROJECT_REQUEST);
export const getProjectSuccess = createAction(GET_PROJECT_SUCCESS);
export const getProjectError = createAction(GET_PROJECT_ERROR);
export const getProjectReset = createAction(GET_PROJECT_RESET);

export const createProjectFromTemplateRequest = createAction(CREATE_PROJECT_FROM_TEMPLATE_REQUEST);
export const createProjectFromTemplateSuccess = createAction(CREATE_PROJECT_FROM_TEMPLATE_SUCCESS);
export const createProjectFromTemplateError = createAction(CREATE_PROJECT_FROM_TEMPLATE_ERROR);

export const duplicateProjectRequest = createAction(DUPLICATE_PROJECT_REQUEST);
export const duplicateProjectSuccess = createAction(DUPLICATE_PROJECT_SUCCESS);
export const duplicateProjectError = createAction(DUPLICATE_PROJECT_ERROR);

export const updateProjectRequest = createAction(UPDATE_PROJECT_REQUEST);
export const updateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS);
export const updateProjectError = createAction(UPDATE_PROJECT_ERROR);

export const updateProjectAutomaticallyRequest = createAction(UPDATE_PROJECT_AUTOMATICALLY_REQUEST);
export const updateProjectAutomaticallySuccess = createAction(UPDATE_PROJECT_AUTOMATICALLY_SUCCESS);
export const updateProjectAutomaticallyError = createAction(UPDATE_PROJECT_AUTOMATICALLY_ERROR);

export const updateProjectNameRequest = createAction(UPDATE_PROJECT_NAME_REQUEST);
export const updateProjectNameSuccess = createAction(UPDATE_PROJECT_NAME_SUCCESS);
export const updateProjectNameError = createAction(UPDATE_PROJECT_NAME_ERROR);

export const updateProjectToExportRequest = createAction(UPDATE_PROJECT_TO_EXPORT_REQUEST);
export const updateProjectToExportSuccess = createAction(UPDATE_PROJECT_TO_EXPORT_SUCCESS);
export const updateProjectToExportError = createAction(UPDATE_PROJECT_TO_EXPORT_ERROR);

export const updateProjectTitlesRequest = createAction(UPDATE_PROJECT_TITLES_REQUEST);
export const updateProjectTitlesSuccess = createAction(UPDATE_PROJECT_TITLES_SUCCESS);
export const updateProjectTitlesError = createAction(UPDATE_PROJECT_TITLES_ERROR);

export const updateProjectVideoInformationRequest =
  createAction(UPDATE_PROJECT_VIDEO_INFORMATION_REQUEST);
export const updateProjectVideoInformationSuccess =
  createAction(UPDATE_PROJECT_VIDEO_INFORMATION_SUCCESS);
export const updateProjectVideoInformationError =
  createAction(UPDATE_PROJECT_VIDEO_INFORMATION_ERROR);

export const updateProjectEmbedCodeGeneratedRequest =
  createAction(UPDATE_PROJECT_EMBED_CODE_GENERATED_REQUEST);
export const updateProjectEmbedCodeGeneratedSuccess =
  createAction(UPDATE_PROJECT_EMBED_CODE_GENERATED_SUCCESS);
export const updateProjectEmbedCodeGeneratedError =
  createAction(UPDATE_PROJECT_EMBED_CODE_GENERATED_ERROR);

export const deleteProjectRequest = createAction(DELETE_PROJECT_REQUEST);
export const deleteProjectSuccess = createAction(DELETE_PROJECT_SUCCESS);
export const deleteProjectError = createAction(DELETE_PROJECT_ERROR);

export const changeLayout = createAction(CHANGE_LAYOUT);

export const changeSize = createAction(CHANGE_SIZE);

export const changeColor = createAction(CHANGE_COLOR);

export const unsplashSearchRequest = createAction(UNSPLASH_SEARCH_REQUEST);
export const unsplashSearchSuccess = createAction(UNSPLASH_SEARCH_SUCCESS);
export const unsplashSearchError = createAction(UNSPLASH_SEARCH_ERROR);

export const unsplashNextPageRequest = createAction(UNSPLASH_NEXT_PAGE_REQUEST);
export const unsplashNextPageSuccess = createAction(UNSPLASH_NEXT_PAGE_SUCCESS);
export const unsplashNextPageError = createAction(UNSPLASH_NEXT_PAGE_ERROR);

export const unsplashReset = createAction(UNSPLASH_RESET);

export const setUnsplashImageDimensions = createAction(SET_UNSPLASH_IMAGE_DIMENSIONS);

export const cloneElements = createAction(CLONE_ELEMENTS);

export const copyPasteElements = createAction(COPY_PASTE_ELEMENTS);

export const addMediaElements = createAction(ADD_MEDIA_ELEMENTS);

export const replaceImageFromRightClick = createAction(REPLACE_IMAGE_FROM_RIGHT_CLICK);

export const saveMediaElements = createAction(SAVE_MEDIA_ELEMENTS);

export const addTextElementSuccess = createAction(ADD_TEXT_ELEMENT_SUCCESS);

export const writeTextElement = createAction(WRITE_TEXT_ELEMENT);

export const addLineElement = createAction(ADD_LINE_ELEMENT);

export const addCircleElement = createAction(ADD_CIRCLE_ELEMENT);

export const addSquareElement = createAction(ADD_SQUARE_ELEMENT);

export const addRoundedRectElement = createAction(ADD_ROUNDED_RECT_ELEMENT);

export const addTriangleElement = createAction(ADD_TRIANGLE_ELEMENT);

export const addGroupElement = createAction(ADD_GROUP_ELEMENT);

export const updateElementSuccess = createAction(UPDATE_ELEMENT);

export const moveElementBackward = createAction(MOVE_ELEMENT_BACKWARD);

export const moveElementToBack = createAction(MOVE_ELEMENT_TO_BACK);

export const moveElementForward = createAction(MOVE_ELEMENT_FORWARD);

export const moveElementToFront = createAction(MOVE_ELEMENT_TO_FRONT);

export const lockElement = createAction(LOCK_ELEMENT);

export const unlockElement = createAction(UNLOCK_ELEMENT);

export const deleteElementSuccess = createAction(DELETE_ELEMENT);

export const deleteElementById = createAction(DELETE_ELEMENT_BY_ID);

export const addTagToElement = createAction(ADD_TAG_TO_ELEMENT);

export const removeTagFromElement = createAction(REMOVE_TAG_FROM_ELEMENT);

export const addSelectedElement = createAction(ADD_SELECTED_ELEMENT);

export const cleanSelectedElement = createAction(CLEAN_SELECTED_ELEMENT);

export const removeSelectedElement = createAction(REMOVE_SELECTED_ELEMENT);

export const ungroupElementsSuccess = createAction(UNGROUP_ELEMENTS);

export const addLinkToElement = createAction(ADD_LINK_TO_ELEMENT);

export const saveVideosPosition = createAction(SAVE_VIDEOS_POSITION);

export const saveGifsPosition = createAction(SAVE_GIFS_POSITION);

export const cropImage = createAction(CROP_IMAGE);

export const moveElementWithKeyboard = createAction(MOVE_ELEMENT_WITH_KEYBOARD);

export const moveFrame = createAction(MOVE_FRAME);

export const addFrame = createAction(ADD_FRAME);

export const removeImageBackgroundRequest = createAction(REMOVE_IMAGE_BACKGROUND_REQUEST);
export const removeImageBackgroundSuccess = createAction(REMOVE_IMAGE_BACKGROUND_SUCCESS);
export const removeImageBackgroundError = createAction(REMOVE_IMAGE_BACKGROUND_ERROR);

export const replaceBgRemovedImage = createAction(REPLACE_BG_REMOVED_IMAGE);

export const addTextCombination = createAction(ADD_TEXT_COMBINATION);

export const addObjectCombination = createAction(ADD_OBJECT_COMBINATION);

export const receiveShotstackVideo = createAction(RECEIVE_SHOTSTACK_VIDEO);

export const transferProjectRequest = createAction(TRANSFER_PROJECT_REQUEST);
export const transferProjectSuccess = createAction(TRANSFER_PROJECT_SUCCESS);
export const transferProjectError = createAction(TRANSFER_PROJECT_ERROR);

export const unsplashSearch = (
  param,
  page,
  perPage = 15,
) => async dispatch => {
  try {
    dispatch(unsplashSearchRequest());
    const {
      data: { results, total_pages: totalPages },
    } = await ProjectService.unsplashSearch(param, page, perPage);

    const images = results.map(({ id, urls, links, user: { name, links: authorLink } }) => (
      {
        id,
        preview: urls.regular,
        author: { name, link: authorLink.html },
        downloadLocation: links.download_location,
      }
    ));
    dispatch(unsplashSearchSuccess({ images, totalPages }));
  } catch (err) {
    dispatch(unsplashSearchError());
  }
};

export const unsplashNextPage = (param, page, perPage = 15) => async dispatch => {
  try {
    dispatch(unsplashNextPageRequest());
    const {
      data: { results, total_pages: totalPages },
    } = await ProjectService.unsplashSearch(param, page, perPage);

    const images = results.map(({ id, urls, links, user: { name, links: authorLink } }) => (
      {
        id,
        preview: urls.regular,
        author: { name, link: authorLink.html },
        downloadLocation: links.download_location,
      }
    ));
    dispatch(unsplashNextPageSuccess({ images, totalPages }));
  } catch (err) {
    dispatch(unsplashNextPageError());
  }
};

export const unsplashDownload = link => {
  try {
    ProjectService.unsplashDownload(link);
  } catch (err) { }
};

const createQrCodeForProject = async (account, project) => {
  const qrName = getCloudinaryFilenameWithoutTimestamp(
    getCloudinaryFilenameFromUrl(account.qrCodeUrl),
  );
  const qrCode = { name: qrName, src: account.qrCodeUrl };
  const [elem] = await duplicateAssetFiles([qrCode]);
  const { ratio } = calculateAspectRatioFit(
    elem.width, elem.height, project.layoutWidth * 0.70, project.layoutHeight * 0.70,
  );
  const uuid = uuidv4();
  const mediaElement = {
    type: IMAGE_ELEMENT,
    uploadType: LOCAL_UPLOAD,
    saved: elem.saved,
    src: elem.src,
    x: project.layoutWidth / 2,
    y: project.layoutHeight / 2,
    scaleX: ratio,
    scaleY: ratio,
    rotation: 0,
    uuid,
    width: elem.width,
    height: elem.height,
    unlocked: true,
    qrImage: true,
  };
  const newElements = JSON.parse(project.elements || null) || [];
  newElements.push(mediaElement);
  project.elements = JSON.stringify(newElements);
  return project;
};

export const createProject = (project, account, history, historyAction) => async (dispatch) => {
  try {
    dispatch(createProjectRequest());
    if (project.type === PROJECT_TYPE.SIGN && account.qrCodeUrl) {
      project = await createQrCodeForProject(account, project);
    }
    const { data } = await ProjectService.createProject({ project, accountId: account.id });
    dispatch(createProjectSuccess(data));
    history && history.push(
      routeWithProps(routesPaths.project, { id: data.project.id }),
      { data: historyAction },
    );
  } catch (error) {
    dispatch(createProjectError());
  }
};

export const getProject = id => async dispatch => {
  try {
    dispatch(getProjectRequest());
    const { data } = await ProjectService.getProject(id);
    dispatch(getProjectSuccess(data));
  } catch (error) {
    dispatch(getProjectError());
    Sentry.captureException(error);
  }
};

export const createProjectFromTemplate = (template, accountId, history) => async dispatch => {
  try {
    dispatch(createProjectFromTemplateRequest());
    const { data } = await ProjectService.createProjectFromTemplate(template.id, accountId);
    dispatch(createProjectFromTemplateSuccess());
    trackApplyTemplate(data.project.id, template.category.name, template.layoutSource);
    history.push(routeWithProps(routesPaths.project, { id: data.project.id }));
  } catch (error) {
    dispatch(createProjectFromTemplateError(error?.data?.message));
  }
};

export const duplicateProject = projectId => async dispatch => {
  const toastId = 'duplicateProject';
  try {
    dispatch(duplicateProjectRequest());
    toast(<ToastLoading message="Duplicating Stagger" />, toastInfoConfig(toastId));
    const { data } = await ProjectService.duplicateProject(projectId);
    dispatch(duplicateProjectSuccess(data));
    toast.success('Seeing double, your project is copied ✌️', toastSuccessConfig);
  } catch (error) {
    toast.error('Error while duplicating Stagger', toastErrorConfig);
    dispatch(duplicateProjectError());
  } finally {
    toast.dismiss(toastId);
  }
};

export const saveCanvasFiles = async (elements) => (
  promiseInListWithLimit(elements, async elem => {
    if (MEDIA_ELEMENTS.includes(elem.type) && !elem.saved) {
      try {
        const { data: { url, width, height } } = await ProjectService.saveFileFromLocal(elem);
        return { ...elem, saved: true, file: undefined, src: url, width, height };
      } catch (error) {
        toast.error(error?.data?.message, toastErrorConfig);
        return {
          ...elem,
          saved: true,
          file: undefined,
          uploadType: INVALID_LOCAL_UPLOAD,
        };
      }
    }
    return elem;
  })
);

export const saveCanvasGroupsFiles = async groupsOfElementsById => {
  const ungroupedImages = [];

  Object.entries(groupsOfElementsById).forEach(([groupId, group]) => {
    group
      .forEach((elem, index) => {
        if (MEDIA_ELEMENTS.includes(elem.type) && !elem.saved) {
          ungroupedImages.push({ groupId, index, elem });
        }
      });
  });
  const values = await promiseInListWithLimit(ungroupedImages,
    async ({ groupId, index, elem }) => {
      const [savedElement] = await saveCanvasFiles([elem]);
      return { element: savedElement, index, groupId };
    });
  const newGroups = produce(groupsOfElementsById, copyGroupsOfElementsById => {
    values.forEach(groupItem => {
      const { groupId, index, element } = groupItem;
      copyGroupsOfElementsById[groupId][index] = element;
    });
  });
  return newGroups;
};

export const savePreviewFiles = async (files, dataToUpdate, projectType, projectId) => {
  const elements = JSON.parse(dataToUpdate.elements);
  const videoIdsByPosition = JSON.parse(dataToUpdate.videoIdsByPosition);
  const gifIdsByPosition = JSON.parse(dataToUpdate.gifIdsByPosition);
  const previewFiles = await promiseInListWithLimit(files, async (file, index) => {
    const { layoutWidth, layoutHeight } = dataToUpdate;
    const fileExtraData = {
      canvasIndex: index,
      projectType,
      projectId,
      layoutWidth,
      layoutHeight,
    };
    let videoData;
    const frameHasVideo = videoIdsByPosition && videoIdsByPosition[index]?.length;
    if (frameHasVideo) {
      const video = elements.find(elem => elem.uuid === videoIdsByPosition[index][0]);
      const { xExport, yExport } = calculatePositionVideoExport(
        video,
        index,
        layoutWidth,
        layoutHeight,
      );
      const scaleVideo = calculateScaleVideo(video, layoutWidth, layoutHeight);
      videoData = {
        url: video.src,
        duration: video.duration,
        scale: scaleVideo,
        x: xExport,
        y: yExport,
      };
    }
    let gifData;
    const frameHasGif = gifIdsByPosition && gifIdsByPosition[index]?.length;
    if (frameHasGif) {
      gifData = gifIdsByPosition[index].map(gifId => {
        const gif = elements.find(elem => elem.uuid === gifId);
        const { xExport, yExport } = calculatePositionVideoExport(
          gif,
          index,
          layoutWidth,
          layoutHeight,
        );
        const scaleGif = calculateScaleVideo(gif, layoutWidth, layoutHeight);
        return ({
          url: gif.src,
          duration: gif.duration,
          scale: scaleGif,
          x: xExport,
          y: yExport,
        });
      });
    }
    index += 1;
    const { data } = await ProjectService.savePreviewFile(file, fileExtraData, videoData, gifData);
    return data;
  });
  const previews = previewFiles.map(elem => elem.url);
  const videoIdsShotstack = previewFiles.map(elem => elem.videoId);
  const signDownloads = previewFiles.map(elem => elem.transformedUrl);
  return { previews, videoIdsShotstack, signDownloads };
};

export const saveThumbnailFile = async file => {
  const { data: { url } } = await ProjectService.saveFileFromLocal({ file });
  return url;
};

const getParamsToUpdateProject = async (
  params,
  fileThumbnail,
  toastId,
) => {
  let dataToUpdate = params;
  const elements = await saveCanvasFiles(params.elements);
  const groupsOfElementsById = await saveCanvasGroupsFiles(params.groupsOfElementsById);
  dataToUpdate = {
    ...dataToUpdate,
    elements: JSON.stringify(elements),
    videoIdsByPosition: JSON.stringify(params.videoIdsByPosition),
    gifIdsByPosition: JSON.stringify(params.gifIdsByPosition),
    groupsOfElementsById: JSON.stringify(groupsOfElementsById),
  };
  if (fileThumbnail) {
    toastId && toast.update(toastId, {
      render: <ToastLoading message="This is a beautiful collage!" emoji="🙌" />,
    });
    const thumbnail = await saveThumbnailFile(fileThumbnail);
    dataToUpdate = {
      ...dataToUpdate,
      thumbnail,
    };
  }
  return dataToUpdate;
};

export const updateProject = (id, params, fileThumbnail) => (
  async (dispatch, getState) => {
    const toastId = 'savingDraft';
    try {
      toast(<ToastLoading message="Saving Draft" />, toastInfoConfig(toastId));
      const { rulesEngine: { projectFacts }, projectMemory } = getState();
      params.facts = projectFacts;
      params.memory = projectMemory;
      const dataToUpdate = await getParamsToUpdateProject(params, fileThumbnail);
      const { data } = await ProjectService.updateProject(id, dataToUpdate);
      dispatch(updateProjectSuccess(data));
      toast.success('Your work is saved, time for coffee ☕', toastSuccessConfig);
    } catch (error) {
      toast.error(error?.data?.message, toastErrorConfig);
      dispatch(updateProjectError());
    } finally {
      toast.dismiss(toastId);
    }
  }
);

export const updateProjectAutomatically = (
  id,
  params,
  fileThumbnail,
) => (
  async (dispatch, getState) => {
    try {
      const {
        rulesEngine: { projectFacts },
        projectState: { forceMediaAutoSave, actionsUnsaved },
        projectMemory,
      } = getState();
      params.facts = projectFacts;
      params.memory = projectMemory;
      const dataToUpdate = await getParamsToUpdateProject(params, fileThumbnail);
      const { data } = await ProjectService.updateProjectAutomatically(
        id,
        dataToUpdate,
      );
      dispatch(updateProjectAutomaticallySuccess({ ...data, amountUnsaved: actionsUnsaved }));
      if (forceMediaAutoSave) {
        dispatch(saveMediaElements());
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(updateProjectAutomaticallyError());
    }
  }
);

export const updateProjectName = (id, params) => async dispatch => {
  try {
    dispatch(updateProjectNameRequest());
    const { data: { project } } = await ProjectService.updateProject(id, params);
    const { name } = project;
    dispatch(updateProjectNameSuccess({ name }));
    toast.success('Name saved', toastSuccessConfig);
  } catch (error) {
    dispatch(updateProjectNameError());
    if (error?.data?.details) {
      throw error.data.details;
    }
  }
};

export const updateProjectFrameTitles = (id, params) => async dispatch => {
  try {
    dispatch(updateProjectTitlesRequest());
    const { data: { project } } = await ProjectService.updateProject(id, params);
    const { frameTitles } = project;
    dispatch(updateProjectTitlesSuccess({ frameTitles }));
  } catch (error) {
    dispatch(updateProjectTitlesError());
    if (error?.data?.details) {
      throw error.data.details;
    }
  }
};

export const updateProjectVideoInformation = (id, params) => async dispatch => {
  try {
    dispatch(updateProjectVideoInformationRequest());
    const { data: { project } } = await ProjectService.updateProject(id, params);
    const { showedVideoInformation } = project;
    dispatch(updateProjectVideoInformationSuccess({ showedVideoInformation }));
  } catch (error) {
    dispatch(updateProjectVideoInformationError());
  }
};

export const updateProjectEmbedCodeGenerated = (id, params) => async dispatch => {
  try {
    dispatch(updateProjectEmbedCodeGeneratedRequest());
    const { data: { project } } = await ProjectService.updateProject(id, params);
    const { embedCodeGenerated } = project;
    dispatch(updateProjectEmbedCodeGeneratedSuccess({ embedCodeGenerated }));
  } catch (error) {
    dispatch(updateProjectEmbedCodeGeneratedError());
  }
};

export const updateProjectToExport = (
  id,
  files,
  fileThumbnail,
  callback,
  showFeedback = true,
  generatePdf = false,
) => async (dispatch, getState) => {
  const toastId = showFeedback ? 'updateProjectToExport' : null;
  try {
    toastId && toast(<ToastLoading message="Prepping your design" emoji="🛠" />,
      toastInfoConfig(toastId));
    const state = getState();
    const { project: { present }, rulesEngine: { projectFacts }, projectMemory } = state;
    const params = {
      size: present.size,
      color: present.color.hex,
      alpha: present.color.alpha,
      layoutWidth: present.layout.width,
      layoutHeight: present.layout.height,
      layoutSource: present.layout.source,
      elements: present.elements,
      groupsOfElementsById: present.groupsOfElementsById,
      videoIdsByPosition: present.videoIdsByPosition,
      gifIdsByPosition: present.gifIdsByPosition,
      videoUrls: [],
      frameTitles: present.frameTitles,
      facts: projectFacts,
      memory: projectMemory,
    };
    let dataToUpdate = await getParamsToUpdateProject(params, fileThumbnail, toastId);
    toastId && toast.update(toastId, {
      render: <ToastLoading message="Preparing your proofs" emoji="🖼️" />,
    });
    const {
      previews,
      videoIdsShotstack,
      signDownloads,
    } = await savePreviewFiles(files, dataToUpdate, present.type, present.id);
    dataToUpdate = { ...dataToUpdate, previews, videoIdsShotstack };
    toastId && toast.update(toastId, {
      render: <ToastLoading message="Here we go! 3, 2, 1..." emoji="🍾" />,
    });
    if (present.type === PROJECT_TYPE.SIGN) {
      dataToUpdate.signSettings = { downloads: signDownloads, generatePdf };
    }
    const { data: { project } } = await ProjectService.updateProject(id, dataToUpdate);

    dispatch(updateProjectToExportSuccess({
      previews: project.previews,
      thumbnail: project.thumbnail,
      videoIds: project.videoIdsShotstack,
      videoUrls: project.videoUrls,
      signSettings: project.signSettings,
      id: project.id,
      callback,
    }));
  } catch (error) {
    const message = error?.data?.message || 'There was an error while exporting.';
    toastId && toast.error(message, toastErrorConfig);
    !error?.data && Sentry.captureException(error);
    dispatch(updateProjectToExportError());
  } finally {
    toastId && toast.dismiss(toastId);
  }
};

export const deleteProject = projectId => async dispatch => {
  try {
    dispatch(deleteProjectRequest());
    await ProjectService.deleteProject(projectId);
    dispatch(deleteProjectSuccess({ projectId }));
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(deleteProjectError());
  }
};

const toastIsOverlapping = (width, element, newElement) => {
  const isOverlapping = isElementOverlapping({
    xElement: newElement.x || element.x,
    widthElement: element.width,
    scaleXElement: newElement.scaleX || element.scaleX,
    widthLayout: width,
    isText: element.type === TEXT_ELEMENT,
  });
  if (isOverlapping && !!element.link) {
    toast.error('Linked element cannot overlap two canvases.', toastErrorConfig);
  }
  if (isOverlapping && element.type === VIDEO_ELEMENT) {
    toast.error('Video cannot overlap two canvases.', toastErrorConfig);
  }
  if (isOverlapping && element.type === IMAGE_ELEMENT && isGif(element.src)) {
    toast.error('Gif cannot overlap two canvases.', toastErrorConfig);
  }
  return isOverlapping;
};

export const updateElement = (newElement, timestamp) => async (dispatch, getState) => {
  const state = getState();
  const { project: { present } } = state;
  const { layout: { width } } = present;
  const elem = present.elements.find(element => element.uuid === newElement.uuid);
  const isOverlapping = toastIsOverlapping(width, elem, newElement);
  const newAttrs = { ...newElement, isOverlapping };
  if (elem.type === VIDEO_ELEMENT) {
    const videoAttrs = { ...elem, ...newAttrs };
    const videos = saveVideoCanvasPosition(present, videoAttrs);
    dispatch(saveVideosPosition({ videos }));
  } else if (elem.type === IMAGE_ELEMENT && isGif(elem.src)) {
    const gifAttrs = { ...elem, ...newAttrs };
    const gifs = saveGifCanvasPosition(present, gifAttrs);
    dispatch(saveGifsPosition({ gifs }));
  }
  dispatch(updateElementSuccess({ attrs: newAttrs, timestamp }));
  ENABLE_DESIGN_TIPS && dispatch(setDirtyFact({
    elements: [{ ...elem, ...newAttrs }],
    action: {
      type: UPDATE_ELEMENT,
      payload: { attrs: newAttrs, timestamp },
    },
  }));
};

export const ungroupElements = (uuid) => async (dispatch, getState) => {
  const state = getState();
  const { canvas: { scale } } = state;
  dispatch(ungroupElementsSuccess({ scale, uuid }));
};

export const deleteElement = () => async (dispatch, getState) => {
  const { project: { present: project } } = getState();
  dispatch(deleteElementSuccess());

  const selectedElementsUUIDs = Object.keys(project.selectedElements);
  const selectedElements = project.elements.filter(e => selectedElementsUUIDs.includes(e.uuid));
  ENABLE_DESIGN_TIPS && dispatch(setDirtyFact({
    elements: selectedElements,
    action: {
      type: DELETE_ELEMENT,
      payload: null,
    },
  }));
};

export const addTextElement = (attrs) => async dispatch => {
  dispatch(addTextElementSuccess(attrs));
  ENABLE_DESIGN_TIPS && dispatch(setDirtyFact({
    elements: [{ ...attrs }],
    action: {
      type: ADD_TEXT_ELEMENT,
      payload: attrs,
    },
  }));
};

export const undo = () => async dispatch => {
  dispatch(ActionCreators.undo());
  dispatch(triggerEventReset());
};

export const removeImageBackground = (projectId, image) => async dispatch => {
  try {
    dispatch(removeImageBackgroundRequest({ imageUUID: image.uuid }));
    trackRemoveBackground(image.uploadType);
    await ProjectService.removeImageBackground(projectId, image.src);
    dispatch(removeImageBackgroundSuccess({ imageUUID: image.uuid }));
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(removeImageBackgroundError());
  }
};

export const removeElementOnFrameDeletion = (elem) => async (dispatch, getState) => {
  const { project: { present: project } } = getState();
  try {
    if (project.selectedElements[elem.id]) {
      await dispatch(removeSelectedElement(elem.id));
    }
    dispatch(deleteElementById(elem));
  } catch (error) {
    Sentry.captureMessage(
      'Error while trying to delete an element on frame deletion',
      { extra: { selectedElemId: elem.id, projectId: project.id } },
    );
  }
};

export const transferProject = (projectId, accountIdTo) => async dispatch => {
  try {
    dispatch(transferProjectRequest());
    await ProjectService.transferProject(projectId, accountIdTo);
    dispatch(transferProjectSuccess({ projectId }));
  } catch (error) {
    toast.error(error?.data?.message, toastErrorConfig);
    dispatch(transferProjectError());
  }
};
