import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FBLogin } from 'src/pages/dashboard/instagram-dashboard/fb-login';
import { useCheckFBLoginStatus } from 'src/hooks';
import { Button } from 'src/common/button/Button';
import { logoutFromFB } from 'src/actions/instagramAnalyticsActions';
import FacebookIcon from './facebook.svg';
import InstagramIcon from './instagram.svg';
import styles from './SocialNetworks.module.scss';

const SocialNetworks = () => {
  const dispatch = useDispatch();
  const loggedIn = useCheckFBLoginStatus();

  const removeConnection = useCallback(() => {
    dispatch(logoutFromFB());
  }, [dispatch]);


  let containerToShow;
  if (loggedIn) {
    containerToShow = (
      <div className={styles.loggedContainer}>
        <img src={FacebookIcon} className={styles.facebookIcon} alt="Facebook" />
        <img src={InstagramIcon} className={styles.instagramIcon} alt="Instagram" />
        <span className={styles.connectedMessage}>
          Your <strong>Stagger</strong> Facebook and Intagram account are successfuly connected.
        </span>
      </div>
    );
  } else {
    containerToShow = (
      <div className={styles.buttonContainer}>
        <span className={styles.title}>No social accounts connected.</span>
        <span className={styles.subtitle}>
          Connect your Instagram through Facebook account to let Stagger
          analyze the performance of your stories and creative.
        </span>
        <FBLogin loggedIn={loggedIn} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          Social Networks
        </div>
        {loggedIn && (
          <Button
            className={styles.remove}
            onClick={removeConnection}
          >
            Remove Connection
          </Button>
        )}
      </div>
      {containerToShow}
    </div>
  );
};

export { SocialNetworks };
