import { useState } from 'react';

const usePagination = (callback, optimized = false) => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const prevPage = () => {
    if (page > 1) {
      !optimized && callback(page - 1);
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    const newPage = page + 1;
    if (!optimized) {
      callback(newPage);
    } else if (optimized && newPage > maxPage) {
      callback(newPage);
      setMaxPage(newPage);
    }
    setPage(newPage);
  };

  return { page, prevPage, nextPage, setPage };
};

export { usePagination };
