import React from 'react';
import { func } from 'prop-types';

import { Button } from '../../../common/button';
import styles from './Step4AllSet.module.scss';

const Step4AllSet = ({ closeOnboarding }) => (
  <div>
    <div className={styles.allSetHeader}>
      You&apos;re all set!
    </div>
    <p className={styles.message}>
      We have everything we need to start learning your brand
      and helping you tell your story. Let’s get started!
    </p>
    <Button className={styles.launchEditor} onClick={closeOnboarding}>
      Launch the Story Editor
    </Button>
  </div>
);

Step4AllSet.propTypes = {
  closeOnboarding: func,
};

export { Step4AllSet };
