import React, { useState } from 'react';
import { string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import DeleteIcon from 'src/assets/icons/delete-tool-white.svg';
import { LoadingCard } from 'src/common/loading-card';
import {
  getCloudinaryFilenameWithoutTimestamp,
  getCloudinaryFilenameFromUrl,
} from 'src/utils/cloudinaryHelpers';
import { deleteImageFromChat } from 'src/actions/collageActions';
import { useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';
import { UploadedMediaCard } from '../../add-media-control/uploaded-media-card';
import styles from './MediaInChat.module.scss';

const MediaInChat = ({ loadingId }) => {
  const dispatch = useDispatch();

  const { chatMedia } = useSelector(({ collage }) => ({
    chatMedia: collage.chatMedia,
  }));

  const { windowSize: { width } } = useWindowSize();
  const isSmallWidth = width <= SMALL_WIDTH;

  const [showDelete, setShowDelete] = useState(false);

  if (!chatMedia[loadingId]) {
    return null;
  }

  const loadingList = Array.from({ length: chatMedia[loadingId].loadingAmount },
    (_, i) => ({ loading: true, key: i }));
  const elemList = chatMedia[loadingId].media.map(media => ({ loading: false, url: media }));
  const result = [...loadingList, ...elemList];

  const onClickDelete = (url) => {
    dispatch(deleteImageFromChat({ loadingId, url }));
  };

  const onMouseEnter = (url) => {
    !isSmallWidth && setShowDelete(url);
  };

  const onMouseLeave = () => {
    !isSmallWidth && setShowDelete();
  };

  return (
    <div className={styles.container}>
      {result.map((elem) => {
        if (elem.loading) {
          return (
            <LoadingCard key={elem.key} className={styles.loadingCard} />
          );
        }
        const id = getCloudinaryFilenameFromUrl(elem.url);
        const name = getCloudinaryFilenameWithoutTimestamp(id);
        return (
          <div
            key={elem.url}
            className={styles.cardContainer}
            onMouseEnter={() => onMouseEnter(elem.url)}
            onMouseLeave={() => onMouseLeave(elem.url)}
          >
            <UploadedMediaCard
              preview={elem.url}
              id={id}
              name={name}
              addImage={() => {}}
              isSelected={false}
              disabled
            />
            {(isSmallWidth || showDelete === elem.url) && (
              <button
                className={styles.deleteButton}
                onClick={() => onClickDelete(elem.url)}
              >
                <img src={DeleteIcon} alt="Delete" />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

MediaInChat.propTypes = {
  loadingId: string.isRequired,
};

export { MediaInChat };
