import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  createCustomerPortalSession,
  CREATE_CUSTOMER_PORTAL_SESSION,
  GET_BILLING_INFORMATION,
} from 'src/actions/userActions';
import { PLAN } from 'src/constants/memberships';
import { Alert } from 'src/common/alert';
import { routesPaths } from 'src/routes/routesPaths';
import { useSession, useStatus } from 'src/hooks';
import { LOADING, NOT_STARTED } from 'src/constants/status';
import { Button } from 'src/common/button/Button';
import { PlanSection } from './plan-section';
import { BillingSection } from './billing-section';
import styles from './BillingInformation.module.scss';

const BillingInformation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    user: { customerId, membership: { subscription, plan } },
    billingInformation = {},
  } = useSession();

  const { cardInformation, addressInformation } = billingInformation;

  const formattedAddressInformation = useMemo(() => {
    const formatted = {};
    if (!addressInformation?.line1) {
      return formatted;
    }
    formatted.line1 = addressInformation.line2
      ? `${addressInformation.line1}, ${addressInformation.line2}`
      : addressInformation.line1;
    formatted.line2 = `${addressInformation.city},
     ${addressInformation.state || ''}
     ${addressInformation.country || ''} ${addressInformation.postal_code}`;
    return formatted;
  }, [addressInformation]);

  const manageBilling = useCallback(() => {
    if (subscription && customerId) {
      dispatch(createCustomerPortalSession(customerId, pathname));
    } else {
      history.push(routesPaths.choosePlan, { pathname });
    }
  }, [customerId, subscription, dispatch, history, pathname]);

  const { status: createSessionStatus } = useStatus(CREATE_CUSTOMER_PORTAL_SESSION);
  const { status: getBillingStatus } = useStatus(GET_BILLING_INFORMATION);

  const actualPlan = plan === PLAN.BETA ? PLAN.FREE : plan;
  const planMembership = actualPlan.substring(0, 1).toUpperCase() + actualPlan.substring(1);

  const frequency = useMemo(() => {
    if (!subscription) return '-';
    return subscription.type === 'month' ? 'Monthly' : 'Yearly';
  }, [subscription]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          Billing Information
        </div>
        <Button
          className={styles.manage}
          onClick={manageBilling}
          loading={createSessionStatus === LOADING}
          spinnerClassName={styles.spinnerIcon}
        >
          Manage billing
        </Button>
      </div>
      {getBillingStatus !== NOT_STARTED && subscription && !subscription.active && (
        <Alert containerClassName={styles.alert}>
          <span className={styles.alertText}>
            Your subscription is paused. You need to update your payment method.
          </span>
          <Button className={styles.alertButton} onClick={manageBilling} secondary short>
            Update
          </Button>
        </Alert>
      )}
      <PlanSection plan={planMembership} frequency={frequency} />
      <BillingSection
        title="Payment Info"
        defaultMessage="No payment info"
        line1={`•••• •••• •••• ${cardInformation?.last4}`}
        line2={cardInformation?.brand}
        loading={getBillingStatus === LOADING}
      />
      <BillingSection
        title="Billing Info"
        defaultMessage="No billing info"
        line1={formattedAddressInformation?.line1}
        line2={formattedAddressInformation?.line2}
        loading={getBillingStatus === LOADING}
      />
    </div>
  );
};

export { BillingInformation };
