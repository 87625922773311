import React from 'react';
import { string, func } from 'prop-types';

import styles from './ShapeSelectOption.module.scss';

const ShapeSelectOption = ({ icon, onClick, alt }) => (
  <button onClick={onClick} className={styles.button}>
    <img src={icon} alt={alt} />
  </button>
);

ShapeSelectOption.propTypes = {
  icon: string.isRequired,
  alt: string.isRequired,
  onClick: func.isRequired,
};

export { ShapeSelectOption };
