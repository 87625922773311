import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { func } from 'prop-types';

import { Input } from 'src/common/input';
import { Select } from 'src/common/select';
import { Button } from 'src/common/button';
import { useForm, useTextInputProps, useStatus, useSession } from 'src/hooks';
import { validateInvite } from 'src/utils/constrains';
import { LOADING, SUCCESS, ERROR } from 'src/constants/status';
import { inviteToAccounts, INVITE_TO_ACCOUNTS } from 'src/actions/accountActions';
import { reset } from 'src/actions/statusActions';
import styles from './AddEditorForm.module.scss';

const AddEditorForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSession();

  const availableAccounts = user.membership.accounts.map(account => ({
    value: account.id,
    label: account.brandName,
  }));

  const addInviteRequest = useCallback(async (values) => {
    const invite = {
      ...values,
      accounts: values.accounts.map(account => account.value),
    };
    invite.email = values.email.trim();
    await dispatch(inviteToAccounts(invite));
  }, [dispatch]);

  const { status: statusInviteToAccounts, error } = useStatus(INVITE_TO_ACCOUNTS);

  useEffect(() => {
    if (statusInviteToAccounts === SUCCESS) {
      onCancel();
    }

    return () => {
      if (statusInviteToAccounts === SUCCESS || statusInviteToAccounts === ERROR) {
        dispatch(reset(INVITE_TO_ACCOUNTS));
      }
    };
  }, [dispatch, onCancel, statusInviteToAccounts]);

  const {
    values,
    touched,
    errors,
    refs,
    handleInputChange,
    handleSubmit,
  } = useForm(addInviteRequest, { validate: validateInvite });

  const inputProps = useTextInputProps({
    handleInputChange,
    values,
    errors,
    touched,
    refs,
  });

  const selectOnChange = useCallback((name, value) => {
    handleInputChange(name, value);
  }, [handleInputChange]);

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.fieldsContainer}>
          <Input
            name="name"
            placeholder="First name"
            containerClassName={styles.input}
            disabled={false}
            {...inputProps('name')}
          />
          <Input
            name="lastName"
            placeholder="Last name"
            containerClassName={styles.input}
            disabled={false}
            {...inputProps('lastName')}
          />
          <Input
            name="email"
            placeholder="Email address"
            containerClassName={styles.input}
            disabled={false}
            {...inputProps('email')}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Select
            options={availableAccounts}
            placeholder="Choose accounts"
            name="accounts"
            {...inputProps('accounts')}
            onChange={(items) => {
              selectOnChange('accounts', items);
            }}
            value={values.accounts}
            closeMenuOnSelect
            isMulti
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onCancel}
            secondary
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={styles.saveButton}
            loading={statusInviteToAccounts === LOADING}
          >
            Save
          </Button>
        </div>
      </form>
      {statusInviteToAccounts === ERROR && <span className={styles.error}>{error}</span>}
    </>
  );
};

AddEditorForm.propTypes = {
  onCancel: func.isRequired,
};

export { AddEditorForm };
