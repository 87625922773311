import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { bool, number, shape } from 'prop-types';
import cn from 'classnames';

import BorderLineType from 'src/assets/icons/border-line-type.svg';
import BorderLineTypeWhite from 'src/assets/icons/border-line-type-white.svg';
import { updateElement } from 'src/actions/projectActions';
import { useClickOutside, useProjectSelector } from 'src/hooks';
import { BORDER_DASH_OPTIONS } from 'src/constants/general';
import { useDropdownWithScroll } from 'src/pages/project/controls-options/useDropdownsWithScroll';
import { OPTION_ICONS, OPTION_ICONS_SELECTED } from './optionIcons';
import styles from './BorderDash.module.scss';

const PADDING_LEFT_DROPDOWN = 10;

const BorderDash = ({ disabled, scrollPosition }) => {
  const dispatch = useDispatch();

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.values(selectedElements);
  const elementSelected = elements.find(item => item.uuid === selectedElementsList[0]);

  const [showOptions, setShowOptions] = useState(false);

  const updateElementRequest = useCallback(attrs => {
    setShowOptions(false);
    selectedElementsList.forEach(elementId => {
      const newAttrs = {
        uuid: elementId,
        ...attrs,
      };
      dispatch(updateElement(newAttrs));
    });
  }, [dispatch, selectedElementsList]);


  const clickeableId = 'clickeableBorderDash';
  const ref = useClickOutside(
    () => setShowOptions(false),
    showOptions,
    [clickeableId],
  );

  const onClickButton = () => !disabled && setShowOptions(!showOptions);

  const lineTypeSelected = elementSelected?.strokeDash?.join(',') || '';

  const containerId = 'borderDash';
  const {
    optionsSize,
  } = useDropdownWithScroll(scrollPosition, containerId);

  return (
    <div className={styles.container} id={containerId}>
      <button
        disabled={disabled}
        onClick={onClickButton}
        className={styles.button}
      >
        <img
          src={disabled ? BorderLineType : BorderLineTypeWhite}
          alt="Border line type"
          id={clickeableId}
        />
      </button>
      {showOptions && (
        <div
          className={styles.options}
          ref={ref}
          style={{ left: optionsSize.left - PADDING_LEFT_DROPDOWN }}
        >
          {BORDER_DASH_OPTIONS.map((type, index) => {
            const typeString = type.join(',');
            const isSelected = lineTypeSelected === typeString;
            return (
              <button
                key={typeString}
                className={cn(styles.option, { [styles.selected]: isSelected })}
                onClick={() => updateElementRequest({ strokeDash: type })}
              >
                <img
                  src={isSelected ? OPTION_ICONS_SELECTED[index] : OPTION_ICONS[index]}
                  alt=""
                />
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

BorderDash.propTypes = {
  disabled: bool.isRequired,
  scrollPosition: shape({
    x: number,
  }),
};

export { BorderDash };
