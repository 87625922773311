import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import { showProductTip } from 'src/actions/userSettingsActions';
import { DesignComponentsService } from 'src/services/designComponentsService';
import { toastErrorConfig } from 'src/constants/toastConfig';
import { promiseInListWithLimit } from 'src/utils/helpers';
import { ProjectService } from 'src/services/projectService';
import { CampaignAssetService } from 'src/services/campaignAssetService';
import { duplicateAssetFiles } from 'src/utils/canvasHelpers';
import { SHOWING_STATUS } from 'src/constants/general';
import createAction from './createAction';

export const CHANGE_CONTROL_OPEN = 'CHANGE_CONTROL_OPEN';

const SAVE_DRAGGABLE_OFFSET = 'SAVE_DRAGGABLE_OFFSET';

const RESET_DRAGGABLE_OFFSET = 'RESET_DRAGGABLE_OFFSET';

const SAVE_CANVAS_SIZE = 'SAVE_CANVAS_SIZE';

const SAVE_ZOOM = 'SAVE_ZOOM';

const SET_EDITABLE_TEXT = 'SET_EDITABLE_TEXT';

export const SET_EDITABLE_IMAGE = 'SET_EDITABLE_IMAGE';

const SET_EDITABLE_TAG = 'SET_EDITABLE_TAG';

const SET_EDITABLE_FRAME_TITLE = 'SET_EDITABLE_FRAME_TITLE';

const SET_REPLACEABLE_IMAGE_RIGHT_CLICK = 'SET_REPLACEABLE_IMAGE_RIGHT_CLICK';

const OPEN_RIGHT_CLICK_OPTIONS = 'OPEN_RIGHT_CLICK_OPTIONS';

const CLOSE_RIGHT_CLICK_OPTIONS = 'CLOSE_RIGHT_CLICK_OPTIONS';

const SET_EYEDROPPER_ACTIVE = 'SET_EYEDROPPER_ACTIVE';

const CHANGE_EYEDROPPER_COLOR = 'CHANGE_EYEDROPPER_COLOR';

export const CHOOSE_EYEDROPPER_COLOR = 'CHOOSE_EYEDROPPER_COLOR';

const RESET_EYEDROPPER_COLOR = 'RESET_EYEDROPPER_COLOR';

const SET_EYEDROPPER_INACTIVE = 'SET_EYEDROPPER_INACTIVE';

const SET_GRADIENT_ACTIVE_COLOR_ID = 'SET_GRADIENT_ACTIVE_COLOR_ID';

const SET_PINCH_ZOOM_GESTURE = 'SET_PINCH_ZOOM_GESTURE';

const SELECT_FRAME = 'SELECT_FRAME';

const DESELECT_FRAME = 'DESELECT_FRAME';

const SET_EDITABLE_HYPERLINK = 'SET_EDITABLE_HYPERLINK';

export const GET_TEXT_COMBINATIONS = 'GET_TEXT_COMBINATIONS';
export const GET_TEXT_COMBINATIONS_REQUEST = 'GET_TEXT_COMBINATIONS_REQUEST';
export const GET_TEXT_COMBINATIONS_SUCCESS = 'GET_TEXT_COMBINATIONS_SUCCESS';
export const GET_TEXT_COMBINATIONS_ERROR = 'GET_TEXT_COMBINATIONS_ERROR';

export const GET_OBJECTS_COMBINATIONS = 'GET_OBJECTS_COMBINATIONS';
export const GET_OBJECTS_COMBINATIONS_REQUEST = 'GET_OBJECTS_COMBINATIONS_REQUEST';
export const GET_OBJECTS_COMBINATIONS_SUCCESS = 'GET_OBJECTS_COMBINATIONS_SUCCESS';
export const GET_OBJECTS_COMBINATIONS_ERROR = 'GET_OBJECTS_COMBINATIONS_ERROR';

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_REQUEST = 'UPLOAD_MEDIA_REQUEST';
export const UPLOAD_MEDIA_ERROR = 'UPLOAD_MEDIA_ERROR';

export const UPLOAD_SINGLE_ASSET_SUCCESS = 'UPLOAD_SINGLE_ASSET_SUCCESS';
export const UPLOAD_SINGLE_ASSET_REQUEST = 'UPLOAD_SINGLE_ASSET_REQUEST';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_ERROR = 'DELETE_MEDIA_ERROR';

export const SET_SELECTING_MULTIPLE_MOBILE = 'SET_SELECTING_MULTIPLE_MOBILE';

export const GET_SQUAD_EVENT_ASSETS = 'GET_SQUAD_EVENT_ASSETS';
export const GET_SQUAD_EVENT_ASSETS_REQUEST = 'GET_SQUAD_EVENT_ASSETS_REQUEST';
export const GET_SQUAD_EVENT_ASSETS_SUCCESS = 'GET_SQUAD_EVENT_ASSETS_SUCCESS';
export const GET_SQUAD_EVENT_ASSETS_ERROR = 'GET_SQUAD_EVENT_ASSETS_ERROR';

export const GET_SQUAD_EVENT_ASSETS_RESET = 'GET_SQUAD_EVENT_ASSETS_RESET';

export const ADD_ASSETS_TO_MEDIA_DRAWER_REQUEST = 'ADD_ASSETS_TO_MEDIA_DRAWER_REQUEST';
export const ADD_ASSETS_TO_MEDIA_DRAWER_SUCCESS = 'ADD_ASSETS_TO_MEDIA_DRAWER_SUCCESS';
export const ADD_ASSETS_TO_MEDIA_DRAWER_ERROR = 'ADD_ASSETS_TO_MEDIA_DRAWER_ERROR';

export const changeControlOpen = createAction(CHANGE_CONTROL_OPEN);

export const saveDraggableOffset = createAction(SAVE_DRAGGABLE_OFFSET);

export const resetDraggableOffset = createAction(RESET_DRAGGABLE_OFFSET);

export const saveCanvasSize = createAction(SAVE_CANVAS_SIZE);

export const saveZoom = createAction(SAVE_ZOOM);

export const setEditableText = createAction(SET_EDITABLE_TEXT);

export const setEditableImage = createAction(SET_EDITABLE_IMAGE);

export const setEditableTag = createAction(SET_EDITABLE_TAG);

export const setEditableFrameTitle = createAction(SET_EDITABLE_FRAME_TITLE);

export const setReplaceableImageRightClick = createAction(SET_REPLACEABLE_IMAGE_RIGHT_CLICK);

export const openRightClickOptions = createAction(OPEN_RIGHT_CLICK_OPTIONS);

export const closeRightClickOptions = createAction(CLOSE_RIGHT_CLICK_OPTIONS);

export const setEyeDropperActive = createAction(SET_EYEDROPPER_ACTIVE);

export const changeEyeDropperColor = createAction(CHANGE_EYEDROPPER_COLOR);

export const chooseEyeDropperColor = createAction(CHOOSE_EYEDROPPER_COLOR);

export const resetEyeDropperColor = createAction(RESET_EYEDROPPER_COLOR);

export const setEyeDropperInactive = createAction(SET_EYEDROPPER_INACTIVE);

export const setGradientActiveColorId = createAction(SET_GRADIENT_ACTIVE_COLOR_ID);

export const setPinchZoomGesture = createAction(SET_PINCH_ZOOM_GESTURE);

export const selectFrame = createAction(SELECT_FRAME);

export const deselectFrame = createAction(DESELECT_FRAME);

export const setEditableHyperlink = createAction(SET_EDITABLE_HYPERLINK);

export const getTextCombinationsRequest = createAction(GET_TEXT_COMBINATIONS_REQUEST);
export const getTextCombinationsSuccess = createAction(GET_TEXT_COMBINATIONS_SUCCESS);
export const getTextCombinationsError = createAction(GET_TEXT_COMBINATIONS_ERROR);

export const getObjectsCombinationsRequest = createAction(GET_OBJECTS_COMBINATIONS_REQUEST);
export const getObjectsCombinationsSuccess = createAction(GET_OBJECTS_COMBINATIONS_SUCCESS);
export const getObjectsCombinationsError = createAction(GET_OBJECTS_COMBINATIONS_ERROR);

export const uploadMediaRequest = createAction(UPLOAD_MEDIA_REQUEST);
export const uploadMediaSuccess = createAction(UPLOAD_MEDIA_SUCCESS);
export const uploadMediaError = createAction(UPLOAD_MEDIA_ERROR);

export const uploadSingleAssetRequest = createAction(UPLOAD_SINGLE_ASSET_REQUEST);
export const uploadSingleAssetSuccess = createAction(UPLOAD_SINGLE_ASSET_SUCCESS);

export const deleteMediaRequest = createAction(DELETE_MEDIA_REQUEST);
export const deleteMediaSuccess = createAction(DELETE_MEDIA_SUCCESS);
export const deleteMediaError = createAction(DELETE_MEDIA_ERROR);

export const setSelectingMultipleMobile = createAction(SET_SELECTING_MULTIPLE_MOBILE);

export const getSquadEventAssetsRequest = createAction(GET_SQUAD_EVENT_ASSETS_REQUEST);
export const getSquadEventAssetsSuccess = createAction(GET_SQUAD_EVENT_ASSETS_SUCCESS);
export const getSquadEventAssetsError = createAction(GET_SQUAD_EVENT_ASSETS_ERROR);

export const getSquadEventAssetsReset = createAction(GET_SQUAD_EVENT_ASSETS_RESET);

export const addAssetsToMediaDrawerRequest = createAction(ADD_ASSETS_TO_MEDIA_DRAWER_REQUEST);
export const addAssetsToMediaDrawerSuccess = createAction(ADD_ASSETS_TO_MEDIA_DRAWER_SUCCESS);
export const addAssetsToMediaDrawerError = createAction(ADD_ASSETS_TO_MEDIA_DRAWER_ERROR);

export const getTextCombinations = () => async dispatch => {
  try {
    dispatch(getTextCombinationsRequest());
    const { data } = await DesignComponentsService.getTextCombinations();
    dispatch(getTextCombinationsSuccess({ textCombinations: data.designComponents }));
  } catch (e) {
    dispatch(getTextCombinationsError());
  }
};

export const getObjectsCombinations = () => async dispatch => {
  try {
    dispatch(getObjectsCombinationsRequest());
    const { data } = await DesignComponentsService.getObjectsCombinations();
    dispatch(getObjectsCombinationsSuccess({ objectCombinations: data.designComponents }));
  } catch (e) {
    dispatch(getObjectsCombinationsError());
  }
};

export const uploadMedia = (media, loadingId) => async (dispatch, getState) => {
  try {
    dispatch(uploadMediaRequest({ amount: media.length, loadingId }));
    await promiseInListWithLimit(media, async file => {
      dispatch(uploadSingleAssetRequest());
      const { data: { url } } = await ProjectService.saveFileFromLocal(file);
      await dispatch(uploadSingleAssetSuccess({ url, loadingId }));
      return url;
    });
    const state = getState();
    const { project: { present: { id } }, canvas: { uploadedMedia } } = state;
    const { data } = await ProjectService.updateProjectAutomatically(
      id,
      { uploadedMedia },
    );
    dispatch(uploadMediaSuccess({ uploadedMedia: data.project.uploadedMedia, loadingId }));
  } catch (error) {
    dispatch(uploadMediaError({ loadingId }));
    Sentry.captureException(error);
    toast.error('Error while importing the media', toastErrorConfig);
  }
};

export const deleteMedia = (mediaToDelete) => async (dispatch, getState) => {
  try {
    dispatch(deleteMediaRequest());
    await promiseInListWithLimit(mediaToDelete, async ({ src }) => {
      await ProjectService.deleteFileFromUrl(src);
    });
    const state = getState();
    const { project: { present: { id } }, canvas: { uploadedMedia } } = state;
    const newMedia = uploadedMedia.filter(elem => (
      !mediaToDelete.find(elemToDelete => elem === elemToDelete.src)
    ));
    const { data } = await ProjectService.updateProjectAutomatically(
      id,
      { uploadedMedia: newMedia },
    );
    dispatch(deleteMediaSuccess({ project: data.project }));
  } catch (error) {
    dispatch(deleteMediaError());
    Sentry.captureException(error);
  }
};

export const getSquadEventAssets = (page, perPage = 12) => async dispatch => {
  try {
    dispatch(getSquadEventAssetsRequest());
    const { data } = await CampaignAssetService.getSquadEventAssets(page, perPage);
    dispatch(getSquadEventAssetsSuccess({ assets: data.assets, totalAssets: data.totalAssets }));
  } catch (e) {
    dispatch(getSquadEventAssetsError());
  }
};

export const addAssetsToMediaDrawer = (assets) => async (dispatch, getState) => {
  try {
    dispatch(addAssetsToMediaDrawerRequest());
    const duplicatedFiles = await duplicateAssetFiles(assets);
    const duplicatedUrls = duplicatedFiles.map(elem => elem.src);
    const state = getState();
    const {
      project: { present: { id } },
      canvas: { uploadedMedia },
      session: { user: { settings } },
    } = state;
    const { data } = await ProjectService.updateProjectAutomatically(
      id,
      { uploadedMedia: [...duplicatedUrls, ...uploadedMedia] },
    );
    dispatch(addAssetsToMediaDrawerSuccess({ uploadedMedia: data.project.uploadedMedia }));
    if (settings.showSquadEventCollageTip === SHOWING_STATUS.DONT_SHOW) {
      dispatch(showProductTip('showSquadEventCollageTip'));
    }
  } catch (error) {
    dispatch(addAssetsToMediaDrawerError());
  }
};
