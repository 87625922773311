import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BACKSPACE_CHARACTER_CODE, DELETE_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { useKeyboardListener, useProjectSelector } from 'src/hooks';
import { areSelectedElementsDeleteable } from 'src/utils/canvasHelpers';
import { isHtmlTagFocused } from 'src/utils/helpers';
import { deleteElement } from 'src/actions/projectActions';

const useDeleteElementShortcut = () => {
  const dispatch = useDispatch();

  const { elements, selectedElements, groupsOfElementsById, type } = useProjectSelector();

  const { gradientActiveColorId } = useSelector(({ canvas }) => ({
    gradientActiveColorId: canvas.gradientActiveColorId,
  }));

  const selectedElementsList = Object.keys(selectedElements);

  const backspacePressed = useKeyboardListener(BACKSPACE_CHARACTER_CODE);
  const deletePressed = useKeyboardListener(DELETE_CHARACTER_CODE);

  const deleteElementRequest = useCallback(() => {
    dispatch(deleteElement());
  }, [dispatch]);

  const multipleElementsDeleteable = areSelectedElementsDeleteable(
    selectedElements,
    elements,
    groupsOfElementsById,
    type,
  );

  useEffect(() => {
    if (!isHtmlTagFocused() && !gradientActiveColorId &&
      (backspacePressed || deletePressed) && selectedElementsList.length &&
      multipleElementsDeleteable) {
      deleteElementRequest();
    }
  }, [
    deleteElementRequest,
    dispatch,
    selectedElementsList.length,
    backspacePressed,
    deletePressed,
    gradientActiveColorId,
    multipleElementsDeleteable,
  ]);
};

export { useDeleteElementShortcut };
