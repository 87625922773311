import React, { useState } from 'react';
import { enUS } from 'date-fns/locale';
import { isBefore, format, getYear, isEqual } from 'date-fns';
import { DateRangePickerCalendar, START_DATE, useDateInput } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { func, instanceOf } from 'prop-types';
import { toast } from 'react-toastify';

import { Input } from 'src/common/input';
import { toastErrorConfig } from 'src/constants/toastConfig';
import styles from './DatePickerRange.module.scss';

const MIN_DATE = new Date(2021, 0, 1);

const DatePickerRange = ({ onSelectRange, initialStartDate, initialEndDate }) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [focus, setFocus] = useState(START_DATE);

  const handleFocusChange = newFocus => {
    setFocus(newFocus || START_DATE);
  };

  const handleEndDate = (date) => {
    if (isBefore(date, MIN_DATE)) {
      if (getYear(date) > 2000) {
        toast.error(`End date must be after ${format(MIN_DATE, 'MM/dd/yyyy')}`, toastErrorConfig);
      }
      return;
    }
    if (startDate) {
      if (isBefore(startDate, date) || isEqual(startDate, date)) {
        onSelectRange({
          from: startDate,
          to: date,
        });
      } else {
        toast.error('Start date must be before end date', toastErrorConfig);
      }
    } else {
      setEndDate(date);
    }
  };

  const handleStartDate = (date) => {
    if (isBefore(date, MIN_DATE)) {
      if (getYear(date) > 2000) {
        toast.error(`Start date must be after ${format(MIN_DATE, 'MM/dd/yyyy')}`, toastErrorConfig);
      }
      return;
    }
    if (startDate) {
      setEndDate(undefined);
      setStartDate(date);
    } else if (endDate) {
      if (isBefore(date, endDate) || isEqual(date, endDate)) {
        onSelectRange({
          from: date,
          to: endDate,
        });
      } else {
        toast.error('Start date must be before end date', toastErrorConfig);
      }
    } else {
      setStartDate(date);
    }
  };

  const inputStartProps = useDateInput({
    date: startDate,
    format: 'MM/dd/yyyy',
    locale: enUS,
    onDateChange: handleStartDate,
  });

  const inputEndProps = useDateInput({
    date: endDate,
    format: 'MM/dd/yyyy',
    locale: enUS,
    onDateChange: handleEndDate,
  });

  return (
    <div className={styles.container} id="datePickerRange">
      <div className={styles.header}>
        <Input
          label="Start"
          containerClassName={styles.inputContainer}
          name="startDate"
          {...inputStartProps}
        />
        <Input
          label="End"
          containerClassName={styles.inputContainer}
          name="endDate"
          {...inputEndProps}
        />
      </div>
      <DateRangePickerCalendar
        startDate={startDate}
        endDate={endDate}
        focus={focus}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        onFocusChange={handleFocusChange}
        locale={enUS}
        minimumDate={MIN_DATE}
      />
    </div>
  );
};

DatePickerRange.propTypes = {
  onSelectRange: func.isRequired,
  initialStartDate: instanceOf(Date),
  initialEndDate: instanceOf(Date),
};

export { DatePickerRange };
