import React, { useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { bool, arrayOf, number } from 'prop-types';
import { useDispatch } from 'react-redux';

import CloseIcon from 'src/assets/icons/close-white.svg';
import { trackDownload } from 'src/utils/analytics';
import {
  useDownloadPreviews,
  useProjectSelector,
  useWindowSize,
  useSession,
} from 'src/hooks';
import { EXPORT_CALLBACK, SHOWING_STATUS } from 'src/constants/general';
import { routeWithProps } from 'src/utils/helpers';
import { routesPaths } from 'src/routes/routesPaths';
import { Button } from 'src/common/button';
import { showProductTip } from 'src/actions/userSettingsActions';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { CopyEmbedButton } from './copy-embed-button';
import { EnableDownloadModal } from '../enable-download-modal';
import styles from './ExportButtons.module.scss';

const ExportButtons = ({ disabled, selectedIndexes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, previews, layout } = useProjectSelector();

  const { imagesDownloaded, downloadPreviews } = useDownloadPreviews();

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  const { user } = useSession();

  const onClickDownload = () => {
    if (user.settings.showDownloadProjectTip === SHOWING_STATUS.ALREADY_CLOSED) {
      downloadPreviews(selectedIndexes);
    } else {
      dispatch(showProductTip('showDownloadProjectTip'));
    }
  };

  useEffect(() => {
    if (previews?.length && imagesDownloaded === (selectedIndexes || previews).length) {
      trackDownload(id, layout.source);
    }
  }, [history, id, imagesDownloaded, layout.source, previews, selectedIndexes]);

  const actionToTake = useMemo(() => (history.location.state?.data?.action), [history]);

  const previewsSelectedIndexes = selectedIndexes.join(',');

  const search = !previewsSelectedIndexes ?
    {} :
    { search: `selected=${previewsSelectedIndexes}` };

  return (
    <>
      <div className={styles.container}>
        <CopyEmbedButton disabled={disabled} />
        {isMobile ? (
          <Link
            to={{
              pathname: routeWithProps(
                routesPaths.previewBreakDown,
                { id },
              ),
              ...search,
            }}
            target="_blank"
            className={styles.link}
          >
            <Button
              className={styles.linkButton}
              alternativeCTA
              disabled={disabled}
              short
            >
              Download
            </Button>
          </Link>
        ) : (
          <Button
            className={styles.export}
            onClick={onClickDownload}
            disabled={disabled}
            alternativeCTA
            short
          >
            Download {actionToTake === EXPORT_CALLBACK.DOWNLOAD_PREVIEWS ? 'Again' : ''}
          </Button>
        )}
        <Link to={routeWithProps(routesPaths.project, { id })} className={styles.cancel}>
          <img src={CloseIcon} alt="Close" />
        </Link>
      </div>
      {user.settings.showDownloadProjectTip === SHOWING_STATUS.SHOW && (
        <EnableDownloadModal
          onClose={downloadPreviews}
        />
      )}
    </>
  );
};

ExportButtons.propTypes = {
  disabled: bool.isRequired,
  selectedIndexes: arrayOf(number),
};

export { ExportButtons };
