import { v4 as uuidv4 } from 'uuid';

import {
  getProjectReset,
  getProjectSuccess,
} from 'src/actions/projectActions';
import {
  receiveGeneratedCollage,
  receiveReGeneratedCollage,
  showGeneratedCollageSuccess,
  discardGeneratedCollageDataSuccess,
  keepCurrentProjectForUndo,
  undoCollage,
  addMessagesToChat,
  answerToChat,
  startOverChat,
  addProjectMediaToChat,
  removeLastMessageOfType,
  askForCollageFromChat,
  deleteImageFromChat,
  askForAmountFromChat,
} from 'src/actions/collageActions';
import {
  uploadMediaError,
  uploadMediaRequest,
  uploadSingleAssetSuccess,
} from 'src/actions/canvasActions';
import { logout } from 'src/actions/userActions';
import { COLLAGE_CHAT_TYPE } from 'src/constants/general';
import createReducer from './createReducer';

const initialState = {
  projectId: undefined,
  generatedCollageToShow: null,
  isGeneratedCollageVisible: false,
  projectForUndo: null,
  chatMessages: [],
  chatMedia: {},
  userInteraction: false,
};

const actionHandlers = {
  [getProjectReset]: () => initialState,
  [getProjectSuccess]: (state, { payload: { project, pendingCollageData } }) => {
    state.projectId = project.id;
    if (pendingCollageData) {
      state.chatMessages = [
        { type: COLLAGE_CHAT_TYPE.LOADING, id: uuidv4() },
      ];
    }
    state.chatMedia = {};
    state.userInteraction = false;
  },
  [receiveGeneratedCollage]: (state, { payload: { projectId, generatedCollage } }) => {
    if (state.projectId === projectId && !state.generatedCollageToShow) {
      state.generatedCollageToShow = generatedCollage;
      state.chatMessages = state.chatMessages.slice(0, -1);
      state.chatMessages = [
        ...state.chatMessages,
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Here\'s your Creator Collage, what do you think?',
          id: uuidv4(),
        },
        {
          type: COLLAGE_CHAT_TYPE.COLLAGE_THUMBNAIL,
          id: uuidv4(),
        },
      ];
      state.userInteraction = true;
    }
  },
  [receiveReGeneratedCollage]: (state, { payload: { projectId, generatedCollage } }) => {
    if (state.projectId === projectId) {
      state.generatedCollageToShow = generatedCollage;
    }
  },
  [showGeneratedCollageSuccess]: (state, { payload: { projectId, isNewCollage } }) => {
    if (state.projectId === projectId) {
      state.generatedCollageToShow = null;
      state.isGeneratedCollageVisible = true;
      if (isNewCollage) {
        state.chatMessages = [
          ...state.chatMessages,
          { type: COLLAGE_CHAT_TYPE.ANSWER_REGENERATE, id: uuidv4() },
        ];
      }
    }
  },
  [discardGeneratedCollageDataSuccess]: (state, { payload: projectId }) => {
    if (state.projectId === projectId) {
      state.generatedCollageToShow = null;
      state.isGeneratedCollageVisible = false;
      state.projectForUndo = null;
    }
  },
  [keepCurrentProjectForUndo]: (state, { payload: { project } }) => {
    if (state.projectId === project.id) {
      state.projectForUndo = project;
    }
  },
  [undoCollage]: (state, { payload: { projectId } }) => {
    if (state.projectId === projectId) {
      state.projectForUndo = null;
    }
  },
  [uploadMediaRequest]: (state, { payload: { amount, loadingId } }) => {
    if (loadingId) {
      if (state.chatMedia[loadingId]) {
        state.chatMedia[loadingId].loadingAmount += amount;
      } else {
        state.chatMedia[loadingId] = { loadingAmount: amount, media: [] };
      }
    }
  },
  [uploadMediaError]: (state, { payload: { loadingId } }) => {
    if (loadingId && state.chatMedia[loadingId]) {
      state.chatMedia[loadingId].loadingAmount = 0;
    }
  },
  [uploadSingleAssetSuccess]: (state, { payload: { url, loadingId } }) => {
    if (loadingId && state.chatMedia[loadingId]) {
      state.chatMedia[loadingId].loadingAmount -= 1;
      state.chatMedia[loadingId].media = [url, ...state.chatMedia[loadingId].media];
    }
  },
  [askForAmountFromChat]: state => {
    const media = Object.values(state.chatMedia).flatMap(m => m.media);
    if (media.length < 2) {
      state.chatMessages = [
        ...state.chatMessages,
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: '⚠️ We need at least two of your images to make a collage',
          id: uuidv4(),
        },
        { type: COLLAGE_CHAT_TYPE.UPLOAD_MEDIA, id: uuidv4() },
      ];
    } else {
      state.chatMessages = [
        ...state.chatMessages,
        {
          type: COLLAGE_CHAT_TYPE.QUESTION,
          text: 'Up to how many canvases do you want your Creator Collage to be in length?',
          id: uuidv4(),
        },
        { type: COLLAGE_CHAT_TYPE.AMOUNT_CANVASES_ANSWER, id: uuidv4() },
      ];
    }
  },
  [askForCollageFromChat]: (state, { payload: { selectedAmount } }) => {
    state.chatMessages = [
      ...state.chatMessages,
      {
        type: COLLAGE_CHAT_TYPE.QUESTION,
        text: 'Great! We’re designing a Creator Collage for you now!',
        id: uuidv4(),
      },
      {
        type: COLLAGE_CHAT_TYPE.LOADING,
        id: uuidv4(),
        sendCollage: true,
        amountCanvases: selectedAmount,
      },
    ];
  },
  [addProjectMediaToChat]: (state, { payload: { media, loadingId } }) => {
    state.chatMedia[loadingId] = { loadingAmount: 0, media };
    const index = state.chatMessages.findIndex(m => m.type === COLLAGE_CHAT_TYPE.ADD_PROJECT_MEDIA);
    if (index !== -1) {
      state.chatMessages[index] = {
        type: COLLAGE_CHAT_TYPE.MEDIA_IN_CHAT,
        loadingId,
        id: uuidv4(),
      };
      // Remove any other messages after
      state.chatMessages.splice(index + 1, state.chatMessages.length - index - 1);
    }
  },
  [addMessagesToChat]: (state, { payload: { messages, clean = false } }) => {
    if (clean) {
      state.chatMessages = [];
      state.chatMedia = {};
      state.userInteraction = false;
    }
    state.chatMessages = [...state.chatMessages, ...messages];
  },
  [answerToChat]: (state, { payload: { answer, messages = [], selectedValue } }) => {
    state.chatMessages = state.chatMessages.map(m => {
      if (m.type.includes(COLLAGE_CHAT_TYPE.ANSWER) && !m.answer) {
        m.answer = answer;
        if (selectedValue) {
          m.selectedValue = selectedValue;
        }
      }
      return m;
    });
    state.chatMessages = [...state.chatMessages, ...messages];
    state.userInteraction = true;
  },
  [removeLastMessageOfType]: (state, { payload: { type } }) => {
    const lastIndex = state.chatMessages.map(m => m.type).lastIndexOf(type);
    if (lastIndex !== -1) {
      state.chatMessages.splice(lastIndex, 1);
    }
  },
  [deleteImageFromChat]: (state, { payload: { loadingId, url } }) => {
    if (loadingId && state.chatMedia[loadingId]) {
      state.chatMedia[loadingId].media = state.chatMedia[loadingId].media.filter(element => (
        element !== url
      ));
    }
  },
  [startOverChat]: state => {
    state.chatMessages = [];
    state.chatMedia = {};
    state.userInteraction = false;
  },
  [logout]: () => initialState,
};

export default createReducer(initialState, actionHandlers);
