import React, { useState, useEffect } from 'react';
import { func, number, bool, string } from 'prop-types';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { ENTER_CHARACTER_CODE } from 'src/constants/keyboardCodes';
import { toastErrorConfig } from 'src/constants/toastConfig';
import LetterSpacingWhiteIcon from 'src/assets/icons/letter-spacing-white.svg';
import styles from './LetterSpacingInput.module.scss';

const MAX_SPACING = 100;

const LetterSpacingInput = ({
  disabled = false,
  onBlur,
  onChange,
  letterSpacing = 0,
  inputClassName = '',
  icon = LetterSpacingWhiteIcon,
}) => {
  const [value, setValue] = useState(letterSpacing);

  useEffect(() => {
    setValue(letterSpacing);
  }, [letterSpacing]);

  const changeLetterSpacing = ({ target }) => {
    let valueNumber = +target.value;
    valueNumber = Math.round(valueNumber * 100) / 100;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > MAX_SPACING) {
      toast.error(`Letter spacing must be less than ${MAX_SPACING}`, toastErrorConfig);
    } else if (onChange) {
      onChange(valueNumber);
    } else {
      onBlur(valueNumber);
    }
  };

  const onKeyDown = ({ key, target }) => {
    if (key === ENTER_CHARACTER_CODE) {
      target.blur();
    }
  };

  return (
    <div className={styles.container}>
      <img src={icon} alt="Letter spacing" />
      <input
        className={cn(styles.input, inputClassName)}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        disabled={disabled}
        onBlur={changeLetterSpacing}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

LetterSpacingInput.propTypes = {
  disabled: bool,
  onBlur: func.isRequired,
  onChange: func,
  letterSpacing: number,
  inputClassName: string,
  icon: string,
};

export { LetterSpacingInput };
