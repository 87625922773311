import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { number, shape } from 'prop-types';

import OpacityIcon from 'src/assets/icons/transparency.svg';
import { updateElement } from 'src/actions/projectActions';
import { useProjectSelector, useTooltip } from 'src/hooks';
import { InputNumber } from 'src/common/input-number';
import { Tooltip } from 'src/common/tooltip';
import { useDropdownWithScroll } from '../../useDropdownsWithScroll';
import styles from './OpacityOptions.module.scss';

const MAX_VALUE = 100;
const PADDING_LEFT_DROPDOWN = 10;

const OpacityOptions = ({ scrollPosition }) => {
  const dispatch = useDispatch();

  const { selectedElements, elements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const elementSelected = useMemo(() => (
    elements.find(elem => elem.uuid === selectedElementsList[0])
  ), [elements, selectedElementsList]);

  const changeOpacityElementRequest = useCallback((value, event) => {
    selectedElementsList.forEach(elementId => {
      const newAttrs = {
        uuid: elementId,
        opacity: +value,
      };
      dispatch(updateElement(newAttrs, event.timeStamp));
    });
  }, [dispatch, selectedElementsList]);

  const valueToShow = useMemo(() => {
    if (elementSelected?.opacity === undefined) {
      return MAX_VALUE;
    }
    return elementSelected.opacity;
  }, [elementSelected]);

  const { ref, showTooltip } = useTooltip();

  const containerId = 'opacityOptions';
  const { optionsSize } = useDropdownWithScroll(scrollPosition, containerId);

  const tooltipStyles = useCallback(() => (
    {
      left: optionsSize.left - PADDING_LEFT_DROPDOWN,
      right: 'unset',
      transform: 'unset',
    }
  ), [optionsSize.left]);

  return (
    <div className={styles.container} id={containerId}>
      <img src={OpacityIcon} alt="Transparency" ref={ref} />
      {showTooltip && (
        <Tooltip style={tooltipStyles()}>
          Transparency
        </Tooltip>
      )}
      <InputNumber
        min={0}
        max={MAX_VALUE}
        value={valueToShow}
        onChange={(value, e) => (
          changeOpacityElementRequest(value, e)
        )}
        sufix="%"
        className={styles.inputContainer}
      />
    </div>
  );
};

OpacityOptions.propTypes = {
  scrollPosition: shape({
    x: number,
  }),
};

export { OpacityOptions };
