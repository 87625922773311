import { useRef } from 'react';
import Konva from 'konva';
import { useSelector, useDispatch } from 'react-redux';

import {
  CANVAS_BACKGROUND,
  STAGE_ID,
  CANVAS_DRAWING_CONTENT,
  CANVAS_SELECTION_ID,
  CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
  CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT,
  CANVAS_RIGHT_CLICK_OPTIONS_TYPE,
} from 'src/constants/general';
import { useProjectSelector } from 'src/hooks';
import { getRelativePointerPosition, getRightOptionsPosition } from 'src/utils/canvasHelpers';
import {
  changeEyeDropperColor,
  openRightClickOptions,
  resetEyeDropperColor,
} from 'src/actions/canvasActions';
import {
  cleanSelectedElement,
  addSelectedElement,
} from 'src/actions/projectActions';
import { isRightClick, rgbToHex } from 'src/utils/helpers';

const useMouseEventsStage = (refDrawingLayer) => {
  const dispatch = useDispatch();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  const refSelection = useRef();

  const { eyeDropper } = useSelector(({ canvas }) => ({
    eyeDropper: canvas.eyeDropper,
  }));

  const { layout, size, editableText } = useProjectSelector();

  const updateSelectionRect = () => {
    const node = refSelection?.current;
    if (!node || !selection?.current) {
      return;
    }
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
    });
    node.getLayer().batchDraw();
  };

  const onMouseDownStage = ({ target }) => {
    if (!refDrawingLayer?.current || !selection?.current) {
      return;
    }
    const clickedOnEmpty = target.attrs.id === CANVAS_BACKGROUND ||
      target.attrs.id === STAGE_ID;
    if (!clickedOnEmpty) {
      return;
    }
    const { current } = refDrawingLayer;
    const { x, y } = getRelativePointerPosition(current);
    selection.current.visible = true;
    selection.current.x1 = x;
    selection.current.y1 = y;
    selection.current.x2 = x;
    selection.current.y2 = y;
    updateSelectionRect();
  };

  const selectStage = (e) => {
    const { target, evt } = e;
    const clickedOnEmpty = target.attrs.id === CANVAS_BACKGROUND ||
      target.attrs.id === STAGE_ID;
    if (editableText) {
      dispatch(addSelectedElement({ id: editableText }));
    } else if (clickedOnEmpty && !eyeDropper.active) {
      dispatch(cleanSelectedElement());
    }
    if (target.attrs.id === CANVAS_BACKGROUND && isRightClick(evt)) {
      const pos = getRightOptionsPosition(
        evt,
        CANVAS_RIGHT_CLICK_OPTIONS_CANVAS_HEIGHT,
        CANVAS_RIGHT_CLICK_OPTIONS_WIDTH,
      );
      dispatch(openRightClickOptions({
        ...pos,
        type: CANVAS_RIGHT_CLICK_OPTIONS_TYPE.BACKGROUND,
      }));
    }
  };

  const onMouseMoveStage = ({ evt }) => {
    if (!refDrawingLayer?.current) {
      return;
    }
    if (eyeDropper.active) {
      const canv = refDrawingLayer.current.toCanvas({
        pixelRatio: 1,
        x: 0,
        width: layout.width * size,
        y: 0,
        height: layout.height,
      });
      const context = canv.getContext('2d');
      const pointerColor = context.getImageData(evt.offsetX, evt.offsetY, 1, 1).data;
      const hex = rgbToHex(pointerColor[0], pointerColor[1], pointerColor[2]);
      if (hex !== eyeDropper.color) {
        dispatch(changeEyeDropperColor({ hex }));
      }
    }
    if (!selection?.current?.visible) {
      return;
    }
    const { current } = refDrawingLayer;
    const { x, y } = getRelativePointerPosition(current);
    selection.current.x2 = x;
    selection.current.y2 = y;
    updateSelectionRect();
  };

  const onMouseUpStage = (e) => {
    if (!selection?.current?.visible || !refSelection?.current || !refDrawingLayer?.current) {
      return;
    }
    const isClick = selection.current.x1 === selection.current.x2 &&
      selection.current.y1 === selection.current.y2;
    if (isClick) {
      selectStage(e);
      selection.current.visible = false;
      updateSelectionRect();
      return;
    }
    const selBox = refSelection.current.getClientRect();
    const elements = [];
    refDrawingLayer.current.findOne(`#${CANVAS_DRAWING_CONTENT}`)
      .children.forEach((elementNode) => {
        if (elementNode.attrs.id) {
          const elBox = elementNode.getClientRect();
          if (Konva.Util.haveIntersection(selBox, elBox)) {
            elements.push(elementNode);
          }
        }
      });
    selection.current.visible = false;
    updateSelectionRect();
    elements.forEach(elem => elem.fire('click', { origin: CANVAS_SELECTION_ID }));
    if (!elements.length) {
      selectStage(e);
    }
  };

  const onMouseLeaveStage = () => {
    if (eyeDropper.active) {
      dispatch(resetEyeDropperColor());
    }
  };

  return {
    onMouseDownStage,
    onMouseMoveStage,
    onMouseUpStage,
    onMouseLeaveStage,
    onTapStage: selectStage,
    refSelection,
  };
};

export { useMouseEventsStage };
