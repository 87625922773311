import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number } from 'prop-types';

import { createPaletteColor } from 'src/actions/brandLibraryActions';
import { ColorPicker } from 'src/common/color-picker';
import { CUSTOM } from 'src/constants/general';
import styles from './AddColor.module.scss';

const DEFAULT_COLOR = { hex: '#ffffff', alpha: 100 };

const AddColor = ({ paletteId }) => {
  const dispatch = useDispatch();

  const { disabled } = useSelector(({ brandLibrary }) => ({
    disabled: brandLibrary.disabled,
  }));

  const [newColor, setNewColor] = useState(DEFAULT_COLOR);

  const createColorRequest = useCallback(() => {
    dispatch(createPaletteColor(paletteId, newColor));
    setNewColor(DEFAULT_COLOR);
  }, [dispatch, newColor, paletteId]);

  return (
    <ColorPicker
      color={newColor}
      onColorChange={values => setNewColor(values)}
      className={styles.colorPickerContainer}
      swatch={onClick => (
        <button className={styles.addButton} onClick={onClick} disabled={disabled}>
          <span className={styles.add}>+</span>
        </button>
      )}
      onClose={createColorRequest}
      position={CUSTOM}
      customPosition={styles.customPosition}
      openInModal
    />
  );
};

AddColor.propTypes = {
  paletteId: number.isRequired,
};

export { AddColor };
