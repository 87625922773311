import React from 'react';
import { func } from 'prop-types';
import { useDispatch } from 'react-redux';

import AlertImg from 'src/assets/images/enable-downloads-example.svg';
import { Button } from 'src/common/button';
import { Modal } from 'src/common/modal';
import { closeProductTip } from 'src/actions/userSettingsActions';
import styles from './EnableDownloadModal.module.scss';

const EnableDownloadModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(closeProductTip('showDownloadProjectTip'));
    onClose();
  };

  return (
    <Modal
      isShowing
      hide={onClick}
      isEscEnabled={false}
      curvedContainer
      classNameContent={styles.modal}
    >
      <div className={styles.content}>
        <span className={styles.title}>
          Enable Browser Permission
        </span>
        <span className={styles.subtitle}>
          Give your browser permission to download multiple
          files to download all of your Stagger images.
        </span>
        <img src={AlertImg} alt="example" className={styles.image} />
        <Button
          className={styles.button}
          onClick={onClick}
          alternativeCTA
        >
          Ok, got it.
        </Button>
      </div>
    </Modal>
  );
};

EnableDownloadModal.propTypes = {
  onClose: func.isRequired,
};

export { EnableDownloadModal };
