import React, { useCallback, useEffect, useState } from 'react';
import { func, number, object, string, bool } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import {
  createAssetCategory,
  createColorPalette,
  createLogoCategory,
} from 'src/actions/brandLibraryActions';
import {
  CLEAR_BRAND_LIBRARY_SEARCH_BUTTON_ID,
} from 'src/constants/general';
import { CategoryOption } from './category-option';
import { AddNewCategory } from './add-new-category';
import { ChevronArrow } from './chevron-arrow';
import {
  useCategoryChanges,
  ASSETS_OPTION,
  LOGOS_OPTION,
  COLORS_OPTION,
  FONTS_OPTION,
} from './useCategoryChanges';
import styles from './SideMenu.module.scss';

const SideMenu = ({
  setNavigationOpen,
  navigationOpen,
  setSelectedNavigation,
  selectedNavigation,
  setSelectedCategoryId,
  selectedCategoryId,
  isSearching,
}) => {
  const dispatch = useDispatch();

  const {
    id,
    logoCategories,
    assetCategories,
    colorPalettes,
  } = useSelector(({ brandLibrary }) => ({
    logoCategories: brandLibrary.logoCategories,
    assetCategories: brandLibrary.assetCategories,
    colorPalettes: brandLibrary.colorPalettes,
    id: brandLibrary.id,
  }));

  const [openStyle, setOpenStyle] = useState({});

  useEffect(() => {
    // Initial settings
    setNavigationOpen({ [LOGOS_OPTION]: true });
    setSelectedNavigation(LOGOS_OPTION);
    const logosOption = document.getElementById(LOGOS_OPTION);
    setOpenStyle({ [LOGOS_OPTION]: logosOption?.scrollHeight });
    if (logoCategories?.length) {
      setSelectedCategoryId(logoCategories[0].id);
    } else {
      setSelectedCategoryId();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavigationOpen, setSelectedCategoryId, setSelectedNavigation]);

  useCategoryChanges(openStyle, setOpenStyle, setSelectedCategoryId, setSelectedNavigation);

  const onClickTitleWithCategories = useCallback((e, navigation) => {
    let categoriesContainer = e.target.nextElementSibling;
    if (e.target.tagName === 'IMG') {
      // Clicks on chevron
      categoriesContainer = e.target.parentElement.nextElementSibling;
    }
    if (navigationOpen[navigation]) {
      const newStyle = { ...openStyle };
      delete newStyle[navigation];
      setOpenStyle(newStyle);
      setNavigationOpen({ ...navigationOpen, [navigation]: false });
    } else {
      setOpenStyle({ ...openStyle, [navigation]: categoriesContainer.scrollHeight });
      setNavigationOpen({ ...navigationOpen, [navigation]: true });
    }
  }, [setNavigationOpen, navigationOpen, openStyle]);

  const onClickCategory = useCallback((navigation, categoryId) => {
    setSelectedCategoryId(categoryId);
    setSelectedNavigation(navigation);
    if (isSearching) {
      const clearButton = document.getElementById(CLEAR_BRAND_LIBRARY_SEARCH_BUTTON_ID);
      if (clearButton) {
        clearButton.click();
      }
    }
  }, [setSelectedCategoryId, setSelectedNavigation, isSearching]);

  const addNewLogoCategory = async (params) => {
    await dispatch(createLogoCategory(id, params));
  };

  const addNewAssetCategory = async (params) => {
    await dispatch(createAssetCategory(id, params));
  };

  const addNewColorPalette = async (params) => {
    await dispatch(createColorPalette(id, params));
  };

  return (
    <div className={styles.list}>
      <div
        className={cn(styles.option, { [styles.active]: !!navigationOpen[LOGOS_OPTION] })}
      >
        <button
          className={cn(styles.title, { [styles.active]: !!navigationOpen[LOGOS_OPTION] })}
          onClick={(e) => onClickTitleWithCategories(e, LOGOS_OPTION)}
        >
          Logos
          <ChevronArrow isOpen={!!navigationOpen[LOGOS_OPTION]} />
        </button>
        <div
          className={cn(styles.categories, { [styles.active]: !!navigationOpen[LOGOS_OPTION] })}
          style={{ maxHeight: openStyle[LOGOS_OPTION] }}
          id={LOGOS_OPTION}
        >
          <div className={styles.categoriesContent}>
            {logoCategories?.map(logoCat => (
              <CategoryOption
                {...logoCat}
                key={logoCat.id}
                selected={selectedNavigation === LOGOS_OPTION &&
                    logoCat.id === selectedCategoryId && !isSearching}
                onClickCategory={() => onClickCategory(LOGOS_OPTION, logoCat.id)}
              />
            ))}
            <AddNewCategory addCategory={addNewLogoCategory} />
          </div>
        </div>
      </div>
      <div
        className={cn(styles.option, { [styles.active]: !!navigationOpen[ASSETS_OPTION] })}
      >
        <button
          className={cn(styles.title, { [styles.active]: !!navigationOpen[ASSETS_OPTION] })}
          onClick={(e) => onClickTitleWithCategories(e, ASSETS_OPTION)}
        >
          Assets
          <ChevronArrow isOpen={!!navigationOpen[ASSETS_OPTION]} />
        </button>
        <div
          className={cn(styles.categories, { [styles.active]: !!navigationOpen[ASSETS_OPTION] })}
          style={{ maxHeight: openStyle[ASSETS_OPTION] }}
          id={ASSETS_OPTION}
        >
          <div className={styles.categoriesContent}>
            {assetCategories?.map(assetCat => (
              <CategoryOption
                {...assetCat}
                key={assetCat.id}
                selected={selectedNavigation === ASSETS_OPTION &&
                  assetCat.id === selectedCategoryId && !isSearching}
                onClickCategory={() => onClickCategory(ASSETS_OPTION, assetCat.id)}
              />
            ))}
            <AddNewCategory addCategory={addNewAssetCategory} />
          </div>
        </div>
      </div>
      <div
        className={cn(styles.option, { [styles.active]: !!navigationOpen[COLORS_OPTION] })}
      >
        <button
          className={cn(styles.title, { [styles.active]: !!navigationOpen[COLORS_OPTION] })}
          onClick={(e) => onClickTitleWithCategories(e, COLORS_OPTION)}
        >
          Color Palettes
          <ChevronArrow isOpen={!!navigationOpen[COLORS_OPTION]} />
        </button>
        <div
          className={cn(styles.categories, { [styles.active]: !!navigationOpen[COLORS_OPTION] })}
          style={{ maxHeight: openStyle[COLORS_OPTION] }}
          id={COLORS_OPTION}
        >
          <div className={styles.categoriesContent}>
            {colorPalettes?.map(palettes => (
              <CategoryOption
                {...palettes}
                key={palettes.id}
                selected={selectedNavigation === COLORS_OPTION &&
                  palettes.id === selectedCategoryId && !isSearching}
                onClickCategory={() => onClickCategory(COLORS_OPTION, palettes.id)}
              />
            ))}
            <AddNewCategory addCategory={addNewColorPalette} />
          </div>
        </div>
      </div>
      <div
        className={cn(styles.option, { [styles.active]: selectedNavigation === FONTS_OPTION })}
      >
        <button
          className={cn(styles.title, { [styles.active]: selectedNavigation === FONTS_OPTION })}
          onClick={() => onClickCategory(FONTS_OPTION)}
        >
          Fonts
        </button>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  setNavigationOpen: func.isRequired,
  navigationOpen: object,
  setSelectedNavigation: func.isRequired,
  selectedNavigation: string,
  setSelectedCategoryId: func.isRequired,
  selectedCategoryId: number,
  isSearching: bool.isRequired,
};

export { SideMenu };
