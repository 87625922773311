import React from 'react';
import { useDispatch } from 'react-redux';

import { FtuTip } from 'src/pages/project/ftu-tip';
import { closeProductTip } from 'src/actions/userSettingsActions';
import { useWindowSize } from 'src/hooks';
import { SMALL_WIDTH } from 'src/constants/breakpoints';

const TemplateTip = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeProductTip('showTemplateTip'));
  };

  const { windowSize: { width } } = useWindowSize();
  const isSmallWidth = width <= SMALL_WIDTH;

  return (
    <FtuTip
      title="Add your own image"
      subtitle={isSmallWidth ?
        'Choose “···” in the menu and select “Change image” to replace it with your own.' :
        'Right-click on any image and select “Change image” to replace with your own.'}
      onClose={onClose}
    />
  );
};

export { TemplateTip };
