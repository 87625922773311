import React, { useRef } from 'react';

import { ENABLE_REMOVE_BG } from 'src/constants/general';
import { useProjectSelector, useScrollPosition, useWindowSize } from 'src/hooks';
import { MOBILE_THRESHOLD_WIDTH } from 'src/constants/breakpoints';
import { ElementShadow } from '../element-control/element-shadow';
import { BorderOptions } from '../element-control/border-options';
import { OpacityOptions } from './opacity-options';
import { RemoveBackground } from './remove-background';
import { MaskControl } from './mask-control';
import styles from './ImageControl.module.scss';

const ImageControl = () => {
  const ref = useRef();

  const { editableImage } = useProjectSelector();

  const { position } = useScrollPosition(ref);

  const { windowSize: { width } } = useWindowSize();
  const isMobile = width <= MOBILE_THRESHOLD_WIDTH;

  if (editableImage) {
    return <MaskControl />;
  }

  return (
    <div className={styles.container} ref={ref}>
      <ElementShadow scrollPosition={position} paddingLeftTooltip={10} />
      <OpacityOptions scrollPosition={position} />
      {ENABLE_REMOVE_BG && <RemoveBackground scrollPosition={position} />}
      <BorderOptions
        scrollPosition={position}
        disableDash
        paddingLeftOptions={isMobile ? 90 : 0}
      />
    </div>
  );
};

export { ImageControl };
