export const getCloudinaryFilenameFromUrl = (url) => {
  let fileName = url.substr(url.lastIndexOf('/') + 1);
  [fileName] = fileName.split('?');
  return fileName;
};

export const getCloudinaryFilenameWithoutTimestamp = filename => {
  const filenameParts = filename.split('.');
  const fileExtension = filenameParts[filenameParts.length - 1];

  let filenameWithoutTimestamp;
  const timestampSplitterPos = filenameParts[0].lastIndexOf('_');
  if (timestampSplitterPos !== -1) {
    filenameWithoutTimestamp = filenameParts[0].substring(0, timestampSplitterPos);
  } else {
    [filenameWithoutTimestamp] = filenameParts;
  }
  const newFilename = `${filenameWithoutTimestamp}.${fileExtension}`;
  return newFilename;
};

const addTransformationToCludinaryUrl = (url, transformation) => {
  if (!url) {
    return url;
  }
  const uploadWord = 'upload';
  const indexOfUpload = url.indexOf(uploadWord);
  // eslint-disable-next-line max-len
  return `${url.slice(0, indexOfUpload)}${uploadWord}/${transformation}${url.slice(indexOfUpload + uploadWord.length)}`;
};

export const optimizeCloudinaryThumbnail = (url) => (
  addTransformationToCludinaryUrl(url, 'q_auto:eco,c_scale,w_600')
);

export const optimizeCloudinaryBigThumbnail = (url) => (
  addTransformationToCludinaryUrl(url, 'q_auto:good')
);

const optimizationCanvasImage = 'q_auto:good';

export const optimizeCloudinaryCanvasImage = (url) => (
  addTransformationToCludinaryUrl(url, optimizationCanvasImage)
);

export const removeOptimizationCloudinaryCanvasImage = (url) => (
  url.replace(`/${optimizationCanvasImage}`, '')
);

export const makeCloudinaryImageDownloable = (url) => (
  addTransformationToCludinaryUrl(url, 'fl_attachment')
);

export const convertCloudinaryGifToVideo = (url) => (
  `${url.substring(0, url.lastIndexOf('.'))}.mp4`
);
