import React from 'react';
import { string, number, arrayOf, shape, oneOfType } from 'prop-types';

import { ProjectCard } from './project-card';
import styles from './ProjectsRows.module.scss';

const ProjectsRows = ({ rows, amountRows }) => (
  <div className={styles.projects}>
    {rows.map((projectsPerRow, index) => {
      const list = projectsPerRow.map(
        project => <ProjectCard {...project} key={project.id} />,
      );
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.row} key={index}>
          {list}
          {Array.from({ length: amountRows - list.length }, (v, i) => i).map(
            elem => (
              <div className={styles.blankcard} key={elem} />
            ),
          )}
        </div>
      );
    })}
  </div>
);

const projectsRow = arrayOf(shape({
  id: oneOfType([number, string]),
}));

ProjectsRows.propTypes = {
  rows: arrayOf(projectsRow).isRequired,
  amountRows: number.isRequired,
};

export { ProjectsRows };
