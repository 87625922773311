import React from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import { Button } from 'src/common/button';
import styles from './AnswerToChat.module.scss';

const AnswerToChat = ({ text, onClick, loading, disabled, answer, className }) => (
  (!answer || answer === text) && (
    <Button
      className={cn(styles.button, className, { [styles.answer]: answer === text })}
      onClick={onClick}
      disabled={disabled || answer === text}
      loading={loading}
      short
      secondary
      spinnerClassName={styles.spinner}
    >
      {text}
    </Button>
  )
);

AnswerToChat.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  loading: bool,
  disabled: bool,
  answer: string,
  className: string,
};

export { AnswerToChat };
