import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  COMMAND_CHARACTER_CODE,
  CONTROL_CHARACTER_CODE,
  KEY_L_CHARACTER_CODE,
} from 'src/constants/keyboardCodes';
import { useKeyboardReset } from 'src/hooks/useKeyboardListener';
import { useKeyboardListener, useProjectSelector } from 'src/hooks';
import { lockElement, unlockElement } from 'src/actions/projectActions';
import { isHtmlTagFocused } from 'src/utils/helpers';

const useLockElementShortcut = () => {
  const dispatch = useDispatch();

  const { elements, selectedElements } = useProjectSelector();

  const selectedElementsList = Object.keys(selectedElements);

  const commandPressed = useKeyboardListener(COMMAND_CHARACTER_CODE);

  const controlPressed = useKeyboardListener(CONTROL_CHARACTER_CODE);

  const keyLPressed = useKeyboardListener(KEY_L_CHARACTER_CODE);

  const resetKey = useKeyboardReset();

  useEffect(() => {
    if (!isHtmlTagFocused() && keyLPressed && (commandPressed || controlPressed)) {
      const isElementSelected = selectedElementsList.length === 1;
      if (isElementSelected) {
        const selectedElement = elements.find(elem => elem.uuid === selectedElementsList[0]);
        resetKey(KEY_L_CHARACTER_CODE);
        if (selectedElement.unlocked) {
          dispatch(lockElement());
        } else {
          dispatch(unlockElement());
        }
      }
    }
  }, [
    selectedElementsList,
    commandPressed,
    controlPressed,
    keyLPressed,
    elements,
    dispatch,
    resetKey,
  ]);
};

export { useLockElementShortcut };
