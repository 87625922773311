import { LOCAL_UPLOAD } from 'src/constants/uploadFile';
import {
  IMAGE_ELEMENT,
  VIDEO_ELEMENT,
  TEXT_ELEMENT,
  LINE_ELEMENT,
  MEDIA_ELEMENTS,
} from 'src/constants/canvasElements';
import {
  CANVAS_OFFSET_FOR_ADDING_IMAGES,
  PROJECT_TYPE,
} from 'src/constants/general';
import { promiseInListWithLimit, getRootElementFontSize } from 'src/utils/helpers';
import { ProjectService } from 'src/services/projectService';

export const calculateAspectRatioFit = (layoutWidth, layoutHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / layoutWidth, maxHeight / layoutHeight);
  return { ratio, width: layoutWidth * ratio, height: layoutHeight * ratio };
};

export const isElementOverlapping = ({
  xElement,
  widthElement,
  scaleXElement = 1,
  widthLayout,
  isText = false,
}) => {
  let isOverlapping = false;
  const targetWidth = widthElement * scaleXElement;
  const xCoordinate = isText ? xElement : (xElement - (targetWidth / 2));
  const elementPlacedInCanvasNumber = Math.ceil(xCoordinate / widthLayout);
  if (xCoordinate > widthLayout * (elementPlacedInCanvasNumber - 1) &&
      xCoordinate <= widthLayout * elementPlacedInCanvasNumber &&
      xCoordinate + targetWidth > widthLayout * elementPlacedInCanvasNumber) {
    isOverlapping = true;
  }
  return isOverlapping;
};

export const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  if (pos) {
    return transform.point(pos);
  }
  return { x: 0, y: 0 };
};

export const getPosDragBoundFunc = (
  refPosition,
  pos,
  scale,
  abs,
  shiftShortcutPressed,
) => {
  const MAX_POSITIONS_SAVED = 20;
  const { positions } = refPosition;
  if (shiftShortcutPressed) {
    if (positions.length <= MAX_POSITIONS_SAVED) {
      positions.push({
        x: pos.x * scale,
        y: pos.y * scale,
      });
    } else {
      const circularIndex = refPosition.posIndex % (MAX_POSITIONS_SAVED + 1);
      positions[circularIndex] = {
        x: pos.x * scale,
        y: pos.y * scale,
      };

      refPosition.posIndex += 1;

      let movementsOnY = 0;
      let movementsOnX = 0;

      for (let index = 0; index < positions.length - 1; index++) {
        const position = positions[index];
        const nextPosition = positions[index + 1];
        const distanceX = Math.abs(position.x - nextPosition.x);
        const distanceY = Math.abs(position.y - nextPosition.y);
        if (distanceX > distanceY) {
          movementsOnX += 1;
        } else {
          movementsOnY += 1;
        }
      }

      if (movementsOnY >= movementsOnX) {
        return { x: abs.x, y: pos.y };
      }
      return { x: pos.x, y: abs.y };
    }
  }
  return pos;
};

export const getCropForReplacedImage = (imageResult, imageToReplace) => {
  const croppedOriginal = {
    width: imageToReplace.crop ? imageToReplace.crop?.width : imageToReplace.width,
    height: imageToReplace.crop ? imageToReplace.crop?.height : imageToReplace.height,
  };
  const isImageToReplaceTall = (croppedOriginal.height / croppedOriginal.width) >= 1;
  if (isImageToReplaceTall) {
    const replacedHeightPercentage = (imageResult.height * 100) / croppedOriginal.height;
    const newWidth = (croppedOriginal.width * replacedHeightPercentage) / 100;
    return {
      x: (imageResult.width - newWidth) / 2,
      y: 0,
      width: newWidth,
      height: imageResult.height,
    };
  }
  const replacedWidthPercentage = (imageResult.width * 100) / croppedOriginal.width;
  const newHeight = (croppedOriginal.height * replacedWidthPercentage) / 100;
  return {
    x: 0,
    y: (imageResult.height - newHeight) / 2,
    width: imageResult.width,
    height: newHeight,
  };
};

export const areSelectedElementsUnlocked = (selectedElements, elements, groupsOfElementsById) => (
  Object.keys(selectedElements).reduce((res, selectedElemId) => {
    let selectedElement = elements.find(elem => elem.uuid === selectedElemId);
    if (!selectedElement) {
      Object.entries(groupsOfElementsById).forEach(([groupId, group]) => {
        if (groupId === selectedElemId) {
          selectedElement = group;
        }
      });
    }
    return res && selectedElement?.unlocked;
  }, true)
);

export const areSelectedElementsDeleteable = (
  selectedElements,
  elements,
  groupsOfElementsById,
  type,
) => {
  if (type !== PROJECT_TYPE.SIGN) {
    return true;
  }
  const qrElements = elements.filter(elem => elem.qrImage);
  const qrGroups = Object.entries(groupsOfElementsById).filter(([, group]) => group.qrImage);
  const qrImages = qrElements.length + qrGroups.length;
  const amountOfQrsSelected = (
    Object.keys(selectedElements).reduce((res, selectedElemId) => {
      let selectedElement = elements.find(elem => elem.uuid === selectedElemId);
      if (!selectedElement) {
        Object.entries(groupsOfElementsById).forEach(([groupId, group]) => {
          if (groupId === selectedElemId) {
            selectedElement = group;
          }
        });
      }
      if (selectedElement.qrImage) {
        res += 1;
      }
      return res;
    }, 0)
  );
  return amountOfQrsSelected < qrImages;
};

export const getReplacedImageAttrs = (imgToReplace, resultImg) => {
  const {
    src,
    width,
    height,
    file: mediaFile,
    uploadType,
    saved,
    sourceId,
    duration,
  } = resultImg;
  return ({
    crop: getCropForReplacedImage({ width, height }, imgToReplace),
    src,
    scaleX: (imgToReplace.width * imgToReplace.scaleX) / width,
    scaleY: (imgToReplace.height * imgToReplace.scaleY) / height,
    width,
    height,
    file: mediaFile,
    saved: saved || uploadType !== LOCAL_UPLOAD,
    uploadType,
    sourceId,
    duration,
  });
};

export const getTransformerAnchorsByType = (element, editableImage) => {
  switch (element.type) {
    case IMAGE_ELEMENT:
      return {
        rotateEnabled: element.unlocked && editableImage !== element.uuid,
      };
    case VIDEO_ELEMENT:
      return {
        rotateEnabled: false,
        enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      };
    case TEXT_ELEMENT:
      return {
        enabledAnchors: ['middle-left', 'middle-right'],
      };
    case LINE_ELEMENT:
      return { enabledAnchors: ['middle-left', 'middle-right'] };
    default:
      return {};
  }
};

export const getStrokeDash = (dash, lineWidth, scale) => dash.map(d => d * lineWidth * scale);

export const getShadowOffset = (shadowOffsetX = 0, shadowOffsetY = 0, strokeWidth = 0) => ({
  x: shadowOffsetX + (strokeWidth / 2),
  y: shadowOffsetY + (strokeWidth / 2),
});

export const getFillLinearGradientColorStops = (colorStops = []) => (
  colorStops.reduce((res, actual) => {
    res.push(actual.offset);
    res.push(actual.color);
    return res;
  }, [])
);

export const getMediaToKeepParsed = (mediaToKeep = []) => (
  mediaToKeep.map((elem, index) => ({
    ...elem,
    x: ((elem.width * elem.scaleX) / 2) + (index * CANVAS_OFFSET_FOR_ADDING_IMAGES),
    y: ((elem.height * elem.scaleY) / 2) + (index * CANVAS_OFFSET_FOR_ADDING_IMAGES),
  }))
);

export const duplicateAssetFiles = async assets => (
  promiseInListWithLimit(assets, async (elem) => {
    const { src, name } = elem;
    const {
      data: { fileUrl, height, width, duration },
    } = await ProjectService.saveFileFromUrl(src, name);
    ([elem.src] = fileUrl.split('#'));
    elem.width = width;
    elem.height = height;
    elem.saved = true;
    if (duration) {
      elem.duration = duration;
    }
    return elem;
  })
);

export const saveNewMediaFilesInCanvas = (newElements, state) => {
  newElements.forEach(elem => {
    if (MEDIA_ELEMENTS.includes(elem.type)) {
      let stateElem = state.elements.find(e => e.uuid === elem.uuid);
      if (!stateElem) {
        Object.entries(state.groupsOfElementsById).forEach(([, group]) => {
          group
            .forEach((groupElem) => {
              if (groupElem.uuid === elem.uuid) {
                stateElem = groupElem;
              }
            });
        });
      }
      if (stateElem && !stateElem.saved && elem.saved) {
        stateElem.saved = true;
        stateElem.src = elem.src;
        stateElem.file = undefined;
        if (stateElem.width !== elem.width || stateElem.height !== elem.height) {
          stateElem.scaleX = (stateElem.width * stateElem.scaleX) / elem.width;
          stateElem.width = elem.width;
          stateElem.scaleY = (stateElem.height * stateElem.scaleY) / elem.height;
          stateElem.height = elem.height;
        }
      }
    }
  });
};

export const getRightOptionsPosition = (evt, height, width) => {
  const position = {
    x: evt.clientX,
    y: evt.clientY,
  };
  const valueOfRem = getRootElementFontSize();
  const rightOptionsHeightInPx = height * valueOfRem;
  if (position.y + rightOptionsHeightInPx > window.innerHeight) {
    position.y = window.innerHeight - rightOptionsHeightInPx;
  }
  const rightOptionsWidthInPx = width * valueOfRem;
  if (position.x + rightOptionsWidthInPx > window.innerWidth) {
    position.x = window.innerWidth - rightOptionsWidthInPx;
  }
  return position;
};

export const setCursorToPointer = e => {
  const container = e.target.getStage().container();
  container.style.cursor = 'pointer';
};

export const setCursorToDefault = e => {
  const container = e.target.getStage().container();
  container.style.cursor = 'default';
};

export const drawCanvasElementoptionsButton = (refMenu, refTransform) => {
  if (refMenu?.current && refTransform?.current) {
    const pos = refTransform.current.absolutePosition();
    const leftMargin = 5;
    refMenu.current.absolutePosition({
      x: pos.x + refTransform.current.width() + leftMargin,
      y: pos.y,
    });
  }
};
