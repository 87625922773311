import React from 'react';
import { Link } from 'react-router-dom';

import { NotFoundImg, NotFoundImg2x, NotFoundImg3x } from 'src/assets/images/not-found';
import { routesPaths } from 'src/routes/routesPaths';
import styles from './NotFound.module.scss';

const NotFound = () => (
  <div className={styles.container}>
    <img
      src={NotFoundImg}
      alt="Not found"
      srcSet={`${NotFoundImg2x} 2x, ${NotFoundImg3x} 3x`}
      className={styles.image}
    />
    <span className={styles.text}>
      Whoops, we might be a little lost.
    </span>
    <Link to={routesPaths.projects} className={styles.goBack}>
      Back to projects
    </Link>
  </div>
);

export { NotFound };
